import { useState, useEffect, useCallback } from "react";
import "./InstitutionDefinition.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getAllStatesByCountryCode } from "../../../../Api/places/getPlaces";
import { formattedDate } from "../../../../formatter/date";
import {
  getAllBranches,
  getBankParameterByName,
  institutionDefinition,
  getAllInstitutions,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import useCustom from "../../../../Api/Hooks-manager/coreModules/useCustom";
import Confirm from "../../../../Confirmbox/Confirm";
import CancelModal from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import Table from "../../../DatagridTable/table";
import EditForm from "./edit";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import Details from "./view";

const InstitutionDefinition = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const countries = useSelector(state => state.common.countries);
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const [states, setStates] = useState([]);
  const [error, setError] = useState({});
  const [errorEdit, setErrorEdit] = useState({});
  const [institutions, setInstitutions] = useState([]);
  const [allInstitutions, setAllInstitutions] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedValue, setSelectedValue] = useState("true");
  const [cancel, setCancel] = useState(false);
  const [data, setData] = useState({
    name: "",
    code: "",
    address: "",
    currencyCode: "",
    stateCode: "",
    countryCode: "",
    startTime: "",
    endTime: "",
    status: true,
    headOfficeBranchCode: "",
    logo: "string",
    institutionType: "",
  });

  const [details, setDetails] = useState({});

  const { allCurrencyDefinition, getCurrencyDefinitionAll } = useCustom();

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "institutionType",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "code",
      label: "Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Addresse",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "stateCode",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "countryCode",
      label: "Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}{" "}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleInstitution = allInstitutions[dataIndex];
                setDetails(singleInstitution);
                fetchState(singleInstitution.countryCode);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleInstitution = allInstitutions[dataIndex];
                setDetails(singleInstitution);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllBranches = useCallback(async () => {
    const response = await getAllBranches(token);
    if (response && response.data) {
      setAllBranches(response.data);
    }
  }, [token]);

  const fetchState = async value => {
    const response = await getAllStatesByCountryCode(token, value);
    console.log("statees", response.data);
    if (response && response.data) {
      setStates(response.data);
    }
  };
  const fetchAllInstitution = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllInstitutions(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllInstitutions(response.data);
    }
  }, [dispatch, token]);

  const parameters = "IST_Type";
  const fetchInstitutionType = useCallback(async () => {
    const response = await getBankParameterByName(token, parameters);
    if (response && response.data.isT_Type) {
      setInstitutions(response.data.isT_Type);
    }
  }, [token]);

  useEffect(() => {
    fetchAllInstitution();
  }, [fetchAllInstitution, updateTable]);

  useEffect(() => {
    fetchAllBranches();
  }, [fetchAllBranches]);

  useEffect(() => {
    fetchInstitutionType();
  }, [fetchInstitutionType]);

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, [getCurrencyDefinitionAll]);

  const handleChange = (name, id) => e => {
    const { value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (name === "countryCode") {
      fetchState(value);
    }
  };

  const handleEditChange = name => e => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
    if (name === "countryCode") {
      fetchState(value);
    }
  };

  const validateInstitutionDefinition = () => {
    const {
      name,
      code,
      address,
      currencyCode,
      stateCode,
      countryCode,
      startTime,
      endTime,
      institutionType,
    } = data;

    const error = {};

    if (name === "") {
      error.name = "Provide the name of the bank as recognized by CBN";
    }
    if (institutionType === "" || institutionType === "Select an institution") {
      error.institutionType = "Select a valid institution type";
    }
    if (currencyCode === "" || currencyCode === "Select a currency") {
      error.currencyCode = "Select a valid currency";
    }
    if (address.length < 10) {
      error.address = "Address should not be less than 10 characters";
    }
    if (address.length > 150) {
      error.address = "Address should not be more than 150 characters";
    }
    if (countryCode === "" || countryCode === "Select a country") {
      error.countryCode = "Select a valid country";
    }
    if (stateCode === "" || stateCode === "Select a state") {
      error.stateCode = "Select a valid state";
    }
    if (code === "") {
      error.code = "Invalid institution code";
    }
    if (startTime === "") {
      error.startTime = "Invalid start time";
    }
    if (endTime === "") {
      error.endTime = "Invalid stop time";
    }
    return error;
  };

  const validateEditInstitutionDefinition = () => {
    const {
      name,
      code,
      address,
      currencyCode,
      stateCode,
      countryCode,
      startTime,
      endTime,
      headOfficeBranchCode,
      institutionType,
    } = details;

    const error = {};

    if (name === "") {
      error.name = "Provide the name of the bank as recognized by CBN";
    }
    if (institutionType === "" || institutionType === "Select an institution") {
      error.institutionType = "Select a valid institution type";
    }
    if (currencyCode === "" || currencyCode === "Select a currency") {
      error.currencyCode = "Select a valid currency";
    }
    if (address.length < 10 || address.length > 150) {
      error.address =
        address.length < 5
          ? "Address should not be less than 10 characters"
          : "Address should not be more than 150 characters";
    }
    if (countryCode === "" || countryCode === "Select a country") {
      error.countryCode = "Select a valid country";
    }
    if (stateCode === "" || stateCode === "Select a state") {
      error.stateCode = "Select a valid state";
    }
    if (headOfficeBranchCode === "") {
      error.headOfficeBranchCode = "Invalid head office";
    }
    if (code === "") {
      error.code = "Invalid institution code";
    }
    if (startTime === "") {
      error.startTime = "Invalid start time";
    }
    if (endTime === "") {
      error.endTime = "Invalid stop time";
    }
    return error;
  };

  const EmptyFieldsResult = () => {
    setData({
      name: "",
      code: "",
      address: "",
      currencyCode: "Select a currency",
      stateCode: "Select a state",
      countryCode: "Select a country",
      startTime: "",
      endTime: "",
      status: true,
      headOfficeBranchCode: "",
      logo: "string",
      institutionType: "Select an institution",
    });
  };

  const postResult = async () => {
    dispatch(isLoading());
    const response = await institutionDefinition(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      setOpen(true);
      setMessage(response.message);
      if (response.message === "Success") {
        EmptyFieldsResult();
        fetchInstitutionType();
        fetchAllInstitution();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleClearRecord = () => {
    setData({
      name: "",
      code: "",
      address: "",
      currencyCode: "Select a currency",
      stateCode: "Select a state",
      countryCode: "Select a country",
      startTime: "",
      endTime: "",
      status: true,
      headOfficeBranchCode: "",
      logo: null,
      institutionType: "Select an institution",
    });
    return true;
  };

  const handleSubmit = () => {
    selectedValue === "true" ? (data.status = true) : (data.status = false);
    const checkError = validateInstitutionDefinition();
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;

    setError({});
    setOpen(true);
  };

  const handleEditSubmit = () => {
    details.status = details.status === true ? true : false;
    const checkError = validateEditInstitutionDefinition();
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;

    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };

  return (
    <div className="institution--definition">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={postResult}
        //message='Are you sure you want to delete this record?'
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <CancelModal
        ApiCall={handleClearRecord}
        message="Are you sure you want to clear this record?"
        openBox={cancel}
        setOpenBoxState={setCancel}
        actionMsg="Your records were cleared successfully"
      />
      <div className="headerdiv">
        <p>Institution Definition</p>
      </div>
      <Paper style={{ padding: 32 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <label>Institution Name</label>
            <br />
            <input
              type="text"
              className={error.name ? "error-input-z" : null}
              value={data.name}
              onChange={handleChange("name")}
            />{" "}
            {error.name && <ErrorMessage message={error.name} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Institution Type</label>
            <br />
            <select
              onChange={handleChange("institutionType")}
              className={error.institutionType ? "error-input-z" : null}
              value={data.institutionType}
            >
              <option value="Select an institution">
                Select an institution
              </option>
              {institutions.map(institution => (
                <option value={institution.parameterValue} key={institution.id}>
                  {institution.parameterValue}{" "}
                </option>
              ))}{" "}
            </select>
            {error.institutionType && (
              <ErrorMessage message={error.institutionType} />
            )}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Institution Currency</label>
            <br />
            <select
              onChange={handleChange("currencyCode")}
              className={error.currencyCode ? "error-input-z" : null}
              value={data.currencyCode}
            >
              <option value="Select a currency">Select a currency</option>
              {allCurrencyDefinition.map(item => (
                <option value={item.currencyCode} key={item.id}>
                  {item.currencyName}{" "}
                </option>
              ))}{" "}
            </select>
            {error.currencyCode && (
              <ErrorMessage message={error.currencyCode} />
            )}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Institution Address</label>
            <br />
            <input
              className={error.address ? "error-input-z" : null}
              type="text"
              value={data.address}
              onChange={handleChange("address")}
            />{" "}
            {error.address && <ErrorMessage message={error.address} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Country</label>
            <br />
            <select
              onChange={handleChange("countryCode")}
              className={error.countryCode ? "error-input-z" : null}
              value={data.countryCode}
            >
              <option value="Select a country">Select a country</option>
              {countries.map(country => (
                <option value={country.countryCode2} key={country.id}>
                  {country.name}{" "}
                </option>
              ))}{" "}
            </select>
            {error.countryCode && <ErrorMessage message={error.countryCode} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>State</label>
            <br />
            <select
              onChange={handleChange("stateCode")}
              className={error.stateCode ? "error-input-z" : null}
              value={data.stateCode}
            >
              <option value="Select a state">Select a state</option>
              {states.map(state => (
                <option value={state.name} key={state.id}>
                  {state.name}{" "}
                </option>
              ))}{" "}
            </select>
            {error.stateCode && <ErrorMessage message={error.stateCode} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Head Office Branch Code</label>
            <br />
            <select
              value={data.headOfficeBranchCode}
              onChange={handleChange("headOfficeBranchCode")}
            >
              <option value="Select a branch">Select a branch</option>
              {allBranches.map(branch => (
                <option value={branch.code} key={branch.id}>
                  {`${branch.name} - ${branch.code}`}{" "}
                </option>
              ))}{" "}
            </select>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Institution Code</label>
            <br />
            <input
              className={error.code ? "error-input-z" : null}
              type="text"
              value={data.code}
              onChange={handleChange("code")}
            />{" "}
            {error.code && <ErrorMessage message={error.code} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Work Time</label>
            <div className="work-time">
              <span>Start Time</span>
              <input
                className={error.startTime ? "error-input-z" : null}
                type="time"
                value={data.startTime}
                style={{
                  minWidth: "30px",
                  marginLeft: 120,
                }}
                onChange={handleChange("startTime")}
              />{" "}
              {error.startTime && <ErrorMessage message={error.startTime} />}
              <br />
              <span>Stop Time</span>
              <input
                className={error.endTime ? "error-input-z" : null}
                type="time"
                value={data.endTime}
                style={{
                  minWidth: "30px",
                  marginLeft: 120,
                }}
                onChange={handleChange("endTime")}
              />{" "}
              {error.endTime && <ErrorMessage message={error.endTime} />}{" "}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Status</label>
            <div className="status">
              <div>
                <span>Active</span>
                <Radio
                  checked={selectedValue === "true"}
                  onChange={e => setSelectedValue(e.target.value)}
                  value="true"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
                <span style={{ marginLeft: 80 }}>Inactive</span>
                <Radio
                  checked={selectedValue === "false"}
                  onChange={e => setSelectedValue(e.target.value)}
                  value="false"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "B" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="savebtn flex--1">
          <button className="save" onClick={() => handleSubmit()}>
            Save
          </button>
        </div>
      </Paper>
      {!loading && (
        <Table
          title="Institutions"
          subtitle=""
          name="List of institutions"
          records={allInstitutions}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />{" "}
      {details && details.id && (
        <EditForm
          editData={details}
          errorEdit={errorEdit}
          states={states}
          handleEditChange={handleEditChange}
          allCurrencyDefinition={allCurrencyDefinition}
          institutions={institutions}
          allBranches={allBranches}
          handleEditSubmit={handleEditSubmit}
        />
      )}{" "}
    </div>
  );
};

export default InstitutionDefinition;

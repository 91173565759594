import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleChange,
  details,
}) => {
  return (
    <EditModal
      title="Update currency definition"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}organization/CurrencyDefinition/Update/${details.id}`}
    >
         <div className="fiscal--year ">
       <section className="section--1 ">
       <div className="sub--section grid--1">
                <div className="label--input flex--3">
                  <label>Currency Name</label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e, "currencyDef")}
                    name="currencyName"
                    value={details.currencyName}
                    // className={`${
                    //   Object.keys(errors).includes("currencyName") &&
                    //   "error-input-z"
                    // }`}
                  />
                  {/* {errors.currencyName && (
                    <ErrorMessage message={errors.currencyName} />
                  )} */}
                </div>
                <div className="label--input flex--3">
                  <label>Currency Code</label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e, "currencyDef")}
                    name="currencyCode"
                    value={details.currencyCode}
                    // className={`${
                    //   Object.keys(errors).includes("currencyCode") &&
                    //   "error-input-z"
                    // }`}
                  />
                  {/* {errors.currencyCode && (
                    <ErrorMessage message={errors.currencyCode} />
                  )} */}
                </div>
                <div className="label--input flex--3">
                  <label>Numeric Code</label>
                  <input
                    type="number"
                    onChange={(e) => handleChange(e, "currencyDef")}
                    name="numericCode"
                    value={details.numericCode}
                    // className={`${
                    //   Object.keys(errors).includes("numericCode") &&
                    //   "error-input-z"
                    // }`}
                  />
                </div>
              </div>
           </section>              
      </div>
    </EditModal>
  );
};

export default EditData;

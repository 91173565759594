import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {getReferredList, getTotalEarnedByReferral} from "../../../../Api/Hooks-manager/accountEnquiry/accounteEnquiry"
import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import Details from "../../../Details/Products/AccountProducts";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
} from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { TRUE } from "sass";


const ReferralTable = ({details}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [referralBonus, setReferralBonus] = useState(0);
  const [referralInfo, setReferralInfo] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "memberId",
      label: "Customer ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fullName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productCode",
      label: "Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdOn",
      label: "Registration Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "active",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (active) => (
    //       <p
    //         style={{
    //           color:
    //           active === "true"
    //               ? "green"
    //               : active === "false"
    //               ? "red"
    //               : "rgb(224, 148, 33)",
    //         }}
    //       >
    //         {active === "active" ? "false" : "true"}
    //       </p>
    //     ),
    //   },
    // },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setReferralInfo(item);
        dispatch(openDetailsModal());
      }
    },
  };

  const memberId = details.customer.memberId;

  const fetchAllReferralListByCode = useCallback(async (referralCode) => {
    dispatch(loading());
    const response = await getReferredList(token, referralCode);
    console.log(response, "peope...")
    dispatch(loading());
    if (response.data && response.data.length) {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].createdOn = formattedDate(
          response.data[i].createdOn
        );
        // response.data[i].dateAuthorized = formattedDate(response.data[i].dateAuthorized);
        // response.data[i].updatedDateTime = formattedDate(
        //   response.data[i].updatedDateTime
        // );
      }
      setData(response.data);
    }
  }, [token]);

  const fetchTotalEarnedByCode = useCallback(async(memberCode) => {
    dispatch(loading());
    const response = await getTotalEarnedByReferral(token, memberCode);
    console.log(response.data, "referral bonus");
    dispatch(loading())
    if (response && response.data) {
      setReferralBonus(response.data);
    } else{
      return;
    }
  },[token]);

  useEffect(() => {
    fetchAllReferralListByCode(memberId);
  }, [fetchAllReferralListByCode, details, updateTable, memberId]);

  useEffect(() => {
    fetchTotalEarnedByCode(memberId);
  }, [memberId, fetchTotalEarnedByCode]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && (
        <Table
          title="List of referrals"
          subtitle={`Total earned referral bonus: ${referralBonus}`}
        //   name="List of referrals"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      {/* <DetailsContainer title="AccountProduct products - Record Details">
        <Details details={details} />
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize teller product"
        question="Are you sure you want to authorize this product?"
        url={`${baseUrl}account/AccountProduct/Authorize/${details.id}`}
      /> */}
    </div>
  );
};

export default ReferralTable;

import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";
import { DateConverter } from "../../../ConvertDate";
import ErrorMessage from "../../../ErrorMessage";
import Grid from "@material-ui/core/Grid"


const EditData = ({
  handleEditSubmit,
  handleChange,
  details,
}) => {
  return (
    <EditModal
      title="Update Parameter Settings"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}organization/BankParameters/Update/${details.id}`}
    >
       <Grid container spacing={2}>
           <Grid item xs={12} sm={6} md={4}>
           <label>Parameter Name</label>
                     <input style={{height:44,borderRadius:5}}
                         type="text"
                         onChange={handleChange}
                         name='parameterName'
                         value={details.parameterName}
                     /> 
           </Grid>
           <Grid item xs={12} sm={6} md={4}>
           <label>Parameter Value</label>
                     <input style={{height:44,borderRadius:5}}
                         type="text"
                         onChange={handleChange}
                         name='parameterValue'
                         value={details.parameterValue}
                     />
           </Grid>
           <Grid item xs={12} sm={6} md={4}>
           <label>Parameter Length</label>
                     <input style={{height:44,borderRadius:5}}
                         type="number"
                         onChange={handleChange}
                         name='parameterLength'
                         value={details.parameterLength}
                     />
           </Grid>
       </Grid>
    </EditModal>
  );
};

export default EditData;

import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/AccountOperations/AccountEnquiry";

const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Customer account - Record Details"
      question="Are you sure you want to delete this Account?"
    >
      <Details details={details} showTooltip={true}/>
    </DetailsContainer>

  );
};

export default View;
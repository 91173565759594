import React from 'react'
import { Switch, Route } from "react-router-dom";
import './Main.scss'
import UserCreation from './UserCreation/UserCreation';
import BranchTellerTills from './BranchTellerTills/BranchTellerTills';
import ChannelUser from './ChannelUser/ChannelUser';
import ManagePermission from './ManagePermission/ManagePermission';
import ManageRoles from './ManageRoles/ManageRoles';
import UserTellerTillLinkage from './UserTellerTills/UserTellerTills';

const Main = () => {
    return (
        <div className='main'>
            <Switch>
                <Route exact path='/dashboard/security/1' component={UserCreation}/>
                <Route path='/dashboard/security/2' component={BranchTellerTills}/>
                <Route path='/dashboard/security/3' component={UserTellerTillLinkage}/>
                <Route path='/dashboard/security/4' component={ChannelUser}/>
                <Route path='/dashboard/security/5' component={ManageRoles}/>
                <Route path='/dashboard/security/6' component={ManagePermission}/>
            </Switch>
        </div>
    )
}

export default Main

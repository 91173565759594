import React,  { useState, useEffect } from 'react';
import './InvestmentCertificate.scss'
import { BiErrorCircle } from "react-icons/bi";
import Spinner from '../../../../Backdrop/index'


const InvestmentCertificate = () => {
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 2000);
    }, [])
    return (
        <div className='InvestmentCertificate border--main'>
            <div style={{ position: 'absolute'}}>
                <Spinner loading={loader}/>
            </div>
            <div className="headerdiv">
                <p>Reports | Investment Certificate Report</p>
            </div>
            <div className='not--loaded flex--6'>
                {!loader && 
                    <>
                    <BiErrorCircle className='circle-error'/>
                    <h4>No Data Found</h4>
                    </>
                }
            </div>
        </div>
    )
}

export default InvestmentCertificate

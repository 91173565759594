import React from 'react';
import './Main.scss';
import { Switch, Route} from "react-router-dom";
import McLiquidation from './McLiquidation/McLiqidation';
import McIssuance from './McIssuance/McIssuance';
import LiquidationWalkin from './LiquidationWalkin/LiquidationWalkin';
import McBranch from './McBranch/McBranch';

 const Main = () => {
    return (
        <div className='Main'>
            <Switch>
                <Route path='/dashboard/customeraccount/1' component={McIssuance }/>  
                <Route path='/dashboard/customeraccount/2' component={McLiquidation }/>  
                <Route path='/dashboard/customeraccount/3' component={LiquidationWalkin}/>  
                <Route path='/dashboard/customeraccount/4' component={McBranch }/>  
            </Switch> 
        </div>
    )
}
export default Main;

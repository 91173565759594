import { useState, useCallback, useEffect } from "react";
import "./PARReport.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
// import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../ErrorMessage";
import {
  getAllParReports,
  getBranchCode,
} from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { formattedDate } from "../../../../../formatter/date"
import { useDispatch } from "react-redux";

import {

  isLoading,
} from "../../../../../Api/redux-manager/actions";
import { numberFormatter } from "../../../../../formatter";

const PARReport = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const [par, setPar] = useState({
    branchCode: "",
  });
  const [allParReport, setAllParReport] = useState(null);
  const [branchesCode, setBranchesCode] = useState([]);

  const [errors, setErrors] = useState({});

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccountNo",
      label: "Loan Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanProduct",
      label: "Loan product.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "repaymentAccountNo",
      label: "Repayment Acc No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanCurrency",
      label: " Loan Currency",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "bookedDate",
      label: "Loan Book Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "startDate",
      label: "Loan start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "loanAmount",
      label: "Loan Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{numberFormatter(value)}</p>

      },
    },
    {
      name: "disbursementDate",
      label: "Loan Disbursement Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "amountDisbursed",
      label: "Amount Disbursed ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tenor",
      label: "Tenor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Loan Maturity Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "overduePrincipalAmount",
      label: "Overdue Principal Amnt. ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "overdueInterestAmount",
      label: "Overdue interest Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "totalOverdueRepaymentAmount",
      label: " Total Overdue Repayment",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "repaymentDueDate",
      label: "Repayment Due Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "overdueDaysNumber",
      label: "No. of Days Overdue",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastRepaymentDate",
      label: " Last Repayment Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setPar({
      ...par,
      [name]: value,
    });
  };
  const resetHandlers = () => {
    EmptyField();
    setAllParReport(false);
  };

  const EmptyField = () => {
    setPar({
      branchCode: "",
    });
  };

  const handleSearch = () => {
    const checkErrors = validateData();
    if (checkErrors || loading) {
      fetchParReport();
      console.log("i am livid");
    }
    console.log("i am believer");
  };

  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { branchCode } = par;
    if (branchCode === "") {
      error.branchCode = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };
  const fetchBranchCode = useCallback(async () => {
    const response = await getBranchCode(token, branchesCode);
    if (response && response.data) {
      setBranchesCode(response.data);
    }
  }, [token, branchesCode]);
  useEffect(() => {
    fetchBranchCode();
  }, []);

  const fetchParReport = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllParReports(token, par);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setAllParReport(response.data);
    }
  }, [par, token, dispatch]);

  console.log("par report", allParReport);

  return (
    <div className="PARReport border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p>Reports | Portfolio At Risk Report</p>
      </div>
      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Branch Code</label>
            <select
              type="text"
              value={par.branchCode}
              name="branchCode"
              onChange={handleChange("branchCode")}
            >
               <option >Select a branch code</option>
              {branchesCode.map((codes,i) => (
                <option value={codes.code} key={i}>{codes.code}</option>
              ))}
            </select>
            {errors.branchCode && <ErrorMessage message={errors.branchCode} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSearch}>Search</button>
          <button onClick={resetHandlers}>Reset</button>
        </div>
        <hr />
        {allParReport && (
          <div>
            <Spinner loading={loading} />
            <Table
              title="PAR Report"
              subtitle=""
              name="List of PAR Reports"
              records={allParReport}
              options={options}
              columns={columns}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default PARReport;

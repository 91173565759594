import { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Routes } from "../../Api/configUrl";

import { getCustomerByAccountNumber } from "../../Api/Hooks-manager/loanAccountOperations";
import {
  loading,
} from "../../Api/redux-manager/actions";
import Item from "../DatagridTable/item";
import { makeStyles } from "@material-ui/core/styles";
import AuthorizationModal from "../DatagridTable/authorizationModal";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogContent-root":{
      display:"flex"
    }
  },
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  item: {
    width: "100%",
  },
}));


const TransactionDetails = ({ details }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const load = useSelector((state) => state.approve.loading);

  const [cusAccountInfo, setCusAccountInfo] = useState({
    creditAccountName: "",
    debitAccountName: "",
  });

  // const [cusDebitAccountInfo, setCusDebitAccountInfo] = useState({
  //   debitAccount: "",
  //   debitAccountName: "",
  // });

  console.log(details);



  const fetchCustomerDetails = useCallback(
    async (accNum, key) => {
      dispatch(loading());
      const response = await getCustomerByAccountNumber(token, accNum);
      if (response && response.data) {
        // console.log(response.data.accountName, "mmmm")
        dispatch(loading());
        setCusAccountInfo((prev) => ({
          ...prev,
          [key]: response.data.accountName,
        }));

        // setCusDebitAccountInfo((prev) => ({
        //   ...prev,
        //   debitAccountName: response.data.accountName,
        // }));
  
      }
    },
    [token, dispatch]
  );

  useEffect(() => {
    if(details.creditAccount.length === 10){
      fetchCustomerDetails(details.creditAccount, "creditAccountName")
    }
  },[details.creditAccount]);

  useEffect(() => {
    if(details.debitAccount.length === 10){
      fetchCustomerDetails(details.debitAccount, "debitAccountName")
    }
  },[details.debitAccount]);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(value);

    const classes = useStyles();

  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Debit Account" name={details.debitAccount} />
        </Grid>
        {cusAccountInfo.debitAccountName && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Debit Account Name"
              name={cusAccountInfo.debitAccountName}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Credit Account" name={details.creditAccount} />
        </Grid>
        {cusAccountInfo.creditAccountName && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Credit Account Name"
              name={cusAccountInfo.creditAccountName}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Currency" name={details.currencyCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Transaction Amount"
            name={numberFormat(details.postAmount)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Narration" name={details.narration} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Transaction Date" name={details.transactionDate} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Reference" name={details.referenceNumber} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Date Authorized" name={details.dateAuthorized}/>
      </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
      </Grid> */}
      </Grid>
    </div>


  );
};

export default TransactionDetails;

import * as actionTypes from "../constants";
import { updatedObject } from "../utilty";

const initialState = {
  loading: false,
  openDetailsModal: false,
  openForm: false,
  openDecisionModal: false,
  newOpenDecisionModal: false,
  openResponseModal: false,
  data: null,
  status: "",
  message: "",
  updateTable: "",
};

const loading = (state, action) => {
  return updatedObject(state, {
    loading: !state.loading,
  });
};

const openDetailsModal = (state, action) => {
  return updatedObject(state, {
    openDetailsModal: true,
  });
};

const closeDetailsModal = (state, action) => {
  return updatedObject(state, {
    openDetailsModal: false,
  });
};

const openFormDialog = (state, action) => {
  return updatedObject(state, {
    openForm: true,
  });
};

const closeFormDialog = (state, action) => {
  return updatedObject(state, {
    openForm: false,
  });
};

const openDecisionModal = (state, action) => {
  return updatedObject(state, {
    opendecisionModal: true,
    data: action.payload,
  });
};

const newOpenDecisionModal = (state, action) => {
  return updatedObject(state, {
    newOpenDecisionModal: true,
    data: action.payload,
  });
};

const closeDecisionModal = (state, action) => {
  return updatedObject(state, {
    opendecisionModal: false,
    newOpenDecisionModal: false,
  });
};

const authFormStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
  });
};

const authFormSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    data: null,
    opendecisionModal: false,
    newOpenDecisionModal: false,
    openDetailsModal: false,
    openResponseModal: true,
    status: action.status,
    message: action.message,
    updateTable: Math.random().toString(36),
  });
};

const authFormFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    opendecisionModal: false,
    newOpenDecisionModal: false,
    openResponseModal: true,
    status: action.status,
    message: action.message,
    upadteTable: "",
  });
};

const closeResponseModal = (state, action) => {
  return updatedObject(state, {
    openResponseModal: false,
    status: "",
    message: "",
  });
};

const openResponseModal = (state, action) => {
  return updatedObject(state, {
    openResponseModal: true,
  });
};

const approve = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SPINNER:
      return loading(state, action);
    case actionTypes.OPEN_DETAILS_MODAL:
      return openDetailsModal(state, action);
    case actionTypes.CLOSE_DETAILS_MODAL:
      return closeDetailsModal(state, action);
    case actionTypes.OPEN_FORM_DIALOGUE:
      return openFormDialog(state, action);
    case actionTypes.CLOSE_FORM_DIALOGUE:
      return closeFormDialog(state, action);
    case actionTypes.OPEN_DECISION_MODAL:
      return openDecisionModal(state, action);
    case actionTypes.NEW_OPEN_DECISION_MODAL:
      return newOpenDecisionModal(state, action);
    case actionTypes.CLOSE_DECISION_MODAL:
      return closeDecisionModal(state, action);
    case actionTypes.APPROVE_FORM_START:
      return authFormStart(state, action);
    case actionTypes.APPROVE_FORM_SUCCESS:
      return authFormSuccess(state, action);
    case actionTypes.APPROVE_FORM_FAIL:
      return authFormFail(state, action);
    case actionTypes.CLOSE_RESPONSE_MODAL:
      return closeResponseModal(state, action);
    case actionTypes.OPEN_RESPONSE_MODAL:
      return openResponseModal(state, action);
    default:
      return state;
  }
};

export default approve;

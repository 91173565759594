const isValidName = (name) => {
    return /^[a-zA-Z]{2,}$/.test(name);
  };

const isValidUserName = (name) => {
    return /^[A-Za-z0-9]{4,}$/.test(name);
  };

  const isValidEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const isValidPassword = (password) => { 
    return /^(.){6,}$/.test(password);
  };
  
  const isValidBriefDescription = (briefDescription) => {
    return /^.{5,}$/.test(briefDescription);
  };
  
  const isValidFunds = (funds) => {
    return /^[\d]{4,}$/.test(funds);
  };
  
  const isValidPhoneNumber = (phone) => {
    return /^(\+234)?(0)?[\d]{10}|(234)?(0)?[\d]{10}|[0][\d]{10}$/.test(phone);
  };

  const isValidBvn = (bvn) => {
    return /^[0-9]{11}$/.test(bvn)
  };
  const isValidRcNumberNum = (num) => {
    return /^[0-9]{9}$/.test(num)
  };

  const isValidDriversLicense = (license) => {
    return  /^[A-Z0-9]{12}$/.test(license)
  };
  const isValidDriversLicenseNum = (num) => {
    return /^[0-9]{12}$/.test(num)
  };
  const isValidDriversLicenseAlpha = (alpha) => {
    return /^[A-Za-z]{12}$/.test(alpha);
  };

  const isValidLienCodeAlpha = (alpha) => {
    return /^[A-Za-z]{4}$/.test(alpha);
  };
  
  const isValidVotersId = (voter) => {
    return /^[a-zA-Z0-9 ]{9,19}$/.test(voter)
  };
  const isValidVotersIdNum = (num) => {
    return /^[0-9]{19}$/.test(num)
  };
  const isValidVotersIdAlpha = (alpha) => {
    return /^[A-Za-z]{19}$/.test(alpha);
  };

  const isValidTinNumber = (tin) => {
    return /^[0-9]{14}$/.test(tin)
  };


  const isValidIntPassport = (passport) => {
    return /^[A-Z0-9]{7,9}$/.test(passport)
  };
  const isValidIntPassportNum = (num) => {
    return /^[0-9]{9}$/.test(num)
  };
  const isValidIntPassportAlpha = (alpha) => {
    return /^[A-Za-z]{9}$/.test(alpha);
  };

  const isValidNin = (nin) => {
    return /^[0-9]{11,12}$/.test(nin)
  };

  const isValidReferralCode = (num) => {
    return /^[0-9]{7}$/.test(num)
  };
  const isValidRcNumber = (rc) => {
    return /^[A-Z0-9]{9}$/.test(rc)
  };
  const isValidRcNumberAlpha = (alpha) => {
    return /^[A-Za-z]{9}$/.test(alpha);
  };

  export {
    isValidName,
    isValidUserName,
    isValidEmail,
    isValidPassword,
    isValidBriefDescription,
    isValidFunds,
    isValidPhoneNumber,
    isValidBvn,
    isValidTinNumber,
    isValidIntPassport,
    isValidDriversLicense,
    isValidVotersId,
    isValidNin,
    isValidRcNumber,
    isValidRcNumberNum,
    isValidRcNumberAlpha,
    isValidDriversLicenseNum,
    isValidDriversLicenseAlpha,
    isValidVotersIdNum,
    isValidVotersIdAlpha,
    isValidIntPassportNum,
    isValidIntPassportAlpha,
    isValidLienCodeAlpha,
    isValidReferralCode,
   };
  


// NIGERIA_BVN_REGEX = /^[0-9]{11}$/
// NIGERIA_NIN_REGEX = /^[0-9]{11}$/
// NIGERIA_NIN_SLIP_REGEX = /^[0-9]{11}$/
// NIGERIA_DRIVER_LICENSE_REGEX = /^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/
// NIGERIA_VOTER_ID_REGEX = /^[a-zA-Z0-9 ]{9,19}$/
// NIGERIA_CAC_REGEX = /^(RC)?[0-9]{5,8}$/
// NIGERIA_TIN_REGEX = /^[0-9]{8,}-[0-9]{4,}$/
import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/CoreModules/PeriodDefinition";

const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Period Definition- Record Details"
      question="Are you sure you want to delete this period?"
      url={`${baseUrl}organization/PeriodDefinition/Delete/${details.id}`}
    >
      <Details details={details} />
    </DetailsContainer>
  );
};

export default View;

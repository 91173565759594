import React from "react";
import "./debitcredittellertills.scss";
import BusinessLogic from "./receiveCashLogic";
import NumberFormat from "react-number-format";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import ErrorModal from "../../../../Confirmbox/myModal";

import Confirm from "../../../../Confirmbox/Confirm";


const CashDeposit = () => {
  const {
    glTransaction,
    setGlTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    denomTotalValue,
    denominationValue,
    setDenominationValue,
    screenChange,
    handleScreenChange,
    errors,
    errorSummary,
    user,
    index,
    setErrors,
    validateInput,
    handleChange,
    handleDenominationChange,
    makeTransfer,
    makeAutoTransfer,
    open,
    setOpen,
    openErrorModal,
    setOpenErrorModal,
    message,
    handleSubmit,
    allBranches,
  } = BusinessLogic();

  const { denominations } = glTransaction;

  // const handleScreenChange = (i) => {
  //   console.log("qwswqeq", i);
  //   setScreenChange(i);
  // };

  return (
    <main className="cash-deposit--lcy">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={glTransaction.postAmount > debitAccDetails.userCrCashLimit ? makeTransfer : makeAutoTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <ErrorModal
        title="Error Summary"
        openDialog={openErrorModal}
        positiveDialog={false}
        onClose={() => setOpenErrorModal(false)}
      >
        <div>
        {errorSummary && errorSummary.notBalance && (
            <div>{errorSummary.notBalance}</div>
          )}
        </div>
      </ErrorModal>
      <section className="section--1">
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className="flex--3">
              <button
                className={`${!screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(false)}
              >
                1
              </button>
              <p>Receive Cash</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                2
              </button>
              <p>Denominations</p>
            </div>
          </div>
          {!screenChange && (
            <>
              <div style={{ paddingTop: 6, padding: 25 }}>
                <div className="teller--def flex--1">
                  <div className="flex--3 input--3">
                    <label>Till Account Number</label>
                    <input
                      disabled
                      name="debitAccount"
                      className={`pink ${
                        errors.debitAccount ? "error-input-z" : null
                      }`}
                      value={glTransaction.debitAccount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex--3 input--3">
                    <label>Teller Name</label>
                    <input
                      disabled
                      name="product"
                      value={user.firstName + " " + user.lastName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex--3  input--select">
                    <label>Branch</label>
                    <input
                      disabled
                      name="entryBranch"
                      value={glTransaction.entryBranch}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="teller--def flex--1">
                  <div className="flex--3  input--select">
                    <label>Till Currency</label>
                    <input
                      type="text"
                      disabled
                      className={`pink ${
                        errors.currencyCode ? "error-input-z" : null
                      }`}
                      name="currencyCode"
                      value={glTransaction.currencyCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex--3 input--3">
                    <label> GL Account Number</label>
                    <input
                      placeholder=""
                      className={`debit--GL--input ${
                        errors.debitAccount ? "error-input-z" : null
                      }`}
                      name="creditAccount"
                      value={glTransaction.creditAccount}
                      onChange={handleChange}
                    />
                    {errors.creditAccount && (
                      <ErrorMessage message={errors.creditAccount} />
                    )}
                  </div>
                  <div className="flex--3 input--3">
                    <label> GL Account Name</label>
                    <input
                      disabled
                      name="accountName"
                      value={creditAccDetails.accountName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex--3  input--select">
                    <label>GL Account Branch</label>
                    <input
                      disabled
                      name="debitAccountBranch"
                      value={creditAccDetails.accountBranch}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="teller--def flex--1">
                  <div className="flex--3  input--select">
                    <label>GL Account Currency</label>
                    <input
                      disabled
                      name="currency"
                      value={glTransaction.currencyCode}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex--3 input--3">
                    <label>Amount</label>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      value={glTransaction.postAmount}
                      onValueChange={({ floatValue }) =>
                        handleChange({
                          target: {
                            name: "postAmount",
                            value: floatValue,
                          },
                        })
                      }
                    />
                    {errors.postAmount && (
                      <ErrorMessage message={errors.postAmount} />
                    )}
                  </div>
                  <div className="flex--3 input--3">
                    <label>Narration</label>
                    <input
                      type="text"
                      name="narration"
                      onChange={handleChange}
                      value={glTransaction.narration}
                    />
                    {errors.narration && (
                      <ErrorMessage message={errors.narration} />
                    )}
                  </div>
                </div>

                <div className="flex--5">
                  <button onClick={() => handleScreenChange(true)}>Next</button>
                </div>
              </div>
            </>
          )}
          {screenChange && (
            <>
              <main className="sub-main">
              <section className="sub-main--1 flex--2">
                  <div className="flex--2">
                    <p>Amount</p>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      value={glTransaction.postAmount}
                      onValueChange={({ floatValue }) =>
                        handleChange({
                          target: {
                            name: "postAmount",
                            value: floatValue,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="flex--2">
                    <p>Net Amount</p>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      disabled
                      value={denomTotalValue}
                      onChange={() => {}}
                    />
                  </div>
                </section>
                <section className="sub-main--2 flex--3">
                  {denominations.map((item, i) => (
                    <div key={i}>
                      <div className="multiplier-input flex--2">
                        <input
                          type="number"
                          value={item.denomNo}
                          disabled
                          name="denomNo"
                        />
                        <span>&times;</span>
                        <input
                          type="number"
                          value={item.denomCount}
                          name="denomCount"
                          onChange={(e) => handleDenominationChange(e, i)}
                        />
                        <span>&#61;</span>
                        <input
                          disabled
                          name="denomTotal"
                          type="number"
                          // onValueChange= {(values) => {
                          //   setGlTransaction((glTransaction) => ({
                          //     ...glTransaction,
                          //     denomTotal: (values.value)
                          //   }))
                          // }}
                          value={item.denomTotal}
                        />
                      </div>
                    </div>
                  ))}
                </section>
              </main>
              <div className="button-last button-last--1 flex--2">
                <div className="button-container flex--1 flex--5">
                  <button onClick={() => handleScreenChange(false)}>
                    Previous
                  </button>
                  <button onClick={handleSubmit}>Save</button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default CashDeposit;

import React from 'react'
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import CashDeposit from './CashDeposit/CashDeposit';
import CashWithdrawal from './CashWithdrawal/CashWithdrawal';
import FundTransfer from './FundTransfer/FundTransfer';
import ChequeTransfer from './ChequeTransfer/ChequeTransfer';





const Main = () => { 
    return (
        <div className='main'>
            <Switch> 
                <Route path='/dashboard/cashtransactions-fcy/001'component={CashDeposit}/>
                <Route path='/dashboard/cashtransactions-fcy/002'component={CashWithdrawal}/>
                <Route path='/dashboard/cashtransactions-fcy/003'component={FundTransfer}/>
                <Route path='/dashboard/cashtransactions-fcy/004'component={ChequeTransfer}/>
            </Switch>
        </div>
    )
}

export default Main

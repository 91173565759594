import React from "react";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/LoanAccount/LoanDisbursement";

const view = ({ details, creditAccountDetails }) => {
  console.log(creditAccountDetails, "yyyy");
  return (
    <DetailsContainer
      title="Customer account - Record Details"
      question="Are you sure you want to delete this record?"
    >
      <Details
        details={details}
        showTooltip={true}
        creditAccountDetails={creditAccountDetails}
      />
    </DetailsContainer>
  );
};

export default view;

import { useState, useEffect, useCallback } from "react";
import "./UserCreation.scss";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useSelector } from 'react-redux';
// import UserStatus from "../../../../commons/checkboxes/userStatus";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import { formattedDate } from "../../../../formatter/date";
import EditForm from "./edit";
import Details from "./view";
import { useDispatch } from "react-redux";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";
import Confirm from "../../../../Confirmbox/Confirm";
import useForm from "../../../../Api/Hooks-manager/securitymanagement/useCustom";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import {
  createUser,
  getAllUsers,
} from "../../../../Api/Hooks-manager/securitymanagement/userCreation";
//  import { getAllRoles} from '../../../../Api/Hooks-manager/securitymanagement/useCustom';
import { getAllBranches } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import {
  isValidName,
  isValidEmail,
  isValidPassword,
  isValidUserName,
} from "../../../../Helper/validator";
import {
  getAllStatesByCountryCode,
} from "../../../../Api/places/getPlaces";

// import SearchDropdown from '../../../SearchableDropdown/Search'

const UserCreation = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const countries = useSelector((state) => state.common.countries);
  const loading = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const{getAllRoles, allRoles, setAllRoles} =useForm()
  // const [loading, setLoading] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [states, setStates] = useState([]);
  const [errorEdit, setErrorEdit] = useState({});
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    // title: "",
    firstName: "",
    userName: "",
    lastName: "",
    mobileNumber: "",
    middleName: "",
    countryCode: "",
    stateName: "",
    role: "",
    branchCode: "",
    gender: "",
    maritalStatus: "",
    emailAddress: "",
    institution: "",
    password: "",
    confirmPassword: "",
    isLoanOfficer: false,
    isAccountOfficer: false,
    });

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fullName",
      label: "Full Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "homeBranch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "activationStatus",
      label: "Activation Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Activated"
                  ? "green" : "red"
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllCreatedUsers =useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllUsers(token, "Staff", 0, 1000);
    if (response && response.data) {
      dispatch(isLoading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].fullName = `${response.data[i].firstName} ${response.data[i].lastName}`;
        response.data[i].activationStatus = response.data[i].inActive? 'De-activated' : 'Activated';
        response.data[i].createdDateTime = formattedDate(response.data[i].createdDateTime);
        response.data[i].dateAuthorized = formattedDate(response.data[i].dateAuthorized);
        response.data[i].updatedDateTime = formattedDate(response.data[i].updatedDateTime);
      };
    }
      setData(response.data);
    }
  },[dispatch, token])

  useEffect(() => {
    fetchAllCreatedUsers();
  }, [fetchAllCreatedUsers,updateTable]);


  const handleChange = (name) => (e) => {
    const { value, checked } = e.target;
    setUser({
      ...user,
      [name]: name === "isLoanOfficer" || name === "isAccountOfficer" ? checked : value,
    });
    if (name === "countryCode") {
      fetchState(value);
    }
  };

  const isValidateForm = (user) => {
    let errors = {};
    
    if (!user.firstName || !isValidName(user.firstName)) {
      errors.firstName = "Invalid Entry";
    }
    if (!isValidUserName(user.userName)) {
      errors.userName = "Invalid Username";
    }
    if (!user.lastName.trim() || !isValidName(user.lastName)) {
      errors.lastName = "Invalid Entry";
    }
    if (!user.countryCode.trim()) {
      errors.countryCode = "Select an option";
    }
    if (!user.stateName.trim()) {
      errors.stateName = "Select an option";
    }
    if (!user.role.trim()) {
      errors.role = "Select an option";
    }
    if (!user.branchCode.trim()) {
      errors.branchCode = "Select an option";
    }
    if (!user.gender.trim()) {
      errors.gender = "Select an option";
    }
    if (!user.emailAddress.trim() || !isValidEmail(user.emailAddress)) {
      errors.emailAddress = "Invalid Email";
    }
    if (!user.password.trim() || !isValidPassword(user.password)) {
      errors.password = "Min. of 6 characters";
    }
    if (
      !user.confirmPassword.trim() ||
      !isValidPassword(user.confirmPassword)
    ) {
      errors.confirmPassword = "Min. of 6 characters";
    }
    if (user.confirmPassword !== user.password) {
      errors.confirmPassword = "Password does not match";
    }
    return errors;
  };
  
  const fetchState = async (value) => {
    const response = await getAllStatesByCountryCode(token, value);
    if (response && response.data) {
      setStates(response.data);
    }
  };
  const fetchRoles = useCallback(async () => {
    const response = await getAllRoles(token);
    if (response && response.data) {
      setAllRoles(response.data);
    }
  }, [getAllRoles, setAllRoles, token]);

  const fetchAllBranches = useCallback(async () => {
    const response = await getAllBranches(token);
    if (response && response.data) {
      setAllBranches(response.data);
    }
  }, [token]);
  
  const handleSave = () => {
    let checkError = isValidateForm(user);
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;
    setError({});
    setOpen(true);
  };


  const EmptyFieldPostUser = () => {
    setUser({
      firstName: "",
      userName: "",
      lastName: "",
      middleName: "",
      countryCode: "",
      stateName: "",
      role: "",
      branchCode: "",
      operatingBranch: "",
      appRole: "",
      gender: "",
      mobile: "",
      emailAddress: "",
      password: "",
      confirmPassword: "",
      isAccountOfficer: false,
      isLoanOfficer: false,
      institution: "",
    });
  }
  const postUser = async () => {
    dispatch(isLoading());
    const owner = JSON.parse(localStorage.getItem("user"));
    user.institution = owner.institution;
    const response = await createUser(owner.jwtToken, user);
    if (response && response.message) {
      setMessage(response.message);
      dispatch(isLoading());
      if (response.status) {
        EmptyFieldPostUser();
        fetchAllCreatedUsers();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleEditChange = (name) => (e) => {
    const { value, checked } = e.target;
    setDetails({
      ...details,
      [name]: name === "isLoanOfficer" || name === "isAccountOfficer" ? checked : value,
    });
    if (name === "countryCode") {
      fetchState(value);
    }
    console.log(handleEditChange, "here is the change in edit")
  };
  const handleEditSubmit = () => {
    const checkError = validateEditUser();
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;
    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };
  const validateEditUser = () => {
    const {
      firstName,
      userName,
      lastName,
      countryCode,
      stateName,
      role,
      operatingBranch,
      gender,
      emailAddress,
    } = details;

    const error = {};
    if (firstName === "") {
      error.firstName = "Field required";
    }

    if (userName === "") {
      error.userName = "Field required";
    }
    if (lastName === "") {
      error.lastName = "Field required";
    }
    // if (middleName === "") {
    //   error.middleName = "Field required";
    // }
    if (countryCode === "") {
      error.countryCode = "Select an option";
    }
    if (stateName === "") {
      error.stateName = "Select an option";
    }
    
    if (role === "") {
      error.role = "Select an option";
    }
    if (operatingBranch === "") {
      error.operatingBranch = "Field required";
    }
    if (gender === "") {
      error.gender = "Select an option";
    }
    if (emailAddress === "") {
      error.emailAddress = "Field required";
    }

    return error;
  };

  const [homeBranchUX, setHomeBranchUX] = useState("");

  useEffect(() => {

    if (user.branchCode) {
      setUser({ ...user, operatingBranch: user.branchCode });
      let uxItem = allBranches.filter((item) => item.code === user.branchCode);
      setHomeBranchUX(`${uxItem[0].name} - ${uxItem[0].code}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.branchCode]);
  
  useEffect(() => {
    fetchRoles();
    fetchAllBranches()
  },[])

  return (
    <div>
    <main className="user--account">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={postUser}
        //message='Are you sure you want to delete this record?'
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />    
      <div className="headerdiv">
        <p>User Creation</p>
      </div>
      <Paper style={{ marginTop: 20, padding: 25 }}>
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <label>Username</label>
                <input
                  type="text"
                  value={user.userName}
                  name="userName"
                  onChange={handleChange("userName")}
                  className={`${error.userName && "error-input-z"}`}
                />
                {error.userName && <ErrorMessage message={error.userName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>First Name</label>
                <input
                  type="text"
                  value={user.firstName}
                  name="firstName"
                  onChange={handleChange("firstName")}
                  className={`${error.firstName && "error-input-z"}`}
                />
                {error.firstName && <ErrorMessage message={error.firstName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Middle Name</label>
                <input
                  type="text"
                  value={user.middleName}
                  name="middleName"
                  onChange={handleChange("middleName")}
                  className={`${error.middleName && "error-input-z"}`}
                />
                {error.middleName && (
                  <ErrorMessage message={error.middleName} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Last Name</label>
                <input
                  type="text"
                  value={user.lastName}
                  name="lastName"
                  onChange={handleChange("lastName")}
                  className={`${error.lastName && "error-input-z"}`}
                />
                {error.lastName && <ErrorMessage message={error.lastName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Mobile</label>
                <input
                  type="number"
                  name="mobile"
                  value={user.mobile}
                  maxLength='12'
                  onChange={handleChange("mobile")}
                  
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <label>Country </label>
                <br />
                <select
                  onChange={handleChange("countryCode")}
                  className={error.countryCode ? "error-input-z" : null}
                  value={user.countryCode}
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option value={country.countryCode2} key={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {error.countryCode && (
                  <ErrorMessage message={error.countryCode} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>State</label>
                <br />
                <select
                  value={user.stateName}
                  name="stateName"
                  onChange={handleChange("stateName")}
                  className={`${error.stateName && "error-input-z"}`}
                >
                  <option value="">Select a state</option>
                  {states.map((state) => (
                    <option value={state.name} key={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {error.stateName && <ErrorMessage message={error.stateName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Role</label>
                <br />
                <select
                  value={user.role}
                  name="role"
                  onChange={handleChange("role")}
                  className={`${error.role && "error-input-z"}`}
                >
                  <option value="">select</option>
                  {allRoles.map((item) =>(
                    <option
                    value ={item.name}
                    key={item.id}
                    >{item.name}</option>
                  ))}
                </select>
                {error.role && <ErrorMessage message={error.role} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Branch Code</label>
                <br />
                <select
                  value={user.branchCode}
                  name="branchCode"
                  onChange={handleChange("branchCode")}
                  className={`${error.branchCode && "error-input-z"}`}
                >
                  <option value="Select a branch">Select a branch</option>
                  {allBranches.map((branch) => (
                    <option
                      value={branch.code}
                      key={branch.id}
                    >{`${branch.name} - ${branch.code}`}</option>
                  ))}
                </select>
                {error.branchCode && (
                  <ErrorMessage message={error.branchCode} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Home Branch</label>
                <input type="text" value={homeBranchUX} disabled />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Gender</label>
                <br />
                <select
                  value={user.gender}
                  name="gender"
                  onChange={handleChange("gender")}
                  className={`${error.gender && "error-input-z"}`}
                >
                  <option value="">select</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
                {error.gender && <ErrorMessage message={error.gender} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Password</label>
                <input
                  type="password"
                  value={user.password}
                  name="password"
                  onChange={handleChange("password")}
                  className={`${error.password && "error-input-z"}`}
                />
                {error.password && <ErrorMessage message={error.password} />}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Email</label>
                <input
                  type="email"
                  value={user.emailAddress}
                  name="emailAddress"
                  onChange={handleChange("emailAddress")}
                  className={`${error.emailAddress && "error-input-z"}`}
                />
                {error.emailAddress && (
                  <ErrorMessage message={error.emailAddress} />
                )}
                <br />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  value={user.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange("confirmPassword")}
                  className={`${error.confirmPassword && "error-input-z"}`}
                />
                {error.confirmPassword && (
                  <ErrorMessage message={error.confirmPassword} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} >
              <div className='checkboxdiv flex--2'>
                <div className='label-input flex--2'>
                  <label>Loan Officer</label>
                  <input  
                  checked={user.isLoanOfficer}
                  name="isLoanOfficer"
                  type="checkbox"
                  className="inputcheck"
                  onChange={handleChange("isLoanOfficer")}
                />
                </div>
                <div className='label-input flex--2'>
                  <label>Account Officer</label>
                  <input
                    checked={user.isAccountOfficer}
                    name="isAccountOfficer"
                    type="checkbox"
                    className="inputcheck"
                    onChange={handleChange("isAccountOfficer")}
                    
                    />
                </div>
              </div>
          </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>
            </Grid>
            <button className="save" onClick={handleSave}>
              Save
            </button>
           
          </>
      </Paper>
    </main>
    {!loading && (
        <Table
          title="User Creation"
          subtitle=""
          name="List of Users"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details}/>
      {details && details.id && 
        <EditForm
          details={details}
          errorEdit={errorEdit}
          countries={countries}
          states={states}
          allBranches={allBranches}
          allRoles={allRoles}
          handleEditSubmit={handleEditSubmit}
          handleEditChange={handleEditChange}
        />
     }
    </div>
  );
};

export default UserCreation;

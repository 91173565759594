import axios from 'axios';
import { Routes } from '../../configUrl';



const getAllUserTellerTill = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllUserTellerTill,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };
  const createUserTellerTill = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createUserTellerTill,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err.response)
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const updateUserTellerTill = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateUserTellerTill + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const deleteUserTellerTill = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteUserTellerTill + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };
  const authorizeUserTellerTill = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeUserTellerTill + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };


  const getHandler = async (token, url) => {
    return await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

export {
    getAllUserTellerTill,
    createUserTellerTill,
    updateUserTellerTill,
    deleteUserTellerTill,
    authorizeUserTellerTill,
    getHandler,
}

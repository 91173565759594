import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DetailsContainer from "../../../DatagridTable/view";
import {
  isLoading,
  openViewModal,
} from "../../../../Api/redux-manager/actions"; 
import {updateLoanRepayment} from "../../../../Api/Hooks-manager/loanAccountOperations";
import RepaymetDetailsContainer from "../../../DatagridTable/editloanscheduleDetails";
import Details from "../../../Details/LoanAccount/LoanScheduleDetails";
import LoanScheduleDetails from "../../../Details/LoanAccount/LoanScheduleDetails";
import CustomizedSnackbars from "../../../../Confirmbox/Snackbar";

const RepaymentSchedule = ({
  open,
  onClose,
  details,
  repaymentShedule,
  onEditedScheduleSave,
  loanAccountNum,
}) => {
  const [scheduleEdit, setScheduleEdit] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState({
    status: "",
    message: "",
  });
  const [editedSchedule, setEditedSchedule] = useState(
    repaymentShedule.map((row) => ({ ...row }))
  );

  const [totals, setTotals] = useState({
    repaymentPrincipal: 0,
    repaymentInterest: 0,
    repaymentAmount: 0,
  });
  const [errors, setErrors] = useState(Array(repaymentShedule.length).fill({}));

  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);



  useEffect(() => {
    // Calculate Totals
    const schedule = scheduleEdit ? editedSchedule : repaymentShedule;
    const newTotals = schedule.reduce(
      (previous, next) => {
        previous.repaymentAmount = parseFloat(
          parseFloat(previous.repaymentAmount + next.repaymentAmount).toFixed(2)
        );
        previous.repaymentInterest = parseFloat(
          parseFloat(
            previous.repaymentInterest + next.repaymentInterest
          ).toFixed(2)
        );
        previous.repaymentPrincipal = parseFloat(
          parseFloat(
            previous.repaymentPrincipal + next.repaymentPrincipal
          ).toFixed(2)
        );
        return previous;
      },
      {
        repaymentPrincipal: 0.0,
        repaymentInterest: 0.0,
        repaymentAmount: 0.0,
      }
    );
    setTotals(newTotals);
  }, [repaymentShedule, editedSchedule, scheduleEdit]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const clearScheduleError = () => {
    setErrors(Array(repaymentShedule.length).fill({}));
  };

  const updateData= {
    repaymentDetails: editedSchedule.map((item) =>{
      return{
        sequenceId: item.sequenceId,
        repaymentAmount: item.repaymentAmount,
        repaymentInterest: item.repaymentInterest,
        
      }
    }),
    loanAccountNo: loanAccountNum,
  }


  const updateLoanSchedule = async () =>{
    dispatch(isLoading())
    const response = await updateLoanRepayment(token, updateData);
    if(response && response.data){
      dispatch(isLoading())
      setSnackBarContent({
        status: "success",
        message: response.data,
      });
      setSnackBarOpen(true);
      setEditedSchedule(editedSchedule);
    }else{
      setSnackBarContent({
        status: "error",
        message: "Loan schedule update failed!",
      });
      setSnackBarOpen(true);
    }
  }

  const onScheduleSave = () => {
    // let errorExist = validateRepaymentSchedule();
      onEditedScheduleSave(editedSchedule);
      setScheduleEdit(false);
      updateLoanSchedule();
  };

  const validateRepaymentSchedule = () => {
    let errorCount = 0;
    const newErrors = repaymentShedule.map(() => ({}));
    // check totals for errors
    const editedTotals = editedSchedule.reduce(
      (previous, next) => {
        previous.repaymentAmount = parseFloat(
          (previous.repaymentAmount + next.repaymentAmount).toFixed(2)
        );
        previous.repaymentInterest = parseFloat(
          parseFloat(
            previous.repaymentInterest + next.repaymentInterest
          ).toFixed(2)
        );
        previous.repaymentPrincipal = parseFloat(
          parseFloat(
            previous.repaymentPrincipal + next.repaymentPrincipal
          ).toFixed(2)
        );
        return previous;
      },
      {
        repaymentPrincipal: 0.0,
        repaymentInterest: 0.0,
        repaymentAmount: 0.0,
      }
    );

    const totalsError = {};
    if (editedTotals.repaymentPrincipal !== totals.repaymentPrincipal) {
      totalsError.repaymentPrincipal = "Invalid principal total";
      errorCount += 1;
    }
    if (editedTotals.repaymentInterest !== totals.repaymentInterest) {
      totalsError.repaymentInterest = "Invalid Intrest total";
      errorCount += 1;
    }
    newErrors.push(totalsError);
    setErrors(newErrors);
    const validationStatus = Boolean(errorCount); // True if Validation fails
    if (validationStatus) {
      setSnackBarContent({
        status: "error",
        message: "Schedule Validation Failed",
      });
      setSnackBarOpen(true);
    } else {
      setSnackBarContent({
        status: "success",
        message: "Validation successful",
      });
      setSnackBarOpen(true);
    }
    return validationStatus;
  };

  const toggleScheduleEdit = (value) => {
    clearScheduleError();
    setScheduleEdit(value);
  };

  const onScheduleReset = () => {
    setEditedSchedule(repaymentShedule.map((row) => ({ ...row })));
    clearScheduleError();
  };

  const onScheduleChange = (field, index, value) => {
    const newSchedule = [...editedSchedule];
    console.log(newSchedule);
    newSchedule[index][field] = parseFloat(value || 0); // Updated Schedule is an array of objects
    if (field === "repaymentInterest" || field === "repaymentPrincipal") {
      newSchedule[index]["repaymentAmount"] =
        newSchedule[index].repaymentPrincipal +
        newSchedule[index].repaymentInterest;
    }
    // if(field === "repaymentInterest"){
    //   editedSchedule[index]["repaymentAmount"] = newSchedule[index].repaymentInterest;
    // }
    setEditedSchedule(newSchedule);
    if (errors[index][field]) {
      const updatedErrors = [...errors];
      updatedErrors[index][field] = "";
      setErrors(updatedErrors);
    }
  };

  return (
    <RepaymetDetailsContainer
      title="Repayments Schedule - Record Details"
      question=""
      open={open || false}
      handleClose={onClose}
      edit={scheduleEdit}
      onScheduleSave={onScheduleSave}
    >
      <LoanScheduleDetails
        details={details}
        repaymentShedule={repaymentShedule}
        editedSchedule={editedSchedule}
        edit={scheduleEdit}
        setEdit={toggleScheduleEdit}
        onScheduleReset={onScheduleReset}
        onScheduleChange={onScheduleChange}
        onScheduleValidate={validateRepaymentSchedule}
        errors={errors}
        totals={totals}
      />
      <CustomizedSnackbars
        handleClose={handleCloseSnackBar}
        message={snackBarContent.message}
        status={snackBarContent.status}
        open={snackBarOpen}
      />
    </RepaymetDetailsContainer>
  );
};

export default RepaymentSchedule;

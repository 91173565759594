import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import Item from "../../DatagridTable/item";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { numberFormatter } from "../../../formatter";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
  root: {},
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexDirection:"row",
  },
  item:{
    width:'100%',
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  }
}));


const InvestmentProducts = ({details}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [details]);
    return (
      <section className={classes.root}>
        <div style={{marginTop:50, padding:25}}>
          <>
            <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Product Details (1)"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Product Details (2)"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />
             
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2} className={classes.root}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Product Code" name={details.productCode} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Product Name" name={details.productName} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Currency" name={details.currency} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Investment Status" name={details.productStatus ? 'Active': 'Inactive'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Default Inv. Amount" name={numberFormatter(details.defaultInvAmount)} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Minimum Inv. Amount" name={numberFormatter(details.minimumInvAmount)} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Maximum Inv. Amount" name={numberFormatter(details.maximumInvAmount)} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Rate Chart Code" name={details.rateChatCode || 'Nil'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Default Tenor" name={details.defaultTenor} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Minimum Tenor" name={details.minimumTenor} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Tenor Basis" name={details.maximumTenor} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Rate Type" name={details.tenorBasis} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Default Interest Rate"
                      name={details.defaultInterestRate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Minimum Interest Rate"
                      name={details.minimumInterestRate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Maximum Interest Rate"
                      name={details.maximumInterestRate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Rate Basis" name={details.rateBasis} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Minimum Variance" name={details.minimumVariance} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Maximum Variance" name={details.maximumVariance} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Minimum Interest Rate"
                      name={details.minimumInterestRate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Item
                      label="Pre Liquidation Penalty Rate"
                      name={details.preLiquidationPenaltyRate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Accrual Required"
                      name={details.accrualRequired ? "Yes":"no"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Premature Liq Allowed"
                      name={details.prematureLiqAllowed ? "Yes":"no"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Automatic Rollover"
                      name={details.automaticRollover ? "Yes":"no"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Apply Tax"
                      name={details.applyTax ? "Yes":"no"}
                    />
                  </Grid>

              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={1} className={classes.root}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Penalty On Premature Liq"
                      name={details.penaltyOnPrematureLiq ? "Yes":"no"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Accrual Period"
                      name={details.accrualPeriod}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Interest Payable Account"
                      name={details.interestPayableAccount}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Balance Basis"
                      name={details.balanceBasis}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Penal Income"
                      name={details.penalIncome}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Interest Expense"
                      name={details.interestExpense}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="At Maturity"
                      name={details.atMaturity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Unclaimed Interest Account"
                      name={details.unclaimedInterestAccount}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Withholding Tax Account"
                      name={details.withHoldingTaxAccount}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Interest Calculation Period"
                      name={details.interestCalculationPeriod}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Interest Period"
                      name={details.interestPeriod}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Item
                      label="Allow Prem. Liq Only After (Days)"
                      name={details.allowPrematureLiqOnlyAfter}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Created Date Time" name={details.createdDateTime} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Updated Date Time" name={details.updatedDateTime} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Date Authorized" name={details.dateAuthorized} />
                  </Grid>
                  {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Reason" name={details.reason} />
                  </Grid>}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Status"
                      name={
                        details.authorizationStatus === "PendingEditedAuthorization"
                          ? "Pending Edited Authorization"
                          : details.authorizationStatus
                      }
                    />
                  </Grid>
              </Grid>
            </TabPanel>

            </div>
          </>

        </div>
      </section>
    )
}

export default InvestmentProducts

import axios from 'axios';
import { Routes } from '../configUrl';

const getCountries = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllCountries,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };

  const getStates = async (token, countryId) => {
    return await axios({
        method: "get",
        url: `${Routes.getAllStates}${countryId}`,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };

  const getAllStatesByCountryCode = async (token, countryCode) => {
    return await axios({
        method: "get",
        url: `${Routes.getAllStatesByCountryCode}${countryCode}`,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };


export {
    getCountries,
    getStates,
    getAllStatesByCountryCode
}
import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from '../../../Details/SecurityManagement/UserCreation';

const View = ({ details }) => {
  const data = {
    userId: details.id,
    action: details.inActive ? "activate" : "deactivate",
  };
  return (
    <DetailsContainer
      title="User Creation - Record Details"
      btnTitle={details.inActive ? "Activate" : "De-activate"}
      question={`Are you sure you want to ${
        details.inActive ? "Activate" : "De-activate"
      } this user?`}
      url={Routes.activateAndDectivateUser}
      method="post"
      data={data}
    >
     <Details details={details}/> 
    </DetailsContainer>
  );
};

export default View;

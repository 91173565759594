import * as actionTypes from "../constants";
import { updatedObject } from "../utilty";

const initialState = {
  loading: false,
  openForm: false,
  openDeleteDecisionModal: false,
  openDeleteResponseModal: false,
  open: false,
  view: false,
  openResponseModal: false,
  status: false,
  message: "",
  updateTable: "",
};

const isLoading = (state, action) => {
  return updatedObject(state, {
    loading: !state.loading,
  });
};

const openFormModal = (state, action) => {
  return updatedObject(state, { openForm: true });
};

const closeFormModal = (state, action) => {
  return updatedObject(state, { openForm: false });
};

const openViewModal = (state, action) => {
  return updatedObject(state, { view: true });
};

const closeViewModal = (state, action) => {
  return updatedObject(state, { view: false });
};

const openResquestDecisionModal = (state, action) => {
  return updatedObject(state, { open: true });
};

const closeResquestDecisionModal = (state, action) => {
  return updatedObject(state, { open: false });
};

const authFormStart = (state, action) => {
  return updatedObject(state, {
    open: false,
    loading: true,
  });
};

const authFormSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    openResponseModal: true,
    status: action.status,
    message: action.message,
    updateTable: Math.random().toString(36),
  });
};

const authFormFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    openResponseModal: true,
    status: action.status,
    message: action.message,
  });
};

const closeResquestResponseModal = (state, action) => {
  return updatedObject(state, { openResponseModal: false });
};

const openDeleteDecisionModal = (state, action) => {
  return updatedObject(state, { openDeleteDecisionModal: true });
};

const closeDeleteDecisionModal = (state, action) => {
  return updatedObject(state, { openDeleteDecisionModal: false });
};

const deleteItemStart = (state, action) => {
  return updatedObject(state, {
    openDeleteDecisionModal: false,
    loading: true,
  });
};

const deleteItemSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    openDeleteResponseModal: true,
    view: false,
    status: action.status,
    message: action.message,
    updateTable: Math.random().toString(36),
  });
};

const deleteItemFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    openDeleteResponseModal: true,
    status: action.status,
    message: action.message,
  });
};

const closeDeleteResponseModal = (state, action) => {
  return updatedObject(state, { openDeleteResponseModal: false });
};

const req = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return isLoading(state, action);
    case actionTypes.OPEN_VIEW_MODAL:
      return openViewModal(state, action);
    case actionTypes.CLOSE_VIEW_MODAL:
      return closeViewModal(state, action);
    case actionTypes.OPEN_UPDATE_FORM_MODAL:
      return openFormModal(state, action);
    case actionTypes.CLOSE_UPDATE_FORM_MODAL:
      return closeFormModal(state, action);
    case actionTypes.OPEN_REQUEST_MODAL:
      return openResquestDecisionModal(state, action);
    case actionTypes.CLOSE_REQUEST_MODAL:
      return closeResquestDecisionModal(state, action);
    case actionTypes.MAKE_REQUEST_START:
      return authFormStart(state, action);
    case actionTypes.MAKE_REQUEST_SUCCESS:
      return authFormSuccess(state, action);
    case actionTypes.MAKE_REQUEST_FAIL:
      return authFormFail(state, action);
    case actionTypes.CLOSE_REQUEST_RESPONSE_MODAL:
      return closeResquestResponseModal(state, action);
    case actionTypes.OPEN_DELETE_DECISION_MODAL:
      return openDeleteDecisionModal(state, action);
    case actionTypes.CLOSE_DELETE_DECISION_MODAL:
      return closeDeleteDecisionModal(state, action);
    case actionTypes.CLOSE_DELETE_RESPONSE_MODAL:
      return closeDeleteResponseModal(state, action);
    case actionTypes.DELETE_ITEM_START:
      return deleteItemStart(state, action);
    case actionTypes.DELETE_ITEM_SUCCESS:
      return deleteItemSuccess(state, action);
    case actionTypes.DELETE_ITEM_FAIL:
      return deleteItemFail(state, action);
    default:
      return state;
  }
};

export default req;

import axios from 'axios';
import { Routes } from '../../configUrl';



const getAllTellerTill = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllTellerTill,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };
  
  const createTellerTill = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createTellerTill,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAUserTillDetails = async (token, userid) => {
    return await axios({
      method: "get",
      url: Routes.getCurrentUserTill + userid,
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data : res.data.data
      }
    })
    .catch((err) => {
      console.log(err)
      return {
        data: [{}],
      };
    })

  }

  const getAUserCashBalance = async (token, userid) => {
    return await axios({
      method: "get",
      url: Routes.getCurrentUserCashBalance + userid,
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data : res.data.data
      }
    })
    .catch((err) => {
      console.log(err)
      return {
        data: [{}],
      };
    })

  }

  const updateTellerTill = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateTellerTill + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const deleteTellerTill = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteTellerTill + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };
  const authorizeTellerTill = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeTellerTill + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };



  const getHandler = async (token, url) => {
    return await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };
  // const authorizeTellerTill = async (token,body,id) => {
  //   return await axios({
  //       method: "put",
  //       data: body,
  //       url: Routes.authorizeTellerTill + id,
  //       headers: {
  //         "Content-Type":  "application/json",
  //         'Authorization': `Bearer: ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       return {
  //         status: res.data.status,
  //         message: res.data.responseMessage,
  //       };
  //     })
  //     .catch((err) => {
  //       return {
  //         status: err.response.data.status,
  //         message: err.response.data.responseMessage,
  //       };
  //     });
  // };
export {
    getAllTellerTill,
    createTellerTill,
    updateTellerTill,
    deleteTellerTill,
    authorizeTellerTill,
    getHandler,
    getAUserTillDetails,
    getAUserCashBalance,
}

import React, { useState, useEffect, useCallback } from "react";
import "./FiscalYear.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { fiscalYear } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Table from "../../../DatagridTable/table";
import EditForm from "./edit";
import { formattedDate } from "../../../../formatter/date";
import { baseUrl } from "../../../../Api/configUrl";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import Details from "./view";

const FiscalYear = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const loading = useSelector(state => state.req.loading);
  const [details, setDetails] = useState({});
  const updateTable = useSelector(state => state.req.updateTable);

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [years, setFiscalYear] = useState([]);
  const [error, setError] = useState({});
  const [errorEdit, setErrorEdit] = useState({});

  const [data, setData] = useState({
    year: "",
    startdate: "",
    enddate: "",
    status: true,
  });

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "year",
      label: "Fiscal Year",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startingdate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "endingdate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}{" "}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = years[dataIndex];
                console.log(singleItem);
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = years[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllCreatedFiscalYear = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}organization/FiscalYear/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].startingdate = formattedDate(
            response.data[i].startdate
          );
          response.data[i].endingdate = formattedDate(response.data[i].enddate);
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setFiscalYear(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllCreatedFiscalYear();
  }, [fetchAllCreatedFiscalYear, updateTable]);

  const handleChange = name => e => {
    const { value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleEditChange = name => e => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const validateFiscalYear = () => {
    const { year, startdate, enddate } = data;
    const error = {};
    if (year.length < 6 || year.length > 9) {
      error.year = "Length is between 6-10 characters";
    }
    if (startdate === "") {
      error.startdate = "Provide a valid start date";
    }
    if (enddate === "") {
      error.enddate = "Provide a valid end date";
    }
    return error;
  };

  const validateEditFiscalYear = () => {
    const { year, startdate, enddate } = details;
    const error = {};
    if (year.length < 6 || year.length > 9) {
      error.year = "Length is between 6-10 characters";
    }
    if (startdate === "") {
      error.startdate = "Provide a valid start date";
    }
    if (enddate === "") {
      error.enddate = "Provide a valid end date";
    }
    return error;
  };

  const EmptyFieldsFiscalYear = () => {
    setData({
      year: "",
      startdate: "",
      enddate: "",
      status: true,
    });
  };

  const postResult = async () => {
    dispatch(isLoading());
    const response = await fiscalYear(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.message === "Success") {
        EmptyFieldsFiscalYear();
        fetchAllCreatedFiscalYear();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSubmit = () => {
    const checkError = validateFiscalYear();
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;
    setError({});
    setOpen(true);
  };

  const handleEditSubmit = () => {
    const checkError = validateEditFiscalYear();
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;
    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };

  return (
    <div>
      <div className="fiscal--year">
        <Confirm
          ApiCall={postResult}
          openBox={open}
          setOpenBoxState={setOpen}
          errorMessage={message}
        />
        <Spinner loading={loading} />
        <div className="heading">
          <p>Fiscal Year</p>
        </div>
        <Paper style={{ padding: 32 }}>
          <section className="section--1">
            <div className="sub--section grid--1">
              <div className="label--input flex--3">
                <label>Fiscal Year</label>
                <input
                  className={error.year ? "error-input-z" : null}
                  type="text"
                  value={data.year}
                  onChange={handleChange("year")}
                />
                {error.year && <ErrorMessage message={error.year} />}
              </div>
              <div className="label--input flex--3">
                <label>Start Date</label>
                <input
                  className={error.startdate ? "error-input-z" : null}
                  type="date"
                  value={data.startdate}
                  onChange={handleChange("startdate")}
                />
                {error.startdate && <ErrorMessage message={error.startdate} />}
              </div>
              <div className="label--input flex--3">
                <label>End Date</label>
                <input
                  className={error.enddate ? "error-input-z" : null}
                  type="date"
                  value={data.enddate}
                  onChange={handleChange("enddate")}
                />
                {error.enddate && <ErrorMessage message={error.enddate} />}
              </div>
            </div>
            <div className="button">
              <button onClick={handleSubmit}>Save</button>
            </div>
          </section>
        </Paper>
      </div>
      {!loading && (
        <Table
          title="Fiscal Year"
          subtitle=""
          name="List of Fiscal Year"
          records={years}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorEdit}
          handleEditChange={handleEditChange}
          handleEditSubmit={handleEditSubmit}
        />
      )}
    </div>
  );
};
export default FiscalYear;

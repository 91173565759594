import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from '../../../Details/InvestmentAccount/InvestmentLiquidation';
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { Routes as routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { numberFormatter } from "../../../../formatter";


const InvestmentLiquidation = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "investmentAccountNumber",
      label: "Investment Acc. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerAccountNumber",
      label: "Customer Acc. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "liquidationType",
      label: "Liquidation Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "liquidationAmount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{numberFormatter(value)}</p>
      },
    },
    {
      name: "investmentBalance",
      label: "Balance",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{numberFormatter(value)}</p>
      },
    },
    {
      name: "createdDateTime",
      label: "Date Liquidated",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date) => <p>{formattedDate(date)}</p>
      },
    },
    // {
    //   name: "bookDate",
    //   label: "Book Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "maturityDate",
    //   label: "Maturity Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllUnauthorizedLiquidation = useCallback(async () => {
    dispatch(loading());
    const url = routes.getUnauthtorizedLiquidations;
    const res = await getHandler(token, url);
    dispatch(loading());
    if (res.data) {
      const parsedData = res.data.map((data, index) => {
        data['sn'] = index + 1
        data['investmentAccountNumber'] = data.investmentBooking.investmentAccountNumber
        data['customerAccountNumber'] = data.investmentBooking.customerAccountNumber
        return data
      })
      setData(parsedData);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllUnauthorizedLiquidation();
  }, [fetchAllUnauthorizedLiquidation, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Investment Liquidation"
        subtitle=""
        name="List Of Unauthorized Investments Liquidations"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer
        title="Investment Liquidation - Record Details"
      >
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize investment"
        question="Are you sure you want to authorize this liquidation?"
        url={`${routes.authorizeLiquidationById}${details.id}`}
        rejectUrl={`${routes.rejectLiquidationById}${details.id}`}
      />
    </div>
  );
};

export default InvestmentLiquidation;

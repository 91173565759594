import { useState, useEffect, useCallback } from "react";
import "./ChartofAccount.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../Api/Hooks-manager/coreModules/useCustom";
import Spinner from "../../../Backdrop/index";
import Confirm from "../../../../Confirmbox/Confirm";
import { validateChartOfAccount } from "../../../../Api/validator/validate11";
import { RiErrorWarningFill } from "react-icons/ri";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { getAllBranches } from "../../../../Api/Hooks-manager/coreModules/coreModules";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  openDetailsModal,
  openFormModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";


const ChartofAccount = () => {
  const {
    handleGLAccountCreate,
    valuesCreate,
    allGlAccount,
    getGLAccountGetAll,
    getBankParametersAll,
    allbankparameters,
    setValuesCreate,
    handleChange,
    loader,
  } = useForm();
  const [accountType, setAccountType] = useState([]);
  const [accountHierarchy, setAccountHierarchy] = useState([]);
  const [errors, setErrors] = useState({});
  const [allBranches, setAllBranches] = useState([]);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const [errorEdit, setErrorEdit] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const [actionAccount, setActionAccount] = useState(null);
  const openGLReplicationModal = useSelector(state => state.approve.openForm);

  const token = useSelector(state => state.common.userDetails.jwtToken);
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "glType",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "glHierarchy",
      label: "Account Hierarchy",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                if(singleItem && singleItem.id){
                  setActionAccount(singleItem)
                  dispatch(openFormModal())
                }

              }}
            >
              Replicate GL
            </MenuItem> */}
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };


  const fetchAllBranches = useCallback(async () => {
    const response = await getAllBranches(token);
    if (response && response.data) {
      setAllBranches(response.data);
    }
  }, [token]);

  const fetchAllGLAccount = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}account/GLAccount/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllBranches();
  }, [fetchAllBranches]);

  // useEffect(() => {
  //   ReplicateAGL();
  // }, []);

  useEffect(() => {
    fetchAllGLAccount();
  }, [fetchAllGLAccount, updateTable]);

  const handleAccountTypes = e => {
    setValuesCreate({ ...valuesCreate, productAccount: e });
  };

  const handleisGLActive = e => {
    setValuesCreate({ ...valuesCreate, isGLActive: e });
  };

  const handleReconType = e => {
    setValuesCreate({ ...valuesCreate, reckonType: e.target.value });
  };

  useEffect(() => {}, [allGlAccount]);

  const handleEditChange = name => e => {
    const { value, checked } = e.target;
    let input = value;
    if (name === "productAccount" && value === "true") {
      input = true;
    }
    if (name === "accountName" && value === "true") {
      input = true;
    }
    if (name === "accountNumber" && value === "true") {
      input = true;
    }
    if (name === "productAccount" && value === "false") {
      input = false;
    }
    if (name === "isGLActive" && value === "true") {
      input = true;
    }
    if (name === "isGLActive" && value === "false") {
      input = false;
    }
    if (name === "glType") {
      getGLAccountGetAll(value);
    }
    setDetails({
      ...details,
      [name]: input,
    });
  };

  const validateUpdateGlAccount = values => {
    let errors = {};

    if (values.accountNumber.length !== 9) {
      errors.accountNumber = "Field needs to be 9 characters";
    } else if (values.accountNumber.includes(" ")) {
      errors.accountNumber = "No spaces are allowed";
    }

    if (values.accountName === "") {
      errors.accountName = "Field is required";
    }

    if (values.productAccount === "") {
      errors.productAccount = "Select an option";
    }

    if (values.glHierarchy !== "Head") {
      if (values.parentGLID === "") {
        errors.parentGLID = "Select an option";
      }
    }

    if (values.glType === "") {
      errors.glType = "Select an option";
    }

    if (values.glHierarchy === "") {
      errors.glHierarchy = "Select an option";
    }

    if (values.isGLActive === "") {
      errors.isGLActive = "Select an option";
    }

    if (values.entityType === "") {
      errors.entityType = "Select an option";
    }

    if (values.branchCode === "") {
      errors.branchCode = "Select an option";
    }

    return errors;
  };


  useEffect(() => {
    let accType = [];
    let accHierarchy = [];
    accType = allbankparameters.filter(
      paramterType => paramterType.parameterName === "COA_Type"
    );
    accHierarchy = allbankparameters.filter(
      paramterType => paramterType.parameterName === "COA_Hierarchy"
    );

    setAccountType(accType);
    setAccountHierarchy(accHierarchy);
  }, [allbankparameters, allGlAccount]);

  const [openBox, setOpenBox] = useState(false);
  useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);

  const handleConfirmUpdate = () => {
    const checkError = validateUpdateGlAccount(details);
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;
    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };


  const handleConfirm = () => {
    setErrors(validateChartOfAccount(valuesCreate));
    if (Object.keys(validateChartOfAccount(valuesCreate)).length === 0) {
      setOpenBox(true);
    } else {
    }
  };

  return (
    <div>
      <div className="chartof--account">
        <div style={{ position: "absolute" }}>
          <Spinner loading={loader || loading} />
        </div>
        <Confirm
          ApiCall={() => handleGLAccountCreate(fetchAllGLAccount)}
          openBox={openBox}
          setOpenBoxState={setOpenBox}
        />
        <div className="headerdiv">
          <p>Chart of Accounts</p>
        </div>
        <Paper style={{ padding: 32 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <label>Ledger Account Code</label>
              <br />
              <div className="error-container-z">
                <input
                  type="text"
                  onChange={handleChange}
                  name="accountNumber"
                  value={valuesCreate.accountNumber}
                  maxLength="9"
                  className={`${
                    Object.keys(errors).includes("accountNumber") &&
                    "error-input-z"
                  }`}
                />
                {errors.accountNumber && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.accountNumber}</small>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Ledger Account Name</label>
              <br />
              <div className="error-container-z">
                <input
                  type="text"
                  value={valuesCreate.accountName}
                  name="accountName"
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("accountName") &&
                    "error-input-z"
                  }`}
                />
                {errors.accountName && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.accountName}</small>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Account Type</label>
              <br />
              <div className="error-container-z">
                <select
                  value={valuesCreate.glType}
                  name="glType"
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("glType") && "error-input-z"
                  }`}
                >
                  
                  <option>Please, Select An Option</option>
                  {accountType.map((parameters, index) => {
                    return (
                      <option key={index} value={parameters.parameterValue}>
                        {parameters.parameterValue}
                      </option>
                    );
                  })}
                </select>
                
                {errors.glType && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.glType}</small>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Account Hierarchy</label>
              <br />
              <div className="error-container-z">
                <select
                  value={valuesCreate.glHierarchy}
                  name="glHierarchy"
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("glHierarchy") &&
                    "error-input-z"
                  }`}
                >
                  <option>Please, Select An Option</option>
                  {accountHierarchy.map((hierarchy, index) => {
                    return (
                      <option key={index} value={hierarchy.parameterValue}>
                        {hierarchy.parameterValue}
                      </option>
                    );
                  })}
                </select>
                {errors.glHierarchy && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.glHierarchy}</small>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Parent Ledger</label>
              <div className="error-container-z">
                <select
                  value={valuesCreate.parentGLID}
                  name="parentGLID"
                  onChange={handleChange}
                  className={`${
                    valuesCreate.glHierarchy === "Head" && "input-disable"
                  } ${
                    Object.keys(errors).includes("parentGLID") &&
                    "error-input-z"
                  }`}
                >
                  <option>Please, Select An Option</option>
                  {allGlAccount.map((item, index) => {
                    if (item.glHierarchy !== "Child" && item.glType === valuesCreate.glType) {

                      return (
                        <option key={index} value={item.accountNumber}>
                          {`${item.accountNumber} - ${item.accountName}`}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
                {errors.parentGLID && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.parentGLID}</small>
                  </div>
                )}
              </div>
              <br />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Parent Branch Code</label>
              <div className="error-container-z">
                <select
                  value={valuesCreate.branchCode}
                  name="branchCode"
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("branchCode") &&
                    "error-input-z"
                  }`}
                >
                  <option>Select branch</option>
                  {allBranches.map(item => (
                    <option
                      value={item.code}
                      key={item.id}
                    >{`${item.name} - ${item.code}`}</option>
                  ))}
                </select>
                {errors.branchCode && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.branchCode}</small>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              
              md={6}
              className="error-container-z select--z"
            >
              <label>Product Account</label>
              <div
                className={`status ${
                  Object.keys(errors).includes("productAccount") &&
                  "error--border"
                }`}
              >
                <div className="entry-type flex--2">
                  <div className="label-input flex--2">
                    <label>Yes</label>
                    <input
                      value="true"
                      name="productAccount"
                      type="checkbox"
                      checked={valuesCreate.productAccount === true}
                      onChange={e => handleAccountTypes(true)}
                    />
                  </div>
                  <div className="label-input flex--2">
                    <label>No</label>
                    <input
                      value="false"
                      name="productAccount"
                      type="checkbox"
                      checked={valuesCreate.productAccount === false}
                      onChange={e => handleAccountTypes(false)}
                    />
                  </div>
                </div>
              </div>
              {errors.productAccount && (
                <div className="flex--2 error--p">
                  <RiErrorWarningFill className="icon" />
                  <small>{errors.productAccount}</small>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="error-container-z select-z-1"
            >
              <label>Entry Type</label>
              <div
                className={`entry-type entry-type--1 flex--2 ${
                  Object.keys(errors).includes("glType") && "error--border"
                }`}
              >
                <div className="label-input flex--2">
                  <label>Automatic</label>
                  <input
                    value="Automatic"
                    name="entityType"
                    type="checkbox"
                    checked={valuesCreate.entityType === "Automatic"}
                    onChange={handleChange}
                  />
                </div>
                <div className="label-input flex--2">
                  <label>Manual</label>
                  <input
                    value="Manual"
                    name="entityType"
                    type="checkbox"
                    checked={valuesCreate.entityType === "Manual"}
                    onChange={handleChange}
                  />
                </div>
                <div className="label-input flex--2">
                  <label>Both</label>
                  <input
                    value="Both"
                    name="entityType"
                    type="checkbox"
                    checked={valuesCreate.entityType === "Both"}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {errors.entityType && (
                <div className="flex--2 error--p">
                  <RiErrorWarningFill className="icon" />
                  <small>{errors.entityType}</small>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="error-container-z select--z"
            >
              <label>Status</label>
              <div
                className={`status ${
                  Object.keys(errors).includes("isGLActive") && "error--border"
                }`}
              >
                <div className="entry-type flex--2">
                  <div className="label-input flex--2">
                    <label>Active</label>
                    <input
                      value="Active"
                      name="isGLActive"
                      type="checkbox"
                      checked={valuesCreate.isGLActive === true}
                      onChange={e => handleisGLActive(true)}
                    />
                  </div>
                  <div className="label-input flex--2">
                    <label>Inactive</label>
                    <input
                      value="Inactive"
                      name="isGLActive"
                      type="checkbox"
                      checked={valuesCreate.isGLActive === false}
                      onChange={e => handleisGLActive(false)}
                    />
                  </div>
                </div>
              </div>
              {errors.isGLActive && (
                <div className="flex--2 error--p">
                  <RiErrorWarningFill className="icon" />
                  <small>{errors.isGLActive}</small>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="error-container-z select--z"
            >
              <label>Recon Type</label>
              <div className={`recon-type`}>
                <div className={`entry-type flex--2`}>
                  <div className="label-input flex--2">
                    <label>Debit Reconciliation</label>
                    <input
                      value="Debit Reconciliation"
                      name="reckonType"
                      type="checkbox"
                      checked={
                        valuesCreate.reckonType === "Debit Reconciliation"
                      }
                      onChange={handleReconType}
                    />
                  </div>
                  <div className="label-input flex--2">
                    <label>Credit Reconciliation</label>
                    <input
                      value="Credit Reconciliation"
                      name="reckonType"
                      type="checkbox"
                      checked={
                        valuesCreate.reckonType === "Credit Reconciliation"
                      }
                      onChange={handleReconType}
                    />
                  </div>
                  <div className="label-input flex--2">
                    <label>None</label>
                    <input
                      value="None"
                      name="reckonType"
                      type="checkbox"
                      checked={valuesCreate.reckonType === "None"}
                      onChange={handleReconType}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="savebtn flex--2">
            <button className="save" onClick={handleConfirm}>
              Save
            </button>
          </div>
        </Paper>
      </div>
      {!loading && (
        <Table
          title="Chart of Account"
          subtitle=""
          name="Gl accounts"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleConfirmUpdate}
          details={details}
          handleChange={handleEditChange}
          handleisGLActive={handleisGLActive}
          handleAccountTypes={handleAccountTypes}
          errors={errorEdit}
          handleReconType={handleReconType}
          allGlAccount={allGlAccount}
          accountType={accountType}
          accountHierarchy={accountHierarchy}
          allBranches={allBranches}
        />
      )}
    </div>
  );
};

export default ChartofAccount;

import { useState, useEffect, useCallback } from "react";
import { Grid} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import Item from "../../../DatagridTable/item";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";


const PortfolioMaintainance = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portfolioCode",
      label: "Portfolio Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portfolioType",
      label: "Portfolio Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerBranch",
      label: "Customer Branch",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "statementFrequency",
      label: "Statement Frequency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllportfolioMaintainance = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}treasury/PortfolioMaintenance/GetAll`;
    const allResponse = await getHandler(token, url);
    dispatch(loading());
    const response = allResponse.data.filter(
      (item) =>
        item.authorizationStatus === "Pending" ||
        item.authorizationStatus === "PendingEditedAuthorization"
    );
    if (response.length > 0){
      for (let i = 0; i < response.length; i++) {
        response[i].sn = i + 1;
        response[i].createdDateTime = formattedDate(response[i].createdDateTime);
        response[i].dateAuthorized = formattedDate(response[i].dateAuthorized);
        response[i].updatedDateTime = formattedDate(response[i].updatedDateTime);
      };
    }
      setData(response);
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllportfolioMaintainance();
  }, [fetchAllportfolioMaintainance, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Treasury Portfolio Maintainance"
        subtitle=""
        name="List of Portfolios"
        records={data}
        options={options}
        columns={columns}
      />}
      <Details
        title="Treasury Portfolio Maintainance - Record Details"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Portfolio Code" name={details.portfolioCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Portfolio Type" name={details.portfolioType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Customer Name" name={details.customerName} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Customer Branch" name={details.customerBranch} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Statement Frequency" name={details.statementFrequency} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Currency" name={details.currency} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
          </Grid>
      </Details>
      <AuthorizationModal
        title="Authorize Portfolio"
        question="Are you sure you want to authorize this Portfolio?"
        url={`${baseUrl}treasury/PortfolioMaintenance/Authorize/${details.id}`}
      />
    </div>
  );
};

export default PortfolioMaintainance;

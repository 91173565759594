import { useState } from "react";
import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from '../../../Details/SecurityManagement/ManageRoles'



const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Manage Roles - Record Details"
      question="Are you sure you want to delete this role?"
      url={`${Routes.deleteARole}${details.id}`}
    >
      <Details details={details}/>
    </DetailsContainer>
  );
};

export default View;

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import Item from "../../DatagridTable/item";

const useStyles = makeStyles((theme) => ({
    root: {
      // display:"flex",
      // alignItems:"center",
      // flexDirection:"row"
    },
    text: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.secondary.main,
    },
    hr: {display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "black",
      width: "100%",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    noData: {
      
      flexDirection: "row",
    },
    noData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    item: {
      width: "100%",
    },
  }));
  

const ManageRoles = ({details}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Role Name" name={details.name} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Description" name={details.description} />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Role Permissions" name={details.rolePermissions} />
          </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Status"
            name={
              details.authorizationStatus === "PendingEditedAuthorization"
                ? "Pending Edited Authorization"
                : details.authorizationStatus
            }
          />
        </Grid>
        <Divider className={classes.hr} />
        <Grid item xs={12}>
          <h6 className={classes.text}>Permissions</h6>
        </Grid>
        {details && details.permissionArray && details.permissionArray.length > 0 ? (
          details.permissionArray.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
              <Item label="" name={item.parameter} className={classes.item} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={classes.noData}>
            <Item label="" name="No permmision" className={classes.item} />
          </Grid>
        )}
      </Grid>

    )
}

export default ManageRoles

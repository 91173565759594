import { useState, useCallback, useEffect } from "react";
import "./GuarantorsInformations.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
// import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
// import ErrorMessage from "../../../../ErrorMessage";
import {
  getCbrReports,
} from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { useDispatch } from "react-redux";
// import moment from "moment";
// import { format } from "date-fns";

import { isLoading } from "../../../../../Api/redux-manager/actions";

const GuarantorsInformation = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  // const branch = useSelector((state) => state.common.branch.branch);
  // const applicationDate = format(new Date(branch.workdate), "yyyy-MM-dd");

  // const [cbrReport, setCbrReport] = useState({
  //   startDate: applicationDate,
  //   endDate: "",
  // });
  const [allCbrReports, setAllCbrReports] = useState(null);
  // const [errors, setErrors] = useState({});

  const columns = [
    
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanaccount",
      label: "Loan Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountnumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "typeofGuarantee",
      label: "Type Of Guarantee",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nameofCorporateGuarantor",
      label: "Name Of Corporate Guarantor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "bizIDNumberofCorporateGuarantor",
      label: " Corporate Guarantor Business ID. ",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "individualGuarantorSurname",
      label: "Individual Guarantor Surname",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "individualGuarantorFirstName",
      label: "Individual Guarantor FirstName",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "individualGuarantorMiddleName",
      label:  "Individual Guarantor MiddleName",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "guarantorsDateofBirthIncorporation",
      label: "Guarantors DOB. Incorporation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsGender",
      label: "Guarantors Gender",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsNationalID",
      label: "Guarantors National Id ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsIntlPassportNumber",
      label: "Guarantors Intl PassportNo. ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsDriversLicenceNumber",
      label: "Guarantors Drivers Licence No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "guarantorsBVN",
      label: "Guarantors BVN",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsOtherID",
      label: "Unpaid Loan Fees ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "guarantorsPrimaryAddressLine1",
      label: "Loan Arrears",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "guarantoraddress",
      label: "Paid Principal",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsPrimaryAddressCityLGA",
      label: "Due Interest",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantorsPrimaryState",
      label: "Paid Interest",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:"guarantorsPrimaryCountry",
      label: "Last Repayment Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "guarantorsPrimaryPhoneNumber",
      label: "guarantors Prim. No.",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "guarantorsEmailAddress",
      label:"Guarantors Email Address",
      options: {
        filter: true,
        sort: false, 
      },
    },
    
   
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  // const handleChange = (name) => (e) => {
  //   const { value } = e.target;
  //   setErrors((prev) => ({
  //     ...prev,
  //     [name]: undefined,
  //   }));
  //   setCbrReport({
  //     ...cbrReport,
  //     [name]: value,
  //   });
  // };
  // const resetHandlers = () => {
  //   EmptyField();
  //   setAllCbrReports(false);
  // };

  // const EmptyField = () => {
  //   setCbrReport({
  //     branchCode: "",
  //     startDate: "",
  //     endDate: "",
  //   });
  // };

  // const handleSearch = () => {
  //   const checkErrors = validateData();
  //   if (checkErrors || loading) {
  //     fetchCbrReports();
  //     console.log("i am livid");
  //   }
  //   console.log("i am believer");
  // };

  // const validateData = () => {
  //   const error = {};
  //   let errorCount = 0;
  //   const { startDate, endDate,  } = cbrReport;
  //   if (startDate === "") {
  //     error.startDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }
  //   if (endDate === "") {
  //     error.endDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }
   
  //   setErrors(error);
  //   if (errorCount) {
  //     return false;
  //   }
  //   return true;
  // };
  const fetchCbrReports = useCallback(async () => {
    dispatch(isLoading());
    const response = await getCbrReports(token);
    if (response && response.data.guarantors) {
      dispatch(isLoading());
      if (response && response.data.guarantors.length > 0) {
        for (let i = 0; i < response.data.guarantors.length; i++) {
          response.data.guarantors[i].sn = i + 1;
        }
      }
      setAllCbrReports(response.data.guarantors);
    }
  }, [token, dispatch]);
 
  useEffect(() => {
    fetchCbrReports();
  }, [fetchCbrReports]);


  return <div className="guarantorsinformation border--main">

<div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Guarantors Information</p>
      </div>
      {/* <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="From"
              value={cbrReport.startDate}
              onChange={handleChange("startDate")}
            ></input>
            {errors.startDate && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input
              name="To"
              type="date"
              value={cbrReport.endDate}
              onChange={handleChange("endDate")}
            ></input>
            {errors.endDate && <ErrorMessage message={errors.endDate} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSearch}>Search</button>
          <button onClick={resetHandlers}>Reset</button>
        </div>
        <hr /> */}
        {allCbrReports && (
          <div>
            <Spinner loading={loading} />
            <Table
              title="Guarantor's Information"
              subtitle=""
              name="List Of Guarantor's Information"
              records={allCbrReports}
              options={options}
              columns={columns}
            />
          </div>
        )}
      {/* </main> */}
  </div>;
};

export default GuarantorsInformation;

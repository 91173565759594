import { useState, useCallback,useEffect } from "react";
import "./LoanRepayment.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../ErrorMessage";
import { formattedDate } from "../../../../../formatter/date"
import { getAllLoanRepaymentReport, getBranchCode } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../../Api/redux-manager/actions";

const LoanRepaymentReport = () => {
  // const [loader, setLoader] = useState(false);
  // const [view, setView] = useState({});
  // const [openView, setOpenView] = useState(false);
  const dispatch = useDispatch(isLoading());
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const [branchesCode, setBranchesCode] = useState([])

  const [repayment, setRepayment] = useState({
    startDate: "",
    endDate: "",
    branchCode: "",
  });
  const [repaymentReport, setRepaymentReport] = useState(null);

  const [errors, setErrors] = useState("");

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccountNo",
      label: "Loan Accnt. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanProduct",
      label: "Loan Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "repaymentAccountNo",
      label: "Repayment Accnt. No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanCurrency",
      label: " Loan Currency",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "bookedDate",
      label: "Loan Book Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "startDate",
      label: "Loan Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "loanAmount",
      label: "Loan Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amountDisbursed",
      label: "Amount Disbursed",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tenor",
      label: "Tenor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "totalPrincipalRepaymentAmount",
      label: "Principal Repayment Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "totalInterestRepaymentAmount",
      label: "Interest Repayment Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "totalRepaymentAmount",
      label: "Total Repayment Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastRepaymentDate",
      label: "Repayment Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "nextRepaymentDate",
      label: " Next Repayment Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const handleSubmit = () => {
    const checkErrors = validateData();
    if (checkErrors) {
      fetchRepaymentReport();
      console.log("i am livid");
    }
    console.log("i am believer");
  };
  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { startDate, endDate, branchCode } = repayment;
    if (startDate === "") {
      error.startDate = "Field is required";
      errorCount = errorCount + 1;
    }
    if (endDate === "") {
      error.endDate = "Field is required";
      errorCount = errorCount + 1;
    }
    if (branchCode === "") {
      error.branchCode = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };
  const handleChanges = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setRepayment({
      ...repayment,
      [name]: value,
    });
  };
  const resetHandler = () => {
    EmptyField();
    setRepaymentReport(false);
  };

  const EmptyField = () => {
    setRepayment({
      branchCode: "",
      startDate: "",
      endDate: "",
    });
  };

  const fetchBranchCode = useCallback(async () => {
    const response = await getBranchCode(token,branchesCode);
    if (response && response.data){
        setBranchesCode(response.data)
      }  
  }, [token,branchesCode])
  useEffect(() => {
    fetchBranchCode();
  }, [])

  const fetchRepaymentReport = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllLoanRepaymentReport(token, repayment);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setRepaymentReport(response.data);
    }
  }, [repayment, token, dispatch]);

  // const fetchAccountDetails = async (accNo, action) => {
  //   dispatch(isLoading());
  //   const response = await getCustomerByAccountNumber(token, accNo);
  //   if (response && response.data) {
  //     dispatch(isLoading());
  //     if (response.status) {
  //       setDetails(response.data);
  //       dispatch(action());
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const fetchAllAccountEnquiry = async () => {
  //     dispatch(isLoading());
  //     const url = `${baseUrl}account/CustomerAccount/GetAll`;
  //     const response = await getHandler(token, url);
  //     if (response && response.data) {
  //       console.log("data is here",response.data)
  //       dispatch(isLoading());
  //       console.log(response.data);
  //       if (response.data.length > 0) {
  //         for (let i = 0; i < response.data.length; i++) {
  //           response.data[i].sn = i + 1;
  //         }
  //       }
  //       setData(response.data);
  //     }
  //   };
  //   fetchAllAccountEnquiry();
  // }, [dispatch, token, updateTable]);

  return (
    <div className="LoanRepayment border--main">
      <div style={{ position: "absolute" }}>
      <Spinner loading={loading} />

      </div>
      <div className="headerdiv">
        <p>Reports | Loan Repayment Report</p>
      </div>

      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Branch Code</label>
            <select
              type="text"
              name="branchCode"
              value={repayment.branchCode}
              onChange={handleChanges("branchCode")}
            >bn
               <option >Select a branch code</option>
              {branchesCode.map((codes,i) => (
                <option value={codes.code} key={i}>{codes.code}</option>
              ))}
            </select>
            {errors.branchCode && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={repayment.startDate}
              onChange={handleChanges("startDate")}
            ></input>
            {errors.startDate && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input
              type="date"
              name="endDate"
              value={repayment.endDate}
              onChange={handleChanges("endDate")}
            ></input>
            {errors.endDate && <ErrorMessage message={errors.endDate} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSubmit}>Search</button>
          <button onClick={resetHandler}>Reset</button>
        </div>
      <hr />
      {repaymentReport  && (
        <div>
          <Spinner loading={loading} />
          <Table
            title="Loan Repayment Report"
            subtitle=""
            name="List of Reports"
            records={repaymentReport}
            options={options}
            columns={columns}
          />
        </div>
      )}
            </main>

    </div>
  );
};

export default LoanRepaymentReport;

import { combineReducers } from "redux";
import {
  LoginUsers,
  LoaderSwitch,
} from "./onBoard";
import common from './common';
import approve from './approve';
import req from './crud';

const appReducer = combineReducers({
  LoginUsers,
  LoaderSwitch,
  common,
  req,
  approve
});

export default appReducer;

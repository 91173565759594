import React from 'react'
import './TillManagement.scss'

const TillManagement = () => {
    return (
        <main className='till-management border--main'>
            <div className='heading'>
                <p>Till Management</p> 
            </div>
            <section className='section--1'>
                <div className='img-description flex--1'>
                    <img alt='' src='werftg'/>
                    <div className='flex--3'>
                        <h3>Close Batch</h3>
                        <p>Using this option, the teller batch can be opened on a specified date. A teller can initiate the teller batch with the current cash position that has been retained from the previous date or start with zero cash and subsequently buy cash from the fault.</p>
                    </div>
                </div>
                <div className='img-description flex--1'>
                    <img alt='' src='werftg'/>
                    <div className='flex--3'>
                        <h3>Cash Balancing</h3>
                        <p>Using this option, the teller batch can be opened on a specified date. A teller can initiate the teller batch with the current cash position that has been retained from the previous date or start with zero cash and subsequently buy cash from the fault.</p>
                    </div>
                </div>
                <div className='img-description flex--1'>
                    <img alt='' src='werftg'/>
                    <div className='flex--3'>
                        <h3>Close Batch</h3>
                        <p>Using this option, the teller batch can be opened on a specified date. A teller can initiate the teller batch with the current cash position that has been retained from the previous date or start with zero cash and subsequently buy cash from the fault.</p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default TillManagement

import { makeStyles, Grid } from "@material-ui/core";
import Item from "../../DatagridTable/item";
import Moment from "react-moment";
import { formattedDate } from "../../../formatter/date";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    textAlign: "center", padding: "1.5rem 0px"
  },
  rateChartRanges: {
    border: "1px solid black",
    marginBottom: "3rem",
    padding: "2rem",
  },
}));

const RateChart = ({ details }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Rate Code" name={details.code} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Rate Description" name={details.description} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Rate Chart Basis" name={details.basis} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Tenor Basis" name={details.tenorBasis || "Nil"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Status"
            name={
              details.authorizationStatus === "PendingEditedAuthorization"
                ? "Pending Edited Authorization"
                : details.authorizationStatus
            }
          />
        </Grid>
      </Grid>
      <h6 className={classes.text}>
        Rate Charts
      </h6>
      <div style={{ paddingBottom: "1.5rem" }}>
        {details &&
          details.rateChartRanges &&
          details.rateChartRanges.map((el, i) => (
            <Grid
              container
              spacing={2}
              key={i}
              className={classes.rateChartRanges}
            >
              {details.basis === "Tenor" && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Tenor From" name={el.tenorFrom} />
                </Grid>
              )}
              {details.basis === "Tenor" && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Tenor To" name={el.tenorTo} />
                </Grid>
              )}
              {details.basis === "Amount" && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Amount From" name={el.amountFrom} />
                </Grid>
              )}
              {details.basis === "Amount" && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Amount To" name={el.amountTo} />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item label="Rate" name={el.rate} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item label="Rate Basis" name={el.rateBasis || "Nil"} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Effective Date"
                  name={
                    <Moment format="DD MMM YYYY">{el.effectiveDate}</Moment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Updated Date Time"
                  name={formattedDate(el.updatedDateTime)}
                />
              </Grid>
            </Grid>
          ))}
      </div>
    </div>
  );
};

export default RateChart;

import { RiErrorWarningFill } from "react-icons/ri";
import Grid from "@material-ui/core/Grid";

import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleChange,
  errors,
  accountHierarchy,
  allGlAccount,
  accountType,
  details,
  allBranches,
}) => {

  console.log(details, "edit details")
  return (
    <EditModal
      title="Update Chart of Account"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}account/GLAccount/Update/${details.id}`}
    >
      <Grid container spacing={1} className="chartof--account">
        <Grid item xs={12} sm={6} md={6}>
          <label>Ledger Account Code</label>
          <br />
          <div className="error-container-z">
            <input
              type="text"
              onChange={handleChange}
              name="accountNumber"
              value={details.accountNumber}
              className={`input-disable`}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Ledger Account Name</label>
          <br />
          <div className="error-container-z">
            <input
              type="text"
              value={details.accountName}
              name="accountName"
              onChange={handleChange("accountName")}
              className={`${errors.accountName && "error-input-z"}`}
            />
            {errors.accountName && (
              <div className="flex--2 error--p">
                <RiErrorWarningFill className="icon" />
                <small>{errors.accountName}</small>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Account Type</label>
          <br />
          <div className="error-container-z">
            <select
              // name="glType"
              disabled
              onChange={handleChange("glType")}
              value={details.glType}
              className={`${errors.glType && "error-input-z"}`}
            >
              <option>select an option</option>
              {accountType &&
                accountType.map((parameters, index) => {
                  return (
                    <option key={index} value={parameters.parameterValue}>
                      {parameters.parameterValue}
                    </option>
                  );
                })}
            </select>
            {errors.glType && (
              <div className="flex--2 error--p">
                <RiErrorWarningFill className="icon" />
                <small>{errors.glType}</small>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Account Hierarchy</label>
          <br />
          <div className="error-container-z">
            <select
              value={details.glHierarchy}
              disabled
              name="glHierarchy"
              onChange={handleChange}
              className={`${errors.glHierarchy && "error-input-z"}`}
            >
              <option>Please, Select An Option</option>
              {accountHierarchy &&
                accountHierarchy.map((hierarchy, index) => {
                  return (
                    <option key={index} value={hierarchy.parameterValue}>
                      {hierarchy.parameterValue}
                    </option>
                  );
                })}
            </select>
            {errors.glHierarchy && (
              <div className="flex--2 error--p">
                <RiErrorWarningFill className="icon" />
                <small>{errors.glHierarchy}</small>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Parent Ledger</label>
          <select
            // name="parentGLID"
            disabled
            value={details.parentGLID}
            onChange={handleChange("parentGLID")}
            className={`${errors.parentGLID && "error-input-z"}`}
          >
            <option>Please, Select An Option</option>
            {allGlAccount &&
              allGlAccount.filter((item) => {
                return(details.accountNumber !== item.accountNumber)
              })
              .map((item, index) => {
                if (item.glHierarchy !== "Child" && item.glType === details.glType){
                  return (
                    <option key={index} value={item.accountNumber}>
                      {`${item.accountNumber} - ${item.accountName}`}
                    </option>
                  );

                }
                return null;
              })}
          </select>
          <br />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <label>Parent Branch Code</label>
          <select
            // name="parentGLID"
            value={details.branchCode}
            disabled
            onChange={handleChange("branchCode")}
            className={`${errors.branchCode && "error-input-z"}`}
          >
            <option>Please, Select An Option</option>
            {allBranches.map(item => (
                    <option
                      value={item.code}
                      key={item.id}
                    >{`${item.name} - ${item.code}`}</option>
                  ))}
                {errors.branchCode && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.branchCode}</small>
                  </div>
                )}
          </select>
          <br />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className="error-container-z select--z"
        >
          <label>Product Account</label>
          <div className={`status ${errors.glType && "error--border"}`}>
            <div className="entry-type flex--2">
              <div className="label-input flex--2">
                <label>Yes</label>
                <input
                  value="true"
                  name="productAccount"
                  type="checkbox"
                  checked={details.productAccount === true}
                  onChange={handleChange ("productAccount")}
                />
              </div>
              <div className="label-input flex--2">
                <label>No</label>
                <input
                  value="false"
                  name="productAccount"
                  type="checkbox"
                  checked={details.productAccount === false}
                  onChange={handleChange("productAccount")}
                />
              </div>
            </div>
          </div>
          {errors.productAccount && (
            <div className="flex--2 error--p">
              <RiErrorWarningFill className="icon" />
              <small>{errors.productAccount}</small>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className="error-container-z select-z-1"
        >
          <label>Entry Type</label>
          <div
            className={`entry-type flex--2 ${
              errors.entityType && "error--border"
            }`}
          >
            <div className="label-input flex--2">
              <label>Automatic</label>
              <input
                value="Automatic"
                name="entityType"
                type="checkbox"
                checked={details.entityType === "Automatic"}
                onChange={handleChange("Automatic")}
              />
            </div>
            <div className="label-input flex--2">
              <label>Manual</label>
              <input
                value="Manual"
                name="entityType"
                type="checkbox"
                checked={details.entityType === "Manual"}
                onChange={handleChange("Manual")}
              />
            </div>
            <div className="label-input flex--2">
              <label>Both</label>
              <input
                value="Both"
                name="entityType"
                type="checkbox"
                checked={details.entityType === "Both"}
                onChange={handleChange("Both")}
              />
            </div>
          </div>
          {errors.entityType && (
            <div className="flex--2 error--p">
              <RiErrorWarningFill className="icon" />
              <small>{errors.entityType}</small>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className="error-container-z select--z"
        >
          <label>Status</label>
          <div className={`status ${errors.glType && "error--border"}`}>
            <div className="entry-type flex--2">
              <div className="label-input flex--2">
                <label>Active</label>
                <input
                  value="true"
                  name="isGLActive"
                  type="checkbox"
                  checked={details.isGLActive === true}
                  onChange={handleChange("isGLActive")}
                />
              </div>
              <div className="label-input flex--2">
                <label>Inactive</label>
                <input
                  value="false"
                  name="isGLActive"
                  type="checkbox"
                  checked={details.isGLActive === false}
                  onChange={handleChange("isGLActive")}
                />
              </div>
            </div>
          </div>
          {errors.isGLActive && (
            <div className="flex--2 error--p">
              <RiErrorWarningFill className="icon" />
              <small>{errors.isGLActive}</small>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className="error-container-z select--z"
        >
          <label>Recon Type</label>
          <div className={`recon-type`}>
            <div className={`entry-type flex--2`}>
              <div className="label-input flex--2">
                <label>Debit Reconciliation</label>
                <input
                  value="Debit Reconciliation"
                  name="reckonType"
                  type="checkbox"
                  checked={details.reckonType === "Debit Reconciliation"}
                  onChange={handleChange}
                />
              </div>
              <div className="label-input flex--2">
                <label>Credit Reconciliation</label>
                <input
                  value="Credit Reconciliation"
                  name="reckonType"
                  type="checkbox"
                  checked={details.reckonType === "Credit Reconciliation"}
                  onChange={handleChange}
                />
              </div>
              <div className="label-input flex--2">
                <label>None</label>
                <input
                  value="None"
                  name="reckonType"
                  type="checkbox"
                  checked={details.reckonType === "None"}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </EditModal>
  );
};

export default EditData;

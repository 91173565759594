import Moment from "react-moment";
import { Grid } from "@material-ui/core";
import Item from "../../DatagridTable/item";

const BranchDefinition = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Branch Name" name={details.name} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Branch Address" name={details.parentBranchId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Country" name={details.countryId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="LGA" name={details.lga} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Parent Branch Code" name={details.parentBranchId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="State" name={details.stateId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Institution Code" name={details.code} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Suspense Ledger Account" name={details.suspenseLedgerAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Vault Account" name={details.vaultAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Work  Date" name={<Moment format="D MMM YYYY">{details.workdate}</Moment>} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Start Time" name={details.startTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="End Time" name={details.endTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>

    )
}

export default BranchDefinition

import React from 'react'
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import CashDeposit from './CashDeposit/CashDeposit';
import CashWithdrawal from './CashWithdrawal/CashWithdrawal';
import ChequeTransfer from './ChequeTransfer/ChequeTransfer';
import DebitAndCreditTellerTills from "./DebitandCredittellertills/debitcredittellertills"




const Main = () => { 
    return (
        <div className='main'>
            <Switch> 
                <Route path='/dashboard/cashtransactions-lcy/001'component={CashDeposit}/>
                <Route path='/dashboard/cashtransactions-lcy/002'component={CashWithdrawal}/>
                <Route path='/dashboard/cashtransactions-lcy/004'component={ChequeTransfer}/>
                <Route path='/dashboard/cashtransactions-lcy/005'component={DebitAndCreditTellerTills}/>

            </Switch>
        </div>
    )
}

export default Main

import { LOADER_OFF, LOADER_ON, LOGIN_ATTEMPT, LOGOUT_USER } from '../constants'



  const initialLoginState = {
    login: false
  }

  export const LoginUsers = (state=initialLoginState, action={}) => {
    switch (action.type) {
      case LOGIN_ATTEMPT:
        return {...state, login: true}
      case LOGOUT_USER:
        return {...state, login: false}
      default:
        return state
    }
  }


  const initialLoaderState = {
    loader: false
  }

  export const LoaderSwitch = (state=initialLoaderState, action={}) => {
    switch (action.type) {
      case LOADER_ON:
        return {...state, loader: true}
      case LOADER_OFF:
        return {...state, loader: false}
      default:
        return state
    }
  }




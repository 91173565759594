import Item from "../../DatagridTable/item";
import { Grid } from '@material-ui/core';

const CustomerCategory = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Category Code" name={details.category} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Category Description" name={details.name} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Category Type" name={details.type} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Status"
              name={
                details.authorizationStatus === "PendingEditedAuthorization"
                  ? "Pending Edited Authorization"
                  : details.authorizationStatus
              }
            />
          </Grid>
        </Grid>
    )
}

export default CustomerCategory

import React, { useState } from "react";
import "./ChequeTransfer.scss";
import ErrorMessage from "../../../ErrorMessage";
import NumberFormat from "react-number-format";

const ChequeTransfer = () => {
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    debitAccountNumber: "",
    debitAccountCurrency: "",
    creditAccountCurrency: "",
    creditAccountNumber: "",
    accountProduct: "",
    customerBvn: "",
    chequeIssueDate: "",
    chequeAmount: "",
    accountAmount: "",
    accountBranch: "",
    chequeNumber: "",
    narration: "",
    controlNumber: "",
  });

  const handleChanger = (e) => {
    console.log("e.target.value", e.target.value);
    console.log("e.target.name", e.target.name);
    //   const { name, value } = e.target;
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSave = () => {
    let checkError = isValidateForm(user);
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
  };
  const isValidateForm = (user) => {
    let errors = {};

    if (!user.debitAccountNumber.trim()) {
      errors.debitAccountNumber = "Field is required";
    }
    if (!user.debitAccountCurrency.trim()) {
      errors.debitAccountCurrency = "Field is required";
    }
    if (!user.customerBvn.trim()) {
      errors.customerBvn = "Field is required";
    }
    if (!user.creditAccountCurrency.trim()) {
      errors.creditAccountCurrency = "Field is required";
    }
    if (!user.creditAccountNumber.trim()) {
      errors.creditAccountNumber = "Field is required";
    }
    if (!user.accountProduct.trim()) {
      errors.accountProduct = "Field is required";
    }
    if (!user.controlNumber.trim()) {
      errors.controlNumber = "Field is required";
    }
    if (!user.chequeIssueDate.trim()) {
      errors.chequeIssueDate = "Field is required";
    }
    if (!user.chequeAmount.trim()) {
      errors.chequeAmount = "Field is required";
    }
    if (!user.narration.trim()) {
      errors.narration = "Field is required";
    }
    if (!user.accountAmount.trim()) {
      errors.accountAmount = "Field is required";
    }
    if (!user.accountBranch.trim()) {
      errors.accountBranch = "Field is required";
    }
    if (!user.chequeNumber.trim()) {
      errors.chequeNumber = "Field is required";
    }
    return errors;
  };

  return (
    <main className="chequeTransfer">
      <section className="section--1">
        <div className="heading">
          <p>Cheque Transfer - Foreign Currency</p>
        </div>
        <div className="flex--3">
          <>
            <section className="sub-section">
              <div className="flex--1 container-main">
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Debit Account Number</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="debitAccountNumber"
                        value={user.debitAccountNumber}
                        className={`${
                          error.debitAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        className={`${
                          error.debitAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                  </div>
                  {error.debitAccountNumber && (
                    <ErrorMessage message={error.debitAccountNumber} />
                  )}
                  <div className="flex--3">
                    <label>Customer BVN</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="customerBvn"
                      value={user.customerBvn}
                      className={`${error.customerBvn && "error-input-z"}`}
                    />
                  </div>
                  {error.customerBvn && (
                    <ErrorMessage message={error.customerBvn} />
                  )}
                  <div className="flex--3">
                    <label>Cheque Issue Date</label>
                    <input
                      type="date"
                      onChange={handleChanger}
                      name="chequeIssueDate"
                      value={user.chequeIssueDate}
                      className={`${error.chequeIssueDate && "error-input-z"}`}
                    />
                    {error.chequeIssueDate && (
                      <ErrorMessage message={error.chequeIssueDate} />
                    )}
                  </div>
                  <div className="flex--3">
                    <label>Cheque Amount</label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="chequeAmount"
                      value={user.chequeAmount}
                      className={`${error.chequeAmount && "error-input-z"}`}
                    />
                    
                    {error.chequeAmount && (
                      <ErrorMessage message={error.chequeAmount} />
                    )}
                  </div>
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Credit Account Number</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="creditAccountNumber"
                        value={user.creditAccountNumber}
                        className={`${
                          error.creditAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        className={`${
                          error.creditAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                  </div>
                  {error.creditAccountNumber && (
                    <ErrorMessage message={error.creditAccountNumber} />
                  )}
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Account Product</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="accountProduct"
                        value={user.accountProduct}
                        className={`${error.accountProduct && "error-input-z"}`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        className={`${error.accountProduct && "error-input-z"}`}
                      />
                    </div>
                  </div>
                  {error.accountProduct && (
                    <ErrorMessage message={error.accountProduct} />
                  )}
                </div>
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Debit Account Currency</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="debitAccountCurrency"
                        value={user.debitAccountCurrency}
                        className={`${
                          error.debitAccountCurrency && "error-input-z"
                        }`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        className={`${
                          error.debitAccountCurrency && "error-input-z"
                        }`}
                      />
                    </div>
                  </div>
                  {error.debitAccountCurrency && (
                    <ErrorMessage message={error.debitAccountCurrency} />
                  )}
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Cheque Number</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name=" chequeNumber"
                        value={user.chequeNumber}
                        className={`${error.chequeNumber && "error-input-z"}`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        className={`${error.chequeNumber && "error-input-z"}`}
                      />
                    </div>
                  </div>
                  {error.chequeNumber && (
                    <ErrorMessage message={error.chequeNumber} />
                  )}
                  <div className="flex--3">
                    <label>Control Number</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="controlNumber"
                      value={user.controlNumber}
                      className={`${error.controlNumber && "error-input-z"}`}
                    />
                    {error.controlNumber && (
                      <ErrorMessage message={error.controlNumber} />
                    )}
                  </div>
                  <div className="flex--3">
                    <label>Account Amount</label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="accountAmount"
                      value={user.accountAmount}
                      className={`${error.accountAmount && "error-input-z"}`}
                    />
                    {error.accountAmount && (
                      <ErrorMessage message={error.accountAmount} />
                    )}
                  </div>
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Account Branch</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name=" accountBranch"
                        value={user.accountBranch}
                        className={`${error.accountBranch && "error-input-z"}`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        className={`${error.accountBranch && "error-input-z"}`}
                      />
                    </div>
                  </div>
                  {error.accountBranch && (
                    <ErrorMessage message={error.accountBranch} />
                  )}
                  <div className="flex--3">
                    <label>Credit Account Currency</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="creditAccountCurrency"
                      value={user.creditAccountCurrency}
                      className={`${
                        error.creditAccountCurrency && "error-input-z"
                      }`}
                    />
                    {error.creditAccountCurrency && (
                      <ErrorMessage message={error.creditAccountCurrency} />
                    )}
                  </div>
                </div>
              </div>
              <div className="last-input">
              <label>Narration</label>
                <input
                 
                  type="text"
                  onChange={handleChanger}
                  name="narration"
                  value={user.narration}
                  className={`${error.narration && "error-input-z"}`}
                />
                {error.narration && <ErrorMessage message={error.narration} />}
              </div>
            </section>

            <div className="button-last">
              <button onClick={handleSave}>Save</button>
            </div>
          </>
        </div>
      </section>
    </main>
  );
};

export default ChequeTransfer;

import Loader from '../../../assets/loaderio.svg'

const MiniLoader = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src={Loader} alt=''/>
        </div>
    )
}

export default MiniLoader

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Details from '../../../Details/LoanAccount/LoanDisbursement'
import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/loanAccountOperations";
import Spinner from "../../../Backdrop";

import {
  openDetailsModal,
  loading,
} from "../../../../Api/redux-manager/actions";
import { Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogContent-root":{
      display:"flex"
    }
  },
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  item: {
    width: "100%",
  },
}));


const LoanBooking = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccountNo",
      label: "Loan Account No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditAccount",
      label: "Credit Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "disbursementMethod",
      label: "Method",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "disbursementReferenceNo",
      label: "Reference No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        console.log(item);
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };

  const fetchAllLoanDisbursements = useCallback(async () => {
    dispatch(loading());
    const response = await getHandler(token, Routes.getAllUnauthorizedLoanDisbursement);
    if (response && response.data) {
      dispatch(loading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].dateAuthorized = formattedDate(
          response.data[i].dateAuthorized
        );
        response.data[i].updatedDateTime = formattedDate(
          response.data[i].updatedDateTime
        );
      };
    }
      setData(response.data);
    }
  }, [dispatch, token]);




  useEffect(() => {
    fetchAllLoanDisbursements();
  }, [fetchAllLoanDisbursements, updateTable]);




  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Loan Disbursement"
        subtitle=""
        name="Lists of loan disbursement"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer title="Loan Disbursement - Record Details" className={classes.root}>
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize Loan disbursement"
        question="Are you sure you want to authorize this loan?"
        url={`${Routes.authorizeLoanDisbursement}${details.id}`}
      />
    </div>
  );
};

export default LoanBooking;

import { useState } from "react";
import "./AccountEnquiry.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useCustom from "../../../../Api/Hooks-manager/accountOpening/useCustom";

import { baseUrl } from "../../../../Api/configUrl";
// import { formattedDate } from "../../../../formatter/date";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { getCustomerByAccountNumber } from "../../../../Api/Hooks-manager/loanAccountOperations";

import DotMenu from "../../../../commons/dotMenu/dotMenu";
import Spinner from "../../../Backdrop/index";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openViewModal,
  isLoading,
  openFormModal,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";
import ErrorMessage from "../../../ErrorMessage";
import FreezeModal from "../../../FreezeModal";
import permissions from "../../Sidebar/permissions";

const AccountEnquiry = () => {
  const dispatch = useDispatch();
  // const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const openFreezeModal = useSelector((state) => state.approve.openForm);
  const openStartContributionModal = useSelector((state) => state.approve.openForm);
  const openAddProductModal = useSelector((state) => state.approve.openForm);

  const { manageCustomerAccount: manageCustomerAccountPermission } =
    permissions();

  const [data, setData] = useState(null);
  const [details, setDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [searchType, setSearchType] = useState("accountNumber");
  const [actionAccount, setActionAccount] = useState(null);
  const [enquiryDetails, setEnquiryDetails] = useState({
    accountNumber: "",
    name: "",
    customerId: "",
  });

  const searchFieldMeta = {
    label:
      searchType === "accountNumber"
        ? "Account Number"
        : searchType === "name"
        ? "Name"
        : searchType === "customerId"
        ? "Customer ID"
        : "",
    name: searchType,
  };

  const { addSignatory, removeSignatory } = useCustom();
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Acc No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productName",
      label: "Account Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "memberId",
      label: "Customer ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountstatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Active"
                  ? "green"
                  : status === "Inactive"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "freezeStatus",
      label: "Freeze Status",
      options: {
        customBodyRender: (status) => {
          const textMap = {
            NoFreeze: "No Freeze",
            CreditFreeze: "Credit Freeze",
            DebitFreeze: "Debit Freeze",
            TotalFreeze: "Total Freeze",
          };
          return (
            <p
              style={{
                color:
                  status === "NoFreeze"
                    ? "green"
                    : status === "TotalFreeze"
                    ? "red"
                    : "rgb(224, 148, 33)",
              }}
            >
              {textMap[status]}
            </p>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                if (singleItem && singleItem.customerId) {
                  fetchAccountDetails(
                    singleItem.accountNumber,
                    openUpdateFormModal
                  );
                }
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                if (singleItem && singleItem.customerId) {
                  fetchAccountDetails(singleItem.accountNumber, openViewModal);
                }
              }}
            >
              View
            </MenuItem>
            {manageCustomerAccountPermission && (
              <MenuItem
                onClick={() => {
                  const singleItem = data[dataIndex];
                  if (singleItem && singleItem.customerId) {
                    setActionAccount(singleItem);
                    dispatch(openFormModal());
                  }
                }}
              >
                Change Freeze Status
              </MenuItem>
            )}
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAccountDetails = async (accNo, action) => {
    dispatch(isLoading());
    const response = await getCustomerByAccountNumber(token, accNo);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setDetails(response.data);
        dispatch(action());
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "searchType") {
      setSearchType(value);
      setEnquiryDetails({
        accountNumber: "",
        name: "",
        customerId: "",
      });
    } else {
      setEnquiryDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
    if (errors[name]) {
      setErrors((prev) => {
        return {
          ...prev,
          [name]: "",
        };
      });
    }
  };

  const validateInput = () => {
    const error = {};

    let errorCount = 0;
    if (
      (enquiryDetails.accountNumber.length !== 10 ||
        isNaN(enquiryDetails.accountNumber)) &&
      !enquiryDetails.name
    ) {
      error.accountNumber = "Please Enter a valid account number";
      errorCount += 1;
    } else if (
      enquiryDetails.customerId !== 8 ||
      isNaN(enquiryDetails.customerId) ||
      enquiryDetails.customerId === ""
    ) {
      error.customerId = "Please enter a valid customer ID";
      errorCount += 1;
    }
    if (errorCount > 0) {
      setErrors(error);
      return false;
    } else return true;
    // return true if data validates successfully
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateInput();
    setErrors({});
    fetchAllAccountEnquiry(
      enquiryDetails.accountNumber,
      enquiryDetails.name,
      enquiryDetails.customerId
    );

    if (isValid) {
    }
    return;
  };

  const fetchAllAccountEnquiry = async (
    accountNumber = "",
    name = "",
    customerId = ""
  ) => {
    dispatch(isLoading());
    const url = `${baseUrl}account/CustomerAccount/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      console.log(response, "response")
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
        let tempData = [];
        if (accountNumber || name || customerId) {
          if (name) {
            const find = response.data.filter((item) =>
              item.accountName.toLowerCase().includes(name.toLowerCase())
            );
            tempData = find;
          } else if (accountNumber) {
            const find = response.data.find((item) => {
              return item.accountNumber === accountNumber;
            });
            find && tempData.push(find);
          } else if (customerId) {
            const find = response.data.filter(
              (item) => item.memberId === customerId
            );
            tempData = find;
          }
        } else {
          tempData = response.data;
        }
        setData(tempData);
      }
    }
  };

  const EmptyField = () => {
    setEnquiryDetails({
      accountNumber: "",
      name: "",
      customerId: "",
    });
  };

  // const clearRecords = () => {
  //   window.location.reload()
  //   // setSearchType("accountNumber")
  // };

  // useEffect(() => {
  //   fetchAllAccountEnquiry();
  // }, [dispatch, token, updateTable]);

  return (
    <div className="account-enquiry">
      <div className="headerdiv">
        <p>Account Enquiry</p>
      </div>
      <Paper
        style={{
          marginTop: 20,
          marginBottom: 50,
          padding: 25,
          boxShadow: "2px 2px 2px 2px white-smoke",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3}>
              <label>Search Type</label>
              <br />
              <select
                name="searchType"
                onChange={handleInputChange}
                value={searchType}
              >
                <option value={"accountNumber"}>Account Number</option>
                <option value={"name"}>Name</option>
                <option value={"customerId"}>Customer ID</option>
              </select>
              <br />
              {errors.accountNumber && (
                <ErrorMessage message={errors.accountNumber} />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <label>{searchFieldMeta.label}</label>
              <br />
              <input
                type="text"
                className={`${
                  errors[searchFieldMeta.name] ? "error-input-z" : null
                }`}
                name={searchType}
                onChange={handleInputChange}
                value={enquiryDetails[searchFieldMeta.name]}
              />{" "}
              <br />
              {(errors.name || errors.accountNumber || errors.customerId) && (
                <ErrorMessage message={errors[searchFieldMeta.name]} />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={3} className="accountEnquiryButtons">
            <button className="search-btn">Search</button>
            {/* <button className="search-btn--2" onclick={clearRecords}>Clear Records</button> */}
          </Grid>
        </form>
      </Paper>

      <div>
        <Spinner loading={loading} />
        {!loading && data && (
          <Table
            title="Account Enquiry"
            subtitle=""
            name="List of Accounts"
            records={data}
            options={options}
            columns={columns}
          />
        )}
        {details && details.id && <Details details={details} />}
        {details && details.id && (
          <EditForm
            details={details}
            removeSignatory={removeSignatory}
            addSignatory={addSignatory}
          />
        )}
        {actionAccount && (
          <FreezeModal
            actionAccountObject={actionAccount}
            actionOnClose={() => {
              setActionAccount({});
            }}
            onSuccess={() => {
              fetchAllAccountEnquiry(
                enquiryDetails.accountNumber,
                enquiryDetails.name,
                enquiryDetails.customerId
              );
            }}
            open={openFreezeModal}
          />
        )}
      </div>
    </div>
  );
};

export default AccountEnquiry;

import React, {useState, useEffect} from 'react';
import useCustom from '../../../../Api/Hooks-manager/investmentAccountOperations/useCustom';
import { add } from 'date-fns';
import { format } from 'date-fns';
import Spinner from '../../../Backdrop/index';
import useCustom3 from '../../../../Api/Hooks-manager/accountOpening/useCustom';
import Confirm from '../../../../Confirmbox/Confirm';
import { validateInvestmentAmendment1 } from '../../../../Api/validator/validate11';
import ErrorMessage from "../../../ErrorMessage";
import NumberFormat from "react-number-format";
import './LoanAmendment.scss'

const Loanamendment = () => {
    const [screenchange, setScreenchange] = useState(false);
    

  const [errors, setErrors] = useState({});
  const [error, setError] = React.useState({})
  const [openBox, setOpenBox] = React.useState(false)


  
  const handleScreenChange = (i) => {
    let checkError = validateInvestmentAmendment1;
     console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
     setError({});
     setScreenchange(i)
  };

  const handleAmendments = () =>{
    let checkError = validateInvestmentAmendment1;
     console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setOpenBox(true);
  }



return (
  <div className="loan--amendment">
  <div style={{ position: 'absolute'}}>
  </div> 
  <div className="headerdiv">
    <p>Loan Amendment </p>
  </div>

    {!screenchange && (
      <>
      <main style={{ padding: 25 }} className='flex--3 border--main'>
        <section className='section--1 flex--4'>
          <div className='flex--3'>
            <label>Loan Account Number</label>
            <input 
              type='text'
              name='investmentNumber'
              maxLength='17'
            />
          </div>
          <div className='flex--3'>
            <label>Account Name</label>
            <input 
              type="text"
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Branch</label>
            <input
              type="text"
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Currency</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Loan Product</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Loan Status</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Loan Amount</label>
            <NumberFormat className='input--amount'
              displayType={'text'} thousandSeparator={true} />
          </div>
          <div className='flex--3'>
            <label>Loan Book Date</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Amendment Eff. Date</label>
            <input 
              disabled
              type="text"
              value={format(new Date(), 'dd/MM/yyyy')}
            />
          </div>
          <div className='tenor flex--3'>
            <label>Tenor (In Days)</label>
            <input 
              type='number' 
              name='Tenor'
            />
          </div>
          <div className='flex--3'>
            <label>Loan Maturity Date</label>
            <input 
              type='text' 
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Loan Balance</label>
            <NumberFormat className='input--amount' 
             />
            </div>
          <div className='flex--3'>
            <label>Additional Loan Amount</label>
            <NumberFormat
                thousandSeparator={true}
                inputMode="numeric"
                renderText={(value) => <div>{value}</div>}
              />
          </div>
          <div className='flex--3'>
            <label>New Loan Amount</label>
            <input 
              disabled
              type="number"
              name="newInvAmount"
              step="any"
            />
          </div>
          <div className='flex--3'>
            <label>New Maturity Date</label>
            <input 
              type='text' 
              disabled

            />
          </div>
        </section>
        <section className='flex--3 section--2'>
          <label>Debited from</label>
          <select disabled>
              <option>Select An Option</option>
              <option value='SameAccount'>Same Account</option>
              <option value='DifferentAccount'>Different Account</option>
          </select>
        </section>
        <section className='flex--4'>
          <div className='flex--3'>
            <label>Account Number</label>
            <input 
              type='text' 
              name='CustomerAccountNumber'
            />
          </div>
          <div className='flex--3'>
            <label>Account Name</label>
            <input 
              type='text' 
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Branch</label>
            <input 
              type="text"
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Currency</label>
            <input 
              type="text"
              disabled
            />
        </div>
        </section>
        <div className='btn-cont'>
          <button className="btnz" onClick={() =>handleScreenChange(true)}>Next</button>
        </div>
      </main>
    </>
    )
    }

    {
      screenchange && 
      <main className='main--2 border--main'>
        <div className='flex--4'>
          <div className='flex--3'>
            <label>Loan Account Number</label>
            <input 
              type="text" 
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Name</label>
            <input 
              type="text"
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Branch</label>
            <input
              type="text"
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>Account Currency</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Loan Product</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Loan Status</label>
            <input 
              disabled
              type="text"
            />
          </div>
          <div className='flex--3'>
            <label>Amendment Eff. Date</label>
            <input 
              disabled
              type="text"
            />
          </div>
          {/* <div className='flex--3'>
            <label>Rate Chart Code</label>
            <input 
              disabled
              type="text"
            />
          </div> */}
          <div className='flex--3'>
            <label>Interest Rate</label>
            <input 
              disabled
              type="number"
              step="any"
            />
          </div>
          <div className='flex--1 check checkers' >
          <input
            type="checkbox"
            checked
          />
            <label className="span">Amend Interest Rate</label>
          </div>
          {/* <div className='flex--3'>
            <label>System Rate</label>
            <input 
              type='number'
              step="any" 
              name='SystemRate'
              disabled
            />
          </div> */}
          {/* <div className='flex--3'>
            <label>Variance</label>
            <input 
              type='number' 
              name='Variance'
            />
          </div> */}
          <div className='flex--3'>
            <label>New Interest Rate</label>
            <input 
              type='number'
              step="any" 
              name='FinalRate'
            />
          </div>
          <div className='flex--3'>
            <label>Maturity Amount</label>
            <input 
              type='number'
              step="any" 
              disabled
            />
          </div>
          <div className='flex--3'>
            <label>New Maturity Amount</label>
            <input type="text" step="any" disabled />
          </div>
        </div>
        <div className='flex--4 button--cont'>
          <button  onClick={() => handleScreenChange(false)} className='btn--1'>Previous</button>
          <button className='btn--2'  >Amend Loan</button>
        </div>
      </main>
    }
  </div>
)
}
export default Loanamendment;
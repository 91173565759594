export const CHANGE_FIELD = 'CHANGE_FIELD';


export const REQUEST_PENDING = 'REQUEST_PENDING';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILED = 'REQUEST_FAILED';


export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGOUT_USER = 'LOGOUT_USER';


export const LOADER_ON = 'LOADER_ON';
export const LOADER_OFF = 'LOADER_OFF';


export const SUCCESS_MSG_ON = 'SUCCESS_MSG_ON';
export const SUCCESS_MSG_OFF = 'SUCCESS_MSG_OFF';
export const ERROR_MSG_ON = 'ERROR_MSG_ON';
export const ERROR_MSG_OFF = 'ERROR_MSG_OFF';

export const GET_ALL_PERMISSIONS_START = 'GET_ALL_PERMISSIONS_START';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAIL = 'GET_ALL_PERMISSIONS_FAIL';
export const GET_ALL_PERMISSIONS_FROM_OBJ = 'GET_ALL_PERMISSIONS_FROM_OBJ';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';

export const GET_BRANCH_START = 'GET_BRANCH_START';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_FAILED = 'GET_BRANCH_FAILED';

export const GET_ALL_COUNTRIES_START = 'GET_ALL_COUNTRIES_START';
export const GET_ALL_COUNTRIES_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_COUNTRIES_FAIL = 'GET_ALL_COUNTRIES_FAIL';
export const GET_ALL_BRANCHES_START = 'GET_ALL_BRANCHES_START';
export const GET_ALL_BRANCHES_SUCCESS = 'GET_ALL_BRANCHES_SUCCESS';
export const GET_ALL_BRANCHES_FAIL = 'GET_ALL_BRANCHES_FAIL';
export const GET_ALL_TELLER_TILLS_START = 'GET_ALL_TELLER_TILLS_START';
export const GET_ALL_TELLER_TILLS_SUCCESS = 'GET_ALL_TELLER_TILLS_SUCCESS';
export const GET_ALL_TELLER_TILLS_FAIL = 'GET_ALL_TELLER_TILLS_FAIL';
export const CLEAR_COMMON = 'CLEAR_COMMON';

export const OPEN_DETAILS_MODAL = 'OPEN_DETAILS_MODAL';
export const CLOSE_DETAILS_MODAL = 'CLOSE_DETAILS_MODAL';

export const LOAD_SPINNER = 'LOAD_SPINNER';
export const OPEN_FORM_DIALOGUE = 'OPEN_FORM_DIALOGUE';
export const CLOSE_FORM_DIALOGUE = 'CLOSE_FORM_DIALOGUE';
export const OPEN_DECISION_MODAL = 'OPEN_DECISION_MODAL';
export const NEW_OPEN_DECISION_MODAL = 'NEW_OPEN_DECISION_MODAL';
export const CLOSE_DECISION_MODAL = 'CLOSE_DECISION_MODAL';

export const APPROVE_FORM_START = 'APPROVE_FORM_START';
export const APPROVE_FORM_SUCCESS = 'APPROVE_FORM_SUCCESS';
export const APPROVE_FORM_FAIL = 'APPROVE_FORM_FAIL';
export const CLOSE_RESPONSE_MODAL = 'CLOSE_RESPONSE_MODAL';
export const OPEN_RESPONSE_MODAL = 'OPEN_RESPONSE_MODAL';

export const OPEN_UPDATE_FORM_MODAL = 'OPEN_UPDATE_FORM_MODAL';
export const CLOSE_UPDATE_FORM_MODAL = 'CLOSE_UPDATE_FORM_MODAL';

export const OPEN_REQUEST_MODAL = 'OPEN_REQUEST_MODAL';
export const CLOSE_REQUEST_MODAL = 'CLOSE_REQUEST_MODAL';

export const OPEN_VIEW_MODAL = 'OPEN_VIEW_MODAL';
export const CLOSE_VIEW_MODAL = 'CLOSE_VIEW_MODAL';

export const MAKE_REQUEST_START = 'MAKE_REQUEST_START';
export const MAKE_REQUEST_SUCCESS = 'MAKE_REQUEST_SUCCESS';
export const MAKE_REQUEST_FAIL = 'MAKE_REQUEST_FAIL';

export const OPEN_DELETE_DECISION_MODAL = 'OPEN_DELETE_DECISION_MODAL';
export const CLOSE_DELETE_DECISION_MODAL = 'CLOSE_DELETE_DECISION_MODAL';

export const OPEN_DELETE_RESPONSE_MODAL = 'OPEN_DELETE_RESPONSE_MODAL';
export const CLOSE_DELETE_RESPONSE_MODAL = 'CLOSE_DELETE_RESPONSE_MODAL';

export const DELETE_ITEM_START = 'DELETE_ITEM_START';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAIL = 'DELETE_ITEM_FAIL';

export const OPEN_REQUEST_RESPONSE_MODAL = 'OPEN_REQUEST_RESPONSE_MODAL';
export const CLOSE_REQUEST_RESPONSE_MODAL = 'CLOSE_REQUEST_RESPONSE_MODAL';
export const IS_LOADING = 'IS_LOADING';

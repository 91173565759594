import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/CoreModules/BranchDefinition"


const View = ({details}) => {
    return (
        <DetailsContainer
        title="Branch Definition - Record Details"
        question="Are you sure you want to delete this branch?"
        url={`${Routes.deleteAbranch}${details.id}`}
      >
        <Details details={details}/>
            </DetailsContainer>
    )
}

export default View

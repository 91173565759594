import React, { useState, useEffect } from "react";
import "./Investmentamendment.scss";
import useCustom from "../../../../Api/Hooks-manager/investmentAccountOperations/useCustom";
import { add } from "date-fns";
import { format } from "date-fns";
import Spinner from "../../../Backdrop/index";
import useCustom3 from "../../../../Api/Hooks-manager/accountOpening/useCustom";
import Confirm from "../../../../Confirmbox/Confirm";
import { validateInvestmentAmendment1 } from "../../../../Api/validator/validate11";
import ErrorMessage from "../../../ErrorMessage";
import NumberFormat from "react-number-format";

const Investmentamendment = () => {
  const [screenchange, setScreenchange] = useState(false);
  const {
    errorInvestmentNumber,
    investmentNumber,
    handleInvestmentAmendMentCreate,
    valuesMainInvestmentAmendMent,
    setValuesMainInvestmentAmendMent,
    getInvestmentBookingAll,
    valuesInvestmentAmendMentUx,
    setValuesInvestmentAmendMentUx,
    handleChange,
    loader,
  } = useCustom();
  const {
    getAccountByAccountNumber,
    valuesStaticInvestmentBook,
    loader: loaders,
  } = useCustom3();

  const [errors, setErrors] = useState({});
  const [error, setError] = React.useState({});
  const [openBox, setOpenBox] = React.useState(false);
  const [customerSameAccountNumber, setCustomerSameAccountNumber] = React.useState("");

  const handleScreenChange = (i) => {
    let checkError = validateInvestmentAmendment1(
      valuesMainInvestmentAmendMent
    );
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setScreenchange(i);
  };

  const handleAmendments = () => {
    let checkError = validateInvestmentAmendment1(
      valuesMainInvestmentAmendMent
    );
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setOpenBox(true);
  };
  
  

  useEffect(() => {
    const a = async () => {
      if(valuesMainInvestmentAmendMent.CustomerAccountNumber.length === 10 && valuesInvestmentAmendMentUx.DecideAccount === "DifferentAccount"){
          await getAccountByAccountNumber(valuesMainInvestmentAmendMent.CustomerAccountNumber)
    }

  }
  a();
 },[valuesMainInvestmentAmendMent.CustomerAccountNumber]);  

  useEffect(() => {
    const handleDebitFrom = async () => {
      if (valuesInvestmentAmendMentUx.DecideAccount === "SameAccount") {
        await getAccountByAccountNumber(
          valuesInvestmentAmendMentUx.customerAccountNumber
        );
        setValuesMainInvestmentAmendMent((valuesMainInvestmentAmendMent) => ({
          ...valuesMainInvestmentAmendMent,
          CustomerAccountNumber:
            valuesInvestmentAmendMentUx.customerAccountNumber,
        }));
        setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({

          ...valuesInvestmentAmendMentUx,
          Acctname: valuesStaticInvestmentBook.accName,
          AcctBranch: valuesStaticInvestmentBook.accBranch,
          AcctCurrency: valuesStaticInvestmentBook.accCurrency,
          AcctNumber: valuesInvestmentAmendMentUx.customerAccountNumber,
        }));
      } else {
        if(valuesMainInvestmentAmendMent.CustomerAccountNumber !== ""){
          setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({

            ...valuesInvestmentAmendMentUx,
            Acctname: valuesStaticInvestmentBook.accName,
            AcctBranch: valuesStaticInvestmentBook.accBranch,
            AcctCurrency: valuesStaticInvestmentBook.accCurrency,
          }));
        }
      }
    };
    handleDebitFrom();
  }, [
    valuesInvestmentAmendMentUx.DecideAccount,
    valuesStaticInvestmentBook.accName,
    valuesStaticInvestmentBook.accBranch,
    valuesStaticInvestmentBook.accCurrency,
    // valuesMainInvestmentAmendMent.CustomerAccountNumber,
  ]);
          
        



  useEffect(() => {
    if(valuesInvestmentAmendMentUx.DecideAccount !== "SameAccount"){
      console.log("ooooooooooooohjvvhvghvgvggv")
      setValuesMainInvestmentAmendMent((valuesMainInvestmentAmendMent) => ({
        ...valuesMainInvestmentAmendMent,
        CustomerAccountName: "",
        CustomerAccountNumber: "",
      }));
        setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
          ...valuesInvestmentAmendMentUx,
          Acctname: "",
          AcctBranch: "",
          AcctCurrency: "",
        }));
    }
  },[valuesInvestmentAmendMentUx.DecideAccount])




  


  //   useEffect(() => {
  //     if(valuesInvestmentAmendMentUx.DecideAccount === 'SameAccount') {
  //       const a = async () => {
  //         const accountDetails = await getAccountByAccountNumber(valuesMainInvestmentAmendMent.CustomerAccountNumber)
  //         console.log(accountDetails, "ggggggg")

  //       setValuesMainInvestmentAmendMent(valuesMainInvestmentAmendMent => ({...valuesMainInvestmentAmendMent, CustomerAccountNumber: accountDetails.accountNumber, CustomerAccountName: accountDetails.accountName }));
  //       setValuesInvestmentAmendMentUx(valuesInvestmentAmendMentUx => ({...valuesInvestmentAmendMentUx, Acctname: accountDetails.accountName ,AcctBranch: accountDetails.branchCode, AcctCurrency: accountDetails.currency, AcctNumber: accountDetails.accountNumber }));
  //     }
  //     a()
  //   } else {
  //     setValuesMainInvestmentAmendMent(valuesMainInvestmentAmendMent => ({...valuesMainInvestmentAmendMent, CustomerAccountName: '', CustomerAccountNumber: '' }));
  //     setValuesInvestmentAmendMentUx(valuesInvestmentAmendMentUx => ({...valuesInvestmentAmendMentUx, Acctname: '' , AcctBranch: '', AcctCurrency: '' }));

  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [valuesInvestmentAmendMentUx.DecideAccount])

  const handleFieldUpdate = (type) => {
    if (type === "AmendInterestRate") {
      setValuesInvestmentAmendMentUx({
        ...valuesInvestmentAmendMentUx,
        AmendInterestRate: !valuesInvestmentAmendMentUx.AmendInterestRate,
      });
    }
  };

  useEffect(() => {}, [errors]);

  useEffect(() => {
    getInvestmentBookingAll();
  }, []);

  useEffect(() => {
    const total =
      parseInt(valuesMainInvestmentAmendMent.SystemRate) +
      parseInt(valuesMainInvestmentAmendMent.Variance);
    setValuesMainInvestmentAmendMent((valuesMainInvestmentAmendMent) => ({
      ...valuesMainInvestmentAmendMent,
      FinalRate: total,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesMainInvestmentAmendMent.Variance]);

  useEffect(() => {
    const total =
      parseInt(valuesMainInvestmentAmendMent.Amount) +
      parseInt(valuesInvestmentAmendMentUx.Amount);
    setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
      ...valuesInvestmentAmendMentUx,
      newInvAmount: total,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valuesMainInvestmentAmendMent.Amount,
    valuesInvestmentAmendMentUx.Amount,
  ]);
  console.log(valuesInvestmentAmendMentUx)

  useEffect(() => {
    const result = add(new Date(), {
      years: 0,
      months: 0,
      weeks: 0,
      days: valuesMainInvestmentAmendMent.Tenor,
    });
    console.log(result, "resultresult");
    let formattedDate = format(new Date(result), "MM/dd/yyyy");
    setValuesMainInvestmentAmendMent((valuesMainInvestmentAmendMent) => ({
      ...valuesMainInvestmentAmendMent,
      MaturityDate: formattedDate,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesMainInvestmentAmendMent.Tenor]);

  return (
    <div className="investment--amendment">
      <Confirm
        ApiCall={handleInvestmentAmendMentCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
      />
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader || loaders} />
      </div>
      <div className="headerdiv">
        <p>Investment Amendment - Principal and Tenor Increase</p>
      </div>

      {!screenchange && (
        <>
          <main style={{ padding: 25 }} className="flex--3 border--main">
            <section className="section--1 flex--4">
              <div className="flex--3">
                <label>Investment Account Number</label>
                <input
                  type="text"
                  onChange={(e) => handleChange(e, "liquidation")}
                  name="investmentNumber"
                  value={investmentNumber}
                  maxLength="17"
                  className={errorInvestmentNumber && "error-input-z"}
                />
                {errorInvestmentNumber && (
                  <ErrorMessage message={errorInvestmentNumber} />
                )}
              </div>
              <div className="flex--3">
                <label>Account Name</label>
                <input
                  type="text"
                  disabled
                  value={valuesInvestmentAmendMentUx.CustomerAccountName}
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Account Branch</label>
                <input
                  type="text"
                  disabled
                  value={valuesInvestmentAmendMentUx.customerAccountBranch}
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Account Currency</label>
                <input
                  disabled
                  type="text"
                  value={
                    valuesInvestmentAmendMentUx.AcctCurrency
                      ? valuesInvestmentAmendMentUx.AcctCurrency
                      : "NGN"
                  }
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Investment Product</label>
                <input
                  disabled
                  type="text"
                  value={valuesInvestmentAmendMentUx.ProductCode}
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Investment Status</label>
                <input
                  disabled
                  type="text"
                  value={valuesInvestmentAmendMentUx.Status}
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Investment Amount</label>
                <NumberFormat
                  className="input--amount"
                  value={valuesInvestmentAmendMentUx.Amount}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>
              <div className="flex--3">
                <label>Investment Book Date</label>
                <input
                  disabled
                  type="text"
                  value={valuesMainInvestmentAmendMent.BookDate}
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Amendment Eff. Date</label>
                <input
                  disabled
                  type="text"
                  value={format(new Date(), "dd/MM/yyyy")}
                  className={`${
                    Object.keys(errors).includes("CurrentBalance") &&
                    "error-input-z"
                  }`}
                />
              </div>
              <div className="tenor flex--3">
                <label>Tenor (In Days)</label>
                <input
                  type="number"
                  onChange={(e) => handleChange(e, "amendment")}
                  name="Tenor"
                  value={valuesMainInvestmentAmendMent.Tenor}
                  className={`${
                    Object.keys(error).includes("Tenor") && "error-input-z"
                  }`}
                />
                {error.Tenor && <ErrorMessage message={error.Tenor} />}
              </div>
              <div className="flex--3">
                <label>Investment Maturity Date</label>
                <input
                  type="text"
                  disabled
                  value={valuesInvestmentAmendMentUx.MaturityDate}
                  className={`${
                    Object.keys(errors).includes("Tenor") && "error-input-z"
                  }`}
                />
              </div>
              <div className="flex--3">
                <label>Investment Balance</label>
                <NumberFormat
                  className="input--amount"
                  value={valuesInvestmentAmendMentUx.currentBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>
              <div className="flex--3">
                <label>Additional Inv. Amount</label>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  renderText={(value) => <div>{value}</div>}
                  className={`${
                    Object.keys(error).includes("Amount") && "error-input-z"
                  }`}
                  onValueChange={(values) => {
                    setValuesMainInvestmentAmendMent(
                      (valuesMainInvestmentAmendMent) => ({
                        ...valuesMainInvestmentAmendMent,
                        Amount: parseInt(values.value),
                      })
                    );
                  }}
                />
                {error.Amount && <ErrorMessage message={error.Amount} />}
              </div>
              <div className="flex--3">
                <label>New Inv. Amount</label>
                <input
                  disabled
                  type="number"
                  name="newInvAmount"
                  step="any"
                  value={
                    valuesInvestmentAmendMentUx.newInvAmount
                      ? valuesInvestmentAmendMentUx.newInvAmount
                      : 0
                  }
                />
              </div>
              <div className="flex--3">
                <label>New Maturity Date</label>
                <input
                  type="text"
                  onChange={(e) => handleChange(e, "amendment")}
                  disabled
                  value={valuesMainInvestmentAmendMent.MaturityDate}
                  className={`${
                    Object.keys(errors).includes("Tenor") && "error-input-z"
                  }`}
                />
              </div>
            </section>
            <section className="flex--3 section--2">
              <label>Debited from</label>
              <select
                // disabled={
                //   valuesMainInvestmentAmendMent.CustomerAccountNumber === ""
                // }
                name="DecideAccount"
                onChange={(e) => handleChange(e, "decide")}
              >
                <option>Select An Option</option>
                <option value="SameAccount">Same Account</option>
                <option value="DifferentAccount">Different Account</option>
              </select>
            </section>
            <section className="flex--4">
              <div className="flex--3">
                <label>Account Number</label>
                <input
                  type="text"
                  name="CustomerAccountNumber"
                  onChange={(e) => handleChange(e, "amendment")}
                  disabled={
                    valuesInvestmentAmendMentUx.DecideAccount === "" ||
                    valuesInvestmentAmendMentUx.DecideAccount === "SameAccount"
                  }
                  value={
                    valuesInvestmentAmendMentUx.DecideAccount ===
                    "DifferentAccount"
                      ? valuesMainInvestmentAmendMent.CustomerAccountNumber
                      : valuesInvestmentAmendMentUx.DecideAccount ===
                        "SameAccount"
                      ? valuesInvestmentAmendMentUx.AcctNumber
                      : ""
                  }
                  className={`${
                    Object.keys(error).includes("CustomerAccountNumber") &&
                    "error-input-z"
                  }`}
                />
                {error.CustomerAccountNumber && (
                  <ErrorMessage message={error.CustomerAccountNumber} />
                )}
              </div>
              <div className="flex--3">
                <label>Account Name</label>
                <input
                  type="text"
                  // onChange={(e) => handleChange(e, 'amendment')}
                  disabled
                  value={valuesInvestmentAmendMentUx.Acctname}
                />
              </div>
              <div className="flex--3">
                <label>Account Branch</label>
                <input
                  type="text"
                  disabled
                  value={valuesInvestmentAmendMentUx.AcctBranch}
                />
              </div>
              <div className="flex--3">
                <label>Account Currency</label>
                <input
                  type="text"
                  disabled
                  value={valuesInvestmentAmendMentUx.AcctCurrency}
                />
              </div>
            </section>
            <div className="btn-cont">
              <button className="btnz" onClick={() => handleScreenChange(true)}>
                Next
              </button>
            </div>
          </main>
        </>
      )}

      {screenchange && (
        <main className="main--2 border--main">
          <div className="flex--4">
            <div className="flex--3">
              <label>Investment Account Number</label>
              <input type="text" disabled value={investmentNumber} />
            </div>
            <div className="flex--3">
              <label>Account Name</label>
              <input
                type="text"
                disabled
                value={valuesInvestmentAmendMentUx.CustomerAccountName}
                className={`${
                  Object.keys(errors).includes("CurrentBalance") &&
                  "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Account Branch</label>
              <input
                type="text"
                disabled
                value={valuesInvestmentAmendMentUx.customerAccountBranch}
                className={`${
                  Object.keys(errors).includes("CurrentBalance") &&
                  "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Account Currency</label>
              <input
                disabled
                type="text"
                value={valuesInvestmentAmendMentUx.AcctCurrency}
                className={`${
                  Object.keys(errors).includes("CurrentBalance") &&
                  "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Investment Product</label>
              <input
                disabled
                type="text"
                value={valuesInvestmentAmendMentUx.ProductCode}
              />
            </div>
            <div className="flex--3">
              <label>Investment Status</label>
              <input
                disabled
                type="text"
                value={valuesInvestmentAmendMentUx.Status}
              />
            </div>
            <div className="flex--3">
              <label>Amendment Eff. Date</label>
              <input
                disabled
                type="text"
                value={valuesMainInvestmentAmendMent.AmendmentEffectiveDate}
                className={`${
                  Object.keys(errors).includes("CurrentBalance") &&
                  "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Rate Chart Code</label>
              <input
                disabled
                type="text"
                value={
                  valuesInvestmentAmendMentUx.rateChatCode
                    ? valuesInvestmentAmendMentUx.rateChatCode
                    : "None"
                }
              />
            </div>
            <div className="flex--3">
              <label>Interest Rate</label>
              <input
                disabled
                type="number"
                step="any"
                value={valuesInvestmentAmendMentUx.FinalRate}
              />
            </div>
            <div className="flex--3 check checkers">
              <input
                onChange={() => handleFieldUpdate("AmendInterestRate")}
                value={valuesInvestmentAmendMentUx.AmendInterestRate}
                type="checkbox"
                checked={valuesInvestmentAmendMentUx.AmendInterestRate}
              />
              <label className="span">Amend Interest Rate</label>
            </div>
            <div className="flex--3">
              <label>System Rate</label>
              <input
                type="number"
                step="any"
                onChange={(e) => handleChange(e, "amendment")}
                name="SystemRate"
                disabled
                value={valuesMainInvestmentAmendMent.SystemRate}
                className={`${
                  Object.keys(errors).includes("Tenor") && "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Variance</label>
              <input
                type="number"
                onChange={(e) => handleChange(e, "amendment")}
                name="Variance"
                value={valuesMainInvestmentAmendMent.Variance}
                className={`${
                  Object.keys(error).includes("Variance") && "error-input-z"
                }`}
              />
              {error.Variance && <ErrorMessage message={error.Variance} />}
            </div>
            <div className="flex--3">
              <label>New Interest Rate</label>
              <input
                type="number"
                step="any"
                onChange={(e) => handleChange(e, "amendment")}
                name="FinalRate"
                disabled
                value={valuesMainInvestmentAmendMent.FinalRate}
                className={`${
                  Object.keys(error).includes("Tenor") && "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Maturity Amount</label>
              <input
                type="number"
                step="any"
                disabled
                value={valuesMainInvestmentAmendMent.MaturityAmount}
              />
            </div>
            <div className="flex--3">
              <label>New Maturity Amount</label>
              <input type="text" step="any" disabled />
            </div>
          </div>
          <div className="flex--4 button--cont">
            <button
              onClick={() => handleScreenChange(false)}
              className="btn--1"
            >
              Previous
            </button>
            <button className="btn--2" onClick={handleAmendments}>
              Amend Investment
            </button>
          </div>
        </main>
      )}
    </div>
  );
};
export default Investmentamendment;

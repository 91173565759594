import { Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Item from "../../DatagridTable/item";
import { numberFormatter } from "../../../formatter";

const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDialogContent-root":{
        display:"flex"
      }
    },
    text: {
      margin: "auto",
      marginTop: "2rem",
      marginBottom: "1rem",
      color: theme.palette.secondary.main,
    },
    hr: {
      backgroundColor: "black",
      width: "100%",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    table: {
      width: "100%",
      "& th": {
        paddingTop: 12,
        paddingBottom: 12,
        textAlign: "left",
      },
      "& td": {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    noData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    item: {
      width: "100%",
    },
  }));
  

const LoanFacilityLimit = ({details}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Number" name={details.accountNo} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Name" name={details.accountName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Currency" name={details.accountCurrency} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Branch code" name={details.accountBranch} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Approved Loan Limit" name={details.approvedLoanLimit} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Loan Limit Balance" name={details.loanLimitBalance} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Status"
            name={
              details.authorizationStatus === "PendingEditedAuthorization"
                ? "Pending Edited Authorization"
                : details.authorizationStatus
            }
          />
        </Grid>
      </Grid>

    )
}

export default LoanFacilityLimit;

import { useState, useEffect,useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { format, add } from "date-fns";
import {
    generateSampleSchedule,
    createLoanBooking,
    getAllLoanBooking,
    deleteLoanBooking,
    authorizeLoanBooking,
    getCustomerByAccountNumber,
    getLoanFacilityLimitByAccountNum,
    getLoanByLoanAccountNumber,
    createLoanRepayment,
    getAllLoanRepayment,
    getLoanRepaymentByLoanAccNum,
    authorizeLoanRepayment,
    getLoanByCustomerAccountNumber,
    updateLoanRepayment,
  } from "../../../../Api/Hooks-manager/loanAccountOperations";

  import {
    getAllCurrencyDefinition,
  } from "../../../../Api/Hooks-manager/coreModules/coreModules";
  import { formattedDate } from "../../../../formatter/date";
  import useTable from "../../../Tables/useTable";
  import {
    isLoading,
    openViewModal,
  } from "../../../../Api/redux-manager/actions";
  import CustomizedSnackbars from "../../../../Confirmbox/Snackbar";



export default function BusinessLogic(){
    const dispatch = useDispatch();
    const token = useSelector((state) => state.common.userDetails.jwtToken);
    const debitAccountBranch = useSelector((state) => state.common.branch.code);
    const [screenChange, setScreenChange] = useState(1);
    const [open, setOpen] = useState(false);
    const loading = useSelector(state =>  state.req.loading);
    const [takeAction, setTakeAction] = useState(false);
    const [message, setMessage] = useState("");
    const [accNum, setAccountNumber] = useState("");
    const [allLoanAccountNum, setAllLoanAccNum] = useState([]);
    const [invalidAccount, setInvalidAccount] = useState("");
    const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
    const [allLoanRepayment, setAllLoanRepayment] = useState([]);
    const [errors, setErrors] = useState({});
    const [openView, setOpenView] = useState(false);
    const [view, setView] = useState({});
    const [repaymentAmount, setRepaymentAmount] = useState({});
    const [openBoxAuthorize, setOpenAuthorize] = useState(false);
    const [openDecision, setOpenDecision] = useState(false);
    const [repaymentSchedule, setRepaymentSchedule] = useState([]);
    const [fullLoanDetails, setFullLoanDetails] = useState({});
    const [sampleShedule, setSampleSchedule] = useState([]);

    const workdate = useSelector((state) => state.common.branch.workdate);

    const nowDate = format(new Date(workdate), 'yyyy-MM-dd');
  
    const date = new Date();


    const [loanDetails, setLoanDetails] = useState({
      accountHolder: true,
      accountNumber: "",
      accountName: "",
      accountBranch: "",
      currencyCode: "",
      loanProduct: "",
      amount: "",
      bookDate: nowDate,
      tenor: 0,
      tenorBasis: "",
      maturityDate: "",
      interestRate: 0,
      interestPeriod: "",
      interestRateBasis: "",
      firstRepaymentDate: date,
      interestCalcMethod: "",
      amortizationType: "",
      repaymentFrequency: "",
      autoDisbursement: false,
      autoLiquidate: false,
      gaurantorRequired: false,
      startDate: nowDate, //added
      repaymentAccount: "",
      repCustomerName: "",
      repAccountBranch: "",
      repAccountCurrency: "",
      loanOfficer: "",
      purposeOfLoan: "",
      collateralRequired: false,
      bulletpayment: false,
      customerType: true,
      repaymentFrequencyUnit: 1,
      loanGuarantors: [
        {
          customerType: true,
          guarantorAccNo: "",
          guarantorAccName: "",
          accountBranch: "",
          currencyCode: "",
          guarantorAddress: "",
          city: "",
          contactName: "",
          state: "",
          country: "",
          emailAddress: "",
          contactMobile: "",
        },
      ],
      loanCharges: [
        {
          chargeName: "",
          applyChargeAt: "",
          typeOfCharge: "",
          rate: "",
          chargeLedgerAccount: "",
          //rateBasis: "", //not in rules change it to chargeLedgerAccount
          amountBasis: "",
          chargeCurrency: "",
        },
      ],
      // repayments: [
      //   {
      //     repaymentAmount: "",
      //     repaymentPrincipal: "",
      //     repaymentInterest: "",
      //     sequenceId: "",
      //     dueDate: nowDate,
      //     currency: "",
      //     debitAccount: "",
      //   }
      // ],
    });
    const [repayment, setRepayment] = useState({
      sequenceId: null,
      repaymentType: "Transfer",
      loanProductId: null,
      loanAcountNo: "",
      branchCode: "",
      debitAccountBranch:debitAccountBranch,
      amount: 0,
      // repaymentAmount: 0,
      currency: "",
      debitAccount: "",
      denominations: [],
    });

    const [details, setDetails] = useState({});
    const [repaymentDetails, setRepaymentDetails] = useState([]);
    const [debitAccountDetails, setDebitAccountDetails] = useState({});
    const [repaymentAccountDetails, setRepaymentAccountDetails] = useState({});
    const [openShedule, setOpenSchedule] = useState(false);

    // const [updateData, setUpdateData] = useState({
    //   repaymentDetails: [],
    //   loanAccountNo: "",
    // });

    // console.log(updateData);




    const handleChange = (e) => {
      const { name, value } = e.target;
      setRepayment({ ...repayment, [name]: value });
      if (name === "loanProductId" || name === "amount") {
        const inputedVal =
          value === "" ? "" : parseInt(value) < 0 ? 0 : parseInt(value);
        setRepayment({
          ...repayment,
          [name]: inputedVal,
        });
      }
    };
  
    const handleAccNum = (e) => {
      const { value } = e.target;
      if (value.length > 10) return;
      setAccountNumber(value);
    };

    const fetchAllLoanAccountNumber = async () => {
      dispatch(isLoading())
      const response = await getLoanByCustomerAccountNumber(
        token,
        accNum
      );
      if (response && response.data) {
        dispatch(isLoading())
        if (response.status) {
          const authorizedLoans = response.data.filter(
            (item) => item.authorizationStatus === "Authorized"
          );
          setInvalidAccount(
            response.data.length < 1 ? "Could not find any loan" : ""
          );
          if (response.data.length > 0 && authorizedLoans.length < 1) {
            setInvalidAccount("This loan is not currently authorized");
          }
          setAllLoanAccNum(
            authorizedLoans.map((el) => {
              return {
                label: el.loanAccount,
                value: el.loanAccount,
              };
            })
          );
        } else {
          setAllLoanAccNum([]);
          clearAccountDetails(true);
          setInvalidAccount(response.message);
        }
      }
    };

    const fetchLoanAccountDetails = async (accNum) => {
      dispatch(isLoading())
      const response = await getLoanByLoanAccountNumber(token, accNum);
      if (response && response.data) {
        // console.log(response.data, "loan details of customer account");
        dispatch(isLoading())
        if (response.status) {
          setErrors({ ...errors, loanAccountNo: "" });
          setFullLoanDetails({
            ...response.data, 
            loanProductName: response.data.loanProductDetails.productName || ""
          })

          setRepayment({
            ...repayment,
            //amount: response.data.amount,
            debitAccount: response.data.accountNumber,
            branchCode: response.data.accountBranch,
            currency: response.data.currencyCode,
            loanProductId: response.data.id,
          });
          setDetails({
            accountName: response.data.accountName,
            amount: response.data.amount,
            currencyCode: response.data.currencyCode,
            accountBranch: response.data.accountBranch,
            loanBalance: response.data.loanBalance,
          });
        } else {
          setErrors({ ...errors, loanAccountNo: response.message });
          clearAccountDetails(true);
        }
      }
    };


    const fetchCustomerDetails = async (accNum) => {
      dispatch(isLoading())
      const response = await getCustomerByAccountNumber(token, accNum);
      if (response && response.data) {
        dispatch(isLoading())
        if (response.status) {
          setErrors({ ...errors, debitAccount: "" });
          setRepayment({...repayment, debitAccountBranch:response.data.branchCode});
          setDebitAccountDetails({
            debitAccountBranch: response.data.branchCode,
            accountName: response.data.accountName,
            accountBranch: response.data.branchCode,
            currencyCode: response.data.currency,
          });
          setRepayment({
            ...repayment,
            currency: response.data.currency,
          });
        } else {
          setErrors({ ...errors, debitAccount: response.message });
          clearCustomerDetails(true);
        }
      }
    };


    // const fetchLoanShedule = async (open=true) => {
    //   if(sampleShedule.length < 1){
    //     dispatch(isLoading());
    //     const response = await generateSampleSchedule(token, loanDetails);
    //     console.log(response);
    //     if (response && response.data) {
    //       dispatch(isLoading());
  
    //     let newSchedule = response.data;
    //     if (newSchedule && !Array.isArray(newSchedule)){
    //       newSchedule.currency = 'NGN'
    //       newSchedule.debitAccount = loanDetails.accountNumber
    //       newSchedule = [newSchedule]
    //     }
    //     else if(newSchedule && Array.isArray(newSchedule)){
    //       newSchedule = newSchedule.map(schedule => {
    //         schedule.currency = 'NGN'
    //         schedule.debitAccount = loanDetails.accountNumber
    //         return schedule
    //       })
    //     }
    //       setSampleSchedule(newSchedule);
    //       setOpenSchedule(open);
    //     }
    //   }
    //   else{
    //     setOpenSchedule(open);
    //   }
    // };


    const clearCustomerDetails = (invalid) => {
      if (repayment.debitAccount.length < 10 || invalid) {
        setDebitAccountDetails({});
        setRepayment({
          ...repayment,
          currency: "",
        });
      }
    };
  
    const clearAccountDetails = (invalid) => {
      if (repayment.loanAcountNo === "" || invalid) {
        setDetails({});
        setRepayment({
          ...repayment,
          loanProductId: "",
          //debitAccount: "",
          branchCode: "",
          loanProductId: null,
        });
      }
    };


    useEffect(() => {
      if (accNum.length !== 10) {
        clearAccountDetails(true);
        setAllLoanAccNum([]);
      }
    }, [accNum]);
  
    useEffect(() => {
      clearAccountDetails(true);
    }, [repayment.loanAccountNo]);
  
    useEffect(() => {
      clearCustomerDetails(true);
    }, [repayment.debitAccount]);
  
    useEffect(() => {
      if (accNum.length === 10) {
        fetchAllLoanAccountNumber();
      }
    }, [accNum]);
  
    useEffect(() => {
      if (repayment.loanAcountNo) {
        fetchLoanAccountDetails(repayment.loanAcountNo);
      }
    }, [repayment.loanAcountNo]);
  
    useEffect(() => {
      if (repayment.debitAccount.length === 10) {
        fetchCustomerDetails(repayment.debitAccount);
      }
    }, [repayment.debitAccount]);

    const [openRepayment, setOpenRepayment] = useState(false);


    const fetchAllLoanRepaymentsByAccNum = async (loanAccNum, open=true) => {
      dispatch(isLoading())
      const response = await getLoanRepaymentByLoanAccNum(
        token,
        loanAccNum
      );
      // console.log(response.data, "loan repayment")
      const data = response.data;
      const id = response.data.length + 1;
      const length = response.data.length;
      const sum = totalRepaymentAmount(
        length > 0 ? response.data : [{ amount: 0 }]
      );
      if (response && response.data) {
        const repaymentArray = response.data
          .sort((a,b) => (a.sequenceId > b.sequenceId)  // sort by sequenceId
            ? 1 
            : ((b.sequenceId > a.sequenceId) ? -1 : 0))
        setRepaymentSchedule(response.data);
        setRepaymentDetails(response.data);
        // setUpdateData({
        //   repaymentDetails: data.map((item) =>{
        //     return{
        //       sequenceId: item.sequenceId,
        //       repaymentAmount: item.repaymentAmount,
        //       repaymentInterest: item.repaymentInterest,
              
        //     }
        //   }),
        //   loanAccountNo: repayment.loanAcountNo,
        // })
        setOpenRepayment(open);
        dispatch(isLoading())
        setSeqId(id);
        setSumLoanRepayment(sum);
        getARepaymentDetails(response.data,loanAccNum)
      }
      else{
        setOpenRepayment(false);
      }
    };


    const [seqId, setSeqId] = useState(null);

    const [sumLoanRepayment, setSumLoanRepayment] = useState(0);
  
    const listOfPayment = (arr) => arr.map((item) => item.amount);
  
    const totalRepaymentAmount = (arr) =>
      listOfPayment(arr).reduce((total, amount) => total + amount);
  
    const getARepaymentDetails = (arr, loanAccNo, returnData = false) => {
      const list = [...arr], 
      curItem = list.find(item => item.loanAccountNo === loanAccNo);
      if (returnData) {
        return {
          repaymentInterest: curItem.repaymentInterest,
          repaymentPrincipal: curItem.repaymentPrincipal,
        };
      }
      if (curItem) {
        setRepaymentAccountDetails((prev) => ({
          ...prev,
          repaymentInterest: curItem.repaymentInterest,
          repaymentPrincipal: curItem.repaymentPrincipal,
        }));
      }
    };

    // useEffect(() => {
    //   fetchAllLoanBooking();
    //   // BankParameterByName();
    //   fetchAllGlAccount();
    //   fetchAllCurrencyDefinition();
    //   fetchAllLoanProducts();
    //   fetchBranches();
    //   fetchAccountOfficers();
    // }, []);
  
    // useEffect(() => {
    //   console.log("rendering...", loanDetails.amount)
    //   calculateInterestRate(loanDetails.amount)
    // }, [loanDetails.amount])

    // const updateLoanSchedule = async () =>{
    //   dispatch(isLoading())
    //   const response = await updateLoanRepayment(token, updateData);
    //   if(response && response.data){
    //     dispatch(isLoading())
    //     console.log("abba")
    //   }else{
    //     console.log("Nooo")
    //   }
    // }
    
    const onEditedScheduleSave = (editedSchedule) => {
      setSampleSchedule(editedSchedule)
      setLoanDetails(prev => ({...prev, repayments: editedSchedule}))
      // updateLoanSchedule();
    }



    return {
      loanDetails,
      setLoanDetails,
      repayment,
      accNum,
      allLoanRepayment,
      allLoanAccountNum,
      invalidAccount,
      details,
      debitAccountDetails,
      // amount,
      // setAmount,
      // repaymentAccountDetails,
      // addDenomination,
      // removeDenomination,
      handleChange,
      handleAccNum,
      // handleDenominationsChange,
      // handleFirstScreen,
      // postLoanRepayment,
      screenChange,
      setScreenChange,
      // handleSubmit,
      errors,
      open,
      setOpen,
      loading,
      setDetails,
      allCurrencyDefinition,
      message,
      takeAction,
      setTakeAction,
      // setLoanId,
      openView,
      setOpenView,
      view,
      setView,
      openBoxAuthorize,
      setOpenAuthorize,
      openDecision,
      setOpenDecision,
      // decide,
      // authorizeRepayment,
      // setAuthorizeRepayment,
      // handleViewAll,
      repaymentSchedule,
      fullLoanDetails,
      setFullLoanDetails,
      setRepayment,
      sampleShedule,
      // fetchLoanShedule,
      openShedule,
      openRepayment,
      setOpenRepayment,
      setOpenSchedule,
      onEditedScheduleSave,
      // updateLoanSchedule,
      fetchLoanAccountDetails,
      repaymentDetails,
      fetchAllLoanRepaymentsByAccNum,
      // updateData,
    };
}
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./NextOfKin.scss";
import useCustom2 from "../../../Api/Hooks-manager/coreModules/useCustom";

import SearchDropdown from "../../../components/SearchableDropdown/Search";
import ErrorMessage from "../../../components/ErrorMessage";

const NextOfKin = ({ handleChange, value, updateCountry, errors }) => {
  const { allbankparameters, getBankParametersAll } = useCustom2(),
    countries = useSelector((state) => state.common.countries);

  console.log(errors, "errorserrorserrors");

  React.useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);

  // useEffect(() => {
  //     const fetchCountries = async () => {
  //     const user = JSON.parse(localStorage.getItem('user'));
  //     const response = await getCountries(user.jwtToken);
  //     if (response && response.data) {
  //     setCountries(response.data);
  //     }
  // };

  // fetchCountries();
  // }, []);

  const allCountriesValue = countries.map((item) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const handleChangeSelect = (values, type) => {
    if (type === "nationality") {
      updateCountry(values, "nationality");
    }
    if (type === "isDependent") {
      updateCountry("none", "isDependent");
    }
  };

  const parametersRelationship = allbankparameters.filter(
    (items) => items.parameterName === "NOK_Relationships"
  );

  return (
    <div className="next--Kin">
      <div className="new-grid">
        <div style={{ paddingTop: 20, padding: 25 }}>
          <div className="product--def flex--1">
            <div className="flex--3 input--3">
              <label>First Name</label>
              <input
                type="text"
                value={value.nextOfKin.firstName}
                onChange={handleChange("firstName", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("firstName") && "error-input-z"
                }`}
              />
              {errors.firstName && <ErrorMessage message={errors.firstName} />}
            </div>
            <div className="flex--3 input--3">
              <label>Middle Name</label>
              <input
                type="text"
                value={value.nextOfKin.middleName}
                onChange={handleChange("middleName", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("middleName") && "error-input-z"
                }`}
              />
              {errors.middleName && (
                <ErrorMessage message={errors.middleName} />
              )}
            </div>
            <div className="flex--3 input--3">
              <label>Last Name</label>
              <input
                type="text"
                value={value.nextOfKin.lastName}
                onChange={handleChange("lastName", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("lastName") && "error-input-z"
                }`}
              />
              {errors.lastName && <ErrorMessage message={errors.lastName} />}
            </div>
          </div>
          <div className="product--def flex--1">
            <div className="flex--3 input--3">
              <label>Qualification</label>
              <input
                type="text"
                value={value.nextOfKin.qualifications}
                onChange={handleChange("qualifications", "nextOfKin")}
              />
            </div>
            <div className="flex--3 input--3">
              <label>Relationship</label>
              <select
                value={value.nextOfKin.relationship}
                onChange={handleChange("relationship", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("relationship") &&
                  "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                <option value = "Father">Father</option>
                {parametersRelationship.map((title, index) => {
                  return (
                    <option key={index} value={title.parameterValue}>
                      {title.parameterValue}
                    </option>
                  );
                })}
              </select>
              {errors.relationship && (
                <ErrorMessage message={errors.relationship} />
              )}
            </div>
            <div className="flex--3 input--3">
              <label>Gender</label>
              <select
                value={value.nextOfKin.gender}
                onChange={handleChange("gender", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("gender") && "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              {errors.relationship && (
                <ErrorMessage message={errors.relationship} />
              )}
            </div>
          </div>
          <div className="product--def flex--1">
            <div className="flex--3 input--3">
              <label>Phone number</label>
              <input
                type="tel"
                value={value.nextOfKin.phoneNumber}
                onChange={handleChange("phoneNumber", "nextOfKin")}
                maxLength="20"
                className={`${
                  Object.keys(errors).includes("phoneNumber") && "error-input-z"
                }`}
              />
              {errors.phoneNumber && (
                <ErrorMessage message={errors.phoneNumber} />
              )}
            </div>
            
            <div className="flex--3 input--3">
              <label>Address</label>
              <input
                type="text"
                value={value.nextOfKin.address}
                onChange={handleChange("address", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("address") && "error-input-z"
                }`}
              />
              {errors.address && <ErrorMessage message={errors.address} />}
            </div>

            <div className="flex--3 input--3">
              <label>Date of Birth</label>
              <input
                type="date"
                value={value.nextOfKin.dateOfBirth}
                onChange={handleChange("dateOfBirth", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("dateOfBirth") && "error-input-z"
                }`}
              />
              {errors.dateOfBirth && (
                <ErrorMessage message={errors.dateOfBirth} />
              )}
            </div>
          </div>
          <div className="product--def flex--1">
            <div className="flex--3 input--3">
              <label>Profession</label>
              <input
                type="tel"
                value={value.nextOfKin.profession}
                onChange={handleChange("profession", "nextOfKin")}
                maxLength="20"
                className={`${
                  Object.keys(errors).includes("profession") && "error-input-z"
                }`}
              />
              {errors.profession && (
                <ErrorMessage message={errors.profession} />
              )}
            </div>
            <div className="flex--3 input--4">
              <label>Marital Status</label>
              <select
                value={value.nextOfKin.maritalStatus}
                onChange={handleChange("maritalStatus", "nextOfKin")}
                className={`${
                  Object.keys(errors).includes("maritalStatus") &&
                  "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorce">Divorce</option>
                <option value="Widow">Widow</option>
              </select>
              {errors.maritalStatus && (
                <ErrorMessage message={errors.maritalStatus} />
              )}
            </div>
            <div className="whole">
              <div className="flex--3 input--4">
                <label className="lab">Country of Origin</label>
                <div
                  className={`border--norm ${
                    Object.keys(errors).includes("nationality") &&
                    "error-input-z"
                  }`}
                >
                  <SearchDropdown
                    options={allCountriesValue}
                    onChange={handleChangeSelect}
                    type="nationality"
                  />
                </div>
                {errors.nationality && (
                  <div style={{ marginTop: 10 }}>
                    <ErrorMessage
                      message={errors.nationality}
                      className="error-single"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="checboxdiv">
            <input
              type="checkbox"
              className="productcheckbox"
              onChange={() => handleChangeSelect("none", "isDependent")}
              checked={value.nextOfKin.isDependent}
            />
            <label className="span">Is Dependent?</label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NextOfKin;

import axios from 'axios';
import { Routes } from '../../configUrl';

const  getAllLienDetails = async (token, url) => {
    return await axios({
        method: "get",
        url: url,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
       
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
           
            data:[],
        };
    });
};

const postLienDetails = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.newLienAccount,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const updateLienCustomerDetail = async (token,body, id) => {
    console.log('body id fron fun', body.id)
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateLienAccount + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const removeCustomerLien = async (token, data) => {
    return await axios({
        method: "put",
        data: data,
        url: `${Routes.removeALien}${data.id}`,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
export{
    getAllLienDetails,
    postLienDetails,
    updateLienCustomerDetail,
    removeCustomerLien,
}



import React, { useState, useCallback } from "react";
import "./GLReports.scss";
import Table from "../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import { getAllCashMovement } from "../../../../Api/Hooks-manager/eodReports/balanceSheet";
import { isLoading } from "../../../../Api/redux-manager/actions";
// import { formattedDate } from "../../../../formatter/date";
import moment from "moment";
import { formattedDate } from "../../../../formatter/date";
import { format } from "date-fns";

const GeneralLedger = () => {
  const dispatch = useDispatch(isLoading());
  const loading = useSelector((state) => state.req.loading);
  const workdate = useSelector((state) => state.common.branch.workdate);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const applicationDate = format(new Date(workdate), "yyyy-MM-dd");

  const [data, setData] = useState({
    workDate: applicationDate,
  });
  const [allGeneralLedgerReports, setAllGeneralLedger] = useState(null);
  const [errors, setErrors] = useState({});

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchcode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },{
      name: "accountnumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountname",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditbalance",
      label: "Credit Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "debitbalance",
      label: "Debit Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "netbalance",
      label: "Net Balance",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "eod",
      label: "Eod",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { workDate } = data;
    if (workDate === "") {
      error.workDate = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };

  const handleReports = () => {
    const checkErrors = validateData();
    if (checkErrors || loading) {
      fetchGeneralLedger();
      console.log("i am livid");
    }
    console.log("i am believer");
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const EmptyField = () => {
    setData({
      workDate:applicationDate,
    });
  };
  const handleReset = () => {
    EmptyField();

    setAllGeneralLedger(false);
    // setTotals([]);
  };

  // const headCells = [];
  // const [filterFn, setFilterFn] = React.useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });
  // const {
  //   TblContainer,
  //   TblHead,
  //   TblPagination,
  //   recordsAfterPagingAndSorting,
  // } = useTable([], headCells, filterFn);

  const fetchGeneralLedger = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllCashMovement(token, data);
    console.log(response, "this trial balance");
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          //     // response.data[i].createdDateTime = formattedDate(
          //     //   response.data[i].createdDateTime
          //     // );
          //     // response.data[i].dateAuthorized = formattedDate(
          //     //   response.data[i].dateAuthorized
          //     // );
          //     // response.data[i].updatedDateTime = formattedDate(
          //     //   response.data[i].updatedDateTime
          //     // );
        }
      }
      setAllGeneralLedger(response.data);
      // setTotals(response.data.total);
    }
    //setAccountData()
  }, [data, token, dispatch]);

  let toDate = moment(data.workDate).format("MMMM Do YYYY");
  console.log(toDate, "this is date");

  return (
    <main className="general--ledger">
      <div className="headerdiv">
        <p>EOD Reports | General Ledger</p>
      </div>

      <div className="export-button"></div>
      <Spinner loading={loading} />

      <div className="profit">
        <div className="date-range">
          <div className="sub__range">
            <div className="sub__range__1">
              <div className="sub__range__2">
                <div className="date__from">
                  <label>Date</label>
                  <input
                    type="date"
                    name="workDate"
                    value={data.workDate}
                    onChange={handleChange("workDate")}
                  />
                  {errors.workDate && (
                    <ErrorMessage message={errors.workDate} />
                  )}
                </div>
              </div>

              {/* <div className="report__type">
                    <label>Report Type</label>
                    <select
                      name="reportType"
                      onChange={handleChange}
                      value={report.reportType}
                    >
                      <option>Accrual (Paid and Unpaid)</option>
                      <option>Accrual </option>
                      <option>Accrual (Unpaid)</option>
                    </select>
                  </div> */}
            </div>
            <div className="last-btn">
              <button onClick={handleReports}>Search</button>
              <button onClick={handleReset}>Reset</button>
            </div>
          </div>
          {allGeneralLedgerReports && (
            <div>
              <Table
                title={`General ledger As At ` + toDate}
                subtitle=""
                name="General Ledger "
                records={allGeneralLedgerReports}
                options={options}
                columns={columns}
              />
            </div>
          )}
        </div>
      </div>

      {/* {allTrialBalance.length > 0 && <div>

          </div> */}

      {/* {totals.map((total) => {
        return (
          <div className="total_trialBalance">
            <div className="total__1">
              <label>Total Opening Balance</label>
              <input
                key={total.id}
                type="text"
                name="openingBalance"
                value={total.totalOpeningBalance}
              />
            </div>
            <div className="total__1">
              <label>Total Closing Balance</label>
              <input
                key={total.id}
                type="text"
                name="closingBalance"
                value={total.totalClosingBalance}
              />
            </div>
            <div className="total__1">
              <label>Total Debit</label>
              <input
                key={total.id}
                type="text"
                name="totalDebit"
                value={total.totalDebit}
              />
            </div>
            <div className="total__1">
              <label>Total Credit</label>
              <input
                key={total.id}
                type="text"
                name="totalCredit"
                value={total.totalCredit}
              />
            </div>
          </div>
        );
      })} */}
    </main>
  );
};

export default GeneralLedger;

import axios from 'axios';
import { Routes } from '../../configUrl';

const getAllDenominationDefinition = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllDenominationDefinition,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };
  const createDenominationDefinition = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createDenominationDefinition,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const getADenominationDefinition = async (token,body,id) => {
    return await axios({
        method: "get",
        data: body,
        url: Routes.getADenominationDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const updateDenominationDefinition = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateDenominationDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const authorizeDenominationDefinition = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeDenominationDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const deleteDenominationDefinition = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteDenominationDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };
  export {
    getAllDenominationDefinition,
    createDenominationDefinition,
    getADenominationDefinition,
    updateDenominationDefinition,
    authorizeDenominationDefinition,
    deleteDenominationDefinition,
}

import axios from 'axios';
import { Routes } from '../../configUrl';

const verifyAllUsers = async (token) => {
    return await axios({
        method: "post",
        data: {
          token
        },
        url: Routes.verifyNewUser,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: []
        };
      })
      .catch((err) => {
          console.log(err.response)
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  

export {verifyAllUsers}

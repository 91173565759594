import React, { useState, useCallback } from "react";
import "./TrialBalance.scss";
import Table from "../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import { getAllTrialBalance } from "../../../../Api/Hooks-manager/eodReports/balanceSheet";
import { isLoading } from "../../../../Api/redux-manager/actions";
// import { formattedDate } from "../../../../formatter/date";
import moment from "moment";
import { format } from "date-fns";

const TrialBalance = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.req.loading);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [allTrialBalanceReports, setAllTrialBalanceReports] = useState(null);
  const [errors, setErrors] = useState({});
  const workdate = useSelector((state) => state.common.branch.workdate);
  const applicationDate = format(new Date(workdate), "yyyy-MM-dd");
  const [data, setData] = useState({
    workDate: applicationDate,
  });
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchcode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },{
      name: "accountnumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountname",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "openingbalance",
      label: "Opening  Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "credit",
      label: "Credit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "debit",
      label: "Debit",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "closingbalance",
      label: "Closing Balance",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "eod",
    //   label: "Eod",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => <p>{formattedDate(value)}</p>,
    //   },
    // },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setData({
      ...data,
      [name]: value,
    });
  };
  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { workDate } = data;
    if (workDate === "") {
      error.workDate = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };

  
 
  const EmptyField = () => {
    setData({
      workDate: applicationDate,
    });
  };
  const handleReset = () => {
    EmptyField();

    setAllTrialBalanceReports(false);
  };
  let toDate = moment(data.workDate).format("MMMM Do YYYY");
  console.log(toDate, "this is date");
  const fetchAllTrialBalance = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllTrialBalance(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setAllTrialBalanceReports(response.data);
    }
  }, [data, token, dispatch]);
  const handleReports = () => {
    const checkErrors = validateData();
    if (checkErrors || loading) {
      fetchAllTrialBalance();
      console.log("i am livid");
    }
    console.log("i am believer");
  };



  return (
    <main className="trial--balance">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p>EOD Reports | Trial Balance</p>
      </div>
      <div className="profit">
        <div className="date-range">
          <div className="sub__range">
            <div className="sub__range__1">
              <div className="sub__range__2">
                <div className="date__from">
                  <label>Date</label>
                  <input
                    type="date"
                    name="workDate"
                    value={data.workDate}
                    onChange={handleChange("workDate")}
                  />
                  {errors.workDate && (
                    <ErrorMessage message={errors.workDate} />
                  )}
                </div>
              </div>
            </div>
            <div className="last-btn">
              <button onClick={handleReports}>Search</button>
              <button onClick={handleReset}>Reset</button>
            </div>
          </div>
          {allTrialBalanceReports && (
            <div>
              <Spinner loading={loading} />
              <Table
                title={`Trial Balance As At ` + toDate}
                subtitle=""
                name="Trial Balance"
                records={allTrialBalanceReports}
                options={options}
                columns={columns}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default TrialBalance;

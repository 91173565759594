import React, { useState, useCallback } from "react";
import "./BalanceSheets.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllBalanceSheetsReports } from "../../../../Api/Hooks-manager/eodReports/balanceSheet";
import moment from "moment";
import Spinner from "../../../Backdrop/index";
import Table from "../../../DatagridTable/table";
import { isLoading } from "../../../../Api/redux-manager/actions";
import ErrorMessage from "../../../ErrorMessage";
import {
  TableFooter,
  TableCell,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { format } from "date-fns";


const BalanceSheets = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.req.loading);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [allBalanceSheetReports, setAllBAlanceSheetReports] = useState(null);
  const [allReportSummaries, setAllReportSummaries] = useState(null);
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const workdate = useSelector((state) => state.common.branch.workdate);
  const [totals, setTotals] = useState({});
    const applicationDate = format(new Date(workdate), "yyyy-MM-dd");
  const [data, setData] = useState({
    workDate: applicationDate,
  });

  const reportSheetColumns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "glCode",
      label: "GL Code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "gLtype",
      label: "GL type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    // {
    //   name: "branchcode",
    //   label: "GL type",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "balance",
      label: "Balance",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const reportSummaryColumn = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "glType",
      label: "GL Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "debit",
      label: "Debit",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "credit",
      label: "Credit",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "balance",
      label: "Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "transaction_type",
    //   label: "Transaction Type",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "branchcode",
    //   label: "Branch Code",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "eod_date",
    //   label: "EOD Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => <p>{formattedDate(value)}</p>,
    // },
    // },
    // {
    //   name: "debit",
    //   label: "Debit",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },

    // {
    //   name: "totalDebit" ,
    //   label: "TransactionDebit",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },  {
    //   name: "totalCredit",
    //   label: "TransactionCredit",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
  ];

  const option = {
    filterType: "checkbox",
    selectableRows: "none",
    // textLabels:{
    //   pagination: {
    //     next: "Next >",
    //     previous: "< Previous",
    //     rowsPerPage: "Total items Per Page",
    //     displayRows: "OF"
    //   }
    // },
    // onChangePage (currentPage) {
    //   console.log({currentPage});
    // },
    // onChangeRowsPerPage (numberOfRows) {
    //   console.log({numberOfRows});
    // },
    customFooter: (count,   changeRowsPerPage, changePage) => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell style={{ display: "flex",justifyContent:"space-between", color:"black" }}>
              <h6>Total Debit for current page: {totals.totalDebits} </h6>
              <h6> Total Credit for current page: {totals.totalCredits}</h6>
            </TableCell>
          </TableRow>
          <TablePagination
        rowsPerPageOptions={[10, 15, 100]}
        options={option}
               page={page}
               rowsPerPage={rowsPerPage}
               changeRowsPerPage={changeRowsPerPage}
               changePage={changePage}
               count={allReportSummaries.length}
               onChangeRowsPerPage={handleChangeRowsPerPage}
               onChangePage={handleChangePage}


            />
        </TableFooter>
      );
    },



    
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { workDate } = data;
    if (workDate === "") {
      error.workDate = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };
  let toDate = moment(data.workDate).format("MMMM Do YYYY");

  const resetHandlerThree = () => {
    EmptyField();
    setAllBAlanceSheetReports(false);
    setAllReportSummaries(false);
  };

  const EmptyField = () => {
    setData({
      workDate:applicationDate,
    });
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleReports = () => {
    const checkErrors = validateData();
    if (checkErrors || loading ) {
      fetchAllBalanceSheets();
      console.log("i am livid");
    }
    console.log("i am believer");
  };

  const fetchAllBalanceSheets = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllBalanceSheetsReports(token, data);
    if (response && response.data.reportSheets) {
      dispatch(isLoading());
      if (response && response.data.reportSheets.length > 0) {
        for (let i = 0; i < response.data.reportSheets.length; i++) {
          response.data.reportSheets[i].sn = i + 1;
        }
      }
      setAllBAlanceSheetReports(response.data.reportSheets);
    }
    setTotals({
      totalDebits: response.data.totalDebit,
      totalCredits: response.data.totalCredit,
    });
    if (response && response.data.reportSummaries) {
      // dispatch(isLoading());
      if (response && response.data.reportSummaries.length > 0) {
        for (let i = 0; i < response.data.reportSummaries.length; i++) {
          response.data.reportSummaries[i].sn = i + 1;
        }
      }
      setAllReportSummaries(response.data.reportSummaries);
    }
  }, [data, token, dispatch]);

 

  return (
    <main className="balance--sheet">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p>EOD Report | Balance Sheet</p>
            </div>
      <div className="profit">
        <div className="sub__range ">
          <div className="sub__range__bs1 ">
            <div className="sub__range__bs2">
              <div className="date-to">
                <label>Date</label>
                <input
                  type="date"
                  name="workDate"
                  value={data.workDate}
                  onChange={handleChange("workDate")}
                />
                {errors.workDate && <ErrorMessage message={errors.workDate} />}
              </div>
            </div>
          </div>
          <div className="last-btn ">
            <button onClick={handleReports}>Search</button>
            <button onClick={resetHandlerThree}>Reset</button>
          </div>
        </div>
        {allBalanceSheetReports && (
        <div>
          
          {/* <Spinner loading={loading} /> */}
          <Table
            title={`Balance Sheet Report As At ` + toDate}
            subtitle="Report Sheets"
            records={allBalanceSheetReports}
            options={options}
            columns={reportSheetColumns}
          />
        </div>
      )}
      {allReportSummaries && (
        <div>
          <Table
            title="Report Summary"
            records={allReportSummaries}
            options={option}
            columns={reportSummaryColumn}
            rows={allReportSummaries}
          />
        </div>
      )}
      </div>
     
    </main>
  );
};

export default BalanceSheets;

import { useState, useEffect,useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createLoanRepayment,
  getAllLoanRepayment,
  getLoanRepaymentByLoanAccNum,
  authorizeLoanRepayment,
  getLoanByLoanAccountNumber,
  getLoanByCustomerAccountNumber,
  getCustomerByAccountNumber,
} from "../../../../Api/Hooks-manager/loanAccountOperations";
import {
  getAllCurrencyDefinition,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { formattedDate } from "../../../../formatter/date";
import useTable from "../../../Tables/useTable";
import {
  isLoading,
} from "../../../../Api/redux-manager/actions";

export default function BussinessLogic() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const debitAccountBranch = useSelector((state) => state.common.branch.code);
  const [screenChange, setScreenChange] = useState(1);
  const [open, setOpen] = useState(false);
  const loading = useSelector(state =>  state.req.loading);
  const [takeAction, setTakeAction] = useState(false);
  const [message, setMessage] = useState("");
  const [accNum, setAccountNumber] = useState("");
  const [allLoanAccountNum, setAllLoanAccNum] = useState([]);
  const [invalidAccount, setInvalidAccount] = useState("");
  const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
  const [allLoanRepayment, setAllLoanRepayment] = useState([]);
  const [errors, setErrors] = useState({});
  const [openView, setOpenView] = useState(false);
  const [view, setView] = useState({});
  const [repaymentAmount, setRepaymentAmount] = useState({});
  const [openBoxAuthorize, setOpenAuthorize] = useState(false);
  const [openDecision, setOpenDecision] = useState(false);
  const [repaymentSchedule, setRepaymentSchedule] = useState([]);
  const [fullLoanDetails, setFullLoanDetails] = useState({});
  const [authorizeRepayment, setAuthorizeRepayment] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const [repayment, setRepayment] = useState({
    sequenceId: null,
    repaymentType: "Transfer",
    loanProductId: null,
    loanAccountNo: "",
    branchCode: "",
    debitAccountBranch:debitAccountBranch,
    amount: 0,
    // repaymentAmount: 0,
    currency: "",
    debitAccount: "",
    denominations: [],
  });


  const [details, setDetails] = useState({});
  const [debitAccountDetails, setDebitAccountDetails] = useState({});
  const [repaymentAccountDetails, setRepaymentAccountDetails] = useState({});

  const headCells = [
    { id: "id", label: "S/N" },
    { id: "repaymentType", label: "Repayment Type" },
    { id: "currency", label: "Currency" },
    { id: "amount", label: "repayment Amount" },
    { id: "debitAccount", label: "Debit Account" },
    { id: "Status", label: "Status" },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(allLoanRepayment, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.repaymentType.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const handleViewAll = (item) => {
    console.log(item, "itemmsmm");
    setView(item);
    setOpenView(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRepayment({ ...repayment, [name]: value });
    if (name === "loanProductId" || name === "amount") {
      const inputedVal =
        value === "" ? "" : parseInt(value) < 0 ? 0 : parseInt(value);
      setRepayment({
        ...repayment,
        [name]: inputedVal,
      });
    }
  };

  const handleAccNum = (e) => {
    const { value } = e.target;
    if (value.length > 10) return;
    setAccountNumber(value);
  };

  const handleDenominationsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...repayment.denominations];
    list[index][name] =
      name === "total" || name === "denom" || name === "count"
        ? value === ""
          ? ""
          : parseInt(value) < 0
          ? 0
          : parseInt(value)
        : value;
    setRepayment({ ...repayment, denominations: list });
  };

  const addDenomination = () => {
    const list = [...repayment.denominations];
    if (list.length > 8) return;
    list.push({
      total: 0,
      denom: 0,
      count: 0,
    });
    setRepayment({
      ...repayment,
      denominations: list,
    });
  };

  const removeDenomination = (index) => {
    const list = [...repayment.denominations];
    if (list.length < 2) return;
    list.splice(index, 1);
    setRepayment({
      ...repayment,
      denominations: list,
    });
  };

  const fetchAllLoanAccountNumber = async () => {
    dispatch(isLoading())
    const response = await getLoanByCustomerAccountNumber(
      token,
      accNum
    );
    if (response && response.data) {
      dispatch(isLoading())
      if (response.status) {
        const authorizedLoans = response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        );
        setInvalidAccount(
          response.data.length < 1 ? "Could not find any loan" : ""
        );
        if (response.data.length > 0 && authorizedLoans.length < 1) {
          setInvalidAccount("This loan is not currently authorized");
        }
        setAllLoanAccNum(
          authorizedLoans.map((el) => {
            return {
              label: el.loanAccount,
              value: el.loanAccount,
            };
          })
        );
      } else {
        setAllLoanAccNum([]);
        clearAccountDetails(true);
        setInvalidAccount(response.message);
      }
    }
  };

  const fetchLoanAccountDetails = async (accNum) => {
    dispatch(isLoading())
    const response = await getLoanByLoanAccountNumber(token, accNum);

    if (response && response.data) {
      dispatch(isLoading())
      if (response.status) {
        setErrors({ ...errors, loanAccountNo: "" });
        setFullLoanDetails({
          ...response.data, 
          loanProductName: response.data.loanProductDetails.productName || ""
        })
        setRepayment({
          ...repayment,
          //amount: response.data.amount,
          debitAccount: response.data.accountNumber,
          branchCode: response.data.accountBranch,
          currency: response.data.currencyCode,
          loanProductId: response.data.id,
        });
        setDetails({
          accountName: response.data.accountName,
          amount: response.data.amount,
          currencyCode: response.data.currencyCode,
          accountBranch: response.data.accountBranch,
          loanBalance: response.data.loanBalance,
        });
      } else {
        setErrors({ ...errors, loanAccountNo: response.message });
        clearAccountDetails(true);
      }
    }
  };

  const fetchCustomerDetails = async (accNum) => {
    dispatch(isLoading())
    const response = await getCustomerByAccountNumber(token, accNum);
    if (response && response.data) {
      dispatch(isLoading())
      if (response.status) {
        setErrors({ ...errors, debitAccount: "" });
        setRepayment({...repayment, debitAccountBranch:response.data.branchCode});
        setDebitAccountDetails({
          debitAccountBranch: response.data.branchCode,
          accountName: response.data.accountName,
          accountBranch: response.data.branchCode,
          currencyCode: response.data.currency,
        });
        setRepayment({
          ...repayment,
          currency: response.data.currency,
        });
      } else {
        setErrors({ ...errors, debitAccount: response.message });
        clearCustomerDetails(true);
      }
    }
  };

  const clearCustomerDetails = (invalid) => {
    if (repayment.debitAccount.length < 10 || invalid) {
      setDebitAccountDetails({});
      setRepayment({
        ...repayment,
        currency: "",
      });
    }
  };

  const clearAccountDetails = (invalid) => {
    if (repayment.loanAccountNo === "" || invalid) {
      setDetails({});
      setRepayment({
        ...repayment,
        loanProductId: "",
        //debitAccount: "",
        branchCode: "",
        loanProductId: null,
      });
    }
  };

  useEffect(() => {
    if (accNum.length !== 10) {
      clearAccountDetails(true);
      setAllLoanAccNum([]);
    }
  }, [accNum]);

  useEffect(() => {
    clearAccountDetails(true);
  }, [repayment.loanAccountNo]);

  useEffect(() => {
    clearCustomerDetails(true);
  }, [repayment.debitAccount]);

  useEffect(() => {
    if (accNum.length === 10) {
      fetchAllLoanAccountNumber();
    }
  }, [accNum]);

  useEffect(() => {
    if (repayment.loanAccountNo) {
      fetchAllLoanRepaymentsByAccNum(repayment.loanAccountNo);
      fetchLoanAccountDetails(repayment.loanAccountNo);
    }
  }, [repayment.loanAccountNo]);

  useEffect(() => {
    if (repayment.debitAccount.length === 10) {
      fetchCustomerDetails(repayment.debitAccount);
    }
  }, [repayment.debitAccount]);

  const validateFirstScreen = () => {
    const errors = {};
    const { repaymentType, loanAccountNo, debitAccount, amount } = repayment;

    if (accNum === "") {
      setInvalidAccount("Field is required");
    }

    if (accNum.length !== 10) {
      setInvalidAccount("Ten characters are required");
    }

    if (accNum.length === 10 && allLoanAccountNum.length < 1) {
      setInvalidAccount("Could not find authorized loan");
    }

    if (repaymentType === "") {
      errors.repaymentType = "Invalid entry";
    }
    if (loanAccountNo === "") {
      errors.loanAccountNo = "Invalid entry";
    }

    if (amount === "") {
      errors.amount = "Field is required";
    }

    if (details.loanBalance && details.repaymentInterest && amount > details.loanBalance + details.repaymentInterest) {
      errors.amount = `You are about to pay the excess of ${
        repayment.currency
      } ${amount - (details.loanBalance + details.repaymentInterest)}`;
    }

    if (debitAccount === "") {
      errors.debitAccount = "Field is required";
    }

    if (debitAccount.length !== 10) {
      errors.debitAccount = "Ten characters are required";
    }

    if (debitAccount.length === 10 && !debitAccountDetails.accountName) {
      errors.debitAccount = "Invalid account number";
    }

    return errors;
  };

  const handleFirstScreen = () => {
    const checkErrors = validateFirstScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
    setScreenChange(2);
  };

  const postLoanRepayment = async () => {
    dispatch(isLoading())
    const response = await createLoanRepayment(token, repayment);
    if (response && response.data) {
      setMessage(response.message);
      dispatch(isLoading())
      if (response.status) {
        setAccountNumber("");
        fetchAllLoanRepaymentsByAccNum(repayment.loanAccountNo);
        setRepayment({
          sequenceId: null,
          repaymentType: "Transfer",
          loanProductId: null,
          loanAccountNo: "",
          branchCode: "",
          amount: 0,
          currency: "",
          debitAccountBranch: debitAccountBranch,
          debitAccount: "",
          denominations: [],
        });
        setScreenChange(1);
        return true;
      } else {
        return false;
      }
    }
  };

  const fetchAllCurrencyDefinition = async () => {
    const response = await getAllCurrencyDefinition(token);
    if (response && response.data) {
      setAllCurrencyDefinition(response.data);
    }
  };

  const fetchAllLoanRepayments = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllLoanRepayment(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllLoanRepayment(response.data);
    }
  }, [dispatch, token]);
  const [seqId, setSeqId] = useState(null);

  const [sumLoanRepayment, setSumLoanRepayment] = useState(0);

  const listOfPayment = (arr) => arr.map((item) => item.amount);

  const totalRepaymentAmount = (arr) =>
    listOfPayment(arr).reduce((total, amount) => total + amount);

  const getARepaymentDetails = (arr, loanAccNo, returnData = false) => {
    const list = [...arr], 
    curItem = list.find(item => item.loanAccountNo === loanAccNo);
    if (returnData) {
      return {
        repaymentInterest: curItem.repaymentInterest,
        repaymentPrincipal: curItem.repaymentPrincipal,
      };
    }
    if (curItem) {
      setRepaymentAccountDetails((prev) => ({
        ...prev,
        repaymentInterest: curItem.repaymentInterest,
        repaymentPrincipal: curItem.repaymentPrincipal,
      }));
    }
  };

  const fetchAllLoanRepaymentsByAccNum = async (loanAccNum) => {
    dispatch(isLoading())
    const response = await getLoanRepaymentByLoanAccNum(
      token,
      loanAccNum
    );
    const id = response.data.length + 1;
    const length = response.data.length;
    const sum = totalRepaymentAmount(
      length > 0 ? response.data : [{ amount: 0 }]
    );
    if (response && response.data) {
      const repaymentArray = response.data
        .sort((a,b) => (a.sequenceId > b.sequenceId)  // sort by sequenceId
          ? 1 
          : ((b.sequenceId > a.sequenceId) ? -1 : 0))
      setRepaymentSchedule(response.data);
      dispatch(isLoading())
      setSeqId(id);
      setSumLoanRepayment(sum);
      getARepaymentDetails(response.data,loanAccNum)
    }
  };

  const [amount, setAmount] = useState(0);

  const loanBalance = () => {
    // if (sumLoanRepayment && details.amount && repayment.loanProductId) {
    //   const difference = sumLoanRepayment - details.amount ;
    //   setDetails({ ...details, loanBalance: difference });
    //   setAmount(difference);
    // }

    // console.log(repaymentAccountDetails.repaymentInterest, details.repaymentInterest)
    setRepaymentAmount(details.loanBalance + repaymentAccountDetails.repaymentInterest);

  };

  useEffect(() => {
    loanBalance();
  }, [sumLoanRepayment, details.amount, repayment.loanProductId]);

  const handleSubmit = () => {
    repayment.sequenceId = seqId;
    // repayment.amount = repaymentAmount;
    const checkErrors = validateFirstScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setOpen(true);
  };
 
  const decide = async () => {
    dispatch(isLoading())
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await authorizeLoanRepayment(
      user.jwtToken,
      authorizeRepayment,
      view.id
    );
    if (response && response.data) {
      dispatch(isLoading())
      setMessage(response.message);
      if (response.statusCode === 200) {
        setOpenView(false);
        setOpenAuthorize(false);
        setAuthorizeRepayment({
          reason: "",
          isApproved: true,
          authorizationStatus: 0,
        });
        // getAllLoanRepayment();
        return true;
      } else {
        setMessage(response.message);
        return false;
      }
    }
  };

  useEffect(() => {
    // fetchAllLoanRepayments();
    //BankParameterByName();
    //fetchAllGlAccount();
    fetchAllCurrencyDefinition();
  }, []);

  return {
    repayment,
    accNum,
    allLoanRepayment,
    allLoanAccountNum,
    invalidAccount,
    details,
    debitAccountDetails,
    amount,
    setAmount,
    repaymentAccountDetails,
    addDenomination,
    removeDenomination,
    handleChange,
    handleAccNum,
    handleDenominationsChange,
    handleFirstScreen,
    postLoanRepayment,
    screenChange,
    setScreenChange,
    handleSubmit,
    errors,
    open,
    setOpen,
    loading,
    setDetails,
    allCurrencyDefinition,

    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    handleSearch,
    message,
    takeAction,
    setTakeAction,
    // setLoanId,
    openView,
    setOpenView,
    view,
    setView,
    openBoxAuthorize,
    setOpenAuthorize,
    openDecision,
    setOpenDecision,
    decide,
    authorizeRepayment,
    setAuthorizeRepayment,
    handleViewAll,
    repaymentSchedule,
    fullLoanDetails,
    setRepayment,   
     // fetchLoanAccountDetails,
    // fetchAllLoanRepaymentsByAccNum
  };
}

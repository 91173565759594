import { Grid } from '@material-ui/core';
import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/CoreModules/RateChart";
import Item from "../../../DatagridTable/item";

const View = ({details}) => {
    return (
        <DetailsContainer        
        title="Rate chart- Record Details"
        question="Are you sure you want to delete this record?"
        url={`${Routes.deleteRateChart}${details.id}`}
      >
         <Details details={details}/>
      </DetailsContainer>
    )
}

export default View

import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from '../../../Details/CoreModules/CurrencyDefinition'


const View = ({details}) => {
    return (
        <DetailsContainer        
        title="Currency Defintion - Record Details"
        question="Are you sure you want to delete this Currency ?"
        url={`${baseUrl}organization/CurrencyDefinition/Delete/${details.id}`}
      >
        <Details details={details}/>
      </DetailsContainer>
    )
}

export default View

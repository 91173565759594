import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";
import ErrorMessage from "../../../ErrorMessage";

const EditData = ({
  handleEditSubmit,
  handleChange,
  allCurrencyDefinition,
  details,
  error,
}) => {
  const data = {
    denomination: details.denomination,
    currency: details.currency,
    currencySymbol: details.currencySymbol,
    status: true,
  };
  return (
    <EditModal
      title="Update Denomination Definition"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={data}
      url={`${baseUrl}organization/DenomDefinition/Update/${details.id}`}
    >
      <div className="branch--calendar">
        <section className="section--1 flex--4">
          <div className="flex--3">
            <label>Denomination</label>
            <input
              type="text"
              name="denomination"
              onChange={handleChange}
              value={details.denomination}
              className={`${error.denomination ? "error-input-z" : ""}`}
            />
            {error.denomination && (
              <ErrorMessage message={error.denomination} />
            )}
          </div>
          <div className="label--input flex--3">
            <label>Currency</label>
            <select
              className={`currency ${error.currency ? "error-input-z" : ""}`}
              onChange={handleChange}
              value={details.currency}
            >
              <option value="">Select a currency</option>
              {allCurrencyDefinition.map(item => (
                <option value={item.currencyCode} key={item.id}>
                  {item.currencyName}
                </option>
              ))}
            </select>
            {error.currency && <ErrorMessage message={error.currency} />}
          </div>
          <div className="label--input flex--3">
            <label>Currency Symbol</label>
            <select
              className={`currency ${
                error.currencySymbol ? "error-input-z" : ""
              }`}
              disabled
              value={details.currencySymbol}
            >
              <option value="">Select a currency</option>
              {allCurrencyDefinition.map(item => (
                <option value={item.currencyCode} key={item.id}>
                  {item.currencyCode}
                </option>
              ))}
            </select>
            {error.currencySymbol && (
              <ErrorMessage message={error.currencySymbol} />
            )}
          </div>
        </section>
      </div>
    </EditModal>
  );
};

export default EditData;

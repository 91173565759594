import { createTheme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core';

const white = '#FFFFFF';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue[600],
    },
    secondary: {
      main: '#9B0116',
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400]
      },
      error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
      },
  },
});

export default theme;
import React, { useState } from 'react'
// import './LineChart.scss'
import { Bar } from 'react-chartjs-2';
import NoTransaction from './NoTransaction'
import MiniLoader from './MiniLoader';




const BarChart = ({data, loading}) => {
    const chartData = {
        // labels: ['Cash Dep', 'Cash Withd.', 'Fund Trf.', 'Buy Cash', 'Sell Cash'],
        labels: Object.keys(data),
        datasets:[
          {
            // data:[
            //   61,
            //   18,
            //   40,
            //   10,
            //   7
            // ],
            data: Object.values(data),
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              '#FA0A8A',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
            ]
          }
        ]
    }
    if(loading){
      return <MiniLoader/>
    }

    else if(!loading && Object.keys(data).length < 1){
      return <NoTransaction/>
    }
    else return (      
        <div className='BarChart'>
            <Bar
                data={chartData}
                options={{
                    title:{
                    display: true,
                    text:'',
                    fontSize:25,
                    position: 'top left'
                    },
                    legend:{
                    display: false,
                    position: 'bottom'
                    },
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true,
                            userCallback: function(label, index, labels) {
                              if (Math.floor(label) === label) {
                                  return label;
                              }
                            },
                        }
                      }],
                    },
                  }}
            />
        </div>
    )
}


export default BarChart

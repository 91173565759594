import "./Error.scss";
import ErrorIcon from '@material-ui/icons/Error'

const ErrorPage = ({message}) => {
  
  return (
    <main className="reset--password">
      <div className="heading">
        <p>Error</p>
      </div>



      <section className="section--1">
        <div className="flex--3 ErrorContent" style={{
          
        }}>
            <ErrorIcon color="error" style={{ fontSize: 150 }}/>
            <h2>{message}</h2>
        </div>
      </section>
    </main>
  );
};

export default ErrorPage;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useCustom from "../../../Api/Hooks-manager/accountOpening/useCustom";

import "./Accountsignatories.scss";
import useCustom2 from "../../../Api/Hooks-manager/coreModules/useCustom";
import ErrorMessage from "../../../components/ErrorMessage";

const Accountsignatories = ({
  handleChange,
  value,
  errors,
  addSignatory,
  removeSignatory,
  states,
}) => {
  // const [passportBase64, setPassportBase64] = useState("");
  // const [signatureBase64, setSignatureBase64] = useState("");
  const { setData } = useCustom();
  // const [image, setImage] = useState(null);
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);


  // console.log(errors, "thus says the Lord")
  const { allbankparameters, getBankParametersAll } = useCustom2(),
    countries = useSelector((state) => state.common.countries);
  useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);

  // console.log(value, "details");
  // console.log("it got here", value.accountSignatories);

  const parametersTitle = allbankparameters.filter((items) =>
    items.parameterName.toLowerCase().includes("cus_title")
  );

  const parametersIdType = allbankparameters.filter(
    (items) => items.parameterName === "ID_Type"
  );

  const handleChangeFile = async (e, index, type) => {
    const encodedFile = await encodeBase64(e.target.files[0]);
    let MAX_SIZE = 500000;

    if (e.target.files[0].size < MAX_SIZE) {
      const changeObject = {
        target: {
          name: type,
          value: encodedFile,
        },
      };

      handleChange(changeObject, index);
      setShow(false);
      setShow2(false);

    } else if (e.target.files[0].size > MAX_SIZE && type === "passport") {
      errors.passport =
        "Maximum file size of 500kb exceeded. Please reduce image size";
      setShow(true);
    } else if (e.target.files[0].size > MAX_SIZE && type === "signature") {
      errors.signature =
        "Maximum file size of 500kb exceeded. Please reduce image size";
      setShow2(true);
    }
  };

  // const handleChangeFile = async (e, index, type) => {
  //   const encodedFile = await encodeBase64(e.target.files[0]);
  //   const changeObject = {
  //     target: {
  //       name: type,
  //       value: encodedFile
  //     }
  //   }
  //   handleChange(changeObject, index)

  // };
  // const toBase64 = (file) =>
  // new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = (error) => reject(error);
  // });

  // const handleChangeFile = async (e, type) => {
  //   const file = e.target.files[0];
  //   if (file && file.type.substring(0, 5) === "image") {
  //     const fileBase64 = await toBase64(file);
  //     // console.log(fileBase64);
  //     // setImage(fileBase64);
  //     setImage(fileBase64);
  //   }
  // };

  // useEffect(() => {
  //   if (passportBase64) {
  //     value.accountSignatories[0].passport = passportBase64;
  //   }
  //   passportBase64 &&
  //     setData((prevState) => ({ ...prevState, passport: passportBase64 }));
  // }, [passportBase64]);

  // useEffect(() => {
  //   signatureBase64 &&
  //     setData((prevState) => ({ ...prevState, signature: signatureBase64 }));
  //   if (signatureBase64) {
  //     value.signature = passportBase64;
  //   }
  // }, [signatureBase64]);

  const encodeBase64 = (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.log("error: ", error);
          reject("");
        };
      }
    });
  };

  return (
    <main className="account--signatories">
      <section style={{ padding: "20px 50px" }}>
        <div className="checboxdiv">
          <div className="flex--2 checkbox-cont">
            <input
              type="checkbox"
              className="productcheckbox"
              disabled={true}
              checked={false}
              onChange={() => {}}
            />
            <label className="span">Customer Is a Signatory?</label>
          </div>
          <div>
            <button onClick={() => addSignatory()} className="checkbtn">
              Add New
            </button>
          </div>
        </div>
        {value.accountSignatories.map((item, i) => (
          <div
            style={{
              border: "1px solid",
              padding: "10px",
              marginBottom: "20px",
            }}
            key={i}
          >
            <div className="product--def flex--1">
              <div className="flex--3">
                <label>Title</label>
                <select
                  onChange={(e) => handleChange(e, i)}
                  value={item.title}
                  name="title"
                  disabled={value.customerType === "Individual"}
                  className={
                    errors.title && errors.title.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                >
                  <option>Select An Option</option>
                  {parametersTitle.map((title, index) => {
                    return (
                      <option key={index} value={title.parameterValue}>
                        {title.parameterValue}
                      </option>
                    );
                  })}
                  {/* <option>Select An Option</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option> */}
                </select>
                {errors.title && errors.title.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.lastName}
                  className={
                    errors.lastName && errors.lastName.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.lastName &&
                  errors.lastName.find((el) => el.id === i) && (
                    <ErrorMessage message="Invalid entry" />
                  )}
              </div>
              <div className="flex--3 input--3">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.firstName}
                  className={
                    errors.firstName &&
                    errors.firstName.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.firstName &&
                  errors.firstName.find((el) => el.id === i) && (
                    <ErrorMessage message="Invalid entry" />
                  )}
              </div>
              <div className="flex--3 input--3">
                <label>Middle Name</label>
                <input
                  type="text"
                  name="middleName"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.middleName}
                />
              </div>
              <div className="flex--3">
                <label>Gender</label>
                <select
                  name="gender"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.gender}
                  className={
                    errors.gender && errors.gender.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                >
                  <option>Select An Option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errors.gender && errors.gender.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.email}
                  className={
                    errors.email && errors.email.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.email && errors.email.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phonenumber"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.phonenumber}
                  className={
                    errors.phonenumber &&
                    errors.phonenumber.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.phonenumber &&
                  errors.phonenumber.find((el) => el.id === i) && (
                    <ErrorMessage message="Invalid entry" />
                  )}
              </div>
              <div className="flex--3 input--3">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.address}
                  className={
                    errors.address && errors.address.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.address && errors.address.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Country</label>
                <select
                  value={item.country}
                  name="country"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  className={
                    errors.country && errors.country.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                >
                  <option>Select An Option</option>
                  {countries && countries.length > 0
                    ? countries.map((country, i) => {
                        return (
                          <option key={i} value={country.countryCode2}>
                            {country.name}
                          </option>
                        );
                      })
                    : null}
                </select>
                {errors.country && errors.country.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>State</label>
                {value.customerType === "Individual" ? (
                  <input
                    name="state"
                    onChange={(e) => handleChange(e, i)}
                    disabled
                    value={item.state}
                    className={
                      errors.state && errors.state.find((el) => el.id === i)
                        ? "error-input-z"
                        : null
                    }
                  />
                ) : (
                  <select
                    value={item.state}
                    name="state"
                    onChange={(e) => handleChange(e, i)}
                    disabled={value.customerType === "Individual"}
                    className={
                      errors.state && errors.state.find((el) => el.id === i)
                        ? "error-input-z"
                        : null
                    }
                  >
                    <option>Select An Option</option>
                    {states && states.length > 0
                      ? states.map((state, i) => {
                          return (
                            <option key={state.id} value={state.name}>
                              {state.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                )}

                {errors.state && errors.state.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Nationality</label>
                <select
                  value={item.nationality}
                  name="nationality"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  className={
                    errors.nationality &&
                    errors.nationality.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                >
                  <option>Select An Option</option>
                  {countries && countries.length > 0
                    ? countries.map((country, i) => {
                        return (
                          <option key={i} value={country.countryCode2}>
                            {country.name}
                          </option>
                        );
                      })
                    : null}
                </select>
                {errors.nationality &&
                  errors.nationality.find((el) => el.id === i) && (
                    <ErrorMessage message="Invalid entry" />
                  )}
              </div>
              <div className="flex--3 input--3">
                <label>KYC</label>
                <select
                  value={item.kyc}
                  name="kyc"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  className={
                    errors.kyc && errors.kyc.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                >
                  <option>Select An Option</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                </select>
                {errors.kyc && errors.kyc.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>BVN</label>
                <input
                  type="text"
                  name="bvn"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.bvn}
                  className={
                    errors.bvn && errors.bvn.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.bvn && errors.bvn.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>TIN</label>
                <input
                  type="text"
                  name="tin"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.tin}
                  className={
                    errors.tin && errors.tin.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.tin && errors.tin.find((el) => el.id === i) && (
                  <ErrorMessage message="Invalid entry" />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Identification Type</label>
                <select
                  value={item.identificationType}
                  name="identificationType"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  className={
                    errors.identificationType &&
                    errors.identificationType.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                >
                  <option>Select An Option</option>
                  {parametersIdType.map((idtype, index) => {
                    return (
                      <option key={index} value={idtype.parameterValue}>
                        {idtype.parameterValue}
                      </option>
                    );
                  })}
                </select>
                {errors.identificationType &&
                  errors.identificationType.find((el) => el.id === i) && (
                    <ErrorMessage message="Invalid entry" />
                  )}
              </div>
              <div className="flex--3 input--3">
                <label>Identification Number</label>
                <input
                  type="text"
                  name="identificationNumber"
                  onChange={(e) => handleChange(e, i)}
                  disabled={value.customerType === "Individual"}
                  value={item.identificationNumber}
                  className={
                    errors.identificationNumber &&
                    errors.identificationNumber.find((el) => el.id === i)
                      ? "error-input-z"
                      : null
                  }
                />
                {errors.identificationNumber &&
                  errors.identificationNumber.find((el) => el.id === i) && (
                    <ErrorMessage message="Invalid entry" />
                  )}
              </div>

              

              {value.customerType !== "Individual" && (
                <>
                  <div className="flex--3 input--3">
                    <label>Passport Photo</label>
                    <input
                      type="file"
                      onChange={(e) => handleChangeFile(e, i, "passport")}
                      // onChange={handleChangeFile}
                      accept="image/*"
                      className={`input--file ${
                        Object.keys(errors).includes("passport") &&
                        "error-input-z"
                      }`}
                    />
                    {show && errors.passport && errors.passport && (
                      <ErrorMessage message={errors.passport} />
                    )}
                  </div>
                </>
              )}

              {value.customerType !== "Individual" && (
                <div className="flex--3 input--3">
                  <label>Signature</label>
                  <input
                    type="file"
                    onChange={(e) => handleChangeFile(e, i, "signature")}
                    // onChange={handleChangeFile}
                    accept="image/*"
                    className={`input--file ${
                      Object.keys(errors).includes("signature") &&
                      "error-input-z"
                    }`}
                  />
                  {show2 && errors.signature && errors.signature && (
                    <ErrorMessage message={errors.signature} />
                  )}
                </div>
              )}
            </div>
            <div className="dbtn">
              <button className="btnd" onClick={() => removeSignatory(i)}>
                Delete
              </button>
            </div>
          </div>
        ))}
      </section>
    </main>
  );
};
export default Accountsignatories;

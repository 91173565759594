import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import DecisionModal from "../../Confirmbox/decision";
import ResponseModal from "../../Confirmbox/Modal";
import {
  closeDeleteDecisionModal,
  closeDeleteResponseModal,
  deleteItem,
} from '../../Api/redux-manager/actions'

const useStyles = makeStyles((theme) => ({
  children:{
    padding:"1.5rem 0px"
  }
}));

const Modal = ({
  question,
  url,
  method,
  data
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const open = useSelector(state => state.req.openDeleteDecisionModal);
  const openResponseModal = useSelector(state => state.req.openDeleteResponseModal);
  const status = useSelector(state => state.req.status);
  const message = useSelector(state => state.req.message);
  
  return (
    <div>
      <DecisionModal
        question={question}
        openDialog={open}
        onClick={() => dispatch(deleteItem(token,url,method,data))}
        onClose={() => dispatch(closeDeleteDecisionModal())}
      />
      <ResponseModal
        openDialog={openResponseModal}
        onClose={() => dispatch(closeDeleteResponseModal())}
        positiveDialog={status}
      >
        <div className={classes.children}>
        {message}
        </div>
      </ResponseModal>
    </div>
  );
};

export default Modal;

import React, { useState } from "react";
import "./CustomerDebit.scss";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import BusinessLogic from "./businessLogic";
import { useDispatch } from "react-redux";

import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import NumberFormat from "react-number-format";
import Details from "./View";


const CustomerDebit = () => {
  const {
    glTransaction,
    setGlTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    errors,
    details,
    handleChange,
    makeTransfer,
    open,
    setOpen,
    message,
    handleSubmit,
    allBranches,
    fetchCustomerDetails,
  } = BusinessLogic();
  const dispatch = useDispatch();

  const { denominations } = glTransaction;

  return (
    <div className="customer--debit">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={makeTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <div className="headerdiv">
        <p>Miscellaneous Customer debit</p>
      </div>
      <Paper style={{ padding: 25 }}>
          <div className="input-container--1 flex--1">
        <div className="flex--3">
            <label> Account Number</label>
            <input
              type="number"
              className={`pink ${errors.debitAccount ? "error-input-z" : null}`}
              name="debitAccount"
              value={glTransaction.debitAccount}
              onChange={handleChange}
            />
            {errors.debitAccount && (
              <ErrorMessage message={errors.debitAccount} />
            )}
            </div>
            <div className="flex--3">
            <label style={{ color: "white" }}>Account Name</label>
            <input
              placeholder=""
              name="cusaccountName"
              disabled
              value={debitAccDetails.accountName}
            />
          </div>
          {/* <div className="flex--3">
            <label>Account Branch</label>
            <select
            disabled
            className="pink"
              name="entryBranch"
              value={glTransaction.entryBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
          </div> */}
          <div className="flex--3">
            <label>Debit Branch</label>
            <select
              name="debitAccountBranch"
              value={debitAccDetails.accountBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
          </div>
          </div>
          <div className="input-container--1 flex--1">
          <div className="flex--3">
            <label>Account Currency</label>
            <input
              type="text"
              disabled
              value={debitAccDetails.accountCurrency}
            />
          </div>
          <div className="flex--3">
            <label>BVN</label>
            <input
              type="text"
              name="bvn"
              disabled
              value={debitAccDetails.bvn}
            />
          </div>

          {glTransaction && glTransaction.debitAccount && <div className="customerDebitAccBal">
            <label>Account Balance</label>
              <br />
            <NumberFormat
              thousandsGroupStyle="thousand"
              disabled={true}
              thousandSeparator={true}
              inputMode="numeric"
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              value={debitAccDetails.availableBalance}
              className="acctBalance"
            />
          </div>}
          {glTransaction && glTransaction.debitAccount && <div>
            <br/>
            <button onClick={() => {
              const singleItem = debitAccDetails;
              if (singleItem && singleItem){
                fetchCustomerDetails(glTransaction.debitAccount);
                dispatch(openViewModal());

              }
            }} className="customerDebitDetails">View Account Instructions</button>
          </div>}

          </div>
          <div className="input-container--1 flex--1">
          <div className="flex--3">
            <label> GL Account Number</label>
            <input
              type="text"
              className="pink"
              name="creditAccount"
              value={glTransaction.creditAccount}
              onChange={handleChange}
            />
            {errors.creditAccount && (
              <ErrorMessage message={errors.creditAccount} />
            )}
            </div>
          <div className="flex--3">
            <label style={{ color: "white" }}>Des.Acc Name</label>
            <input
              type="text"
              disabled
              value={creditAccDetails.accountName}
            />
            </div>
          {/* <div className="flex--3">
            <label>GL Account branch</label>
              <select
              disabled
              name="entryBranch"
              value={glTransaction.entryBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
            </div> */}
            <div className="flex--3">
            <label>Credit branch</label>
              <select
              name="creditAccountBranch"
              value={creditAccDetails.accountBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
            </div>
          </div>
          <div className="input-container--1 flex--1">
          <div className="flex--3">
            <label>Account Currency</label>
            <select disabled value={glTransaction.currencyCode}>
              <option value='NGN'>Naira</option>
            </select>
          </div>
          <div className="flex--3">
            <label>Amount</label>
            <NumberFormat
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="postAmount"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                className={`${errors.debitAccount ? "error-input-z" : null}`}
                // value={glTransaction.postAmount}
                onValueChange={({floatValue})=>handleChange({
                  target: {
                    name:'postAmount', 
                    value: floatValue
                  }
                })}
                // onValueChange={(values) => {
                //   setGlTransaction((prev) => ({
                //     ...prev,
                //     postAmount: parseInt(values.value),
                //   }));
                // }}
              />
            {/* <input
             type="number"
             name="postAmount"
             className={`${errors.debitAccount ? "error-input-z" : null}`}
             value={glTransaction.postAmount.toFixed(2)}
             onChange={handleChange}
            /> */}
            {errors.postAmount && (
              <ErrorMessage message={errors.postAmount} />
            )}
          </div>
          </div>
          <div className="flex--3">
            <label>Narration</label>
            <input type="text"
            name="narration"
            onChange={handleChange}
            value={glTransaction.narration}
             />
          </div>
        <button className="btn" onClick={handleSubmit}>
          {" "}
          Save
        </button>
        {details && <Details details={details} glTransaction={glTransaction}/>}
      </Paper>
    </div>
  );
};
export default CustomerDebit;

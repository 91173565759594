 import Loader from '../../assets/loaderio.svg'


function BackDrop(props) {
  return (
    <div>
     {props.loading && <div className='flex--2 loader'>
          <img src={Loader} alt=''/>
      </div>}
    </div>
  );
} 


export default BackDrop;

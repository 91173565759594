import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Container from "@material-ui/core/Container";

import Card from "../../../../Card/Card";
import columns from "./columns";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  root: {
   padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: theme.spacing(2),
  },
}));

function Table({ records, show , sumOfCredit, sumOfDebit}) {
  const classes = useStyles();
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    // onRowClick: (rowData, rowMeta) => {
    //   const index = rowMeta.dataIndex;
    //   const item = records[index];
    //   if (item && item._id) {
    //     handleOpenDetails(item);
    //   }
    // },
  };

  return (
    <Zoom in={show} timeout={1000}>
      <div className={classes.root}>
        <div className={classes.container}>
          <Card
            title="Transactions table"
            subtitle={`Sum of credit: ${sumOfCredit} || Sum of debit: ${sumOfDebit}`}
          >
            <Grid container justify="center">
              <Grid item sm={12}>
                <Container maxWidth="lg">
                  <MUIDataTable
                    title={"BULK TRANSACTIONS"}
                    data={records}
                    columns={columns}
                    options={options}
                  />
                </Container>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </Zoom>
  );
}

export default Table;

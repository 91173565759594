import {useState} from 'react';
import './VerifyCreatedUser.scss';
import logo from '../../assets/mainlogo.png';
// import Uhurulogo from '../../assets/uhurulogo.png'
import NobleServelogo from '../../assets/nobleserve.png';
import OpenMail from '../../assets/open-mail.png';
import {useParams} from 'react-router-dom';
import useForm from "../../Api/Hooks-manager/useCustom";
import Spinner from "../Backdrop/index";
import NewModal from "../../Confirmbox/Modal";
import {verifyAllUsers} from "../../Api/Hooks-manager/verifyUser/userVerification";

const VerifyCreatedUser = (props) => {
    console.log('what is in props', props)
    let {usertoken} = useParams();

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [positiveDialog, setPositiveDialog] = useState(false);
    const [status, setStatus] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const token = props.match.params.usertoken;
    const [user, setUser] = useState({token}),
      [responseError, setResponseError] = useState("");

    const verificationForUsers = async () => {
        setLoading(true)
        const response = await verifyAllUsers (token);
        console.log('-----I want to---- ', response)
        if (response && response.data){
          setDialogMessage(response.message);
          setLoading(false);
          if(response.status) {
            setDialogTitle("Successful");
            setOpenDialog(true);
            setStatus(true);
            setPositiveDialog(true)
          }
          else{
            setDialogTitle("UnSuccessful");
            setOpenDialog(true)
            setPositiveDialog(false);
            setStatus(false);
          }
        }
    }

    const resetLogin = () =>{
        if(token){
          props.history.push('/')
        }else {
          setOpenDialog(false)
        }
      }
    return (
        <main className="verify--user ">
            <Spinner loading={loading} />
            <NewModal
            title={dialogTitle}
            openDialog={openDialog}
            message="Your Email has been verified"
            positiveDialog={positiveDialog}
            onClose={resetLogin}
            >
            {dialogMessage}
            </NewModal>

            {/* <img src={logo} alt=''/> */}
                <img src={logo} alt="" />
            <section className="section--1 flex--3">
                <img src={OpenMail} alt='' className='open--mail'/>
                <div className="verify--section-1">
                    <div class="content flex--3">
                        <h4>Please verify your email address</h4>
                        <button onClick={verificationForUsers}>Verify Email</button>
                    </div>

                </div>
            </section>
                <img src={NobleServelogo} alt="" className="logo-uhuru" />  
        </main>
    )
}

export default VerifyCreatedUser

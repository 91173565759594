import axios from 'axios';
import { Routes } from '../../configUrl';



const getAllBalanceSheets = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getBalanceSheetReport}${data.From}/${data.To}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};
const getAllBalanceSheetsReports = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getBalanceSheetReports}${data.workDate}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};const getAllBalanceHistory = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getBalanceHistory}${data.workDate}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};const getAllIncomeStatement = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getIncomeStatement}${data.workDate}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};const getAllTrialBalance = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getTrialBalance}${data.workDate}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};const getAllCashMovement = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getCashMovement}${data.workDate}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};
  export{getAllBalanceSheets,
    getAllBalanceSheetsReports,
    getAllTrialBalance,
    getAllCashMovement,
    getAllIncomeStatement,
    getAllBalanceHistory,}
import React from 'react';
import './Main.scss';
import { Switch, Route} from "react-router-dom";
import CustomerCredit from './CustomerCredit/CustomerCredit';
import CustomerDebit from './CustomerDebit/CustomerDebit';
import GlTransfers from './GlTransfers/GlTransfers';
import SingledebitMultiplecredit from './SingledebitMultiplecredit/SingledebitMultiplecredit';
import TellerTransactions from './TellerTransactions/TellerTransactions';
import BulkJournalPosting from './BulkJournalPosting/BulkJournalPosting';
import FundsTransfer from './FundTransfer/FundTransfer'
import BulkTransaction from './BulkTransaction/BulkTransaction'

 const Main = () => {
    return ( 
        <div className='Main'>
            <Switch>
                <Route path='/dashboard/miscellaneoustransactions/customercredit' component={CustomerCredit }/> 
                <Route path='/dashboard/miscellaneoustransactions/customerdebit' component={CustomerDebit}/> 
                <Route path='/dashboard/miscellaneoustransactions/gltransfers' component={GlTransfers }/> 
                <Route path='/dashboard/miscellaneoustransactions/singledebitmultiplecredit' component={SingledebitMultiplecredit }/> 
                <Route path='/dashboard/miscellaneoustransactions/tellertransaction' component={TellerTransactions }/> 
                <Route path='/dashboard/miscellaneoustransactions/bulkjournalposting' component={BulkJournalPosting }/> 
                <Route path='/dashboard/miscellaneoustransactions/fundstransfer' component={FundsTransfer}/> 
                <Route path='/dashboard/miscellaneoustransactions/bulktransaction' component={BulkTransaction}/> 
            </Switch> 
        </div>
    )
}
export default Main;
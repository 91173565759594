import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from "../../../../Api/configUrl";
import SearchDropdown from "../../../SearchableDropdown/Search";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  checkbox: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& input": {
      height: 15,
      borderRadius: 0,
      width: 20,
      marginLeft: 0,
    },
  },
  expand: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  border: {
    border: "1px solid black",
    marginBottom: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttondiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  item: {
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const EditData = ({
  handleEditSubmit,
  handleFieldUpdate,
  handleEditChangeSelect,
  details,
  errorEdit,
  allCurrencyDefinition,
  allRateChart,
  errorsOfOtherServicesOtherthanProductDetails,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);

  const displayErr = (i, name) => {
    return (
      errorsOfOtherServicesOtherthanProductDetails.index === i &&
      errorsOfOtherServicesOtherthanProductDetails.type === name
    );
  };;
  const [show, setShow] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0)
  },[details])

  console.log(details);

  return (
    <EditModal
      title="Investment Products"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this Investment Product record?"
      data={details}
      url={`${baseUrl}investment/InvestmentProduct/Update/${details.id}`}
    >
      <section className={classes.root}>
        <div style={{ marginTop: 50, padding: 25 }}>
          <>
          <div className={classes.root}>
          <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Product Details (1)"
                    {...a11yProps(0)}
                    onClick={() => setHide(false)}
                  />
                  <Tab
                    label="Product Details (2)"
                    {...a11yProps(1)}
                    onClick={() => setHide(true)}
                  />

                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Grid container spacing={3} className={classes.root}>
                  <Grid item xs={12} sm={12} md={6}>
                    <label>Product Code</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="productCode"
                      disabled
                      value={details.productCode}
                      className={`${
                        Object.keys(errorEdit).includes("productCode") && "error-input-z"
                      }`}
                      type="text"
                      maxLength="6"
                    />
                    {errorEdit.productCode && (
                      <ErrorMessage message={errorEdit.productCode} />
                    )}
                    <div className={classes.checkbox}>
                      <input
                        type="checkbox"
                        name="productStatus"
                        checked={details.productStatus}
                        onChange={handleFieldUpdate}
                      />
                      <p>Deactivate Product</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label>Product Name</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="productName"
                      disabled
                      value={details.productName}
                      className={`${
                        Object.keys(errorEdit).includes("productName") && "error-input-z"
                      }`}
                      type="text"
                    />
                    {errorEdit.productName && (
                      <ErrorMessage message={errorEdit.productName} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Currency</label>
                    <select
                      name="currency"
                      value={details.currency}
                      onChange={handleFieldUpdate}
                      className={`${
                        Object.keys(errorEdit).includes("currency") && "error-input-z"
                      }`}
                    >
                      <option value="">Select An Option</option>
                      {allCurrencyDefinition.map((item) => (
                        <option value={item.currencyCode} key={item.id}>
                          {item.currencyName}
                        </option>
                      ))}
                    </select>
                    {errorEdit.currency && <ErrorMessage message={errorEdit.currency} />}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Default Inv. Amount</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="defaultInvAmount"
                      value={details.defaultInvAmount}
                      className={`${
                        Object.keys(errorEdit).includes("defaultInvAmount") &&
                        "error-input-z"
                      }`}
                      type="number"
                    />
                    {errorEdit.defaultInvAmount && (
                      <ErrorMessage message={errorEdit.defaultInvAmount} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Minimum Inv. Amount</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="minimumInvAmount"
                      value={details.minimumInvAmount}
                      className={`${
                        Object.keys(errorEdit).includes("minimumInvAmount") &&
                        "error-input-z"
                      }`}
                      type="number"
                    />
                    {errorEdit.minimumInvAmount && (
                      <ErrorMessage message={errorEdit.minimumInvAmount} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Maximum Inv. Amount</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="maximumInvAmount"
                      value={details.maximumInvAmount}
                      className={`${
                        Object.keys(errorEdit).includes("maximumInvAmount") &&
                        "error-input-z"
                      }`}
                      type="number"
                    />
                    {errorEdit.maximumInvAmount && (
                      <ErrorMessage message={errorEdit.maximumInvAmount} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Default Tenor</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="defaultTenor"
                      value={details.defaultTenor}
                      className={`${
                        Object.keys(errorEdit).includes("defaultTenor") && "error-input-z"
                      }`}
                      type="number"
                    />
                    {errorEdit.defaultTenor && (
                      <ErrorMessage message={errorEdit.defaultTenor} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Minimum Tenor</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="minimumTenor"
                      value={details.minimumTenor}
                      className={`${
                        Object.keys(errorEdit).includes("minimumTenor") && "error-input-z"
                      }`}
                      type="number"
                    />
                    {errorEdit.minimumTenor && (
                      <ErrorMessage message={errorEdit.minimumTenor} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Maximum Tenor</label>
                    <input
                      onChange={handleFieldUpdate}
                      name="maximumTenor"
                      value={details.maximumTenor}
                      className={`${
                        Object.keys(errorEdit).includes("maximumTenor") && "error-input-z"
                      }`}
                      type="number"
                    />
                    {errorEdit.maximumTenor && (
                      <ErrorMessage message={errorEdit.maximumTenor} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Tenor Basis</label>
                    <select
                      name="tenorBasis"
                      value={details.tenorBasis}
                      onChange={handleFieldUpdate}
                      className={`${
                        Object.keys(errorEdit).includes("tenorBasis") && "error-input-z"
                      }`}
                    >
                      <option>Select An Option</option>
                      <option value="Days">Days</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                    {errorEdit.tenorBasis && (
                      <ErrorMessage message={errorEdit.tenorBasis} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Rate Type</label>
                    <select
                      value={details.rateType}
                      name="rateType"
                      onChange={handleFieldUpdate}
                      className={`${
                        Object.keys(errorEdit).includes("rateType") && "error-input-z"
                      }`}
                    >
                      <option value="">Select An Option</option>
                      <option value="Flat">Fixed</option>
                      <option value="RateCharts">Rate Chart</option>
                    </select>
                    {errorEdit.rateType && <ErrorMessage message={errorEdit.rateType} />}
                  </Grid>
                  {details.rateType !== "Flat" && (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Rate Chart Code</label>
                        <div
                          className={`border--norm ${
                            Object.keys(errorEdit).includes("rateChatCode") &&
                            "error-input-z"
                          }`}
                        >
                          <SearchDropdown
                            options={allRateChart}
                            onChange={handleEditChangeSelect}
                            type="rateChatCode"
                          />
                        </div>
                        {errorEdit.rateChatCode && (
                          <ErrorMessage message={errorEdit.rateChatCode} />
                        )}
                      </Grid>
                    </>
                  )}

                  {details.rateType !== "RateCharts" && (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Default Interest Rate</label>
                        <input
                          onChange={handleFieldUpdate}
                          name="defaultInterestRate"
                          step="any"
                          value={details.defaultInterestRate}
                          disabled={!details.rateType}
                          className={`${
                            Object.keys(errorEdit).includes("defaultInterestRate") &&
                            "error-input-z"
                          }`}
                          type="number"
                        />
                        {errorEdit.defaultInterestRate && (
                          <ErrorMessage message={errorEdit.defaultInterestRate} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Minimum Interest Rate</label>
                        <input
                          onChange={handleFieldUpdate}
                          name="minimumInterestRate"
                          step="any"
                          disabled={!details.rateType}
                          value={details.minimumInterestRate}
                          className={`${
                            Object.keys(errorEdit).includes("minimumInterestRate") &&
                            "error-input-z"
                          }`}
                          type="number"
                        />
                        {errorEdit.minimumInterestRate && (
                          <ErrorMessage message={errorEdit.minimumInterestRate} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Maximum Interest Rate</label>
                        <input
                          onChange={handleFieldUpdate}
                          name="maximumInterestRate"
                          step="any"
                          disabled={!details.rateType}
                          value={details.maximumInterestRate}
                          className={`${
                            Object.keys(errorEdit).includes("maximumInterestRate") &&
                            "error-input-z"
                          }`}
                          type="number"
                        />
                        {errorEdit.maximumInterestRate && (
                          <ErrorMessage message={errorEdit.maximumInterestRate} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Pre Liquidation Penalty Rate</label>
                        <input
                          onChange={handleFieldUpdate}
                          name="preLiquidationPenaltyRate"
                          step="any"
                          disabled={!details.rateType}
                          value={details.preLiquidationPenaltyRate}
                          className={`${
                            Object.keys(errorEdit).includes("preLiquidationPenaltyRate") &&
                            "error-input-z"
                          }`}
                          type="number"
                        />
                        {errorEdit.preLiquidationPenaltyRate && (
                          <ErrorMessage message={errorEdit.preLiquidationPenaltyRate} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Rate Basis</label>
                        <select
                          disabled={!details.rateType}
                          value={details.rateBasis}
                          name="rateBasis"
                          onChange={handleFieldUpdate}
                          className={`${
                            Object.keys(errorEdit).includes("rateBasis") &&
                            "error-input-z"
                          }`}
                        >
                          <option>Select An Option</option>
                          <option value="PerAnnum">Per Annum</option>
                          <option value="PerMonth">Per Month</option>
                        </select>
                        {errorEdit.rateBasis && (
                          <ErrorMessage message={errorEdit.rateBasis} />
                        )}
                      </Grid>
                    </>
                  )}

                  {details.rateType !== "Flat" && (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Minimum Variance</label>
                        <input
                          onChange={handleFieldUpdate}
                          disabled={!details.rateType}
                          name="minimumVariance"
                          value={details.minimumVariance}
                          className={`${
                            Object.keys(errorEdit).includes("minimumVariance") &&
                            "error-input-z"
                          }`}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Maximum Variance</label>
                        <input
                          onChange={handleFieldUpdate}
                          disabled={!details.rateType}
                          name="maximumVariance"
                          value={details.maximumVariance}
                          className={`${
                            Object.keys(errorEdit).includes("maximumVariance") &&
                            "error-input-z"
                          }`}
                          type="number"
                        />
                        {errorEdit.maximumVariance && (
                          <ErrorMessage message={errorEdit.maximumVariance} />
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    {/* <p
                      className={classes.expand}
                      onClick={() => setShow((prev) => !prev)}
                    >
                      {show ? "Show less" : "Show more"}
                    </p> */}


                  </Grid>
                </Grid>

              </TabPanel>


              <TabPanel value={value} index={1}>
                <Grid container spacing={1}>
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <div className={classes.checkbox}>
                            <input
                              type="checkbox"
                              name="accrualRequired"
                              checked={details.accrualRequired}
                              onChange={handleFieldUpdate}
                            />
                            <p>Accrual Required</p>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <div className={classes.checkbox}>
                            <input
                              type="checkbox"
                              name="prematureLiqAllowed"
                              checked={details.prematureLiqAllowed}
                              onChange={handleFieldUpdate}
                            />
                            <p>Premature Liq Allowed</p>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <div className={classes.checkbox}>
                            <input
                              type="checkbox"
                              name="prematureLiqAllowed"
                              checked={details.automaticRollover}
                              onChange={handleFieldUpdate}
                            />
                            <p>Automatic Rollover</p>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <div className={classes.checkbox}>
                            <input
                              type="checkbox"
                              name="applyTax"
                              checked={details.applyTax}
                              onChange={handleFieldUpdate}
                            />
                            <p>Apply Tax</p>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.checkbox}>
                            <input
                              type="checkbox"
                              name="invest"
                              checked={details.invest}
                              onChange={handleFieldUpdate}
                            />
                            <p>Penalty On Premature Liq</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Accrual Period</label>
                          <input
                            disabled
                            value={details.accrualPeriod}
                            name="accrualPeriod"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Product Account</label>
                          <input
                            disabled
                            value={details.productAccount}
                            name="productAccount"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Interest Payable Account</label>
                          <input
                            disabled
                            value={details.interestPayableAccount}
                            name="interestPayableAccount"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <label>Balance Basis</label>
                          <input
                            disabled
                            value={details.balanceBasis}
                            name="balanceBasis"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Penal Income</label>
                          <input
                            disabled
                            value={details.penalIncome}
                            name="penalIncome"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Interest Expense</label>
                          <input
                            disabled
                            value={details.interestExpense}
                            name="interestExpense"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <label>At Maturity</label>
                          <input
                            disabled
                            value={details.atMaturity}
                            name="atMaturity"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Unclaimed Int Account</label>
                          <input
                            disabled
                            value={details.unclaimedInterestAccount}
                            name="unclaimedInterestAccount"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Withholding Tax Account</label>
                          <input
                            disabled
                            value={details.withHoldingTaxAccount}
                            name="withHoldingTaxAccount"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <label>Interest Calculation Period</label>
                          <input
                            disabled
                            value={details.interestCalculationPeriod}
                            name="interestCalculationPeriod"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Unclaimed Inv Account</label>
                          <input
                            disabled
                            value={details.unClaimedInvestmentAccount}
                            name="unClaimedInvestmentAccount"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <label>Interest Period</label>
                          <input
                            disabled
                            value={details.interestPeriod}
                            name="interestPeriod"
                            onChange={handleFieldUpdate}
                          />
                        </Grid>
                      </Grid>
                </Grid>
              </TabPanel>

          </div>
          </>
        </div>
      </section>
    </EditModal>
  );
};

export default EditData;

import React from 'react'
import './LiquidationWalkin.scss'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const LiquidationWalkin = () => {
    const [screenChange, setScreenChange] = React.useState(false)

    const handleScreenChange = (i) => {
        console.log('qwswqeq', i)
        setScreenChange(i)
    }

    return (
        <main className='LiquidationWalkin'>
            <section className='section--1'>
                <div className='heading'>
                    <p>Managers Cheque - Liquidation Walkin</p> 
                </div>
                <div className='flex--3'>
                    <div className='button--double flex--2'>
                        <div className='flex--3'>
                            <button className={`${!screenChange ? 'active-1' : ''}`} onClick={() => handleScreenChange(false)}>1</button> 
                            <p>Transaction</p>
                        </div>
                        <div className='flex--3'>
                            <button  className={`${screenChange ? 'active-1' : ''}`} onClick={() => handleScreenChange(true)}>2</button>
                            <p>Denominations</p>
                        </div>
                    </div>
                    {
                    !screenChange && (
                    <>
                    <div className='transaction-grid'>
                    <Paper style={{paddingBottom:30, paddingLeft:30, paddingRight:30, }}> 
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                        <label>Transaction Type</label> 
                        <select>
                                       <option>Bankers Cheque Liquidation Against Walkin</option>
                                       <option></option>
                                   </select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        <label>Currency</label> 
                        <select>
                                       <option>NGN</option>
                                       <option></option>
                                   </select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        <label>Till Account No</label>
                        <input type='number'/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        <label>Teller Name</label>
                        <input type='text'/>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                        <label>Branch</label>
                        <input type='text' />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                        <label style={{color:'white'}}>Teller Name</label>
                        <input type='text'/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        <label>Issuing Branch Number</label>
                        <input type='number'/>
                        </Grid>
                        <Grid  item xs={12} sm={6} md={6}>
          <div className="radiodiv">
              <span>Transaction Type:</span>
             <input type="radio" className="productradio" name="type" value='tci' style={{marginLeft:10}} />
             <label className="span">TCI</label>
             <input type="radio" className="productradio" name='type' value='tbi' style={{marginLeft:20}}/>
             <label className="span">TBI</label>
          </div>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}></Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <label>MC Liquidation Date</label>
          <input type='date'/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <label>Demand Draft Number</label>
          <input type='number'/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <label>Amount</label>
          <input type='number'/>
          </Grid> <Grid  item xs={12} sm={6} md={6}>
          <label>Beneficiary Name</label>
          <input type='text'/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <label>Issuing Date</label>
          <input type='date'/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <label>Beneficiary Currency</label>
          <input type='text'/>
          </Grid>
          <Grid  item xs={12} sm={12} md={12}>
          <label>Narration</label>
          <input type='text'/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <label>Instruments</label>
          <input type='text'/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          </Grid>
                    </Grid>
                    <button className='next' onClick={() => handleScreenChange(true)}>Next</button>
                    </Paper>
                    </div>
                    </>  
                    )
                    }
                    {
                        screenChange && (
                            <>
                                <Paper className='sub-main' style={{paddingBottom:50, margin:50}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className='sect1'>
                                                <div>
                                                    <span>Amount</span>
                                                    <input />
                                                </div>
                                                <div>
                                                <span>Net Amount</span>
                                                <input />
                                                </div>
                                            </div>
                                        </Grid>
                                        
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&times;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&#61;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}> </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&times;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&#61;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}> </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&times;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&#61;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}> </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&times;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&#61;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}> </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&times;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                        <span>&#61;</span>
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}>
                                        <input />
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}> </Grid>
                                        
                                    </Grid>
                                 </Paper>
                                <div className='button-last button-last--1 flex--2'>
                                    <div className='button-container flex--1'>
                                        <button onClick={() => handleScreenChange(false)}>Previous</button>
                                        <button onClick={() => handleScreenChange(true)}>Save</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                
                </div>
            </section>
        </main>
    )
}

export default LiquidationWalkin;

import { baseUrl, Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/viewTransactionDetails";
import Details from "./Details";

const View = ({ details, glTransaction }) => {

    const {debitAccount} = glTransaction
  return (
    <DetailsContainer
      title="Customer Account Instructions and Mandate"
      question="Are you sure you want to delete this products?"
      url={Routes.getCustomerByAccountNumber + debitAccount}
    >
      <Details details={details}/>
    </DetailsContainer>
  );
};

export default View;

import React, { useEffect, useState } from "react";
import "./AccountInfo.scss";
// import Grid from '@material-ui/core/Grid';+
import useCustom2 from "../../../Api/Hooks-manager/products/useCustom";
import ErrorMessage from "../../../components/ErrorMessage";
import useCustom3 from "../../../Api/Hooks-manager/coreModules/useCustom";
import { getAllAccountOfficers } from "../../../Api/Hooks-manager/accountEnquiry/accounteEnquiry";
import SearchDropdown from "../../../../src/components/SearchableDropdown/Search";
import useCustom from "../../../Api/Hooks-manager/accountOpening/useCustom";

const Accountinfo = ({
  handleChange,
  handleSearchableChangeSelect,
  value,
  errors,
}) => {
  const { allAccountProduct, getAccountDefinitionAll } = useCustom2();
  const { allCurrencyDefinition, getCurrencyDefinitionAll } = useCustom3();
  const [accountOfficers, setAccountOfficers] = useState([]);
  const [passportBase64, setPassportBase64] = useState("");
  const [signatureBase64, setSignatureBase64] = useState("");
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(true);


  const { setData } = useCustom();

  useEffect(() => {
    getAccountDefinitionAll();
  }, [getAccountDefinitionAll]);

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, [getCurrencyDefinitionAll]);

  useEffect(() => {
    fetchAccountOfficers();
  }, []);

  const parameters = "Account";

  const filteredAccountProduct = allAccountProduct.filter(
    (items) => items.authorizationStatus === "Authorized"
  );
  const fetchAccountOfficers = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getAllAccountOfficers(
      user.jwtToken,
      parameters,
      1,
      1000
    );
    if (response && response.data) {
        setAccountOfficers(response.data);
    //   setAccountOfficers(
    //     response.data.map((item) => {
    //       return {
    //         value: `${item.firstName} ${item.middleName} ${item.lastName}`,
    //         label: `${item.firstName} ${item.middleName} ${item.lastName}`,
    //       };
    //     })
    //   );
    }
  };
  // ID_Type
  //  const parametersIdType = allbankparameters.filter(items => items.parameterName === 'ID_Type')

  // const handleChangeFile = (e, type) => {
  //   encodeBase64(e.target.files[0], type);
  //   console.log(e.target.files[0]);
  //   const fileType =
  //     e.target.files[0].type === "image/png" ||
  //     e.target.files[0].type === "image/jpg" ||
  //     e.target.files[0].type === "image/pdf" ||
  //     e.target.files[0].type === "image/jpeg";
  //   console.log(fileType, "fileTypefileType");
  //   // if(type === 'passport'){

  //   // }
  // };



  const handleChangeFile = (e, type) => {
    encodeBase64(e.target.files[0], type);
    console.log(e.target.files[0].size);
    let MAX_SIZE = 500000;
    if (e.target.files[0].size < MAX_SIZE) {
      const fileType =
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/pdf" ||
      e.target.files[0].type === "image/jpeg";
      console.log(fileType, "fileTypefileType");
      setShow(false);
    } else if (e.target.files[0].size > MAX_SIZE && type === "signature") {
      errors.signature =
        "Maximum file size of 500kb exceeded. Please reduce image size";
      setShow(true);
    }

    // if(type === 'passport'){

    // }
  };

  // const toBase64 = (file) =>
  // new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = (error) => reject(error);
  // });

  // const handleChangeFile = async (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type.substring(0, 5) === "image") {
  //     const fileBase64 = await toBase64(file);
  //     // console.log(fileBase64);
  //     // setImage(fileBase64);
  //     setImage(fileBase64);
  //   }
  // };

  useEffect(() => {
    if (passportBase64) {
      value.passport = passportBase64;
    }
    passportBase64 &&
      setData((prevState) => ({ ...prevState, passport: passportBase64 }));
  }, [passportBase64]);

  useEffect(() => {
    if (signatureBase64) {
      value.signature = signatureBase64;
    }
    signatureBase64 &&
      setData((prevState) => ({ ...prevState, signature: signatureBase64 }));
  }, [signatureBase64]);

  const encodeBase64 = (file, type) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (type === "signature") {
          var Base64 = reader.result;
          setSignatureBase64(Base64);
          return;
        }else{

          setPassportBase64(Base64);
        }
        // var Base64 = reader.result;
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };

  console.log("checking account info", value);

  return (
    <div className="account--info">
      <div style={{ paddingTop: 20, padding: 25 }}>
        <div className="product--def flex--1">
          <div className="flex--3 input--3">
            <label>Account Product</label>
            {!filteredAccountProduct.length && (
              <select>
                <option>Select An Option</option>
                <option>No Authorized Record Found</option>
              </select>
            )}
            {filteredAccountProduct.length > 0 && (
              <>
                <select
                  onChange={handleChange(
                    "accountProductCode",
                    "customerInfo",
                    allAccountProduct
                  )}
                  value={value.customerAccount[0].accountProductCode}
                  className={`${
                    Object.keys(errors).includes("accountProductCode") &&
                    "error-input-z"
                  }`}
                >
                  <option>Select An Option</option>
                  {filteredAccountProduct.map((items, index) => {
                    return (
                      <option key={index} value={items.productCode}>
                        {`${items.productCode} -- ${items.productName}`}
                      </option>
                    );
                  })}
                </select>
                {errors.accountProductCode && (
                  <ErrorMessage message={errors.accountProductCode} />
                )}
              </>
            )}
          </div>
          <div className="flex--3 input--3">
            <label>Account Type</label>
            <input
              type="text"
              value={value.customerAccount[0].accountType}
              disabled
              readOnly
            />
          </div>
          <div className="flex--3 input--3">
            <label>Account Officer</label>
            <div>
              {/* <div
                className={`border--norm ${
                  errors.loanOfficer ? "error-input-z" : null
                }`}
              >
                <SearchDropdown
                  options={accountOfficers}
                  value={value.customerAccount[0].accountOfficer}
                  onChange={handleSearchableChangeSelect}
                  type="loanOfficer"
                />
              </div> */}

              <select
                onChange={handleChange(
                    "accountOfficer",
                    "customerInfo",
                    accountOfficers
                  )}
                className={`border--norm ${
                  errors.accountOfficer ? "error-input-z" : null
                }`}
                value={value.customerAccount[0].accountOfficer}
              >
                <option>select option</option>
                {accountOfficers.map((items, index) => {
                  return (
                    <option key={index} value={`${items.firstName} ${items.lastName}`}>
                      {`${items.firstName} ${items.lastName}`}
                    </option>
                  );
                })}
              </select>
              {errors.accountOfficer && (
                <ErrorMessage message={errors.accountOfficer} />
              )}
            </div>
          </div>
        </div>

        <div className=" flex--2 flex--7">
          <div className="flex--3 input--3">
            <label>Currency</label>
            <select
              onChange={handleChange("Currency", "customerInfo")}
              value={value.customerAccount[0].Currency}
              className={`${
                Object.keys(errors).includes("Currency") && "error-input-z"
              }`}
            >
              <option value="">Select a currency</option>
              {/* <option value="NGN">NGN</option> */}
              {allCurrencyDefinition.map((item) => (
                <option value={item.currencyCode} key={item.id}>
                  {item.currencyName}
                </option>
              ))}
            </select>
            {errors.Currency && <ErrorMessage message={errors.Currency} />}
          </div>

          <div className="flex--3 input--3">
            <label>Account Name</label>
            <input
              type="num"
              value={value.customerAccount[0].accountName}
              onChange={handleChange("accountName", "customerInfo")}
              disabled
              className={`${
                Object.keys(errors).includes("accountName") && "error-input-z"
              }`}
            />
            {errors.accountName && (
              <ErrorMessage message={errors.accountName} />
            )}
          </div>
        </div>

        <div className="product--def flex--1">
          {/* <div className="flex--3 input--4">
            <label>Referral Code</label>
            <input
              type="num"
              value={value.customerAccount[0].referralcode}
              className={`${
                Object.keys(errors).includes("referralcode") &&
                "error-input-z"
              }`}
              onChange={handleChange("referralCode", "customerInfo")}
            />
            {errors.referralCode && <ErrorMessage message={errors.referralCode} />}
          </div> */}
          <div className="flex--3 input--4">
            <label>Old Account Number</label>
            <input
              type="num"
              value={value.customerAccount[0].oldAccountNumber}
              onChange={handleChange("oldAccountNumber", "customerInfo")}
            />
          </div>
          <div className="flex--3 input--4">
            <label>Debit/Credit Card Number</label>
            <input
              type="num"
              value={value.customerAccount[0].ATMNumber}
              onChange={handleChange("ATMNumber", "customerInfo")}
              className={`${
                Object.keys(errors).includes("ATMNumber") && "error-input-z"
              }`}
            />
            {errors.ATMNumber && <ErrorMessage message={errors.ATMNumber} />}
          </div>
        </div>
        <div className="product--def flex--1">
          {value.customerType === "Joint" && (
            <div className="flex--3 input--4">
              <label>Account Mandates (Image,Signature and Mandates)</label>
              <input
                type="file"
                accept="image/*, pdf"
                onChange={(e) => handleChangeFile(e, "signature")}
                // onChange={handleChangeFile}
                className={`input--file ${
                  Object.keys(errors).includes("signature") && "error-input-z"
                }`}
              />
              {show && errors.signature && <ErrorMessage message={errors.signature} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Accountinfo;

import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import {
  createLoanFacilityLimit,
  updateLoanFacilityLimit,
  getAllLoanFacilityLimit,
  getLoanFacilityLimitByAccountNum,
  getALoanFacilityLimit,
  authorizeLoanFacilityLimit,
} from "../../../../Api/Hooks-manager/loanAccountOperations";
import useTable from "../../../Tables/useTable";
import { formattedDate } from "../../../../formatter/date";
import {
  isLoading,
  openViewModal,
  openUpdateFormModal,
  openResquestDecisionModal,
} from "../../../../Api/redux-manager/actions";
import { getCustomerByAccountNumber } from "../../../../Api/Hooks-manager/loanAccountOperations";

export default function BusinessLogic() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [open, setOpen] = useState(false);
  const loading = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const [takeAction, setTakeAction] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [message, setMessage] = useState("");
  const [accNum, setAccountNumber] = useState("");
  const [allLoanAccountNum, setAllLoanAccNum] = useState([]);
  const [invalidAccount, setInvalidAccount] = useState("");
  const [allLoanLimits, setAllLoanLimits] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [view, setView] = useState({});
  const [openBoxAuthorize, setOpenAuthorize] = useState(false);
  const [openDecision, setOpenDecision] = useState(false);
  const [show, setShow] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [authorizeLoanFacility, setAuthorizeLoanFacility] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const [loanFacilityLimit, setLoanFacilitylimit] = useState({
    accountHolder: true,
    accountNo: "",
    accountName: "",
    accountBranch: "",
    accountCurrency: "",
    approvedLoanLimit: "",
  });

  const [details, setDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [editErrors, setEditErrors] = useState({});

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNo",
      label: "Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountBranch",
      label: "Account Branch",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountCurrency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "approvedLoanLimit",
      label: "Approved Loan Limit",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "loanLimitBalance",
    //   label: "Loan Limit Balance",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allLoanLimits[dataIndex];
                setEditDetails({
                  id: singleItem.id,
                  accountNo: singleItem.accountNo,
                  accountName: singleItem.accountName,
                  accountBranch: singleItem.accountBranch,
                  accountCurrency: singleItem.accountCurrency,
                  approvedLoanLimit: singleItem.approvedLoanLimit,
                  // loanLimitBalance: singleItem.loanLimitBalance,
                });
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = allLoanLimits[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const validateScreen = () => {
    const errors = {};
    const {
      accountHolder,
      accountNo,
      accountName,
      branchId,
      currency,
      approvedLoanLimit,
    } = loanFacilityLimit;

    if (accountHolder && accountNo === "") {
      errors.accountNo = "Field is required";
    }
    if (accountHolder && accountNo.length !== 10) {
      errors.accountNo = "Account number should be 10 character long";
    }
    if (approvedLoanLimit === "") {
      errors.approvedLoanLimit = "Field is required";
    }
    return errors;
  };

  const openEditDetails = (el) => {
    setEditDetails({
      id: el.id,
      accountNo: el.accountNo,
      accountName: el.accountName,
      accountCurrency: el.accountCurrency,
      accountBranch: el.accountBranch,
      approvedLoanLimit: el.approvedLoanLimit,
      // loanFacilityLimit: el.loanFacilityLimit,
    });
    setShowEditDetails(true);
  };

  const validateUpdateDetails = () => {
    const errors = {};

    const { approvedLoanLimit, loanLimitBalance } = editDetails;

    if (approvedLoanLimit === "") {
      errors.approvedLoanLimit = "Field is required";
    }
    // if(loanLimitBalance === ""){
    //   errors.loanLimitBalance = "Field is required";
    // }
    // if(Number(loanLimitBalance) > Number(approvedLoanLimit)){
    //   errors.loanLimitBalance = "Loan limit balance cannot be greater than the approved loan limit"
    // }

    return errors;
  };

  const fetchAllLoanLimitFacility = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllLoanFacilityLimit(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllLoanLimits(response.data);
    }
  }, [dispatch, token]);

  const fetchCAccountDetails = useCallback(
    async (accNum) => {
      dispatch(isLoading());
      const response = await getCustomerByAccountNumber(token, accNum);
      if (response && response.data) {
        dispatch(isLoading());
        if (response.status) {
          if (response.data.authorizationStatus === "Authorized") {
            setErrors((prev) => ({ ...prev, accountNo: "" }));
            setLoanFacilitylimit((prev) => ({
              ...prev,
              accountName: response.data.accountName,
              accountBranch: response.data.branchCode,
              accountCurrency: response.data.currency,
            }));
          } else {
            clearAccountDetails();
            setErrors((prev) => ({
              ...prev,
              accountNo: "Unauthorized account number",
            }));
            setLoanFacilitylimit((prev) => ({
              ...prev,
              accountName: "",
              accountBranch: "",
              accountCurrency: "",
            }));
          }
          // setLoanFacilitylimit((prev) => ({...prev, creditAccountBranch:response.data.branchCode}))
        } else {
          clearAccountDetails();
          setErrors({ ...errors, accountNo: response.message });
        }
      }
      console.log("loanFacilityLimit", loanFacilityLimit);
    },
    [dispatch, token, loanFacilityLimit]
  );

  const fetchLoanLimitDetailsByAccnm = useCallback(async (accno) => {
    dispatch(isLoading());
    const response = await getLoanFacilityLimitByAccountNum(token, accno);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        if (response.data.approvedLoanLimit === "") {
          setErrors((prev) => ({ ...prev, accountNo: "" }));
          fetchCAccountDetails(accno);
          // setLoanFacilitylimit(prev => ({
          //   ...prev,
          //   // accountName: response.data.accountName,
          //   // accountBranch: response.data.accountBranch,
          //   // accountCurrency: response.data.accountCurrency,
          //   approvedLoanLimit: response.data.approvedLoanLimit,
          // }))
        } else if (response.data.approvedLoanLimit > 0) {
          setErrors((prev) => ({
            ...prev,
            accountNo: "This account number already has an approved loan limit",
          }));
          setLoanFacilitylimit((prev) => ({
            ...prev,
            accountName: "",
            accountBranch: "",
            accountCurrency: "",
          }));
        }
      } else if (response.responseCode === "99") {
        setErrors((prev) => ({ ...prev, accountNo: "" }));
        fetchCAccountDetails(accno);
      } else {
        clearAccountDetails();
        setErrors({ ...errors, accountNo: response.message });
      }
    }
  }, []);

  const postLoanLimitFacility = async () => {
    dispatch(isLoading());
    const response = await createLoanFacilityLimit(token, loanFacilityLimit);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        clearAccountDetails();
        setMessage(`Your loan facility limit has been successfully saved.`);
        setShow(false);
        fetchAllLoanLimitFacility();
        return true;
      } else {
        setMessage(response.message);
        return false;
      }
    }
  };

  // const decide = async () => {
  //   dispatch(isLoading());
  //   const response = await authorizeLoanFacilityLimit(
  //     token,
  //     authorizeLoanFacility,
  //     view.id
  //   )
  //   if(response && response.data){
  //     dispatch(isLoading());
  //     setMessage(response.message);
  //     if(response.data.statusCode === 200){
  //       setOpenView(false);
  //       setOpenAuthorize(false);
  //       setAuthorizeLoanFacility({
  //         reason: "",
  //         isApproved: true,
  //         authorizationStatus: 0,
  //       });
  //       fetchAllLoanLimitFacility();
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  const clearAccountDetails = () => {
    setErrors({ ...errors, accountNo: "" });
    setLoanFacilitylimit({
      ...loanFacilityLimit,
      accountName: "",
      accountBranch: "",
      accountNo: "",
      accountCurrency: "",
      approvedLoanLimit: "",
    });
  };

  const { accountNo, accountHolder } = loanFacilityLimit;

  // useEffect(() => {
  //     if (accountNo.length === 10) {
  //         fetchCAccountDetails(accountNo)
  //     }
  // }, [accountNo]);

  useEffect(() => {
    if (accountNo.length === 10) {
      fetchLoanLimitDetailsByAccnm(accountNo);
    }
  }, [accountNo]);

  useEffect(() => {
    if (!accountHolder) {
      clearAccountDetails();
    }
  }, [accountHolder]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "accountNo" && value.length > 10) return;
    setLoanFacilitylimit({ ...loanFacilityLimit, [name]: value });
  };

  //   const handleAccNum = (e) => {
  //     const { value } = e.target;
  //     if (value.length > 10) return;
  //     setAccountNumber(value);
  //     console.log(loanFacilityLimit);
  //   };

  const handleFirstScreen = () => {
    const checkErrors = validateScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
  };

  const handleEditDetailsChange = (e) => {
    const { value, name } = e.target;
    setEditDetails({
      ...editDetails,
      [name]: value,
    });
  };

  const handleDetailsUpdateSubmit = () => {
    const checkErrors = validateUpdateDetails();
    setEditErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    // setEditErrors({});
    dispatch(openResquestDecisionModal());
  };

  const handleSubmit = () => {
    const checkErrors = validateScreen();
    console.log("checking..", checkErrors);
    console.log("invalidAccount..", invalidAccount);
    setErrors(checkErrors);
    if (invalidAccount || Object.keys(checkErrors).length > 0) return;
    setOpen(true);
  };
  const parameters = "CUS_CATEGORY,Loan_Status";
  //   const BankParameterByName = async () => {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     const response = await getBankParameterByName(user.jwtToken, parameters);
  //     if (response && response.data.cuS_CATEGORY) {
  //       setLoanStatus(response.data.loan_Status);
  //     }
  //     if (response && response.data.loan_Status) {
  //       setLoanStatus(response.data.loan_Status);
  //     }
  //   };

  useEffect(() => {
    fetchAllLoanLimitFacility();
  }, [fetchAllLoanLimitFacility, updateTable]);

  const postEditDetails = async () => {
    dispatch(isLoading());
    const response = await updateLoanFacilityLimit(
      token,
      editDetails,
      editDetails.id
    );
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      return true;
    }
  };

  return {
    details,
    editDetails,
    accNum,
    errors,
    loading,
    options,
    columns,
    open,
    message,
    takeAction,
    editErrors,
    setTakeAction,
    loanFacilityLimit,
    setOpen,
    setLoanFacilitylimit,
    invalidAccount,
    fetchCAccountDetails,
    // fetchLoanLimitDetailsByAccnm,
    handleFirstScreen,
    handleChange,
    handleSubmit,
    // decide,
    postLoanLimitFacility,
    allLoanLimits,
    openDecision,
    openBoxAuthorize,
    setOpenDecision,
    handleEditDetailsChange,
    handleDetailsUpdateSubmit,
    postEditDetails,
    openEditDetails,
    showEditDetails,
    setShowEditDetails,
  };
}

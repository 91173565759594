import React, { useState, useCallback } from "react";
import "./ProfitandLoss.scss";
import Table from "../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import { getAllIncomeStatement } from "../../../../Api/Hooks-manager/eodReports/balanceSheet";
import { isLoading } from "../../../../Api/redux-manager/actions";
// import { formattedDate } from "../../../../formatter/date";
import moment from "moment";
import { format } from "date-fns";
// import { ChangedDate } from "../../../../formatter/date";
// import YourCustomRowComponent from "./YourCustomRowComponent"
import {
  TableFooter,
  TableCell,
  TableRow,
  TablePagination,
} from "@material-ui/core";
const ProfitandLoss = () => {
  const dispatch = useDispatch(isLoading());
  const loading = useSelector((state) => state.req.loading);
  const workdate = useSelector((state) => state.common.branch.workdate);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const applicationDate = format(new Date(workdate), "yyyy-MM-dd");
  const [data, setData] = useState({
    workDate: applicationDate,
  });
  const [allProfitAndLossReports, setAllProfitAndLossReports] = useState(null);
  const [errors, setErrors] = useState({});
  const [creditBalances, setCreditBalances] = useState([]);
  const [debitBalances, setDebitBalances] = useState([]);
  const [sumOfCreditBalances, setSumOfCreditBalances] = useState(0);
  const [sumOfDebitBalances, setSumOfDebitBalances] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchcode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountnumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountname",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditbalance",
      label: "Income Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "debitbalance",
      label: "Expense Balance",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },

    // {
    //   name: "processdate",
    //   label: "Process Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => <p>{ChangedDate(value)}</p>,

    //   },
    // },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customFooter: (count, changeRowsPerPage, changePage) => {
      return (
        <TableFooter>
          <TableRow >
            <TableCell style={{ color: "black",width:"100%" }}>
              <h5><u>Summary</u></h5>

              <h6>Total Income : {sumOfCreditBalances} </h6>
              <h6> Total Expense : {sumOfDebitBalances}</h6>
              <h6>
                Profit and Loss: {sumOfCreditBalances - sumOfDebitBalances}
              </h6>
            </TableCell>
            
          </TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 15, 100]}
            options={options}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            count={allProfitAndLossReports.length}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        </TableFooter>
      );
    },
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const creditCards = [
  //   {
  //     name: "Tom Tallis",
  //     cardNumber: "5500005555555559",
  //     cvc: "582",
  //     expiry: "02/24"
  //   },
  //   {
  //     name: "Rich Harris",
  //     cardNumber: "4444444444444448",
  //     cvc: "172",
  //     expiry: "03/22"
  //   },
  //   {
  //     name: "Moby Dixon",
  //     cardNumber: "3566003566003566",
  //     cvc: "230",
  //     expiry: "12/25"
  //   }
  // ];

  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { workDate } = data;
    if (workDate === "") {
      error.workDate = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };

  const handleReports = () => {
    const checkErrors = validateData();
    if (checkErrors || loading) {
      fetchAllProfitAndLoss();
      console.log("i am livid");
    }
    console.log("i am believer");
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const EmptyField = () => {
    setData({
      workDate: applicationDate,
    });
  };
  const handleReset = () => {
    EmptyField();

    setAllProfitAndLossReports(false);
  };

  const fetchAllProfitAndLoss = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllIncomeStatement(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          // response.data[i].creditbalance = response.data.creditbalance
          //     // response.data[i].createdDateTime = formattedDate(
          //     //   response.data[i].createdDateTime
          //     // );
          //     // response.data[i].dateAuthorized = formattedDate(
          //     //   response.data[i].dateAuthorized
          //     // );
          //     // response.data[i].updatedDateTime = formattedDate(
          //     //   response.data[i].updatedDateTime
          //     // );
        }
      }
      setAllProfitAndLossReports(response.data);
      const arr = response.data;
      const reducer = (a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2);
      const creditBalanceArray = (arr) =>
        arr.map(({ creditbalance }) => creditbalance);
      // .map(el => parseFloat(el.Amount));

      const creditBalanceArr = creditBalanceArray(arr);

      // const sumOfCreditBalance = creditBalanceArr.reduce(reducer, 0);
      setSumOfCreditBalances(creditBalanceArr.reduce(reducer, 0));
      // console.log(arr);
      // setTotals(response.data.total);
      const debitBalanceArray = (arr) =>
        arr.map(({ debitbalance }) => debitbalance);
      // .map(el => parseFloat(el.Amount));

      const debitBalanceArr = debitBalanceArray(arr);

      // const sumOfCreditBalance = creditBalanceArr.reduce(reducer, 0);
      setSumOfDebitBalances(debitBalanceArr.reduce(reducer, 0));
    }
    //setAccountData()
  }, [data, token, dispatch]);

  let toDate = moment(data.workDate).format("MMMM Do YYYY");
  console.log(toDate, "this is date");
  console.log("feyi", sumOfCreditBalances, sumOfDebitBalances);

  return (
    <main className="trial--balance">
      <div className="headerdiv">
        <p>EOD Reports | Profit And Loss </p>
      </div>

      <div className="export-button"></div>
      <Spinner loading={loading} />

      <div className="profit">
        <div className="date-range">
          <div className="sub__range">
            <div className="sub__range__1">
              <div className="sub__range__2">
                <div className="date__from">
                  <label>Date</label>
                  <input
                    type="date"
                    name="workDate"
                    value={data.workDate}
                    onChange={handleChange("workDate")}
                  />
                  {errors.workDate && (
                    <ErrorMessage message={errors.workDate} />
                  )}
                </div>
              </div>
            </div>
            <div className="last-btn">
              <button onClick={handleReports}>Search</button>
              <button onClick={handleReset}>Reset</button>
            </div>
          </div>
          {allProfitAndLossReports && (
            <div>
              <Spinner loading={loading} />

              <Table
                title={`Profit And Loss  As At ` + toDate}
                subtitle=""
                name="Profit and Loss"
                records={allProfitAndLossReports}
                options={options}
                columns={columns}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default ProfitandLoss;

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import Item from "../../DatagridTable/item";
import { numberFormatter } from "../../../formatter";


const useStyles = makeStyles((theme) => ({
  root: {},
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexDirection:"row",
  },
  item:{
    width:'100%'
  }
}));


const TellerProduct = ({details}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Product Code" name={details.code} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Description" name={details.description} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Value Type" name={details.valueType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Debit Account" name={details.debitAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Tax Account" name={details.taxAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Credit Account" name={details.creditAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Track Denomination" name={details.trackDenomination ? "Yes" : "No" } />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Cash Transaction"
              name={details.cashTransaction ? "Yes" : "No"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Charge Applicable"
              name={details.chargeApplicable ? "Yes" : "No"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Apply Tax" name={details.applyTax ? "Yes" : "No"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Instrument Required"
              name={details.instrumentRequired ? "Yes" : "No"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Vault Transaction"
              name={details.vaultTransaction ? "Yes" : "No"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Created Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Divider className={classes.hr} />
          <h6 className={classes.text}>Charges</h6>
          {details && details.charges && details.charges.length > 0 ? (
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Charges</th>
                  <th>Account</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Rate</th>
                  <th>Currency</th>
                </tr>
              </thead>
              <tbody>
                {details.charges.map((el, i) => (
                  <tr key={i}>
                    <td>
                      <Item label="" name={el.name} />
                    </td>
                    <td>
                      <Item label="" name={el.applyat} />
                    </td>
                    <td>
                      <Item label="" name={el.ledgerAccountNumber} />
                    </td>
                    <td>
                      <Item label="" name={el.typeOf} />
                    </td>
                    <td>
                      <Item
                        label=""
                        name={el.typeOf === "Flat Amount" ? "Amount" : "Rate"}
                      />
                    </td>
                    <td>
                      <Item label="" name={numberFormatter(el.amount)} />
                    </td>
                    <td>
                      <Item label="" name={el.currency} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ):  <Grid xs={12}>
          <div className={classes.noData}>
          <Item label="" name="Charges are not applicable" className={classes.item}/>
          </div>
        </Grid>}
          <Divider className={classes.hr} />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized} />
          </Grid>
          {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Reason" name={details.reason} />
          </Grid>}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Status"
              name={
                details.authorizationStatus === "PendingEditedAuthorization"
                  ? "Pending Edited Authorization"
                  : details.authorizationStatus
              }
            />
          </Grid>
        </Grid>
    
    )
}

export default TellerProduct

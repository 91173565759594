import React, { useState, useEffect } from "react";
import "./App.scss";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Main";
import { BiErrorCircle } from "react-icons/bi";
import { logoutUser, clearCommon } from "./Api/redux-manager/actions";
import { useDispatch } from "react-redux";
import SetNewPassword from "./components/SetNewPassword/setNewPassword";
import VerifyCreatedUser from "./components/UserVerification/VerifyCreatedUser";

// import { useSelector } from 'react-redux';

import { useHistory } from "react-router-dom";
// import { attemptLogin } from './Api/redux-manager/actions';

const mapStateToProps = (state) => {
  return {
    loginAccess: state.LoginUsers.login,
  };
};

const events = ["click", "load", "scroll"];

const TIMEOUT_DURATION = 300000

function App(props) {
  // console.log("----props from App.js", props)
  const history = useHistory();
  const dispatch = useDispatch();
  const [sessionExpired, setSessionExpired] = useState(false);
  const { loginAccess } = props;
  
  const session = () => {
    setSessionExpired(false);
    history.push("/");
    dispatch(logoutUser());
    dispatch(clearCommon());
    localStorage.removeItem("persist:root");
    localStorage.removeItem("user");
    window.location.reload();
  };

  useEffect(() => {
    if(loginAccess){
      const timeOut =  new Date().getTime() + TIMEOUT_DURATION
      localStorage.setItem("timeOut", timeOut);
    }

    let values = localStorage.getItem("timeOut");
    if (values < new Date().getTime() && loginAccess !== false) {
      setSessionExpired(true);
    }

    const checkSession = () => {
      if (window.location.pathname !== "/" && loginAccess !== false) {
        let values = localStorage.getItem("timeOut");
        if (values < new Date().getTime()) {
          localStorage.removeItem("timeOut");
          setSessionExpired(true);
        }
      }
    }



    let timer = setInterval(checkSession, 20000);

    const run = () => {
      clearInterval(timer)
      timer = setInterval(checkSession, 5000);
      if(loginAccess){
        const timeOut =  new Date().getTime() + TIMEOUT_DURATION
        localStorage.setItem("timeOut", timeOut);
      }
    }
    events.forEach(event => {
      window.addEventListener(event, run)
    })

    return () =>{
      clearInterval(timer)
      events.forEach(event => {
        window.removeEventListener(event, run)
      })
    };
  }, [loginAccess]);

  // const loaderToggle = useSelector(state => state.LoaderSwitch)
  // const successErrorSwitch = useSelector(state => state.successErrorSwitch)
  //   successErrorSwitch:
  // errorMsg: false

  const CheckUrlNotLoggedIn = ({component: Component, ...rest}) => {
    <Route
    {...rest}
    render = {(props) => 
    !loginAccess ? <Redirect to="/" /> : <Component {...props}/>
    }
    />
  }

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        loginAccess ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );

  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        loginAccess ? (
          <Redirect to="/dashboard/home"/>
        ) : (
          <Component {...props} />
        )
      }
    />
  );

  const Page404 = () => (
    <div className="flex--2 loader">
      <h1 style={{ color: "black", background: "white", padding: "2rem" }}>
        Oops! Page doesn't exists
      </h1>
    </div>
  );

  return (
    <div>
      {sessionExpired && (
        <div className="flex--2 loader">
          <div className="flex--3 signed--out">
            <BiErrorCircle className="icon" />
            <h1>Your Session has Expired, Logging Out ...</h1>
            <button onClick={session}>Continue</button>
          </div>
        </div>
      )}
      <Switch>
        <LoginRoute exact path="/" component={Login} />
        <Route
          path="/password-reset/token/:usertoken"
          component={SetNewPassword}
        />
        <Route
          path="/user-verification/token/:usertoken"
          component={VerifyCreatedUser}
        />
        {/* <Route path='/reduxtrial' component={Reduxtrial}/> */}
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <CheckUrlNotLoggedIn path = "/" component = {Login}/>
        <Route component={Page404} />
      </Switch>



    </div>
  );
}

export default connect(mapStateToProps, null)(App);

import { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {
  isLoading,
  openFormModal,
} from "../../../../Api/redux-manager/actions";
import { addMultipleOverdueDays } from "../../../../Api/Hooks-manager/products/products";
import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes } from "../../../../Api/configUrl";
import NumberFormat from "react-number-format";
import AddBulkModal from "../../ProductsModal/index";
import axios from "axios";
import { baseUrl } from "../../../../Api/configUrl";
import CustomizedSnackbars from '../../../../Confirmbox/Snackbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  checkbox: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& input": {
      height: 15,
      borderRadius: 0,
      width: 20,
      marginLeft: 0,
    },
  },
  border: {
    border: "1px solid black",
    marginBottom: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttondiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  item: {
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  addRemoveButton: {
    display: "flex",
    justifyContent: "space-around",
  },
  addButton: {
    backgroundColor: "#D0021B",
    padding: "0.5rem",
    borderRadius: "5px",
    color: "#fff",
    width: "max-content",
  },
  removeButton: {
    backgroundColor: "#fff",
    border: "1px solid #D0021B",
    padding: "0.4rem",
    borderRadius: "5px",
    color: "#D0021B",
  },
}));

const EditData = ({
  handleEditDetailsChange,
  handleEditLoanProductChange,
  handleEditOverdueDayChange,
  handleEditRepaymentOrderChange,
  handleEditCustomerRestrictionChange,
  handleDetailsUpdateSubmit,
  details,
  errorEdit,
  allCurrencyDefinition,
  setEditDetails,
  allGlAccount,
  loanStatus,
  cusCategory,
  loanProductCharge,
  overdueDays,
  repaymentOrder,
  customerRestriction,
  errorsOfOtherServicesOtherthanProductDetails,
  handleSubmitEditCharges,
  setEditOverdueDays,
  updateAddOverDueDays,
  handleOverdueDayChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);
  const [editLoanCharges, setEditLoanCharges] = useState([]);
  const [editLoanOverdue, setEditLoanOverdue] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [msg, setMsg] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [guarantorForm, setGuarantorForm] = useState("customer");
  const openAddBulkModal = useSelector((state) => state.approve.openForm);
  const [actionAccount, setActionAccount] = useState(details);
  const [url, setUrl] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarContent, setSnackBarContent] = useState({
    status: "",
    message: ""
  });
  // const addBulkOverDueDaysData = [
  //   {from: 0,
  //     to: 0,
  //     loanStatus: "",
  //     loanProductId: details.id,
  //   }
  // ]

  // const addOverdueDays = () => {
  //   const list = [...overdueDays];
  //   list.push({
  //     from: 0,
  //     to: 0,
  //     loanStatus: "",
  //   });
  //   setEditOverdueDays((prev) => [...prev, { ...list }]);
  // };


  const addOverdueDays = (index) => {
    const list = {
      from: 0,
      to: 0,
      loanStatus: "",
    };
    setEditOverdueDays((prev) => [...prev, { ...list }]);
    console.log(list);
  };


  const deleteAvOverdeuDay = async (index) => {
    dispatch(isLoading());
    const list = [...overdueDays];
    const item = overdueDays[index];
    try {
      const response = await axios.delete(
        `${baseUrl}loan/overdueDays/Delete/${item.id}`
      );
      if (response.data.status) {
        setSnackBarContent({
          status: "success",
          message: "Overdue days deleted successfully"
        })
        setSnackBarOpen(true);
        window.location.reload();
        return true;
      } else{
        console.log("valid");
        setSnackBarContent({
          status: "error",
          message: "Failed to delete over due days"
        })
        setSnackBarOpen(true);
        setErrorMsg(response.message);
        return false;
      }
    } catch {
      // console.log("innnvalid");
      return false;
    } finally {
      dispatch(isLoading());
    }
  };


  const removeOverDueDays = (index) => {
    const lists = [...overdueDays];
    const item = overdueDays[index];
    if (lists.length < 2) return;
    lists.splice(index, 1);
    setEditOverdueDays((prev) => [...lists]);
    // console.log("removeoverdue")
  };

  // const addBulkOverDueDays = async() => {
  //   const lists = [...overdueDays];
  //   dispatch(isLoading());
  //   const response = await addMultipleOverdueDays(token, lists);
  //   setMsg(response.message);
  //   console.log(response, "adddd bulk overdue")
  //   if(response && response.data){
  //     dispatch(isLoading());
  //     if(response.status){
  //       return true;
  //     } else{
  //       setErrorMsg(response.message)
  //       return false;
  //     }
  //   }

  // }

  console.log(actionAccount, "actionAccount");
  // console.log(repaymentOrder);
  // console.log(overdueDays[0].id);

  const displayErr = (i, name) => {
    return (
      errorsOfOtherServicesOtherthanProductDetails.index === i &&
      errorsOfOtherServicesOtherthanProductDetails.type === name
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackBarOpen(false);
  };

  useEffect(() => {
    setValue(0);
  }, [details]);

  return (
    <EditModal
      title="Loan Products"
      onClick={handleDetailsUpdateSubmit}
      question="Are you sure you want to update this Loan Product record?"
      data={details}
      url={`${Routes.updateLoanProducts}${details.id}`}
      hide={hide}
      // beforeAction={addBulkOverDueDays}
      // onClose={onEditClose}
    >
      <CustomizedSnackbars 
          handleClose={handleCloseSnackBar} 
          message={snackBarContent.message} 
          status={snackBarContent.status} 
          open={snackBarOpen}
        />
      <section className={classes.root}>
        <div style={{ marginTop: 50, padding: 25 }}>
          <>
            <div className={classes.root}>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Product Details"
                    {...a11yProps(0)}
                    onClick={() => setHide(false)}
                  />
                  <Tab
                    label="Loan product Charges"
                    {...a11yProps(1)}
                    onClick={() => setHide(true)}
                  />
                  <Tab
                    label="Overdue Days"
                    {...a11yProps(2)}
                    onClick={() => setHide(true)}
                  />
                  <Tab
                    label="Repayment Order"
                    {...a11yProps(3)}
                    onClick={() => setHide(true)}
                  />
                  <Tab
                    label="Customer Restriction"
                    {...a11yProps(4)}
                    onClick={() => setHide(true)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={3}>
                    <label>Product Code</label>
                    <input
                      disabled
                      type="text"
                      className={errorEdit.productCode ? "error-input-z" : null}
                      name="productCode"
                      value={details.productCode}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.productCode && (
                      <ErrorMessage message={errorEdit.productCode} />
                    )}
                    <div className={classes.checkbox}>
                      <input type="checkbox" disabled />
                      <p>Deactivate Product</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label>Product Name</label>
                    <input
                      disabled
                      type="text"
                      className={errorEdit.productName ? "error-input-z" : null}
                      name="productName"
                      value={details.productName}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.productName && (
                      <ErrorMessage message={errorEdit.productName} />
                    )}
                  </Grid>
                  <Grid item sm={12}></Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Currency</label>
                    <select
                      name="currency"
                      className={errorEdit.currency ? "error-input-z" : null}
                      value={details.currency}
                      onChange={handleEditDetailsChange}
                    >
                      {allCurrencyDefinition.map((item) => (
                        <option value={item.currencyCode} key={item.id}>
                          {item.currencyName}
                        </option>
                      ))}
                    </select>
                    {errorEdit.currency && (
                      <ErrorMessage message={errorEdit.currency} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Default Principal</label>
                    <NumberFormat
                      thousandSeparator={true}
                      value={details.defaultPrincipal}
                      inputMode="numeric"
                      renderText={(value) => <div>{value}</div>}
                      className={`some ${
                        errorEdit.defaultPrincipal ? "error-input-z" : null
                      }`}
                      onValueChange={(values) => {
                        setEditDetails((product) => ({
                          ...product,
                          defaultPrincipal: parseInt(values.value),
                        }));
                      }}
                    />
                    {errorEdit.defaultPrincipal && (
                      <ErrorMessage message={errorEdit.defaultPrincipal} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Minimum Principal</label>
                    <NumberFormat
                      value={details.minimumPrincipal}
                      thousandSeparator={true}
                      inputMode="numeric"
                      renderText={() => <div>{value}</div>}
                      className={`some ${
                        errorEdit.minimumPrincipal ? "error-input-z" : null
                      }`}
                      onValueChange={(values) => {
                        setEditDetails((product) => ({
                          ...product,
                          minimumPrincipal: parseInt(values.value),
                        }));
                      }}
                    />
                    {errorEdit.minimumPrincipal && (
                      <ErrorMessage message={errorEdit.minimumPrincipal} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Maximum Principal</label>
                    <NumberFormat
                      value={details.maximumPrincipal}
                      thousandSeparator={true}
                      inputMode="numeric"
                      renderText={() => <div>{value}</div>}
                      className={`some ${
                        errorEdit.maximumPrincipal ? "error-input-z" : null
                      }`}
                      onValueChange={(values) => {
                        setEditDetails((product) => ({
                          ...product,
                          maximumPrincipal: parseInt(values.value),
                        }));
                      }}
                    />
                    {errorEdit.maximumPrincipal && (
                      <ErrorMessage message={errorEdit.maximumPrincipal} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Default Tenor</label>
                    <input
                      type="number"
                      className={
                        errorEdit.defaultTenor ? "error-input-z" : null
                      }
                      name="defaultTenor"
                      value={details.defaultTenor}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.defaultTenor && (
                      <ErrorMessage message={errorEdit.defaultTenor} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Minimum Tenor</label>
                    <input
                      type="number"
                      className={
                        errorEdit.minimumTenor ? "error-input-z" : null
                      }
                      name="minimumTenor"
                      value={details.minimumTenor}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.minimumTenor && (
                      <ErrorMessage message={errorEdit.minimumTenor} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Maximum Tenor</label>
                    <input
                      type="number"
                      className={
                        errorEdit.maximumTenor ? "error-input-z" : null
                      }
                      name="maximumTenor"
                      value={details.maximumTenor}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.maximumTenor && (
                      <ErrorMessage message={errorEdit.maximumTenor} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Tenor Basis</label>
                    <select
                      name="tenorBasis"
                      className={errorEdit.tenorBasis ? "error-input-z" : null}
                      value={details.tenorBasis}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="Days">Days</option>
                      <option value="Months">Month</option>
                    </select>
                    {errorEdit.tenorBasis && (
                      <ErrorMessage message={errorEdit.tenorBasis} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Rate Type</label>
                    <select
                      name="rateType"
                      className={errorEdit.rateType ? "error-input-z" : null}
                      value={details.rateType}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="Select rate type">Select rate type</option>
                      <option value="Fixed">Fixed</option>
                      {/* <option value="Floating">Floating</option> */}
                    </select>
                    {errorEdit.rateType && (
                      <ErrorMessage message={errorEdit.rateType} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Default Interest Rate</label>
                    <input
                      type="number"
                      className={
                        errorEdit.defaultInterestRate ? "error-input-z" : null
                      }
                      name="defaultInterestRate"
                      step="any"
                      value={details.defaultInterestRate}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.defaultInterestRate && (
                      <ErrorMessage message={errorEdit.defaultInterestRate} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Minimum Interest Rate</label>
                    <input
                      type="number"
                      className={
                        errorEdit.minimumInterestRate ? "error-input-z" : null
                      }
                      name="minimumInterestRate"
                      step="any"
                      value={details.minimumInterestRate}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.minimumInterestRate && (
                      <ErrorMessage message={errorEdit.minimumInterestRate} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Maximum Interest Rate</label>
                    <input
                      type="number"
                      className={
                        errorEdit.maximumInterestRate ? "error-input-z" : null
                      }
                      name="maximumInterestRate"
                      step="any"
                      value={details.maximumInterestRate}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.maximumInterestRate && (
                      <ErrorMessage message={errorEdit.maximumInterestRate} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Rate Basis</label>
                    <select
                      name="rateBasis"
                      className={errorEdit.rateBasis ? "error-input-z" : null}
                      value={details.rateBasis}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="PerAnnum">Per Annum</option>
                      <option value="PerMonth">Per Month</option>
                    </select>
                    {errorEdit.rateBasis && (
                      <ErrorMessage message={errorEdit.rateBasis} />
                    )}
                  </Grid>
                  {details.rateType === "Floating" && (
                    <Grid item xs={12} sm={6} md={3}>
                      <label>Minimum Variance</label>
                      <input
                        type="number"
                        className={
                          errorEdit.minimumVariance ? "error-input-z" : null
                        }
                        name="minimumVariance"
                        value={details.minimumVariance}
                        onChange={handleEditDetailsChange}
                      />
                      {errorEdit.minimumVariance && (
                        <ErrorMessage message={errorEdit.minimumVariance} />
                      )}
                    </Grid>
                  )}
                  {details.rateType === "Floating" && (
                    <Grid item xs={12} sm={6} md={3}>
                      <label>Maximum Variance</label>
                      <input
                        type="number"
                        className={
                          errorEdit.maximumVariance ? "error-input-z" : null
                        }
                        name="maximumVariance"
                        value={details.maximumVariance}
                        onChange={handleEditDetailsChange}
                      />
                      {errorEdit.maximumVariance && (
                        <ErrorMessage message={errorEdit.maximumVariance} />
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <label>Repayment Frequency</label>
                    <select
                      name="repaymentFrequency"
                      className={
                        errorEdit.repaymentFrequency ? "error-input-z" : null
                      }
                      value={details.repaymentFrequency}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                    {errorEdit.repaymentFrequency && (
                      <ErrorMessage message={errorEdit.repaymentFrequency} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label>Interest Cal Method</label>
                    <select
                      name="interestCalculationMethod"
                      className={
                        errorEdit.interestCalculationMethod
                          ? "error-input-z"
                          : null
                      }
                      value={details.interestCalculationMethod}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="DecliningBalance">
                        Declining Balance
                      </option>
                      <option value="FlatRate">Flat Rate</option>
                    </select>
                    {errorEdit.interestCalculationMethod && (
                      <ErrorMessage
                        message={errorEdit.interestCalculationMethod}
                      />
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={3}>
                    <label>Interest Method</label>
                    <select
                      name="interestCalculationMethod"
                      className={
                        errorEdit.interestCalculationMethod
                          ? "error-input-z"
                          : null
                      }
                      value={details.interestCalculationMethod}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="DecliningBalance">
                        Declining Balance
                      </option>
                      <option value="FlatRate">Flat Rate</option>
                    </select>
                    {errorEdit.interestCalculationMethod && (
                      <ErrorMessage
                        message={errorEdit.interestCalculationMethod}
                      />
                    )}
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={3}>
                    <label>Amortization Type</label>
                    <select
                      name="interestCalculationMethod"
                      className={
                        errorEdit.amortizationType ? "error-input-z" : null
                      }
                      value={details.amortizationType}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="EqualInstallment">
                        Equal installment
                      </option>
                      <option value="EqualPrincipal">Equal Principal</option>
                    </select>
                    {errorEdit.amortizationType && (
                      <ErrorMessage message={errorEdit.amortizationType} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Accrual Period</label>
                    <select
                      name="accrualPeriod"
                      className={
                        errorEdit.accrualPeriod ? "error-input-z" : null
                      }
                      value={details.accrualPeriod}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="OnMaturity">OnMaturity</option>
                      <option value="Daily">Daily</option>
                    </select>
                    {errorEdit.accrualPeriod && (
                      <ErrorMessage message={errorEdit.accrualPeriod} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Interest Period</label>
                    <select
                      name="interestPeriod"
                      className={
                        errorEdit.interestPeriod ? "error-input-z" : null
                      }
                      value={details.interestPeriod}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="Actual">Actual/Actual</option>
                      <option value="Actual360">Actual/360</option>
                      <option value="Actual30"> 30/Actual</option>
                    </select>
                    {errorEdit.interestPeriod && (
                      <ErrorMessage message={errorEdit.accrualPeriod} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Liquidation Type</label>
                    <select
                      name="liquidationType"
                      className={
                        errorEdit.liquidationType ? "error-input-z" : null
                      }
                      value={details.liquidationType}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="Manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                    {errorEdit.liquidationType && (
                      <ErrorMessage message={errorEdit.liquidationType} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Disbursement Method</label>
                    <select
                      name="disbursementMethod"
                      className={
                        errorEdit.disbursementMethod ? "error-input-z" : null
                      }
                      value={details.disbursementMethod}
                      onChange={handleEditDetailsChange}
                    >
                      <option value="Manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                    {errorEdit.disbursementMethod && (
                      <ErrorMessage message={errorEdit.disbursementMethod} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Loan Account</label>

                    <select
                      name="loanAccount"
                      className={errorEdit.loanAccount ? "error-input-z" : null}
                      onChange={handleEditDetailsChange}
                    >
                      <option value={details.loanAccount}>{details.loanAccount}</option>
                      {allGlAccount
                        .filter(
                          (item) =>
                            item.glHierarchy === "Child" &&
                            item.glType === "Income"
                        )
                        .map((list, i) => (
                          <option
                            key={i}
                            name={list.accountNumber}
                            value={`${list.accountNumber}`}
                          >{`${list.accountName}--${list.accountNumber}`}</option>
                        ))}
                    </select>
                    {errorEdit.loanAccount && (
                      <ErrorMessage message={errorEdit.loanAccount} />
                    )}     
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Interest Account</label>

                    <select
                      name="interestAccount"
                      className={errorEdit.interestAccount ? "error-input-z" : null}
                      onChange={handleEditDetailsChange}
                    >
                      <option value={details.interestAccount}>{details.interestAccount}</option>
                      {allGlAccount
                        .filter(
                          (item) =>
                            item.glHierarchy === "Child" &&
                            item.glType === "Income"
                        )
                        .map((list, i) => (
                          <option
                            key={i}
                            name={list.accountNumber}
                            value={`${list.accountNumber}`}
                          >{`${list.accountName}--${list.accountNumber}`}</option>
                        ))}
                    </select>
                    {errorEdit.interestAccount && (
                      <ErrorMessage message={errorEdit.interestAccount} />
                    )} 
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Interest Receivable Account</label>

                    <select
                      name="interestReceivableAccount"
                      className={errorEdit.interestReceivableAccount ? "error-input-z" : null}
                      onChange={handleEditDetailsChange}
                    >
                      <option value={details.interestReceivableAccount}>{details.interestReceivableAccount}</option>
                      {allGlAccount
                        .filter(
                          (item) =>
                            item.glHierarchy === "Child" &&
                            item.glType === "Income"
                        )
                        .map((list, i) => (
                          <option
                            key={i}
                            name={list.accountNumber}
                            value={`${list.accountNumber}`}
                          >{`${list.accountName}--${list.accountNumber}`}</option>
                        ))}
                    </select>
                    {errorEdit.interestReceivableAccount && (
                      <ErrorMessage message={errorEdit.interestReceivableAccount} />
                    )} 
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Penal Interest Account</label>

                    <select
                      name="penalInterestAccount"
                      className={errorEdit.penalInterestAccount ? "error-input-z" : null}
                      onChange={handleEditDetailsChange}
                    >
                      <option value={details.penalInterestAccount}>{details.penalInterestAccount}</option>
                      {allGlAccount
                        .filter(
                          (item) =>
                            item.glHierarchy === "Child" &&
                            item.glType === "Income"
                        )
                        .map((list, i) => (
                          <option
                            key={i}
                            name={list.accountNumber}
                            value={`${list.accountNumber}`}
                          >{`${list.accountName}--${list.accountNumber}`}</option>
                        ))}
                    </select>
                    {errorEdit.penalInterestAccount && (
                      <ErrorMessage message={errorEdit.penalInterestAccount} />
                    )} 
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>Loan WriteOff Account</label>

                    <select
                      name="penalInterestAccount"
                      className={errorEdit.loanWriteOff ? "error-input-z" : null}
                      onChange={handleEditDetailsChange}
                    >
                      <option value={details.loanWriteOff}>{details.loanWriteOff}</option>
                      {allGlAccount
                        .filter(
                          (item) =>
                            item.glHierarchy === "Child" &&
                            item.glType === "Income"
                        )
                        .map((list, i) => (
                          <option
                            key={i}
                            name={list.accountNumber}
                            value={`${list.accountNumber}`}
                          >{`${list.accountName}--${list.accountNumber}`}</option>
                        ))}
                    </select>
                    {errorEdit.loanWriteOff && (
                      <ErrorMessage message={errorEdit.loanWriteOff} />
                    )} 
                  </Grid>
                  {details && details.equityMultiple &&(<Grid item xs={12} sm={6} md={3}>
                    <label>Equity Multiple</label>
                    <input
                      type="number"
                      className={
                        errorEdit.equityMultiple ? "error-input-z" : null
                      }
                      name="equityMultiple"
                      step="any"
                      value={details.equityMultiple}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.equityMultiple && (
                      <ErrorMessage message={errorEdit.equityMultiple} />
                    )}
                  </Grid>)}
                  {details && details.adminFee &&(<Grid item xs={12} sm={6} md={3}>
                    <label>Admin Fee</label>
                    <input
                      type="number"
                      className={
                        errorEdit.adminFee ? "error-input-z" : null
                      }
                      name="adminFee"
                      step="any"
                      value={details.adminFee}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.adminFee && (
                      <ErrorMessage message={errorEdit.adminFee} />
                    )}
                  </Grid>)}
                  {details && details.loanBelowHundredFee &&(<Grid item xs={12} sm={6} md={3}>
                    <label>Charges For Loan Below 100K</label>
                    <input
                      type="number"
                      className={
                        errorEdit.loanBelowHundredFee ? "error-input-z" : null
                      }
                      name="loanBelowHundredFee"
                      step="any"
                      value={details.loanBelowHundredFee}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.loanBelowHundredFee && (
                      <ErrorMessage message={errorEdit.loanBelowHundredFee} />
                    )}
                  </Grid>)}
                  {details && details.loanAboveHundredFee &&(<Grid item xs={12} sm={6} md={3}>
                    <label>Charges For Loan Above 100K</label>
                    <input
                      type="number"
                      className={
                        errorEdit.loanAboveHundredFee ? "error-input-z" : null
                      }
                      name="loanAboveHundredFee"
                      step="any"
                      value={details.loanAboveHundredFee}
                      onChange={handleEditDetailsChange}
                    />
                    {errorEdit.loanAboveHundredFee && (
                      <ErrorMessage message={errorEdit.loanAboveHundredFee} />
                    )}
                  </Grid>)}
                  <Grid item xs={12} sm={6} md={3} className={classes.checkbox}>
                    <input
                      type="checkbox"
                      name="bulletpayment"
                      checked={details.bulletpayment}
                      onChange={handleEditDetailsChange}
                    />
                    <label>Bullet Payment</label>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {loanProductCharge.map((item, i) => (
                  <Grid
                    container
                    spacing={2}
                    border={1}
                    className={classes.border}
                    key={item.id}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <label>Charge Name</label>
                      <select
                        name="chargeName"
                        className={
                          displayErr(i, "chargeName") ? "error-input-z" : null
                        }
                        value={item.chargeName}
                        onChange={(e) => handleEditLoanProductChange(e, i)}
                      >
                        <option value="AdminFee">AdminFee</option>
                        <option value="LegalFees">LegalFees</option>
                        <option value="DocCharge">DocCharge</option>
                        <option value="CreditSearchFee">CreditSearchFee</option>
                        <option value="MngmtFee">MngmtFee</option>
                        <option value="LandSearchFee">LandSearchFee</option>
                        <option value="ValuationFee">ValuationFee</option>
                        <option value="InsuranceFee">InsuranceFee</option>
                        <option value="RolloverFee">RolloverFee</option>
                        <option value="ProcessingFee">ProcessingFee</option>
                      </select>
                      {displayErr(i, "chargeName") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label>Apply Charges At {item.applyChargesAt}</label>
                      <select
                        name="applyChargesAt"
                        className={
                          displayErr(i, "applyChargesAt")
                            ? "error-input-z"
                            : null
                        }
                        value={item.applyChargesAt}
                        onChange={(e) => handleEditLoanProductChange(e, i)}
                      >
                        <option value="Booking">Booking</option>
                        <option value="Repayment">Repayment</option>
                        <option value="Disbursement">Disbursement</option>
                      </select>
                      {displayErr(i, "applyChargesAt") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label>
                        Charge Ledger Account {item.chargeLedgerAccount}
                      </label>
                      <select
                        name="chargeLedgerAccount"
                        className={
                          displayErr(i, "chargeLedgerAccount")
                            ? "error-input-z"
                            : null
                        }
                        value={item.chargeLedgerAccount}
                        onChange={(e) => handleEditLoanProductChange(e, i)}
                      >
                        {allGlAccount.map((item) => (
                          <option value={item.accountNumber} key={item.id}>
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                      {displayErr(i, "chargeLedgerAccount") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label>Type of charge</label>
                      <select
                        name="typeOfCharge"
                        className={
                          displayErr(i, "typeOfCharge") ? "error-input-z" : null
                        }
                        value={item.typeOfCharge}
                        onChange={(e) => handleEditLoanProductChange(e, i)}
                      >
                        <option value="FlatAmount">Flat Amount</option>
                        <option value="Rate">Rate</option>
                      </select>
                      {displayErr(i, "typeOfCharge") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>

                    {item.typeOfCharge === "FlatAmount" && (
                      <Grid item xs={12} sm={6} md={4}>
                        <label>Amount</label>
                        <NumberFormat
                          thousandSeparator={true}
                          value={item.amount}
                          inputMode="numeric"
                          renderText={(value) => <div>{value}</div>}
                          className={`some ${
                            displayErr(i, "FlatAmount") ? "error-input-z" : null
                          }`}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            handleEditLoanProductChange(
                              "",
                              i,
                              "amount",
                              value,
                              formattedValue
                            );
                          }}
                        />
                        {displayErr(i, "FlatAmount") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                    )}
                    {item.typeOfCharge === "Rate" && (
                      <Grid item xs={12} sm={6} md={4}>
                        <label>Rate</label>
                        <input
                          type="number"
                          className={
                            displayErr(i, "Rate") ? "error-input-z" : null
                          }
                          name="rate"
                          value={item.rate}
                          onChange={(e) => handleEditLoanProductChange(e, i)}
                        />
                        {displayErr(i, "Rate") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      <label>Charge currency</label>
                      <select
                        name="chargeCurrency"
                        className={
                          displayErr(i, "chargeCurrency")
                            ? "error-input-z"
                            : null
                        }
                        value={item.chargeCurrency}
                        onChange={(e) => handleEditLoanProductChange(e, i)}
                      >
                        {allCurrencyDefinition.map((item) => (
                          <option value={item.currencyCode} key={item.id}>
                            {item.currencyName}
                          </option>
                        ))}
                      </select>
                      {displayErr(i, "chargeCurrency") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.buttondiv}>
                        <Button
                          className={classes.button}
                          onClick={() => handleSubmitEditCharges(i, "charges")}
                        >
                          Update
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/* <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <input
                      // className="checkbox-noncust"
                      type="radio"
                      name="updateOverDueDays"
                      value={"update"}
                      checked={updateAddOverDueDays === "update"}
                      onChange={handleChange}
                    />
                    <label>Update</label>{" "}
                  </Grid>
                  <Grid item xs={2}>
                    <input
                      // className="checkbox-noncust"
                      type="radio"
                      name="addBulk"
                      value={"addBulk"}
                      checked={updateAddOverDueDays === "addBulk"}
                      onChange={handleChange}
                    />
                    <label>Add</label>
                  </Grid>
                </Grid> */}
                {/* <div className="check-div">
            
                <span style={{color: 'white'}}>Name</span> 
                </div>                 
                */}
                <Grid container spacing={8}>
                  {/* <Grid item xs={2}>
                    <button
                      className={classes.addButton}
                      onClick={() => addOverdueDays()}
                    >
                      Add +
                    </button>
                  </Grid> */}
                  <Grid item xs={2}>
                  <button
                    className={classes.addButton}
                    onClick={() => {
                      dispatch(openFormModal());
                    }}
                  >
                    Add overdue days
                  </button>
                  </Grid>
                </Grid>
                <br />
                {overdueDays.map((item, i) => (
                  <>
                    <Grid
                      container
                      spacing={1}
                      className={classes.item}
                      key={item.id}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <label>From</label>
                        <input
                          type="number"
                          className={
                            displayErr(i, "from") ? "error-input-z" : null
                          }
                          name="from"
                          value={item.from}
                          onChange={(e) => handleEditOverdueDayChange(e, i)}
                        />
                        {displayErr(i, "from") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>To</label>
                        <input
                          type="number"
                          className={
                            displayErr(i, "to") ? "error-input-z" : null
                          }
                          name="to"
                          value={item.to}
                          onChange={(e) => handleEditOverdueDayChange(e, i)}
                        />
                        {displayErr(i, "to") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Status</label>
                        <select
                          name="loanStatus"
                          className={
                            displayErr(i, "loanStatus") ? "error-input-z" : null
                          }
                          value={item.loanStatus}
                          onChange={(e) => handleEditOverdueDayChange(e, i)}
                        >
                          {loanStatus.map((item, i) => (
                            <option
                              key={item.id}
                              name={item.parameterValue}
                              value={item.parameterValue}
                            >
                              {item.parameterValue}
                            </option>
                          ))}
                        </select>
                        {displayErr(i, "loanStatus") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <br />
                        <div>
                          <button
                            className={classes.removeButton}
                            onClick={() => deleteAvOverdeuDay(i)}
                          >
                            Remove
                          </button>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <div className={classes.buttondiv}>
                          <Button
                            className={classes.button}
                            onClick={() =>
                              handleSubmitEditCharges(i, "overdueDays")
                            }
                          >
                            Update
                          </Button>
                        </div>
                      </Grid>
                    </Grid>

                    {/* {updateAddOverDueDays === "addBulk" && (<Grid
                      container
                      spacing={1}
                      className={classes.item}
                      key={item.id}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <label>From</label>
                        <input
                          type="number"
                          className={
                            displayErr(i, "from") ? "error-input-z" : null
                          }
                          name="from"
                          value={item.from}
                          onChange={(e) => handleOverdueDayChange(e, i)}
                        />
                        {displayErr(i, "from") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}{" "}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>To</label>
                        <input
                          type="number"
                          className={
                            displayErr(i, "to") ? "error-input-z" : null
                          }
                          name="to"
                          value={item.to}
                          onChange={(e) => handleOverdueDayChange(e, i)}
                        />
                        {displayErr(i, "to") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>Status</label>
                        <select
                          name="loanStatus"
                          className={
                            displayErr(i, "loanStatus") ? "error-input-z" : null
                          }
                          value={item.loanStatus}
                          onChange={(e) => handleOverdueDayChange(e, i)}
                        >
                          {loanStatus.map((item, i) => (
                            <option
                              key={item.id}
                              name={item.parameterValue}
                              value={item.parameterValue}
                            >
                              {item.parameterValue}
                            </option>
                          ))}
                        </select>
                        {displayErr(i, "loanStatus") && (
                          <ErrorMessage
                            message={
                              errorsOfOtherServicesOtherthanProductDetails.msg
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.buttondiv}>
                          <Button
                            className={classes.button}
                            onClick={() =>
                              handleSubmitEditCharges(i, "overdueDays")
                            }
                          >
                            Add Bulk
                          </Button>
                        </div>{" "}
                      </Grid>
                    </Grid>)} */}
                  </>
                ))}

                {actionAccount && (
                  <AddBulkModal
                    actionAccountObject={actionAccount}
                    actionOnClose={() => {
                      setActionAccount({});
                    }}
                    onSuccess={() => {}}
                    open={openAddBulkModal}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {repaymentOrder.map((item, i) => (
                  <Grid
                    container
                    spacing={1}
                    className={classes.item}
                    key={item.id}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <label>Sequence order</label>
                      <input
                        type="number"
                        name="sequenceId"
                        disabled
                        className="input--1"
                        value={item.sequenceId}
                        onChange={(e) => handleEditRepaymentOrderChange(e, i)}
                      />
                      {displayErr(i, "sequenceId") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <label>Type</label>
                      <select
                        name="repaymentType"
                        value={item.repaymentType}
                        className={
                          displayErr(i, "repaymentType")
                            ? "error-input-z"
                            : null
                        }
                        //className="select--s"
                        onChange={(e) => handleEditRepaymentOrderChange(e, i)}
                      >
                        <option value="Interest">Interest</option>
                        <option value="Principal">Principal</option>
                        <option value="Charges">Charges</option>
                        <option value="Penalties">Penalties</option>
                        <option value="WriteOff">WriteOff</option>
                      </select>
                      {displayErr(i, "repaymentType") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.buttondiv}>
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleSubmitEditCharges(i, "repayment order")
                          }
                        >
                          Update
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </TabPanel>
              <TabPanel value={value} index={4}>
                {customerRestriction.map((item, i) => (
                  <Grid container spacing={1} className={classes.item} key={i}>
                    <Grid item xs={12} sm={6} md={6}>
                      <label>Customer Category</label>
                      <select
                        name="customerCategory"
                        value={item.customerCategory}
                        onChange={(e) =>
                          handleEditCustomerRestrictionChange(e, i)
                        }
                      >
                        {cusCategory.map((item, i) => (
                          <option
                            key={item.id}
                            className={
                              displayErr(i, "category") ? "error-input-z" : null
                            }
                            name={item.category}
                            value={item.category}
                          >
                            {item.category}
                          </option>
                        ))}
                      </select>
                      {displayErr(i, "category") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <label>Category Description</label>
                      <input
                        type="text"
                        disabled
                        className={
                          displayErr(i, "customerName") ? "error-input-z" : null
                        }
                        name="customerName"
                        value={item.customerName}
                        onChange={(e) =>
                          handleEditCustomerRestrictionChange(e, i)
                        }
                      />
                      {displayErr(i, "customerName") && (
                        <ErrorMessage
                          message={
                            errorsOfOtherServicesOtherthanProductDetails.msg
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.buttondiv}>
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleSubmitEditCharges(i, "customer restriction")
                          }
                        >
                          Update
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </TabPanel>
            </div>
          </>
        </div>
      </section>
    </EditModal>
  );
};

export default EditData;

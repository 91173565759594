import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../DatagridTable/table";
import DetailsContainer from "../../DatagridTable/details";
import AuthorizationModal from "../../DatagridTable/authorizationModal";
import { getHandler } from "../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../Backdrop";
import { openDetailsModal, loading } from "../../../Api/redux-manager/actions";
import { baseUrl } from "../../../Api/configUrl";
// import { getCustomerByAccountNumber } from "../../../Api/Hooks-manager/loanAccountOperations";
import Details from "../../Details/AccountOperations/LienAccountDetails";
import { formattedDate } from "../../../formatter/date"

const  LienAccountBlockAuth = () => {
  const dispatch = useDispatch();
  const [lien, setLien] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lienCode",
      label: "Lien Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
   
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = lien[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal())
      }
    },
  };


  const fetchLienAccountUnAuth = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}account/Lien/GetUnAuthorized`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
      if (response.data.length > 0){
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setLien(response.data);
    }
  }, [dispatch, token]);


  useEffect(() => {
    fetchLienAccountUnAuth();
  }, [fetchLienAccountUnAuth, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Lien/Amount Block"
        subtitle=""
        name="List of Accounts"
        records={lien}
        options={options}
        columns={columns}
      />}
      <DetailsContainer
        title="Lien/Amount Block - Record Details"
      >
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize Account"
        question="Are you sure you want to authorize this Account?"
        url={`${baseUrl}account/Lien/Authorize/${details.id}`}
      />
    </div>
  );
};

export default LienAccountBlockAuth;

import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import Item from "../../DatagridTable/item";
import { numberFormatter } from "../../../formatter";
import { formattedDate } from "../../../formatter/date";
import { format } from "date-fns";

import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  text: {
    margin: "auto",
    marginTop: "2rem",
    marginBottom: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  item: {
    width: "100%",
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const LoanBooking = ({ details }) => {
  console.log(details, "here is what we have in Odetails");
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [details]);

  return (
    <Grid container spacing={2} className={classes.root}>
      <div>
        <>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Loan Details"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Loan Product Charges"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Guarantor"
                  {...a11yProps(2)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Loan Disbursement"
                  {...a11yProps(3)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Repayment Schedule"
                  {...a11yProps(4)}
                  onClick={() => setHide(true)}
                />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Customer Account No."
                    name={details.accountNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Customer Name" name={details.accountName} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Loan Account No." name={details.loanAccount} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Account Holder"
                    name={details.accountHolder ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Branch" name={details.accountBranch} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Currency" name={details.currencyCode} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Loan Product" name={details.loanProduct} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Amount" name={numberFormatter(details.amount)} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Interest Calculation Method"
                    name={details.interestCalcMethod}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Tenor" name={details.tenor} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Loan Status" name={details.loanStatus} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Loan Classification"
                    name={
                      details.loanClassification
                        ? details.loanClassification
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Amortization Type"
                    name={details.amortizationType}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Rate" name={details.interestRate} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Repayment Account"
                    name={details.repaymentAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Tenor Basis" name={details.tenorBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Tenor" name={details.tenor} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Rep Customer Name"
                    name={details.repCustomerName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Rep. Account Branch"
                    name={details.repAccountBranch}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Rep. Account Currency"
                    name={details.repAccountCurrency}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Loan Officer"
                    name={details.loanOfficer || "Nil"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Rep Customer Name"
                    name={details.repCustomerName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Loan Book Date"
                    name={formattedDate(details.bookDate)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Loan Maturity Date"
                    name={formattedDate(details.maturityDate)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="First Repayment Date"
                    name={formattedDate(details.firstRepaymentDate)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Created Date Time"
                    name={formattedDate(details.createdDateTime)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Updated Date Time"
                    name={formattedDate(details.updatedDateTime)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Date Authorized"
                    name={formattedDate(details.dateAuthorized)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Status"
                    name={
                      details.authorizationStatus ===
                      "PendingEditedAuthorization"
                        ? "Pending Edited Authorization"
                        : details.authorizationStatus
                    }
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid conatiner spacing={2} className={classes.root}>
                {details &&
                details.loanCharges &&
                details.loanCharges.length > 0 ? (
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Charges</th>
                        <th>Account</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Rate</th>
                        <th>Currency</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.loanCharges.map((el, i) => (
                        <tr key={i}>
                          <td>
                            <Item label="" name={el.chargeName} />
                          </td>
                          <td>
                            <Item label="" name={el.applyChargeAt} />
                          </td>
                          <td>
                            <Item label="" name={el.chargeLedgerAccount} />
                          </td>
                          <td>
                            <Item label="" name={el.typeOfCharge} />
                          </td>
                          <td>
                            <Item label="" name={el.amountBasis} />
                          </td>
                          <td>
                            <Item label="" name={el.rate} />
                          </td>
                          <td>
                            <Item label="" name={el.chargeCurrency} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Grid xs={12}>
                    <div className={classes.noData}>
                      <Item
                        label=""
                        name="Charges are not applicable"
                        className={classes.item}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid conatiner spacing={2} className={classes.root}>
                {details &&
                details.loanGuarantors &&
                details.loanGuarantors.length > 0 ? (
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Acc No.</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Phone No.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.loanGuarantors.map((el, i) => (
                        <tr key={i}>
                          <td>
                            <Item label="" name={el.guarantorAccName} />
                          </td>
                          <td>
                            <Item label="" name={el.guarantorAccNo} />
                          </td>
                          <td>
                            <Item label="" name={el.guarantorAddress} />
                          </td>
                          <td>
                            <Item label="" name={el.state} />
                          </td>
                          <td>
                            <Item label="" name={el.country} />
                          </td>
                          <td>
                            <Item label="" name={el.contactMobile} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Grid xs={12}>
                    <div className={classes.noData}>
                      <Item
                        label=""
                        name="Guarantors are not applicable"
                        className={classes.item}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid conatiner spacing={2} className={classes.root}>
                {details &&
                details.disbursements &&
                details.disbursements.length > 0 ? (
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Method</th>
                        <th>Ref No.</th>
                        <th>Amount</th>
                        <th>Created Date Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.disbursements.map((el, i) => (
                        <tr key={i}>
                          <td>
                            <Item label="" name={el.disbursementMethod} />
                          </td>
                          <td>
                            <Item label="" name={el.disbursementReferenceNo} />
                          </td>
                          <td>
                            <Item label="" name={numberFormatter(el.amount)} />
                          </td>
                          <td>
                            <Item
                              label=""
                              name={formattedDate(el.createdDateTime)}
                            />
                          </td>
                          <td>
                            <Item label="" name={el.authorizationStatus} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Grid xs={12}>
                    <div className={classes.noData}>
                      <Item
                        label=""
                        name="This service is currently not available until authorization"
                        className={classes.item}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid conatiner spacing={2} className={classes.root}>
                {details &&
                details.repayments &&
                details.disbursements.length > 0 &&
                details.repayments.length > 0 ? (
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Due Date</th>
                        <th>Currency</th>
                        <th>Rep. Amount</th>
                        <th>Rep. Principal</th>
                        <th>Rep. Interest</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.repayments.map((el, i) => (
                        <tr key={i}>
                          <td>
                            <Item
                              label=""
                              name={format(new Date(el.dueDate), "yyyy-MM-dd")}
                            />
                          </td>
                          <td>
                            <Item label="" name={el.currency} />
                          </td>
                          <td>
                            <Item
                              label=""
                              name={numberFormatter(el.repaymentAmount)}
                            />
                          </td>
                          <td>
                            <Item
                              label=""
                              name={numberFormatter(el.repaymentPrincipal)}
                            />
                          </td>
                          <td>
                            <Item
                              label=""
                              name={numberFormatter(el.repaymentInterest)}
                            />
                          </td>
                          <td>
                            <Item label="" name={el.paymentStatus} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Grid xs={12}>
                    <div className={classes.noData}>
                      <Item
                        label=""
                        name="This service is currently not available until after disbursement"
                        className={classes.item}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid conatiner spacing={2} className={classes.root}></Grid>
            </TabPanel>
          </div>
        </>
      </div>

      {/* <Divider className={classes.hr} />
      <h6 className={classes.text}>loan Product Charge</h6>

      <h6 className={classes.text}>Guarantors</h6>

      <h6 className={classes.text}>Loan Disbursements</h6>

      <h6 className={classes.text}>Repayments</h6>

      <Divider className={classes.hr} /> */}
    </Grid>
  );
};

export default LoanBooking;

import { useState, useEffect } from "react";
import {
  getCustomerByAccountNumber,
  getGLAccount,
  debit,
} from "../../../../Api/Hooks-manager/Transactions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllBranches } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import {getAUserTillDetails} from "../../../../Api/Hooks-manager/securitymanagement/tellerTills";
import  {handleAutoDebitForBulk} from "../../../../Api/Hooks-manager/Transactions"

import { DateConverter } from "../../../ConvertDate";
import { TransactionType, ModuleType } from "../../../../Helper/enum";
import {
  getCountries,
  getAllStatesByCountryCode,
} from "../../../../Api/places/getPlaces";
import useTable from "../../../Tables/useTable";

export const BussinessLogic = () => {
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const userId = useSelector((state) => state.common.userDetails.userName);
  const vaultDetails = useSelector((state) => state.common.branch || {});
  const [loading, setLoading] = useState(false);
  const [screenChange, setScreenChange] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [allBranches, setAllBranches] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorSummary, setErrorSummary] = useState(null);
  // console.log(vaultDetails, "here are the user details");

const [error, setError] = useState(false);
// const [user, setUser] = useState({
//   country: "",
//   tillAccountNumber: "",
//   tellerName: "",
//   branch: "",
//   amount: "",
//   narration: "",

// });

  const [debitAccDetails, setDebitAccDetails] = useState({
    accountName: "",
    entryBranch: "",
    currencyCode: "",
    branchName: "",
    availableBalance: null,
    isValidAccNum: false,
    accountNumber:""
  });
  const [creditAccDetails, setCreditAccDetails] = useState({
    accountName: "",
    accountBranch: "",
    currencyCode: "",
    isValidAccNum: false,
    // userCrCashLimit:"",
    accountNumber:""


  });


  const [errors, setErrors] = useState({});

  const [glTransaction, setGlTransaction] = useState({
    debitAccount: "", // account you are reoving money from
    creditAccount: vaultDetails.vaultAccount, // account you are sending money to
    postAmount: 0, //amount you want to transact
    transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
    transactionType: TransactionType.BCH, //enum
    entryBranch: user.operatingBranch, //get it from the user object operatingBranch
    debitAccountBranch: "", // the account in the debitor's acccount number
    creditAccountBranch: vaultDetails.code, // the account in the creditor's acccount number
    tillId: "", // the teller ID
    narration: "", //auto generate not bmore than 50 character
    currencyCode: "NGN", //from the fectched account
    debitModule: ModuleType.TellerTill, //enum from moduleType
    creditModule: ModuleType.GlAccount, //enum from moduleType
    inputterIpAddress: "", //empty
    bankId: user.institution, //get it from the user object  institution code : operatingBranch
    chequeNumber: "", //empty
    controlNumber: "", //empty for now
    clearingType: "", //empty for now
    issueDate: "", //datetime
    bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
    routingNumber: "", //empty for now
    terminalId: "", //empty for now
    msgType: "", //empty for now
    msgStan: "", //empty for now
    depositorName: "", //for non cash deposit it should be empty
    depositorMobile: "", //for non cash deposit it should be empty
    depositorAddress: "", //for non cash deposit it should be empty
    denominations: [1000, 500, 200,100, 50, 20, 10, 5, 2, 1].map((item) => {
      return {
        denomNo: item,
        denomCount: 0,
        denomTotal: 0,
      }
    }), //send empty array for non cash transaction
    amlDataStatus: true,
    amlAccountNumber: "", // here down empty string
    amlCustomerNumber: "",
    amlIdNumber: "",
    amlBvnNumber: "",
    amlCustomerType: "",
    amlCustomer: "",
    amlWaiveId: true,
    amlTitle: "",
    amlFirstName: "",
    amlMiddleName: "",
    amlLastName: "",
    amlPhoneNumber: "",
    amlDob: "",
    amlPob: "",
    amlNationality: "",
    amlAddress: "",
    amlFundSource: "",
    amlOccupation: "",
    amlNumber: "",
    amlType: "",
    amlIssuer: "",
    amlCountry: "",
    amlState: "",
    amlExpiration: "",
    amlIssueDate: "",
  });

  const [denomTotalValue, setDenomTotalValue] = useState(0);

  // const fetchAllBranches = async () => {
  //   setLoading(true);
  //   const allResponse = await getAllBranches(user.jwtToken);
  //   if(allResponse && allResponse.data){
  //       setLoading(false);
  //       // setAllBranches(
  //       //     allResponse.data.filter(
  //       //       (item) => item.authorizationStatus === "Authorized"
  //       //     )
  //       //   );
  //       // console.log(allResponse, "")
  //   }
  //   const response = allResponse.data.find((item => item))
  //   if(response) {
  //       console.log(response, "ogggggaaa")

  //     setGlTransaction({
  //       ...glTransaction,
  //       creditAccount: response.vaultAccount,
  //       creditAccountBranch: response.parentBranchId,
  //     });
  //     setCreditAccDetails({
  //       ...creditAccDetails,
  //       accountBranch: response.parentBranchId,
  //       isValidAccNum: true,
  //     });
  //   }
  // };

  const { debitAccount, creditAccount } = glTransaction;

  // const [denominationValue, setDenominationValue] = useState([glTransaction.denominations]);

// const calulateDenomination = () => {
//   const denomList = [...glTransaction.denominations];
//   for (let item of denomList){
//     item.denomTotal = item.denomTotal > 0 ? item.denomTotal : item.denomNo * item.denomCount
//   }
//   console.log(denomList, "here is the maths")
//   setGlTransaction(prev => ({
//     ...prev,
//     denominations: denomList
//   }))
// }

// useEffect(() => {
//   calulateDenomination();
// },[])

  // useEffect(() => {
  //   const denomList = [...glTransaction.denominations];
  //   const newTotal = (denomList.denomNo) * (denomList.denomCount);
  //   setGlTransaction((glTransaction) => ({
  //     ...glTransaction,
  //     denomTotal: newTotal,
  //   }));
  //   console.log(newTotal, "adding amount")
  // }, [glTransaction.denominations])






  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "debitAccount" && value.length > 10) ||
      (name === "creditAccount" && value.length > 9)
    )
      return;
    const inputedVal =
      value === "" ? "" : parseFloat(value) < 0 ? 0 : parseFloat(value);
    setGlTransaction({
      ...glTransaction,
      [name]: name === "postAmount" ? inputedVal : value,
    });

    

  };
  
  const handleDenominationChange = (e, index) => {
    const {name, value} = e.target;
    const denomList = [...glTransaction.denominations];
    if(name === "denomValue") return;
    const denomObject = denomList[index]
    denomObject["denomCount"] = value;
    denomObject["denomTotal"] = (denomObject['denomNo']) * (denomObject['denomCount']) 
    denomList[index] = denomObject
    const totalValue = denomList.reduce((prev, current) => {
      return prev + current.denomTotal
    },0)
    setDenomTotalValue(totalValue);
    
    // console.log(totalValue)

    // setGlTransaction({
    //   ...glTransaction,
    //   denominations : denomList
    // });
    // console.log(glTransaction, "gogogo")
  }

//   const fetchCustomerDetails = async (accNum) => {
//     setLoading(true);
//     const response = await getCustomerByAccountNumber(token, accNum);
//     console.log("-----accounts details---", response);
//     if (response && response.data) {
//       setLoading(false);
//       if (response.status) {
//         if (response.data.authorizationStatus === 'Authorized'){
//         setGlTransaction({
//           ...glTransaction,
//           currencyCode: response.data.currency,
//           creditAccountBranch: response.data.branchCode,
//         });
//         setDebitAccDetails({
//           ...debitAccDetails,
//           accountName: response.data.accountName,
//           availableBalance: response.data.accountBalance,
//           entryBranch: response.data.branchCode,
//           accountCurrency: response.data.currency,
//           bvn: response.data.customer.bvn,
//           phoneNumber: response.data.customer.phoneNumber,
//           product: response.data.accountProduct.productName,
//           email: response.data.customer.email,
//           isValidAccNum: true,
//         });
//         setErrors({ ...errors, debitAccount: "" });
//       }else{
//         setErrors({ ...errors, debitAccount: 'Unauthorized Account Number'  });
//         }
//       } else {
//         clearDebitAccountDetails();
//         setErrors({ ...errors, debitAccount: response.message });
//       }
//     }
//   };

  const fetchDebitAccountDetails = async () => {
    //gl account details
    setLoading(true);
    const allResponse = await getAUserTillDetails(token, userId);
    // console.log("-----debit accounts details---", allResponse);
    if(allResponse && allResponse.data) {
      setLoading(false);
    }
    const response = allResponse.data.find((item => item.accountGL));
    console.log(response, "teller till details")
    const response2 = allResponse.data.find((item) => item.tellerTillLinkage);
    if(response){
      setGlTransaction({
        ...glTransaction,
        debitAccount: response.accountGL.accountNumber, 
        entryBranch:response.accountGL.branchCode,
        debitAccountBranch: response.accountGL.branchCode,
        tillId: response.tillId, 
      });
      setDebitAccDetails({
        ...debitAccDetails,
        accountName: response.accountGL.accountName,
          entryBranch: response.accountGL.branchCode,
          currencyCode: response.accountGL.currency,
        isValidAccNum: true,
        // userCrCashLimit:response2.tellerTillLinkage.userCrCashLimit
      });
      setErrors({ ...errors, debitAccount: "" });
    } else {
      clearDebitAccountDetails();

    setErrors({ ...errors, debitAccount: "" });
    }
    // if (response && response.data) {
    //   setLoading(false);
    //   if (response.status) {
    //     if (
    //       response.data.authorizationStatus === "Authorized"
    //     ) {
    //       setGlTransaction({...glTransaction,entryBranch:response.data.branchCode, currencyCode: response.data.currency});
    //       setDebitAccDetails({
    //         ...debitAccDetails,
    //         accountName: response.data.accountName,
    //         //   entryBranch: response.data.branchCode,
    //         //   currencyCode: response.data.currency,
    //         isValidAccNum: true,
    //       });
    //       setCreditAccDetails([])
    //       setErrors({ ...errors, creditAccount: "" });
    //     } else {
    //       setErrors({
    //         ...errors,
    //         creditAccount: "Unauthorized Account Number",
    //       });
    //     }
    //   } else {
    //     clearCreditAccountDetails();
    //     setErrors({ ...errors, creditAccount: response.message });
    //   }
    // }
  };

  const EmptyFieldMakeTransfer = () => {
    setGlTransaction({
      debitAccount: "", // account you are reoving money from
      creditAccount: vaultDetails.vaultAccount, // account you are sending money to
      postAmount: 0, //amount you want to transact
      transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
      transactionType: TransactionType.BCH, //enum
      entryBranch: user.operatingBranch, //get it from the user object operatingBranch
      debitAccountBranch: "", // the account in the debitor's acccount number
      creditAccountBranch: "", // the account in the creditor's acccount number
      tillId: "", // the teller ID
      narration: "GL to GL transfer", //auto generate not bmore than 50 character
      currencyCode: "NGN", //from the fectched account
      debitModule: ModuleType.TellerTill, //enum from moduleType
      creditModule: ModuleType.GlAccount, //enum from moduleType
      inputterIpAddress: "", //empty
      bankId: user.institution, //get it from the user object  institution code : operatingBranch
      chequeNumber: "", //empty
      controlNumber: "", //empty for now
      clearingType: "", //empty for now
      issueDate: "", //datetime
      bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
      routingNumber: "", //empty for now
      terminalId: "", //empty for now
      msgType: "", //empty for now
      msgStan: "", //empty for now
      depositorName: "", //for non cash deposit it should be empty
      depositorMobile: "", //for non cash deposit it should be empty
      depositorAddress: "", //for non cash deposit it should be empty
      denominations: [1000, 500, 200, 100, 50, 20, 10, 5, 2, 1].map((item) => {
        return {
          denomNo: item,
          denomCount: 0,
          denomTotal: 0,
        };
      }), //send empty array for non cash transaction
      amlDataStatus: true,
      amlAccountNumber: "", // here down empty string
      amlCustomerNumber: "",
      amlIdNumber: "",
      amlBvnNumber: "",
      amlCustomerType: "",
      amlCustomer: "",
      amlWaiveId: true,
      amlTitle: "",
      amlFirstName: "",
      amlMiddleName: "",
      amlLastName: "",
      amlPhoneNumber: "",
      amlDob: "",
      amlPob: "",
      amlNationality: "",
      amlAddress: "",
      amlFundSource: "",
      amlOccupation: "",
      amlNumber: "",
      amlType: "",
      amlIssuer: "",
      amlCountry: "",
      amlState: "",
      amlExpiration: "",
      amlIssueDate: "",
    });
  };
  const makeAutoTransfer = async () => {
    setLoading(true);
    const response = await handleAutoDebitForBulk(user.jwtToken, glTransaction);
    console.log("debited....", response);
    if (response && response.data) {
      setLoading(false);
      setMessage(response.message);
      if (response.status) {
        clearDebitAccountDetails();
        clearCreditAccountDetails();
        EmptyFieldMakeTransfer();
        setScreenChange(false);
        return true;
      } else {
        return false;
      }
    }
  };

  const makeTransfer = async () => {
    setLoading(true);
    const response = await debit(user.jwtToken, glTransaction);
    console.log("debited....", response);
    if (response && response.data) {
      setLoading(false);
      setMessage(response.message);
      if (response.status) {
        clearDebitAccountDetails();
        clearCreditAccountDetails();
        EmptyFieldMakeTransfer();
        setScreenChange(false);
        return true;
      } else {
        return false;
      }
    }
  };

  const clearCreditAccountDetails = () => {
    setCreditAccDetails({
      accountName: "",
      entryBranch: "",
      currencyCode: "",
      accountCurrency: "",
      bvn: "",
      availableBalance: null,
      isValidAccNum: false,
    });
    setGlTransaction({ ...glTransaction, currencyCode: "", debitAccountBranch:"" });
  };

  const clearDebitAccountDetails = () => {
    setDebitAccDetails({
      accountName: "",
      accountBranch: "",
      currencyCode: "",
      isValidAccNum: false,
    });
  };

  // useEffect(() => {
  //   if (debitAccount && debitAccount.length !== 10) {
  //     setErrors({ ...errors, debitAccount: "" });
  //     clearDebitAccountDetails();
  //   }
  // }, [debitAccount]);

  useEffect(() => {
    if (creditAccount && creditAccount.length < 9) {
      setErrors({ ...errors, creditAccount: "" });
      clearCreditAccountDetails();
    }
  }, [creditAccount]);

  // useEffect(() => {
  //   if (debitAccount && debitAccount.length === 9) {
  //     fetchCustomerDetails(debitAccount);
  //   }
  // }, [debitAccount]);

  useEffect(() => {
    fetchDebitAccountDetails(debitAccount);
  }, [debitAccount]);

  // useEffect(() => {
  //   fetchAllBranches(creditAccount);
  // }, [creditAccount]);



  const validateInput = () => {
    const { debitAccount, creditAccount, postAmount } = glTransaction;
    const errors = {};

    if (debitAccount === "") {
      errors.debitAccount = "Account No. is required";
    }
    if (postAmount === "") {
      errors.postAmount = "Field is required.";
    }
    // if (debitAccount && debitAccount.length < 9) {
    //   errors.debitAccount = "9 characters are required";
    // }
    // if (
    //   debitAccount &&
    //   debitAccount.length !== 9 &&
    //   !debitAccount.isValidAccNum
    // ) {
    //   errors.debitAccount = "Invalid Account No.";
    // }

    // if (debitAccDetails.availableBalance != undefined && postAmount > debitAccDetails.availableBalance){
    //   errors.postAmount = "Insufficient balance in debit account";
    // }

    return errors;
  };


//   const handleNext = () => {
//     let checkError = isValidateForm(user);
//     console.log(Object.keys(checkError).length, "errorvslues");
//     setError(checkError);
//     if (Object.keys(checkError).length > 0) {
//       return;
//     }
//     setError({});
//     setScreenChange(true);
//   };
  const handleScreenChange = (i) => {
    let checkErrors = validateInput();
    console.log(Object.keys(checkErrors).length, "errorvslues");
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
    // const {postAmount} = glTransaction;
    // if (postAmount > debitAccDetails.availableBalance) {
    //   console.log(debitAccDetails.availableBalance, "insufficient balance");
    //   setErrorSummary({
    //     notBalance: `Insufficient balance in teller's till account:${debitAccDetails.availableBalance}`,
    //   });
    //   setOpenErrorModal(true);
    //   return;
    // }
    setScreenChange(i);
    console.log("qwswqeq", i);
  };

  const handleSubmit = () => {
    const {postAmount, denominations} = glTransaction;
    const checkErrors = validateInput();
    setErrors(checkErrors);
    if( denominations && denomTotalValue !== postAmount){
      setErrorSummary({
        notBalance: `Sum of denominations: ${denomTotalValue} must be equal to post amount: ${postAmount}`
      })
      setOpen(false);
      setOpenErrorModal(true);
    } else {
      if (Object.keys(checkErrors).length > 0) return;
      setOpen(true);
      setOpenErrorModal(false)
    }
    };

  return {
    glTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    denomTotalValue,
    screenChange,
    user,
    handleScreenChange,
    // denominationValue,
    // setDenominationValue,
    handleDenominationChange,
    error,
    errors,
    setErrors,
    validateInput,
    handleChange,
    makeTransfer,
    makeAutoTransfer,
    open,
    setOpen,
    openErrorModal,
    setOpenErrorModal,
    errorSummary,
    message,
    allBranches,
    handleSubmit,
    setGlTransaction,
  };
};

export default BussinessLogic;
import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from '../../../Details/SecurityManagement/UserTellerTill'
const View = ({ details }) => {
  return (
    <DetailsContainer
      title="User Teller Tills - Record Details"
      question="Are you sure you want to delete this Teller Till?"
      url={`${Routes.deleteUserTellerTill}${details.id}`}
    >
     <Details details={details}/>
    </DetailsContainer>
  );
};

export default View;

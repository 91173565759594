import axios from "axios";
import { Routes } from "../../configUrl";
const getAllReportLoans = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getAllReportLoan}${data.branchCode}/${data.startDate}/${data.endDate}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
const getLoanDisbursement = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getDisbursementLoan}${data.branchCode}/${data.startDate}/${data.endDate}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
const getAllLoanAccrual = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getAllLoanAccrualReport}${data.branchCode}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
const getAllParReports = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getParReports}${data.branchCode}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
const getParReportExtended = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getParReportsExtended}${data.branchCode}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
const getAllLoanRepaymentReport = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getAllLoanRepaymentReport}${data.branchCode}/${data.startDate}/${data.endDate}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
const getAllRepaymentDueReport = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getAllLoanRepaymentDue}${data.branchCode}/${data.startDate}/${data.endDate}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};

const getBranchCode = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getAllBranches}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getCbrReports = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.getAllCbrReports}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};
export {
  getAllReportLoans,
  getAllLoanAccrual,
  getLoanDisbursement,
  getAllParReports,
  getAllRepaymentDueReport,
  getAllLoanRepaymentReport,
  getBranchCode,
  getParReportExtended,
  getCbrReports,
};

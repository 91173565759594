import { useState , useEffect} from "react";
import "./DealPosting.scss";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import useBusinessLogic from '../PortfolioMaintenance/useBusinessLogic'
import ErrorMessage from "../../../ErrorMessage";
import BusinessLogic from "./businessLogic";
import Spinner from "../../../Backdrop/index";
import Confirm from "../../../../Confirmbox/Confirm";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";



const DealPosting = () => {

  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const loader = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const { allPortfolioMaintainance, getAllPortfolioMaintainance } = useBusinessLogic()
  const {
    data,
    handleDealPostCreate,
    dealPosting,
    setDealPosting,  
  } = BusinessLogic();

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portfolioCode",
      label: "Portfolio Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portfolioType",
      label: "Portfolio Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dealReference",
      label: "Deal Reference",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tradeDate",
      label: "Trade Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "externalReferenceNo",
      label: "Ext.Ref.No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dealQuantity",
      label: "Deal Quantity",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };


  useEffect(() => {
    getAllPortfolioMaintainance()
  }, [getAllPortfolioMaintainance])

  const [error, setError] = useState({});
  const [openBox, setOpenBox] = useState(false);
  const [productCo] =useState([]);
  const [setName] = useState("");
  


  const handleSave = (i) => {
    let checkError = isValidateForm(dealPosting);
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setOpenBox(true);
  };


  const handleEditChange = (name) => (e) => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };
  const handleChanger = (e) => {
    setDealPosting({ ...dealPosting, [e.target.name]: e.target.value });
  };

  
  useEffect(() => {
    if(dealPosting.portfolioCode) {
      let filteredArray = allPortfolioMaintainance.filter(items => items.portfolioCode === dealPosting.portfolioCode)
      setDealPosting(deal =>({...deal, portfolioType: filteredArray[0].portfolioType, moneyMarketType: filteredArray[0].moneyMarketType, accountNumber: filteredArray[0].customerAccount }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealPosting.portfolioCode])
  
  const isValidateForm = (dealPosting) => {
    let errors = {};
    
    if (!dealPosting.accountNumber) {
      errors.accountNumber = "This field is required";
    }
    if (!dealPosting.portfolioCode) {
      errors.portfolioCode = "This field is required";
    }
    if (dealPosting.portfolioType === "") {
      errors.portfolioType = "Select an option";
    }
    if (!dealPosting.product) {
      errors.product = "This field is required";
    }
    if (!dealPosting.productDescription) {
      errors.productDescription = "This field is required";
    }
    if (!dealPosting.dealReference) {
      errors.dealReference = "This field is required";
    }
    if (!dealPosting.externalReferenceNo) {
      errors.externalReferenceNo = "This field is required";
    }
    if (!dealPosting.dealQuantity) {
      errors.dealQuantity = "This field is required";
    }
    if (dealPosting.priceQuote === "") {
      errors.priceQuote = "Select an option";
    }
    if (dealPosting.currency === "") {
      errors.currency = "Select an option";
    }
    if (!dealPosting.inputPrice) {
      errors.inputPrice = "This field is required";
    }
    if (!dealPosting.priceInLocalCurrency) {
      errors.priceInLocalCurrency = "This field is required";
    }
    if (!dealPosting.tradeDate) {
      errors.tradeDate = "This field is required";
    }
    if (!dealPosting.quantityOfLocalCurrency) {
      errors.quantityOfLocalCurrency = "This field is required";
    }
    if (!dealPosting.dealInputDate) {
      errors.dealInputDate = "This field is required";
    }
    if (dealPosting.interest === "") {
      errors.interest = "Select an option";
    }
    if (!dealPosting.maturtiyDate) {
      errors.maturtiyDate = "This field is required";
    }
    if (dealPosting.quoteBy === "") {
      errors.quoteBy = "Select an optiond";
    }
      if (!dealPosting.brokerId) {
        errors.brokerId = "This field is required";
      }
      return errors;
    };
    const [ setErrorEdit] = useState()
    const handleEditSubmit = () => {
      setErrorEdit({});
      dispatch(openResquestDecisionModal());
    };

  useEffect(()=>{
    if (dealPosting.productCode){
      const proType = ()=>{

        const list= [...productCo];
        const currItem = list.find(item => item.value === dealPosting.productCode);
        setName(currItem.name)
      }
      proType()
    }
  }, [dealPosting.productCode])
  return (
    <div>
    <main className="deal--posting">
      <div className="heading">
        <p>
          <b>Treasury</b> | Deal Posting
        </p>
      </div>
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader} />
      </div>
      <Confirm
        ApiCall={handleDealPostCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
      />
      <Paper style={{ padding: 32 }}>
        <section className="section--1">
          <div className="deal-post flex--1">

            <div className="flex--3 input--2">
              <label>Portfolio Code</label>
              <select
                type="text"
                onChange={handleChanger}
                name="portfolioCode"
                value={dealPosting.portfolioCode}
                className={`${error.portfolioCode && "error-input-z"}`}
              >
                <option value="">Select a portfolio code</option>
                  {allPortfolioMaintainance.map((portfolio) =>(
                <option
                value ={portfolio.portfolioCode}
                key={portfolio.id}
                >{portfolio.portfolioCode} - {portfolio.portfolioType}</option>
              ))}
              </select>
              {error.portfolioCode && (
                <ErrorMessage message={error.portfolioCode} />
              )}
            </div>

            <div className="flex--3 input--3">
              <label>Portfolio Type</label>
              <input value={dealPosting.portfolioType} disabled/>
              {error.portfolioType && (
                <ErrorMessage message={error.portfolioType} />
              )}
            </div>
            <div className="flex--3 input--2">
              <label>Money Market Type</label>
              <input value={dealPosting.moneyMarketType} disabled/>
            </div>
            <div className="flex--3 input--2">
              <label>Account Number</label>
              <input value={dealPosting.accountNumber} disabled/>
            </div>
          </div>

          <div className="deal-post flex--1">
            <div className="flex--3 input--2">
              <label>Product</label>
              <input
                type="text"
                onChange={handleChanger}
                name="product"
                value={dealPosting.product}
                className={`${error.product && "error-input-z"}`}
              />
              {error.product && <ErrorMessage message={error.product} />}
            </div>

            <div className="flex--3 input--2">
              <label>Product Name</label>
              <input
                type="text"
                onChange={handleChanger}
                name="productDescription"
                value={dealPosting.productDescription}
                className={`${error.productDescription && "error-input-z"}`}
              />
              {error.productDescription && (
                <ErrorMessage message={error.productDescription} />
              )}
            </div>

            <div className="flex--3 input--2">
              <label>Deal Reference</label>
              <input
                type="text"
                onChange={handleChanger}
                name="dealReference"
                value={dealPosting.dealReference}
                className={`deal--ref ${error.dealReference && "error-input-z"}`}
              />
              {error.dealReference && (
                <ErrorMessage message={error.dealReference} />
              )}
            </div>

            <div className="flex--3 input--2">
              <label>External Reference No.</label>
              <input
                name="externalReferenceNo"
                value={dealPosting.externalReferenceNo}
                type="text"
                onChange={handleChanger}
                className={`${error.externalReferenceNo && "error-input-z"}`}
              />
              {error.externalReferenceNo && (
                <ErrorMessage message={error.externalReferenceNo} />
              )}
            </div>
          </div>

          <div className="deal-post flex--1">
            <div className="flex--3 input--2">
              <label>Deal Quantity</label>
              <input
                name="dealQuantity"
                onChange={handleChanger}
                value={dealPosting.dealQuantity}
                type="text"
                className={`${error.dealQuantity && "error-input-z"}`}
              />
              {error.dealQuantity && (
                <ErrorMessage message={error.dealQuantity} />
              )}
            </div>

            <div className="flex--3 input--3">
              <label>Price Quote</label>
              <select
                name="priceQuote"
                value={dealPosting.priceQuote}
                onChange={handleChanger}
                className={`${error.priceQuote && "error-input-z"}`}
              >
                <option value="">Select an option</option>
                <option value="Price">Price</option>
                <option value="PercentagePrice">PercentagePrice</option>
                <option value="Discount">Discount</option>
                <option value="PercentagePremium">PercentagePremium</option>
                <option value="PercentageDiscount">PercentageDiscount</option>
                <option value="Premium">Premium</option>
                <option value="YieldToMaturity">YieldToMaturity</option>
              </select>
              {error.priceQuote && <ErrorMessage message={error.priceQuote} />}
            </div>

            <div className="flex--3 input--3">
              <label>Currency</label>
              <select
                name="currency"
                value={dealPosting.currency}
                onChange={handleChanger}
                className={`${error.currency && "error-input-z"}`}
              >
                <option value="">Select an option</option>
                <option value="NGN">NGN</option>
                <option value="GDP">GDP</option>
              </select>
              {error.currency && <ErrorMessage message={error.currency} />}
            </div>

            <div className="flex--3 input--2">
              <label>Input Price</label>
              <input
                name="inputPrice"
                type="number"
                step="any"
                onChange={handleChanger}
                value={dealPosting.inputPrice}
                className={`${error.inputPrice && "error-input-z"}`}
              />
              {error.inputPrice && <ErrorMessage message={error.inputPrice} />}
            </div>
          </div>

          <div className="deal-post flex--1">
            <div className="flex--3 input--2">
              <label>Price In Local Currency</label>
              <input
                name="priceInLocalCurrency"
                type="number"
                step="any"
                onChange={handleChanger}
                value={dealPosting.priceInLocalCurrency}
                className={`${error.priceInLocalCurrency && "error-input-z"}`}
              />
              {error.priceInLocalCurrency && (
                <ErrorMessage message={error.priceInLocalCurrency} />
              )}
            </div>

            <div className="flex--3 input--2">
              <label>Trade Date</label>
              <input
                type="date"
                name="tradeDate"
                onChange={handleChanger}
                value={dealPosting.tradeDate}
                className={`${error.tradeDate && "error-input-z"}`}
              />
              {error.tradeDate && <ErrorMessage message={error.tradeDate} />}
            </div>

            <div className="flex--3 input--2">
              <label>Qty. of Local Currency</label>
              <input
                name="quantityOfLocalCurrency"
                onChange={handleChanger}
                value={dealPosting.quantityOfLocalCurrency}
                className={`${
                  error.quantityOfLocalCurrency && "error-input-z"
                }`}
              />
              {error.quantityOfLocalCurrency && (
                <ErrorMessage message={error.quantityOfLocalCurrency} />
              )}
            </div>

            <div className="flex--3 input--2">
              <label>Deal Input Date</label>
              <input
                type="Date"
                name="dealInputDate"
                onChange={handleChanger}
                value={dealPosting.dealInputDate}
                className={`${error.dealInputDate && "error-input-z"}`}
              />
              {error.dealInputDate && (
                <ErrorMessage message={error.dealInputDate} />
              )}
            </div>
          </div>

          <div className="deal-post flex--1">
            <div className="flex--3 input--3">
              <label>Interest</label>
              <select
                name="interest"
                type="number"
                step="any"
                onChange={handleChanger}
                value={dealPosting.interest}
                className={`${error.interest && "error-input-z"}`}
              >
                <option value="">Select an option</option>
                <option value="PlusAccrued">Plus Accrued</option>
                <option value="Flat">Flat</option>
              </select>
              {error.interest && <ErrorMessage message={error.interest} />}
            </div>

            <div className="flex--3 input--2">
              <label>Maturity Date</label>
              <input
                type="date"
                name="maturtiyDate"
                onChange={handleChanger}
                value={dealPosting.maturtiyDate}
                className={`maturity--date ${error.maturtiyDate && "error-input-z"}`}
              />
              {error.maturtiyDate && (
                <ErrorMessage message={error.maturtiyDate} />
              )}
            </div>

            <div className="flex--3 input--3">
              <label>Quote By</label>
              <select
                name="quoteBy"
                onChange={handleChanger}
                value={dealPosting.quoteBy}
                className={`${error.quoteBy && "error-input-z"}`}
              >
                <option value="">Select an option</option>
                <option value="Units">Units</option>
                <option value="Nominal">Nominal</option>
              </select>
              {error.quoteBy && <ErrorMessage message={error.quoteBy} />}
            </div>
          </div>
          <div className="deal-post label--input">
            <div className="flex--3 input--1">
              <label>Broker ID</label>
              <input
                name="brokerId"
                onChange={handleChanger}
                value={dealPosting.brokerId}
                className={`${error.brokerId && "error-input-z"}`}
              />
              {error.brokerId && <ErrorMessage message={error.brokerId} />}
            </div>
          </div>

          <div className="button-last">
            <button onClick={handleSave}>Save</button>
          </div>
        </section>

      </Paper>
    </main>
    {!loader && (
      <Table
        title="Deal Posting"
        subtitle=""
        name="List of Deals"
        records={data}
        options={options}
        columns={columns}
      />
    )}
    {details && details.id && <Details details={details} />}
    {details && details.id && (
      <EditForm
        handleEditSubmit={handleEditSubmit}
        details={details}
        handleEditChange={handleEditChange}
        errorEdit={{}}
      />
    )}
</div>
  );
};

export default DealPosting;

import axios from 'axios';
import { Routes } from '../../configUrl';

const getAllUsers = async (token,appRole,start,end) => {
  return await axios({
      method: "get",
      url: `${Routes.getAllUsers}?appRole=${appRole}&offset=${start}&limit=${end}`,
      headers: {
        "Content-Type":  "application/json",
        'Authorization': `Bearer: ${token}`, 
      },
    })
    .then((res) => {
      return {
        data: res.data.data
      };
    })
    .catch((err) => {
        console.log(err)
      return {
        data:[],
      };
    });
};

const fetchAllUnauthorizedUsers = async (token,start,end) => {
  return await axios({
      method: "get",
      url: `${Routes.getAllUnauthorizedUser}?offset=${start}&limit=${end}`,
      headers: {
        "Content-Type":  "application/json",
        'Authorization': `Bearer: ${token}`, 
      },
    })
    .then((res) => {
      return {
        data: res.data.data
      };
    })
    .catch((err) => {
        console.log(err)
      return {
        data:[],
      };
    });
};


  const createUser = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createUser,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status,
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status,
          data:[],
        };
      });
  };

  const updateUser = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateUserAccess + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        console.log('-----res',res)
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: []
        };
      })
      .catch((err) => {
        console.log('----------err',err.response)
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const editUserProfile = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.editProfile + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const deleteUser = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteUser + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeACreatedUser = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeCreatedUser + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const activateACreatedUser = async (token,body) => {
    return await axios({
        method: "post", //should be changed to put
        data: {
          userId:body.id,
          action:body.action,
        },
        url: Routes.activateAndDectivateUser,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };


  const getHandler = async (token, url) => {
    return await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };


  
export {
    getAllUsers,
    createUser,
    updateUser,
    deleteUser,
    authorizeACreatedUser,
    fetchAllUnauthorizedUsers,
    activateACreatedUser,
    getHandler,
    
}
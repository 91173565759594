import axios from "axios";
import { Routes } from "../../configUrl";

const pasportphotoupdate = async (token, customerId, passport, signature) => {
  return await axios({
    method: "post",
    data: {
      customerId,
      passport,
      signature,
    },
    url: Routes.updatePassportPhoto,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data: res.data,
      };
    })
    .catch((err) => {
      return {
        statusCode: err.response.data.statusCode,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const downloadUploadedPhoto = async (token, url) => {
  return await axios({
    method: "get",
    url: Routes.downloadPassportPhoto + encodeURIComponent(url),
    headers: {
      "Content-Type":  "application/json",
      'Authorization': `Bearer: ${token}`,
    },
  })
  .then((res) => {
    return {
      status: res.data.status,
      data: res.data.data,
    };
  })
  .catch((err) => {
    if (err.response && err.response.data){
      return {
        status: err.response.data.status,
          data: [],
        };
    }
    return {
      status: 'error',
        data: [],
      }
    
  })
}

export { pasportphotoupdate,  downloadUploadedPhoto};

import { useState, useEffect } from "react";
import "./PortfolioMaintenance.scss";
import MenuItem from '@material-ui/core/MenuItem';
import useBusinessLogic from './useBusinessLogic'
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from  '../../../../Api/configUrl';
import axios from 'axios' 
import Confirm from '../../../../Confirmbox/Confirm'
import Spinner from '../../../Backdrop/index'
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";


const PortfolioMaintenance = () => {
  const dispatch = useDispatch();
  const { getAllPortfolioMaintainance, data } = useBusinessLogic()
  const [details, setDetails] = useState({});
  const loader = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [screenChange, setScreenChange] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false)
  const [authorize, setAuthorize] = useState({
    reason: '',
    isApproved: true,
    authorizationStatus: 0
})

const columns = [
  {
    name: "sn",
    label: "S/N",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "portfolioCode",
    label: "Portfolio Code",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "portfolioType",
    label: "Portfolio Type",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "customerName",
    label: "Customer Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "customerBranch",
    label: "Customer Branch",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "statementFrequency",
    label: "Statement Frequency",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "currency",
    label: "Currency",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "authorizationStatus",
    label: "Status",
    options: {
      customBodyRender: (status) => (
        <p
          style={{
            color:
              status === "Authorized"
                ? "green"
                : status === "Rejected"
                ? "red"
                : "rgb(224, 148, 33)",
          }}
        >
          {status}
        </p>
      ),
    },
  },
  {
    name: "id",
    label: "Action",
    options: {
      customBodyRenderLite: (dataIndex, rowIndex) => (
        <DotMenu>
          <MenuItem
            onClick={() => {
              const singleItem = data[dataIndex];
              setDetails(singleItem);
              dispatch(openUpdateFormModal());
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              const singleItem = data[dataIndex];
              setDetails(singleItem);
              dispatch(openViewModal());
            }}
          >
            View
          </MenuItem>
        </DotMenu>
      ),
    },
  },
];

const options = {
  filterType: "checkbox",
  selectableRows: "none",
};
const [setErrorEdit] = useState()
const handleEditSubmit = () => {
  setErrorEdit({});
  dispatch(openResquestDecisionModal());
};
const handleEditChange = (name) => (e) => {
  const { value } = e.target;
  setDetails({
    ...details,
    [name]: value,
  });
};
const handleConfirmAuthorize = () => {
    setAuthorize(true)
  }
   useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, [token])



  const handleScreenChange = (i, previous) => {
    if(previous) {
      setScreenChange(i);
      return;
    }
    let checkError = isValidateForm(portfolio);
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setScreenChange(i);
  };
  const handleChanger = (e) => {
    const { name } = e.target;
    if(name === 'stateOfHoldingsRequired') {
      setPortfolio({ ...portfolio, stateOfHoldingsRequired: !portfolio.stateOfHoldingsRequired });
      return;
    }
    if(name === 'corporateActionNoticeRequired') {
      setPortfolio({ ...portfolio, corporateActionNoticeRequired: !portfolio.corporateActionNoticeRequired });
      return;
    }
    if(name === 'accrueRedemptionPremium') {
      setPortfolio({ ...portfolio, accrueRedemptionPremium: !portfolio.accrueRedemptionPremium });
      return;
    }
    setPortfolio({ ...portfolio, [e.target.name]: e.target.value });
  };

  const [error, setError] = useState({});
  const [portfolio, setPortfolio] = useState({
    portfolioCode: "",
    portfolioDescritpion: "",
    portfolioType: "",
    customerAccount: "",
    customerName: "",
    customerBranch: "",
    assetsAccountBasis: "",
    portfolioStartDate: "",
    corporateActionNoticeRequired: false,
    stateOfHoldingsRequired: false,
    currency: "",
    noticeDays: "",
    noticeDaysType: "",
    statementFrequency: "",
    statementStartDate: "",
    accrueType: "",
    premiumOrDiscountMethod: "",
    accrualMethod: "",
    accrualFrequency: "",
    accrualStartDate: "",
    moneyMarketType: "",
    limitingTracking: "",
    redemptionAccrualMethod: "",
    redemptionAccrualFrequency: "",
    redemptionStartDate: "",
    accrueRedemptionPremium: false
  });

  const EmptyFields = () => {
    setPortfolio({
      portfolioCode: "",
      portfolioDescritpion: "",
      portfolioType: "",
      customerAccount: "",
      customerName: "",
      customerBranch: "",
      assetsAccountBasis: "",
      portfolioStartDate: "",
      corporateActionNoticeRequired: false,
      stateOfHoldingsRequired: false,
      currency: "",
      noticeDays: "",
      noticeDaysType: "",
      statementFrequency: "",
      statementStartDate: "",
      accrueType: "",
      premiumOrDiscountMethod: "",
      accrualMethod: "",
      accrualFrequency: "",
      accrualStartDate: "",
      moneyMarketType: "",
      limitingTracking: "",
      redemptionAccrualMethod: "",
      redemptionAccrualFrequency: "",
      redemptionStartDate: "",
      accrueRedemptionPremium: false
    })
  }


  const handleConfirm = () => {
    let checkError = isValidateForm(portfolio);
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setOpenBox(true)
  };
  
 
  
  const handlePortfoliMaintenanceCreate =  async (e) => {
    dispatch(isLoading());
    let data = {...portfolio};
    try {
      const response = await axios.post(`${baseUrl}treasury/PortfolioMaintenance/Create`, data);
      
      if(response.data.statusCode === 200){
        getAllPortfolioMaintainance();
        setScreenChange(false);
        EmptyFields()
        return true
        } else {
            return false
        }
    } catch (err) {
        if(err.message === 'Request failed with status code 400'){
            return false
        } else if(err.message === 'Failed to save record.'){
            return false
        } else {
            return false
        }
    } finally {
      dispatch(isLoading());
    }
}


  const isValidateForm = (portfolio) => {
    let errors = {};

    if (!screenChange && !portfolio.portfolioCode.trim()) {
      errors.portfolioCode = "Please enter the portfolio code";
    }
    if (!screenChange && !portfolio.portfolioDescritpion.trim()) {
      errors.portfolioDescritpion = "Field is required";
    }
    if (!screenChange && !portfolio.portfolioType.trim()) {
      errors.portfolioType = "Select an option";
    }
    if (!screenChange && !portfolio.customerAccount.trim()) {
      errors.customerAccount = "Field is required";
    }
    if (!screenChange && !portfolio.customerName.trim()) {
      errors.customerName = "Field is required";
    }
    if (!screenChange && !portfolio.customerBranch.trim()) {
      errors.customerBranch = "Field is required";
    }
    if (!screenChange && !portfolio.assetsAccountBasis.trim()) {
      errors.assetsAccountBasis = "Field is required";
    }
    if (!screenChange && !portfolio.portfolioStartDate.trim()) {
      errors.portfolioStartDate = "Select a date";
    }
    if (!screenChange && !portfolio.currency.trim()) {
      errors.currency = "Select an option";
    }
    if (!screenChange && !portfolio.noticeDays) {
      errors.noticeDays = "Field is required";
    }
    if (!screenChange && !portfolio.noticeDaysType) {
      errors.noticeDaysType = "Select an option";
    }
    if (!screenChange && !portfolio.statementFrequency) {
      errors.statementFrequency = "Select an option";
    }
    if (!screenChange && !portfolio.statementStartDate) {
      errors.statementStartDate = "Select an option";
    }
    if (screenChange && !portfolio.accrueType) {
      errors.accrueType = "Field is required";
    }
    if (screenChange && !portfolio.premiumOrDiscountMethod) {
      errors.premiumOrDiscountMethod = "Field is required";
    }
    if (screenChange && !portfolio.accrualMethod.trim()) {
      errors.accrualMethod = "Field is required";
    }
    if (screenChange && !portfolio.accrualFrequency) {
      errors.accrualFrequency = "Field is required";
    }
    if (screenChange && !portfolio.accrualStartDate) {
      errors.accrualStartDate = "Field is required";
    }
    if (screenChange && !portfolio.moneyMarketType) {
      errors.moneyMarketType = "Field is required";
    }
    if (screenChange && !portfolio.limitingTracking) {
      errors.limitingTracking = "Field is required";
    }
    if (screenChange && !portfolio.redemptionAccrualMethod === '') {
      errors.redemptionAccrualMethod = "Field is required";
    }
    if (screenChange && !portfolio.redemptionStartDate) {
      errors.redemptionStartDate = "Field is required";
    }
    if (screenChange && !portfolio.redemptionAccrualFrequency) {
      errors.accrualFrequency2 = "Field is required";
    }
    return errors;
  };

    useEffect(() => {
    getAllPortfolioMaintainance()  
}, [getAllPortfolioMaintainance,updateTable])

  return (
    <div>
    <main className="portfolio--maintenance">
      <div style={{ position: 'absolute'}}>
          <Spinner loading={loader}/>
      </div> 
      <Confirm 
        ApiCall={handlePortfoliMaintenanceCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
      />
      <div className="heading">
        <p>Portfolio Maintenance</p>
      </div>
      <section className="section--1">
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className="flex--3">
              <button
                className={`${!screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(false)}
              >
                1
              </button>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                2
              </button>
            </div>
          </div>
        </div>
        {!screenChange && (
          <>
            <main className="sub-main">
              <section className="sub-main--1 flex--2">
              <div className='flex--4'>
                <div className="flex--3 flex--input">
                  <p>Portfolio Code</p>
                  <input 
                    type="number"
                    onChange={handleChanger}
                    name="portfolioCode"
                    value={portfolio.portfolioCode}
                    className={`${
                      error.portfolioCode && "error-input-z"
                    }`} 
                  />
                  {error.portfolioCode && (
                      <ErrorMessage message={error.portfolioCode} />
                    )}
                </div>
                <div className="flex--3 flex--inputind">
                  <p>Portfolio Description</p>
                  <input
                  type="text"
                  onChange={handleChanger}
                  name="portfolioDescritpion"
                  value={portfolio.portfolioDescritpion}
                  className={`${
                    error.portfolioDescritpion && "error-input-z"
                  }`}
                  />
                  {error.portfolioDescritpion && (
                      <ErrorMessage message={error.portfolioDescritpion} />
                    )}
                </div>
                </div>
                <div className='flex--4'>
                <div className="flex--3 flex--input">
                  <p>Portfolio Type</p>
                  <select
                  type="text"
                  onChange={handleChanger}
                  name="portfolioType"
                  value={portfolio.portfolioType}
                  className={`${
                    error.portfolioType && "error-input-z"
                  }`}>
                    <option value="">Select An Option</option>
                    <option value="bank">Bank</option>
                    <option value="customer">Customer</option>
                  </select>
                  {error.portfolioType && (
                      <ErrorMessage message={error.portfolioType} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Customer Account</p>
                  <input 
                  
                  type="number"
                  onChange={handleChanger}
                  name="customerAccount"
                  value={portfolio.customerAccount}
                  className={`${
                    error.customerAccount && "error-input-z"
                  }`}
                  />
                  {error.customerAccount && (
                      <ErrorMessage message={error.customerAccount} />
                    )}
                </div>
                <div className="flex--3 flex--inputindv2">
                  <p>Customer name</p>
                  <input 
                  
                  type="text"
                  onChange={handleChanger}
                  name="customerName"
                  value={portfolio.customerName}
                  className={`${
                    error.customerName && "error-input-z"
                  }`}
                  />
                  {error.customerName && (
                      <ErrorMessage message={error.customerName} />
                    )}
                </div>
                </div>
                <div className='flex--4'></div>
                <div className="flex--3 flex--input">
                  <p>Customer Branch</p>
                  <input 
                     
                    type="text"
                    onChange={handleChanger}
                    name="customerBranch"
                    value={portfolio.customerBranch}
                    className={`${
                      error.customerBranch && "error-input-z"
                    }`}
                  />
                  {error.customerBranch && (
                      <ErrorMessage message={error.customerBranch} />
                    )}
                </div>
                <div className="flex--3 flex--inputind3">
                  <p>Assets Acct. Basis</p>
                  <input
                  
                  type="text"
                  onChange={handleChanger}
                  name="assetsAccountBasis"
                  value={portfolio.assetsAccountBasis}
                  className={`${
                    error.assetsAccountBasis && "error-input-z"
                  }`}
                  />
                  {error.assetsAccountBasis && (
                      <ErrorMessage message={error.assetsAccountBasis} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Portfolio Start Date</p>
                  <input 
                  type="date"
                  onChange={handleChanger}
                  name="portfolioStartDate"
                  value={portfolio.portfolioStartDate}
                  className={`${
                    error.portfolioStartDate && "error-input-z"
                  }`} 
                  />
                  {error.portfolioStartDate && (
                      <ErrorMessage message={error.portfolioStartDate} />
                    )}
                </div>
                <div className="flex--3 flex--currency">
                  <p>Currency</p>
                  <select
                  
                  type="text"
                  onChange={handleChanger}
                  name="currency"
                  value={portfolio.currency}
                  className={`${
                    error.currency && "error-input-z"
                  }`}
                  >
                    <option></option>
                    <option>NGN</option>
                  </select>
                  {error.currency && (
                      <ErrorMessage message={error.currency} />
                    )}
                </div>
              </section>
              <section className="sub-main--2 flex--2">
                <div className="flex--3 action">
                  <div className="corporate">
                    <p>Corporate Action Notice Required</p>
                  </div>
                  <div className="checboxdiv flex--2">
                    <div>
                      <input 
                        type="checkbox"
                        name='corporateActionNoticeRequired'
                        onChange={handleChanger}
                        checked={portfolio.corporateActionNoticeRequired}
                       />
                    </div>
                    <label className="span">
                      Corporate Action Notice Required
                    </label>
                  </div>
                </div>
              </section>
              <section className="sub-main--3 flex--2">
                <div className="flex--3 flex--inputind3">
                  <p>Notice Days</p>
                  <input 
                  
                  type="number"
                  onChange={handleChanger}
                  name="noticeDays"
                  value={portfolio.noticeDays}
                  className={`${
                    error.noticeDays && "error-input-z"
                  }`}
                  />
                  {error.noticeDays && (
                      <ErrorMessage message={error.noticeDays} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Notice Days Type</p>
                  <select
          
                  type="number"
                  onChange={handleChanger}
                  name="noticeDaysType"
                  value={portfolio.noticeDaysType}
                  className={`${
                    error.noticeDaysType && "error-input-z"
                  }`}
                  >
                    <option value="">Select An Option</option>
                    <option value="10">Branch Calendar</option>
                    <option value="11">Bank Calendar</option>
                  </select>
                  {error.noticeDaysType && (
                      <ErrorMessage message={error.noticeDaysType} />
                    )}
                </div>
              </section>
              <section className="main--2 flex--2">
                <div className="flex--3 action">
                  <div className="corporate">
                    <p>Statement of Holdings</p>
                  </div>
                  <div className="checboxdiv flex--2">
                    <div>
                      <input 
                        type="checkbox"
                        name='stateOfHoldingsRequired'
                        onChange={handleChanger}
                        checked={portfolio.stateOfHoldingsRequired}
                      />
                    </div>
                    <label className="span">
                      Statement of Holdings Required
                    </label>
                  </div>
                </div>
              </section>
              <section className="sub-main--4 flex--2">
                <div className="flex--3 flex--inputind3">
                  <p>Statement Frequency</p>
                  <select
                    type="number"
                    onChange={handleChanger}
                    name="statementFrequency"
                    value={portfolio.statementFrequency}
                    className={`${
                      error.statementFrequency && "error-input-z"
                    }`}
                  >
                    <option value=''>Select An Option</option>
                    <option value='Daily'>Daily</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Quartely'>Quarterly</option>
                    <option value='Half_Yearly'>Half_Yearly</option>
                    <option value='Yearly'>Yearly</option>
                  </select>
                  {error.statementFrequency && (
                      <ErrorMessage message={error.statementFrequency} />
                    )}
                </div>
                <div className="flex--3 flex--inputind3">
                  <p>Statement Date</p>
                  <input 
                   
                  type="date"
                  onChange={handleChanger}
                  name="statementStartDate"
                  value={portfolio.statementStartDate}
                  className={`${
                    error.statementStartDate && "error-input-z"
                  }`}
                  />
                  {error.statementStartDate && (
                      <ErrorMessage message={error.statementStartDate} />
                    )}
                </div>
              </section>
              <div className="savediv flex--2">
                {/* Hmmmmmmm....What inspired this line */}
                <button onClick={() => handleScreenChange(true)} className="save">Next</button>
              </div>
              
            </main>
          </>
        )}
        {screenChange && (
          <>
            <section className="section2">
              <div className="flex--3 action">
                <div className="corporate">
                  <p>Premium or Discount</p>
                </div>
              </div>
              <section className="sub--section2 flex--2 ">
                <div className="flex--3 flex--input">
                  <p>Accrue Type</p>
                  <select
                    type="text"
                    onChange={handleChanger}
                    name="accrueType"
                    value={portfolio.accrueType}
                    className={`${
                      error.accrueType && "error-input-z"
                    }`}
                  >
                    <option value="">Select An Option</option>
                    <option value="Premium">Premium</option>
                    <option value="Discount">Discount</option>
                  </select>
                  {error.accrueType && (
                      <ErrorMessage message={error.accrueType} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Premium/Discount Method</p>
                  <select
                     
                    type="text"
                    onChange={handleChanger}
                    name="premiumOrDiscountMethod"
                    value={portfolio.premiumOrDiscountMethod}
                    className={`${
                      error.premiumOrDiscountMethod && "error-input-z"
                    }`}
                  >
                    <option value="">Select An Option</option>
                    <option value="ConstantYieldBasis">Constant Yield Basis</option>
                    <option value="Exponential">Exponential</option>
                    <option value="StraightLineBasis">Straight Line Basis</option>
                  </select>
                  {error.premiumOrDiscountMethod && (
                      <ErrorMessage message={error.premiumOrDiscountMethod} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Accrual Method</p>
                  <select
                     
                    type="text"
                    onChange={handleChanger}
                    name="accrualMethod"
                    value={portfolio.accrualMethod}
                    className={`${
                      error.accrualMethod && "error-input-z"
                    }`}
                  >
                    <option value="">Select An Option</option>
                    <option value="Actual_Actual">Actual/Actual</option>
                    <option value="Actual_360">Actual/360</option>
                    <option value="_30_Actual"> 30/Actual</option>
                  </select>
                  {error.accrualMethod && (
                      <ErrorMessage message={error.accrualMethod} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Accrual Frequency</p>
                  <select
                     
                    type="text"
                    onChange={handleChanger}
                    name="accrualFrequency"
                    value={portfolio.accrualFrequency}
                    className={`${
                      error.accrualFrequency && "error-input-z"
                    }`}
                  >
                    <option value=''>Select An Option</option>
                    <option value='Daily'>Daily</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Quarterly'>Quarterly</option>
                    <option value='HalfYearly'>Half_Yearly</option>
                    <option value='Yearly'>Yearly</option>
                  </select>
                  {error.accrualFrequency && (
                      <ErrorMessage message={error.accrualFrequency} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Accrual Start Date</p>
                  <input 
                   
                  type="date"
                  onChange={handleChanger}
                  name="accrualStartDate"
                  value={portfolio.accrualStartDate}
                  className={`${
                    error.accrualStartDate && "error-input-z"
                  }`}
                   
                   />
                   {error.accrualStartDate && (
                      <ErrorMessage message={error.accrualStartDate} />
                    )}
                </div>
              </section>
              <div className="corporate">
                <p>Limits Tracking</p>
              </div>
              <section className="sub--section2 flex--2 ">
                <div className="flex--3 flex--input">
                  <p>Money Markets Type</p>
                  <select
                    type="text"
                    onChange={handleChanger}
                    name="moneyMarketType"
                    value={portfolio.moneyMarketType}
                    className={`${
                      error.moneyMarketType && "error-input-z"
                    }`}
                  >
                    <option value=''>Select An Option</option>
                    <option value='Bonds'>Bonds</option>
                    <option value='Equities'>Equities</option>
                    <option value='TreasuryBills'>Treasury Bills</option>
                  </select>
                  {error.moneyMarketType && (
                      <ErrorMessage message={error.moneyMarketType} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Limits Tracking Basis</p>
                  <select
                     
                    type="text"
                    onChange={handleChanger}
                    name="limitingTracking"
                    value={portfolio.limitingTracking}
                    className={`${
                      error.limitingTracking && "error-input-z"
                    }`}
                  >
                    <option value=''>Select An Option</option>
                    <option value='FaceValue'>Face Value</option>
                    <option value='MarketPrice'>Market price</option>
                    <option value='BookPrice'>Book Price</option>
                  </select>
                  {error.limitingTracking && (
                      <ErrorMessage message={error.limitingTracking} />
                    )}
                </div>
              </section>
              <div className="corporate">
                <p>Redemption</p>
              </div>
              <section className="sub--section2 flex--2 ">
                <div className="checboxdiv flex--2 ">
                  <div className="sub--checboxdiv flex--2 ">
                    <div>
                      <input 
                        type="checkbox"
                        name='accrueRedemptionPremium'
                        onChange={handleChanger}
                        checked={portfolio.accrueRedemptionPremium}
                      />
                    </div>
                    <label className="span">Accrue Redemption Premium</label>
                  </div>
                </div>
                <div className="flex--3 flex--input">
                  <p>Redemption Accrual Method</p>
                  <select
                     
                    type="text"
                    onChange={handleChanger}
                    name="redemptionAccrualMethod"
                    value={portfolio.redemptionAccrualMethod}
                    className={`${
                      error.redemptionAccrualMethod && "error-input-z"
                    }`}
                  >
                    <option value="">Select An Option</option>
                    <option value="Actual_Actual">Actual/Actual</option>
                    <option value="Actual_360">Actual/360</option>
                    <option value="_30_Actual"> 30/Actual</option>
                  </select>
                  {error.redemptionAccrualMethod && (
                      <ErrorMessage message={error.redemptionAccrualMethod} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Redemption Accrual Frequency</p>
                  <select
                     
                    type="text"
                    onChange={handleChanger}
                    name="redemptionAccrualFrequency"
                    value={portfolio.redemptionAccrualFrequency}
                    className={`${
                      error.redemptionAccrualFrequency && "error-input-z"
                    }`}
                  >
                    <option value=''>Select An Option</option>
                    <option value='Daily'>Daily</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Quarterly'>Quarterly</option>
                    <option value='HalfYearly'>Half_Yearly</option>
                    <option value='Yearly'>Yearly</option>
                  </select>
                  {error.redemptionAccrualFrequency && (
                      <ErrorMessage message={error.redemptionAccrualFrequency} />
                    )}
                </div>
                <div className="flex--3 flex--input">
                  <p>Redemption Start date</p>
                  <input  
                   
                  type="date"
                  onChange={handleChanger}
                  name="redemptionStartDate"
                  value={portfolio.redemptionStartDate}
                  className={`${
                    error.redemptionStartDate && "error-input-z"
                  }`}
                  />
                  {error.redemptionStartDate && (
                      <ErrorMessage message={error.redemptionStartDate} />
                    )}
                </div>
              </section>
              <div className="decisiondiv flex--2">
                <button
                  className="decision"
                  onClick={() => handleScreenChange(false, 'previous')}
                >
                  Previous
                </button>
                <button
                  className="decision"
                  onClick={handleConfirm}
                >
                  save
                </button>
              </div>
            </section>
          </>
        )}
      </section>
   
       {openBoxAuthorize && (
        <section className="updater--box">
          <main className="update--content auth-box">
            <div className="auth-body flex--3">
              <div className="flex--3 input--1">
                <label>Reason</label>
                <input
                  value={authorize.reason}
                  name="reason"
                  onChange={(e) => handleChanger(e, "authorize")}
                />
              </div>
              <div className="flex--3">
                <label>Is Approved ?</label>
                <select
                  value={authorize.isApproved}
                  name="isApproved"
                  onChange={(e) => handleChanger(e, "authorize")}
                >
                  <option>Select an option</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
            </div>
            <div id="button--cont" className="button-sub">
              <button
                onClick={() => {
                  setOpenBoxAuthorize(false);
                  EmptyFields();
                }}
              >
                Cancel
              </button>
              <button id="button" onClick={() => handleConfirmAuthorize()}>
                Authorize
              </button>
            </div>
          </main>
        </section>
      )}
    </main>
    {!loader && (
      <Table
        title="Portfolio Maintainance"
        subtitle=""
        name="List of Portfolio's"
        records={data}
        options={options}
        columns={columns}
      />
    )}
    {details && details.id && <Details details={details} />}
    {details && details.id && (
      <EditForm
        handleEditSubmit={handleEditSubmit}
        details={details}
        handleEditChange={handleEditChange}
        errorEdit={{}}
      />
    )}
</div>
  );
};

export default PortfolioMaintenance;

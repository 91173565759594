import { DateConverter } from "../../../ConvertDate";
import ErrorMessage from "../../../ErrorMessage";
import Grid from "@material-ui/core/Grid";

import EditModal from "../../../DatagridTable/edit";
import { Routes } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  details,
  handleEditChange,
  errorEdit,
}) => {
  const data = {
    year: details.year,
    startdate: details.startdate,
    enddate: details.enddate,
    status: true,
  };
  return (
    <EditModal
      title="Update Fiscal Year"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={data}
      url={`${Routes.deleteFiscalYear}${details.id}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <label>Fiscal Year</label>
          <input
            style={{ height: 44, borderRadius: 5 }}
            //className={err === "year" ? "error--input" : null}
            className={errorEdit.year ? "error-input-z" : null}
            type="text"
            value={details.year}
            onChange={handleEditChange("year")}
          />
          {errorEdit.year && <ErrorMessage message={errorEdit.year} />}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <label>Start Date</label>
          <br />
          <input
            style={{ height: 44, borderRadius: 5 }}
            className={errorEdit.startdate ? "error-input-z" : null}
            type="date"
            value={DateConverter(details.startdate)}
            onChange={handleEditChange("startdate")}
          />
          {errorEdit.startdate && (
            <ErrorMessage message={errorEdit.startdate} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <label>End Date</label>
          <br />
          <input
            style={{ height: 44, borderRadius: 5 }}
            className={errorEdit.enddate ? "error-input-z" : null}
            type="date"
            value={DateConverter(details.enddate)}
            onChange={handleEditChange("enddate")}
          />
          {errorEdit.enddate && <ErrorMessage message={errorEdit.enddate} />}
        </Grid>
      </Grid>
    </EditModal>
  );
};

export default EditData;

import React from 'react';
import './Main.scss';
import { Switch, Route} from "react-router-dom";
import InvestmentAmendment from './InvestmentAmendment/Investmentamendment'
import InvestmentBooking from './InvestmentBooking/Investmentbooking';
import InvestmentLiquidation from './InvestmentLiquidation/InvestmentLiquidation';

 const Main = () => {
    return ( 
        <div>
          <div className='Main'>
           <Switch>
           <Route path='/dashboard/authorizeinvestmentaccountoperations/investmentamendment' component={InvestmentAmendment }/> 
           <Route path='/dashboard/authorizeinvestmentaccountoperations/investmentbooking' component={InvestmentBooking }/> 
           <Route path='/dashboard/authorizeinvestmentaccountoperations/investmentliquidation' component={InvestmentLiquidation }/> 
               </Switch> 
        </div>  
        </div>
    )
}
export default Main;
import { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes, baseUrl } from "../../../../Api/configUrl";

import NumberFormat from "react-number-format";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  checkbox: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& input": {
      height: 15,
      borderRadius: 0,
      width: 20,
      marginLeft: 0,
    },
  },
  border: {
    border: "1px solid black",
    marginBottom: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttondiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  item: {
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const EditData = ({
  details,
  handleDetailsUpdateSubmit,
  handleFieldUpdate,
  errorEdit,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);

//   const displayErr = (i, name) => {
//     return (
//       errorsOfOtherServicesOtherthanProductDetails.index === i &&
//       errorsOfOtherServicesOtherthanProductDetails.type === name
//     );
//   };
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

  useEffect(() => {
    setValue(0);
  }, [details]);

  return (
    <EditModal
      title="Loan Facility Limit"
      onClick={handleDetailsUpdateSubmit}
      question="Are you sure you want to update this Loan Facility Limit record?"
      data={details}
      url={`${baseUrl}loan/LoanLimit/Update/${details.id}`}
    >
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={12} md={6}>
          <label>Account Number</label>
          <input
            onChange={handleFieldUpdate}
            name="accountNo"
            disabled
            value={details.accountNo}
            className={`${
              Object.keys(errorEdit).includes("accountNo") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.accountNo && (
            <ErrorMessage message={errorEdit.accountNo} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Account Name</label>
          <input
            onChange={handleFieldUpdate}
            name="accountName"
            disabled
            value={details.accountName}
            className={`${
              Object.keys(errorEdit).includes("accountName") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.accountName && (
            <ErrorMessage message={errorEdit.accountName} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Account Branch</label>
          <input
            onChange={handleFieldUpdate}
            name="accountBranch"
            disabled
            value={details.accountBranch}
            className={`${
              Object.keys(errorEdit).includes("accountBranch") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.accountBranch && (
            <ErrorMessage message={errorEdit.accountBranch} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Account Currency</label>
          <input
            onChange={handleFieldUpdate}
            name="accountCurrency"
            disabled
            value={details.accountCurrency}
            className={`${
              Object.keys(errorEdit).includes("accountCurrency") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.accountCurrency && (
            <ErrorMessage message={errorEdit.accountCurrency} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Approved Loan Limit</label>
          <input
            onChange={handleFieldUpdate}
            name="approvedLoanLimit"
            value={details.approvedLoanLimit}
            className={`${
              Object.keys(errorEdit).includes("approvedLoanLimit") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.approvedLoanLimit && (
            <ErrorMessage message={errorEdit.approvedLoanLimit} />
          )}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6}>
          <label>Loan Limit Balance</label>
          <input
            onChange={handleFieldUpdate}
            name="loanLimitBalance"
            disabled
            value={details.loanLimitBalance}
            className={`${
              Object.keys(errorEdit).includes("loanLimitBalance") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.loanLimitBalance && (
            <ErrorMessage message={errorEdit.loanLimitBalance} />
          )}
        </Grid> */}
      </Grid>
    </EditModal>
  );
};

export default EditData;

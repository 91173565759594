import React, {useState} from "react";
import "./CashDeposit.scss";
import BusinessLogic from "./businessLogic";
import { makeStyles } from "@material-ui/core/styles";
import ErrorModal from "../../../../Confirmbox/myModal";

import Grid from "@material-ui/core/Grid";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  input: {
   
  },
  text: {
    margin: "auto",
    marginTop: "2rem",
    marginBottom: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  item: {
    width: "100%",
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const CashDeposit = () => {
  const classes = useStyles();
  const {
    glTransaction,
    setGlTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    denomTotalValue,
    denominationValue,
    setDenominationValue,
    screenChange,
    handleScreenChange,
    errors,
    index,
    setErrors,
    validateInput,
    handleChange,
    handleDenominationChange,
    makeTransfer,
    makeAutoTransfer,
    open,
    setOpen,
    openErrorModal,
    setOpenErrorModal,
    errorMsg,
    errorSummary,
    message,
    handleSubmit,
    allBranches,
  } = BusinessLogic();

  

  const {denominations} = glTransaction;


  console.log(glTransaction, "glTransaction");
  
  // console.log(glTransaction.denominations[0].denomCount, "denominations1000")
  // console.log(glTransaction.denominations[1].denomCount, "denominations500")
  // console.log(glTransaction.denominations[2].denomCount, "denominations200")

  // const headCells = [
  //   { id: "id", label: "S/N" },
  //   { id: "tillId", label: "Till Id" },
  //   { id: "branchId", label: "Branch" },
  //   { id: "accountNumber", label: "Till CCYI Account" },
  //   { id: "authorizationStatus", label: "Auth. Status" },
  //   { id: "action", label: "Action", disableSorting: true },
  // ];

  // const [filterFn, setFilterFn] = React.useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value === "") return items;
  //       else
  //         return items.filter((x) =>
  //           x.tillId.toLowerCase().includes(target.value)
  //         );
  //     },
  //   });
  // };

  // const {
  //   TblContainer,
  //   TblHead,
  //   TblPagination,
  //   recordsAfterPagingAndSorting,
  // } = useTable([], headCells, filterFn);

  return (
    <main className="cash-deposit--lcy">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={glTransaction.postAmount > debitAccDetails.userCrCashLimit ? makeTransfer : makeAutoTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <ErrorModal
        title="Error Summary"
        openDialog={openErrorModal}
        positiveDialog={false}
        onClose={() => setOpenErrorModal(false)}
      >
        <div>
        {errorSummary && errorSummary.notBalance && (
            <div>{errorSummary.notBalance}</div>
          )}
        </div>
      </ErrorModal>
      <section className="section--1">
        <div className="headerdiv">
          <p>Cash Deposit - Local Currency</p>
        </div>
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className="flex--3">
              <button
                className={`${!screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                1
              </button>
              <p>Transaction</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                2
              </button>
              <p>Denominations</p>
            </div>
          </div>
          {!screenChange && (
            <>
              <Grid
                container
                spacing={1}
                style={{ padding: 40 }}
                className={"product--def"}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Number</label>
                  <br />
                  <input
                    type="number"
                    className={`pink ${
                      errors.creditAccount ? "error-input-z" : null
                    }`}
                    name="creditAccount"
                    value={glTransaction.creditAccount}
                    onChange={handleChange}
                  />
                  {errors.creditAccount && (
                    <ErrorMessage message={errors.creditAccount} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Name</label>
                  <br />
                  <input
                    type="text"
                    className={`pink ${
                      errors.accountName ? "error-input-z" : null
                    }`}
                    name="accountName"
                    value={creditAccDetails.accountName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <label>Branch</label>
                  <br />
                  <input
                    disabled
                    name="creditAccountBranch"
                    value={glTransaction.creditAccountBranch}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <label>Product</label>
                  <br />
                  <input
                    disabled
                    name="product"
                    value={creditAccDetails.product}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Currency</label>
                  <br />
                  <input
                    disabled
                    name="currency"
                    value={glTransaction.currencyCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Customer BVN</label>
                  <br />
                  <input
                    type="text"
                    name="bvn"
                    disabled
                    value={creditAccDetails.bvn}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Phone Number</label>
                  <br />
                  <input
                    type="text"
                    name="phoneNumber"
                    disabled
                    value={creditAccDetails.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Email Address</label>
                  <br />
                  <input
                    type="text"
                    name="email"
                    disabled
                    value={creditAccDetails.email}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Account Number</label>
                  <br />
                  <input
                    type="number"
                    className={`pink ${
                      errors.debitAccount ? "error-input-z" : null
                    }`}
                    name="debitAccount"
                    value={glTransaction.debitAccount}
                    onChange={handleChange}
                  />
                  {errors.debitAccount && (
                    <ErrorMessage message={errors.debitAccount} />
                  )}
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <label>Amount</label>
                  <br />
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator={true}
                    inputMode="numeric"
                    name="postAmount"
                    value={glTransaction.postAmount}
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                    className={`${
                      errors.creditAccount ? "error-input-z" : null
                    }`}
                    onValueChange={({ floatValue }) =>
                      handleChange({
                        target: {
                          name: "postAmount",
                          value: floatValue,
                        },
                      })
                    }
                  />
                  {errors.postAmount && (
                    <ErrorMessage message={errors.postAmount} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Narration</label>
                  <br />
                  <input
                    type="text"
                    name="narration"
                    onChange={handleChange}
                    value={glTransaction.narration}
                  />
                </Grid>
              </Grid>
                <div className="flex--5">
                  <button onClick={() => handleScreenChange(true)}>Next</button>
                </div>

              {/* 
                <hr />
                <h4>Cash Deposit</h4>
                <div className="sub--section flex--2">
                  <div className="main-filter flex--2">
                    <input
                      className="filter"
                      placeholder="Filter"
                      onChange={handleSearch}
                    />

                    <button className="button--second">Remove Filter</button>
                  </div>
                </div> */}
              {/* <TblContainer>
                <TblHead />
                <tbody>
                {recordsAfterPagingAndSorting().map((item,i) => (
                <tr key={item.id}>
                    <th scope="row">{i+1}</th>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>
                      <div className="table--icon">
                      <DotMenu>
                        <MenuItem>Edit</MenuItem>
                        <MenuItem>Delete</MenuItem>
                        <MenuItem>View</MenuItem>
                      </DotMenu>
                      </div>
                    </td>
                  </tr>
                 ))}
                </tbody>
              </TblContainer>
              <TblPagination /> */}
            </>
          )}
          {screenChange && (
            <>
              <main className="sub-main">
                <section className="sub-main--1 flex--2">
                  <div className="flex--2">
                    <p>Amount</p>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      disabled={true}
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      value={glTransaction.postAmount}
                      onValueChange={({ floatValue }) =>
                        handleChange({
                          target: {
                            name: "postAmount",
                            value: floatValue,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="flex--2">
                    <p>Net Amount</p>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      disabled
                      value={denomTotalValue}
                      onChange={() => {}}
                    />
                  </div>
                </section>
                <section className="sub-main--2 flex--3">
                  {denominations.map((item, i) => (<div key={i}>
                    <div className="multiplier-input flex--2">
                      <input type="number"
                        value={item.denomNo}
                        disabled
                        name="denomNo"
                      />
                      <span>&times;</span>
                      <input
                        type="number"
                        className={`pink ${
                          errors.denomCount ? "error-input-z" : null
                        }`}
                        value={item.denomCount}
                        name="denomCount"
                        onChange={(e) => handleDenominationChange(e, i)}
                      />
                      <span>&#61;</span>
                      <input
                        disabled
                        name="denomTotal"
                        type="number"
                        // onValueChange= {(values) => {
                        //   setGlTransaction((glTransaction) => ({
                        //     ...glTransaction,
                        //     denomTotal: (values.value)
                        //   }))
                        // }}
                        value={item.denomTotal}
                      />
                     
                    </div>                
                  </div>))}
                  
                </section>
              </main>
              <div className="button-last button-last--1 flex--2">
                <div className="button-container flex--1 flex--5">
                  <button onClick={() => handleScreenChange(false)}>
                    Previous
                  </button>
                  <button onClick={handleSubmit}>Save</button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default CashDeposit;

import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BusinessLogic from "../components/Dashboard/LoanAccountOperations/LoanBooking/businessLogic"
import DecisionModal from "./decision";
import ResponseModal from "./Modal";

const useStyles = makeStyles((theme) => ({
  children: {
    padding: "1.5rem 0px",
  },
}));

const Modal = ({
  openBox,
  ApiCall,
  setOpenBoxState,
  message,
  actionMsg,
  errorMessage,
}) => { 
  const classes = useStyles();

  const [response, setResponse] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // useEffect(() => {
  // }, [openBox, ApiCall])

  const handleClose = () => {
    setOpenBoxState((state) => (state = false));
    setResponse((state) => (state = false));
  };

  const handleCall = async () => {
    let a = await ApiCall();
    setResponse((state) => (state = true));
    if (a === true) {
      setIsSuccess(true);

    } else if (a === false) {
      setIsSuccess(false);
    } else {
      console.log("it is not working");
    }
  };

  return (
    <div>
      {!response && <DecisionModal
        question={message ? message : "Are you sure you want to save records.... ?"}
        openDialog={openBox}
        onClick={handleCall}
        onClose={handleClose}
      />}
      <ResponseModal
        openDialog={response}
        onClose={handleClose}
        positiveDialog={isSuccess}
      >
        <div className={classes.children}>
          {isSuccess ? (
            <p>
              {actionMsg ? actionMsg : "Your records were saved successfully"}
            </p>
          ) : (
            <p>
              {errorMessage ? errorMessage : "Unsuccessful, An error occurred"}
            </p>
          )}
        </div>
      </ResponseModal>
    </div>
  );
};

export default Modal;

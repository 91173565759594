import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Container from "@material-ui/core/Container";

import Card from "../Card/Card";
//import columns from "./columns";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: theme.spacing(2),
  },
  text: {
    textAlign: "center",
  },
}));

function Table({
  title,
  subtitle,
  name,
  records,
  columns,
  options,
  expandedRows,
}) {
  const classes = useStyles();

  return (
    <Zoom in={true} timeout={1000}>
      <div className={classes.root}>
        <div className={classes.container}>
          <Card title={title} subtitle={subtitle}>
            <Grid container justify="center">
              <Grid item sm={12}>
                <Container maxWidth="lg">
                  {records && records.length > 0 ? (
                    <MUIDataTable
                      title={name}
                      data={records}
                      columns={columns}
                      options={options}
                      expandedRows={expandedRows}
                    />
                  ) : (
                    <h3 className={classes.text}>{"No record found"}</h3>
                  )}
                </Container>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </Zoom>
  );
}

export default Table;

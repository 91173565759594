import { useState, useEffect, useCallback } from "react";
import "./CreateablePeriods.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../Api/Hooks-manager/coreModules/useCustom";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import Confirm from "../../../../Confirmbox/Confirm";
import { validatePeriodDef } from "../../../../Api/validator/validate11";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { baseUrl } from "../../../../Api/configUrl";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const CreateablePeriods = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [errorEdit, setErrorEdit] = useState({});
  const [years, setCreatableYear] = useState([]);

  const {
    allPeriodDefinition,
    getPeriodDefinitionAll,
    valuesPeriodDef,
    handleChange,
    loader,
    fiscalYear,
    handlePeriodDefinitionCreate,
  } = useForm();

  const [openBox, setOpenBox] = useState(false);
  const [errors, setErrors] = useState({});

  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fiscalYear",
      label: "Fiscal Year",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "periodCode",
      label: "Period Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startingdate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "endingdate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = years[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = years[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const handleEditChange = e => {
    const { value, name } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleEditSubmit = () => {
    dispatch(openResquestDecisionModal());
  };

  const fetchAllDefinedperiods = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}organization/PeriodDefinition/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].startingdate = formattedDate(
            response.data[i].startdate
          );
          response.data[i].endingdate = formattedDate(response.data[i].enddate);
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setCreatableYear(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllDefinedperiods();
  }, [fetchAllDefinedperiods, updateTable]);

  const handleConfirm = () => {
    setErrors(validatePeriodDef(valuesPeriodDef));
    if (Object.keys(validatePeriodDef(valuesPeriodDef)).length === 0) {
      setOpenBox(true);
    } else {
      console.log("there aree errors");
    }
  };



  useEffect(() => {}, [allPeriodDefinition]);
  useEffect(() => {}, [fiscalYear]);
  return (
    <div>
      <div className="createable--periods">
        <div style={{ position: "absolute" }}>
          <Spinner loading={loading || loader} />
        </div>
        <Confirm
          ApiCall={() => handlePeriodDefinitionCreate(fetchAllDefinedperiods)}
          openBox={openBox}
          setOpenBoxState={setOpenBox}
        />
        <div className="headerdiv">
          <p> Period Definition </p>
        </div>
        <Paper
          style={{
            padding: 32,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <label> Fiscal Year </label> <br />
              {/* <div className='error-container-z'> */}
              <select
                name="fiscalYear"
                onChange={e => handleChange(e, "createPeriods")}
                className={` ${
                  Object.keys(errors).includes("fiscalYear") && "error-input-z"
                }`}
              >
                <option>Please, Select a year</option>
                {fiscalYear.map((year, index) => {
                  return (
                    <option key={index} value={year.year}>
                      {year.year}
                    </option>
                  );
                })}
              </select>
              {errors.fiscalYear && (
                <ErrorMessage message={errors.fiscalYear} />
              )}
              {/* </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label> Period Code </label> <br />
              <input
                type="text"
                onChange={e => handleChange(e, "createPeriods")}
                name="periodCode"
                value={valuesPeriodDef.periodCode}
                className={` ${
                  Object.keys(errors).includes("periodCode") && "error-input-z"
                }`}
              />
              {errors.periodCode && (
                <ErrorMessage message={errors.periodCode} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label> Start Date </label> <br />
              <input
                type="date"
                onChange={e => handleChange(e, "createPeriods")}
                name="startdate"
                value={valuesPeriodDef.startdate}
                className={` ${
                  Object.keys(errors).includes("startdate") && "error-input-z"
                }`}
              />
              {errors.startdate && <ErrorMessage message={errors.startdate} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label> End Date </label> <br />
              <input
                type="date"
                onChange={e => handleChange(e, "createPeriods")}
                name="enddate"
                value={valuesPeriodDef.enddate}
                className={` ${
                  Object.keys(errors).includes("enddate") && "error-input-z"
                }`}
              />
              {errors.enddate && <ErrorMessage message={errors.enddate} />}
            </Grid>
          </Grid>
          <div className="savebtn">
            <button className="save" onClick={handleConfirm}>
              Save
            </button>
          </div>
        </Paper>
      </div>
      {!loading && (
        <Table
          title="Period Definition"
          subtitle=""
          name="List of Defined Periods"
          records={years}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorEdit}
          handleChange={handleEditChange}
          handleEditSubmit={handleEditSubmit}
          fiscalYear={fiscalYear}
        />
      )}
    </div>
  );
};
export default CreateablePeriods;

import { useState, useEffect, useCallback } from "react";
import "./CurrencyDefintion.scss";
import MenuItem from "@material-ui/core/MenuItem";
import useCustom from "../../../../Api/Hooks-manager/coreModules/useCustom";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import Confirm from "../../../../Confirmbox/Confirm";
import { validateCurrencyDef } from "../../../../Api/validator/validate11";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import { baseUrl } from "../../../../Api/configUrl";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const CurrencyDefintion = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const {
    handleChange,
    getCurrencyDefinitionAll,
    errMessage,
    loader,
    valuesCurrencyDef,
    handleCurrencyDefinitionCreate,
    allCurrencyDefinition,
  } = useCustom();
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyName",
      label: "Currency Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "numericCode",
      label: "Numeric Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllDefinedCurrency = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}organization/CurrencyDefinition/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllDefinedCurrency();
  }, [fetchAllDefinedCurrency, updateTable]);

  useEffect(() => {}, [allCurrencyDefinition]);

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, [getCurrencyDefinitionAll]);

  const [openBox, setOpenBox] = useState(false);
  const [errors, setErrors] = useState({});

  const handleConfirm = () => {
    setErrors(validateCurrencyDef(valuesCurrencyDef));
    if (Object.keys(validateCurrencyDef(valuesCurrencyDef)).length === 0) {
      setOpenBox(true);
    } else {
      console.log("there aree errors");
    }
  };

  const handleEditChange = e => {
    const { value, name } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleEditSubmit = () => {
    dispatch(openResquestDecisionModal());
  };
  return (
    <div>
      <main className="currency--definition border--main">
        <div style={{ position: "absolute" }}>
          <Spinner loading={loading || loader} />
        </div>
        <Confirm
          ApiCall={handleCurrencyDefinitionCreate}
          openBox={openBox}
          setOpenBoxState={setOpenBox}
          errorMessage={errMessage}
        />
        <section className="section--1">
          <div className="heading">
            <p>Create Currency</p>
          </div>

          <div className="sub--section grid--1">
            <div className="label--input flex--3">
              <label>Currency Name</label>
              <input
                type="text"
                onChange={e => handleChange(e, "currencyDef")}
                name="currencyName"
                value={valuesCurrencyDef.currencyName}
                className={`${
                  Object.keys(errors).includes("currencyName") &&
                  "error-input-z"
                }`}
              />
              {errors.currencyName && (
                <ErrorMessage message={errors.currencyName} />
              )}
            </div>
            <div className="label--input flex--3">
              <label>Currency Code</label>
              <input
                type="text"
                onChange={e => handleChange(e, "currencyDef")}
                name="currencyCode"
                value={valuesCurrencyDef.currencyCode}
                className={`${
                  Object.keys(errors).includes("currencyCode") &&
                  "error-input-z"
                }`}
              />
              {errors.currencyCode && (
                <ErrorMessage message={errors.currencyCode} />
              )}
            </div>
            <div className="label--input flex--3">
              <label>Numeric Code</label>
              <input
                type="number"
                onChange={e => handleChange(e, "currencyDef")}
                name="numericCode"
                value={valuesCurrencyDef.numericCode}
                className={`${
                  Object.keys(errors).includes("numericCode") && "error-input-z"
                }`}
              />
              {errors.numericCode && (
                <ErrorMessage message={errors.numericCode} />
              )}
            </div>
          </div>
          <div className="button">
            <button onClick={handleConfirm}>Save</button>
          </div>
        </section>
      </main>
      {!loading && (
        <Table
          title="Currency Definition"
          subtitle=""
          name="List of Currencies"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleEditSubmit}
          details={details}
          handleChange={handleEditChange}
          allCurrencyDefinition={allCurrencyDefinition}
        />
      )}
    </div>
  );
};

export default CurrencyDefintion;

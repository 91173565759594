import React from "react";
import "./Main.scss";
import { Switch, Route } from "react-router-dom";
import LoanDisbursement from "./LoanDisbursement/LoanDisbursement";
import LoanBooking from "../AuthorizeLoanAccountOperations/LoanBooking/Loanbooking";
import LoanRepayment from "./LoanRepayment/Loanrepayment";
import LoanAmendment from "./LoanAmendment/Loanamendment";
import LoanFacilityLimit from "./LoanFacilityLimit/LoanFacilityLimit";


const Main = () => {
  return (
    <div>
      <div className="Main">
        <Switch>
          <Route
            path="/dashboard/authorizeloanaccountoperations/loandisbursement"
            component={LoanDisbursement}
          />
          <Route
            path="/dashboard/authorizeloanaccountoperations/loanbooking"
            component={LoanBooking}
          />
          <Route            
           path="/dashboard/authorizeloanaccountoperations/loanrepayment"
            component={LoanRepayment}
          />
          <Route            
           path="/dashboard/authorizeloanaccountoperations/loanramendment"
            component={LoanAmendment}
          />
          <Route            
           path="/dashboard/authorizeloanaccountoperations/loanfacilitylimit"
            component={LoanFacilityLimit}
          />
        </Switch>
      </div>
    </div>
  );
};
export default Main;

import Item from "../../DatagridTable/item";
import { Grid } from "@material-ui/core";
import { formattedDate } from "../../../formatter/date";

const LienAccountDetails= ({details}) => {

    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Number" name={details.accountNumber} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Lien Code" name={details.lienCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Lien Amount" name={details.amount} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Start Date" name={details.startDate} />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="End Date" name={details.endDate} />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Description" name={details.description} />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Status" name={details.status ? "True" : "False"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" 
          name={details.dateAuthorized}
          />
        </Grid>
        {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Reason" name={details.reason} />
        </Grid>}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label=" Authorization Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
        </Grid>
      </Grid>

    );
};

export default  LienAccountDetails;

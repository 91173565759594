import React from "react";
import "./BankClearingCodes.scss";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const BankClearingCodes = () => {
  return (
    <div className="banking--codes">
      <div className="headerdiv">
        <p>Banking Clearing Codes</p>
      </div> 
      <Paper style={{ padding: 32 }}>
        <main className='container--flex'>
          <section className='flex--1 section--1'>
            <div className='flex--3'>
              <label>Banking Codes</label>
              <input  />
            </div> 
            <div className='flex--3'>
              <label>Bank Name</label>
              <input/>
            </div>
            <div className='flex--3'>
              <label>Suspense Account</label>
              <input  />
            </div>
            <div className='flex--3 flat-days'>
              <label>Flat Days</label>
              <input  />
            </div>
            <div className='flex--3'>
              <label>Settlement Type</label>
              <select>
                <option>Automatic</option>
                <option>Manual</option>
              </select>
            </div>
          </section>
          <section className='section--2'>
            <div className="clearingbox">
              <div className="clearingbox1 flex--1">
                <div className='flex--3'>
                  <label>Clearing Branch codes</label>
                  <input />
                </div>
                <div className='flex--3'>
                  <label>Clearing Branch Name</label>
                  <input />
                </div>
                <div className='flex--3'>
                  <label>Clearing location Codes</label>
                  <input />
                </div>
                <div className='flex--3'>
                  <label>Location Name</label>
                  <input />
                </div>
                <div className='flex--3 button--down'>
                  {/* <label style={{ color: "red" }}>Delete</label> */}
                  {/* Pls check the css i applied in the button-down to achieved */}
                  <button className="delete">Delete</button>
                </div>
              </div>
              <div className='button-flex flex--3'>
                <button className="add">+</button>
                <label>Add More</label>
              </div>
            </div>
          </section>
        </main>
        <div className='flex--1 last-section'>
          <button className="save">Save</button>  
        </div>
      </Paper>
    </div>
  );
};
export default BankClearingCodes;

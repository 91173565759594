import './Main.scss'
import { Switch, Route } from 'react-router-dom'
import DealPosting from './DealPosting/DealPosting'
import PortfolioMaintenance from './PortfolioMaintenance/PortfolioMaintenance'
import ProductDefinition from './ProductDefinition/ProductDefinition'
import Report from './Report/Report'

const Main = () => {
  return (
    <div>
      <div className='Main'>
        <Switch>
          <Route
            path='/dashboard/treasury/Productdefinition'
            component={ProductDefinition}
          />
          <Route
            path='/dashboard/treasury/Portfoliomaintenance'
            component={PortfolioMaintenance}
          />
          <Route
            path='/dashboard/treasury/Dealposting'
            component={DealPosting}
          />
          <Route path='/dashboard/treasury/report' component={Report} />
        </Switch>
      </div>
    </div>
  )
}

export default Main

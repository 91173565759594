import React from 'react'
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import BulkUploadTransaction from './BulkUploadAuthorization/BulkUploadAuthorization';
import TransactionAuthorization from './TransactionAuthorization/RemoteAuthorization';

const Main = () => {
    return (
        <div className="main">
            <Switch>
                <Route path='/dashboard/remoteauthorization/bulkuploadauthorization' component={BulkUploadTransaction}/>
                <Route path='/dashboard/remoteauthorization/transactionauthorization' component={TransactionAuthorization}/>
            </Switch>
        </div>
    )
}

export default Main

import { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Table from "../../../DatagridTable/table";
import Details from "../../../DatagridTable/details";
import DetailsContainer from "../../../DatagridTable/details";

import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import Item from "../../../DatagridTable/item";
import { numberFormatter } from "../../../../formatter";
// import CurrencyFormat from 'react-currency-format';
import { getCustomerByAccountNumber } from "../../../../Api/Hooks-manager/loanAccountOperations";
import { getGLAccount } from "../../../../Api/Hooks-manager/Transactions";

import {
  getPendingBranchTransactions,
  authorizeRemoteTransaction,
} from "../../../../Api/Hooks-manager/authorizeTransaction/authorizeTransaction";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
  openViewModal,
} from "../../../../Api/redux-manager/actions";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import MenuItem from '@material-ui/core/MenuItem';
import { Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import TransactionDetails from "../../../Details/TransactionAuthorization";

const RemoteAuthorization = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [allPendingTransaction, setAllPendingTransaction] = useState([]);

  const [accountInfo, setAccountInfo] = useState({
    creditAccount: "",
    debitAccount: "",
    creditAccountName: "",
    debitAccountName: "",
  });

  // console.log(details);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "debitAccount",
      label: "Debit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditAccount",
      label: "Credit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "postAmount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "referenceNumber",
      label: "Reference",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "transactionDate",
      label: "Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "id",
    //   label: "Action",
    //   options: {
    //     customBodyRenderLite: (dataIndex, rowIndex) => (
    //       <DotMenu>
    //         <MenuItem
    //           onClick={() => {
    //             const singleItem = data[dataIndex];
    //             setDetails(singleItem);
    //             dispatch(openDetailsModal());

    //           }}
    //         >
    //           View
    //         </MenuItem>
    //       </DotMenu>
    //     ),
    //   },
    // },
    // {
    //   name: "authorizationStatus",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (status) => (
    //       <p
    //         style={{
    //           color:
    //             status === "Authorized"
    //               ? "green"
    //               : status === "Rejected"
    //               ? "red"
    //               : "rgb(224, 148, 33)",
    //         }}
    //       >
    //         {status}
    //       </p>
    //     ),
    //   },
    // },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };

  // console.log(details);

  // function compare( a, b ) {
  //   if ( a.last_nom < b.last_nom ){
  //     return -1;
  //   }
  //   if ( a.last_nom > b.last_nom ){
  //     return 1;
  //   }
  //   return 0;
  // };

  const fetchAllTransaction = useCallback(
    async (index) => {
      dispatch(loading());
      const allResponse = await getPendingBranchTransactions(token, data);
      dispatch(loading());
      const response = allResponse.data.filter(
        (item) => item.bulkType !== "MDMC"
      );

      console.log(response, "Non-mdmc transaction")

      if (response) {
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            response[i].sn = i + 1;
            response[i].createdDateTime = formattedDate(
              response[i].createdDateTime
            );
            response[i].dateAuthorized = formattedDate(
              response[i].dateAuthorized
            );
            response[i].updatedDateTime = formattedDate(
              response[i].updatedDateTime
            );
            response[i].transactionDate = formattedDate(
              response[i].transactionDate
            );

            // setCustomerAccountInfo({
            //   creditAccount: response[i].creditAccount,
            //   debitAccount: response[i].debitAccount,
            // });
          }
        }
        setAllPendingTransaction(allResponse.data);
        setData(response);
      }
    },
    [token, dispatch]
  );

  // console.log(customerAccountInfo.creditAccount, customerAccountInfo.debitAccount)

  useEffect(() => {
    fetchAllTransaction();
  }, [updateTable]);



    const fetchCustomerDetails = useCallback(
    async (accNum, key) => {
      dispatch(loading());
      const response = await getCustomerByAccountNumber(token, accNum);
      if (response && response.data) {
        // console.log(response.data.accountName, "mmmm")
        dispatch(loading());
        setAccountInfo((prev) => ({
          ...prev,
          [key]: response.data.accountName,
        }));
      }
    },
    [token, dispatch]
  );

  // console.log(customerAccountInfo.creditAccountName);
  // console.log(customerAccountInfo.debitAccountName);

  // const fetchGlAccountDetails = useCallback(async (glAcc, key) => {
  //   dispatch(loading());
  //   const response = await getGLAccount(token, glAcc);
  //   if (response && response.data) {
  //     dispatch(loading());
  //     if (response.status) {
  //       setGlAccountInfo((prev) => ({
  //         ...prev,
  //         [key]: response.data.accountName,
  //       }));
  //     }
  //   }
  // },[token]);

  // console.log(customerAccountInfo);

 



  // const convert =(value)=>{
  //   parseFloat(convert).toFixed(2)
  // }

  return (
    <div>
      <Spinner loading={load} />
      
      {!load && <Table
        title="Transaction Authorization"
        subtitle=""
        name="List of Transactions"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer title="Transaction Authorization - Record Details">
        {details && details.id && <TransactionDetails details={details}/>}
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize Transaction"
        question="Are you sure you want to authorize this transaction?"
        url={`${Routes.authorizeRemoteTransaction}`}
        bulkId={details.bulkId}
        referenceNumber={details.referenceNumber}
        method="post"
      />
    </div>
  );
};

export default RemoteAuthorization;

import { useState, useEffect } from "react";
import "./TmeEodProcesses.scss";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import ErrorMessage from "../../ErrorMessage";
import { getAllBranches } from "../../../Api/Hooks-manager/coreModules/coreModules";
import { getAllTmeEod } from "../../../Api/Hooks-manager/TmeEod/TmeEodprocess";
import { runTmeEod, getEodLogs } from "../../../Api/Hooks-manager/TmeEod/RunEodProcess";
import Spinner from "../../Backdrop/index";
import {format} from 'date-fns';
import { MdClear, MdCached } from "react-icons/md";
import CustomizedSnackbars from '../../../Confirmbox/Snackbar';
import { formattedDate } from "../../../formatter/date";



const TmeEodProcesses = () => {
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const workDate = useSelector((state) => state.common.branch.workdate);
  const [error, setError] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarContent, setSnackBarContent] = useState({
    status: "",
    message: ""
  });
  const [allEodProcess, setAllEodProcess] = useState([]);
  const [message, setMessage] = useState("");
  const [eodData, setEodData] = useState([]);
  console.log('workdate',format(new Date(workDate),'dd-MM-yyyy'))
  const [process, setProcess] = useState({
    branch: "",
    branchName: "",
    Process: "",
    processId: "",
    branchCode: "",
    branchDate: format(new Date(workDate),'yyyy-MM-dd'),
  });

  console.log(process)

  const [data, setData] = useState([]);
  const [startSyncing, setStartSyncing] = useState(false);

  const handleChanger = (e) => {
    setProcess({ ...process, [e.target.name]: e.target.value });
  };

  const handleProcess = () => {
    let checkError = isValidateForm(process);
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    runTmeProcess();
    setOpen(true)
    // runTmeProcess();
  };
  const isValidateForm = (process) => {
    let errors = {};

    if (!process.branch.trim()) {
      errors.branch = "Select an option";
    }
    if (!process.branchName.trim()) {
      errors.branchName = "Select an option";
    }
    if (!process.processId.trim()) {
      errors.processId = "Select an option";
    }
    return errors;
  };
  const fetchAllBranches = async () => {
    const response = await getAllBranches(token);
    if (response && response.data) {
      setAllBranches(response.data);
    }
  };
  useEffect(() => {
    fetchAllBranches();
  }, []);

  useEffect(() => {
    if (process.branch) {
      let filteredArray = allBranches.filter(
        (items) => items.code === process.branch
      );
      let names;
      filteredArray.length
        ? (names = filteredArray[0].name)
        : (names = "All branches selected");
      setProcess((process) => ({ ...process, branchName: names }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.branch]);

  const fetchAllEodProcess = async () => {
    setLoading(true);
    const response = await getAllTmeEod(token);
    if (response && response.data) {
      setLoading(false);
      setAllEodProcess(response.data);
    }
  };

  useEffect(() => {
    fetchAllEodProcess();
  }, []);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackBarOpen(false);
  };

  const items = () => allEodProcess.map((item) => item.processName);

  // const newData = () => eodData.map((item) => item);

  // const a = eodData.find((item) => item === item.id
  // )
  // console.log(a, "here we gooooooooo")


  const runTmeProcess = async () => {
    setLoading(true);
    const response = await runTmeEod(token, process);
    if (response && response.data) {
      setLoading(false);
      if (response.status) {
        setSnackBarContent({
          status: "success",
          message: "EOD run process initiated"
        })
        setSnackBarOpen(true);
        getAllEodLogs();
        // setMessage(response.message);
        // setStartSyncing(true);
      }else{
        setSnackBarContent({
          status: "error",
          message: "EOD Run process failed"
        })
        setSnackBarOpen(true);
        // setStartSyncing(false);
      }
    }
  };

  const getAllEodLogs = async () => {
    setLoading(true);
    const response = await getEodLogs(token, process);
    if(response && response.data){
      setLoading(false);
      if(response.status){
        const data = [...response.data];
        // const TimeA = data.find((item) => item === item.createdDateTime);
        // console.log(data.createdDateTime, "createdDateTime is here")
        const timer = setInterval(() => {
          setEodData((prev) => [
            ...prev,
            data.shift()
          ])
          if(data.length < 1){
            clearInterval(timer)
          }
        }, 1500);

        // setEodData(response.data);
        setMessage(response.message);
        // setStartSyncing(true);
        // clearProcessDetails({});
      }else{
        // setStartSyncing(false);
      }
    }
  }

  const handlClearMsg = () => {
    setEodData([]);
  }


  // console.log(eodData, "here is eod datttttttt")
  

//   useEffect(() => {
//     if(process.branch && process.processId)
//     runTmeProcess();
// },[process.branch, process.processId]);

// useEffect(() => {
//   if(process.branch && process.processId)
//   getAllEodLogs();
// },[]);

// const newItems = () => eodData.find((item) => item === item.id);

console.log(eodData.length, "rewerts")

const HandleResetMsg = () => {
  setEodData([]);
  getAllEodLogs();
}

useEffect(() => {
  eodData.find((item) => item === item.id);
  const indexOfLastItem = eodData.length - 1;
  const lastItemInArray = eodData[indexOfLastItem];
  
  if(lastItemInArray){
    HandleResetMsg()
    // getAllEodLogs()
  }
},[])


  const clickedItem = () =>
    allEodProcess.find((item) => item.processId === process.processId);
  const nameOfClickedProcess = clickedItem() ? clickedItem().processName : "";
  const indexOfClickedItem = clickedItem()
    ? items().indexOf(nameOfClickedProcess)
    : 0;
    const indexOfLastItem = items().length - 1;
    const lastItemInArray = items()[indexOfLastItem];




  // const processNames = (arr) => {
  //   let item = [];
  //   if (indexOfClickedItem !== -1) {
  //     for (let i = indexOfClickedItem; i < arr.length; i++) {
  //       item.push(arr[i]);
  //     }
  //   }
  //   const response = item.map((item) => item.processName);
  //   return response;
  // };

  // useEffect(() => {
  //   let index = 0;
  //   if (startSyncing) {
  //     const interval = setInterval(() => {
  //       setMessage(processNames(allEodProcess)[index]);
  //       index = (index + 1) % processNames(allEodProcess).length;
  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }
  // }, [startSyncing]);

  const clearProcessDetails = () => (
    setProcess({
      branch: "",
      branchName: "",
      Process: "",
      processId: "",
      branchCode: "",
      branchDate: format(new Date(workDate),'yyyy-MM-dd'),
    })
  )


  // useEffect(() => {
  //   if (startSyncing){
  //     const interval = setInterval(() => {
  //       setMessage(message);
  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }
  // }, [startSyncing]);

  // useEffect(() => {
  //   if (lastItemInArray && lastItemInArray === message) {
  //     setTimeout(() => {
  //       setStartSyncing(false);
  //       setMessage(`Eod has finished running`);
  //     }, 2500);
  //   }
  // }, [lastItemInArray, message]);


  return (
    <div className="TmeEodProcesses">
      <div className="heading">
        <Spinner loading={loading} />
        <CustomizedSnackbars 
          handleClose={handleCloseSnackBar} 
          message={snackBarContent.message} 
          status={snackBarContent.status} 
          open={snackBarOpen}
        />
        <p>TME EOD Processes</p>
      </div>
      <Paper style={{ padding: 32 }}>
        <section className="section--1">
          <div className="eod flex--1">
            <div className="flex--3 input--3">
              <label>Branch</label>
              <select
                name="branch"
                onChange={handleChanger}
                value={process.branch}
                className={`${error.branch && "error-input-z"}`}
                id=""
              >
                <option value="">Select an option</option>
                <option value="all">All</option>
                {allBranches.map((item) => (
                  <option value={item.code} key={item.id}>
                    {item.code}
                  </option>
                ))}
              </select>
              {error.branch && <ErrorMessage message={error.branch} />}
            </div>

            <div className="flex--3 input--2">
              <label>Branch Name</label>
              <input value={process.branchName} disabled />
              {error.Process && <ErrorMessage message={error.Process} />}
            </div>
          </div>
          <div className="eod">
            <div className="flex--3 input--2">
              <label>Process</label>
              <select
                onChange={handleChanger}
                name="processId"
                value={process.processId}
                className={`${error.processId && "error-input-z"}`}
              >
                <option value="">Select an option</option>
                <option value="All">All</option>
                {allEodProcess.map((item, i) => (
                  <option value={item.processId} key={item.id}>
                    {item.processId} - {item.processName}
                  </option>
                ))}
              </select>
              {error.processId && <ErrorMessage message={error.processId} />}
            </div>
          </div>

          <div className="eod">
            <div className="flex--3 input--2">
              <div className="textarea">
                <div className="clearMessage">
                  <MdCached size={20} onClick={HandleResetMsg} className="eClear"/>
                  <MdClear onClick={handlClearMsg} className="eClear" size={20}/>
                </div>                    
                <ul>
                  {eodData.map((item, index) => (
                    <li key={index}>
                      <h5>{format(new Date(item.createdDateTime), 'yyyy-MM-dd')}</h5> <br/>
                      {item.logMessage}
                      <hr className="horizontalLine"/>
                    </li>
                  ))}
                </ul>


                {message}
              </div>
            </div>
          </div>

          <div className="button-last">
            <button disabled={startSyncing} onClick={handleProcess}>
              Run Process
            </button>
          </div>
        </section>
      </Paper>
    </div>
  );
};

export default TmeEodProcesses;

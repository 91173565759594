import { useState, useEffect } from 'react';
import './AccountAccrualReport.scss';
import MenuItem from '@material-ui/core/MenuItem';
import { BiErrorCircle } from "react-icons/bi";
import useTable from "../../../../Tables/useTable";
import Spinner from "../../../../Backdrop/index";
import { FiEdit } from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";
import { AiTwotoneDelete } from "react-icons/ai";
import { format } from "date-fns";
import { MdFileDownload } from "react-icons/md";
import DotMenu from "../../../../../commons/dotMenu/dotMenu";


const AccountAccrualReport = () => {
    const [loader, setLoader] = useState(false);
    const [view, setView] = useState({});
    const [openView, setOpenView] = useState(false);

    const headCells = [
        { id: "id", label: "S/N" },
        { id: "accountNumber", label: "Account Number" },
        { id: "portfolioCode", label: "Portfolio Code" },
        { id: "portfolioType", label: "Portfolio Type" },
        { id: "dealReference", label: "Deal Reference" },
        { id: "tradeDate", label: "Trade Date" },
        { id: "externalReferenceNo", label: "External Reference No." },
        { id: "maturityDate", label: "Maturity Date" },
        { id: "dealQuantity", label: "Deal Quantity" },
        { id: "authorizationStatus", label: "Auth. Status" },
        { id: "action", label: "Action", disableSorting: true },
      ];
    
      const [filterFn] = useState({
        fn: (items) => {
          return items;
        },
      });
      const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
      } = useTable([], headCells, filterFn);

    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 2000);
    }, [])
    return (
        <div className='AccountAccrualReport border--main'>
            <div style={{ position: 'absolute'}}>
                <Spinner loading={loader}/>
            </div>
            <div className="headerdiv">
                <p>Reports | Account Accrual Report</p>
            </div>
        <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input type="date"></input>
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input type="date"></input>
          </div>
        </div>

        <div className="button--1 flex--1">
          <button>Search</button>
          <button>Reset</button>
        </div>
        <hr />

        <TblContainer>
          <TblHead />
          <tbody>
            {recordsAfterPagingAndSorting().map((dealPosting, i) => (
              <tr key={dealPosting.id}>
                <th scope="row">{i + 1}</th>
                <td>{dealPosting.accountNumber}</td>
                <td>{dealPosting.portfolioCode}</td>
                <td>{dealPosting.portfolioType}</td>
                <td>{dealPosting.dealReference}</td>
                <td>{format(new Date(dealPosting.tradeDate), "dd/mm/yyyy")}</td>
                <td>{dealPosting.externalReferenceNo}</td>
                <td>
                  {format(new Date(dealPosting.maturityDate), "dd/mm/yyyy")}
                </td>
                <td>{dealPosting.dealQuantity}</td>
                <td>{dealPosting.authorizationStatus}</td>
                {/* <td>
                  <div
                    className={`table--icon ${
                      (dealPosting.authorizationStatus === "Authorized" ||
                        dealPosting.authorizationStatus === "Rejected") &&
                      "disabled-delete"
                    }`}
                  >
                    <AiTwotoneDelete />
                  </div>
                </td> */}
                {/* <td>
                  <div className="adj--icon cursor--pointer">
                    <BsEyeFill
                      onClick={() => {
                        setOpenView(true);
                        setView(dealPosting);
                      }}
                    />
                  </div>
                </td> */}

                <td>
                    <DotMenu>
                      {/* <MenuItem>Edit</MenuItem>
                      <MenuItem>Delete</MenuItem> */}
                      <MenuItem  onClick={() => {
                        setOpenView(true);
                        setView(dealPosting);
                      }}>View
                      </MenuItem>
                    </DotMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </TblContainer>
        <TblPagination />

        <div className="button--2">
          <button>
            Click To Download <MdFileDownload />
          </button>
        </div>

        <div className="not--loaded flex--6">
          {/* {!loader && 
                        <>
                        <BiErrorCircle className='circle-error'/>
                        <h4>No Data Found</h4>
                        </>
                    } */}
        </div>
      </main>
        </div>
    )
}

export default AccountAccrualReport

import axios from 'axios';
import { Routes } from '../../configUrl';

const updateCustomerDataInformation = async (token,body,id) => {
    
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateCustomerInfo + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const updateCustomerDataNextOfKin = async (token,body) => {
    console.table('234567890-',body)
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateNextOfKin + body.id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const updateCustomerDataAccountSignatory = async (token,body) => {
    console.log('body id fron fun', body.id)
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateAccounSignatories + body.id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  const updateCustomerDataAccountinstruction = async (token,body) => {
    console.log('body id fron fun', body.id)
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateAccountInstructions + body.id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAllAccountOfficers = async (token,officertype,start,end) => {
    return await axios({
        method: "get",
        url: `${Routes.getAllAccountAndLoanOfficers}${officertype}?pageNumber=${start}&pageSize=${end}`,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`, 
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };

  const changeAccountFreezeStatus = async (token, accNo, state) => {
    const body = {
      accountNumber: accNo,
      freezeStatus: state
    }
    try{
      await axios.put(
        Routes.freezeAccountByAccountNumber,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer: ${token}`,
          }
        }
      )
      return {
        status: true,
        message: "Freeze Status Changed Successfully",
      }
    }
    catch(err){
      console.log(err)
      return {
        status: false,
        message: "Freeze Status Failed to change",
      }
    }
    
  }

  const getReferredList =async (token, referralCode) => {
    return await axios ({
      method: "get",
      url: `${Routes.getAllReferralListByCode}?referralCode=${referralCode}`,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer: ${token}`,
      },
    })
    .then((res) => {
      return{
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return{
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
  };

  const getTotalEarnedByReferral =async (token, referralCode) => {
    return await axios ({
      method: "get",
      url: `${Routes.getTotalEarnedByReferralID}?referralCode=${referralCode}`,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer: ${token}`,
      },
    })
    .then((res) => {
      return{
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return{
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
  };

  export {
    updateCustomerDataInformation,
    updateCustomerDataNextOfKin,
    updateCustomerDataAccountSignatory,
    updateCustomerDataAccountinstruction,
    getAllAccountOfficers,
    changeAccountFreezeStatus,
    getReferredList,
    getTotalEarnedByReferral,
  }
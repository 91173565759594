import { useState, useCallback, useEffect } from "react";
import { baseUrl } from "../../configUrl";

import axios from "axios";

const useCustom = () => {
  const [loader, setLoader] = useState(false);
  const [allAccountProduct, setAllAccountProduct] = useState([]);

  const [deleteId, setDeleteId] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [openView, setOpenView] = useState(false);
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false);
  const [updateId, setUpdateID] = useState("");
  const [openBoxUpdate, setOpenBoxUpdate] = useState(false);
  const [allInvestmentProduct, setAllInvestmentProduct] = useState([]);
  const [editCustomerRestriction, setEditCustomerRestriction] = useState([]);
  const [editDetails, setEditDetails] = useState({});
  const [showEditDetails, setShowEditDetails] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let Token = user && user.jwtToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
  }, [user]);

  const [valuesProduct, setValuesProduct] = useState({
    productCode: "",
    productName: "",
    status: false,
    accountType: "",
    currency: "",
    creditAccount: "",
    debitAccount: "",
    interestPayableAccount: "",
    interestReceivableAccount: "",
    interestIncome: "",
    interestExpense: "",
    trackDormancy: false,
    noOfDaysToActivateInactivity: "",
    noOfInactiveDormantDays: "",
    debitBalanceAllowed: false,
    applyDebitInterest: false,
    applyCreditInterest: false,
    vatAccountNo: "",
    whtAccountNo: "",
    accountReactivationType: "",
    reactivationTrigger: "",
    enforceMinBalCheck: false,
    expectedMinBalance: "",
    debitInterestRate: "",
    debitRateBasis: "",
    debitInterestPeriod: "",
    debitBalanceBasis: "",
    debitInterestCalculationPeriod: "",
    creditInterestWHTRate: "",
    debitInterestVATRate: "",
    debitAccrueInterest: "",
    creditInterestRate: "",
    creditRateBasis: "",
    creditInterestPeriod: "",
    creditBalanceBasis: "",
    creditInterestCalculationPeriod: "",
    creditAccrueInterest: "",
    balanceRequestCreditInterest: "",
    interestLiquidationPeriod: "",
    mobileApplicable: true,
    createCustomerRestriction: [
      {
        customerCategory: "",
        customerName: "",
      },
    ],
    requireMemberShipFee: false,
    gateWayCreditAccount: "",
    gateWayDebitAccount: "",
    membershipFee: 0,
    deductions: 0,
    minimumContribution: 0,
    tenureType: 0,
    tenureForContribution: 0,
    baseAllowableContribution: 0,
    isEligibleForLoan: false,
  });

  const EmptyProduct = () => {
    setValuesProduct({
      productCode: "",
      productName: "",
      status: false,
      accountType: "",
      currency: "",
      creditAccount: "",
      debitAccount: "",
      interestPayableAccount: "",
      interestReceivableAccount: "",
      interestIncome: "",
      interestExpense: "",
      trackDormancy: false,
      noOfDaysToActivateInactivity: "",
      noOfInactiveDormantDays: "",
      debitBalanceAllowed: false,
      applyDebitInterest: false,
      applyCreditInterest: false,
      vatAccountNo: "",
      whtAccountNo: "",
      accountReactivationType: "",
      reactivationTrigger: "",
      enforceMinBalCheck: false,
      expectedMinBalance: "",
      debitInterestRate: "",
      debitRateBasis: "",
      debitInterestPeriod: "",
      debitBalanceBasis: "",
      debitInterestCalculationPeriod: "",
      creditInterestWHTRate: "",
      debitInterestVATRate: "",
      debitAccrueInterest: "",
      creditInterestRate: "",
      creditRateBasis: "",
      creditInterestPeriod: "",
      creditBalanceBasis: "",
      creditInterestCalculationPeriod: "",
      creditAccrueInterest: "",
      balanceRequestCreditInterest: "",
      interestLiquidationPeriod: "",
      mobileApplicable: false,
      createCustomerRestriction: [
        {
          customerCategory: "",
          customerName: "",
        },
      ],
      requireMemberShipFee: false,
      gateWayCreditAccount: "",
      gateWayDebitAccount: "",
      membershipFee: 0,
      deductions: 0,
      minimumContribution: 0,
      tenureType: 0,
      tenureForContribution: 0,
      baseAllowableContribution: 0,
      isEligibleForLoan: false,
    });
  };

  const [valuesInvestProduct, setValuesInvestProduct] = useState({
    productName: "",
    productCode: "",
    currency: "",
    productStatus: true,
    defaultInvAmount: 0,
    minimumInvAmount: 0,
    maximumInvAmount: 0,
    defaultTenor: 0,
    maximumTenor: 0,
    minimumTenor: 0,
    tenorBasis: "",
    rateType: "",
    rateChatCode: "",
    defaultInterestRate: 0,
    maximumInterestRate: 0,
    minimumInterestRate: 0,
    rateBasis: "",
    maximumVariance: 0,
    minimumVariance: 0,
    accrualRequired: false,
    prematureLiqAllowed: false,
    automaticRollover: false,
    applyTax: false,
    penaltyOnPrematureLiq: false,
    accrualPeriod: "",
    productAccount: "",
    interestPayableAccount: "",
    balanceBasis: 0,
    penalIncome: "",
    preLiquidationPenaltyRate: 0,
    interestExpense: "",
    atMaturity: "",
    unclaimedInterestAccount: "",
    withHoldingTaxAccount: "",
    interestCalculationPeriod: "",
    unClaimedInvestmentAccount: "",
    interestPeriod: "",
    allowPrematureLiqOnlyAfter: 0,
  });

  const [authorize, setAuthorize] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const handleChange = (e, change) => {
    const { name, value } = e.target;
    if (change === "authorize") {
      setAuthorize((authorize) => ({ ...authorize, [name]: value }));
    } else if (change === "invest") {
      setValuesInvestProduct({ ...valuesInvestProduct, [name]: value });
    } else {
      setValuesProduct({ ...valuesProduct, [name]: value });
      localStorage.setItem("valuesProduct", valuesProduct);
    }
  };

  const handleaAccountProductCreate = async (callback) => {
    let data = { ...valuesProduct };

    try {
      const response = await axios.post(
        `${baseUrl}account/AccountProduct/Create`,
        data
      );
      if (response.data.statusCode === 200) {
        EmptyProduct();
        callback();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const updateAccountProduct = async () => {
    setLoader(true);
    const data = { ...valuesProduct };
    try {
      const response = await axios.put(
        `${baseUrl}account/AccountProduct/Update/${updateId}`,
        data
      );
      if (response.data.status) {
        // getPeriodDefinitionAll()
        EmptyProduct();
        let a = allAccountProduct.map((items) => {
          if (items.id === updateId) {
            items = { ...items, ...response.data.data };
          }
          return items;
        });
        setAllAccountProduct(a);
        // getAccountDefinitionAll()
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const deleteAccountProduct = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}account/AccountProduct/Delete/${deleteId}`
      );
      if (response.data.status) {
        let a = allAccountProduct.filter((items) => items.id !== deleteId);
        setAllAccountProduct(a);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleAccountProductAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}account/AccountProduct/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        let a = allAccountProduct.map((items) => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllAccountProduct(a);
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getAccountDefinitionAll = useCallback(async () => {
    // setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}account/AccountProduct/GetAll`
      );
      // const maindata = await response.json();
      if (response.data.statusCode === 200) {
        setAllAccountProduct(response.data.data);
        return true;
      }
    } catch (err) {
      return false;
    } finally {
      setLoader(false);
    }
  }, []);

  // Beginnning of Investment Product
  const EmptyInvestmentProduct = () => {
    setValuesInvestProduct({
      productName: "",
      productCode: "",
      productStatus: true,
      defaultInvAmount: 0,
      minimumInvAmount: 0,
      maximumInvAmount: 0,
      defaultTenor: 0,
      maximumTenor: 0,
      minimumTenor: 0,
      tenorBasis: "",
      rateType: "",
      rateChatCode: "",
      defaultInterestRate: 0,
      maximumInterestRate: 0,
      minimumInterestRate: 0,
      rateBasis: "",
      maximumVariance: 0,
      minimumVariance: 0,
      accrualRequired: false,
      prematureLiqAllowed: false,
      automaticRollover: false,
      applyTax: false,
      penaltyOnPrematureLiq: false,
      accrualPeriod: "",
      productAccount: "",
      interestPayableAccount: "",
      balanceBasis: 0,
      penalIncome: "",
      preLiquidationPenaltyRate: 0,
      interestExpense: "",
      atMaturity: "",
      unclaimedInterestAccount: "",
      withHoldingTaxAccount: "",
      interestCalculationPeriod: "",
      unClaimedInvestmentAccount: "",
      interestPeriod: "",
      allowPrematureLiqOnlyAfter: 0,
    });
  };

  const handleInvestmentProductCreate = async (callback) => {
    let data = { ...valuesInvestProduct };
    try {
      setLoader(true);
      const response = await axios.post(
        `${baseUrl}investment/InvestmentProduct/Create`,
        data
      );
      if (response.data.status) {
        EmptyInvestmentProduct();
        callback();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getInvestmentProductsAll = useCallback(async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}investment/InvestmentProduct/GetAll`
      );
      if (response.data.statusCode === 200) {
        setAllInvestmentProduct(response.data.data);
        return true;
      }
    } catch (err) {
      return false;
    } finally {
      setLoader(false);
    }
  }, []);

  const deleteInvestmentProduct = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}investment/InvestmentProduct/Delete/${deleteId}`
      );
      if (response.data.status) {
        let a = allInvestmentProduct.filter((items) => items.id !== deleteId);
        setAllInvestmentProduct(a);
        return true;
      }
      if (
        response.data.responseMessage.includes(
          "This Record has been authorized and possibled used"
        )
      ) {
        setErrMessage("Unsuccessful, You cannot delete an authorized record.");
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const updateInvestmentProduct = async () => {
    setLoader(true);
    let data = { ...valuesInvestProduct };
    try {
      const response = await axios.put(
        `${baseUrl}investment/InvestmentProduct/Update/${updateId}`,
        data
      );
      if (response.data.statusCode === 200) {
        // getCurrencyDefinitionAll()
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleInvestmentProductAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}investment/InvestmentProduct/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        let a = allInvestmentProduct.map((items) => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllInvestmentProduct(a);
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoader(false);
    }
  };


  return {
    EmptyProduct,
    EmptyInvestmentProduct,
    updateInvestmentProduct,
    authorize,
    allInvestmentProduct,
    getInvestmentProductsAll,
    getAccountDefinitionAll,
    handleInvestmentProductAuthorize,
    deleteInvestmentProduct,
    updateAccountProduct,
    openBoxUpdate,
    setOpenBoxUpdate,
    handleInvestmentProductCreate,
    setValuesInvestProduct,
    valuesInvestProduct,
    setUpdateID,
    handleAccountProductAuthorize,
    openBoxAuthorize,
    setOpenBoxAuthorize,
    loader,
    errMessage,
    openView,
    setOpenView,
    deleteAccountProduct,
    setDeleteId,
    allAccountProduct,
    handleChange,
    setValuesProduct,
    valuesProduct,
    handleaAccountProductCreate,
  };
};

export default useCustom;

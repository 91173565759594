import axios from "axios";
import { Routes, baseUrl2 } from "../../configUrl";

//---------------------------------------------------- loan booking------------------------------------------

const createLoanBooking = async (token, body) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.createLoanBooking,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      console.log("create loanbooking..", res);
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log("create loanbooking err..", err.response);
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const updateLoanBooking = async (token, body, id) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.updateLoanBooking + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const getAllLoanBooking = async (token) => {
  return await axios({
    method: "get",
    url: Routes.getAllLoanBooking,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      console.log("all loan bookings..", res);
      return {
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getAllUnauthorizedLoans = async (token) => {
  return await axios({
    method: "get",
    url: Routes.getUnauthorizedLoans,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      console.log("all loan bookings..", res);
      return {
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getALoanBooking = async (token, id) => {
  return await axios({
    method: "get",
    url: Routes.getALoanBooking + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        data: [],
      };
    });
};

const generateSampleSchedule = async (token, data) => {
  return await axios({
    method: "get",
    url: `${Routes.generateSampleSchedule}principal=${data.amount}&interestRate=${data.interestRate}&startdate=${data.startDate}&maturitydate=${data.maturityDate}&frequency=${data.repaymentFrequency}&basis=${data.interestRateBasis}&interestcalcmethod=${data.interestCalcMethod}&bulletpayment=${data.bulletpayment}&frequencyUnit=${data.repaymentFrequencyUnit}&interestPeriod=${data.interestPeriod}&amortization=${data.amortizationType}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
        message: res.data.responseMessage,
        status: res.data.status,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const getLoanByLoanAccountNumber = async (token, accNo) => {
  return await axios({
    method: "get",
    url: Routes.getLoanByLoanAccountNumber + accNo,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.status ? res.data.data : {},
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getLoanByCustomerAccountNumber = async (token, accNo) => {
  return await axios({
    method: "get",
    url: Routes.getLoanByCustomerAccountNumber + accNo,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.status ? res.data.data : [],
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const deleteLoanBooking = async (token, id) => {
  return await axios({
    method: "delete",
    url: Routes.deleteLoanBooking + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        statusCode: err.data.statusCode,
        message: err.data.responseMessage,
        data: [],
      };
    });
};

const authorizeLoanBooking = async (token, body, id) => {
  return await axios({
    method: "put",
    data: body,
    url: Routes.authorizeLoanBooking + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        statusCode: err.response.data.statusCode,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

//---------------------------------------------------- loan repayment------------------------------------------

const createLoanRepayment = async (token, body) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.createLoanRepayment,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const getAllLoanRepayment = async (token) => {
  return await axios({
    method: "get",
    url: Routes.getAllLoanRepayment,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getLoanRepaymentByLoanAccNum = async (token, loanAccno) => {
  return await axios({
    method: "get",
    url: Routes.getLoanRepaymentByLoanAccNum + loanAccno,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        data: [],
      };
    });
};

const getALoanRepayment = async (token, id) => {
  return await axios({
    method: "get",
    url: Routes.getALoanRepayment + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        data: [],
      };
    });
};

const authorizeLoanRepayment = async (token, body, id) => {
  return await axios({
    method: "put",
    data: body,
    url: Routes.authorizeLoanRepayment + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        statusCode: err.response.data.statusCode,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};


const updateLoanRepayment = async (token, body) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.updateRepaymentSchedule,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
  .then((res) => {
    return {
      message: res.data.responseMessage,
      status: res.data.status,
      data: res.data.data,
    }
  })
  .catch((err) => {
    return{
      message: err.response.data.responseMessage,
      status: err.response.data.status,
      data: [],
    };
  });
};

//---------------------------------------------------- loan disbursment------------------------------------------

const createLoanDisbursement = async (token, body) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.createLoanDisbursement,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const updateLoanDisbursement = async (token, body, id) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.updateLoanDisbursement + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const getAllLoanDisbursement = async (token) => {
  return await axios({
    method: "get",
    url: Routes.getAllLoanDisbursement,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getLoanDisbursementLoanAccountNum = async (token, loanAccNo) => {
  return await axios({
    method: "get",
    url: Routes.getLoanDisbursementLoanAccountNum + loanAccNo,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getALoanDisbursement = async (token, id) => {
  return await axios({
    method: "get",
    url: Routes.getALoanDisbursement + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        data: [],
      };
    });
};

const authorizeLoanDisbursement = async (token, body, id) => {
  return await axios({
    method: "put",
    data: body,
    url: Routes.authorizeLoanDisbursement + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })

    .catch((err) => {
      return {
        statusCode: err.response.data.statusCode,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

//---------------------------------------------------- Loan Facility Limit------------------------------------------

const createLoanFacilityLimit = async (token, body) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.createLoanLimit,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const updateLoanFacilityLimit = async (token, body, id) => {
  return await axios({
    method: "post",
    data: body,
    url: Routes.updateLoanLimit + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        status: res.data.status,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        status: err.response.data.status,
        data: [],
      };
    });
};

const getAllLoanFacilityLimit = async (token) => {
  return await axios({
    method: "get",
    url: Routes.getAllLoanLoanLimit,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getLoanFacilityLimitByAccountNum = async (token, accno) => {
  return await axios({
    method: "get",
    url: Routes.getLoanLimitByAccountNum + accno,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        responseCode: err.response.data.responseCode,
        data: [],
      };
    });
};

const getALoanFacilityLimit = async (token, id) => {
  return await axios({
    method: "get",
    url: Routes.getALoanLimit + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        data: [],
      };
    });
};

const authorizeLoanFacilityLimit = async (token, body, id) => {
  return await axios({
    method: "put",
    data: body,
    url: Routes.authorizeLoanLimit + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        statusCode: res.data.statusCode,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })

    .catch((err) => {
      return {
        statusCode: err.response.data.statusCode,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

//---------------------------------------------------- Customer Account------------------------------------------

const getCustomerByAccountNumber = async (token, accNum) => {
  return await axios({
    method: "get",
    url: Routes.getCustomerByAccountNumber + accNum,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

const getHandler = async (token, url) => {
  return await axios({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        data: [],
      };
    });
};
//---------------------------------------------------- lien ------------------------------------------



export {
  generateSampleSchedule,
  createLoanBooking,
  getAllLoanBooking,
  authorizeLoanBooking,
  updateLoanBooking,
  deleteLoanBooking,
  getALoanBooking,
  getLoanByLoanAccountNumber,
  getLoanByCustomerAccountNumber,
  createLoanRepayment,
  getAllLoanRepayment,
  getLoanRepaymentByLoanAccNum,
  getALoanRepayment,
  updateLoanRepayment,
  authorizeLoanRepayment,
  createLoanDisbursement,
  getAllLoanDisbursement,
  getLoanDisbursementLoanAccountNum,
  authorizeLoanDisbursement,
  updateLoanDisbursement,
  getALoanDisbursement,
  getCustomerByAccountNumber,
  createLoanFacilityLimit,
  updateLoanFacilityLimit,
  getAllLoanFacilityLimit,
  getLoanFacilityLimitByAccountNum,
  getALoanFacilityLimit,
  authorizeLoanFacilityLimit,
  getHandler,
  getAllUnauthorizedLoans,
};

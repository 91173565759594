import { useState } from "react";
import "./AccountOpening.scss";
import CustomerBio from "../../../../commons/AccountPages/CustomerBio/Customerbio";
import CustomerInfo from "../../../../commons/AccountPages/CustomerInfo/Customerinfo";
import AccountInfo from "../../../../commons/AccountPages/AccountInfo/Accountinfo";
import AccountSignatories from "../../../../commons/AccountPages/AccountSignatories/Accountsignatories";
import Accountinstructions from "../../../../commons/AccountPages/AccountInstruction/Accountinstructions";
import NextOfKin from "../../../../commons/AccountPages/NextOfKin/NextOfKin";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop/index";
import {
  validateAccountOpening1,
  validateAccountOpening2,
  validateAccountOpening3,
  validateAccountOpening5,
  validateAccountOpening4,
} from "../../../../Api/validator/validate11";
import useCustom from "../../../../Api/Hooks-manager/accountOpening/useCustom";

const AccountOpening = () => {
  const {
    accountNumbDisplay,
    handleaAccountOpeningCreate,
    screenChange,
    setScreenChange,
    loader,
    updateCountry,
    data,
    handleChange,
    handleSearchableChangeSelect,
    message,
    addSignatory,
    handleAccountSignatories,
    removeSignatory,
    states,
  } = useCustom();
  const [openBox, setOpenBox] = useState(false);
  const [error1, setError1] = useState({});
  const [error2, setError2] = useState({});
  const [error3, setError3] = useState({});
  const [error4, setError4] = useState({});
  const [error5, setError5] = useState({});



  const handleConfirm = () => {
    setOpenBox(true);
  };

  console.log(data, "iiii");


  const handleScreenChange = (i, type) => {
    if (type === "page1") {
      setError1(validateAccountOpening1(data));
      if (Object.keys(validateAccountOpening1(data)).length > 0) return;
      setScreenChange(i);
    } else if (type === "page2") {
      setError2(validateAccountOpening2(data));
      if (Object.keys(validateAccountOpening2(data)).length > 0) return;
      setScreenChange(i);
    } else if (type === "page3") {
      setError3(validateAccountOpening3(data));
      if (Object.keys(validateAccountOpening3(data)).length > 0) return;
      setScreenChange(i);
    } else if (type === "page4") {
      setError4(validateAccountOpening4(data));
      if (Object.keys(validateAccountOpening4(data)).length > 0) return;
      setScreenChange(i);
    } else if (type === "page5") {
      // setError5(validateAccountOpening5(data))
      // if (Object.keys(validateAccountOpening5(data)).length > 0) return;
      const checkErrors = validateAccountOpening5(data);
      setError5(checkErrors);
      if (Object.keys(checkErrors).length > 0) return;
      setError5({});
      setScreenChange(i);
    } else {
      setScreenChange(i);
    }
  };

  // console.log(data, "account datassss");

  const moveToSecondScreen =
    data.customerType !== "" &&
    !(
      data.customerType === "Individual" ||
      data.customerType === "Corporate" ||
      data.customerType !== "Joint"
    );

  return (
    <main className="account--opening">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader} />
      </div>
      <Confirm
        ApiCall={handleaAccountOpeningCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
        actionMsg={`Successful, Your Account with Number - ${accountNumbDisplay} has been created`}
        errorMessage={message}
      />
      <section className="section--1">
        <div className="heading">
          <p>Account Opening</p>
        </div>
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className={`flex--3`}>
              <button
                className={`${screenChange === 1 ? "active-1" : ""}`}
                onClick={() => handleScreenChange(1)}
              >
                1
              </button>
              <p>Customer Bio</p>
            </div>
            <div className={`flex--3`}>
              <button
                className={`${screenChange === 2 ? "active-1" : ""}`}
                onClick={() => handleScreenChange(2, "page1")}
                disabled={moveToSecondScreen && data.customerType === "Joint"}
              >
                2
              </button>
              <p>Customer Info</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange === 3 ? "active-1" : ""}`}
                onClick={() => handleScreenChange(3, "page2")}
                disabled={
                  data.customerType !== "" && data.customerType !== "Individual"
                }
              >
                3
              </button>
              <p>Next of kin</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange === 4 ? "active-1" : ""}`}
                onClick={() => handleScreenChange(4, "page3")}
              >
                4
              </button>
              <p>Account Info</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange === 5 ? "active-1" : ""}`}
                onClick={() => handleScreenChange(5, "page4")}
              >
                5
              </button>
              <p>Account Signatories</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange === 6 ? "active-1" : ""}`}
                onClick={() => handleScreenChange(6, "page5")}
              >
                6
              </button>
              <p>Account Instruction</p>
            </div>
          </div>
          <div className="sub--main">
            {screenChange === 1 && (
              <>
                <CustomerBio
                  handleChange={handleChange}
                  value={data}
                  errors={error1}
                />
                <div className="button-switch flex--2">
                  <button
                    className="next"
                    onClick={() =>
                      handleScreenChange(
                        Number(`${moveToSecondScreen ? 4 : 2}`),
                        "page1"
                      )
                    }
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {screenChange === 2 && (
              <>
                <CustomerInfo
                  handleChange={(value, type) => handleChange(value, type)}
                  value={data}
                  errors={error2}
                />
                <div className="button-switch flex--2">
                  <button
                    className="previous"
                    onClick={() => handleScreenChange(1)}
                  >
                    Previous
                  </button>
                  <button
                    className="next"
                    onClick={() =>
                      handleScreenChange(
                        data.customerType === "Individual" ? 3 : 4,
                        "page2"
                      )
                    }
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {screenChange === 3 && (
              <>
                <NextOfKin
                  handleChange={(value, type) => handleChange(value, type)}
                  value={data}
                  updateCountry={(value, type) => updateCountry(value, type)}
                  errors={error3}
                />
                <div className="button-switch flex--2">
                  <button
                    className="previous"
                    onClick={() => handleScreenChange(2)}
                  >
                    Previous
                  </button>
                  <button
                    className="next"
                    onClick={() => handleScreenChange(4, "page3")}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
            {screenChange === 4 && (
              <>
                <AccountInfo
                  value={data}
                  handleSearchableChangeSelect={handleSearchableChangeSelect}
                  handleChange={(value, type, product) =>
                    handleChange(value, type, product)
                  }
                  errors={error4}
                />
                <div className="button-switch flex--2">
                  <button
                    className="previous"
                    onClick={() =>
                      handleScreenChange(
                        Number(
                          `${
                            data.customerType !== "" &&
                            data.customerType !== "Individual"
                              ? 1
                              : 3
                          }`
                        )
                      )
                    }
                  >
                    Previous
                  </button>
                  <button
                    className="next"
                    onClick={() => handleScreenChange(5, "page4")}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
            {screenChange === 5 && (
              <>
                <AccountSignatories
                  handleChange={handleAccountSignatories}
                  states={states}
                  value={data}
                  errors={error5}
                  addSignatory={addSignatory}
                  removeSignatory={removeSignatory}
                />
                <div className="button-switch flex--2">
                  <button
                    className="previous"
                    onClick={() => handleScreenChange(4)}
                  >
                    Previous
                  </button>
                  <button
                    className="next"
                    onClick={() => handleScreenChange(6)}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
            {screenChange === 6 && (
              <>
                <Accountinstructions handleChange={handleChange} value={data} />
                <div className="button-switch flex--2">
                  <button
                    className="previous"
                    onClick={() => handleScreenChange(5)}
                  >
                    Previous
                  </button>
                  <button className="next" onClick={handleConfirm}>
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default AccountOpening;

import React from 'react'
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/LoanAccount/LoanFacilityLimit";


const View = ({details}) => {
    return (
        <DetailsContainer
      title="Loan Limit Facility - Record Details"
      question=""
    >
      <Details details={details}/>
    </DetailsContainer>
    )
}


export default View

import { useState, useCallback, useEffect } from "react";
import "./ParExtended.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../ErrorMessage";
import {
  getParReportExtended,
  getBranchCode,
} from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { formattedDate } from "../../../../../formatter/date";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../../Api/redux-manager/actions";

const PARExtended = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const [parExtended, setParExtended] = useState({
    branchCode: "",
  });
  const [parReportExtended, setParReportExtended] = useState(null);
  const [branchesCode, setBranchesCode] = useState([]);
  const [errors, setErrors] = useState({});

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountOfficer",
      label: "Account Officer",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "disbursementDate",
      label: " Disbursement Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "econimicSector",
      label: "Economic Sector",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "linkedAccountNumber",
      label: "Linked Acc No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerAccountBalance",
      label: "Customer Acc Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAmount",
      label: "Loan Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "product",
      label: "Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "principalBalance",
      label: "Principal Balance ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "totalOutstandingPrincipal",
      label: "Total Outstanding Princ ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pastDuePrincipal",
      label: "Past Due Princ. ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pastDueInterest",
      label: "Past Due Interest. ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "unpaidLoanFees",
      label: "Unpaid Loan Fees ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanArrears",
      label: "Loan Arrears",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paidPrincipal",
      label: "Paid Principal",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dueInterest",
      label: "Due Interest",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paidInterest",
      label: "Paid Interest",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastRepaymentAmount",
      label: "Last Repayment Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "daysOverDue",
      label: "Days Overdue",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "expectedCreditLossStages",
      label: "Expected Credit Loss Stages",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lendingModel",
      label: " Lending Model",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "repaymentMode",
      label: " Repayment Mode",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pastDueDate",
      label: "Past Due Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "lastRepaymentDate",
      label: " Last Repayment Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "nuban",
      label: " Nuban",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "branchCode",
      label: "Branch Code ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "region",
      label: "Region",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "ministries_Department",
      label: "Ministries Department",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "collateral",
      label: "Collateral",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "collateralValue",
      label: "Collateral Value",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor1",
      label: "Guarantor 1",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor2",
      label: "Guarantor 2",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor1Name",
      label: "Guarantor 1 Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor2Name",
      label: "Guarantor 2 Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor1Phone",
      label: "Guarantor 1 Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor2Phone",
      label: "Guarantor 2 Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor1Address",
      label: "Guarantor 1 Adress",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "guarantor2Address",
      label: "Guarantor 2 Adress",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "percentageOfGuarantor1Exposure",
      label: "Percentage Of Guarantor1 Exposure",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "percentageOfGuarantor2Exposure",
      label: "Percentage Of Guarantor2 Exposure",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "vauleOfGuarantor1Exposure",
      label: "VauleOfGuarantor1Exposure",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "vauleOfGuarantor2Exposure",
      label: "VauleOfGuarantor2Exposure",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "taxIDNumber",
      label: "tax ID Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "restructuredLoanMaturation",
      label: "restructured Loan Maturation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "ippis",
      label: "ippis",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "isRestructured",
      label: "is Restructured",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "groupName",
      label: "Grp Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "associationName",
      label: " Association Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "associationID",
      label: "Association ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "unpaidInterest",
      label: "Unpaid Interest",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tenure",
      label: "Tenure",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "interestRate",
      label: "Interest Rate",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setParExtended({
      ...parExtended,
      [name]: value,
    });
  };
  const resetHandlers = () => {
    EmptyField();
    setParReportExtended(false);
  };

  const EmptyField = () => {
    setParExtended({
      branchCode: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleSearch = () => {
    const checkErrors = validateData();
    if (checkErrors) {
      fetchParReportExtended();
      console.log("i am livid");
    }
    console.log("i am believer");
  };

  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const {  branchCode } = parExtended;
   
    if (branchCode === "") {
      error.branchCode = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };
  const fetchBranchCode = useCallback(async () => {
    const response = await getBranchCode(token, branchesCode);
    if (response && response.data) {
      setBranchesCode(response.data);
    }
  }, [token, branchesCode]);
  useEffect(() => {
    fetchBranchCode();
  }, []);

  const fetchParReportExtended = useCallback(async () => {
    dispatch(isLoading());
    const response = await getParReportExtended(token, parExtended);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setParReportExtended(response.data);
    }
  }, [parExtended, token, dispatch]);

  return (
    <div className="PARExtended border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p>Reports | Portfolio At Risk Report (Extended)</p>
      </div>
      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Branch Code</label>
            <select
              type="text"
              value={parExtended.branchCode}
              name="branchCode"
              onChange={handleChange("branchCode")}
            >
              <option>Select a branch code</option>
              {branchesCode.map((codes, i) => (
                <option value={codes.code} key={i}>
                  {codes.code}
                </option>
              ))}
            </select>
            {errors.branchCode && <ErrorMessage message={errors.branchCode} />}
          </div>
          </div>


        <div className="button--1 flex--1">
          <button onClick={handleSearch}>Search</button>
          <button onClick={resetHandlers}>Reset</button>
        </div>
        <hr />
        {parReportExtended && (
          <div>
            {/* <Spinner loading={loading} /> */}
            <Table
              title="PAR Report (Extended)"
              subtitle=""
              name="List Of PAR Reports"
              records={parReportExtended}
              options={options}
              columns={columns}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default PARExtended;

import React from 'react'
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import InstitutionDefinition from './InstitutionDefinition/InstitutionDefinition';
import BranchDefinition from './BranchDefinition/BranchDefinition';
import BankCalendar from './BankCalendar/BankCalendar';
import BranchCalendar from './BranchCalendar/BranchCalendar';
import ChartofAccount from './ChartofAccount/ChartofAccount';
import CountDefinition from './CountDefinition/CountDefinition';
import CurrencyDefintion from './CurrencyDefintion/CurrencyDefintion';
import DenominationDefinition from './DenominationDefinition/DenominationDefinition';
import CreatablePeriods from './CreatablePeriods/CreateablePeriods';
import FiscalYear from './FiscalYear/FiscalYear';
import ParameterSettings from './ParameterSettings/ParameterSettings';
import CustomerCategory from './CustomerCategory/CustomerCategory';
import RateChart from './RateCharts/RateCharts';
import BankClearingCodes from './BankClearingCodes/BankClearingCodes';

const Main = () => { 
    return (
        <div className='main'>
            <Switch> 
                <Route exact path='/dashboard/authorizecoremodule/1' component={InstitutionDefinition}/>
                <Route exact path='/dashboard/authorizecoremodule/branchdefinition' component={BranchDefinition}/>
                <Route path='/dashboard/authorizecoremodule/bankcalendar' component={BankCalendar}/>
                <Route path='/dashboard/authorizecoremodule/branchcalendar' component={BranchCalendar}/>
                <Route path='/dashboard/authorizecoremodule/chartofaccount' component={ChartofAccount}/>
                <Route path='/dashboard/authorizecoremodule/countdefinition' component={CountDefinition}/>
                <Route path='/dashboard/authorizecoremodule/currencydefinition' component={CurrencyDefintion}/>
                <Route path='/dashboard/authorizecoremodule/denominationdefinition'component={DenominationDefinition}/>
                <Route path='/dashboard/authorizecoremodule/creatableperiods'component={CreatablePeriods}/>
                <Route path='/dashboard/authorizecoremodule/fiscalyear'component={FiscalYear}/>
                <Route path='/dashboard/authorizecoremodule/parametersettings'component={ParameterSettings}/>
                <Route path='/dashboard/authorizecoremodule/parametersettings'component={ParameterSettings}/>
                <Route path='/dashboard/authorizecoremodule/customercategory'component={CustomerCategory}/>
                <Route path='/dashboard/authorizecoremodule/rateChart'component={RateChart}/>
                <Route path='/dashboard/authorizecoremodule/bankclearingcodes'component={BankClearingCodes}/>
            </Switch>
        </div>
    )
}

export default Main

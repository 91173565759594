import { formattedDate } from "../../../../../formatter/date"
import { formatMoney } from "../../../../../formatter/formatter"

export const columns = [
  {
    name: "#",
    label: "S/N",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, meta) => {
            return (
                <p>{meta.rowIndex + 1}</p>
            );
        }
    },
  },
  {
    name: "referenceNumber",
    label: "Reference ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "transactionType",
    label: "Type",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "transactionStatus",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: value => {
        let style = {}
        if(value === 'Committed'){
          style = {color: 'green'}
        }
        else if(value === 'Aborted'){
          style = {color: 'red'}
        }
        else{
          style = {color: 'rgb(224, 148, 33)'}
        }
        return <p style={style}>{value}</p>
      }
    },
  },
  {
    name: "debitAccount",
    label: "Debit Account Number",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "creditAccount",
    label: "Credit Account Number",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "narration",
    label: "Narration",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "authorizedBy",
    label: "Authorized By",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => value ? (<p>{value}</p>) : (<p>N/A</p>)
    },
  },
  {
    name: "createdBy",
    label: "Created By",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "postAmount",
    label: "Amount",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => <p>{formatMoney(value)}</p>
    },
  },
  {
    name: "transactionDate",
    label: "Date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => <p>{formattedDate(value)}</p>
    },
  },
  // {
  //   name: "createdBy",
  //   label: "Inputter ID",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
  // {
  //   name: "authorizedBy",
  //   label: "Authorizer ID",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
]
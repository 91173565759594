import * as actionTypes from '../constants';
import { Routes } from '../../configUrl';
import axios from 'axios'

  
//---------------------------permission attached to role----------------------------

export const getPermissionsStart = () => {
    return {
        type : actionTypes.GET_ALL_PERMISSIONS_START
    };
};


export const getPermissionsSuccess = (payload) => {
    return {
        type : actionTypes.GET_ALL_PERMISSIONS_SUCCESS,
        payload
    };
};

export const getPermissionsFail = (error) => {
    return {
        type : actionTypes.GET_ALL_PERMISSIONS_FAIL,
        error : error
    };
};

export const getPermissions = (token, roleName) => {
    return dispatch => {
        dispatch(getPermissionsStart());
         axios({
            method : 'get',
            url: Routes.getPermissionByRoleName + roleName,
            headers : {
              'Authorization' : `Bearer: ${token}`
            }
          })
          .then(res => {
              dispatch(getPermissionsSuccess(res.data.data))
          })
          .catch(err => {
            dispatch(getPermissionsFail(err.response.data))
          })
    }
};

//---------------------------PERMISSIONS FROM USER OBJECT----------------------------

export const getPermissionsFromUserObj = (payload) => {
    return {
        type : actionTypes.GET_ALL_PERMISSIONS_FROM_OBJ,
        payload
    };
};

//---------------------------USER DETAILS----------------------------

export const getUserDetails = (payload) => {
    return {
        type : actionTypes.GET_USER_DETAILS,
        payload
    };
};

//---------------------------branch attached to user----------------------------

export const getBranchStart = () => {
    return {
        type : actionTypes.GET_BRANCH_START
    };
};


export const getBranchSuccess = (payload) => {
    return {
        type : actionTypes.GET_BRANCH_SUCCESS,
        payload
    };
};

export const getBranchFail = (error) => {
    return {
        type : actionTypes.GET_BRANCH_FAILED,
        error : error
    };
};

export const getBranchByCode = (token, code) => {
    return dispatch => {
        dispatch(getBranchStart());
         axios({
            method : 'get',
            url: Routes.getBranchByCode + code,
            headers : {
              'Authorization' : `Bearer: ${token}`
            }
          })
          .then(res => {
              dispatch(getBranchSuccess(res.data.data))
          })
          .catch(err => {
            dispatch(getBranchFail(err.response.data))
          })
    }
};

//---------------------------ALL COUNTRIES----------------------------

export const getCountriesStart = () => {
    return {
        type : actionTypes.GET_ALL_COUNTRIES_START
    };
};


export const getCountriesSuccess = (payload) => {
    return {
        type : actionTypes.GET_ALL_COUNTRIES_SUCCESS,
        payload
    };
};

export const getCountriesFail = (error) => {
    return {
        type : actionTypes.GET_ALL_COUNTRIES_FAIL,
        error : error
    };
};

export const getAllCountries = (token) => {
    return dispatch => {
        dispatch(getCountriesStart());
         axios({
            method : 'get',
            url: Routes.getAllCountries,
            headers : {
              'Authorization' : `Bearer: ${token}`
            }
          })
          .then(res => {
              dispatch(getCountriesSuccess(res.data.data))
          })
          .catch(err => {
            dispatch(getCountriesFail(err.response.data))
          })
    }
};

//---------------------------ALL Branches----------------------------

export const getAllBranchesStart = () => {
    return {
        type : actionTypes.GET_ALL_BRANCHES_START
    };
};


export const getAllBranchesSuccess = (payload) => {
    return {
        type : actionTypes.GET_ALL_BRANCHES_SUCCESS,
        payload
    };
};

export const getAllBranchesFail = (error) => {
    return {
        type : actionTypes.GET_ALL_BRANCHES_FAIL,
        error : error
    };
};

export const getAllBranches = (token) => {
    return dispatch => {
        dispatch(getAllBranchesStart());
         axios({
            method : 'get',
            url: Routes.getAllBranches,
            headers : {
              'Authorization' : `Bearer: ${token}`
            }
          })
          .then(res => {
              dispatch(getAllBranchesSuccess(res.data.data.map(el => el.code)))
          })
          .catch(err => {
            dispatch(getAllBranchesFail(err.response.data))
          })
    }
};

//---------------------------Branch Teller Tills----------------------------
export const getAllTellerTillStart = () => {
    return {
        type : actionTypes.GET_ALL_TELLER_TILLS_START
    };
};

export const getAllTellerTillSuccess = (payload) => {
    return {
        type : actionTypes.GET_ALL_TELLER_TILLS_SUCCESS,
        payload
    };
};

export const getAllTellerTillFail = (error) => {
    return {
        type: actionTypes.GET_ALL_TELLER_TILLS_FAIL,
        error : error
    };
};

export const getAllTellerTills = (token) => {
    return dispatch => {
        dispatch(getAllTellerTillStart());
        axios({
            method: "get",
            url: Routes.getAllTellerTill,
            headers: {
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            dispatch(getAllTellerTillSuccess(res.data.data))
        })
        .catch(err => {
            dispatch(getAllTellerTillFail(err.response.data))
        })

    }
}

//---------------------------Clear common----------------------------

export const clearCommon = () => {
    return {
        type : actionTypes.CLEAR_COMMON
    };
};






import { useSelector } from "react-redux";

const Permissions = () => {
  const permissions = useSelector((state) => state.common.permissions);
  const user = useSelector((state) => state.common.userDetails);
  const arr = (str) => permissions.includes(str);
  //------------------------------------------ security manage menu start-------------------------------------------------------------
  const securityManagement =
    user.isSuperAdmin ||
    arr("CAN_VIEW_ROLE") ||
    arr("CAN_MANAGE_TELLERTILLLINKAGE") ||
    arr("CAN_VIEW_TELLERTILLLINKAGE") ||
    arr("CAN_MANAGE_TELLERTILL") ||
    arr("CAN_VIEW_TELLERTILL") ||
    arr("CAN_MANAGE_ROLE") ||
    arr("CAN_MANAGE_STAFF");

  const AuthorizeSecurityManagement =
    user.isSuperAdmin ||
    arr("CAN_AUTHORIZE_ROLE") ||
    arr("CAN_AUTHORIZE_STAFF") ||
    arr("CAN_AUTHORIZE_TELLERTILL") ||
    arr("CAN_AUTHORIZE_TELLERTILLLINKAGE");

  //_________________________________________security sub menu______________________________________________________________________

  const manageRoles =
    user.isSuperAdmin || arr("CAN_VIEW_ROLE") || arr("CAN_MANAGE_ROLE");
  const authorizeManageRole = user.isSuperAdmin || arr("CAN_AUTHORIZE_ROLE");

  const userCreation = user.isSuperAdmin || arr("CAN_MANAGE_STAFF");
  const AuthorizeUserCreation = user.isSuperAdmin || arr("CAN_AUTHORIZE_STAFF");

  const userTellerTills =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_TELLERTILLLINKAGE") ||
    arr("CAN_VIEW_TELLERTILLLINKAGE");
  const authorizeUserTellerTills =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_TELLERTILLLINKAGE");

  const branchTellerTills =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_TELLERTILL") ||
    arr("CAN_VIEW_TELLERTILL");
  const authorizeBranchTellerTills =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_TELLERTILL");

  //-------------------------------------------------security manage menu end--------------------------------------------------------------------------------

  //-------------------------------------------------Transaction (cash fcy, lcy, misscellanous) start--------------------------------------------------------------------------------

  const miscellaneoustransactions =
    user.isSuperAdmin || arr("CAN_INITIATE_TRANSACTIONS");
  const authorizeMiscellanousTransaction =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_TRANSACTIONS");

  //-------------------------------------------------Transaction (cash fcy, lcy, misscellanous) end--------------------------------------------------------------------------------

  //------------------------------------------------EOD TME Processes start--------------------------------------------------------------------------------

  const eodTmeProcess = user.isSuperAdmin || arr("CAN_RUN_EOD_OPERATION");

  //-------------------------------------------------EOD TME Processes end--------------------------------------------------------------------------------

  //------------------------------------------------Reports start--------------------------------------------------------------------------------
  const reports =
    user.isSuperAdmin ||
    arr("CAN_REVERSE_TRANSACTIONS") ||
    arr("CAN_VIEW_TRANSACTIONS");
  //------------------------------------------------Reports end--------------------------------------------------------------------------------

  //------------------------------------------------Loan Account Operations start--------------------------------------------------------------------------------
  const loanAccountOperations =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_REPAYMENT") ||
    arr("CAN_VIEW_REPAYMENT") ||
    arr("CAN_MANAGE_LOAN_LIMITS")
    arr("CAN_VIEW_LOAN_LIMITS") ||
    arr("CAN_MANAGE_DISBURSEMENT") ||
    arr("CAN_VIEW_DISBURSEMENT") ||
    arr("CAN_MANAGE_LOAN_DETAILS") ||
    arr("CAN_VIEW_LOAN_DETAILS");

  const authorizeLoanAccountOperations =
    user.isSuperAdmin ||
    arr("CAN_AUTHORIZE_REPAYMENT") ||
    arr("CAN_AUTHORIZE_LOAN_LIMITS") ||
    arr("CAN_AUTHORIZE_DISBURSEMENT") ||
    arr("CAN_AUTHORIZE_LOAN_DETAILS");
  //_________________________________________Loan Account Operation sub menu______________________________________________________________________
  const loanBooking =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_LOAN_DETAILS") ||
    arr("CAN_VIEW_LOAN_DETAILS");
  const authorizeLoanBooking =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_LOAN_DETAILS");

  const loanDisbursement =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_DISBURSEMENT") ||
    arr("CAN_VIEW_DISBURSEMENT");
  const authorizeLoanDisbursement =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_DISBURSEMENT");

  const loanRepayment =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_REPAYMENT") ||
    arr("CAN_VIEW_REPAYMENT");
  const authorizeLoanRepayment =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_REPAYMENT");

  const loanFacilityLimit =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_LOAN_DETAILS");
  const authorizeLoanFacilityLimit =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_LOAN_DETAILS");
  //------------------------------------------------Loan Account Operations end--------------------------------------------------------------------------------

    //------------------------------------------------Products start--------------------------------------------------------------------------------
    const products =
    user.isSuperAdmin ||
    arr("CAN_VIEW_LOAN_PRODUCT") ||
    arr("CAN_MANAGE_LOAN_PRODUCT") ||
    arr("CAN_VIEW_INVESTMENT_PRODUCT") ||
    arr("CAN_MANAGE_INVESTMENT_PRODUCT") ||
    arr("CAN_VIEW_ACCOUNTPRODUCT") ||
    arr("CAN_MANAGE_ACCOUNTPRODUCT") ||
    arr("CAN_VIEW_TELLER_PRODUCT_DEFINITION");
    arr("CAN_MANAGE_TELLER_PRODUCT_DEFINITION");

  const authorizeProducts =
    user.isSuperAdmin ||
    arr("CAN_AUTHORIZE_LOAN_PRODUCT") ||
    arr("CAN_AUTHORIZE_INVESTMENT_PRODUCT") ||
    arr("CAN_AUTHORIZE_TELLER_PRODUCT_DEFINITION") ||
    arr("CAN_AUTHORIZE_ACCOUNTPRODUCT");
  //_________________________________________Products sub menu______________________________________________________________________
  const accountProduct =
    user.isSuperAdmin ||
    arr("CAN_MANAGE_ACCOUNTPRODUCT") ||
    arr("CAN_VIEW_ACCOUNTPRODUCT");
  const authorizeAccountProduct =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_ACCOUNTPRODUCT");

  const loanProduct =
    user.isSuperAdmin ||
    arr("CAN_VIEW_LOAN_PRODUCT") ||
    arr("CAN_MANAGE_LOAN_PRODUCT");
  const authorizeLoanProduct =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_LOAN_PRODUCT") ;

  const investmentProduct =
    user.isSuperAdmin ||
    arr("CAN_VIEW_INVESTMENT_PRODUCT") ||
    arr("CAN_MANAGE_INVESTMENT_PRODUCT");
  const authorizeInvestmentProduct =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_INVESTMENT_PRODUCT");
  
  const tellerProductDefinition =
    user.isSuperAdmin ||
    arr("CAN_VIEW_TELLER_PRODUCT_DEFINITION");
    arr("CAN_MANAGE_TELLER_PRODUCT_DEFINITION");
  const authorizeTellerProductDefinition =
    user.isSuperAdmin || arr("CAN_AUTHORIZE_TELLER_PRODUCT_DEFINITION");
  //------------------------------------------------Products end--------------------------------------------------------------------------------

  //------------------------------------------------Investment Account Operations start--------------------------------------------------------------------------------
      const investmentAccountOperations =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_INVESTMENT") ||
      arr("CAN_VIEW_INVESTMENT");
  
    const authorizeInvestmentAccountOperations =
      user.isSuperAdmin ||
      arr("CAN_AUTHORIZE_INVESTMENT");

  //------------------------------------------------Investment Account Operations end--------------------------------------------------------------------------------

  //------------------------------------------------Customer Account Operations start--------------------------------------------------------------------------------
      const customerAccountOperations =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_LIENACCOUNT") ||
      arr("CAN_VIEW_LIENACCOUNT") ||
      arr("CAN_MANAGE_CUSTOMER") ||
      arr("CAN_VIEW_CUSTOMER") ||
      arr("CAN_VIEW_CUSTOMER_ACCOUNT") ||
      arr("CAN_MANAGE_CUSTOMER_ACCOUNT");
  
    const authorizeCustomerAccountOperations =
      user.isSuperAdmin ||
      arr("CAN_AUTHORIZE_LIENACCOUNT") ||
      arr("CAN_AUTHORIZE_CUSTOMER") ||
      arr("CAN_AUTHORIZE_CUSTOMER_ACCOUNT");
    //_________________________________________Customer Account Operations sub menu______________________________________________________________________
    const accountOpening =
      user.isSuperAdmin ||
      arr("CAN_VIEW_CUSTOMER") ||
      arr("CAN_MANAGE_CUSTOMER");
    const authorizeAccountOpening =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_CUSTOMER");
  
    const accounEnquiry =
      user.isSuperAdmin ||
      arr("CAN_VIEW_CUSTOMER_ACCOUNT") ||
      arr("CAN_MANAGE_CUSTOMER_ACCOUNT");

    const manageCustomerAccount =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_CUSTOMER_ACCOUNT");
    
    const allAccountEnquiry = manageCustomerAccount

    const authorizeAccountEnquiry =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_CUSTOMER_ACCOUNT") ;
  
    const lienBock =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_LIENACCOUNT") ||
      arr("CAN_VIEW_LIENACCOUNT");
    const authorizeLienBlock =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_LIENACCOUNT");


    const accountStatement = 
    user.isSuperAdmin || arr("CAN_VIEW_CUSTOMER_ACCOUNT");
    //------------------------------------------------Customer Account Operations end--------------------------------------------------------------------------------

    //------------------------------------------------Core Modules start--------------------------------------------------------------------------------
      const coreModules =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_GLACCOUNT") ||
      arr("CAN_VIEW_GLACCOUNT") ||
      arr("CAN_MANAGE_RATE_CHART") ||
      arr("CAN_VIEW_RATE_CHART") ||
      arr("CAN_MANAGE_PERIOD_DEFINITION") ||
      arr("CAN_VIEW_PERIOD_DEFINITION") ||
      arr("CAN_MANAGE_INSTITUTION") ||
      arr("CAN_VIEW_INSTITUTION") ||
      arr("CAN_MANAGE_FISCAL_YEAR") ||
      arr("CAN_VIEW_FISCAL_YEAR") ||
      arr("CAN_MANAGE_DENOMINATION_DEFINITION") ||
      arr("CAN_VIEW_DENOMINATION_DEFINITION") ||
      arr("CAN_MANAGE_CUSTOMERCATEGORY") ||
      arr("CAN_VIEW_CUSTOMERCATEGORY") ||
      arr("CAN_MANAGE_CURRENCYDEFINITION") ||
      arr("CAN_VIEW_CURRENCYDEFINITION") ||
      arr("CAN_MANAGE_BRANCHHOLIDAYS") ||
      arr("CAN_VIEW_BRANCHHOLIDAYS") ||
      arr("CAN_MANAGE_BANKHOLIDAYS") ||
      arr("CAN_VIEW_BANKHOLIDAYS") ||
      arr("CAN_MANAGE_BANKPARAMETERS") ||
      arr("CAN_VIEW_BANKPARAMETERS") ||
      arr("CAN_MANAGE_BANKCLEARING") ||
      arr("CAN_VIEW_BANKCLEARING");
  
    const authorizeCoreModules =
      user.isSuperAdmin ||
      arr("CAN_AUTHORIZE_GLACCOUNT") ||
      arr("CAN_AUTHORIZE_RATE_CHART") ||
      arr("CAN_AUTHORIZE_INSTITUTION") ||
      arr("CAN_AUTHORIZE_FISCAL_YEAR") ||
      arr("CAN_AUTHORIZE_DENOMINATION_DEFINITION") ||
      arr("CAN_AUTHORIZE_CUSTOMERCATEGORY") ||
      arr("CAN_AUTHORIZE_CURRENCYDEFINITION") ||
      arr("CAN_AUTHORIZE_BRANCHHOLIDAYS") ||
      arr("CAN_AUTHORIZE_BANKPARAMETERS") ||
      arr("CAN_AUTHORIZE_BANKHOLIDAYS") ||
      arr("CAN_AUTHORIZE_BANKCLEARING") ||
      arr("CAN_AUTHORIZE_RATE_CHART") ||
      arr("CAN_AUTHORIZE_PERIOD_DEFINITION");
    //_________________________________________Core Modules sub menu______________________________________________________________________
    const institutionDefinition =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_INSTITUTION") ||
      arr("CAN_VIEW_INSTITUTION");
    const authorizeInstitutionDefinition =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_INSTITUTION");
  
    const branchDefinition =
      user.isSuperAdmin ||
      arr("CAN_VIEW_CUSTOMER_ACCOUNT") ||
      arr("CAN_MANAGE_CUSTOMER_ACCOUNT");
    const authorizeBranchDefinition =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_CUSTOMER_ACCOUNT") ;
  
    const bankCalendar =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_BANKHOLIDAYS") ||
      arr("CAN_VIEW_BANKHOLIDAYS");
    const authorizeBankCalendar =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_BANKHOLIDAYS");
    
    const branchCalendar =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_BRANCHHOLIDAYS") ||
      arr("CAN_VIEW_BRANCHHOLIDAYS");
    const authorizeBranchCalendar =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_BRANCHHOLIDAYS");

    const chartsofAccount =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_GLACCOUNT") ||
      arr("CAN_VIEW_GLACCOUNT");
    const authorizeChartsofAccount =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_GLACCOUNT");

    const countryDefintiion =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_LIENACCOUNT") ||
      arr("CAN_VIEW_LIENACCOUNT");
    const authorizeCountryDefinition =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_LIENACCOUNT");

    const currencyDefinition =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_CURRENCYDEFINITION") ||
      arr("CAN_VIEW_CURRENCYDEFINITION");
    const authorizeCurrencyDefinition =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_CURRENCYDEFINITION");

    const denominationDefinition =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_DENOMINATION_DEFINITION") ||
      arr("CAN_VIEW_DENOMINATION_DEFINITION");
    const authorizeDenominationDefinition =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_DENOMINATION_DEFINITION");

    const periodDefinition =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_PERIOD_DEFINITION") ||
      arr("CAN_VIEW_PERIOD_DEFINITION");
    const authorizePeriodDefinition =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_PERIOD_DEFINITION");

    const fiscalYear =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_FISCAL_YEAR") ||
      arr("CAN_VIEW_FISCAL_YEAR");
    const authorizeFiscalYear =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_FISCAL_YEAR");

    const parameterSettings =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_BANKPARAMETERS") ||
      arr("CAN_VIEW_BANKPARAMETERS");
    const authorizeParameterSettings =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_BANKPARAMETERS");
    
    const customerCategory =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_CUSTOMERCATEGORY") ||
      arr("CAN_VIEW_CUSTOMERCATEGORY");
    const authorizeCustomerCategory =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_BANKPARAMETERS");

    const rateCharts =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_RATE_CHART") ||
      arr("CAN_VIEW_RATE_CHART");
    const authorizeRateCharts =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_RATE_CHART");

    const bankClearingCodes =
      user.isSuperAdmin ||
      arr("CAN_MANAGE_BANKCLEARING") ||
      arr("CAN_VIEW_BANKCLEARING");
    const authorizeBankClearingCodes =
      user.isSuperAdmin || arr("CAN_AUTHORIZE_BANKCLEARING");
    //------------------------------------------------Core Modules end--------------------------------------------------------------------------------
  

  return {
    securityManagement,
    AuthorizeSecurityManagement,
    manageRoles,
    authorizeManageRole,
    userCreation,
    AuthorizeUserCreation,
    userTellerTills,
    authorizeUserTellerTills,
    branchTellerTills,
    authorizeBranchTellerTills,
    miscellaneoustransactions,
    authorizeMiscellanousTransaction,
    eodTmeProcess,
    reports,
    loanAccountOperations,
    authorizeLoanAccountOperations,
    loanBooking,
    authorizeLoanBooking,
    loanDisbursement,
    authorizeLoanDisbursement,
    loanRepayment,
    authorizeLoanRepayment,
    loanFacilityLimit,
    authorizeLoanFacilityLimit,
    products,
    authorizeProducts,
    accountProduct,
    authorizeAccountProduct,
    loanProduct,
    authorizeLoanProduct,
    investmentProduct,
    authorizeInvestmentProduct,
    tellerProductDefinition,
    authorizeTellerProductDefinition,
    investmentAccountOperations,
    authorizeInvestmentAccountOperations,
    customerAccountOperations,
    authorizeCustomerAccountOperations,
    accountOpening,
    authorizeAccountOpening,
    accounEnquiry,
    accountStatement,
    allAccountEnquiry,
    authorizeAccountEnquiry,
    lienBock,
    authorizeLienBlock,
    coreModules,
    authorizeCoreModules,
    institutionDefinition,
    authorizeInstitutionDefinition,
    branchDefinition,
    authorizeBranchDefinition,
    bankCalendar,
    authorizeBankCalendar,
    branchCalendar,
    authorizeBranchCalendar,
    chartsofAccount,
    authorizeChartsofAccount,
    countryDefintiion,
    authorizeCountryDefinition,
    currencyDefinition,
    authorizeCurrencyDefinition,
    denominationDefinition,
    authorizeDenominationDefinition,
    periodDefinition,
    authorizePeriodDefinition,
    fiscalYear,
    authorizeFiscalYear,
    parameterSettings,
    authorizeParameterSettings,
    customerCategory,
    authorizeCustomerCategory,
    rateCharts,
    authorizeRateCharts,
    bankClearingCodes,
    authorizeBankClearingCodes,
    manageCustomerAccount
  };
};

















export default Permissions;

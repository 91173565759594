import { useState, useCallback, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import DecisionModal from "../../../Confirmbox/decision";
import FormDialog from "../../../Confirmbox/form";
import ResponseModal from "../../../Confirmbox/Modal";
import {
  openDecisionModal,
  closeDecisionModal,
  closeFormModal,
  closeResponseModal,
  openResponseModal,
  isLoading,
} from "../../../Api/redux-manager/actions";
import {
  getBankParameterByName,
  getAllCustomerCategory,
  getAllGlAccount,
  getAllCurrencyDefinition,
} from "../../../Api/Hooks-manager/coreModules/coreModules";
import { addMultipleOverdueDays } from "../../../Api/Hooks-manager/products/products";
import BusinessLogic from "../Products/LoanProducts/bussinessLogic";
import LoanProduct from "../../Details/Products/LoanProduct";
import CustomizedSnackbars from '../../../Confirmbox/Snackbar';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "200px",
  },
  centerBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    // marginTop: 20,
    // marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  children: {
    padding: "1.5rem 0px",
  },
}));

const AddBulkModal = ({
  actionAccountObject,
  actionOnClose,
  onSuccess,
  open,
  props,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const openDialog = useSelector((state) => state.approve.opendecisionModal);
  const openresponseModal = useSelector(
    (state) => state.approve.openResponseModal
  );

  const [input, setInput] = useState([
    {
      from: 0,
      to: 0,
      loanStatus:"",
      loanProductId: actionAccountObject.id,
  }
  ]);

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarContent, setSnackBarContent] = useState({
    status: "",
    message: ""
  });

  // const input = new Array({
  //   from: 0,
  //   to: 0,
  //   loanStatus: "",
  //   loanProductId: "",
  // })

  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");
  const [response, setResponse] = useState(null);
  const [loanStatus, setLoanStatus] = useState([]);

  const parameters = "CUS_CATEGORY,Loan_Status";
  const BankParameterByName = useCallback(async () => {
    const response = await getBankParameterByName(token, parameters);
    if (response && response.data.loan_Status) {
      setLoanStatus(response.data.loan_Status);
    }
  }, [token]);

  const { overdueDays, product, setProducts } = BusinessLogic();


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setInput((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleChange = (event) => {
  //   let value = event.target.value;
  //   let name = event.target.name;

  //   setInput((prevalue) => {
  //     return {
  //       ...prevalue,   // Spread Operator
  //       [name]: value
  //     }
  //   })
  // }

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...input];
    list[index][name] =
      name === "from" || name === "to"
        ? value === ""
          ? ""
          : parseInt(value) < 0
          ? 0
          : parseInt(value)
        : value;
        setInput(list);
    // setInput({ ...product, input: list });

};

useEffect(() => {
  BankParameterByName();
}, [BankParameterByName]);


  const validateForm = () => {
    const { from, to, loanStatus, loanProductId } = input;
    if (from === "") {
      setErr("from");
      setMsg("Field is required");
      return;
    }
    if (to === "") {
      setErr("to");
      setMsg("Field is required");
      return;
    }
    if (loanStatus === "") {
      setErr("to");
      setMsg("Field is required");
      return;
    }
    if (loanProductId === "") {
      setErr("to");
      setMsg("Field is required");
      return;
    }
    return true;
  };

  const openDecisionDialog = () => {
    if (validateForm()) {
      setErr("");
      setMsg("");
      dispatch(openDecisionModal(input));
    }
  };

  const addBulkOverDueDays = async () => {
    dispatch(isLoading());
    const response = await addMultipleOverdueDays(token, input);
    setMsg(response.message);
    // console.log(response.status, "adddd bulk overdue");
    if (response && response.data) {
      dispatch(closeDecisionModal());
      // dispatch(openResponseModal());      
      dispatch(isLoading());
      setSnackBarContent({
        status: "success",
        message: "Overdue days added successfully"
      })
      setSnackBarOpen(true);
      window.location.reload();
      return true      
    } else {
      setErr(response.message);
      setSnackBarContent({
        status: "error",
        message: "Failed to add over due days"
      })
      setSnackBarOpen(true);        
      return false;
    }
  };

  const closeModal = (actionCreator = null) => {
    if (actionCreator) {
      dispatch(actionCreator());
    }
    actionOnClose();
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackBarOpen(false);
  };

  console.log(input);

  return (
    <div>
      <FormDialog
        open={open}
        handleClose={() => closeModal(closeFormModal)}
        title={"Add Overdue Days"}
      >
        <>
          {input.map((due, i) => (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                className={classes.centerBtn}
                key={i}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={err === "status"}
                >
                  <TextField
                    label="From"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    id="outlined-size-small"
                    defaultValue={due.from}
                    className={classes.hideElements}
                    size="small"
                    variant="outlined"
                    name="from"
                    onChange={(e) => handleChange(e, i)}

                    error={err === "from"}
                  />
                  {err === "from" && <FormHelperText>{msg}</FormHelperText>}
                  <br />
                  <TextField
                    label="To"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    id="outlined-size-small"
                    defaultValue={due.to}
                    className={classes.hideElements}
                    size="small"
                    variant="outlined"
                    name="to"
                    onChange={(e) => handleChange(e, i)}
                    error={err === "to"}
                  />
                  {err === "to" && <FormHelperText>{msg}</FormHelperText>}
                  <br />
                  <TextField
                    label="Loan Status"
                    id="outlined-size-small"
                    select
                    defaultValue={due.loanStatus}
                    size="small"
                    variant="outlined"
                    // className={classes.hideElements}
                    onChange={(e) => handleChange(e, i)}
                    name="loanStatus"
                    error={err === "loanStatus"}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {loanStatus.map((item, i) => (
                      <option
                        key={item.value}
                        name={item.parameterValue}
                        value={item.parameterValue}
                      >
                        {item.parameterValue}
                      </option>
                    ))}
                  </TextField>
                  {err === "loanStatus" && (
                    <FormHelperText>{msg}</FormHelperText>
                  )}
                  <br />
                  <TextField
                    label="Loan Product ID"
                    id="outlined-size-small"
                    defaultValue={due.loanProductId}
                    className={classes.hideElements}
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleChange(e, i)}
                    name="loanProductId"
                    error={err === "loanProductId"}
                  />
                  {err === "loanProductId" && (
                    <FormHelperText>{msg}</FormHelperText>
                  )}
                  <br />
                </FormControl>
                {/* <p style={{ color: "red" }}>{err === "status" ? msg : ""}</p> */}
              </Grid>

              <Grid item xs={12} className={classes.centerBtn}>
                <Button className={classes.button} onClick={openDecisionDialog}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          ))}
        </>
      </FormDialog>
      <DecisionModal
        question={"Are you sure you want to save this record"}
        openDialog={openDialog}
        onClick={() => addBulkOverDueDays(input)}
        onClose={() => dispatch(closeDecisionModal())}
      />
      {/* <ResponseModal
        openDialog={openresponseModal}
        onClose={() => closeModal(closeResponseModal)}
        positiveDialog={response && response.status}
      >
        <div className={classes.children}>{response && response.message}</div>
      </ResponseModal> */}
      <CustomizedSnackbars 
          handleClose={handleCloseSnackBar} 
          message={snackBarContent.message} 
          status={snackBarContent.status} 
          open={snackBarOpen}
        />
    </div>
  );
};

export default AddBulkModal;

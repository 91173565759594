import Item from "../../DatagridTable/item";
import { Grid } from '@material-ui/core';
//import DealPosting from './../../Dashboard/Treasury/DealPosting/DealPosting';
import { formattedDate } from "../../../formatter/date";

const DealPosting = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Account Number" name={details.accountNumber} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="portfolio Code" name={details.portfolioCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Portfolio Type" name={details.portfolioType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Trade Date" name={formattedDate(details.tradeDate)} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="External Reference No" name={details.externalReferenceNo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Maturity Date" name={formattedDate(details.maturityDate)} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Deal Quantity" name={details.dealQuantity} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Deal Reference" name={details.dealReference} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Reason" name={details.reason} />
          </Grid>}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>
    )
}

export default DealPosting

import axios from 'axios';
import { Routes } from '../../configUrl';


  const createLoanProducts = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createLoanProducts,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status,
          data:[],
        };
      });
  };

  const getAllLoanProducts = async (token) => { 
    return await axios({
        method: "get",
        url: Routes.getAllLoanProducts,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getALoanProducts = async (token,id) => {
    return await axios({
        method: "get",
        url: Routes.getALoanProducts + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const updateLoanProducts = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateLoanProducts + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };


  const addMultipleOverdueDays = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.bulkAdditionOfOverdueDays,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const deleteLoanProducts = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteLoanProducts + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeLoanProducts = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeLoanProducts + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const createTellerProductDefinition = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createTellerProductDefinition,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status,
          data:[],
        };
      });
  };

  const getAllTellerProductDefinition = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllTellerProductDefinitionWithCharges,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data)
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getATellerProductDefinition = async (token,id) => {
    return await axios({
        method: "get",
        url: Routes.getATellerProductDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const updateTellerProductDefinition = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateTellerProductDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const updateTellerProductCharges = async (token,body) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateTellerProductCharges,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };



  const deleteTellerProductDefinition = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteTellerProductDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const deleteTellerProductCharges = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteTellerProductCharges + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeProductDefinition = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeProductDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const putHandler = async (token,body,url) => {
    return await axios({
        method: "put",
        data: body,
        url: url,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  

export {
    createLoanProducts,
    getAllLoanProducts,
    getALoanProducts,
    updateLoanProducts,
    deleteLoanProducts,
    authorizeLoanProducts,
    createTellerProductDefinition,
    getAllTellerProductDefinition,
    getATellerProductDefinition,
    updateTellerProductDefinition,
    updateTellerProductCharges,
    deleteTellerProductDefinition,
    deleteTellerProductCharges,
    authorizeProductDefinition,
    putHandler,
    addMultipleOverdueDays,
}

import React from 'react';
import './Main.scss';
import { Switch, Route } from "react-router-dom";
import ChequeDeposit from './ChequeDeposit/ChequeDeposit';
import ChequeWithdrawal from './ChequeWithdrawal/ChequeWithdrawal';



 const Main = () => {
    return (
        <div className='main'>
            <Switch>
                <Route path='/dashboard/chequetransaction/001' component={ChequeDeposit}/>
                <Route path='/dashboard/chequetransaction/002' component={ChequeWithdrawal}/>
            </Switch>
        </div>
    )
}
export default Main;
import Item from "../../DatagridTable/item";
import { Grid } from "@material-ui/core";


const ChartOfAccount = ({ details }) => {



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Ledger Account Name" name={details.accountName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Ledger Account Code" name={details.accountNumber} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Parent Branch Code" name={details.branchCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Type" name={details.glType} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Hierarchy" name={details.glHierarchy} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Parent Ledger" name={details.parentGLID} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Product Account"
            name={details.productAccount ? "True" : "False"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Entry Type" name={details.entityType} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Status" name={details.isGLActive ? "True" : "False"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Recon Type" name={details.reckonType} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Status"
            name={
              details.authorizationStatus === "PendingEditedAuthorization"
                ? "Pending Edited Authorization"
                : details.authorizationStatus
            }
          />
        </Grid>
      </Grid>

    </>
  );
};

export default ChartOfAccount;

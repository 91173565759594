import React, { useState, useEffect, useCallback } from "react";
import "./BranchTellerTills.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";

import SearchDropdown from "../../../SearchableDropdown/Search";

import {
  getAllBranches,
  getAllGlAccount,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import {
  getAllTellerTill,
  createTellerTill,
} from "../../../../Api/Hooks-manager/securitymanagement/tellerTills";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";
import useCustom3 from "../../../../Api/Hooks-manager/coreModules/useCustom";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import Table from "../../../DatagridTable/table";

import { formattedDate } from "../../../../formatter/date";
import EditForm from "./edit";
import Details from "./view";

const BranchTellerTills = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const loading = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [allBranches, setAllBranches] = useState([]);
  const [error, setError] = useState({});
  const { allCurrencyDefinition, getCurrencyDefinitionAll } = useCustom3();
  const [errorEdit, setErrorEdit] = useState({});

  const [accountNo, setAccountNo] = useState([]);
  const [data, setData] = useState({
    tillId: "",
    branchId: 0,
    currencyCode: "",
    accountNumber: "",
  });

  const fetchAllBranches = useCallback(async () => {
    const response = await getAllBranches(token);
    if (response && response.data) {
      setAllBranches(response.data);
    }
  }, [token]);

  const fetchAnAccount = useCallback(async () => {
    const response = await getAllGlAccount(token);
    if (response && response.data) {
      setAccountNo(
        response.data
          .filter(
            (el) =>
              el.authorizationStatus === "Authorized" && el.glType === "Asset"
          )
          .map((item) => {
            return {
              value: item.accountNumber,
              label: `${item.accountNumber} - ${item.accountName}`,
              name: item.accountName,
            };
          })
      );
    }
  }, [token]);

  const handleTill = (e) => {
    setData({ ...data, tillId: e.target.value });
  };
  const handleBranch = (e) => {
    setData({ ...data, branchId: e.target.value });
  };
  const handleCurrency = (e) => {
    setData({ ...data, currencyCode: e.target.value });
  };
  const [allTellerTills, setAllTellerTills] = useState([]);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tillId",
      label: "Till Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchId",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Till CCYI Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allTellerTills[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = allTellerTills[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllTills = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllTellerTill(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllTellerTills(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllTills();
  }, [fetchAllTills, updateTable]);

  // console.log(allTellerTills, "all teller tills");

  // const tillGLAcct = allTellerTills.map(items) =>{}

  // const tillGLAcct = (arr) => arr.map(({ accountNumber }) => accountNumber);

  // const tillAccount = tillGLAcct(allTellerTills);

  // console.log(tillAccount, "checking.....");


  const postTellerTills = async () => {
    dispatch(isLoading());
    const response = await createTellerTill(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.message === "Success") {
        EmptyFieldPostTeller();
        fetchAllTills();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSave = () => {
    let checkError = isValidateForm(data);
    setError(checkError);
    if (Object.keys(isValidateForm(data)).length > 0) return;
    setOpen(true);
  };

  const EmptyFieldPostTeller = () => {
    setData({
      tillId: "",
      branchId: 0,
      currencyCode: "",
      accountNumber: "",
    });
  };

  const handleEditChange = (name) => (e) => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleEditSubmit = () => {
    const checkError = validateEditTellerTills();
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;
    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };
  const validateEditTellerTills = () => {
    const { accountNumber, tillId, branchId, currencyCode } = details;

    const error = {};

    if (accountNumber === "") {
      error.accountNumber = "Field is required";
    }
    if (currencyCode === "") {
      error.currencyCode = "Select an option";
    }

    if (branchId === "") {
      error.branchId = "Select an option";
    }
    if (tillId === "") {
      error.tillId = "Select an option";
    }

    return error;
  };
  const isValidateForm = (data) => {
    let errors = {};
    if (!data.tillId.trim()) {
      errors.tillId = "Field is required";
    }
    if (!data.branchId) {
      errors.branchId = "Select an option ";
    }
    if (!data.currencyCode.trim()) {
      errors.currencyCode = "Field is Required";
    }
    return errors;
  };

  const handleChangeSelect = (value, type) => {
    if (type === "accountNumber") {
      setData((data) => ({ ...data, accountNumber: value }));
    }
  };

  useEffect(() => {
    fetchAnAccount();
  }, []);

  useEffect(() => {
    fetchAllBranches();
  }, []);

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, []);

  return (
    <main>
      <div className="branchteller--tills">
        <Spinner loading={loading} />
        <Confirm
          ApiCall={postTellerTills}
          openBox={open}
          setOpenBoxState={setOpen}
          errorMessage={message}
        />
        <div className="headerdiv">
          <p>Branch Teller Tills</p>
        </div>
        <Paper style={{ marginTop: 20, padding: 25 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <label>Till ID</label>
              <input
                type="text"
                className={error.branchId ? "error-input-z" : null}
                onChange={handleTill}
                value={data.tillId}
                name="tillId"
              />
              {error.tillId && <ErrorMessage message={error.tillId} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Branch</label>
              <select
                name="branchId"
                value={data.branchId}
                onChange={handleBranch}
                className={`${error.branchId && "error-input-z"}`}
              >
                <option value="Select a branch">Select a branch</option>
                {allBranches.map((branch) => (
                  <option
                    value={branch.code}
                    key={branch.id}
                  >{`${branch.name} - ${branch.code}`}</option>
                ))}
              </select>
              {error.branchId && <ErrorMessage message={error.branchId} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Till CCYI Account</label>
              <div className="ccyiaccount">
                <Grid xs={2} sm={2} md={2}>
                  <label>Currency </label>
                  <select
                    className={`currency ${error.currency && "error-input-z"}`}
                    onChange={handleCurrency}
                  >
                    <option value="">Select a currency</option>
                    {allCurrencyDefinition.map((item) => (
                      <option value={item.currencyCode} key={item.id}>
                        {item.currencyName}
                      </option>
                    ))}
                  </select>
                  {error.currencyCode && (
                    <ErrorMessage message={error.currencyCode} />
                  )}
                </Grid>
                <Grid xs={6} sm={5} md={5}>
                  <label className="acct">Account Number</label>
                  <div className="error-container-z">
                    <div
                      className={`border--norm ${
                        error.accountNumber ? "error-input-z" : null
                      }`}
                    >
                      <SearchDropdown
                        options={accountNo}
                        onChange={handleChangeSelect}
                        type="accountNumber"
                      />
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <button onClick={handleSave} className="save">
            Save
          </button>
        </Paper>
      </div>
      {!loading && (
        <Table
          title="Branch Teller Tills"
          subtitle=""
          name="List of Teller Tills"
          records={allTellerTills}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorEdit}
          handleEditSubmit={handleEditSubmit}
          handleEditChange={handleEditChange}
        />
      )}
    </main>
  );
};
export default BranchTellerTills;

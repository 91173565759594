import React from 'react';
import './McBranch.scss';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";


 const McBranch = () => {
    return (
        <div className="mc--branch">
        <div className="headerdiv">
          <p>Managers Cheque - Branch Unprinted</p>
        </div>
        <Paper style={{ marginTop: 20, padding: 25 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <label>Branch Code</label>
            <br />
            <input type="text" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Branch Class</label>
            <br />
            <input type="text" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='date--branch'>
            <label >From Date</label>
            <br />
            <input type="date" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='date--branch'>
            <label >To Date</label>
            <br />
            <input type="date" />
          </Grid>
          <button className='btn'> Fetch Data</button>
        </Grid>
        <table>
            <thead>
                <tr>
                <th scope="col">S/N</th>
                            <th scope="col">Tran ID</th>
                            <th scope="col">Part tran No</th>
                            <th scope="col">Mc Issue date</th>
                            <th scope="col">Mc Amount</th>
                            <th scope="col">Purchaser Name</th>
                            <th scope="col">Purchaser Account</th>
                            <th scope="col">Beneficiary Name</th>
                </tr>
            </thead>
        </table>
          </Paper>
        </div>
    )
}
export default McBranch;
import React, {useState} from "react";
import "./SingledebitMultiplecredit.scss";
import MenuItem from '@material-ui/core/MenuItem';
import useTable from "../../../Tables/useTable";
import Confirm from "../../../../Confirmbox/Confirm";
import DeleteModal from "../../../../Confirmbox/Confirm";
import UpdateModal from "../../../../Confirmbox/Confirm";
import DecisonModal from "../../../../Confirmbox/Confirm";
import CancelModal from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";
import useCustom from "../../../../Api/Hooks-manager/investmentAccountOperations/useCustom"; 
import DotMenu from "../../../../commons/dotMenu/dotMenu";

const SingledebitMultiplecredit = () => {

  const {
    openView, setOpenView
  } = useCustom();

  const [viewAll, setViewAll] = React.useState({});
  
  const handleViewAll = (item) => {
    console.log(item, "itemmsmm");
    setViewAll(item);
    setOpenView(true);
  };

  const headCells = [
    { id: "id", label: "S/N" },
    { id: "accountNumber", label: "Account Number" },
    { id: "accountName", label: "Account Name" },
    { id: "accountBranch", label: "Account Branch" },
    { id: "amount", label: "Amount" },
    { id: "narration", label: "Narration" },
    { id: "authorizationStatus", label: "Auth. Status" },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable([], headCells, filterFn);
  //  const handleScreenChange = () => {

  //  }
  return (
    <main className="singledebitMultiplecredit">
        <div className="headerdiv">
          <p>Single Debit Multiple Credit</p>
        </div>

        <>
          <div style={{ paddingTop: 20, padding: 25 }}>

          <div className="product--def flex--1">
                        <div className="flex--3 input--5">
                            <label>Debit Account Number</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        <div className="flex--3 input--5">
                            <label> Account Branch</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        </div>
                        <div className="product--def flex--1">
                    <div className="flex--3 input--4">
                            <label>Account Currency</label>
                            <input type="text" />
                        </div>
                    <div className="flex--3 input--4">
                            <label>Instrument Type</label>
                            <input type="text" />
                        </div>
                    <div className="flex--3 input--4">
                            <label>Amount</label>
                            <input type="text" />
                        </div>
                        </div>
                        <div className="product--def flex--1">
                        <div className="flex--3 input--4">
                            <label>Narration</label>
                            <input type="text" />
                        </div>
                        </div>
                         <div className="product--def flex--1">
                        <div className="flex--3 input--4">
                            <label>No of Drafts</label>
                            <input type="number" step="any" />
                        </div>
                        <div className="flex--3 input--4">
                            <label>Amount</label>
                            <input type="number" step="any" />
                        </div>
                        </div>
                        <div className="product--def flex--1">

            <div className="button-last">
              <button>+Add</button>
            </div>
            </div>
            <div> 
            <TblContainer>
                <TblHead />
                <tbody>
                  {recordsAfterPagingAndSorting().map((item,i) => (
                  <tr key={item.id}>
                      <th scope="row">{i+1}</th>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>
                        <div className="table--icon">
                        <DotMenu>
                          <MenuItem>Edit</MenuItem>
                          <MenuItem>Delete</MenuItem>
                          <MenuItem onClick={() => handleViewAll(item)}>View</MenuItem>
                        </DotMenu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TblContainer>
              <TblPagination />
            </div>

          </div>
        </>

        <>
          {/* <div className='section--2'>
                        <Table/>
                    </div> */}
        </>
      
    </main>
  );
};
export default SingledebitMultiplecredit;

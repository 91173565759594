import React from 'react'
import { Switch, Route } from "react-router-dom";
import LoanMaturityReport from './LoanMaturityReport/LoanMaturityReport'
import LoanAccrualReport from './LoanAccrualReport/LoanAccrualReport'
import LoanDisbursementReport from './LoanDisbursementReport/LoanDisbursementReport'
import LoanRepaymentReport from './LoanRepaymentReport/LoanRepaymentReport'
import LoanRepaymentDueReport from './LoanRepaymentDueReport/LoanRepaymentDueReport'
import PARReport from './PARReport/PARReport'
import PARExtended from './ParExtended/ParExtended'



const Main = () => {
    return (
        <div>
            <Switch> 
                <Route path='/dashboard/reports/loan/maturity' component={LoanMaturityReport}/>
                <Route path='/dashboard/reports/loan/accrual' component={LoanAccrualReport}/>
                <Route path='/dashboard/reports/loan/disbursement' component={LoanDisbursementReport}/>
                <Route path='/dashboard/reports/loan/repayment' component={LoanRepaymentReport}/>
                <Route path='/dashboard/reports/loan/repaymentDue' component={LoanRepaymentDueReport}/>
                <Route path='/dashboard/reports/loan/par' component={PARReport}/>
                <Route path='/dashboard/reports/loan/parextended' component={PARExtended}/>

            </Switch>
        </div>
    )
}

export default Main

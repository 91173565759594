import { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from "../../../../Api/configUrl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  checkbox: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& input": {
      height: 15,
      borderRadius: 0,
      width: 20,
      marginLeft: 0,
    },
  },
  expand: {
    cursor: "pointer",
    marginBottom: "20px",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  border: {
    border: "1px solid black",
    marginBottom: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttondiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  item: {
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
  cusCategory,
  filterGlAccount,
  createCustomerRestriction,
  handleEditCustomerRestrictionChange,
  errorsOfOtherServicesOtherthanProductDetails,
}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);
  const classes = useStyles();

  const displayErr = (i, name) => {
    return (
      errorsOfOtherServicesOtherthanProductDetails.index === i &&
      errorsOfOtherServicesOtherthanProductDetails.type === name
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [details]);

  return (
    <EditModal
      title="Account Products"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this Account Product record?"
      data={details}
      url={`${baseUrl}account/AccountProduct/Update/${details.id}`}
    >
      <div style={{ marginTop: 50, padding: 25 }}>
        <>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Product Details (1)"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Product Details (2)"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />

                <Tab
                  label="Customer Restriction"
                  {...a11yProps(2)}
                  onClick={() => setHide(true)}
                />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} className="spacer">
                  <label>Product Code</label>
                  <input
                    disabled
                    type="text"
                    onChange={handleEditChange}
                    name="productCode"
                    value={details.productCode}
                    className={`${
                      errorEdit.productCode ? "error-input-z" : ""
                    }`}
                  />
                  {errorEdit.productCode && (
                    <ErrorMessage message={errorEdit.productCode} />
                  )}
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      name="status"
                      onChange={handleEditChange}
                      checked={details.status}
                    />
                    <p>Deactivate Product</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="spacer">
                  <label>Product Name</label>
                  <input
                    disabled
                    type="text"
                    onChange={handleEditChange}
                    name="productName"
                    value={details.productName}
                    className={`${
                      errorEdit.productName ? "error-input-z" : ""
                    }`}
                  />
                  {errorEdit.productName && (
                    <ErrorMessage message={errorEdit.productName} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Type</label>
                  <div className="error-container-z">
                    <select
                      value={details.accountType}
                      name="accountType"
                      onChange={handleEditChange}
                      className={`${
                        errorEdit.accountType ? "error-input-z" : ""
                      }`}
                    >
                      <option value="Savings">Savings</option>
                      <option value="Current">Current</option>
                    </select>
                    {errorEdit.accountType && (
                      <ErrorMessage message={errorEdit.accountType} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.creditAccount}
                      name="creditAccount"
                      onChange={handleEditChange}
                      className={`${
                        errorEdit.creditAccount ? "error-input-z" : ""
                      }`}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.creditAccount && (
                      <ErrorMessage message={errorEdit.creditAccount} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Payable Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.interestPayableAccount}
                      name="interestPayableAccount"
                      onChange={handleEditChange}
                      className={`${
                        errorEdit.interestPayableAccount ? "error-input-z" : ""
                      }`}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.interestPayableAccount && (
                      <ErrorMessage
                        message={errorEdit.interestPayableAccount}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>No. of Days to Activate Inactivity</label>
                  <input
                    type="number"
                    onChange={handleEditChange}
                    name="noOfDaysToActivateInactivity"
                    value={details.noOfDaysToActivateInactivity}
                    className={`${
                      errorEdit.noOfDaysToActivateInactivity
                        ? "error-input-z"
                        : ""
                    }`}
                  />
                  {errorEdit.noOfDaysToActivateInactivity && (
                    <ErrorMessage
                      message={errorEdit.noOfDaysToActivateInactivity}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.debitAccount}
                      name="debitAccount"
                      onChange={handleEditChange}
                      className={`${
                        errorEdit.debitAccount ? "error-input-z" : ""
                      }`}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.debitAccount && (
                      <ErrorMessage message={errorEdit.debitAccount} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Receivable Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.interestReceivableAccount}
                      className={`${
                        errorEdit.interestReceivableAccount
                          ? "error-input-z"
                          : ""
                      }`}
                      name="interestReceivableAccount"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.interestReceivableAccount && (
                      <ErrorMessage
                        message={errorEdit.interestReceivableAccount}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Income</label>
                  <div className="error-container-z">
                    <select
                      value={details.interestIncome}
                      className={`${
                        errorEdit.interestIncome ? "error-input-z" : ""
                      }`}
                      name="interestIncome"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.interestIncome && (
                      <ErrorMessage message={errorEdit.interestIncome} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Expense</label>
                  <div className="error-container-z">
                    <select
                      value={details.interestExpense}
                      className={`${
                        errorEdit.interestExpense ? "error-input-z" : ""
                      }`}
                      name="interestExpense"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.interestExpense && (
                      <ErrorMessage message={errorEdit.interestExpense} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>No. of Inactive Days Till Dormant</label>
                  <input
                    type="number"
                    onChange={handleEditChange}
                    name="noOfInactiveDormantDays"
                    value={details.noOfInactiveDormantDays}
                    className={`${
                      errorEdit.noOfInactiveDormantDays ? "error-input-z" : ""
                    }`}
                  />
                  {errorEdit.noOfInactiveDormantDays && (
                    <ErrorMessage message={errorEdit.noOfInactiveDormantDays} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Reactivation Type</label>
                  <div className="error-container-z">
                    <select
                      value={details.accountReactivationType}
                      className={`${
                        errorEdit.accountReactivationType ? "error-input-z" : ""
                      }`}
                      name="accountReactivationType"
                      onChange={handleEditChange}
                    >
                      <option value="Manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                    {errorEdit.accountReactivationType && (
                      <ErrorMessage
                        message={errorEdit.accountReactivationType}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Reactivation Trigger</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="reactivationTrigger"
                    value={details.reactivationTrigger}
                    className={`${
                      errorEdit.reactivationTrigger ? "error-input-z" : ""
                    }`}
                  />
                  {errorEdit.reactivationTrigger && (
                    <ErrorMessage message={errorEdit.reactivationTrigger} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Expected Minimum Balance</label>
                  <input
                    type="number"
                    onChange={handleEditChange}
                    name="expectedMinBalance"
                    value={details.expectedMinBalance}
                    className={`${
                      errorEdit.expectedMinBalance ? "error-input-z" : ""
                    }`}
                  />
                  {errorEdit.expectedMinBalance && (
                    <ErrorMessage message={errorEdit.expectedMinBalance} />
                  )}
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="trackDormancy"
                      checked={details.trackDormancy}
                    />
                    <p>Track Domancy</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="debitBalanceAllowed"
                      checked={details.debitBalanceAllowed}
                    />
                    <p>Debit Balance Allowed</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="applyDebitInterest"
                      checked={details.applyDebitInterest}
                    />
                    <p>Apply Debit Interest</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="applyCreditInterest"
                      checked={details.applyCreditInterest}
                    />
                    <p>Apply Credit Interest</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="enforceMinBalCheck"
                      checked={details.enforceMinBalCheck}
                    />
                    <p>Debit Interest Rate</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="isEligibleForLoan"
                      checked={details.isEligibleForLoan}
                    />
                    <p>Loan Eligibility</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} sm={3}>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      onChange={handleEditChange}
                      name="requireMemberShipFee"
                      checked={details.requireMemberShipFee}
                    />
                    <p>Require Membership Fee</p>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Interest Rate</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="debitInterestRate"
                    value={details.debitInterestRate}
                    className={`${
                      errorEdit.debitInterestRate ? "error-input-z" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Rate Basis</label>
                  <select
                    value={details.debitRateBasis}
                    className={`${
                      errorEdit.debitRateBasis ? "error-input-z" : ""
                    }`}
                    name="debitRateBasis"
                    onChange={handleEditChange}
                  >
                    <option value="PerAnnum">Per Annum</option>
                    <option value="PerMonth">Per Month</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Interest Period</label>
                  <select
                    value={details.debitInterestPeriod}
                    className={`${
                      errorEdit.debitInterestPeriod ? "error-input-z" : ""
                    }`}
                    name="debitInterestPeriod"
                    onChange={handleEditChange}
                  >
                    <option value="Actual/Actual">Actual/Actual</option>
                    <option value="Actual/360">Actual/360</option>
                    <option value="30/Actual">30/Actual</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Interest Calc. Period</label>
                  <select
                    value={details.debitInterestCalculationPeriod}
                    className={`${
                      errorEdit.debitInterestCalculationPeriod
                        ? "error-input-z"
                        : ""
                    }`}
                    name="debitInterestCalculationPeriod"
                    onChange={handleEditChange}
                  >
                    <option value="Daily">Daily</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Accrue Interest (Debit)</label>
                  <select
                    value={details.debitAccrueInterest}
                    className={`${
                      errorEdit.debitAccrueInterest ? "error-input-z" : ""
                    }`}
                    name="debitAccrueInterest"
                    onChange={handleEditChange}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Rate (Credit)</label>
                  <input
                    type="number"
                    onChange={handleEditChange}
                    name="creditInterestRate"
                    value={details.creditInterestRate}
                    className={`${
                      errorEdit.debitAccrueInterest ? "error-input-z" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Rate Basis (Credit)</label>
                  <select
                    value={details.creditRateBasis}
                    className={`${
                      errorEdit.creditRateBasis ? "error-input-z" : ""
                    }`}
                    name="creditRateBasis"
                    onChange={handleEditChange}
                  >
                    <option value="PerAnnum">Per Annum</option>
                    <option value="PerMonth">Per Month</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Period (Credit)</label>
                  <select
                    value={details.creditInterestPeriod}
                    className={`${
                      errorEdit.creditInterestPeriod ? "error-input-z" : ""
                    }`}
                    name="creditInterestPeriod"
                    onChange={handleEditChange}
                  >
                    <option value="Actual/Actual">Actual/Actual</option>
                    <option value="Actual/360">Actual/360</option>
                    <option value="30/Actual">30/Actual</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Balance Basis (Credit)</label>
                  <select
                    value={details.creditBalanceBasis}
                    className={`${
                      errorEdit.creditBalanceBasis ? "error-input-z" : ""
                    }`}
                    name="creditBalanceBasis"
                    onChange={handleEditChange}
                  >
                    <option value="Daily Balance">Daily Balance</option>
                    <option value="Average Balance">Average Balance</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Interest VAT Rate</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="debitInterestVATRate"
                    value={details.debitInterestVATRate}
                    className={`${
                      errorEdit.debitInterestVATRate ? "error-input-z" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>VAT Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.gateWayCreditAccount}
                      className={`${
                        errorEdit.gateWayCreditAccount ? "error-input-z" : ""
                      }`}
                      name="gateWayCreditAccount"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.interestIncome && (
                      <ErrorMessage message={errorEdit.interestIncome} />
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Credit Interest WHT Rate</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="creditInterestWHTRate"
                    value={details.creditInterestWHTRate}
                    className={`${
                      errorEdit.creditInterestWHTRate ? "error-input-z" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>WHT Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.whtAccount}
                      className={`${
                        errorEdit.whtAccount ? "error-input-z" : ""
                      }`}
                      name="whtAccount"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.interestIncome && (
                      <ErrorMessage message={errorEdit.interestIncome} />
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Calc. Period (Credit)</label>
                  <select
                    value={details.creditInterestCalculationPeriod}
                    className={`${
                      errorEdit.creditInterestCalculationPeriod
                        ? "error-input-z"
                        : ""
                    }`}
                    name="creditInterestCalculationPeriod"
                    onChange={handleEditChange}
                  >
                    <option value="Daily">Daily</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Accrue Interest (Credit)</label>
                  <select
                    value={details.creditAccrueInterest}
                    className={`${
                      errorEdit.creditAccrueInterest ? "error-input-z" : ""
                    }`}
                    name="creditAccrueInterest"
                    onChange={handleEditChange}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Balance Req For Credit Interest (Credit)</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="balanceRequestCreditInterest"
                    value={details.balanceRequestCreditInterest}
                    className={`${
                      errorEdit.balanceRequestCreditInterest
                        ? "error-input-z"
                        : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>Interest Liquidation Period</label>
                  <select
                    value={details.interestLiquidationPeriod}
                    name="interestLiquidationPeriod"
                    onChange={handleEditChange}
                  >
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Gateway Credit Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.gateWayCreditAccount}
                      className={`${
                        errorEdit.gateWayCreditAccount ? "error-input-z" : ""
                      }`}
                      name="gateWayCreditAccount"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.gateWayCreditAccount && (
                      <ErrorMessage message={errorEdit.gateWayCreditAccount} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Gateway Debit Account</label>
                  <div className="error-container-z">
                    <select
                      value={details.gateWayDebitAccount}
                      className={`${
                        errorEdit.gateWayDebitAccount ? "error-input-z" : ""
                      }`}
                      name="gateWayDebitAccount"
                      onChange={handleEditChange}
                    >
                      {filterGlAccount.map((items, index) => {
                        return (
                          <option key={index} value={items.accountNumber}>
                            {items.accountName} - {items.accountNumber}
                          </option>
                        );
                      })}
                    </select>
                    {errorEdit.gateWayDebitAccount && (
                      <ErrorMessage message={errorEdit.gateWayDebitAccount} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Membership Fee</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="membershipFee"
                    value={details.membershipFee}
                    className={`${
                      errorEdit.membershipFee ? "error-input-z" : ""
                    }`}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Deductions</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="deductions"
                    value={details.deductions}
                    className={`${errorEdit.deductions ? "error-input-z" : ""}`}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Minimum Contribution</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="minimumContribution"
                    value={details.minimumContribution}
                    className={`${
                      errorEdit.minimumContribution ? "error-input-z" : ""
                    }`}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Tenure Type</label>
                  <select
                    value={details.tenureType}
                    className={`${errorEdit.tenureType ? "error-input-z" : ""}`}
                    name="tenureType"
                    onChange={handleEditChange}
                  >
                    <option>Please, Select An Option</option>
                    <option value="None">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Tenure For Contribution</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="tenureForContribution"
                    value={details.tenureForContribution}
                    className={`${
                      errorEdit.tenureForContribution ? "error-input-z" : ""
                    }`}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Base Allowable Contribution</label>
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="baseAllowableContribution"
                    value={details.baseAllowableContribution}
                    className={`${
                      errorEdit.baseAllowableContribution ? "error-input-z" : ""
                    }`}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              {createCustomerRestriction.map((item, index) => (
                <Grid
                  container
                  spacing={1}
                  className={classes.item}
                  key={index}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <label>Category Code</label>
                    <select
                      value={item.customerCategory}
                      name="customerCategory"
                      onChange={(e) =>
                        handleEditCustomerRestrictionChange(e, index)
                      }
                    >
                      <option>Select An Option</option>
                      {cusCategory.map((item, i) => (
                        <option
                          key={item.id}
                          className={
                            displayErr(i, "category") ? "error-input-z" : null
                          }
                          name={item.category}
                          value={item.category}
                        >
                          {item.category}
                        </option>
                      ))}
                    </select>
                    {displayErr(index, "category") && (
                      <ErrorMessage
                        message={
                          errorsOfOtherServicesOtherthanProductDetails.msg
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <label>Category Description</label>
                    <input
                      value={item.customerName}
                      name="customerName"
                      disabled
                      onChange={(e) =>
                        handleEditCustomerRestrictionChange(e, index)
                      }
                      className={
                        displayErr(index, "customerName")
                          ? "error-input-z"
                          : null
                      }
                    />
                    {displayErr(index, "customerName") && (
                      <ErrorMessage
                        message={
                          errorsOfOtherServicesOtherthanProductDetails.msg
                        }
                      />
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={6}>
                      <button
                        disabled={
                          details.createCustomerRestriction.length < 2
                        }
                         onClick={() => handleEditSubmit(index,"customer restriction")}
                      >
                        delete
                      </button>
                  </Grid> */}
                </Grid>
              ))}
            </TabPanel>
          </div>
        </>
      </div>
    </EditModal>
  );
};

export default EditData;

import React from 'react'

const ChequeWithdrawal = () => {
    return (
        <div className=''>
            <h1>qwert qwerty</h1>
        </div>
    )
}

export default ChequeWithdrawal

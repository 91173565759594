import { useState, useEffect, useCallback } from "react";
import Details from "../../../Details/CoreModules/ChartOfAccount"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import {baseUrl} from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date"

const ChartOfAccount = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "glType",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "glHierarchy",
      label: "Account Hierarchy",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
            <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };



  const fetchAllBankCalendar = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}account/GLAccount/GetUnAuthorized`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].createdDateTime = formattedDate(response.data[i].createdDateTime);
        response.data[i].dateAuthorized = formattedDate(response.data[i].dateAuthorized);
        response.data[i].updatedDateTime = formattedDate(response.data[i].updatedDateTime);
      };
    };
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllBankCalendar();
  }, [fetchAllBankCalendar, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Chart of Account"
        subtitle=""
        name="List of chart of account"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer
        title="Chart of Account - Record Details"
      >
       <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize Chart of Account"
        question="Are you sure you want to authorize this account?"
        url={`${baseUrl}account/GLAccount/Authorize/${details.id}`}
      />
    </div>
  );
};

export default ChartOfAccount;

import { useState, useCallback,useEffect } from "react";
import "./LoanRepaymentDueReport.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { getAllRepaymentDueReport, getBranchCode } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import ErrorMessage from "../../../../ErrorMessage";
import { formattedDate } from "../../../../../formatter/date";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../../Api/redux-manager/actions";

const LoanRepaymentDueReport = () => {
  const dispatch = useDispatch(isLoading());
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [errors, setErrors] = useState({});
  const loading = useSelector((state) => state.req.loading);
const [branchesCode, setBranchesCode] = useState([])
  const [dueReport, setDueReport] = useState({
    startDate: "",
    endDate: "",
    branchCode: "",
  });

  const [repaymentDueReport, setRepaymentDueReport] = useState(null);
 
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccountNo",
      label: "Loan Accnt. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanProduct",
      label: "Loan Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "repaymentAccountNo",
      label: "Repayment Accnt. No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanCurrency",
      label: " Loan Currency",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "bookedDate",
      label: "Loan Book Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "startDate",
      label: "Loan Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "loanAmount",
      label: "Loan Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amountDisbursed",
      label: "Amount Disbursed",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tenor",
      label: "Tenor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "nextRepaymentDueDate",
      label: " Repayment Due Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "expectedPrincipalRepaymentAmount",
      label: " Expected Principal Repayment Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "expectedInterestRepaymentAmount",
      label: " Expected Interest Repayment Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "expectedTotalRepaymentAmount",
      label: " Expected Total Repayment Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const handleSubmit = () => {
    const checkErrors = validateData();
    if (checkErrors) {
      fetchRepaymentDueReport();
      console.log("i am livid");
    }
    console.log("i am believer");
  };
  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { startDate, endDate, branchCode } = dueReport;
    if (startDate === "") {
      error.startDate = "Field is required";
      errorCount = errorCount + 1;
    }
    if (endDate === "") {
      error.endDate = "Field is required";
      errorCount = errorCount + 1;
    }
    if (branchCode === "") {
      error.branchCode = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };
  const handleChanges = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setDueReport({
      ...dueReport,
      [name]: value,
    });
  };
  const resetHandler = () => {
    EmptyField();
    setRepaymentDueReport(false);
  };

  const EmptyField = () => {
    setDueReport({
      branchCode: "",
      startDate: "",
      endDate: "",
    });
  };

  const fetchBranchCode = useCallback(async () => {
    const response = await getBranchCode(token,branchesCode);
    if (response && response.data){
        setBranchesCode(response.data)
      }  
  }, [token,branchesCode])
  useEffect(() => {
    fetchBranchCode();
  }, [])

  const fetchRepaymentDueReport = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllRepaymentDueReport(token, dueReport);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setRepaymentDueReport(response.data);
    }
  }, [dueReport, token, dispatch]);

  return (
    <div className="LoanRepaymentDue border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p>Reports | Loan Repayment Due Report</p>
      </div>

      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Branch Code</label>
            <select
              type="text"
              name="branchCode"
              value={dueReport.branchCode}
              onChange={handleChanges("branchCode")}
            >
              <option >Select a branch code</option>
              {branchesCode.map((codes,i) => (
                <option value={codes.code} key={i}>{codes.code}</option>
              ))}
            </select>
            {errors.branchCode && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={dueReport.startDate}
              onChange={handleChanges("startDate")}
            ></input>
            {errors.startDate && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input
              type="date"
              name="endDate"
              value={dueReport.endDate}
              onChange={handleChanges("endDate")}
            ></input>
            {errors.endDate && <ErrorMessage message={errors.endDate} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSubmit}>Search</button>
          <button onClick={resetHandler}>Reset</button>
        </div>
        <hr />
        {repaymentDueReport && (
          <div>
            <Spinner loading={loading} />
            <Table
              title="Loan Repayment Due Report"
              subtitle=""
              name="List of Loan Repayment Due Reports"
              records={repaymentDueReport}
              options={options}
              columns={columns}
            />
            {/* {details && details.id && <Details details={details} />}
          {details && details.id && (
            <EditForm
              details={details}
              removeSignatory={removeSignatory}
              addSignatory={addSignatory}
            />
          )} */}
          </div>
        )}
      </main>
    </div>
  );
};

export default LoanRepaymentDueReport;

import { Grid } from "@material-ui/core";
import Item from "../../DatagridTable/item";

const ParameterSetting = ({details}) => {
  return (
    <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item label="Parameter Name" name={details.parameterName} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item label="Parameter Value" name={details.parameterValue} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item label="Parameter Length" name={details.parameterLength} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item label="Create Date Time" name={details.createdDateTime} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item label="Updated Date Time" name={details.updatedDateTime} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item label="Date Authorized" name={details.dateAuthorized} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item
        label="Status"
        name={
          details.authorizationStatus === "PendingEditedAuthorization"
            ? "Pending Edited Authorization"
            : details.authorizationStatus
        }
      />
    </Grid>
  </Grid>
  );
};

export default ParameterSetting;

import axios from 'axios';
import { Routes } from '../../configUrl';


const createNewAccountForExistingMember = async (token, body) => {
    return await axios({
        method: "post",
        url: `${Routes.generateNewAccountForExistingCustomer}`,
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`,
        },
        data: body,
    })
    .then((res) => {
        return{
            status: res.data.status,
            message: res.data.responseMessage,
            statusCode: res.data.statusCode,
            data: res.data.data,
        };
    })
    .catch((err) => {
        return{
            status: err.response.data.status,
            message: err.response.data.responseMessage,
            statusCode: err.response.data.statusCode,
            data: [],
        };
    });
};


const InitiateContribution = async (token, body) => {
    return await axios({
        method: "post",
        url: `${Routes.startContribution}`,
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`,
        },
        data: body,
    })
    .then((res) => {
        return{
            status: res.data.status,
            message: res.data.responseMessage,
            statusCode: res.data.statusCode,
            data:res.data.data,
        };
    })
    .catch((err) => {
        return{
            status: err.response.data.status,
            message: err.response.data.responseMessage,
            statusCode: err.response.data.statusCode,
            data:[],
        };
    });
}

export {
    createNewAccountForExistingMember,
    InitiateContribution,
};
import { useState, useEffect } from "react";
// import { validateLogin } from './Validate'
import { useHistory } from "react-router-dom";
import {
  attemptLogin,
  logoutUser,
  getPermissionsFromUserObj,
  getUserDetails,
  getBranchByCode,
  getAllCountries,
  getAllBranches,
  getAllTellerTills,
  clearCommon,
  loaderON,
  loaderOFF,
} from "../redux-manager/actions";
import { useDispatch } from "react-redux";
import { validateLogin } from "../validator/validate11";
import axios from "axios";
import { baseUrl } from "../configUrl";
import { encryptAES } from "../../Helper/masking";

const useCustom = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    emailOrUserName: "",
    password: "",
  });

  const [errorLogin, setErrorLogin] = useState(false);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
    } else {
    }
  }, [errors]);

  const speak = (msg) => {
    const sp = new SpeechSynthesisUtterance(msg);
    [sp.voice] = speechSynthesis.getVoices();
    speechSynthesis.speak(sp);
  };


  // const timeOut =  new Date().getTime() + 1642831265;
  // const timeOut =  new Date().getTime() + 600000;

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setErrorLogin(false);
    setErrors(validateLogin(values));
    console.log(Object.keys(validateLogin(values)).length, "adsd");
    if (Object.keys(validateLogin(values)).length > 0) {
      return;
    }
    // dispatch(loaderON())
    setLoader((prevState) => (prevState = true));

    const iv = process.env.REACT_APP_PUBLIC_IV;
    const key = process.env.REACT_APP_SECRETKEY;
    const encryptedPassword = await encryptAES(values?.password, key, iv);
    let data = { ...values, password: encryptedPassword };
    try {
      const response = await axios.post(
        `${baseUrl}user/UserAccess/Authenticate`,
        data
      );
      setMessage(response.data.data.responseMessage);
      if (response.data.statusCode === 200) {
        dispatch(loaderON()); // done to stall application before data is fetched
        setErrorLogin((state) => (state = false));
        // console.log(user, "user details at login")
        //speak(`Welcome ${response.data.data.firstName} ${response.data.data.lastName} to nobleserve core banking application, we aim to make your work very easy`)
        dispatch(
          getPermissionsFromUserObj(
            response.data.data.rolePermission.permissions ?? []
          )
        );
        dispatch(getUserDetails(response.data.data));
        dispatch(attemptLogin());
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.jwtToken}`;
        let user = JSON.stringify(response.data.data);
        dispatch(getAllCountries(response.data.data.jwtToken));
        dispatch(getAllBranches(response.data.data.jwtToken));
        dispatch(getAllTellerTills(response.data.data.jwtToken));
        dispatch(
          getBranchByCode(
            response.data.data.jwtToken,
            response.data.data.operatingBranch
          )
        );
        dispatch(loaderOFF()); // done to stall application before data is fetched
        // let timeOut = new Date().getTime() + 28800000;
        // let timeOut = new Date().getTime() + 1642831265;

        localStorage.setItem("user", user);
        // localStorage.setItem("timeOut", timeOut);
        history.push("/dashboard/home");
        // console.log(new Date().getTime() + 28800000, "here is timeout")
      } else {
        setErrorLogin(true);
        setLoader(false);
      }
    } catch (err) {
      setMessage(
        err.response
          ? err.response.data.responseMessage
          : "Please check your network connection and try again"
      );
      setErrorLogin(true);
    } finally {
      setLoader(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("timeOut");
    dispatch(logoutUser());
    dispatch(clearCommon());
    history.push("/");
    // window.location.reload()
  };

  return {
    handleChange,
    loader,
    errors,
    values,
    handleLogout,
    handleLoginSubmit,
    errorLogin,
    message,
  };
};

export default useCustom;

import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/CoreModules/ParameterSettings";

const View = ({details}) => {
    return (
        <DetailsContainer        
        title="Parameter Settings- Record Details"
        question="Are you sure you want to delete this parameter?"
        url={`${baseUrl}organization/BankParameters/Delete/${details.id}`}
      >
       <Details details={details} />
      </DetailsContainer>
    )
}

export default View

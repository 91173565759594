import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/CoreModules/FiscalYear";

const View = ({details}) => {
    return (
        <DetailsContainer        
        title="Fiscal Year- Record Details"
        question="Are you sure you want to delete this Fiscal year?"
        url={`${Routes.deleteFiscalYear}${details.id}`}
      >
        <Details details={details}/>
      </DetailsContainer>
    )
}

export default View

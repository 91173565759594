import axios from 'axios';
import { Routes } from '../../configUrl';


const institutionType = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getInstitutionType,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const institutionDefinition = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.instittutionDefination,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAllInstitutions = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllInstitutions,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const getAnInstitution = async (token, id) => {
    return await axios({
        method: "get",
        url: Routes.getAnInstitution + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const updateAnInstitution = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateAnInstitution + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const deleteAnInstitution = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteAnInstitution + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeInstitutionType = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeInstitutionType + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };


  const branchDefinition = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.branchDefinition,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAllBranches = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllBranches,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const getABranch = async (token, id) => {
    return await axios({
        method: "get",
        url: Routes.getABranch + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const editABranch = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.editABranch + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const authorizebranchDefinition = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizebranchDefinition + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };


  const deleteAbranch = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteAbranch + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const fiscalYear = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.fiscalYear,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAllFiscalYear = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllFiscalYear,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAFiscalYear = async (token, id) => {
    return await axios({
        method: "get",
        url: Routes.getAFiscalYear + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const updateAFiscalYear = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateAFiscalYear + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const deleteFiscalYear = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteFiscalYear + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeFiscalYear = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeFiscalYear + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const createRateChart = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createRateChart,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status
        };
      });
  };



  const getAllRateChart = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllRateChart,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getARateChart = async (token, id) => {
    return await axios({
        method: "get",
        url: Routes.getARateChart + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const updateRateChart = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateRateChart + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const deleteRateChart = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteRateChart + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeRateChart  = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeRateChart + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const createBankParameter = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createBankParameter,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getBankParameterByName = async (token,body) => {
    return await axios({
        method: "get",
        url: Routes.getBankParameterByName + body,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const createCustomerCategory = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createCustomerCategory,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAllCustomerCategory = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllCustomerCategory,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getACustomerCategory = async (token, id) => {
    return await axios({
        method: "get",
        url: Routes.getACustomerCategory + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const updateCustomerCategory = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.updateCustomerCategory + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const deleteCustomerCategory = async (token,id) => {
    return await axios({
        method: "delete",
        url: Routes.deleteCustomerCategory + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
        };
      });
  };

  const authorizeCustomerCategory = async (token,body,id) => {
    return await axios({
        method: "put",
        data: body,
        url: Routes.authorizeCustomerCategory + id,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };

  const getAllGlAccount = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllGlAccount,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[]
        };
      });
  };

  const getAllCurrencyDefinition = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllCurrencyDefinition,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const getGlAccountByAccountNumber = async (token,accNum) => {
    return await axios({
        method: "get",
        url: Routes.getGlAccountByAccountNumber + accNum,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: []
        };
      });
  };

  const createChartOfAccount = async (token,body) => {
    return await axios({
        method: "post",
        data: body,
        url: Routes.createGLAccount,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status
        };
      });
  };

  const getHandler = async (token, url) => {
    return await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
        return {
          data:[],
        };
      });
  };

  const postHandler = async (token,body,url) => {
    return await axios({
        method: "post",
        data: body,
        url,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status
        };
      });
  };

export {
    institutionType,
    institutionDefinition,
    getAllInstitutions,
    getAnInstitution,
    updateAnInstitution,
    deleteAnInstitution,
    authorizeInstitutionType,
    branchDefinition,
    getAllBranches,
    getABranch,
    editABranch,
    deleteAbranch,
    authorizebranchDefinition,
    fiscalYear,
    getAllFiscalYear,
    getAFiscalYear,
    updateAFiscalYear,
    deleteFiscalYear,
    authorizeFiscalYear,
    getBankParameterByName,
    createRateChart,
    getAllRateChart,
    getARateChart,
    updateRateChart,
    deleteRateChart,
    authorizeRateChart,
    createBankParameter,
    createCustomerCategory,
    getAllCustomerCategory,
    getACustomerCategory,
    updateCustomerCategory,
    deleteCustomerCategory,
    authorizeCustomerCategory,
    getAllGlAccount,
    getAllCurrencyDefinition,
    getGlAccountByAccountNumber,
    getHandler,
    postHandler,
    createChartOfAccount,
}

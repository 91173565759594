import {useState} from "react";
import "./ManagePermission.scss";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import useTable from "../../../Tables/useTable";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

const ManagePermission = () => {
  const[permissions] = useState([]);

  const headCells = [
    { id: "id", label: "S/N" },
    { id: "role", label: "Role" },
    { id: "page", label: "Page" },
    { id: "canView", label: "Can View" },
    { id: "canEdit", label: "Can Edit" },
    { id: "canInsert", label: "Can Insert" },
    { id: "canDelete", label: "Can Delete" },
    { id: "authorize", label: "Auth.Status" },
    { id: "action", label: "Action", disableSorting: true },
  ];
  //eslint-disable-next-line
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(permissions, headCells, filterFn);

  const handleViewAll = () =>{

  };

  return (
    <main className="manage--permission">
      <div className="headerdiv">
        <p>Manage Permissions</p>
      </div>
      <Paper style={{ marginTop: 20, padding: 25 }}>
        <section className="section--1">
          <div className="sub--section grid--1">
            <div className="label--input flex--3">
              <label>Role</label>
              <select className="currency">
                <option></option>
                <option></option>
              
              </select>
            </div>
            <div className="label--input flex--3">
              <label>Page</label>
              <select className="currency1">
                <option></option>
                <option></option>
              
              </select>
            </div>
          </div>
          <div className="spandiv">
            <div className="checkboxdiv">
            <span className="span">Can View</span>
            <input className="spanbox" type="checkbox" color="white" />
            </div>
            <div className="checkboxdiv">
            <span className="span">Can Edit</span>
            <input className="spanbox" type="checkbox" color="white" />
            </div>
              <div className="checkboxdiv">
            <span className="span">Can Insert</span>
            <input className="spanbox" type="checkbox" color="white" />
            </div>   
            <div className="checkboxdiv">
            <span className="span">Can Delete</span>
            <input className="spanbox" type="checkbox" color="white" />
            </div>
          </div>
          <div className="button">
            <button>Save</button>
          </div>
        </section>
        <section className="section--2">
          <hr />
          <span>
            <h4>Manage Permission</h4>
          </span>
          <div className="sub--section flex--2">
            <div className="main-filter flex--2">
              <input placeholder="Filter" />
              
              <button className="button--second">Remove Filter</button>
            </div>
          </div>
          <TblContainer>
              <TblHead />
              <tbody>
                {recordsAfterPagingAndSorting().map((investment, i) => (
                  <tr key={investment.id}>
                    <th scope="row">{i + 1}</th>
                    <td>{investment.userName.toUpperCase()}</td>
                    <td>{investment.firstName + " " + investment.lastName}</td>
                    <td>{investment.emailAddress}</td>
                    <td>{investment.countryCode}</td>
                    <td>{investment.stateName}</td>
                    <td>{investment.role}</td>
                    <td>{investment.operatingBranch}</td>
                    <td>{investment.authorize}</td>

                    <td>
                      <DotMenu>
                        <MenuItem>Edit</MenuItem>
                        <MenuItem>Delete</MenuItem>
                        <MenuItem onClick={() => handleViewAll()}>View</MenuItem>
                      </DotMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TblContainer>
            <TblPagination />
        </section>
      </Paper>
    </main>
  );
};
export default ManagePermission;

const BulkType = {
    SDMC: "SDMC",
    MCI: "MCI",
    MDMC: "MDMC",
  };

  const ModuleType = {
    CustomerAccount: "CustomerAccount",
    Loan: "Loan",
    Investment: "Investment",
    TellerTill: "TellerTill",
    //Vault,
    //TillAccount,
    GlAccount: "GLAccount", //
    Cash: "Cash",
    Interswitch: "Interswitch",
    Nibss: "Nibss",
    Paystack: "Paystack",
  };

  const TransactionType = {
    BCH: " BCH", //Buy Cash
    SCH: "SCH", //Sell Cash
    CDP: "CDP", //Cash Deposit
    CWH: "CWH", //Cash Withdrawal
    CHW: "CHW", //Cheque Withdrawal
    FTR: "FTR", //Fund Transfer
    MCI: "MCI", //Manager's Cheque Issuance
    MCL: "MCL", //Manager's Cheque Liquidation
    MTT: "MTT", //Miscallaneous Teller Transaction
    MTTP: "MTTP", //Miscallaneous Teller Transaction pay cash out
    MTTR: "MTTR", //Miscallaneous Teller Transaction receive cash
    LDM: "LDM", //Loan Disbursement
    LRP: "LRP", //Loan Repayment
    IVB: "IVB", //Investment Booking
    IVL: "IVL", //Investment Liquidation
    SDMC: "SDMC", //Single Debit Multiple Credit
    MDMC: "MDMC", //Multiple Debit Multiple Credit
  };

  const ClearingType = {
    Normal: "Normal",
    Express: "Express",
  };

  export {
    BulkType,
    ModuleType,
    TransactionType,
    ClearingType
  }
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, Button, Box, Modal } from "@mui/material";
import { MdOutlineError, MdCheckCircle } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
    formControl: {
      // margin: theme.spacing(1),
      width: "200px",
    },
    centerBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
      //width: 46,
      height: 46,
      // marginTop: 20,
      // marginRight: "2rem",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#D0021B",
      },
    },
    children: {
      padding: "1.5rem 0px",
    },
  }));

const BasicModal = ({ message, handleOpen, handleClose, open, result }) => {
    const classes = useStyles();
  
    return (
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "white",
              boxShadow: 24,
              padding: "2rem",
              justifyContent:"center",
              alignItems:"center"
            }}
          >
            <Grid container spacing={2} sx={{ justifyContent: "center", margin:"0 auto", alignItems: "center"}}>
              <>
                {result === false ? (
                  <Grid item>
                    <MdOutlineError color="#D0021B" size={60} />
                  </Grid>
                ) : (
                  <Grid item>
                    <MdCheckCircle color="#06940d" size={60} />
                  </Grid>
                )}
              </>
              <>
                {result === false ? (
                  <Grid item >
                    <Typography
                      id="modal-modal-description"
                      sx={{ marginTop: 2, color: "#D0021B", justifyContent: "center", alignItems: "center", textAlign: "center" }}
                    >
                      {message}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item sx={{ justifyContent: "center", alignItems: "center", margin: "0 auto"}}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ marginTop: 2, color: "#06940d", justifyContent: "center", alignItems: "center", textAlign: "center" }}
                    >
                      {message}
                    </Typography>
                  </Grid>
                )}
              </>
              <Grid item>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{
                    height: "40px",
                    bgcolor: "#D0021B",
                    color: "#fff",
                  }}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  };

  export default BasicModal;
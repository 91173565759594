import React, { useState } from "react";
import "./FundTransfer.scss";
import ErrorMessage from "../../../ErrorMessage";
import { isValidEmail, isValidPhoneNumber } from "../../../../Helper/validator";

const FundTransfer = () => {
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    fromAccountNumber: "",
    product: "",
    customerBvn: "",
    emailAddress: "",
    branch: "",
    currency: "",
    phone: "",
    toAccountNumber: "",
    amount: "",
    narration: "",
    charges: "",
    //   instrumentType: "",
  });

  const handleChanger = (e) => {
    console.log("e.target.value", e.target.value);
    console.log("e.target.name", e.target.name);
    //   const { name, value } = e.target;
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSave = () => {
    let checkError = isValidateForm(user);
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    // setScreenChange(true);
  };
  const isValidateForm = (user) => {
    let errors = {};

    if (!user.fromAccountNumber.trim()) {
      errors.fromAccountNumber = "Field is required";
    }
    if (!user.product.trim()) {
      errors.product = "Field is required";
    }
    if (!user.customerBvn.trim()) {
      errors.customerBvn = "Field is required";
    }
    if (!user.branch.trim()) {
      errors.branch = "Field is required";
    }
    if (!user.currency.trim()) {
      errors.currency = "Field is required";
    }
    if (!user.amount.trim()) {
      errors.amount = "Field is required";
    }
    if (!user.charges.trim()) {
      errors.charges = "Field is required";
    }
    if (!user.toAccountNumber.trim()) {
      errors.toAccountNumber = "Field is required";
    }
    if (!user.narration.trim()) {
      errors.narration = "Field is required";
    }
    if (!user.phone.trim() || !isValidPhoneNumber(user.phone)) {
      errors.phone = "Invalid Entry";
    }
    if (!user.emailAddress.trim() || !isValidEmail(user.emailAddress)) {
      errors.emailAddress = "Invalid Email";
    }
    return errors;
  };

  return (
    <main className="fundTransfer--fcy">
      <section className="section--1">
        <div className="heading">
          <p>Fund Transfer - Foreign Currency</p>
        </div>
        <div className="flex--3">
          <>
          <div style={{ padding: 25 }}>
            <section className="sub-section">
              <div className="flex--1 container-main">
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>From Account Number</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="fromAccountNumber"
                        value={user.fromAccountNumber}
                        className={`${
                          error.fromAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="fromAccountNumber"
                        value={user.fromAccountNumber}
                        className={`${
                          error.fromAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                  </div>
                  {error.fromAccountNumber && (
                    <ErrorMessage message={error.fromAccountNumber} />
                  )}
                  <div className="flex--3">
                    <label>Product </label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="product"
                      value={user.product}
                      className={`${error.product && "error-input-z"}`}
                    />
                  </div>
                  {error.product && <ErrorMessage message={error.product} />}
                  {/* <div className="flex--3">
                    <label>Customer BVN</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="customerBvn"
                      value={user.customerBvn}
                      className={`${error.customerBvn && "error-input-z"}`}
                    />
                    {error.customerBvn && (
                      <ErrorMessage message={error.customerBvn} />
                    )}
                  </div> */}
                  {/* <div className="flex--3">
                    <label>Email Address</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="emailAddress"
                      value={user.emailAddress}
                      className={`${error.emailAddress && "error-input-z"}`}
                    />
                    {error.emailAddress && (
                      <ErrorMessage message={error.emailAddress} />
                    )}
                  </div> */}
                </div>
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Branch </label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="branch"
                        value={user.branch}
                        className={`${error.branch && "error-input-z"}`}
                        />
                        {error.branch && <ErrorMessage message={error.branch} />}
                    </div>
                    <div className="sub-input">
                    <label>Currency </label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="currency"
                        value={user.currency}
                        className={`${error.currency && "error-input-z"}`}
                      />
                    </div>
                  </div>
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                    <label>Customer BVN </label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="customerBvn"
                      value={user.customerBvn}
                      className={`${error.customerBvn && "error-input-z"}`}
                    />
                    {error.customerBvn && (
                      <ErrorMessage message={error.customerBvn} />
                    )}
                    </div>
                    <div className="sub-input">
                    <label>Amount</label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="amount"
                      value={user.amount}
                      className={`${error.amount && "error-input-z"}`}
                    />
                    {error.amount && <ErrorMessage message={error.amount} />}
                    </div>
                  </div>
                  {error.currency && <ErrorMessage message={error.currency} />}
                  {/* <div className="flex--3">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      onChange={handleChanger}
                      name="phone"
                      value={user.phone}
                      className={`${error.phone && "error-input-z"}`}
                    />
                    {error.phone && <ErrorMessage message={error.phone} />}
                  </div> */}
                </div>
              </div>
              <div className="flex--1 container-main">
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>To Account Number</label>
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="toAccountNumber"
                        value={user.toAccountNumber}
                        className={`${
                          error.toAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                    <div className="sub-input">
                      <input
                        type="number"
                        onChange={handleChanger}
                        name="toAccountNumber"
                        value={user.toAccountNumber}
                        className={`${
                          error.toAccountNumber && "error-input-z"
                        }`}
                      />
                    </div>
                  </div>
                  {error.toAccountNumber && (
                    <ErrorMessage message={error.toAccountNumber} />
                  )}
                  <div className="flex--3">
                    <label>Product</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="product"
                      value={user.product}
                      className={`${error.product && "error-input-z"}`}
                    />
                  </div>
                  {error.product && <ErrorMessage message={error.product} />}
                  <div className="flex--3">
                  <label>Charges</label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="charges"
                      value={user.charges}
                      className={`${error.charges && "error-input-z"}`}
                    />
                    {error.charges && <ErrorMessage message={error.charges} />}
                  </div>
                  {/* <div className="flex--3">
                    <label>Charges</label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="charges"
                      value={user.charges}
                      className={`${error.charges && "error-input-z"}`}
                    />
                    {error.charges && <ErrorMessage message={error.charges} />}
                  </div> */}
                  {/* <div className="flex--3">
                    <label>Total Amount</label>
                    <input
                    type="number" 
                    step="any"
                    />
                    {error.charges && <ErrorMessage message={error.charges} />}
                  </div> */}
                </div>
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Branch</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="branch"
                        value={user.branch}
                        className={`${error.branch && "error-input-z"}`}
                      />
                    </div>
                    <div className="sub-input">
                    <label>Currency </label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="currency"
                        value={user.currency}
                        className={`${error.currency && "error-input-z"}`}
                      />
                    </div>
                  </div>
                  {error.branch && <ErrorMessage message={error.branch} />}
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                    <label>Customer BVN</label>
                    <input
                      type="text"
                      onChange={handleChanger}
                      name="customerBvn"
                      value={user.customerBvn}
                      className={`${error.customerBvn && "error-input-z"}`}
                    />
                    {error.customerBvn && (
                      <ErrorMessage message={error.customerBvn} />
                    )}
                    </div>
                    <div className="sub-input">
                    <label>Net Amount </label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="amount"
                      value={user.amount}
                      className={`${error.amount && "error-input-z"}`}
                    />
                    {error.amount && <ErrorMessage message={error.amount} />}
                    </div>
                  </div>
                  {error.currency && <ErrorMessage message={error.currency} />}
                  {/* <div className="flex--3">
                    <label>Amount111</label>
                    <input
                      type="number" 
                      step="any"
                      onChange={handleChanger}
                      name="amount"
                      value={user.amount}
                      className={`${error.amount && "error-input-z"}`}
                    />
                    {error.amount && <ErrorMessage message={error.amount} />}
                  </div> */}
                  <div className="flex--3">
                  <label>Total Amount 
                  </label>
                    <input
                    type="number" 
                    step="any"
                    />
                  </div>
                </div>
              </div>
              <div className="last-input">
              <label>Email</label>
                <input
                 
                  type="text"
                  onChange={handleChanger}
                  name="emailAddress"
                  value={user.emailAddress}
                  className={`${error.emailAddress && "error-input-z"}`}
                />
                {error.emailAddress && (
                  <ErrorMessage message={error.emailAddress} />
                )}
              </div>
              <div className="last-input">
              <label>Narration</label>
                <input
                 
                  type="text"
                  onChange={handleChanger}
                  name="narration"
                  value={user.narration}
                  className={`${error.narration && "error-input-z"}`}
                />
                {error.narration && <ErrorMessage message={error.narration} />}
              </div>
            </section>
      </div>

            <div className="button-last">
              <button onClick={handleSave}>Save</button>
            </div>
          </>
        </div>
      </section>
    </main>
  );
};

export default FundTransfer;

import { baseUrl, Routes } from "../../../Api/configUrl";
import DetailsContainer from "../../DatagridTable/viewCurrencyDetails";
import Details from "./Details";

const View = ({ details, userId }) => {

  return (
    <DetailsContainer
      title="Cash Balance || List of Denominations"
      question="Are you sure you want to delete this products?"
      url = {`${baseUrl}account/TellerTill/GetCurrentUserCashBalance/${userId}`}
    >
      <Details details={details} userId={userId}/>
    </DetailsContainer>
  );
};

export default View;
import axios from 'axios';
import { Routes } from '../../configUrl';

const newPasswords = async (user) => {
    return await axios({
        method: "post",
        url: Routes.newPassword,
        headers: {
          "Content-Type":  "application/json"
        },
        data: user
      })
      .then((res) => {
        console.log('..........',res);
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err.response)
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  

export {newPasswords}

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import Details from "../../../Details/CoreModules/BankCalendar";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { baseUrl} from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date"

const BankCalender = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bankId",
      label: "Bank ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "holiday",
      label: "Holiday",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startTime",
      label: "Start Time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "closeTime",
      label: "Close Time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
            <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
          console.log(item)
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };

  const getMonth = (i) => {
    const index = parseInt(i) - 1;
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    return months[index]
  }


  const fetchAllBankCalendar = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}organization/BankHoliday/GetUnAuthorized`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
      if (response.data.length > 0){
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].month = getMonth(response.data[i].month);
          response.data[i].createdDateTime = formattedDate(response.data[i].createdDateTime);
          response.data[i].dateAuthorized = formattedDate(response.data[i].dateAuthorized);
          response.data[i].updatedDateTime = formattedDate(response.data[i].updatedDateTime);
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllBankCalendar();
  }, [fetchAllBankCalendar, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Bank Calender"
        subtitle=""
        name="List of bank calenders"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer
        title="Bank Calender - Record Details"
      >
        <Details details={details} />
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize bank calender"
        question="Are you sure you want to authorize this date?"
        url={`${baseUrl}organization/BankHoliday/Authorize/${details.id}`}
      />
    </div>
  );
};

export default BankCalender;

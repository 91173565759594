import React from "react";
import "./McIssuance.scss";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { AiTwotoneDelete } from 'react-icons/ai';

const McIssuance = () => {
  return (
    <div className="mc--issuance">
      <div className="headerdiv">
        <p>Managers Cheque- Issuance</p>
      </div>
      <Paper style={{ marginTop: 20, padding: 25 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <label>Issuance Type</label>
            <select>
              <option></option>
              <option></option>
            </select>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <label>Account Number</label>
            <input type="number" style={{width:30}}/>
          </Grid>
          <Grid item xs={12} sm={2} md={4}>
            <label style={{color:'white'}}>Account Number</label>
            <input type="number" />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <label>Account Branch</label>
            <input type="number" style={{width:30}}/>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <label style={{color:'white'}}>Account Number</label>
            <input type="number" />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Account Currency</label>
            <input type="number" style={{width:30}}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Amount</label>
            <input type="number" style={{width:30}}/>
            <div className="checboxdiv">
              <input type="checkbox" className="productcheckbox" />
              <label className="span">Walve</label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Net Amount</label>
            <input type="number" style={{width:30}}/>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}>
          <div className="radiodiv">
              <span>Transaction Type:</span>
             <input type="radio" className="productradio" name="type" value='tci' style={{marginLeft:10}} />
             <label className="span">TCI</label>
             <input type="radio" className="productradio" name='type' value='tbi' style={{marginLeft:20}}/>
             <label className="span">TBI</label>
          </div>
          </Grid>
          <Grid  item xs={12} sm={6} md={6}></Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>MC Issue Date</label>
            <input type='date'/>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
              <label>Charge Account</label>
            <input type='type' style={{width:30}}/>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <label style={{color:'white'}}>MC Issue Date</label>
            <input type='type'/>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label >Narration</label>
            <input type='type'/>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label >No of Drafts</label>
            <input type='type'/>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {/* <label style={{color:'white'}}>MC Issue Date</label> */}
            
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <label style={{color:'white'}}>MC Issue Date</label>
            <button className='addbtn'>Add+</button>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={12}>
        <div className='tablediv'>
            <table>
                <thead>
                    <tr>
                        <th>Beneficiary Name</th>
                        <th>Beneficiary Address</th>
                        <th>MC Amount</th>
                    </tr>
                </thead>
                <hr style={{width:'335%'}}/>
                <tbody>
                    <tr>
                        <td><input type='text' /> </td>
                        <td><input type='text' /> </td>
                        <td><input type='number' /> </td>
                        <td>
                                <div className='table--icon'>
                                    <AiTwotoneDelete style={{width:50,}}/>
                                </div>
                            </td>
                    </tr>
                </tbody>

            </table>
            <section>
                <div className='totaldiv'>
                <span>Total:</span>
                <input type='number'style={{width:30}}/>
                </div>
            </section>

           </div>
           </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default McIssuance;

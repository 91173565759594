import Item from "../../DatagridTable/item";
import { Grid } from "@material-ui/core";

const BranchTeller = ({details}) => {
    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Number" name={details.accountNumber} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Branch Code" name={details.branchId} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Currency" name={details.currencyCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Till Id" name={details.tillId} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized}/>
        </Grid>
        {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Reason" name={details.reason} />
        </Grid>}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
        </Grid>
      </Grid>

    );
};

export default BranchTeller

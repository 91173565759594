import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./AccountsOpenedToday.scss";
import Table from "../../../../DatagridTable/table";
import { isLoading } from "../../../../../Api/redux-manager/actions";
import Spinner from "../../../../Backdrop/index";
import { getAccountsOpened } from "../../../../../Api/Hooks-manager/accountsOpenedToday/accountsOpenedToday";
import { CSVLink } from "react-csv";
// import { formattedDate } from "../../../../../formatter/date";

const AccountsOpenedToday = () => {
  const dispatch = useDispatch();
  // const [details, setDetails] = useState({});
  const loading = useSelector((state) => state.approve.loading);
  // const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountType",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchCode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "accountProductCode",
      label: "Account Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "accountBalance",
      label: "Account Balance",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "openingBalance",
      label: "Opening Balance",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "customerId",
      label: "Customer ID",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "accountstatus",
      label: "Account Status",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "processDate",
      label: "Process Date",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "createdDateTime",
      label: "Created Date/Time",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const [allAccountsOpened, setAllAccountsOpened] = useState([]);
  const [data, setData] = useState({
    From: "",
    To: "",
  });

  const headers = [
    { key: "accountNumber", label: "Account Number" },
    { key: "accountName", label: "Account Name" },
    { key: "accountType", label: "Account Type" },
    { key: "currency", label: "Currency" },
    { key: "branchCode", label: "Branch Code" },
    { key: "accountProductCode", label: "Account Product Code" },
    { key: "accountBalance", label: "Account Balance" },
    { key: "openingBalance", label: "Opening Balance" },
    { key: "customerId", label: "Customer ID" },
    { key: "accountstatus", label: "Account Status" },
    { key: "processDate", label: "Process Date" },
    { key: "createdDateTime", label: "Created Date/Time" },
  ];

  useEffect(() => {
    setTimeout(() => {
      dispatch(isLoading());
    }, 2000);
  }, [dispatch]);

  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleReset = () => {
    setData({
      From: "",
      To: "",
    });
    setAllAccountsOpened([]);
  };

  // const handleReset = () => {
  // }
  const fetchAllAccountOpened = useCallback(async () => {
    dispatch(isLoading());

    const response = await getAccountsOpened(token, data);
    console.log(response);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          // response.data[i].createdDateTime = formattedDate(
          //   response.data[i].createdDateTime
          // );
          // response.data[i].dateAuthorized = formattedDate(
          //   response.data[i].dateAuthorized
          // );
          // response.data[i].updatedDateTime = formattedDate(
          //   response.data[i].updatedDateTime
          // );
        }
      }
      setAllAccountsOpened(response.data);
    }
    //setAccountData()
  }, [data, token, dispatch]);

  useEffect(() => {
    if (data.From !== "" && data.To !== "") {
      fetchAllAccountOpened();
    }
  }, [data.From, data.To]);

  return (
    <div className="AccountsOpenedToday border--main">
      <div style={{ position: "absolute" }}>
        <Spinner load={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Account Opened Report</p>
      </div>
      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">From</label>
            <input
              type="date"
              name="From"
              value={data.From}
              onChange={handleChange("From")}
            />
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">To</label>
            <input
              type="date"
              value={data.To}
              name="To"
              onChange={handleChange("To")}
            />
          </div>
        </div>

        <div className="button--1 flex--1">
          {/* <button onClick={fetchAllAccountOpened}> Search</button> */}
          <button onClick={handleReset}>Reset</button>
        </div>

        {allAccountsOpened.length > 0 && (
          <div>
            {!loading && (
              <Table
                title="Accounts"
                subtitle=""
                name="List of Account"
                records={allAccountsOpened}
                options={options}
                columns={columns}
              />
            )}
          </div>
        )}

        {allAccountsOpened.length > 0 && (
          <div className="button--2">
            <CSVLink
              data={allAccountsOpened}
              headers={headers}
              filename={"AccountOpeningReport.csv"}
              className="download--button"
            >
              Download
            </CSVLink>
          </div>
        )}
      </main>
    </div>
  );
};

export default AccountsOpenedToday;

import { useState } from "react";
import "./TransactionSummary.scss";
import { useDispatch, useSelector } from "react-redux";
// import MenuItem from '@material-ui/core/MenuItem';
import { BiErrorCircle } from "react-icons/bi";
import Spinner from "../../../../Backdrop";
import ErrorMessage from "../../../../ErrorMessage";

// import useTable from "../../../../Tables/useTable";
// import { BsEyeFill } from "react-icons/bs";
// import { AiTwotoneDelete } from "react-icons/ai";
import { format } from "date-fns";
// import { MdFileDownload } from "react-icons/md";
// import DotMenu from "../../../../../commons/dotMenu/dotMenu";
import MUIDataTable from "mui-datatables";
import {
  isLoading,
  openViewModal,
} from "../../../../../Api/redux-manager/actions";
import { columns } from "./tableConfig";
import { getTransactionSummary } from "../../../../../Api/Hooks-manager/reports/transactionSummary";
// import Details from "./ModalView";

const TransactionSummary = () => {
  const dispatch = useDispatch(isLoading());
  const loading = useSelector((state) => state.req.loading);
  const branch = useSelector((state) => state.common.branch);
  const branchCode = useSelector((state) => state.common.branch.code);
  const allBranches = useSelector((state) => state.common.allBranches);
  const userId = useSelector((state) => state.common.userDetails.id);
  const userName = useSelector((state) => state.common.userDetails.userName);

  console.log(branchCode)
  // const common = useSelector((state) => state.common);
  const applicationDate = format(new Date(branch.workdate), "yyyy-MM-dd");
  const [inputData, setInputData] = useState({
    startDate: applicationDate,
    endDate: applicationDate,
    entryBranch: branchCode,
    referenceNumber: "",
    accountNumber: "",
    // debitAccount: "", 
    // creditAccount: "",
  });
  const [errors, setErrors] = useState({});
  // const [details, setDetails] = useState({});
  const [transactionData, setTransactionData] = useState(null);

  // console.log(common)

  const validateData = () => {
    const error = {};
    // if(!inputData.entryBranch){
    //   error.entryBranch = 'Please enter a branch'
    // }
    if (!inputData.endDate) {
      error.date = "Please select a date";
    }
    if (!inputData.startDate) {
      error.date = "Please select a date";
    }
    // if(inputData.debitAccount){
    //   error.debitAccount = 'Please enter a valid account number'
    // }
    // if(inputData.creditAccount){
    //   error.creditAccount = 'Please enter a valid account number'
    // }
    // if(!inputData.referenceNumber){
    //   error.referenceNumber = 'Please a transaction reference number'
    // }

    setErrors(error);
    if (Object.keys(error).length > 0) {
      return false;
    } else return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setInputData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setInputData({
      startDate: applicationDate,
      endDate: applicationDate,
      entryBranch: branchCode,
      referenceNumber: "",
      accountNumber: "",
      // debitAccount: "",
      // creditAccount: "",
    });
  };

  console.log(inputData);

  const handleSubmit = async () => {
    const postData = { ...inputData };
    if (!postData.entryBranch) {
      postData.usedId = userId;
    }

    const isValid = validateData();
    if (loading || !isValid) {
      return;
    }
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(isLoading());
    const { data } = await getTransactionSummary(user.jwtToken, postData);
    // const filteredDataByUserID = data.filter((item) => (
    //   item.createdBy === userName ||
    //   item.createdBy === null

    //   )

    //   )
    dispatch(isLoading());
    if(postData.accountNumber){
      const filteredData =  data.filter((record) => (
        record.creditAccount === postData.accountNumber 
        || record.debitAccount === postData.accountNumber
        )
        )
        console.log(filteredData, "filteredData");
        setTransactionData(filteredData);
        return;
      }
      console.log(data, "dataasss");

    setTransactionData(data);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = transactionData[index];
      if (item && item.id) {
        // setDetails(item);
        dispatch(openViewModal());
      }
    },
  };
  return (
    <div className="TransactionSummary border--main">
      <Spinner loading={loading} />
      <div className="headerdiv">
        <p> Reports | Transaction Summary</p>
      </div>

      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="startDate">
              Start Date <span className="required-tag">*</span>
            </label>
            <input
              type="date"
              name="startDate"
              id="startDate"
              value={inputData.startDate}
              onChange={handleChange}
              className={errors.startDate ? "error-input-z" : null}
            />
            {errors.date && <ErrorMessage message={errors.startDate} />}
          </div>

          <div className=" sub-main--1 flex--3">
            <label htmlFor="endDate">
              End Date <span className="required-tag">*</span>
            </label>
            <input
              type="date"
              name="endDate"
              id="endDate"
              value={inputData.endDate}
              onChange={handleChange}
              className={errors.endDate ? "error-input-z" : null}
            />
            {errors.date && <ErrorMessage message={errors.endDate} />}
          </div>

          <div className=" sub-main--1 flex--3">
            <label htmlFor="entryBranch">Branch Code</label>
            <input
            type="entryBranch"
            name="entryBranch"
            id="entryBranch"
            value={inputData.entryBranch}
            disabled
            onChange={handleChange}
            className={errors.entryBranch ? "error-input-z" : null}
            />
            
            {/* <select 
              name="entryBranch" 
              id="entryBranch" 
              value={inputData.entryBranch}
              onChange={handleChange}
              className={errors.entryBranch ? "error-input-z" : null}
            >
                <option value={''}>{inputData.entryBranch ? 'None' : ''}</option>
              {allBranches.map(branch => (
                <option value={branch} key={branch}>{branch}</option>
              ))}
            </select> */}
            {errors.entryBranch && (
              <ErrorMessage message={errors.entryBranch} />
            )}
          </div>

          {/* <div className=" sub-main--1 flex--3">
            <label htmlFor="entryBranch">Branch Code</label>
            <input
              type="number"
              name="entryBranch"
              id="entryBranch"
              value={inputData.entryBranch}
              onChange={handleChange}
              className={errors.entryBranch ? "error-input-z" : null}
            />
            {errors.entryBranch && (
              <ErrorMessage message={errors.entryBranch} />
            )}
          </div> */}
        </div>

        <h5>Filters</h5>
        <div className="sub-main flex--1">
          {" "}
          {/* filters */}
          <div className=" sub-main--1 flex--3">
            <label htmlFor="referenceNumber">Transaction Reference</label>
            <input
              type="text"
              name="referenceNumber"
              id="referenceNumber"
              value={inputData.referenceNumber}
              onChange={handleChange}
              className={errors.referenceNumber ? "error-input-z" : null}
            />
            {errors.referenceNumber && (
              <ErrorMessage message={errors.referenceNumber} />
            )}
          </div>
          {/* <div className=" sub-main--1 flex--3">
            <label htmlFor="debitAccount">Debit Account</label>
            <input
              type="text"
              name="debitAccount"
              id="debitAccount"
              value={inputData.debitAccount}
              onChange={handleChange}
              className={errors.debitAccount ? "error-input-z" : null}
              max={10}
            />
            {errors.debitAccount && (
              <ErrorMessage message={errors.debitAccount} />
            )}
          </div> */}
          <div className=" sub-main--1 flex--3">
            <label htmlFor="accountNumber">Account Number</label>
            <input
              type="text"
              name="accountNumber"
              id="accountNumber"
              value={inputData.accountNumber}
              onChange={handleChange}
              className={errors.accountNumber ? "error-input-z" : null}
              max={10}
            />
            {errors.accountNumber && (
              <ErrorMessage message={errors.accountNumber} />
            )}
          </div>
          {/* <div className=" sub-main--1 flex--3">
            <label htmlFor="creditAccount">Credit Account</label>
            <input
              type="text"
              name="creditAccount"
              id="creditAccount"
              value={inputData.creditAccount}
              onChange={handleChange}
              className={errors.creditAccount ? "error-input-z" : null}
              max={10}
            />
            {errors.creditAccount && (
              <ErrorMessage message={errors.creditAccount} />
            )}
          </div> */}
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleReset}>Reset</button>
          <button onClick={handleSubmit}>Search</button>
          {/* <button>Reset</button> */}
        </div>
        <hr />

        {transactionData &&
          (transactionData.length > 0 ? (
            <MUIDataTable
              title={"Transaction Summary"}
              data={transactionData}
              columns={columns}
              options={options}
            />
          ) : (
            <div className="not--loaded flex--6">
              <BiErrorCircle className="circle-error" />
              <h4>No Data Found</h4>
            </div>
          ))}
      </main>
    </div>
  );
};

export default TransactionSummary;

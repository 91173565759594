import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./InvestmentLiquidation.scss";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useCustom from "../../../../Api/Hooks-manager/investmentAccountOperations/useCustom";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop/index";
import useCustom3 from "../../../../Api/Hooks-manager/accountOpening/useCustom";
import { format } from "date-fns";
import { validateInvestmentLiquidation } from "../../../../Api/validator/validate11";
import Confirm from '../../../../Confirmbox/Confirm'
import NumberFormat from "react-number-format";
import { numberFormatter } from "../../../../formatter";
import { formattedDate } from "../../../../formatter/date";
import Table from "../../../DatagridTable/table";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import MenuItem from '@material-ui/core/MenuItem';
import {
  openViewModal,
} from "../../../../Api/redux-manager/actions";
import Details from "./view";


const InvestmentLiquidation = () => {
  const {
    errMesg,
    mesg, 
    handleInvestmentLiquidationCreate, 
    setValuesInvestmentLiquidation, 
    valuesInvestmentLiquidation, 
    setErrorInvestmentNumber, 
    errorInvestmentNumber, 
    investmentNumber, 
    handleChange, 
    loader, 
    valuesInvestmentAmendMentUx, 
    setValuesInvestmentAmendMentUx,
    // handleInvestmentLiquidationAuthorize,
    getInvestmentLiquidationAll,
    allInvestmentLiquidation
  } = useCustom()
  const { getAccountByAccountNumber, valuesStaticInvestmentBook, loader: loaders } = useCustom3()
  const [errors, setErrors] = useState({});
  const [error, setError] = useState({});
  const [openBox, setOpenBox] = useState(false)
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const dispatch = useDispatch()

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "investmentAccountNumber",
      label: "Investment Acc. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerAccountNumber",
      label: "Customer Acc. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "liquidationType",
      label: "Liquidation Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "liquidationAmount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{numberFormatter(value)}</p>
      },
    },
    {
      name: "investmentBalance",
      label: "Balance",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{numberFormatter(value)}</p>
      },
    },
    {
      name: "createdDateTime",
      label: "Date Liquidated",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date) => <p>{formattedDate(date)}</p>
      },
    },
    // {
    //   name: "bookDate",
    //   label: "Book Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "maturityDate",
    //   label: "Maturity Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allInvestmentLiquidation[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];
  
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  

  useEffect(() => {
    getInvestmentLiquidationAll();
  }, [getInvestmentLiquidationAll, updateTable]);

  useEffect(() => {
    const updateLiquidationField = async () => {
      console.log(valuesInvestmentAmendMentUx.customerAccountNumber, "for liquidation")
      if(investmentNumber){
        await getAccountByAccountNumber(valuesInvestmentAmendMentUx.customerAccountNumber);
        setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
          ...valuesInvestmentAmendMentUx,
          AcctNumber: valuesInvestmentAmendMentUx.customerAccountNumber,
        }))
        setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
          ...valuesInvestmentAmendMentUx,
          Acctname: valuesStaticInvestmentBook.accName,
          AcctBranch: valuesStaticInvestmentBook.accBranch,
          AcctCurrency: valuesStaticInvestmentBook.accCurrency,
        }));
      }
    }
    updateLiquidationField();
  }, [
    valuesStaticInvestmentBook.accName,
    valuesStaticInvestmentBook.accBranch,
    valuesStaticInvestmentBook.accCurrency,])

  // useEffect(() => {
  //   if (valuesInvestmentAmendMentUx.productCode) {
  //     const a = async () => {
  //       const accountDetails = await getAccountByAccountNumber(
  //         valuesInvestmentAmendMentUx.customerAccountNumber
  //       );
  //       setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
  //         ...valuesInvestmentAmendMentUx,
  //         Acctname: accountDetails.accountName,
  //         AcctBranch: accountDetails.branchCode,
  //         AcctCurrency: accountDetails.currency,
  //       }));
  //     };
  //     a();
  //   }
  // }, [valuesInvestmentAmendMentUx.productCode]);

  console.log(valuesInvestmentAmendMentUx.accruedInterest, 'valuesInvestmentAmendMentUx.accruedInterestvaluesInvestmentAmendMentUx.accruedInterest')

  const handleFieldUpdate = (type) => {
    if (type === "wave") {
      setValuesInvestmentLiquidation({
        ...valuesInvestmentLiquidation,
        WaveEarlyLiquidationPenalty: !valuesInvestmentLiquidation.WaveEarlyLiquidationPenalty,
      });
    }
  };

  const handleLiquidate = () => {
    let checkError = validateInvestmentLiquidation(valuesInvestmentLiquidation);
    console.log(checkError, "checkErrorcheckError");
    if (Object.keys(checkError).length > 0) {
      setError(checkError);
      return;
    }
    setError({});
    if(errorInvestmentNumber){
      return;
    }
    if(investmentNumber.length < 9) {
      setErrorInvestmentNumber('Field cannot be less than 9 characters')
      return;
    } 
    setErrorInvestmentNumber('')
    setOpenBox(true);
  };
console.log(mesg);
console.log(errMesg);
  
  return (
    <div className="investment--liquidation">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader || loaders} />
      </div>
      <Confirm 
        ApiCall={handleInvestmentLiquidationCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
        message={"Are you sure you want to liquidate this investment ?"}
        actionMsg={"Investment Liquidation successful"}
        errorMessage={errMesg}
      />
      <div className="headerdiv">
        <p>Investment Liquidation</p>
      </div>
      <Paper style={{ padding: 25 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <label>Investment Account No.</label>
          <br />
          <input 
            type='text'
            onChange={(e) => handleChange(e, 'liquidation')}
            name='investmentNumber'
            value={investmentNumber}
            maxLength='17'
            className={errorInvestmentNumber && 'error-input-z'}
          />
          {errorInvestmentNumber && <ErrorMessage message={errorInvestmentNumber} />}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Account Name</label>
            <br />
            <input
              type="text"
              disabled
              value={valuesInvestmentAmendMentUx.CustomerAccountName}
              className={`${
                Object.keys(errors).includes("CurrentBalance") &&
                "error-input-z"
              }`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Account Branch</label>
            <br />
            <input
              type="text"
              disabled
              value={valuesInvestmentAmendMentUx.customerAccountBranch}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Account Currency</label>
            <br />
            <input
              disabled
              type="text"
              value={
                valuesInvestmentAmendMentUx.AcctCurrency
                  ? valuesInvestmentAmendMentUx.AcctCurrency
                  : "NGN"
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Investment Product</label>
            <br />
            <input
              disabled
              type="text"
              value={valuesInvestmentAmendMentUx.productCode}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Investment Status</label>
            <br />
            <input
              disabled
              type="text"
              value={valuesInvestmentAmendMentUx.investmentStatus}
              className={`${
                Object.keys(errors).includes("CurrentBalance") &&
                "error-input-z"
              }`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Investment Amount</label>
               <NumberFormat
                thousandSeparator={true}
                inputMode="numeric"
                renderText={(value) => <div>{value}</div>}
                className={`${
                  Object.keys(errors).includes("CurrentBalance") &&
                  "error-input-z"
                }`}
                // onValueChange={(values) => {
                //   setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
                //     ...valuesInvestmentAmendMentUx,
                //     Amount: parseInt(values.value),
                //   }));
                // }}
                value={valuesInvestmentAmendMentUx.Amount}
                disabled
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Investment Book Date</label>
            <input
              type="text"
              disabled
              value={
                valuesInvestmentAmendMentUx.bookDate &&
                format(new Date(valuesInvestmentAmendMentUx.bookDate), "dd/MM/yyyy")
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Liquidation Date</label>
            <input
              type="text"
              disabled
              value={valuesInvestmentLiquidation.LiquidationDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Tenor (In Days)</label>
            <br />
            <input
              type="text"
              disabled
              value={valuesInvestmentAmendMentUx.tenor}
              className={`${
                Object.keys(errors).includes("CurrentBalance") &&
                "error-input-z"
              }`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Investment Maturity Date</label>
            <br />
            <input
              type="text"
              disabled
              value={
                valuesInvestmentAmendMentUx.maturityDate &&
                format(
                  new Date(valuesInvestmentAmendMentUx.maturityDate),
                  "dd/MM/yyyy"
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Maturity Amount</label>
            <br />
              <NumberFormat
              thousandSeparator={true}
              inputMode="numeric"
              renderText={(value) => <div>{value}</div>}
              value={
                valuesInvestmentLiquidation.MaturityAmount
                // format(
                //   new Date(valuesInvestmentAmendMentUx.maturityAmount),
                //   "dd/MM/yyyy"
                // )
              }
              disabled
              />
            {/* <input
              type="number"
              step="any"
              name="Amount"
              disabled
              value={
                valuesInvestmentLiquidation.MaturityAmount
                // format(
                //   new Date(valuesInvestmentAmendMentUx.maturityAmount),
                //   "dd/MM/yyyy"
                // )
              }
            /> */}
            
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Rate Basis</label>
            <br />
            <input
              type="text"
              disabled
              value={
                valuesInvestmentAmendMentUx.rateBasis
                  ? valuesInvestmentAmendMentUx.rateBasis
                  : "None"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className='flex--4'>
            <input
              onChange={() => handleFieldUpdate("wave")}
              className="checkbox"
              value={valuesInvestmentLiquidation.WaveEarlyLiquidationPenalty}
              type="checkbox"
              checked={valuesInvestmentLiquidation.WaveEarlyLiquidationPenalty}
            />
            <label className="checkboxlabel">
              Wave Early Liquidation Penalty
            </label>
          </Grid>
          <Grid Grid item xs={12} sm={12} md={6}>
            <label>Accrued Interest</label>
            <br />
            {/* <NumberFormat className='input--amount'
              value={valuesInvestmentAmendMentUx.accruedInterest}
               displayType={'text'} thousandSeparator={true} /> */}
            <input type='text' disabled value={(valuesInvestmentAmendMentUx.accruedInterest) ? numberFormatter(valuesInvestmentAmendMentUx.accruedInterest): 0}/>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Investment Balance</label>
            <br />
            <NumberFormat
              thousandSeparator={true}
              inputMode="numeric"
              renderText={(value) => <div>{value}</div>}
              value={
                valuesInvestmentLiquidation.InvestmentBalance
                // format(
                //   new Date(valuesInvestmentAmendMentUx.maturityAmount),
                //   "dd/MM/yyyy"
                // )
              }
              disabled
              />
            {/* <input type='text' disabled value={valuesInvestmentLiquidation.InvestmentBalance}/> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Liquidation Type</label>
            <br />
            <select
              name="LiquidationType"
              value={valuesInvestmentLiquidation.LiquidationType}
              onChange={(e) => handleChange(e, "liquidation")}
              className={`${error.LiquidationType && 'error-input-z'}`} 
            >
              <option value="">Select An Option</option>
              <option value="Full">Full</option>
              <option value="Partial">Partial</option>
            </select>
            {error.LiquidationType && (
              <ErrorMessage message={error.LiquidationType} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Liquidation Amount</label>
            <br />
            <NumberFormat
              thousandSeparator={true}
              inputMode="numeric"
              name="LiquidationAmount"
              renderText={(value) => <div>{value}</div>}
              value={valuesInvestmentLiquidation.LiquidationAmount}
              onChange={(e) => handleChange(e, "liquidation")}
              disabled={
                valuesInvestmentLiquidation.LiquidationType === "" ||
                valuesInvestmentLiquidation.LiquidationType === "Full"
              }
              />
            {/* <input
              type="number"
              step="any"
              onChange={(e) => handleChange(e, "liquidation")}
              name="LiquidationAmount"
              disabled={
                valuesInvestmentLiquidation.LiquidationType === "" ||
                valuesInvestmentLiquidation.LiquidationType === "Full"
              }
              value={valuesInvestmentLiquidation.LiquidationAmount}
            /> */}
          </Grid>
        </Grid>
        <div className='flex--1 button--cont'>
          <button className="btnz" onClick={handleLiquidate}>Liquidate Investment</button>
        </div>
      </Paper>

      {!loading && (
        <Table
          title="Investment Liquidation"
          subtitle=""
          name="Unauthorized Investments Liquidations"
          records={allInvestmentLiquidation}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
    </div>
  );
};

export default InvestmentLiquidation;

import React from 'react';
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import AccountOpening from './AccountOpening/AccountOpening';
import AccountEnquiry from './AccountEnquiry/AccountEnquiry';
import LienAmountBlock from './LienAmountBlock/LienAmountBlock';
import AccountStatement from './AccountStatement/AccountStatement'
import AllAccountEnquiry from './AllAccountEnquiry/AllAccountEnquiry';

 const Main = () => {
    return (
        <div className='main'>
            <Switch> 
                <Route exact path='/dashboard/customeraccountoperations/accountopening' component={AccountOpening}/>
                <Route exact path='/dashboard/customeraccountoperations/accountenquiry' component={AccountEnquiry}/>
                <Route exact path='/dashboard/customeraccountoperations/allaccountenquiry' component={AllAccountEnquiry}/>
                <Route exact path='/dashboard/customeraccountoperations/lienamountblock' component={LienAmountBlock}/>
                <Route exact path='/dashboard/customeraccountoperations/accountstatement' component={AccountStatement}/>
            </Switch>
        </div>
    ) 
}
export default Main;
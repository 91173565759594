import { useState, useEffect, useCallback } from "react";
import "./BranchDefinition.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { getAllStatesByCountryCode } from "../../../../Api/places/getPlaces";
import {
  branchDefinition,
  getAllBranches,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import useForm from "../../../../Api/Hooks-manager/coreModules/useCustom";
import Confirm from "../../../../Confirmbox/Confirm";
import CancelModal from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";

import { formattedDate } from "../../../../formatter/date";

import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const BranchDefinition = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const countries = useSelector(state => state.common.countries);
  const [states, setStates] = useState([]);
  const [error, setError] = useState({});
  const [errorEdit, setErrorEdit] = useState({});
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("true");
  const [allBranches, setAllBranches] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [children, setChildren] = useState([]);
  const { allGlAccount } = useForm();

  const [branch, setBranch] = useState({
    name: "",
    stateId: "",
    address: "",
    lga: "",
    parentBranchId: "",
    countryId: "",
    startTime: "",
    endTime: "",
    code: "",
    suspenseLedgerAccount: "",
    vaultAccount: "",
    status: true,
  });

  const [details, setDetails] = useState({});

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "code",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "stateId",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "suspenseLedgerAccount",
      label: "Suspense GL Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}{" "}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleBranch = allBranches[dataIndex];
                setDetails(singleBranch);
                fetchState(singleBranch.countryCode);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleBranch = allBranches[dataIndex];
                setDetails(singleBranch);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const getChildrenFromChartOfAccount = useCallback(() => {
    const getChildren = allGlAccount.filter(
      child => child.glHierarchy === "Child"
    );
    setChildren(() => getChildren);
  }, [allGlAccount]);

  const fetchState = async value => {
    const response = await getAllStatesByCountryCode(token, value);
    if (response && response.data) {
      console.log(response.data, "get")
      setStates(response.data);
    }
  };

  const fetchAllBranches = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllBranches(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllBranches(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllBranches();
  }, [fetchAllBranches, updateTable]);

  useEffect(() => {
    getChildrenFromChartOfAccount();
  }, [getChildrenFromChartOfAccount]);

  const handleChange = name => e => {
    const { value } = e.target;
    setBranch({
      ...branch,
      [name]: value,
    });
    if (name === "countryId") {
      fetchState(value);
    }
  };

  const handleEditChange = name => e => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
    if (name === "countryId") {
      fetchState(value);
    }
  };


  const validateBranchDefinition = () => {
    const {
      name,
      stateId,
      lga,
      address,
      countryId,
      startTime,
      endTime,
      code,
      suspenseLedgerAccount,
      vaultAccount,
    } = branch;

    const error = {};

    if (name === "") {
      error.name = "Provide the name of the bank as recognized by CBN";
    }
    if (address.length < 10 || address.length > 150) {
      error.address =
        address.length < 10
          ? "Address should not be less than 10 characters"
          : "Address should not be more than 150 characters";
    }
    if (countryId === null || countryId === "") {
      error.countryId = "Select a valid country";
    }
    if (stateId === null || stateId === "") {
      error.stateId = "Select a valid state";
    }
    if (lga === "") {
      error.lga = "Provide a valid lga";
    }
    if (code === "") {
      error.code = "Invalid institution code";
    }
    if (suspenseLedgerAccount === "") {
      error.suspenseLedgerAccount = "Invalid suspense ledgerAccount";
    }
    if (vaultAccount === "") {
      error.vaultAccount = "Invalid suspense ledgerAccount";
    }
    if (startTime === "") {
      error.startTime = "Invalid start time";
    }
    if (endTime === "") {
      error.endTime = "Invalid stop time";
    }
    return error;
  };

  const validateEditBranchDefinition = () => {
    const {
      name,
      stateId,
      lga,
      address,
      countryId,
      startTime,
      endTime,
      code,
      suspenseLedgerAccount,
      vaultAccount,
    } = details;

    const error = {};

    if (name === "") {
      error.name = "Provide the name of the bank as recognized by CBN";
    }
    if (address.length < 10 || address.length > 150) {
      error.address =
        address.length < 10
          ? "Address should not be less than 10 characters"
          : "Address should not be more than 150 characters";
    }
    if (countryId === null || countryId === "") {
      error.countryId = "Select a valid country";
    }
    if (stateId === null || stateId === "") {
      error.stateId = "Select a valid state";
    }
    if (lga === "") {
      error.lga = "Provide a valid lga";
    }
    if (code === "") {
      error.code = "Invalid institution code";
    }
    if (suspenseLedgerAccount === "") {
      error.suspenseLedgerAccount = "Invalid suspense ledgerAccount";
    }
    if (vaultAccount === "") {
      error.vaultAccount = "Invalid suspense ledgerAccount";
    }
    if (startTime === "") {
      error.startTime = "Invalid start time";
    }
    if (endTime === "") {
      error.endTime = "Invalid stop time";
    }
    return error;
  };

  const EmptyFieldsPostResult = () => {
    setBranch({
      name: "",
      stateId: "",
      address: "",
      lga: "",
      parentBranchId: "",
      countryId: "",
      startTime: "",
      endTime: "",
      code: "",
      suspenseLedgerAccount: "",
      vaultAccount: "",
      status: true,
    });
  };

  const postResult = async () => {
    dispatch(isLoading());
    const response = await branchDefinition(token, branch);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.message === "Success") {
        EmptyFieldsPostResult();
        fetchAllBranches();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleClearRecord = () => {
    setBranch({
      name: "",
      stateId: null,
      address: "",
      lga: "",
      parentBranchId: "",
      countryId: null,
      startTime: "",
      endTime: "",
      code: "",
      suspenseLedgerAccount: "",
      vaultAccount: "",
      status: true,
    });
    return true;
  };

  const handleSubmit = () => {
    selectedValue === "true" ? (branch.status = true) : (branch.status = false);
    const checkError = validateBranchDefinition();
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;

    setError({});
    setOpen(true);
  };

  const handleEditSubmit = () => {
    details.status = details.status === true ? true : false;
    const checkError = validateEditBranchDefinition();
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;

    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };

  return (
    <div className="branch--definition">
      <Confirm
        ApiCall={postResult}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <CancelModal
        ApiCall={handleClearRecord}
        message="Are you sure you want to clear this record?"
        openBox={cancel}
        setOpenBoxState={setCancel}
        actionMsg="Your records were cleared successfully"
      />
      <Spinner loading={loading} />
      <div className="headerdiv">
        <p>Branch Definition</p>
      </div>
      <Paper style={{ padding: 32 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <label>Branch Name</label>
            <br />
            <input
              type="text"
              className={error.name ? "error-input-z" : null}
              value={branch.name}
              onChange={handleChange("name")}
            />{" "}
            {error.name && <ErrorMessage message={error.name} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Branch Address</label>
            <br />
            <input
              className={error.address ? "error-input-z" : null}
              type="text"
              value={branch.address}
              onChange={handleChange("address")}
            />{" "}
            {error.address && <ErrorMessage message={error.address} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Country</label>
            <br />
            <select
              onChange={handleChange("countryId")}
              className={error.countryId ? "error-input-z" : null}
              value={branch.countryId}
            >
              <option value="">Select a country</option>
              {countries.map(country => (
                <option value={country.countryCode2} key={country.id}>
                  {country.name}{" "}
                </option>
              ))}{" "}
            </select>
            {error.countryId && <ErrorMessage message={error.countryId} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>State</label>
            <br />
            <select
              onChange={handleChange("stateId")}
              className={error.stateId ? "error-input-z" : null}
              value={branch.stateId}
            >
              <option value="">Select a state</option>
              {states.map(state => (
                <option value={state.name} key={state.id}>
                  {state.name}{" "}
                </option>
              ))}{" "}
            </select>
            {error.stateId && <ErrorMessage message={error.stateId} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>LGA</label>
            <br />
            <input
              className={error.lga ? "error-input-z" : null}
              type="text"
              value={branch.lga}
              onChange={handleChange("lga")}
            />{" "}
            {error.lga && <ErrorMessage message={error.lga} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Parent Branch Code</label>
            <br />
            <select
              value={branch.parentBranchId}
              onChange={handleChange("parentBranchId")}
            >
              <option>Select parent branch</option>
              {allBranches.map(item => (
                <option value={item.code} key={item.id}>
                  {`${item.name} - ${item.code}`}
                </option>
              ))}{" "}
            </select>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Branch Code</label>
            <br />
            <input
              type="text"
              className={error.code ? "error-input-z" : null}
              value={branch.code}
              onChange={handleChange("code")}
            />{" "}
            {error.code && <ErrorMessage message={error.code} />}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Branch Suspense Account</label>
            <br />
            <select
              className={error.suspenseLedgerAccount ? "error-input-z" : null}
              value={branch.suspenseLedgerAccount}
              onChange={handleChange("suspenseLedgerAccount")}
            >
              <option value="">Select branch suspense account</option>
              {children.map(child => (
                <option value={child.accountNumber} key={child.id}>
                  {`${child.accountName} - ${child.accountNumber}`}
                </option>
              ))}{" "}
            </select>
            {error.suspenseLedgerAccount && (
              <ErrorMessage message={error.suspenseLedgerAccount} />
            )}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Branch Vault Account</label>
            <br />
            <select
              value={branch.vaultAccount}
              className={error.vaultAccount ? "error-input-z" : null}
              onChange={handleChange("vaultAccount")}
            >
              <option value="">Select branch vault account</option>
              {children.map(child => (
                <option value={child.accountNumber} key={child.id}>
                  {`${child.accountName} - ${child.accountNumber}`}
                </option>
              ))}{" "}
            </select>
            {error.vaultAccount && (
              <ErrorMessage message={error.vaultAccount} />
            )}{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Work Time</label>
            <div className="work-time">
              <span>Start Time</span>
              <input
                className={error.startTime ? "error-input-z" : null}
                type="time"
                value={branch.startTime}
                style={{
                  minWidth: "30px",
                  marginLeft: 120,
                }}
                onChange={handleChange("startTime")}
              />{" "}
              {error.startTime && <ErrorMessage message={error.startTime} />}
              <br />
              <span>Stop Time</span>
              <input
                className={error.endTime ? "error-input-z" : null}
                type="time"
                value={branch.endTime}
                style={{
                  minWidth: "30px",
                  marginLeft: 120,
                }}
                onChange={handleChange("endTime")}
              />{" "}
              {error.endTime && <ErrorMessage message={error.endTime} />}{" "}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label>Status</label>
            <div className="status">
              <div>
                <span>Active</span>
                <Radio
                  checked={selectedValue === "true"}
                  onChange={e => setSelectedValue(e.target.value)}
                  value="true"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
                <span style={{ marginLeft: 80 }}>Inactive</span>
                <Radio
                  checked={selectedValue === "false"}
                  onChange={e => setSelectedValue(e.target.value)}
                  value="false"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "B" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="savebtn flex--1">
          <button className="save" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </Paper>
      {!loading && (
        <Table
          title="Branches"
          subtitle=""
          name="List of ibranches"
          records={allBranches}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />{" "}
      {details && details.id && (
        <EditForm
          editBranch={details}
          errorEdit={errorEdit}
          states={states}
          children={children}
          handleEditChange={handleEditChange}
          allBranches={allBranches}
          handleEditSubmit={handleEditSubmit}
        />
      )}{" "}
    </div>
  );
};

export default BranchDefinition;

import axios from 'axios';
import { Routes } from '../../configUrl';

const forgetPassword = async (email) => {
    return await axios({
        method: "post",
        url: Routes.forgotPassword,
        data: {
          email
        }
      })
      .then((res) => {
        // console.log('..........',res);
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err.response)
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data:[],
        };
      });
  };
  

export {forgetPassword}

import React from "react";
import "./Main.scss";
import { Switch, Route } from "react-router-dom";
import LoanDisbursement from "./LoanDisbursement/LoanDisbursement";
import LoanBooking from "./LoanBooking/Loanbooking";
import LoanRepayment from "./LoanRepayment/Loanrepayment";
import LoanAmendment from "./LoanAmendment/Loanamendment";
import LoanFacilityLimit from "./LoanFacilityLimit/LoanFacilityLimit";
import LoanScheduleDetails from "./LoanScheduleDetails/LoanScheduleDetails";


const Main = () => {
  return (
    <div>
      <div className="Main">
        <Switch>
          <Route
            path="/dashboard/loanaccountoperations/loandisbursement"
            component={LoanDisbursement}
          />
          <Route
            path="/dashboard/loanaccountoperations/loanbooking"
            component={LoanBooking}
          />
          <Route            
           path="/dashboard/loanaccountoperations/loanrepayment"
            component={LoanRepayment}
          />
          <Route            
           path="/dashboard/loanaccountoperations/loanramendment"
            component={LoanAmendment}
          />
          <Route            
           path="/dashboard/loanaccountoperations/loanfacilitylimit"
            component={LoanFacilityLimit}
          />
          <Route            
           path="/dashboard/loanaccountoperations/loanscheduledetails"
            component={LoanScheduleDetails}
          />
        </Switch>
      </div>
    </div>
  );
};
export default Main;

import { useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector,useDispatch } from "react-redux";
import DecisionModal from "../../Confirmbox/decision";
import FormDialog from "../../Confirmbox/form";
import ResponseModal from "../../Confirmbox/Modal";
import {
  openDecisionModal,
  closeDecisionModal,
  closeFormModal,
  closeResponseModal,
  openResponseModal,
  isLoading
} from '../../Api/redux-manager/actions'
import { changeAccountFreezeStatus } from "../../Api/Hooks-manager/accountEnquiry/accounteEnquiry";


const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "200px",
  },
  centerBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    // marginTop: 20,
    // marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  children:{
    padding:"1.5rem 0px"
  }
}));

const FreezeModal = ({
  actionAccountObject,
  actionOnClose,
  onSuccess,
  open
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const openDialog = useSelector(state => state.approve.opendecisionModal);
  const openresponseModal = useSelector(state => state.approve.openResponseModal);
  const [input, setInput] = useState({
    status: actionAccountObject.freezeStatus,
  });


  const [err, setErr] = useState('');
  const [msg, setMsg] = useState('');
  const [response, setResponse] = useState(null)

  const handleChange = (e) => {
    const {name, value} = e.target;
    setInput(prev => ({...prev, [name]:value}))
  }

  const validateForm = () => {
    const {
      status
    } = input;
    if (status === ''){
      setErr('status');
      setMsg('Field is required');
      return
    }
    if (status === actionAccountObject.freezeStatus){
      setErr('status');
      setMsg('Status has not changed');
      return 
    }
    return true
  }

  const openDecisionDialog = () => {
    if (validateForm()){
      setErr('');
      setMsg('');
      dispatch(openDecisionModal(input))
    }
  }

  const changeFreezeStatus = async (status) => {
    //dispatch loading
    dispatch(isLoading())
    const res = await changeAccountFreezeStatus(token, actionAccountObject.accountNumber, status)
    setResponse(res)
    dispatch(closeDecisionModal())
    dispatch(openResponseModal())
    dispatch(isLoading())
    onSuccess()
  }

  const closeModal = (actionCreator=null) => {
    if(actionCreator){
      dispatch(actionCreator())
    }
    actionOnClose()
  }
  
  return (
    <div>
      <FormDialog open={open} handleClose={() => closeModal(closeFormModal)} title={"Change Freeze Status"}>
        <Grid container spacing={2}>
          <Grid item xs={12} justifyContent="center" alignItems="center" className={classes.centerBtn}>
            <FormControl variant="outlined" className={classes.formControl} error={err === 'status'}>
              <InputLabel htmlFor="outlined-age-simple">
                {"Freeze status"}
              </InputLabel>
              <Select
                value={input.status}
                onChange={handleChange}
                name="status"
                error={err === "status"}
                input={
                  <OutlinedInput
                    labelWidth={120}
                    name="status"
                    id="outlined-decision-simple"
                  />
                }
              >
                {/* <MenuItem value="">
                  <em>Select an option</em>
                </MenuItem> */}
                <MenuItem value="TotalFreeze">Total Freeze</MenuItem>
                <MenuItem value="DebitFreeze">Debit Freeze</MenuItem>
                <MenuItem value="CreditFreeze">Credit Freeze</MenuItem>
                <MenuItem value="NoFreeze">No Freeze</MenuItem>
              </Select>
              {err === 'status' && (<FormHelperText>{msg}</FormHelperText>)}
            </FormControl>
            {/* <p style={{ color: "red" }}>{err === "status" ? msg : ""}</p> */}
          </Grid>

          <Grid item xs={12} className={classes.centerBtn}>
            <Button className={classes.button} onClick={openDecisionDialog}>Submit</Button>
          </Grid>
        </Grid>
      </FormDialog>
      <DecisionModal
        question={"Are you sure you want to change the freeze status"}
        openDialog={openDialog}
        onClick={() => changeFreezeStatus(input.status)}
        onClose={() => dispatch(closeDecisionModal())}
      />
      <ResponseModal
        openDialog={openresponseModal}
        onClose={() => closeModal(closeResponseModal)}
        positiveDialog={response && response.status}
      >
        <div className={classes.children}>
          {response && response.message}
        </div>
      </ResponseModal>
    </div>
  );
};

export default FreezeModal;

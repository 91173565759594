import { useState, useCallback, useEffect } from "react";
import { baseUrl, Routes as routes } from "../../configUrl";
import axios from "axios";
import Moment from "react-moment";

import { DateConverter } from "../../../components/ConvertDate";
import { format, add } from "date-fns";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { formattedDate } from "../../../formatter/date";
import { isLoading } from "../../../Api/redux-manager/actions";
const useCustom = () => {
  // eslint-disable-next-line
  const [deleteId, setDeleteId] = useState("");
  // eslint-disable-next-line
  const loading = useSelector((state) => state.req.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [loader, setLoader] = useState(false);
  const [amendId, setAmendId] = useState("");
  const [allInvestmentBooking, setAllInvestmentBooking] = useState([]);
  const [allInvestmentLiquidation, setAllInvestmentLiquidation] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [updateId, setUpdateID] = useState("");
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [errMesg, setErrMesg] = useState("");
  const [message, setMessage] = useState("");
  const [mesg, setMesg] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let Token = user && user.jwtToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
  }, [user]);

  const workdate = useSelector((state) => state.common.branch.workdate);
  const nowDate = format(new Date(workdate), "yyyy-MM-dd");
  const date = new Date();

  const [valuesInvestmentBooking, setValuesInvestmentBooking] = useState({
    IsAccountHolder: true,
    CustomerAccountNumber: "",
    CustomerAccountName: "",
    customerAccountBranch:"",
    ProductCode: "",
    CreditInterestTo: "",
    BeneficiaryAccountNumber: "",
    InvestmentAccountNumber: "",
    Status: "",
    Amount: 0,
    MaturityAmount: 0,
    ExpectedInterestAmount: 0,
    BookDate: nowDate,
    MaturityDate: "",
    Tenor: 0,
    SystemRate: 0,
    Variance: 0,
    FinalRate: 0,
    RateChatCode: "",
    StartDate: nowDate, //added
    RateBasis: "",
    AutoClose: true,
    interestPeriod: "",
  });

  // "isAccountHolder": true,
  // "customerAccountNumber": "string",
  // "customerAccountName": "string",
  // "customerAccountBranch": "string",
  // "beneficiaryAccountNumber": "string",
  // "productCode": "string",
  // "status": "string",
  // "amount": 0,
  // "maturityAmount": 0,
  // "expectedInterestAmount": 0,
  // "bookDate": "2021-10-27T22:19:43.416Z",
  // "maturityDate": "2021-10-27T22:19:43.416Z",
  // "tenor": 0,
  // "systemRate": 0,
  // "variance": 0,
  // "finalRate": 0,
  // "rateChatCode": "string",
  // "rateBasis": "string",
  // "creditInterestTo": "string",
  // "autoClose": true


  // //---------------------for calc datediff start-------------------------//
  // const newDatediff = new Date();
  // // newDatediff.setDate(10);
  // const caculateNewDays = (currentDate, noOfMonths) => {
  //   if (noOfMonths === 0) return currentDate
  //   const remainingMonths = 12 - (currentDate.getMonth() + 1)
  //   if(noOfMonths > remainingMonths){
  //       currentDate.setFullYear(currentDate.getFullYear() + 1)
  //   }
  //   const addYear = (noOfMonths - remainingMonths) / 12
  //   const newMonth = (noOfMonths - remainingMonths) % 12

  //   if (addYear >= 1){
  //       currentDate.setFullYear(currentDate.getFullYear() + addYear)
  //       currentDate.setMonth(0)
  //       currentDate.setMonth(newMonth - 1)
  //   }
  //   else{
  //       currentDate.setMonth(newMonth - 1)
  //   }
  //   return currentDate
  // }
  // console.log(caculateNewDays(newDatediff, 2).toLocaleString(), "what is here")
  // //---------------------for calc datediff end-------------------------//




  // useEffect(() => {
  //   setValuesInvestmentBooking((prev) => ({
  //     ...prev,
  //     BookDate: format(new Date(workdate), 'yyyy/MM/dd'),
  //   }));
  // }, [suspenceAccount]);

  const [valuesGetExpectedInterest, setValuesGetExpectedInterest] = useState({
    finalRate: "",
    tenor: "",
    rateBasis: "",
    amount: "",
    interestPeriod: "",
  });


  useEffect(() => {
    if (
      valuesInvestmentBooking.FinalRate !== "" &&
      valuesInvestmentBooking.Tenor !== ""
    ) {
      setValuesGetExpectedInterest((prev) => ({
        ...prev,
        finalRate: valuesInvestmentBooking.FinalRate,
        tenor: valuesInvestmentBooking.Tenor,
        amount: valuesInvestmentBooking.Amount,
      }));

      if (valuesInvestmentBooking.RateBasis === "None") {
        setValuesGetExpectedInterest((prev) => ({
          ...prev,
          rateBasis: "PerMonth",
        }));
      } else {
        setValuesGetExpectedInterest((prev) => ({
          ...prev,
          rateBasis: valuesInvestmentBooking.RateBasis,
        }));
      }

      if(valuesInvestmentBooking.interestPeriod === "None"){
        setValuesGetExpectedInterest((prev) => ({
          ...prev,
          interestPeriod: "Actual",
        }));
      } else {
        setValuesGetExpectedInterest((prev) => ({
          ...prev,
          interestPeriod: valuesInvestmentBooking.interestPeriod,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valuesInvestmentBooking.FinalRate,
    valuesInvestmentBooking.Tenor,
    valuesInvestmentBooking.Amount,
    valuesInvestmentBooking.RateBasis,
    valuesInvestmentBooking.interestPeriod,
  ]);

  
  const [valuesInvestmentAmendMentUx, setValuesInvestmentAmendMentUx] =
  useState({
    CustomerAccountName: "",
      AcctBranch: "",
      AcctCurrency: "",
      AcctNumber: "",
      newInvAmount: 0,
      Acctname: "",
      ProductCode: "",
      CreditInterestTo: "",
      InvestmentAccountNumber: "",
      Status: "",
      DecideAccount: "",
      Amount: 0,
      MaturityAmount: 0,
      ExpectedInterestAmount: 0,
      AmendmentEffectiveDate: "",
      MaturityDate: "",
      Tenor: 0,
      currentBalance: "",
      RateBasis: "",
      newInvestMentProduct: "",
      rateChatCode: "",
      StartDate: "",
      AmendInterestRate: false,
      interestPeriod: "",
    });
    
  const [valuesMainInvestmentAmendMent, setValuesMainInvestmentAmendMent] =
    useState({
      CustomerAccountNumber: "",
      CustomerAccountName: "",
      ProductCode: "",
      Amount: 0,
      MaturityAmount: 0,
      BookDate: "",
      AmendmentEffectiveDate: format(new Date(), 'yyyy/MM/dd'),
      MaturityDate: "",
      Tenor: 0,
      SystemRate: 0,
      Variance: 0,
      FinalRate: 0,
      interestPeriod: "",
    });

    const [valuesInvestmentLiquidation, setValuesInvestmentLiquidation] =
    useState({
      LiquidationAmount: 0,
      investmentNumber: "",
      InvestmentBalance: 0,
      AccruedInterest: "",
      LiquidationType: "",
      // LiquidationDate: format(new Date(), 'yyyy/MM/dd'),
      LiquidationDate: format(new Date(workdate), "yyyy/MM/dd"),
      WaveEarlyLiquidationPenalty: false,
    });

  const [authorize, setAuthorize] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const EmptyFields = () => {
    setValuesInvestmentAmendMentUx({
      CustomerAccountName: "",
      customerAccountBranch: '',
      AcctBranch: "",
      AcctCurrency: "",
      AcctNumber: "",
      newInvAmount: 0,
      Acctname: "",
      ProductCode: "",
      CreditInterestTo: "",
      InvestmentAccountNumber: "",
      Status: "",
      DecideAccount: "",
      Amount: "",
      MaturityAmount: "",
      ExpectedInterestAmount: "",
      AmendmentEffectiveDate: "",
      MaturityDate: "",
      Tenor: 0,
      currentBalance: "",
      RateBasis: "",
      newInvestMentProduct: "",
      rateChatCode: "",
      StartDate: "",
      AmendInterestRate: false,
      productCode: "",
      investmentStatus: "",
      maturityDate: "",
      bookDate: "",
      tenor: ""
      });
    setValuesMainInvestmentAmendMent({
      CustomerAccountNumber: "",
      CustomerAccountName: "",
      ProductCode: "",
      Amount: "",
      MaturityAmount: "",
      BookDate: "",
      AmendmentEffectiveDate: format(new Date(), 'yyyy/MM/dd'),
      MaturityDate: "",
      Tenor: 0,
      SystemRate: "",
      Variance: 0,
      FinalRate: "",
    });
    setAuthorize({
      reason: "",
      isApproved: true,
      authorizationStatus: 0,
    });
  };
  
  const [errorInvestmentNumber, setErrorInvestmentNumber] = useState("");
  const [investmentNumber, setInvestmentNumber] = useState("");
  
  useEffect(() => {
    let newFinalRate =
    parseFloat(valuesInvestmentBooking.SystemRate) +
    parseFloat(valuesInvestmentBooking.Variance);
    setValuesInvestmentBooking((valuesInvestmentBooking) => ({
      ...valuesInvestmentBooking,
      FinalRate: newFinalRate,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesInvestmentBooking.Variance, valuesInvestmentBooking.SystemRate]);
  
  useEffect(() => {
    if (investmentNumber.length > 9) {
      EmptyFields()
      EmptyFieldLiquidation()
      getInvestmentBookingByNumber(investmentNumber);
    }
  }, [investmentNumber]);
  
  useEffect(() => {
    if (valuesInvestmentLiquidation.LiquidationType === "Full") {
      setValuesInvestmentLiquidation((valuesInvestmentLiquidation) => ({
        ...valuesInvestmentLiquidation,
        LiquidationAmount: valuesInvestmentLiquidation.InvestmentBalance,
      }));
    }
  }, [valuesInvestmentLiquidation.LiquidationType]);
  
  const handleChange = (e, change) => {
    const { name, value } = e.target;
    if (change === "booking") {
      setValuesInvestmentBooking((valuesInvestmentBooking) => ({
        ...valuesInvestmentBooking,
        [name]: value,
      }));
    } else if (change === "amendment") {
      // setValuesInvestmentAmendMentUx(valuesInvestmentAmendMentUx => ({...valuesInvestmentAmendMentUx, [name]: value }));
      setValuesMainInvestmentAmendMent((valuesMainInvestmentAmendMent) => ({
        ...valuesMainInvestmentAmendMent,
        [name]: value,
      }));
    } else if (change === "decide") {
      setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
        ...valuesInvestmentAmendMentUx,
        [name]: value,
      }));
    } else if (change === "liquidation") {
      if (name === "investmentNumber") {
        setInvestmentNumber(e.target.value);
      } else {
        setValuesInvestmentLiquidation((valuesInvestmentLiquidation) => ({
          ...valuesInvestmentLiquidation,
          [name]: value,
        }));
      }
    } else if (change === "authorize") {
      setAuthorize((authorize) => ({ ...authorize, [name]: value }));
    }

    if (change === "StartDate"){
   setValuesInvestmentBooking({
        ...valuesInvestmentBooking, 
        // BookDate: value,
        StartDate: value,
      })
    }

    if(change === "invest"){
      setValuesInvestmentBooking((valuesInvestmentBooking) => ({
        ...valuesInvestmentBooking,
        [name]: value,
      }));
    }
  };


  useEffect(() => {
    if(valuesInvestmentBooking.StartDate){const result = add(new Date(valuesInvestmentBooking.StartDate), {
      years: 0,
      months: 0,
      weeks: 0,
      days: valuesInvestmentBooking.Tenor,
    });
    let formattedDate = format(new Date(result), "yyyy-MM-dd");
    console.log(formattedDate, "formattedDateformattedDate");
    // console.log('aaaaaaaaaaaaaaaaaa', valuesInvestmentBooking.RateBasis, valuesInvestmentBooking.Tenor, valuesInvestmentBooking)
    
      console.log(valuesInvestmentBooking.StartDate.length, "ssdadsdsdsd")
      setValuesInvestmentBooking({
        ...valuesInvestmentBooking,
        StartDate: valuesInvestmentBooking.StartDate,
        MaturityDate: formattedDate
      });

    }
  }, [valuesInvestmentBooking.Tenor, valuesInvestmentBooking.ProductCode, valuesInvestmentBooking.StartDate])

  const EmptyFieldBooking = () => {
    setValuesInvestmentBooking({
      IsAccountHolder: true,
      CustomerAccountNumber: "",
      CustomerAccountName: "",
      ProductCode: "",
      CreditInterestTo: "",
      BeneficiaryAccountNumber: "",
      InvestmentAccountNumber: "",
      Status: 0,
      Amount: 0,
      MaturityAmount: 0,
      ExpectedInterestAmount: 0,
      BookDate: "",
      MaturityDate: "",
      Tenor: 0,
      SystemRate: 0,
      Variance: 0,
      FinalRate: 0,
      RateChatCode: 0,
      StartDate: "",
      RateBasis: "",
      AutoClose: false,
      interestPeriod: "",
    });
  };
  
  const handleInvestmentBookingCreate = async (e) => {
    let data = { ...valuesInvestmentBooking };
    
    try {
      setLoader(true);
      const response = await axios.post(
        `${baseUrl}investment/InvestmentBooking/Create`,
        data
        );
      console.log(response);
      if (response.data.statusCode === 200) {
        setMessage(response.data.responseMessage);
        EmptyFieldBooking();
        return true;
      } else {
        setErrMessage(response.data.responseMessage);
        return false;
      }
    } catch (err) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getExpectedInterest = async (e) => {
    let data = { ...valuesGetExpectedInterest };
    
    try {
      setLoader(true);
      const response = await axios.post(
        `${baseUrl}investment/InvestmentBooking/Interest`,
        data
        );

      if (response.data.statusCode === 200) {
        let roundUpNumber = response.data.data.toFixed(3);
        console.log(response.data.data, "roundUpNumberroundUpNumber");
        roundUpNumber = roundUpNumber - 1;
        const maturityAmt =
        parseInt(valuesInvestmentBooking.Amount) + ++roundUpNumber;
        setValuesInvestmentBooking((valuesInvestmentBooking) => ({
          ...valuesInvestmentBooking,
          ExpectedInterestAmount: roundUpNumber,
          MaturityAmount: maturityAmt,
        }));
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleInvestmentAmendMentCreate = async (e) => {
    let data = { ...valuesMainInvestmentAmendMent };

    try {
      setLoader(true);
      const response = await axios.post(
        `${baseUrl}investment/InvestmentBooking/amend/${amendId}`,
        data
      );
      console.log(response);
      if (response.data.statusCode === 200) {
        EmptyFields();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoader(false);
    }
  };

  const deleteInvestmentBooking = async () => {
    setLoader(true);
    try {
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const getInvestmentBookingAll = useCallback(async () => {
    dispatch(isLoading());
    const response = await axios.get(
      `${baseUrl}investment/InvestmentBooking/GetAll`
    );
    if (response && response.data.data) {
      dispatch(isLoading());
      if (response.data.data.length > 0) {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].sn = i + 1;
          response.data.data[i].createdDateTime = formattedDate(
            response.data.data[i].createdDateTime
          );
          response.data.data[i].bookDate =formattedDate (response.data.data[i].bookDate);
          response.data.data[i].maturityDate = formattedDate(response.data.data[i].maturityDate)
          response.data.data[i].dateAuthorized = formattedDate(
            response.data.data[i].dateAuthorized
          );
          response.data.data[i].updatedDateTime = formattedDate(
            response.data.data[i].updatedDateTime
          );
        }
        console.log(response.data.data)
        setAllInvestmentBooking(response.data.data);
      }
    }
  }, [dispatch]);

  const handleInvestmentBookingAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}investment/InvestmentBooking/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        let a = allInvestmentBooking.map((items) => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllInvestmentBooking(a);
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const EmptyFieldLiquidation = () => {
    setValuesInvestmentLiquidation({
      LiquidationAmount: 0,
      investmentNumber: "",
      InvestmentBalance: 0,
      AccruedInterest: "",
      LiquidationType: "",
      MaturityAmount: "",
      LiquidationDate: format(new Date(workdate), "MM/dd/yyyy"),
      WaveEarlyLiquidationPenalty: false,
    });
  };


  // Investment Liquidation
  const handleInvestmentLiquidationCreate = async (e) => {
    let data = { ...valuesInvestmentLiquidation };

    try {
      setLoader(true);
      const response = await axios.post(
        `${routes.initiateInvestmentLiquidation}`,
        {...data, investmentBookingId: amendId}
      );
      console.log(response);
      if (response.data.statusCode === 200) {
        setMesg(response.data.responseMessage)
        EmptyFields()
        EmptyFieldLiquidation();
        setErrorInvestmentNumber('')
        return true;
      } else {
        setErrMesg(response.data.responseMessage)

        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoader(false);
    }
  };

  // const handleInvestmentLiquidationAuthorize = async () => {
  //   setLoader(true);
  //   let data = { ...authorize };
  //   try {
  //     const response = await axios.put(
  //       `${baseUrl}investment/InvestmentBooking/Authorize/${updateId}`, // change
  //       data
  //     );
  //     if (response.data.status) {
  //       setAuthorize({
  //         reason: "",
  //         isApproved: "",
  //       });
  //       let a = allInvestmentLiquidation.map((items) => {
  //         if (items.id === updateId) {
  //           items.authorizationStatus = "Authorized";
  //         }
  //         return items;
  //       });
  //       setAllInvestmentLiquidation(a);
  //       setOpenBoxAuthorize(false);
  //       setOpenView(false);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     return false;
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const getInvestmentLiquidationAll = useCallback(async () => {
    dispatch(isLoading());
    const response = await axios.get(
      `${routes.getUnauthtorizedLiquidations}` //no get all route
    );
    if (response && response.data.data) {
      dispatch(isLoading());
      if (response.data.data) {
        const parsedData = response.data.data.map((data, index) => {
          data['sn'] = index + 1
          data['investmentAccountNumber'] = data.investmentBooking.investmentAccountNumber
          data['customerAccountNumber'] = data.investmentBooking.customerAccountNumber
          return data
        })
        console.log(parsedData)
        setAllInvestmentLiquidation(parsedData);
      }
    }
  }, [dispatch]);

  const getInvestmentBookingByNumber = async (investmentNumber) => {
    setLoader(true);
    try {
      let response = await axios.get(
        `${baseUrl}investment/InvestmentBooking/GetByInvestmentNumber/${investmentNumber}`
      );
      // console.log(response.data, "accountEnquiry");
      if (response.data.data) {
        // console.log(response.data.data, "response.data.dataresponse.data.data");
        if(response.data.data.amount + response.data.data.accruedInterest <= 0){
          setErrorInvestmentNumber("Investment Fully Liquidated");
          return;
        }
        setErrorInvestmentNumber("");
        setValuesInvestmentAmendMentUx((valuesInvestmentAmendMentUx) => ({
          ...valuesInvestmentAmendMentUx,
          ...response.data.data,
          CustomerAccountName: response.data.data.customerAccountName,
          // AcctNumber: response.data.data.customerAccountNumber,
          MaturityDate: format(
            new Date(response.data.data.maturityDate),
            'yyyy/MM/dd'
          ),
          ProductCode: response.data.data.productCode,
          currentBalance: response.data.data.currentBalance,
          Status: response.data.data.investmentStatus,
          Amount: response.data.data.amount,
          rateChatCode: response.data.data.rateChatCode,
          FinalRate: response.data.data.finalRate,
          customerAccountBranch: response.data.data.investmentbranch,
        }));
        
        setValuesMainInvestmentAmendMent((prev) => ({
          ...prev,
          BookDate: format(new Date(response.data.data.bookDate), 'yyyy/MM/dd'),
          Tenor: response.data.data.tenor,
          ProductCode: response.data.data.productCode,
          CustomerAccountNumber: response.data.data.customerAccountNumber,
        }));
        
        setValuesInvestmentLiquidation((valuesInvestmentLiquidation) => ({
          ...valuesInvestmentLiquidation,
          AccruedInterest: response.data.data.accruedInterest,
          InvestmentBalance: parseFloat((response.data.data.amount + response.data.data.accruedInterest).toFixed(2)),
          investmentNumber: response.data.data.investmentAccountNumber,
          // Tenor: response.data.data.tenor,
          // MaturityDate: format(
          //   new Date(response.data.data.maturityDate),
          //   'yyyy/MM/dd'
          // ),
          // SystemRate: response.data.data.systemRate,
          // Variance: response.data.data.variance,
          MaturityAmount: response.data.data.maturityAmount,
          // ProductCode: response.data.data.productCode,
          // BookDate: format(new Date(response.data.data.bookDate), 'yyyy/MM/dd'),
        }));
        setAmendId(response.data.data.id);
      } else {
        setErrorInvestmentNumber("Input a valid InvestMent Number");
      }
    } catch (error) {
      console.log(error.response.data.responseMessage, "it got here");
      if (error.response.data.responseMessage) {
        setErrorInvestmentNumber("Input a valid InvestMent Number");
      }
    } finally {
      setLoader(false);
    }
    
  };
console.log(valuesMainInvestmentAmendMent, "newwwwww")
  return {
    errMessage,
    message,
    errMesg,
    mesg,
    valuesGetExpectedInterest,
    getExpectedInterest,
    EmptyFields,
    handleInvestmentBookingAuthorize,
    // handleInvestmentLiquidationAuthorize,
    allInvestmentBooking,
    allInvestmentLiquidation,
    authorize,
    openBoxAuthorize,
    setOpenBoxAuthorize,
    openView,
    setUpdateID,
    setOpenView,
    getInvestmentBookingAll,
    getInvestmentLiquidationAll,
    setErrorInvestmentNumber,
    handleInvestmentLiquidationCreate,
    setValuesInvestmentLiquidation,
    valuesInvestmentLiquidation,
    errorInvestmentNumber,
    investmentNumber,
    valuesInvestmentAmendMentUx,
    handleInvestmentAmendMentCreate,
    setValuesInvestmentAmendMentUx,
    setValuesMainInvestmentAmendMent,
    loader,
    valuesMainInvestmentAmendMent,
    setDeleteId,
    handleInvestmentBookingCreate,
    deleteInvestmentBooking,
    valuesInvestmentBooking,
    handleChange,
    setValuesInvestmentBooking,
  };
};

export default useCustom;


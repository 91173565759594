import React, { useState } from 'react'
// import './LineChart.scss'
import { Pie } from 'react-chartjs-2';
import NoTransaction from './NoTransaction'
import MiniLoader from './MiniLoader';



const PieChart = ({data, loading}) => {
    const chartData = {
        // labels: ['Cash Dep', 'Cash Withd.', 'Fund Trf.', 'Buy Cash', 'Sell Cash'],
        labels: Object.keys(data),
        datasets:[
          {
            label:'Population',
            // data:[
            //   61,
            //   18,
            //   40,
            //   10,
            //   7
            // ],
            data: Object.values(data),
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              '#FA0A8A',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
            ]
          }
        ]
    }

    if(loading){
      return <MiniLoader/>
    }
    else if(!loading && Object.keys(data).length < 1){
      return <NoTransaction/>
    }
    else return (     
        <div className='BarChart'>
            <Pie
                data={chartData}
                options={{
                    title:{
                    display: true,
                    text:'Largest Cities In Lagos',
                    fontSize:25,
                    position: 'top left'
                    },
                    legend:{
                    display: true,
                    position: 'bottom'
                    }
                }}
            />
        </div>
    )
}


export default PieChart

import Item from "../../DatagridTable/item";
import { Grid } from '@material-ui/core';

const BranchCalender = ({details}) => {
    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Branch Code" name={details.branchCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Bank ID" name={details.bankId} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Month" name={details.month} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Holiday" name={details.holiday} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Year" name={details.year} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Start Time" name={details.startTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Close Time" name={details.closeTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
        </Grid>
      </Grid>
    )
}

export default BranchCalender

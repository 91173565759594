import { useState, useCallback, useEffect } from "react";
import "./IndividualBorrower.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
// import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
// import ErrorMessage from "../../../../ErrorMessage";
import { getCbrReports } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { useDispatch } from "react-redux";
// import moment from "moment";
// import { format } from "date-fns";
import { formattedDate } from "../../../../../formatter/date";


import { isLoading } from "../../../../../Api/redux-manager/actions";

const IndividualBorrower = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  // const branch = useSelector((state) => state.common.branch.branch);
  // const applicationDate = format(new Date(branch.workdate), "yyyy-MM-dd");

  // const [borrowerReport, setBorrowerReport] = useState({
  //   startDate: applicationDate,
  //   endDate: "",
  // });
  const [individualReports, setIndividualReports] =
    useState(null);
  // const [errors, setErrors] = useState({});

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerid",
      label: "Customer id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchcode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firstname",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "middlename",
      label: "Middle Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastname",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dateofbirth",
      label: "Date Of Birth",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "national_Identity_Number",
      label: "National Identity Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "drivers_License_Number",
      label:  "Drivers License Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:  "bvn",
      label:  "Bvn",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "passport_No",
      label: "Passport No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nationality",
      label: " Nationality",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "marital_Status",
      label: "Marital Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:   "phonenumber",
      label:   "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label:"Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lga",
      label: "LGA",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "employment_Status",
      label: "Employment Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "occupation",
      label: "Occupation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "business_Category",
      label: "Business Category",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "business_Sector",
      label: "Business Sector",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "borrower_Type" ,
      label:  "Borrower Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "other_ID" ,
      label:  "Other ID",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:  "taxID",
      label:  "Tax ID",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "picture_File_Path" ,
      label:  "Picture File Path",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:  "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:  "employer_Name",
      label:  "Employer Name",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:"employer_Address_Line1" ,
      label: "EEmployer Address Line1",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:"employer_Address_Line2" ,
      label: "Employer Address Line2",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "employer_City" ,
      label: "Employer City" ,
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:"employer_State" ,
      label: "Employer State",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "employer_Country" ,
      label:  "Employer Country",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "place_of_Birth" ,
      label: "Place of Birth" ,
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "work_Phone",
      label: "Work Phone",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "secondary_Address_Line1",
      label: "Secondary Address Line1",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name:"secondary_Address_Line2" ,
      label: "Secondary Address Line2",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "secondary_Address_CityLGA",
      label:"Secondary Address CityLGA" ,
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:"secondary_Address_State" ,
      label: "Secondary Address State",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name: "secondary_Address_Country",
      label: "Secondary Address Country",
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:  "spouses_Surname" ,
      label:  "Spouses Surname" ,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "spouses_First_Name" ,
      label: "Spouses First Name" ,
      options: {
        filter: true,
        sort: false,
      },
    }, {
      name:  "spouses_Middle_Name" ,
      label: "Spouses Middle Name" ,
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  // const handleChange = (name) => (e) => {
  //   const { value } = e.target;
  //   setErrors((prev) => ({
  //     ...prev,
  //     [name]: undefined,
  //   }));
  //   setBorrowerReport({
  //     ...borrowerReport,
  //     [name]: value,
  //   });
  // };
  // const resetHandlers = () => {
  //   EmptyField();
  //   setIndividualReports(false);
  // };

  // const EmptyField = () => {
  //   setBorrowerReport({
  //     startDate: "",
  //     endDate: "",
  //   });
  // };

  // const handleSearch = () => {
  //   const checkErrors = validateData();
  //   if (checkErrors || loading) {
  //     fetchIndividualBorrowerReports();
  //     console.log("i am livid");
  //   }
  //   console.log("i am believer");
  // };

  // const validateData = () => {
  //   const error = {};
  //   let errorCount = 0;
  //   const { startDate, endDate } = borrowerReport;
  //   if (startDate === "") {
  //     error.startDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }
  //   if (endDate === "") {
  //     error.endDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }

  //   setErrors(error);
  //   if (errorCount) {
  //     return false;
  //   }
  //   return true;
  // };
  const fetchIndividualBorrowerReports = useCallback(async () => {
    dispatch(isLoading());
    const response = await getCbrReports(token);
    if (response && response.data.individuals) {
      dispatch(isLoading());
      if (response && response.data.individuals.length > 0) {
        for (let i = 0; i < response.data.individuals.length; i++) {
          response.data.individuals[i].sn = i + 1;
        }
      }
      setIndividualReports(response.data.individuals);
    }
  }, [token, dispatch]);
  useEffect(() => {
    fetchIndividualBorrowerReports();
  }, [fetchIndividualBorrowerReports]);

  return (
    <div className="individualborrower border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Individual Borrower</p>
      </div>
      {/* <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="From"
              value={borrowerReport.startDate}
              onChange={handleChange("startDate")}
            ></input>
            {errors.startDate && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input
              name="To"
              type="date"
              value={borrowerReport.endDate}
              onChange={handleChange("endDate")}
            ></input>
            {errors.endDate && <ErrorMessage message={errors.endDate} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSearch}>Search</button>
          <button onClick={resetHandlers}>Reset</button>
        </div>
        <hr /> */}
        {individualReports && (
          <div>
            <Spinner loading={loading} />
            <Table
              title="Individual Borrower"
              subtitle=""
              name="List Of Individual Borrower"
              records={individualReports}
              options={options}
              columns={columns}
            />
          </div>
        )}
      {/* </main> */}
    </div>
  );
};

export default IndividualBorrower;

import {useState} from 'react'

const useValidation = () => {
    const [err, setErr] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [type, setType] = useState("");
     const validateRateChartSetUp = (rate, rateChartRanges) => {
        const { code, description, basis, tenorBasis } = rate;
        if (code === "") {
          setErr("code");
          setErrMsg("Rate code is required");
          return;
        }
        if (code.length > 10) {
          setErr("code");
          setErrMsg("Maximum of 10 charcters is required");
          return;
        }
        if (description === "" || description.length > 50) {
          setErr("description");
          setErrMsg(
            description > 50
              ? "Maximum of 50 charcters is required"
              : "Rate Code Description is required"
          );
          return;
        }
        if (basis === "") {
          setErr("basis");
          setErrMsg("select a valid rate chart basis");
          return;
        }
        if (basis === "Tenor" && tenorBasis === "") {
          setErr("tenorBasis");
          setErrMsg("select a valid tenor basis");
          return;
        }
        for (let i = 0; i < rateChartRanges.length; i++) {
            if (basis === "Tenor" && rateChartRanges[i].tenorFrom === "") {
              setErr(i);
              setType("tenorFrom");
              setErrMsg("tenor from is required");
              return;
            }
            if (basis === "Tenor" && rateChartRanges[i].tenorTo === "") {
              setErr(i);
              setType("tenorTo");
              setErrMsg("tenor to is required");
              return;
            }
            if (basis === "Tenor" && rateChartRanges[i].tenorTo === "") {
              setErr(i);
              setType("tenorTo");
              setErrMsg("tenor to is required");
              return;
            }
            if (rateChartRanges[i].effectiveDate === "") {
              setErr(i);
              setType("effectiveDate");
              setErrMsg("Effective date is required");
              return;
            }
            if (basis === "Tenor" && rateChartRanges[i].rateBasis === "") {
              setErr(i);
              setType("rateBasis");
              setErrMsg("select a valid rate basis");
              return;
            }
          }
        return true;
      };
    return { err, errMsg, setErr, setErrMsg, type, setType,  validateRateChartSetUp }
}

export default useValidation


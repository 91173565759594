import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from '../../../Details/CoreModules/CustomerCategory';


const View = ({details}) => {
    return (
        <DetailsContainer        
        title="Customer Category- Record Details"
        question="Are you sure you want to delete this parameter?"
        url={`${baseUrl}organization/CustomerCategory/Delete/${details.id}`}
      >
       <Details details={details}/> 
      </DetailsContainer>
    )
}

export default View

import { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  Button,
  OutlinedInput,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import DecisionModal from "../../Confirmbox/decision";
import FormDialog from "../../Confirmbox/form";
import ResponseModal from "../../Confirmbox/Modal";

import {
  openDecisionModal,
  closeDecisionModal,
  closeFormModal,
  closeResponseModal,
  openResponseModal,
  isLoading,
} from "../../Api/redux-manager/actions";
import axios from "axios";
import { baseUrl, Routes } from "../../Api/configUrl";
import { removeCustomerLien } from "../../Api/Hooks-manager/Lien/lienApi";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "200px",
  },
  centerBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    // marginTop: 20,
    // marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  children: {
    padding: "1.5rem 0px",
  },
  hideElements: {
    display: "none",
  },
}));

const RemovingLienModal = ({
  actionAccountObject,
  actionOnClose,
  onSuccess,
  open,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const openDialog = useSelector((state) => state.approve.opendecisionModal);
  const openresponseModal = useSelector(
    (state) => state.approve.openResponseModal
  );
  const [input, setInput] = useState({
    description: actionAccountObject.description,
    lienCode: actionAccountObject.lienCode,
    amount: actionAccountObject.amount,
    startDate: actionAccountObject.startDate,
    endDate: actionAccountObject.endDate,
    status: actionAccountObject.status,
  });

  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");
  const [response, setResponse] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  // const [lienStatus, setLienStatus] = useState(false);

  const removeLien = "false";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  console.log(input);

  const validateForm = () => {
    const { description, lienCode, amount } = input;
    if (description === "") {
      setErr("description");
      setMsg("Field is required");
      return;
    }
    if (lienCode === "") {
      setErr("lienCode");
      setMsg("Field is required");
      return;
    }
    if (amount === "") {
      setErr("amount");
      setMsg("Field is required");
      return;
    }
    return true;
  };

  const openDecisionDialog = () => {
    setErr("");
    setMsg("");
    dispatch(openDecisionModal(input));
  };

  const removeLienFromAccount = async () => {
    dispatch(isLoading());
    let data = { ...input };
    try {
      const res = await axios.put(
        `${baseUrl}account/Lien/Update/${actionAccountObject.id}`,
        data
      );
      if (res.data.status) {
        setResponse(res);
        dispatch(closeDecisionModal());
        dispatch(openResponseModal());
        onSuccess();
        dispatch(isLoading());
      }
    } catch (error) {
      if (error.response.data.responseMessage === "Unsuccessful") {
        setErrorMsg("Unsuccessful");
        return false;
      }
      return false;
    } finally {
      // dispatch(isLoading());
      return false;
      // window.location.reload();
    }
  };

  // const removeLienFromAccount = async () => {
  //   //dispatch loading
  //   dispatch(isLoading());
  //   const res = await removeCustomerLien(token, input);
  //   setResponse(res);
  //   dispatch(closeDecisionModal());
  //   dispatch(openResponseModal());
  //   dispatch(isLoading());
  //   onSuccess();
  // };

  const closeModal = (actionCreator = null) => {
    if (actionCreator) {
      dispatch(actionCreator());
    }
    actionOnClose();
  };

  return (
    <div>
      <FormDialog
        open={open}
        handleClose={() => closeModal(closeFormModal)}
        title={"Remove Lien From Account"}
      >
        <Grid container spacing={2} justifycontent="center" alignItems="center">
          <Grid item xs={12} className={classes.centerBtn}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={err === "input"}
            >
              {/* <InputLabel htmlFor="outlined-age-simple">
                {"Account Name"}
              </InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                value={input.glAccountName}
                onChange={handleChange}
                name="glAccountName"
                error={err === "glAccountName"}
              />
              {err === "glAccountName" && (
                <FormHelperText>{msg}</FormHelperText>
              )} */}

              {/* <InputLabel htmlFor="outlined-age-simple">
                {"Account Number"}
              </InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                value={input.glAccountNumber}
                onChange={handleChange}
                name="glAccountNumber"
                error={err === "glAccountNumber"}
              />
              {err === "glAccountNumber" && (
                <FormHelperText>{msg}</FormHelperText>
              )} */}

              {/* <TextField
                label="Description"
                id="outlined-size-small"
                defaultValue={input.description}
                className={classes.hideElements}
                size="small"
                variant="outlined"
                onChange={handleChange}
                name="description"
                error={err === "description"}
              />
              {err === "description" && (
                <FormHelperText>{msg}</FormHelperText>
              )}

              <br />

              <TextField
                label="Lien Code"
                id="outlined-size-small"
                defaultValue={input.lienCode}
                className={classes.hideElements}
                size="small"
                variant="outlined"
                onChange={handleChange}
                name="lienCode"
                error={err === "lienCode"}
              />
              {err === "lienCode" && <FormHelperText>{msg}</FormHelperText>}

              <br />

              <TextField
                label="Amount"
                id="outlined-size-small"
                defaultValue={input.amount}
                className={classes.hideElements}
                size="small"
                variant="outlined"
                onChange={handleChange}
                name="amount"
                error={err === "amount"}
              />
              {err === "amount" && <FormHelperText>{msg}</FormHelperText>}
              <br />

              <TextField
                label="Start Date"
                id="outlined-size-small"
                defaultValue={input.startDate}
                className={classes.hideElements}
                size="small"
                variant="outlined"
                onChange={handleChange}
                name="startDate"
                error={err === "startDate"}
              />
              {err === "startDate" && <FormHelperText>{msg}</FormHelperText>}
              <br />

              <TextField
                label="End Date"
                id="outlined-size-small"
                defaultValue={input.endDate}
                className={classes.hideElements}
                size="small"
                variant="outlined"
                onChange={handleChange}
                name="endDate"
                error={err === "endDate"}
              />
              {err === "endDate" && <FormHelperText>{msg}</FormHelperText>}
              <br /> */}

              <TextField
                label="Remove Lien"
                id="outlined-size-small"
                select
                defaultValue={input.status}
                size="small"
                variant="outlined"
                // className={classes.hideElements}
                onChange={handleChange}
                name="status"
                error={err === "status"}
                SelectProps={{
                  native: true,
                }}
              >
                <option></option>
                <option value={removeLien}>Remove Lien</option>
              </TextField>
              {err === "status" && <FormHelperText>{msg}</FormHelperText>}

              {/* <Select
                value={input.status}
                onChange={handleChange}
                name="status"
                error={err === "status"}
                input={
                  <OutlinedInput
                    labelWidth={120}
                    name="status"
                    id="outlined-decision-simple"
                  />
                }
              >
                {/* <MenuItem value="">
                  <em>Select an option</em>
                </MenuItem> */}
              {/* <MenuItem value="TotalFreeze">Total Freeze</MenuItem>
                <MenuItem value="DebitFreeze">Debit Freeze</MenuItem>
                <MenuItem value="CreditFreeze">Credit Freeze</MenuItem>
                <MenuItem value="NoFreeze">No Freeze</MenuItem> */}
              {/* </Select> */}
              {/* {err === "status" && <FormHelperText>{msg}</FormHelperText>} */}
            </FormControl>
            {/* <p style={{ color: "red" }}>{err === "status" ? msg : ""}</p> */}
          </Grid>

          <Grid item xs={12} className={classes.centerBtn}>
            <Button className={classes.button} onClick={openDecisionDialog}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormDialog>
      <DecisionModal
        question={"Are you sure you want to remove lien from this account?"}
        openDialog={openDialog}
        onClick={() => removeLienFromAccount()}
        onClose={() => dispatch(closeDecisionModal())}
      />
      <ResponseModal
        openDialog={openresponseModal}
        onClose={() => closeModal(closeResponseModal)}
        positiveDialog={response && response.status}
      >
        <div className={classes.children}>
          {response && response.data.responseMessage}
        </div>
      </ResponseModal>
    </div>
  );
};

export default RemovingLienModal;

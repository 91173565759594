import { Grid } from "@material-ui/core";
import Item from "../../DatagridTable/item";

const UserCreation = ({details}) => {
    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Username" name={details.userName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="First Name" name={details.firstName} />
        </Grid>
        {details.middleName && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Middle Name" name={details.middleName} />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Last Name" name={details.lastName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Mobile"
            name={details.mobileNumber ? details.mobileNumber : "Nil"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Country code" name={details.countryCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="State" name={details.stateName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Role" name={details.role} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Home Branch" name={details.homeBranch} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Institution" name={details.institution} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Gender" name={details.gender} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Email Address" name={details.emailAddress} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Loan Officer"
            name={details.isLoanOfficer ? "Yes" : "No"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Account Officer"
            name={details.isAccountOfficer ? "Yes" : "No"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Status"
            name={
              details.authorizationStatus === "PendingEditedAuthorization"
                ? "Pending Edited Authorization"
                : details.authorizationStatus
            }
          />
        </Grid>
      </Grid>
    )
}

export default UserCreation

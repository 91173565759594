import { useState, useEffect } from "react";
import {
  getCustomerByAccountNumber,
  debit,
} from "../../../../Api/Hooks-manager/Transactions";

import { DateConverter } from "../../../ConvertDate";
import { TransactionType, ModuleType } from "../../../../Helper/enum";

const Transfer = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [details, setDetails] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [debitAccDetails, setDebitAccDetails] = useState({
    accountName: "",
    branchCode: "",
    accountCurrency: "",
    availableBalance: null,
    accountProductCode: "",
    bvn: "",
    phoneNumber: "",
    email: "",
    isValidAccNum: false,
  });
  const [creditAccDetails, setCreditAccDetails] = useState({
    accountName: "",
    branchCode: "",
    accountCurrency: "",
    availableBalance: null,
    accountProductCode: "",
    bvn: "",
    phoneNumber: "",
    email: "",
    isValidAccNum: false,
  });
  const [errors, setErrors] = useState({});

  const [glTransaction, setGlTransaction] = useState({
    debitAccount: "", // account you are reoving money from
    creditAccount: "", // account you are sending money to
    postAmount: 0, //amount you want to transact
    transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
    transactionType: TransactionType.FTR, //enum
    entryBranch: user.operatingBranch, //get it from the user object operatingBranch
    narration: "Custmer to customer transfer", //auto generate not bmore than 50 character
    currencyCode: "NGN", //from the fectched account
    debitModule: ModuleType.CustomerAccount, //enum from moduleType
    creditModule: ModuleType.CustomerAccount, //enum from moduleType
    debitAccountBranch: "", // the account in the debitor's acccount number
    creditAccountBranch: "", // the account in the creditor's acccount number
    inputterIpAddress: "", //empty
    bankId: user.institution, //get it from the user object  institution code : operatingBranch
    chequeNumber: "", //empty
    controlNumber: "", //empty for now
    clearingType: "", //empty for now
    issueDate: "", //datetime
    bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
    routingNumber: "", //empty for now
    terminalId: "", //empty for now
    msgType: "", //empty for now
    msgStan: "", //empty for now
    depositorName: "", //for non cash deposit it should be empty
    depositorMobile: "", //for non cash deposit it should be empty
    depositorAddress: "", //for non cash deposit it should be empty
    denominations: [
      // {
      //   denomNo: "",
      //   denomCount: 0,
      //   denomTotal: 0,
      // },
    ], //send empty array for non cash transaction
    amlDataStatus: true,
    amlAccountNumber: "", // here down empty string
    amlCustomerNumber: "",
    amlIdNumber: "",
    amlBvnNumber: "",
    amlCustomerType: "",
    amlCustomer: "",
    amlWaiveId: true,
    amlTitle: "",
    amlFirstName: "",
    amlMiddleName: "",
    amlLastName: "",
    amlPhoneNumber: "",
    amlDob: "",
    amlPob: "",
    amlNationality: "",
    amlAddress: "",
    amlFundSource: "",
    amlOccupation: "",
    amlNumber: "",
    amlType: "",
    amlIssuer: "",
    amlCountry: "",
    amlState: "",
    amlExpiration: "",
    amlIssueDate: "",
  });

  const { debitAccount, creditAccount } = glTransaction;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "debitAccount" && value.length > 10) ||
      (name === "creditAccount" && value.length > 10)
    )
      return;
    const inputedVal =
      value === "" ? "" : Number(value) < 0 ? 0 : Number(value);
    setGlTransaction({
      ...glTransaction,
      [name]: name === "postAmount" ? inputedVal : value,
    });
  };
  
  console.log("fu", glTransaction);
  // console.log("debitAccDetails....", debitAccDetails);
  // console.log("credit....", creditAccDetails);

  const fetchDebitAccountDetails = async (debitAccNum) => {
    //gl account details
    setLoading(true);
    const response = await getCustomerByAccountNumber(
      user.jwtToken,
      debitAccNum
    );
    console.log("-----accounts details---", response);
    if (response && response.data) {
      setLoading(false);
      if (response.status) {
        if (response.data.authorizationStatus === "Authorized") {
          setGlTransaction({
            ...glTransaction,
            currencyCode: response.data.currency,
            debitAccountBranch: response.data.branchCode,
          });
          setDebitAccDetails({
            ...debitAccDetails,
            accountName: response.data.accountName,
            branchCode: response.data.branchCode,
            accountCurrency: response.data.currency,
            availableBalance: response.data.accountBalance,
            accountProductCode: response.data.accountProductCode,
            bvn: response.data.customer.bvn,
            phoneNumber: response.data.customer.phoneNumber,
            email: response.data.customer.email,
            isValidAccNum: true,
          });
          setDetails(response.data);
          setErrors({ ...errors, debitAccount: "" });
        } else {
          clearDebitAccountDetails();
          setErrors({ ...errors, debitAccount: "Unauthorized Account Number" });
        }
      } else {
        clearDebitAccountDetails();
        setErrors({ ...errors, debitAccount: response.message });
      }
    }
  };

  const fetchcreditAccountDetails = async (creditAccNum) => {
    setLoading(true);
    const response = await getCustomerByAccountNumber(
      user.jwtToken,
      creditAccNum
    );
    console.log("-----accounts details---", response);
    if (response && response.data) {
      setLoading(false);
      if (response.status) {
        if (response.data.authorizationStatus === "Authorized") {
          setGlTransaction({
            ...glTransaction,
            creditAccountBranch: response.data.branchCode,
          })
          setCreditAccDetails({
            ...creditAccDetails,
            accountName: response.data.accountName,
            branchCode: response.data.branchCode,
            accountCurrency: response.data.currency,
            availableBalance: response.data.accountBalance,
            accountProductCode: response.data.accountProductCode,
            bvn: response.data.customer.bvn,
            phoneNumber: response.data.customer.phoneNumber,
            email: response.data.customer.email,
            isValidAccNum: true,
          });
          setErrors({ ...errors, creditAccount: "" });
        } else {
          clearCreditAccountDetails();
          setErrors({ ...errors, creditAccount: response.message });
        }
      } else {
        clearDebitAccountDetails();
        setErrors({ ...errors, creditAccount: "Unauthorized Account Number" });
      }
    }
  };

  const makeTransfer = async () => {
    setLoading(true);
    const response = await debit(user.jwtToken, glTransaction);
    console.log("debited....", response);
    if (response && response.data) {
      setLoading(false);
      setMessage(response.message);
      if (response.status) {
        clearDebitAccountDetails();
        clearCreditAccountDetails();
        setGlTransaction({
          debitAccount: "", // account you are reoving money from
          creditAccount: "", // account you are sending money to
          postAmount: 0, //amount you want to transact
          transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
          transactionType: TransactionType.FTR, //enum
          entryBranch: user.operatingBranch, //get it from the user object operatingBranch
          narration: "Custmer to customer transfer", //auto generate not bmore than 50 character
          currencyCode: "NGN", //from the fectched account
          debitModule: ModuleType.CustomerAccount, //enum from moduleType
          creditModule: ModuleType.CustomerAccount, //enum from moduleType
          debitAccountBranch: "", // the account in the debitor's acccount number
          creditAccountBranch: "", // the account in the creditor's acccount number
          inputterIpAddress: "", //empty
          bankId: user.institution, //get it from the user object  institution code : operatingBranch
          chequeNumber: "", //empty
          controlNumber: "", //empty for now
          clearingType: "", //empty for now
          issueDate: "", //datetime
          bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
          routingNumber: "", //empty for now
          terminalId: "", //empty for now
          msgType: "", //empty for now
          msgStan: "", //empty for now
          depositorName: "", //for non cash deposit it should be empty
          depositorMobile: "", //for non cash deposit it should be empty
          depositorAddress: "", //for non cash deposit it should be empty
          denominations: [
            // {
            //   denomNo: "",
            //   denomCount: 0,
            //   denomTotal: 0,
            // },
          ], //send empty array for non cash transaction
          amlDataStatus: true,
          amlAccountNumber: "", // here down empty string
          amlCustomerNumber: "",
          amlIdNumber: "",
          amlBvnNumber: "",
          amlCustomerType: "",
          amlCustomer: "",
          amlWaiveId: true,
          amlTitle: "",
          amlFirstName: "",
          amlMiddleName: "",
          amlLastName: "",
          amlPhoneNumber: "",
          amlDob: "",
          amlPob: "",
          amlNationality: "",
          amlAddress: "",
          amlFundSource: "",
          amlOccupation: "",
          amlNumber: "",
          amlType: "",
          amlIssuer: "",
          amlCountry: "",
          amlState: "",
          amlExpiration: "",
          amlIssueDate: "",
        });
        return true;
      } else {
        return false;
      }
    }
  };

  const clearDebitAccountDetails = () => {
    setDebitAccDetails({
      accountName: "",
      branchCode: "",
      accountCurrency: "",
      availableBalance: null,
      accountProductCode: "",
      bvn: "",
      phoneNumber: "",
      email: "",
      isValidAccNum: false,
    });
    setGlTransaction({ ...glTransaction, currencyCode: "" });
  };

  const clearCreditAccountDetails = () => {
    setCreditAccDetails({
      accountName: "",
      branchCode: "",
      accountCurrency: "",
      availableBalance: null,
      accountProductCode: "",
      bvn: "",
      phoneNumber: "",
      email: "",
      isValidAccNum: false,
    });
  };

  useEffect(() => {
    if (debitAccount && debitAccount.length !== 10) {
      setErrors({ ...errors, debitAccount: "" });
      clearDebitAccountDetails();
    }
  }, [debitAccount]);

  useEffect(() => {
    if (creditAccount && creditAccount.length !== 10) {
      setErrors({ ...errors, creditAccount: "" });
      clearCreditAccountDetails();
    }
  }, [creditAccount]);

  useEffect(() => {
    if (debitAccount && debitAccount.length === 10) {
      fetchDebitAccountDetails(debitAccount);
      // debitGl();
    }
  }, [debitAccount]);

  useEffect(() => {
    if (creditAccount && creditAccount.length === 10) {
      fetchcreditAccountDetails(creditAccount);
      // debitGl();
    }
  }, [creditAccount]);

  const validateInput = () => {
    const { debitAccount, creditAccount, postAmount } = glTransaction;
    const errors = {};

    if (debitAccount === "") {
      errors.debitAccount = "Account No. is required";
    }

    if (debitAccount.length < 10) {
      errors.debitAccount = "10 characters are required";
    }
    if (debitAccount.length === 10 && !debitAccDetails.isValidAccNum) {
      errors.debitAccount = "Invalid Account No.";
    }
    if (creditAccount === "") {
      errors.creditAccount = "Account No. is required";
    }
    if (creditAccount.length < 10) {
      errors.creditAccount = "10 characters are required";
    }

    if (creditAccount.length === 10 && !creditAccDetails.isValidAccNum) {
      errors.creditAccount = "Invalid Account No.";
    }
    if (creditAccount !== "" && creditAccount === debitAccount) {
      errors.creditAccount = "You can't transfer to the same account";
    }
    if (postAmount === "") {
      errors.postAmount = "Field is required.";
    }
    // if (
    //   postAmount &&
    //   debitAccDetails.isValidAccNum &&
    //   postAmount > debitAccDetails.availableBalance
    // ) {
    //   errors.postAmount = "Insufficent funds";
    // }
    if (
      debitAccDetails.availableBalance != undefined &&
      postAmount > debitAccDetails.availableBalance
    ) {
      errors.postAmount = "Insufficient balance in debit account";
    }

    return errors;
  };

  const handleSubmit = () => {
    const checkErrors = validateInput();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setOpen(true);
  };

  return {
    glTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    errors,
    details,
    handleChange,
    makeTransfer,
    open,
    setOpen,
    message,
    handleSubmit,
    fetchDebitAccountDetails,
  };
};

export default Transfer;

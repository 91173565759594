import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Details from "../../../Details/CoreModules/RateChart";
import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
} from "../../../../Api/redux-manager/actions";
import { baseUrl, Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date"
import { useCallback } from "react";


const RateChart = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "code",
      label: "Rate Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Rate Code Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdDateTime",
      label: "Date Created",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "basis",
      label: "Basis",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        console.log(item)
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };

  const getAllPendingRateChart = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}organization/RateChart/GetAllWithRateChartRange`;
    const allResponse = await getHandler(token, url);
    dispatch(loading());
    const response = allResponse.data.filter(
      (item) =>
        item.authorizationStatus === "Pending" ||
        item.authorizationStatus === "PendingEditedAuthorization"
    );
    if (response && response.length) {
      for (let i = 0; i < response.length; i++) {
        response[i].sn = i + 1;
        response[i].createdDateTime = formattedDate(
          response[i].createdDateTime
        );
        response[i].dateAuthorized = formattedDate(response[i].dateAuthorized);
        response[i].updatedDateTime = formattedDate(
          response[i].updatedDateTime
        );
      }
      setData(response);
    }
  }, [dispatch, token]);

  useEffect(() => {
    getAllPendingRateChart();
  }, [getAllPendingRateChart, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Authorize Rate Chart"
        subtitle=""
        name="List of pending Rate Charts"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer title="Rate Chart - Record Details">
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize rate chart"
        question="Are you sure you want to authorize this rate chart?"
        url={`${Routes.authorizeRateChart}${details.id}`}
      />
    </div>
  );
};

export default RateChart;

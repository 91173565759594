
import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/CoreModules/BranchCalender";


const View = ({details}) => {
    return (
        <DetailsContainer
        title="Branch calendar - Record Details"
        question="Are you sure you want to delete this calendar?"
        url={`${baseUrl}organization/BranchHoliday/Delete/${details.id}`}
      >
        <Details details={details}/>
      </DetailsContainer>
    )
}

export default View

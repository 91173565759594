import { useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Button,
  OutlinedInput,
  MenuItem,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import DecisionModal from "../../Confirmbox/decision";
import FormDialog from "../../Confirmbox/form";
import ResponseModal from "../../Confirmbox/Modal";
import {
  openDecisionModal,
  closeDecisionModal,
  closeFormModal,
  authorizeForm,
  closeResponseModal
} from '../../Api/redux-manager/actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  centerBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    // marginTop: 20,
    // marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
    textTransform: "none",
  },
  children:{
    padding:"1.5rem 0px"
  }
}));

const AuthorizationModal = ({
  question,
  title,
  url,
  referenceNumber, // optional, only set it when you want to authorize funds
  bulkId, // optional, only set it when you want to authorize funds
  method, // optional, only set it when you want to authorize funds
  rejectUrl // optional, only set it when seperte rejection url available
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const open = useSelector(state => state.approve.openForm);
  const openDialog = useSelector(state => state.approve.opendecisionModal);
  const openresponseModal = useSelector(state => state.approve.openResponseModal);
  const status = useSelector(state => state.approve.status);
  const message = useSelector(state => state.approve.message);
  const [authorize, setAuthorize] = useState({
    reason: "",
    isApproved: '',
    authorizationStatus: 0,
  });


  const [err, setErr] = useState('');
  const [msg, setMsg] = useState('');

  const handleChange = (e) => {
    const {name, value} = e.target;
    if (name === 'isApproved'){
      setAuthorize(prev => ({...prev, [name]:value === 'true' ? true:false}))
      return
    }
    setAuthorize(prev => ({...prev, [name]:value}))
  }

  const validateForm = () => {
    const {
      isApproved,
      reason
    } = authorize;
    if (isApproved === ''){
      setErr('isApproved');
      setMsg('Field is required');
      return
    }
    if (reason === ''){
      setErr('reason');
      setMsg('Field is required');
      return 
    }
    return true
  }

  const openDecisionDialog = () => {
    if (validateForm()){
      setErr('');
      setMsg('');
      if (bulkId || referenceNumber){
        authorize.bulkId = bulkId;
        authorize.referenceNumber = referenceNumber;
        authorize.action = authorize.isApproved ? 'Authorize' : 'Reject';
      }
      dispatch(openDecisionModal(authorize))
    }
  }
  
  return (
    <div>
      <FormDialog open={open} handleClose={() => dispatch(closeFormModal())} title={title}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-simple">
                {"Take action"}
              </InputLabel>
              <Select
                value={authorize.isApproved}
                onChange={handleChange}
                name="isApproved"
                error={err === "isApproved"}
                input={
                  <OutlinedInput
                    labelWidth={120}
                    name="isApproved"
                    id="outlined-decision-simple"
                  />
                }
              >
                <MenuItem value="">
                  <em>Select an option</em>
                </MenuItem>
                <MenuItem value="true">Approve</MenuItem>
                <MenuItem value="false">Disapproved</MenuItem>
              </Select>
            </FormControl>
            <p style={{ color: "red" }}>{err === "isApproved" ? msg : ""}</p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              value={authorize.reason}
              onChange={handleChange}
              id="reason"
              label="Reason"
              multiline
              rows={4}
              name="reason"
              error={err === "reason"}
              helperText={err === "reason" ? msg : ""}
              className={classes.formControl}
            />
          </Grid>
          <Grid item xs={12} className={classes.centerBtn}>
            <Button className={classes.button} onClick={openDecisionDialog}>Submit</Button>
          </Grid>
        </Grid>
      </FormDialog>
      <DecisionModal
        question={question}
        openDialog={openDialog}
        onClick={() => dispatch(authorizeForm(
          token,authorize, 
          !authorize.isApproved && rejectUrl ? rejectUrl : url, 
          method
        ))}
        onClose={() => dispatch(closeDecisionModal())}
      />
      <ResponseModal
        openDialog={openresponseModal}
        onClose={() => dispatch(closeResponseModal())}
        positiveDialog={status}
      >
        <div className={classes.children}>
        {message === 'Success' ? 'Succesfully authorized' : message}
        </div>
      </ResponseModal>
    </div>
  );
};

export default AuthorizationModal;

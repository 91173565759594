import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getGLAccount,
  debit,
} from "../../../../Api/Hooks-manager/Transactions";
import { getAllBranches } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { formattedDate } from "../../../../formatter/date";
import { DateConverter } from "../../../ConvertDate";
import { TransactionType, ModuleType } from "../../../../Helper/enum";

const Transfer = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [allBranches, setAllBranches] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const allTellerTills = useSelector((state) => state.common.allTellerTills);

  const [debitAccDetails, setDebitAccDetails] = useState({
    accountName: "",
    accountBranch: "",
    currencyCode: "",
    availableBalance: null,
    isValidAccNum: false,
  });
  const [creditAccDetails, setCreditAccDetails] = useState({
    accountName: "",
    accountBranch: "",
    currencyCode: "",
    isValidAccNum: false,
  });
  const [errors, setErrors] = useState({});


  const [glTransaction, setGlTransaction] = useState({
    debitAccount: "", // account you are reoving money from
    creditAccount: "", // account you are sending money to
    postAmount: 0, //amount you want to transact
    transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
    transactionType: TransactionType.FTR, //enum
    entryBranch: user.operatingBranch, //get it from the user object operatingBranch
    debitAccountBranch: "", // the account in the debitor's acccount number
    creditAccountBranch: "", // the account in the creditor's acccount number
    narration: "GL to GL transfer", //auto generate not bmore than 50 character
    currencyCode: "NGN", //from the fectched account
    debitModule: ModuleType.GlAccount, //enum from moduleType
    creditModule: ModuleType.GlAccount, //enum from moduleType
    inputterIpAddress: "", //empty
    bankId: user.institution, //get it from the user object  institution code : operatingBranch
    chequeNumber: "", //empty
    controlNumber: "", //empty for now
    clearingType: "", //empty for now
    issueDate: "", //datetime
    bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
    routingNumber: "", //empty for now
    terminalId: "", //empty for now
    msgType: "", //empty for now
    msgStan: "", //empty for now
    depositorName: "", //for non cash deposit it should be empty
    depositorMobile: "", //for non cash deposit it should be empty
    depositorAddress: "", //for non cash deposit it should be empty
    denominations: [
      // {
      //   denomNo: "",
      //   denomCount: 0,
      //   denomTotal: 0,
      // },
    ], //send empty array for non cash transaction
    amlDataStatus: true,
    amlAccountNumber: "", // here down empty string
    amlCustomerNumber: "",
    amlIdNumber: "",
    amlBvnNumber: "",
    amlCustomerType: "",
    amlCustomer: "",
    amlWaiveId: true,
    amlTitle: "",
    amlFirstName: "",
    amlMiddleName: "",
    amlLastName: "",
    amlPhoneNumber: "",
    amlDob: "",
    amlPob: "",
    amlNationality: "",
    amlAddress: "",
    amlFundSource: "",
    amlOccupation: "",
    amlNumber: "",
    amlType: "",
    amlIssuer: "",
    amlCountry: "",
    amlState: "",
    amlExpiration: "",
    amlIssueDate: "",
  });

  const { debitAccount, creditAccount } = glTransaction;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "debitAccount" && value.length > 9) ||
      (name === "creditAccount" && value.length > 9)
    )
      return;
    const inputedVal =
      value === "" ? "" : Number(value) < 0 ? 0 : Number(value);
    setGlTransaction({
      ...glTransaction,
      [name]: name === "postAmount" ? inputedVal : value,
    });
  };

  // console.log("debitAccDetails....", debitAccDetails);
  // console.log("credit....", creditAccDetails);





  const tillGLAcct = (arr) => arr.map(({ accountNumber }) => accountNumber);
  const tillAccount = tillGLAcct(allTellerTills);

  // console.log(tillAccount, "ttttt");



  const fetchDebitAccountDetails = async  (debitAccNum) => {
    //gl account details
    setLoading(true);
    const response = await getGLAccount(token, debitAccNum);
    console.log("-----accounts details---", response);


    console.log(tillAccount, "checking.....");
    if (tillAccount.includes((glTransaction.debitAccount))){
      setLoading(false);
      clearDebitAccountDetails();
      setErrors({
        ...errors,
        debitAccount:
          "This GL cannot be used because it is a till account",
      });
    } else if (response && response.data) {
      setLoading(false);
      if (response.status) {
        if (
          response.data.authorizationStatus === "Authorized" &&
          response.data.glHierarchy === "Child" &&
          response.data.productAccount === false
        ) {
          setGlTransaction({
            ...glTransaction,
            currencyCode: response.data.currency,
            debitAccountBranch: response.data.branchCode,
          });
          setDebitAccDetails({
            ...debitAccDetails,
            accountName: response.data.accountName,
            accountBranch: response.data.branchCode,
            availableBalance: response.data.balance,
            isValidAccNum: true,
          });
          setErrors((e) => ({ ...e, debitAccount: "" }));
        } else if(response.data.authorizationStatus !== "Authorized") {
          setErrors({ ...errors, debitAccount: "Unauthorized Account Number"});
        }
        else if(response.data.productAccount === true){
          setErrors({ ...errors, debitAccount: "This GL cannot be used because it is linked to a product"});
        }
      } else {
        clearDebitAccountDetails();
        setErrors((e) => ({ ...errors, debitAccount: response.message }));
      }
    }
    // eslint-disable-next-line
  };

  const fetchcreditAccountDetails = async (creditAccNum) => {
    //gl account details
    setLoading(true);
    const response = await getGLAccount(token, creditAccNum);
    console.log("-----credit accounts details---", response);
    
    console.log(tillAccount, "checking.....");
    if (tillAccount.includes((glTransaction.creditAccount))){
      setLoading(false);
      clearCreditAccountDetails();
      setErrors({
        ...errors,
        creditAccount:
          "This GL cannot be used because it is a till account",
      });
    } else if (response && response.data) {
      setLoading(false);
      if (response.status) {
        if (
          response.data.authorizationStatus === "Authorized" &&
          response.data.glHierarchy === "Child" &&
          response.data.productAccount === false
        ) {
          setGlTransaction({
            ...glTransaction,
            entryBranch: response.data.branchCode,
            currencyCode: response.data.currency,
            creditAccountBranch: response.data.branchCode,
          });
          setCreditAccDetails({
            ...creditAccDetails,
            accountName: response.data.accountName,
            accountBranch: response.data.branchCode,
            //currencyCode: response.data.currency,
            isValidAccNum: true,
          });
          setErrors((e) => ({ ...e, creditAccount: "" }));
        } else if(response.data.authorizationStatus !== "Authorized") {
          setErrors({
            ...errors,
            creditAccount: "Unauthorized Account Number",
          });
        }
        else if(response.data.productAccount === true){
          setErrors({
            ...errors,
            creditAccount: "This GL cannot be used because it is linked to a product",
          });        }
      } else {
        clearCreditAccountDetails();
        setErrors((e) => ({ ...e, creditAccount: response.message }));
      }
    }
    // eslint-disable-next-line
  };

  const EmptyFieldMakeTransfer = () => {
    setGlTransaction({
      debitAccount: "", // account you are reoving money from
      creditAccount: "", // account you are sending money to
      postAmount: 0, //amount you want to transact
      transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
      transactionType: TransactionType.FTR, //enum
      entryBranch: user.operatingBranch, //get it from the user object operatingBranch
      debitAccountBranch: "", // the account in the debitor's acccount number
      creditAccountBranch: "", // the account in the creditor's acccount number
      narration: "GL to GL transfer", //auto generate not bmore than 50 character
      currencyCode: "NGN", //from the fectched account
      debitModule: ModuleType.GlAccount, //enum from moduleType
      creditModule: ModuleType.GlAccount, //enum from moduleType
      inputterIpAddress: "", //empty
      bankId: user.institution, //get it from the user object  institution code : operatingBranch
      chequeNumber: "", //empty
      controlNumber: "", //empty for now
      clearingType: "", //empty for now
      issueDate: "", //datetime
      bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
      routingNumber: "", //empty for now
      terminalId: "", //empty for now
      msgType: "", //empty for now
      msgStan: "", //empty for now
      depositorName: "", //for non cash deposit it should be empty
      depositorMobile: "", //for non cash deposit it should be empty
      depositorAddress: "", //for non cash deposit it should be empty
      denominations: [
        // {
        //   denomNo: "",
        //   denomCount: 0,
        //   denomTotal: 0,
        // },
      ], //send empty array for non cash transaction
      amlDataStatus: true,
      amlAccountNumber: "", // here down empty string
      amlCustomerNumber: "",
      amlIdNumber: "",
      amlBvnNumber: "",
      amlCustomerType: "",
      amlCustomer: "",
      amlWaiveId: true,
      amlTitle: "",
      amlFirstName: "",
      amlMiddleName: "",
      amlLastName: "",
      amlPhoneNumber: "",
      amlDob: "",
      amlPob: "",
      amlNationality: "",
      amlAddress: "",
      amlFundSource: "",
      amlOccupation: "",
      amlNumber: "",
      amlType: "",
      amlIssuer: "",
      amlCountry: "",
      amlState: "",
      amlExpiration: "",
      amlIssueDate: "",
    });
  };

  console.log(glTransaction, "details");

  const fetchAllBranches = async () => {
    const response = await getAllBranches(token);
    if (response && response.data) {
      setAllBranches(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  };
  const makeTransfer = async () => {
    setLoading(true);
    const response = await debit(token, glTransaction);
    console.log("debited....", response);
    if (response && response.data) {
      setLoading(false);
      setMessage(response.message);
      if (response.status) {
        EmptyFieldMakeTransfer();
        clearDebitAccountDetails();
        clearCreditAccountDetails();
        return true;
      } else {
        return false;
      }
    }
  };

  const clearDebitAccountDetails = useCallback(() => {
    setDebitAccDetails({
      accountName: "",
      entryBranch: "",
      currencyCode: "",
      availableBalance: null,
      isValidAccNum: false,
    });
    setGlTransaction((gl) => ({
      ...gl,
      currencyCode: "",
      debitAccountBranch: "",
    }));
  }, []);

  const clearCreditAccountDetails = () => {
    setCreditAccDetails({
      accountName: "",
      entryBranch: "",
      currencyCode: "",
      isValidAccNum: false,
    });
  };

  useEffect(() => {
    if (debitAccount && debitAccount.length < 9) {
      setErrors((e) => ({ ...e, debitAccount: "" }));
      clearDebitAccountDetails();
    }
  }, [debitAccount, clearDebitAccountDetails]);

  useEffect(() => {
    if (creditAccount && creditAccount.length < 9) {
      setErrors((e) => ({ ...e, creditAccount: "" }));
      clearCreditAccountDetails();
    }
  }, [creditAccount]);

  useEffect(() => {
    if (debitAccount && debitAccount.length === 9) {
      fetchDebitAccountDetails(debitAccount);
      // debitGl();
    }
  }, [debitAccount]);

  useEffect(() => {
    if (creditAccount && creditAccount.length === 9) {
      fetchcreditAccountDetails(creditAccount);
      // debitGl();
    }
  }, [creditAccount]);

  useEffect(() => {
    fetchAllBranches();
  }, []);

  const validateInput = () => {
    const { debitAccount, creditAccount, postAmount } = glTransaction;
    const errors = {};

    if (debitAccount === "") {
      errors.debitAccount = "Field is required";
    }

    if (debitAccount && debitAccount.length < 9) {
      errors.debitAccount = "9 characters are required";
    }
    if (debitAccount.length === 9 && !debitAccDetails.isValidAccNum) {
      errors.debitAccount = "Invalid Account No.";
    }
    if (creditAccount === "") {
      errors.creditAccount = "Field is required";
    }
    if (creditAccount && creditAccount.length < 9) {
      errors.creditAccount = "9 characters are required";
    }
    if (creditAccount.length === 9 && !creditAccDetails.isValidAccNum) {
      errors.creditAccount = "Invalid Account No.";
    }
    if (creditAccount !== "" && creditAccount === debitAccount) {
      errors.creditAccount = "You can't transfer to the same account";
    }
    // if (creditAccount === debitAccount) {
    //   errors.creditAccount = "Debit and credit account must not be the same"
    // }
    if (postAmount === "") {
      errors.postAmount = "Field is required.";
    }
    // if (debitAccDetails.availableBalance != undefined && postAmount > debitAccDetails.availableBalance){
    //   errors.postAmount = "Insufficient balance in debit account";
    // }
    // if (
    //   postAmount &&
    //   debitAccDetails.isValidAccNum &&
    //   postAmount > debitAccDetails.availableBalance
    // ) {
    //   errors.postAmount = "Insufficent funds";
    // }

    return errors;
  };

  const handleSubmit = () => {
    const checkErrors = validateInput();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setOpen(true);
  };

  return {
    glTransaction,
    allBranches,
    loading,
    debitAccDetails,
    creditAccDetails,
    errors,
    handleChange,
    makeTransfer,
    open,
    setOpen,
    message,
    handleSubmit,
    setGlTransaction,
  };
};

export default Transfer;

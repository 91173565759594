import React, { useState, useEffect, useCallback } from "react";
import "./ParameterSettings.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import useForm from "../../../../Api/Hooks-manager/coreModules/useCustom";
import { validateParameterSettings } from "../../../../Api/validator/validate11";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { baseUrl } from "../../../../Api/configUrl";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const ParameterSettings = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const [errors, setErrors] = useState({});
  const [openBox, setOpenBox] = useState(false);
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameterName",
      label: "Parameter Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameterValue",
      label: "Parameter Value",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameterLength",
      label: "Parameter Length",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllDefinedParameter = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}organization/BankParameters/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllDefinedParameter();
  }, [fetchAllDefinedParameter, updateTable]);

  const {
    getBankParametersAll,
    handleBankParametersCreate,
    valuesBankParametersCreate,
    handleChange,
    loader,
  } = useForm();

  useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);

  const handleConfirm = () => {
    setErrors(validateParameterSettings(valuesBankParametersCreate));
    if (
      Object.keys(validateParameterSettings(valuesBankParametersCreate))
        .length === 0
    ) {
      setOpenBox(true);
    }
  };

  const handleEditChange = e => {
    const { value, name } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleEditSubmit = () => {
    dispatch(openResquestDecisionModal());
  };

  return (
    <div>
      <main className="parameter--setting p-settings">
        <div style={{ position: "absolute" }}>
          <Spinner loading={loader || loading} />
        </div>
        <Confirm
          ApiCall={() => handleBankParametersCreate(fetchAllDefinedParameter)}
          openBox={openBox}
          setOpenBoxState={setOpenBox}
        />
        <div className="heading">
          <p>Parameter Settings</p>
        </div>
        <Paper style={{ padding: 32 }}>
          <section className="section--1">
            <div className="sub--section grid--1">
              <div className="label--input flex--3">
                <label>Parameter Name</label>
                <input
                  type="text"
                  name="parameterName"
                  value={valuesBankParametersCreate.parameterName}
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("parameterName") &&
                    "error-input-z"
                  }`}
                />
                {errors.parameterName && (
                  <ErrorMessage message={errors.parameterName} />
                )}
              </div>
              <div className="label--input flex--3">
                <label>Value</label>
                <input
                  type="text"
                  name="parameterValue"
                  value={valuesBankParametersCreate.parameterValue}
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("parameterValue") &&
                    "error-input-z"
                  }`}
                />
                {errors.parameterValue && (
                  <ErrorMessage message={errors.parameterValue} />
                )}
              </div>
              <div className="label--input flex--3">
                <label>Length</label>
                <input
                  type="number"
                  name="parameterLength"
                  value={Number(valuesBankParametersCreate.parameterLength)}
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("parameterLength") &&
                    "error-input-z"
                  }`}
                />
                {errors.parameterLength && (
                  <ErrorMessage message={errors.parameterLength} />
                )}
              </div>
            </div>
            <div className="button">
              <button onClick={handleConfirm}>Save</button>
            </div>
          </section>
        </Paper>
      </main>
      {!loading && (
        <Table
          title="Parameter Settings"
          subtitle=""
          name="List of Parameters"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleEditSubmit}
          details={details}
          handleChange={handleEditChange}
        />
      )}
    </div>
  );
};
export default ParameterSettings;

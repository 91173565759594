import axios from 'axios';
import { Routes as routes} from '../../configUrl';

export const getTransactionSummary = async (token, data, pageNumber, pageSize) => {

    return await axios({
        method: "post",
        url: `${routes.getTransctionSummaryReport}?&pageNumber=${pageNumber=1}&pageSize=${pageSize=100000}`,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        data
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        console.log(err)
        return {
            data:[],
        };
    });
};

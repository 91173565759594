import { useState } from "react";
import "./ChangePassword.scss";
import { isValidPassword } from "../../../Helper/validator";
import Spinner from "../../Backdrop/index";
import NewModal from "../../../Confirmbox/Modal";
import {changeAllPassword} from "../../../Api/Hooks-manager/changePassword/changePassword";
import ErrorMessage from "../../ErrorMessage";
import Confirm from "../../../Confirmbox/Confirm";

const ChangePassword = () => {
  const [error, setError] = useState({});
  const [openDecision, setOpenDecision] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [allPassword, setAllPassword] = useState([]);

  const [user, setUser] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  }),
  [responseError, setResponseError] = useState("");

const isValidateForm = (user) => {

    if (user.oldPassword === "" || 
    user.password === "" || user.confirmPassword === "") {
        setError({oldPassword: "Old password is required", 
        password : "new password is required", confirmPassword: "confirm password is required"});
        return false;
      }
    if (user.password !== user.confirmPassword) {
        setError({
            oldPassword: "", 
        password : "password mis-matched", confirmPassword: "password mis-matched"});
        return false;
      }
      if (user.oldPassword.length < 6 ){
          setError({oldPassword: "password must have more than five characters", password: "", confirmPassword: "" })
          return false
      }
      if (user.password.length < 6 ){
        setError({password: "password must have more than five characters", oldPassword: "", confirmPassword: "" })
        return false
    }
    if (user.confirmPassword.length < 6 ){
        setError({confirmPassword: "password must have more than five characters", password: "", oldPassword: "" })
        return false
    }
      setError({
        oldPassword: "", 
    password : "", confirmPassword: ""});
   return true;
}

const handleChange = (name) => (e) => {
    const { value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    let checkError = isValidateForm(user);
    if(checkError){
       let checkError = isValidateForm(user);
       setOpen(true);
    }
  }

  const passwordChange = async () => {
    setLoading(true);
    let response;
    const userDetail = JSON.parse(localStorage.getItem("user"));
        response = await changeAllPassword(userDetail.jwtToken, user);
        setLoading(false);
        setMessage(response.message);
        if (response.status) {
          return true
        }else{
          return false
        }

    } 
  return (
    <main className="reset--password">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={passwordChange}
        actionMsg={'Password change successful'}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <div className="heading">
        <p>Change Password</p>
      </div>

      <div style={{ position: "absolute" }}>
        {/* <Spinner loading={loader} /> */}

      </div>

      <section className="section--1">
        <div className="flex--3">
          <div className="reset--input">
            <h4 className="reset--p">
              What would you like your new password to be?
            </h4>
            <p>{responseError}</p>
            <div className="reset--input-1 flex--3">
              <label>Old password</label>
              <input 
              type="password"
              name="oldPassword"
              onChange={handleChange("oldPassword")}
              className={`${error.oldPassword && "error-input-z"}`} 
            
               />
               {error.oldPassword && <ErrorMessage message={error.oldPassword} />}
            </div>
            <div className="reset--input-1 flex--3">
              <label>New password</label>
              <input 
              type="password"
              name="password"
              onChange={handleChange("password")}
              className={`${error.password && "error-input-z"}`}  
              
              />
               {error.password && <ErrorMessage message={error.password} />}

            </div>
            <div className="reset--input-1 flex--3">
              <label>Confirm new password</label>
              <input 
              type="password"
              name="confirmPassword"
              onChange={handleChange("confirmPassword")}
              className={`${error.confirmPassword && "error-input-z"}`}  
               
                />
               {error.confirmPassword && <ErrorMessage message={error.confirmPassword} />}

            </div>
            <button onClick={handleSubmit}>Reset Password</button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChangePassword;

import React, { useState, useEffect } from "react";
import "./DenominationExchange.scss";
import MenuItem from '@material-ui/core/MenuItem';
import useTable from "../../../Tables/useTable";
import ErrorMessage from "../../../ErrorMessage";
import { getCountries } from "../../../../Api/places/getPlaces";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

const DenominationExchange = () => {
  const [screenChange, setScreenChange] = useState(false);
  // eslint-disable-next-line
  const [allTellerTills, setAllTellerTills] = useState([]);
  const [countries, setCountries] = useState([]);

  const handleScreenChange = (i) => {
    setScreenChange(i);
  };
  const [openView, setOpenView] = useState({});
  const [viewAll, setViewAll] = useState({});
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    country: "",
    tillAccountNumber: "",
    tellerName: "",
    branch: "",
    amount: "",
  });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const headCells = [
    { id: "id", label: "S/N" },
    { id: "tillAccountNumber", label: "Till Account No" },
    { id: "branchId", label: "Branch" },
    { id: "country", label: "Country" },
    { id: "tellerName", label: "Teller Name" },
    { id: "amount", label: "Amount" },
    { id: "action", label: "Action", disableSorting: true },
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage
  } = useTable(allTellerTills, headCells, filterFn);

  const fetchCountries = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getCountries(user.jwtToken);
    console.log(response, "wertyui");
    if (response) {
      setCountries(response.data);
    }
  };

  const handleSearch = (e) => {
    let target = e.target;
    setPage(0)
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
      },
    });
  };
  const handleChanger = (e) => {
    console.log("e.target.value", e.target.value);
    console.log("e.target.name", e.target.name);
    // const { name, value } = e.target;
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleNext = () => {
    let checkError = isValidateForm(user);
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setScreenChange(true);
  };

  const handleViewAll = (item) => {
    console.log(item, "itemmsmm");
    setViewAll(item);
    setOpenView(true);
  };

  const isValidateForm = (user) => {
    let errors = {};

    if (!user.country.trim()) {
      errors.country = "Select an option";
    }
    if (!user.tillAccountNumber.trim()) {
      errors.tillAccountNumber = "Field is required";
    }
    if (!user.tellerName.trim()) {
      errors.tellerName = "Field is required";
    }
    if (!user.branch.trim()) {
      errors.branch = "Field is required";
    }
    if (!user.amount.trim()) {
      errors.amount = "Field is required";
    }
    return errors;
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <main className="denomination--exchange">
        <div className="heading">
          <p>Denomination Exchange</p>
        </div>
        <section className="section--1 border--main">
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className="flex--3">
              <button
                className={`${!screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(false)}
              >
                1
              </button>
              <p>Transaction</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                2
              </button>
              <p>Denominations</p>
            </div>
          </div>
          {!screenChange && (
            <>
              <div className="sub--section flex--1">
                <div className="sub--main-1 flex--3">
                  <div className="label--input flex--3">
                    <label>Country</label>
                    <select
                      onChange={handleChanger}
                      className={error.country ? "error-input-z" : null}
                      value={user.countryCode}
                    >
                      <option value="Select a country">Select a country</option>
                      {countries.map((country) => (
                        <option value={country.countryCode2} key={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {error.country && <ErrorMessage message={error.country} />}
                  </div>
                  <div className="label--input flex--3">
                    <label>Teller Name</label>
                    <input
                     
                      name="tellerName"
                      value={user.tellerName}
                      onChange={handleChanger}
                      className={`${error.tellerName && "error-input-z"}`}
                    />
                    {error.tellerName && (
                      <ErrorMessage message={error.tellerName} />
                    )}
                  </div>
                  <div className="label--input flex--3">
                    <label>Amount</label>
                    <input
                     
                      name="amount"
                      value={user.amount}
                      onChange={handleChanger}
                      className={`${error.amount && "error-input-z"}`}
                    />
                    {error.amount && <ErrorMessage message={error.amount} />}
                  </div>
                </div>
                <div className="sub--main-1 flex--3">
                  <div className="label--input flex--3">
                    <label>Till Account No.</label>
                    <input
                      type="text"
                     
                      name="tillAccountNumber"
                      value={user.tillAccountNumber}
                      onChange={handleChanger}
                      className={`${
                        error.tillAccountNumber && "error-input-z"
                      }`}
                    />
                    {error.tillAccountNumber && (
                      <ErrorMessage message={error.tillAccountNumber} />
                    )}
                  </div>
                  <div className="branch--input flex--2">
                    <div className="flex--3">
                      <label>Branch</label>
                      <input
                      
                        name="branch"
                        value={user.branch}
                        onChange={handleChanger}
                        className={`branch-input ${error.branch && "error-input-z"}`}
                      />
                    </div>
                    <input className="input" />
                  </div>
                  {error.branch && <ErrorMessage message={error.branch} />}
                </div>
              </div>
              <div className="button-last">
                <button onClick={handleNext}>Next</button>
              </div>
              <hr />
              <h4>Denomination Exchange</h4>
              <div className="sub--section flex--2">
                <div className="main-filter flex--2">
                  <input
                    className="filter"
                    placeholder="Filter"
                    onChange={handleSearch}
                  />
                
                  <button className="button--second">Remove Filter</button>
                </div>
              </div>
              {/* Table comes in here */}
              <TblContainer>
                <TblHead />
                <tbody>
                  {recordsAfterPagingAndSorting().map((item, index) => (
                    <tr key={item.id}>
                      {console.log(item, "investmentinvestment")}
                      <th scope="row">{index + 1}</th>
                      <td>{item.tillAccountNumber}e</td>
                      <td>{item.branchId}</td>
                      <td>{item.country}</td>
                      <td>{item.tellerName}</td>
                      <td>{item.amount}</td>
                      <td>{item.authorizationStatus}</td>
                      <td>
                        <div className={`table--icon ${(item.authorizationStatus === ('Authorized') || item.authorizationStatus === ('Rejected')) && 'disabled-delete'}`}>
                        <DotMenu>
                          <MenuItem>Edit</MenuItem>
                          <MenuItem>Delete</MenuItem>
                          <MenuItem onClick={() => handleViewAll()}>View</MenuItem>
                        </DotMenu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TblContainer>
              <TblPagination />
            </>
          )}
          {screenChange && (
            <>
              <main className="sub-main">
                {/* <section className='sub-main--1 flex--2'>
                                        <div className='flex--2'>
                                            <p>Amount</p>
                                            <input/>
                                        </div>
                                        <div className='flex--2'>
                                            <p>Net Amount</p>
                                            <input/>
                                        </div>
                                    </section> */}
                <section className="sub-main--2 flex--3">
                  <div className="multiplier-input flex--2">
                    <input />
                    <span>&times;</span>
                    <input/>
                    <span>&#61;</span>
                    <input />
                  </div>
                  <div className="multiplier-input flex--2">
                    <input />
                    <span>&times;</span>
                    <input/>
                    <span>&#61;</span>
                    <input />
                  </div>
                  <div className="multiplier-input flex--2">
                    <input />
                    <span>&times;</span>
                    <input/>
                    <span>&#61;</span>
                    <input />
                  </div>
                  <div className="multiplier-input flex--2">
                    <input />
                    <span>&times;</span>
                    <input/>
                    <span>&#61;</span>
                    <input />
                  </div>
                </section>
              </main>
              <div className="button-last button-last--1 flex--2">
                <div className="button-container flex--1">
                  <button onClick={() => handleScreenChange(false)}>
                    Previous
                  </button>
                  <button onClick={() => handleScreenChange(true)}>Save</button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default DenominationExchange;

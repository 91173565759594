import { useState, useCallback,useEffect } from "react";
import "./LoanAccrualReport.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { getAllLoanAccrual, getBranchCode  } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../../Api/redux-manager/actions";
import ErrorMessage from "../../../../ErrorMessage";
import { formattedDate } from "../../../../../formatter/date"

const LoanAccrualReport = () => {
  const dispatch = useDispatch(isLoading());
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const [errors, setErrors] = useState({});
  const [branchesCode, setBranchesCode] = useState([])
  const [report, setReport] = useState({
    branchCode: "",
  });
  const [allAccrualReport, setAllAccrualReport] = useState(null);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
        
      },
    },
    {
      name: "loanAccountNo",
      label: "Loan Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanProduct",
      label: "Loan product.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "repaymentAccountNo",
      label: "Repayment Acc No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanCurrency",
      label: " Loan Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAmount",
      label: " Loan Amnt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amountDisbursed",
      label: "Amnt Disbursed ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bookedDate",
      label: "Loan Book Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "startDate",
      label: "Loan start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "disbursementDate",
      label: "Loan Disbursement Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "tenor",
      label: "Tenor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date ",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "interestRate",
      label: "Interest Rate",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastAccrualAmount",
      label: "Last Accrued Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastAccrualDate",
      label: "Loan Accrual Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>

      },
    },
    {
      name: "accruedAmountTillDate",
      label: "Accrued Amnt. Till Date",
      options: {
        filter: true,
        sort: false,
      },
    },
   
  ];
  // console.log(allAccrualReport);

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchBranchCode = useCallback(async () => {
    const response = await getBranchCode(token,branchesCode);
    if (response && response.data){
        setBranchesCode(response.data)
      }  
  }, [token,branchesCode])
  useEffect(() => {
    fetchBranchCode();
  }, [])


  const fetchLoanAccrualReport = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllLoanAccrual(token, report);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setAllAccrualReport(response.data);
    }
  }, [report, token, dispatch]);

  const handleChanges = (name) => (e) => {
    const { value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));
    setReport({
      ...report,
      [name]: value,
    });
  };
  const resetHandlerThree = () => {
    EmptyField();
    setAllAccrualReport(false);
  };

  const EmptyField = () => {
    setReport({
      branchCode: "",
    });
  };
  const handleSubmission = () => {
    const checkErrors = validateData();
    if (checkErrors || loading) {
      // dispatch(isLoading());
      fetchLoanAccrualReport();
      console.log("i am livid");
    }
    console.log("i am believer");
  };

  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { branchCode } = report;
    if (branchCode === "") {
      error.branchCode = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };
  return (
    <div className="loanAccrualReport border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Loan Accrual Report</p>
      </div>

      <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Branch Code</label>
            <select
              type="text"
              name="branchCode"
              value={report.branchCode}
              onChange={handleChanges("branchCode")}
            >
                <option >Select a branch code</option>
              {branchesCode.map((codes,i) => (
                <option value={codes.code} key={i}>{codes.code}</option>
              ))}
              </select>
            {errors.branchCode && <ErrorMessage message={errors.branchCode} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSubmission}>Search</button>
          <button onClick={resetHandlerThree}>Reset</button>
        </div>
        <hr />

        {allAccrualReport && (
          <div>
            <Spinner loading={loading} />
              <Table
                title="Loan Accrual Report"
                subtitle=""
                name="List of  Accrual Reports"
                records={allAccrualReport}
                options={options}
                columns={columns}
              />
          </div>
        )}
      </main>
    </div>
  );
};

export default LoanAccrualReport;

import React from 'react'
import './Report.scss'

const Report = () => {
    return (
        <main className='reports--container border--main'>
            <div className='heading'>
                <p>Treasury Reports</p>  
            </div>
            <section className="section--1 flex--4">
                <div className='box flex--3'>
                    <p>Total Active Bonds</p>
                    <h1>281</h1>
                </div>
                <div className='box box--2 flex--3'>
                    <p>Total Active Treasure Billls</p>
                    <h1>281</h1>
                </div>
                <div className='box box--3 flex--3'>
                    <p>Total Active Equities</p>
                    <h1>281</h1>
                </div>
                <div className='box flex--3 box--4'>
                    <p>Total Matured Bonds</p>
                    <h1>281</h1>
                </div>
                <div className='box flex--3 box--5'>
                    <p>Total Matured Treasure Bills</p>
                    <h1>281</h1>
                </div>
                <div className='box flex--3 box--6'>
                    <p>Total Active Bonds</p>
                    <h1>281</h1>
                </div>
            </section>
            <section className='section--3 flex--4'>
                <div className='box box--1'>
                    <p>Investment Portfolio</p>
                    <button className='border--main'>View Report</button>
                    <span className='span'>c</span>
                </div>
                <div className='box box--2'>
                    <div className='total flex--2'>
                        <p>Total Cash: <span>180,000,000</span></p>
                        <p>Total Debt: <span>180,000,000</span></p>
                        <span className='span'>v</span>
                    </div>
                </div>
            </section>
        </main> 
    )
}

export default Report

import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Table from "../../../DatagridTable/table";
import Details from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import Item from "../../../DatagridTable/item";
import {
  getHandler,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
} from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.main,
  },
  hr: {display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  item: {
    width: "100%",
  },
}));

const ManageRoles = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Role Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Role Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: async (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        item.permissions = await getPermissionsByRoleName(item.name);
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };

  const getPermissionsByRoleName = async (roleName) => {
    dispatch(loading());
    const url = `${baseUrl}user/Role/GetAllPermissions/${roleName}`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
      return response.data.permissions.map((item) => item.parameter); // string[]
    }
  };

  const fetchAllRoles = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}user/Role/GetAllRolesPendingAuthorization`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].createdDateTime = formattedDate(
          response.data[i].createdDateTime
        );
        response.data[i].dateAuthorized = formattedDate(
          response.data[i].dateAuthorized
        );
        response.data[i].updatedDateTime = formattedDate(
          response.data[i].updatedDateTime
        );
      };
    }
      setData(response.data);
    }
  }, [dispatch, token]);

  const Route = `${baseUrl}user/Role/Authorize/`;

  useEffect(() => {
    fetchAllRoles();
  }, [fetchAllRoles, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load  && <Table
        title="Manage Roles"
        subtitle=""
        name="List of Roles"
        records={data}
        options={options}
        columns={columns}
      />}
      <Details title="Manage Roles - Record Details">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Role Name" name={details.name} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Description" name={details.description} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Role Permissions" name={details.rolePermissions} />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Status"
              name={
                details.authorizationStatus === "PendingEditedAuthorization"
                  ? "Pending Edited Authorization"
                  : details.authorizationStatus
              }
            />
          </Grid>
          <Divider className={classes.hr} />
          <Grid item xs={12}>
          <h6 className={classes.text}>Permissions</h6>
          </Grid>
          {details && details.permissions && details.permissions.length > 0 ? (
            details.permissions.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
                <Item label="" name={item} className={classes.item}/>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} className={classes.noData}>
              <Item label="" name="No permmision" className={classes.item}/>
            </Grid>
          )}
        </Grid>
      </Details>
      <AuthorizationModal
        title="Authorize Roles"
        question="Are you sure you want to authorize this role?"
        url={`${Route}${details.id}`}
      />
    </div>
  );
};

export default ManageRoles;

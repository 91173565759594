import "./GlTransfers.scss";
import NumberFormat from "react-number-format";
import BusinessLogic from "./businessLogic";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";

const GlTransfers = () => {
  const {
    glTransaction,
    setGlTransaction,
    allBranches,
    loading,
    debitAccDetails,
    creditAccDetails,
    errors,
    handleChange,
    makeTransfer,
    open,
    setOpen,
    message,
    handleSubmit,
  } = BusinessLogic();
  return (
    <main className="gl--transfers">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={makeTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <section className="section--1">
        <div className="heading">
          <p>Miscellaneous GL Transfers</p>
        </div>

        <>
        <div style={{ paddingTop: 30, padding: 25 }}>
          <div className="sub--section flex--1">
            <div className="sub--main">
              <div className="input flex--1">
                <div className="label--input flex--3">
                  <label>Debit GL Account</label>
                  <input
                    placeholder=""
                    className={`debit--GL--input ${
                      errors.debitAccount ? "error-input-z" : null
                    }`}
                    name="debitAccount"
                    value={glTransaction.debitAccount}
                    onChange={handleChange}
                  />
                </div>
                <input
                  disabled
                  value={debitAccDetails.accountName}
                />
              </div>
              {errors.debitAccount && (
                <div className="max--error">
                  <ErrorMessage message={errors.debitAccount} />
                </div>
              )}
              <div className="label--input flex--3">
                <label>Currency</label>
                <input
                  disabled
                  placeholder=""
                  className="currency-input"
                  name="currencyCode"
                  value={glTransaction.currencyCode}
                  onChange={() => ""}
                />
              </div>

              <div className="currency--credit">
                <div className="credit flex--1">
                  <div className="label--input flex--3">
                    <label>Credit GL Account</label>
                    <input
                      placeholder=""
                      className={`debit--GL--input ${
                        errors.creditAccount ? "error-input-z" : null
                      }`}
                      name="creditAccount"
                      value={glTransaction.creditAccount}
                      onChange={handleChange}
                    />
                  </div>
                  <input
                    placeholder=""
                    disabled
                    value={creditAccDetails.accountName}
                  />
                </div>
                {errors.creditAccount && (
                  <ErrorMessage message={errors.creditAccount} />
                )}
                <div className="label--input-currency flex--3">
                  <label>Currency</label>
                  <input
                    disabled
                    placeholder=""
                    className="currency-input"
                    name="currency"
                    value={glTransaction.currencyCode}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>

            <div className="sub--main--1">
              <div className="input flex--1">
                {/* <div className="label--input flex--3">
                  <label>GL Debit Branch</label>
                  <select
                  disabled
              name="entryBranch"
              value={glTransaction.entryBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
                </div> */}
                <div className="label--input flex--3">
                  <label>Debit Branch</label>
                  <select
              name="debitAccountBranch"
              value={debitAccDetails.accountBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
                </div>
              </div>
              <div className="label--input--amount">
                <div className="input flex--1">
                  {/* <div className="flex--3">
                    <label>GL Credit Branch</label>
                    <select
                    disabled
              name="entryBranch"
              value={glTransaction.entryBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
                  </div> */}
                  <div className="flex--3">
                    <label>Credit Branch</label>
                    <select
              name="creditAccountBranch"
              value={creditAccDetails.accountBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
                  </div>
                </div>

                <div className="flex--3">
                  <label>Amount</label>
                  <NumberFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator={true}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  inputMode="numeric"
                  value={glTransaction.postAmount}
                  // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                  className={`debit--GL--input ${
                    errors.postAmount ? "error-input-z" : null
                  }`}
                  name="postAmount"
                  onValueChange={({floatValue})=>handleChange({
                    target: {
                      name:'postAmount', 
                      value: floatValue
                    }
                  })}
              />
                  {/* <input
                    placeholder=""
                    type="number"
                    className={`debit--GL--input ${
                      errors.postAmount ? "error-input-z" : null
                    }`}
                    name="postAmount"
                    value={glTransaction.postAmount.toFixed(2)}
                    onChange={handleChange}
                  /> */}
                  {errors.postAmount && (
                    <ErrorMessage message={errors.postAmount} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="tobi">
            <div className="label--input-narration flex--3">
              <label>Narration</label>
              <input
                type="text"
                className={`input ${
                  errors.narration ? "error-input-z" : null
                }`}
                name="narration"
               value={glTransaction.narration}
               onChange={handleChange}
              />
               {errors.narration && <ErrorMessage message={errors.narration} />}

            </div>
          </div>

          <div className='button-last flex--2'>
              <button onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </>
      </section>
    </main>
  );
};
export default GlTransfers;

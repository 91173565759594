import { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from "../../../../Api/configUrl";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import SearchDropdown from "../../../SearchableDropdown/Search";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    width: 10,
    height: 10,
  },
  boxdiv: {
    position: "relative",
    top: "38%",
  },
  textbox: {
    height: 44,
    marginLeft: 10,
    borderRadius: 5,
    margin: "1px 0px",
    position: "relative",
  },
  selectbox: {
    height: 44,
    marginLeft: 10,
    borderRadius: 5,
    margin: "1px 0px",
    position: "relative",
  },
  add: {
    borderRadius: 50,
    width: 37,
    height: 37,
    backgroundColor: "white",
    color: "#d0021b",
    boxShadow: "2px 2px 2px 2px #ebacb3",
    marginBottom: 8,
    textAlign: "center",
  },
  chargebox:{
    border:'1px solid black',
    marginBottom:30
  },
  label:{
    paddingLeft:12
  }
}));

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
  glAccountNumber,
  handleChangeSelect,
  allCurrencyDefinition,
  error,
  handleChangeOtherFeesAndIncome,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <EditModal
      title="Update Product Definition"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}treasury/TreasuryProduct/update/${details.id}`}
    >
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Product Definition" {...a11yProps(0)} />
            <Tab label="Charge Table" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="label--input label--select flex--3"
            >
              <label className={classes.label}>Product Code</label>
              <input
                type="text"
                value={details.productCode}
                onChange={handleEditChange("productCode")}
                className={classes.textbox}
              />
              {errorEdit.productCode && (
                <ErrorMessage message={errorEdit.productCode} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Product Name</label>
              <input
                type="text"
                onChange={handleEditChange("productName")}
                value={details.productName}
                name="productName"
                className={classes.textbox}
              />
              {errorEdit.productName && (
                <ErrorMessage message={errorEdit.productName} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.checkboxdiv}>
              <div className={classes.boxdiv}>
                <input
                  className={classes.checkbox}
                  type="checkbox"
                  name="deactivateProduct"
                  onChange={handleEditChange("deactivateProduct")}
                  checked={details.deactivateProduct}
                  id="deactivateProduct"
                />
                {errorEdit.customerName && (
                  <ErrorMessage message={errorEdit.customerName} />
                )}
                <label className="span" htmlFor="deactivateProduct">
                  {" "}
                  Deactivate Product
                </label>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Portfolio Type</label>
              <select
                onChange={handleEditChange("portFolioType")}
                value={details.portFolioType}
                name="portFolioType"
                className={classes.selectbox}
              >
                <option value="">Select An Option</option>
                <option value="Bank">Bank</option>
                <option value="Customer">Customer</option>
              </select>
              {errorEdit.portFolioType && (
                <ErrorMessage message={errorEdit.portFolioType} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Products Buy-leg</label>
              <select
                type="text"
                name="productBuyLeg"
                onChange={handleEditChange("productBuyLeg")}
                className={classes.selectbox}
                value={details.productBuyLeg}
              >
                <option value="">Select An Option</option>
                <option value="Bank">Bank</option>
                <option value="Customer">Customer</option>
              </select>
              {errorEdit.productBuyLeg && (
                <ErrorMessage message={errorEdit.productBuyLeg} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Products Sell-leg</label>
              <select
                type="text"
                name="productSellLeg"
                onChange={handleEditChange("productSellLeg")}
                className={classes.selectbox}
                value={details.productSellLeg}
                className={classes.selectbox}
              >
                <option value="">Select An Option</option>
                <option value="Bank">Bank</option>
                <option value="Customer">Customer</option>
              </select>
              {errorEdit.productSellLeg && (
                <ErrorMessage message={errorEdit.productSellLeg} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Default Interest Rate</label>
              <input
                type="number"
                step="any"
                onChange={handleEditChange("defaultInterestRate")}
                value={details.defaultInterestRate}
                name="defaultInterestRate"
                className={classes.textbox}
              />
              {errorEdit.defaultInterestRate && (
                <ErrorMessage message={errorEdit.defaultInterestRate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Minimum Interest Rate</label>
              <input
                type="number"
                step="any"
                onChange={handleEditChange("minimumInterestRate")}
                value={details.minimumInterestRate}
                name="minimumInterestRate"
                className={classes.textbox}
              />
              {errorEdit.minimumInterestRate && (
                <ErrorMessage message={errorEdit.minimumInterestRate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Maximum Interest Rate</label>
              <input
                type="number"
                step="any"
                onChange={handleEditChange("maximumInterestRate")}
                value={details.maximumInterestRate}
                name="maximumInterestRate"
                className={classes.textbox}
              />
              {errorEdit.maximumInterestRate && (
                <ErrorMessage message={errorEdit.maximumInterestRate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Rate Basis</label>
              <select
                onChange={handleEditChange("rateBasis")}
                value={details.rateBasis}
                name="rateBasis"
                className={classes.selectbox}
              >
                <option value="">Select An Option</option>
                <option value="Monthly">Per Month</option>
                <option value="Annum">Per Annum</option>
              </select>
              {errorEdit.rateBasis && (
                <ErrorMessage message={errorEdit.rateBasis} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Coupon Maturity Basis</label>
              <select
                onChange={handleEditChange("couponMaturityBasis")}
                value={details.couponMaturityBasis}
                name="couponMaturityBasis"
                className={classes.selectbox}
              >
                <option value="">Select An Option</option>
                <option value="ZeroCoupon ">Zero Coupon</option>
                <option value="UpFront">Upfront Interest</option>
              </select>
              {errorEdit.couponMaturityBasis && (
                <ErrorMessage message={errorEdit.couponMaturityBasis} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Effective Rate Date</label>
              <input
                type="date"
                step="any"
                onChange={handleEditChange("effectiveRateDate")}
                value={details.effectiveRateDate}
                name="effectiveRateDate"
                className={classes.textbox}
              />
              {errorEdit.effectiveRateDate && (
                <ErrorMessage message={errorEdit.effectiveRateDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Default Tenor</label>
              <input
                type="number"
                step="any"
                onChange={handleEditChange("defaultTenor")}
                value={details.defaultTenor}
                name="defaultTenor"
                className={classes.textbox}
              />
              {errorEdit.defaultTenor && (
                <ErrorMessage message={errorEdit.defaultTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Minimum Tenor</label>
              <input
                type="number"
                step="any"
                onChange={handleEditChange("minimumTenor")}
                value={details.minimumTenor}
                name="minimumTenor"
                className={classes.textbox}
              />
              {errorEdit.minimumTenor && (
                <ErrorMessage message={errorEdit.minimumTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Tenor Basis</label>
              <select
                onChange={handleEditChange("tenorBasis")}
                value={details.tenorBasis}
                name="tenorBasis"
                className={classes.selectbox}
              >
                <option value="">Select An Option</option>
                <option value="Days">Days</option>
                <option value="Months">Months</option>
                <option value="Years">Years</option>
              </select>
              {errorEdit.tenorBasis && (
                <ErrorMessage message={errorEdit.tenorBasis} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Maximum Tenor</label>
              <input
                type="number"
                step="any"
                onChange={handleEditChange("maximumTenor")}
                value={details.maximumTenor}
                name="maximumTenor"
                className={classes.textbox}
              />
              {errorEdit.maximumTenor && (
                <ErrorMessage message={errorEdit.maximumTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Issue Date</label>
              <input
                type="date"
                step="any"
                onChange={handleEditChange("issueDate")}
                value={details.issueDate}
                name="issueDate"
                className={classes.textbox}
              />
              {errorEdit.issueDate && (
                <ErrorMessage message={errorEdit.issueDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Issuer Name</label>
              <input
                type="text"
                step="any"
                onChange={handleEditChange("issuerName")}
                value={details.issuerName}
                name="issuerName"
                className={classes.textbox}
              />
              {errorEdit.issuerName && (
                <ErrorMessage message={errorEdit.issuerName} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Issue Price</label>
              <input
                type="text"
                step="any"
                onChange={handleEditChange("issuerPrice")}
                value={details.issuerPrice}
                name="issuerPrice"
                className={classes.textbox}
              />
              {errorEdit.issuerPrice && (
                <ErrorMessage message={errorEdit.issuerPrice} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Start of Trading Date</label>
              <input
                type="date"
                onChange={handleEditChange("startofTradingDate")}
                value={details.startofTradingDate}
                name="startofTradingDate"
                className={classes.textbox}
              />
              {errorEdit.startofTradingDate && (
                <ErrorMessage message={errorEdit.startofTradingDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Profit/Loss Account</label>
              <input
                type="text"
                onChange={handleEditChange("profitOrLossAccount")}
                value={details.profitOrLossAccount}
                name="profitOrLossAccount"
                className={classes.textbox}
              />
              {errorEdit.profitOrLossAccount && (
                <ErrorMessage message={errorEdit.profitOrLossAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Profit/Loss Account</label>
              <div 
                className={`border--norm ${
                  Object.keys(errorEdit).includes("productCreditAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="productCreditAccount"
                />
              </div>
              {errorEdit.productCreditAccount && (
                <ErrorMessage message={errorEdit.productCreditAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Products Debit Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorEdit).includes("productDebitAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="productDebitAccount"
                  className={classes.selectbox}
                />
              </div>
              {errorEdit.productDebitAccount && (
                <ErrorMessage message={errorEdit.productDebitAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Interest Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorEdit).includes("interestAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestAccount"
                />
              </div>
              {errorEdit.interestAccount && (
                <ErrorMessage message={errorEdit.interestAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Interest Income</label>
              <div
                className={`border--norm ${
                  Object.keys(errorEdit).includes("interestIncomeAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestIncomeAccount"
                />
              </div>
              {errorEdit.interestIncomeAccount && (
                <ErrorMessage message={errorEdit.interestIncomeAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Interest Expense</label>
              <div
                className={`border--norm ${
                  Object.keys(errorEdit).includes("interestExpenseAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestExpenseAccount"
                />
              </div>
              {errorEdit.interestExpenseAccount && (
                <ErrorMessage message={errorEdit.interestExpenseAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Interest Payable Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorEdit).includes("interestPayableAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestPayableAccount"
                />
              </div>
              {errorEdit.interestPayableAccount && (
                <ErrorMessage message={errorEdit.interestPayableAccount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label className={classes.label}>Interest Receivable Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorEdit).includes(
                    "interestReceivableAccount"
                  ) && "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestReceivableAccount"
                />
              </div>
              {errorEdit.interestReceivableAccount && (
                <ErrorMessage message={errorEdit.interestReceivableAccount} />
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {details.otherFeesAndIncome.map((item, index) => (
            <Grid container spacing={3} className={classes.chargebox} >
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="label--input label--select flex--3 " 
              >
                <label>Charge Name</label>
                <input
                  type="text"
                  className={errorEdit.effectiveRateDate ? "error-input-z" : null}
                  onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                  value={item.chargeName}
                  name="chargeName"
                  maxLength="10"
                  className={classes.textbox}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="label--input label--select flex--3"
              >
                <label>Apply Charge At</label>
                <input
                  type="text"
                  className={errorEdit.effectiveRateDate ? "error-input-z" : null}
                  onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                  value={item.applyChargeAccount}
                  name="applyChargeAccount"
                  maxLength="10"
                  className={classes.textbox}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="label--input label--select flex--3"
              >
                <label>Charge Acc</label>
                <input
                  type="text"
                  className={errorEdit.effectiveRateDate ? "error-input-z" : null}
                  onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                  value={item.chargeLedgerAccount}
                  name="chargeLedgerAccount"
                  maxLength="10"
                  className={classes.textbox}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="label--input label--select flex--3"
              >
                <label>Type of Charge</label>
                <select
                  className={errorEdit.portFolioType ? "error-input-z" : null}
                  onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                  value={item.chargeType}
                  name="chargeType"
                  className={classes.selectbox}
                >
                  <option value="">Select An Option</option>
                  <option value="FlatAmount">Flat Amount </option>
                  <option value="PercentageRate">Percentage Rate</option>
                </select>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="label--input label--select flex--3"
              >
                <label>Amount/Rate</label>
                <input
                  type="text"
                  className={errorEdit.effectiveRateDate ? "error-input-z" : null}
                  onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                  value={item.amountRate}
                  name="amountRate"
                  maxLength="10"
                  className={classes.textbox}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="label--input label--select flex--3"
              >
                <label>Charge Currency</label>
                <select
                  name="chargeCurrency"
                  value={item.chargeCurrency}
                  onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                  className={`${
                    Object.keys(errorEdit).includes("chargeCurrency") &&
                    "error-input-z"
                  }`}
                  className={classes.selectbox}
                >
                  <option value="">Select An Option</option>
                  {allCurrencyDefinition.map((currency) => (
                    <option value={currency.currencyCode} key={currency.id}>
                      {currency.currencyName}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
          ))}
        </TabPanel>
      </div>
    </EditModal>
  );
};

export default EditData;

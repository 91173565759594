import axios from 'axios';
import { Routes } from '../../configUrl';

const getAllTmeEod = async (token) => {
    return await axios({
        method: "get",
        url: Routes.getAllEodProcesses,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };
  

export {getAllTmeEod}

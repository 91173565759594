import { useState, useEffect,useCallback, } from 'react'
import axios from 'axios' 
import { baseUrl } from  '../../../../Api/configUrl';
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../../formatter/date";
import {
    isLoading,
  } from "../../../../Api/redux-manager/actions";


const BusinessLogic = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        // let Token = user && user.jwtToken
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }, [user])
    
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const token = useSelector((state) => state.common.userDetails.jwtToken);
    const loader = useSelector(state => state.req.loading)
    const [allDealPostings, setAllDealPostings] = useState([])
    const [openView, setOpenView] = useState(false)
    const [updateId, setUpdateID] = useState('')
    const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const [openBoxUpdate, setOpenBoxUpdate] = useState(false)
    const [authorize, setAuthorize] = useState({
        reason: '',
        isApproved: true,
        authorizationStatus: 0
    })

    const handleConfirmAuthorize = () => {
        setAuthorize(true)
      }

    const [dealPosting, setDealPosting] = useState({
        accountNumber: "",
        portfolioCode: "",
        portfolioType: "",
        product: "",
        moneyMarketType: '',
        productDescription: "",
        dealReference: "",
        externalReferenceNo: "",
        dealQuantity: "",
        priceQuote: "",
        currency: "",
        inputPrice: "",
        priceInLocalCurrency:"", 
        tradeDate:"",
        quantityOfLocalCurrency:"",
        dealInputDate:"",
        interest:"",
        maturtiyDate:"",
        brokerId:"",
        quoteBy:"",
      });
    const EmptyDealPosting = () => {
        setDealPosting({ 
            accountNumber: "",
            portfolioCode: "",
            portfolioType: "",
            product: "",
            productDescription: "",
            dealReference: "",
            externalReferenceNo: "",
            dealQuantity: "",
            priceQuote: "",
            currency: "",
            inputPrice: "",
            priceInLocalCurrency:"",
            tradeDate:"",
            quantityOfLocalCurrency:"",
            dealInputDate:"",
            interest:"",
            maturtiyDate:"",
            brokerId:"",
            quoteBy:"",
           
        })
    }
    

    const getDealPostingAll = useCallback(async () => {
        dispatch(isLoading());
        const url = `${baseUrl}treasury/DealPosting/GetAll`;
        const response = await getHandler(token, url);
        if (response && response.data) {
          dispatch(isLoading());
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].sn = i + 1;
              response.data[i].createdDateTime = formattedDate(
                response.data[i].createdDateTime
              );
              response.data[i].dateAuthorized = formattedDate(
                response.data[i].dateAuthorized
              );
              response.data[i].updatedDateTime = formattedDate(
                response.data[i].updatedDateTime
              );
            }
          }
          setData(response.data);
        }
      },[dispatch, token]);
    
    
    const handleDealPostCreate =  async (e) => {
        let data = {...dealPosting};

        try {
            dispatch(isLoading())
            const response = await axios.post(`${baseUrl}treasury/DealPosting/Create`, data);
            if(response.data.statusCode === 200){
                getDealPostingAll()
                EmptyDealPosting()
                return true
            } else {
                return false
            }
        } catch (err) {
            return false
        } finally {
            dispatch(isLoading())
        }
    }
    const handleDealPostingAuthorize = async () => {
        dispatch(isLoading())
        let data = {...authorize};
        try {
            const response = await axios.put(`${baseUrl}treasury/DealPosting/Authorize/${updateId}`, data);
            if(response.data.status){
                setAuthorize({
                    reason: '',
                    isApproved: ''
                })
                let a = allDealPostings.map(items => {
                    if(items.id === updateId) {
                        items.authorizationStatus = 'Authorized'
                    }
                    return items
                })
                setAllDealPostings(a)
                // Not meant to be here, so that user would see the success
                // setOpenBoxAuthorize(false)
                // 
                setOpenView(false)
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        } finally {
            dispatch(isLoading())
        }
    }
    const deleteDealPostings = async () => {
        try {
            const response = await axios.delete(`${baseUrl}treasury/DealPosting/delete/${deleteId}`);
            if(response.data.status){
                return true
            }
            if(response.data.responseMessage.includes('This Record has been authorized and possibled used')) {
                setErrMessage('Unsuccessful, You cannot delete an authorized record.')
                return false
            }
        } catch {
            return false
        } finally {
            dispatch(isLoading())
        }
    }

    // const updatedealPosting = async () => {
    //     setLoader(true);
    //     let data = {...dealPosting};
    //     try {
    //         const response = await axios.put(`${baseUrl}treasury/DealPosting/update/${updateId}`, data);
    //         if(response.data.status){
    //             getDealPostingAll()
    //             EmptyDealPosting()
    //             setOpenBoxUpdate(false)
    //             return true
    //         } else {
    //             return false
    //         }
    //     } catch (error) {
    //             if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
    //                 setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
    //                 return false
    //             }
    //         return false
    //     } finally {
    //         setLoader(false);
    //     }
    // }
    const handleUpdate = (item) => {
        setUpdateID(item.id)
        setDealPosting({...dealPosting, ...item})
        setOpenBoxUpdate(true)
    }


    const handleUpdateClose = () => {
        EmptyDealPosting()
        setOpenBoxUpdate(false)
    }
    const updateDealPosting = async () => {
        dispatch(isLoading())
        let data = {...dealPosting};
        try {
            const response = await axios.put(`${baseUrl}treasury/DealPosting/update/${updateId}`, data);
            if(response.data.statusCode === 200){
                // getCurrencyDefinitionAll()
                setDealPosting({
                    data
                })
                let a = allDealPostings.map(items => {
                    if(items.id === updateId) {
                        items = {...dealPosting}
                    }
                    return items
                })
                setAllDealPostings(a)
                setOpenBoxUpdate(false)
                return true
            } else {
                return false
            }
        } catch (error) {
                if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
                    setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
                    return false
                }
            return false
        } finally {
            dispatch(isLoading())
        }
    }

useEffect(() => {
    getDealPostingAll()  
}, [getDealPostingAll])


    return {loader,
        data,
         handleDealPostCreate,
         allDealPostings,
         dealPosting,
          setDealPosting,
          handleDealPostingAuthorize,
          openView, 
          setOpenView,
          openBoxAuthorize,
          setOpenBoxAuthorize,
          updateId,
          setUpdateID,
          authorize,
          setAuthorize,
          handleConfirmAuthorize,
          EmptyDealPosting,
          deleteDealPostings,
          deleteId,
           setDeleteId,
           errMessage, setErrMessage,
           updateDealPosting,
           getDealPostingAll,
           openBoxUpdate, setOpenBoxUpdate,
           handleUpdate, handleUpdateClose }
}

export default BusinessLogic

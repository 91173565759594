import { useState, useCallback, useEffect } from "react";
import { baseUrl } from "../../configUrl";
import axios from "axios";
import _ from "lodash";
import { getAllStatesByCountryCode } from "../../../Api/places/getPlaces";

const useCustom = () => {
  const [loader, setLoader] = useState(false);
  const [singleAccount, setSingleAccount] = useState({});
  const [accountID, setAccountID] = useState("");
  const [updateId, setUpdateID] = useState("");
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [accountFetchMessage, setAccountFetchMessage] = useState("");
  const [errorsAcctID, seterrorsAcctID] = useState("");
  const [accountNumbDisplay, setAccountNumbDisplay] = useState("");
  const [screenChange, setScreenChange] = useState(1);
  const [allCustomerAccounts, setAllCustomerAccounts] = useState([]);
  const [message, setMessage] = useState("");
  const [states, setStates] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let Token = user && user.jwtToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
  }, [user]);

  const [data, setData] = useState({
    title: "",
    firstName: "",
    middleName: "",
    corporateName: "",
    rcNumber: "",
    jaName: "",
    lastName: "",
    customerType: "",
    email: "",
    phoneNumber: "",
    gender: "",
    customerCategory: "",
    dateOfBirth: "",
    address: "",
    city: "",
    country: "",
    state: "",
    lga: "",
    nationality: "",
    passport: "",
    signature: "",
    kyc: "",
    bvn: "",
    tin: "",
    mandate: "",
    identificationType: "",
    identificationNumber: "",
    nextOfKin: {
      firstName: "",
      middleName: "",
      lastName: "",
      relationship: "",
      profession: "",
      gender: "",
      phoneNumber: "",
      qualifications: "",
      address: "",
      maritalStatus: "",
      dateOfBirth: "",
      nationality: "",
      isDependent: true,
    },
    customerAccount: [
      {
        accountType: "",
        accountName: "",
        oldAccountNumber: "",
        accountProductCode: "",
        accountOfficer: "",
        productId: "",
        ATMNumber: "",
        Currency: "",
        chequeBookName: "",
        creditAmount: 0,
        debitAmount: 0,
        accountInstruction1: "",
        accountInstruction2: "",
        accountInstruction3: "",
        referralCode: "",
      },
    ],
    accountSignatories: [
      {
        signatoryClass: "",
        customerIsASignatory: false,
        title: "",
        lastName: "",
        firstName: "",
        middleName: "",
        email: "",
        phonenumber: "",
        gender: "",
        language: "",
        address: "",
        city: "",
        country: "",
        state: "",
        lga: "",
        nationality: "",
        kyc: "",
        bvn: "",
        identificationType: "",
        identificationNumber: "",
        passport: "",
        signature: "",
      },
    ],
  });

  const addSignatory = () => {
    const list = {
      signatoryClass: "",
      customerIsASignatory: false,
      title: "",
      lastName: "",
      firstName: "",
      middleName: "",
      email: "",
      phonenumber: "",
      gender: "",
      language: "",
      address: "",
      city: "",
      country: "",
      state: "",
      lga: "",
      nationality: "",
      kyc: "",
      bvn: "",
      identificationType: "",
      identificationNumber: "",
      passport: "",
      signature: "",
    };
    setData((prev) => ({
      ...prev,
      accountSignatories: [...prev.accountSignatories, list],
    }));
  };

  const removeSignatory = (index) => {
    const lists = [...data.accountSignatories];
    if (lists.length < 2) return;
    lists.splice(index, 1);
    setData((prev) => ({
      ...prev,
      accountSignatories: lists,
    }));
  };

  const [authorize, setAuthorize] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const [valuesStaticInvestmentBook, setValuesStaticInvestmentBook] = useState({
    accName: "",
    accBranch: "",
    accCurrency: "",
  });

  const updateCountry = (value, type) => {
    console.log(type, "type");
    if (type === "nationality") {
      setData((prevState) => ({
        ...prevState,
        nextOfKin: { ...prevState.nextOfKin, nationality: value },
      }));
    }

    if (type === "isDependent") {
      setData((prevState) => ({
        ...prevState,
        nextOfKin: {
          ...prevState.nextOfKin,
          isDependent: !prevState.nextOfKin.isDependent,
        },
      }));
    }
  };

  const handleSearchableChangeSelect = (value, type) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  const fetchState = async (value) => {
    const response = await getAllStatesByCountryCode(user.jwtToken, value);
    if (response && response.data) {
      setStates(response.data);
    }
  };

  const handleAccountSignatories = (e, index) => {
    const { name, value } = e.target;
    console.log(value, index, name);

    const signatory = {...data.accountSignatories[index]}
    signatory[name] = value
    setData(prev => {
      const temp = {...prev,}
      temp.accountSignatories[index] = signatory
      return temp
    })
    // setData((prev) => {
    //   // const values = Object.assign(prev)
      
    //   // const lists = [...prev.accountSignatories];
    //   // console.log(lists[index][name], name);
    //   console.log( values);
    //   return {...values}
    // });
    if (name === "country") {
      fetchState(value);
    }
  };

  useEffect(() => {
    if (
      (data.customerType === "Individual" || data.customerType === "Joint")
      ) {
 
      const updater = {...data.accountSignatories[0]};
      updater["customerIsASignatory"] = true;
      updater["title"] = data.title;
      updater["lastName"] = data.lastName;
      updater["firstName"] = data.firstName;
      updater["middleName"] = data.middleName;
      updater["phonenumber"] = data.phoneNumber;
      updater["address"] = data.address;
      updater["nationality"] = data.nationality;
      updater["kyc"] = data.kyc;
      updater["bvn"] = data.bvn;
      updater["lga"] = data.lga;
      updater["city"] = data.city;
      updater["country"] = data.country;
      updater["state"] = data.state;
      updater["gender"] = data.gender;
      updater["email"] = data.email;
      updater["signature"] = data.signature;
      updater["passport"] = data.passport;
      updater["identificationType"] = data.identificationType;
      updater["identificationNumber"] = data.identificationNumber;
      const newState = { ...data, accountSignatories: [ updater] }
      console.log(newState);
      if(!_.isEqual(data, newState)){
        setData(newState)
      }
    }
  },[data, data.signature])

  const handleChange = (name, type, allAccountProduct) => (e) => {
    const {value} = e.target;
    if (!type) {
      setData((prevState) => ({ ...prevState, [name]: value }));
      if (data.customerType === "Individual" || data.customerType === "Joint") {
        const secondUpdater = [...data.customerAccount];

        secondUpdater[0]["accountName"] = 
          data.customerType === "Joint"  
          ? data.jaName
          :`${data.firstName} ${data.middleName && data.middleName} ${data.lastName}`;

      } else {
        const secondUpdater = [...data.customerAccount];
        secondUpdater[0]["accountName"] = data.corporateName;

        setData((prevState) => ({
          ...prevState,
          customerAccount: secondUpdater,
        }));
      }

    }
    if (type === "nextOfKin") {
      setData((prevState) => ({
        ...prevState,
        nextOfKin: { ...prevState.nextOfKin, [name]: value },
      }));
    }
    if (type === "customerInfo") {
      let update = [...data.customerAccount];
      update[0][name] = value;
      if (name === "accountProductCode") {
        allAccountProduct.forEach((item) => {
          if (item.productCode === value) {
            update[0]["accountType"] = item.accountType;
            update[0]["productId"] = item.id;
          }
        });
      }
      setData((prevState) => ({ ...prevState, customerAccount: update }));
    }

    if (type === "accountSignatories") {
      let updater = [...data.accountSignatories];
      if (name === "customerIsASignatory") {
        let a = updater[0]["customerIsASignatory"];
        updater[0]["customerIsASignatory"] = !a;
        updater[0]["gender"] = data.gender;
        updater[0]["signature"] = data.signature;
        updater[0]["passport"] = data.passport;
      } else {
        updater[0][name] = value;
      }
      setData((prevState) => ({ ...prevState, customerIsASignatory: updater }));
    }

    if (type === "accountID") {
      setAccountID(value);
    }
    if (type === "authorize") {
      setAuthorize((authorize) => ({ ...authorize, [name]: value }));
    }
  };

  const EmptyFields = () => {
    setData({
      title: "",
      firstName: "",
      middleName: "",
      corporateName: "",
      rcNumber: "",
      jaName: "",
      lastName: "",
      customerType: "",
      email: "",
      phoneNumber: "",
      gender: "",
      customerCategory: "",
      dateOfBirth: "",
      address: "",
      city: "",
      country: "",
      state: "",
      lga: "",
      nationality: "",
      passport: "",
      signature: "",
      kyc: "",
      bvn: "",
      tin: "",
      mandate: "",
      identificationType: "",
      identificationNumber: "",
      nextOfKin: {
        firstName: "",
        middleName: "",
        lastName: "",
        relationship: "",
        profession: "",
        gender: "",
        qualifications: "",
        address: "",
        maritalStatus: "",
        dateOfBirth: "",
        nationality: "",
        isDependent: true,
      },
      customerAccount: [
        {
          accountType: "",
          accountName: "",
          accountOfficer:"",
          oldAccountNumber: "",
          accountProductCode: "",
          productId: "",
          ATMNumber: "",
          Currency: "",
          chequeBookName: "",
          creditAmount: 0,
          debitAmount: 0,
          accountInstruction1: "",
          accountInstruction2: "",
          accountInstruction3: "",
          referralCode: "",
        },
      ],
      accountSignatories: [
        {
          signatoryClass: "",
          customerIsASignatory: false,
          title: "",
          lastName: "",
          firstName: "",
          middleName: "",
          email: "",
          phonenumber: "",
          gender: "",
          language: "",
          address: "",
          city: "",
          country: "",
          state: "",
          lga: "",
          nationality: "",
          kyc: "",
          bvn: "",
          identificationType: "",
          identificationNumber: "",
          passport: "",
          signature: "",
        },
      ],
    });
  };

  const handleaAccountOpeningCreate = async (e) => {
    let datas = { ...data };

    if (datas.customerType !== "Individual") {
      datas.kyc = "Complete";
      datas.nextOfKin = null;
    }

    if(datas.customerType === "Individual"){
      datas.accountSignatories.passport = "";
      datas.accountSignatories.signature = "";
    }

    try {
      setLoader(true);
      const response = await axios.post(
        `${baseUrl}account/Customer/Create`,
        datas
      );
      console.log(response);
      if (response.data.statusCode === 200) {
        setAccountNumbDisplay(
          response.data.data.customerAccount[0].accountNumber
        );
        setScreenChange(1);
        EmptyFields();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("-----data-----", err.response);
      setMessage(err.response.data.responseMessage);
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getAccountByAccountNumber = async (accID) => {
    setLoader(true);
    try {
      let response;
      if (typeof accID === "string" && accID.length === 10) {
        response = await axios.get(
          `${baseUrl}account/CustomerAccount/AccountEnquiry/${accID}`
        );
      } else {
        response = await axios.get(
          `${baseUrl}account/CustomerAccount/AccountEnquiry/${accountID}`
        );
      }
      // const maindata = await response.json();
      if (response.data.status) {
        // console.log(response.data, "hjchjdjhcjhavhj22")
        if (response.data.data.authorizationStatus === "Authorized") {
          setSingleAccount(response.data.data);
          setUpdateID(response.data.data.id);
          setAccountFetchMessage("");
          setValuesStaticInvestmentBook((valuesStaticInvestmentBook) => ({
            ...valuesStaticInvestmentBook,
            accName: response.data.data.accountName,
            accBranch: response.data.data.branchCode,
            accCurrency: response.data.data.currency,
          }));
          // return response.data.data;
        } else {
          setSingleAccount({});
          setAccountFetchMessage("Unauthorized account number");
          setSingleAccount("");
          setValuesStaticInvestmentBook((valuesStaticInvestmentBook) => ({
            ...valuesStaticInvestmentBook,
            accName: "",
            accBranch: "",
            accCurrency: "",
          }));
        }
      }
    } catch (error) {
      setSingleAccount({});
      if (error.response.data.responseMessage) {
        setAccountFetchMessage("Input a valid Account Number");
        setSingleAccount("");
        seterrorsAcctID("errrrrorrrrr");
        setValuesStaticInvestmentBook((valuesStaticInvestmentBook) => ({
          ...valuesStaticInvestmentBook,
          accName: "",
          accBranch: "",
          accCurrency: "",
        }));
      }
    } finally {
      setLoader(false);
    }
  };

  const handleCustomerAccountAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}account/CustomerAccount/Authorize/${updateId}`,
        data
      );
      console.log(response, "responseresponse");
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        setAccountID(response.data.data.accountNumber);
        setOpenBoxAuthorize(false);
        return true;
      } else {
        return false;
      }
    } catch {
    } finally {
      setLoader(false);
    }
  };

  const getAllCustomerAccounts = useCallback(async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}account/CustomerAccount/GetAll`
      );
      if (response.data.statusCode === 200) {
        setAllCustomerAccounts(response.data.data);
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  }, []);

  return {
    accountNumbDisplay,
    screenChange,
    setScreenChange,
    allCustomerAccounts,
    getAllCustomerAccounts,
    errorsAcctID,
    loader,
    setData,
    valuesStaticInvestmentBook,
    errMessage,
    setErrMessage,
    authorize,
    setAccountID,
    accountFetchMessage,
    openBoxAuthorize,
    setOpenBoxAuthorize,
    handleCustomerAccountAuthorize,
    updateCountry,
    getAccountByAccountNumber,
    handleaAccountOpeningCreate,
    accountID,
    singleAccount,
    data,
    handleChange,
    handleSearchableChangeSelect,
    message,
    addSignatory,
    handleAccountSignatories,
    removeSignatory,
    states,
    fetchState,
  };
};

export default useCustom;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from '../../../Details/CoreModules/ParameterSettings'
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import {
  loading,
} from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { useCallback } from "react";

const ParameterSettings = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameterName",
      label: "Parameter Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameterValue",
      label: "Parameter Value",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameterLength",
      label: "Parameter Length",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
       // dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllPendingParameters = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}organization/BankParameters/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
      };
    };
      setData(response.data);
    }
  },[dispatch,token]);

  useEffect(() => {
    fetchAllPendingParameters();
  }, [fetchAllPendingParameters,updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Parameter Settings"
        subtitle=""
        name="List of Parameters"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer title="Parameter Settings - Record Details">
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize branch calender"
        question="Are you sure you want to authorize this date?"
        url={`${baseUrl}organization/BranchHoliday/Authorize/${details.id}`}
      />
    </div>
  );
};

export default ParameterSettings;

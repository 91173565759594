import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleMonthUx,
  monthUx,
  slicedmontstate,
  handleChange,
  details,
}) => {
  return (
    <EditModal
      title="Update bank calender"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}organization/BankHoliday/Update/${details.id}`}
    >
      <div className="bank--calendar">
      <section className="section--1 flex--4">
            <div className="flex--3">
              <label>Select New Month</label>
              <select value={monthUx} onChange={handleMonthUx}>
                <option value="">Select An Option</option>
                {slicedmontstate.map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex--3">
              <label>Holidays</label>
              <input
                type="text"
                onChange={(e) => handleChange(e, "bankCalendar")}
                name="holiday"
                value={details.holiday}
              />
            </div>
            <div className="flex--3">
              <label>Start Time</label>
              <input
                type="time"
                onChange={(e) => handleChange(e, "bankCalendar")}
                name="startTime"
                value={details.startTime}
              />
            </div>
            <div className="flex--3">
              <label>Close Time</label>
              <input
                type="time"
                onChange={(e) => handleChange(e, "bankCalendar")}
                name="closeTime"
                value={details.closeTime}
              />
            </div>
            <div className="flex--3">
              <label>Year</label>
              <input
                type="text"
                onChange={(e) => handleChange(e, "bankCalendar")}
                name="year"
                // readOnly
                disabled
                value={details.year}
                // className={`${Object.keys(errors).includes('currencyName') && 'error-input-z'}`}
              />
            </div>
            <div className="flex--3">
              <label>Bank ID</label>
              <input
                type="text"
                onChange={(e) => handleChange(e, "bankCalendar")}
                name="bankId"
                value={details.bankId}
                // className={`${Object.keys(errors).includes('currencyName') && 'error-input-z'}`}
              />
            </div>
            <div className="flex--3">
              <label>Exemption Flag</label>
              <input
                type="text"
                onChange={(e) => handleChange(e, "bankCalendar")}
                name="exemptFlg"
                value={details.exemptFlg}
                disabled
              />
            </div>
          </section>
      </div>
    </EditModal>
  );
};

export default EditData;

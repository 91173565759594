import React, {useState} from 'react'
import './ChannelUser.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Channels from '../../../../commons/checkboxes/channels';
import Staff from '../../../../commons/checkboxes/staff';
import ErrorMessage from "../../../ErrorMessage";
import useTable from "../../../Tables/useTable";
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidUserName,
} from "../../../../Helper/validator";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

const ChannelUser = () => {
  const [error, setError] = useState({});
  
  const [allChannelUser] = useState([
    // {
    //   id:'dfghj',
    // investment:"sdfghj",
    //   name:'sdwer',
    //   product:'ertyuio',
    //   status:'approved'
    // }
  ]);
  const headCells = [
    { id: "id", label: "S/N" },
    { id: "username", label: "Username" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "gender", label: "Gender" },
    { id: "dateofbirth", label: "Date of Birth" },
    { id: "mobilenumber", label: "Mobile Number" },
    { id: "channelsenabled", label: "Channels Enabled" },
    { id: "staff", label: "Staff" },
    { id: "action", label: "Action", disableSorting: true },
  ];
  
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(allChannelUser, headCells, filterFn);
  
  const handleSearch = (e) => {
    let target = e.target;
    setPage(0)
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
      },
    });
  };
  const [user, setUser] = useState({
    userName:"",
    firstName:"",
    middleName:"",
    lastName:"",
    email:"",
    gender:"",
    dob:"",
    number:"",
    
  })
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  }
  const handleSave = () => {
    let checkError = isValidateForm(user);
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;
    setError({});
  };
  
  const isValidateForm = (user) => {
    let errors ={};

    if (!user.userName.trim() || isValidUserName(user.userName) === ""){
      errors.userName = "Field is required";
    }
    if (!user.firstName.trim()){
      errors.firstName = "Field is required";
    }
    if (!user.middleName.trim()){
      errors.middleName = "Field is required";
    }
    if (!user.lastName.trim()){
      errors.lastName = "Field is required";
    }
    if (!user.email.trim() || isValidEmail(user.email) === ""){
      errors.email = "Field is required";
    }
    if (!user.gender.trim()){
      errors.gender = "Field is required";
    }
    if (!user.dob.trim()){
      errors.dob = "Field is required";
    }
    if (!user.number.trim() || isValidPhoneNumber(user.number) === ""){
      errors.number = "Field is required";
    }
    return errors;
  }

    return (
        <div className='channel--user'>
            <div className='headerdiv'>
            <p>Channel User</p>
            </div>
            <Paper style={{ marginTop: 20, padding: 25 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Username</label> 
                      <input 
                      type="text"
                       name= "userName"
                      value={user.userName}
                      onChange={handleChange("userName")}
                      className={`${error.userName && "error-input-z"}`}
                      />
                      {error.userName && <ErrorMessage message={error.userName} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>First Name</label> 
                      <input 
                      type="text" 
                      name= "firstName"
                      value={user.firstName}
                      onChange={handleChange("firstName")}
                      className={`${error.firstName && "error-input-z"}`}
                      />
                      {error.firstName && <ErrorMessage message={error.firstName} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Middle Name</label> 
                      <input 
                      type="text"
                      name= "middleName"
                      value={user.middleName}
                      onChange={handleChange("middleName")}
                      className={`${error.middleName && "error-input-z"}`} 
                      />
                      {error.middleName && <ErrorMessage message={error.middleName} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Last Name</label> 
                      <input 
                      type="text"
                      name="lastName" 
                      value={user.lastName}
                      onChange={handleChange("lastName")}
                      className={`${error.lastName && "error-input-z"}`} 
                      />
                      {error.lastName && <ErrorMessage message={error.lastName} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Email</label> 
                      <input 
                      type="text"
                      name="email" 
                      value={user.email}
                      onChange={handleChange("email")}
                      className={`${error.email && "error-input-z"}`} 
                      />
                      {error.email && <ErrorMessage message={error.email} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Gender</label> 
                      <input 
                      type="text"
                      name="gender" 
                      value={user.gender}
                      onChange={handleChange("gender")}
                      className={`${error.gender && "error-input-z"}`} 
                      />
                     {error.gender && <ErrorMessage message={error.gender} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Date Of Birth</label> 
                      <input 
                      type="text" 
                      name="dob" 
                      value={user.dob}
                      onChange={handleChange("dob")}
                      className={`${error.dob && "error-input-z"}`} 
                      />
                      {error.dob && <ErrorMessage message={error.dob} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <label>Mobile Number</label> 
                      <input 
                      type="tel"
                      name="number" 
                      value={user.number}
                      onChange={handleChange("number")}
                      className={`${error.number && "error-input-z"}`} 
                      />
                      {error.number && <ErrorMessage message={error.dob} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <label>Channels Enabled</label>
                    <div className='channels'><Channels/></div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <label>Staff</label>
                        <div className='staff'><Staff/></div>
                    </Grid>
                </Grid>
                <button className='save' onClick={handleSave}>Save</button>
                <hr/>
                <h4>Channel User</h4>
                <div className='sub--section flex--2'>
                    <div className='main-filter flex--2'>
                        <input className="filter" placeholder='Filter' onChange={handleSearch}/>
                        
                        <button className='button--second'>Remove Filter</button>
                    </div>
                </div>
                 {/* Table comes in here */}
                 <TblContainer>
            <TblHead />
            <tbody>
              {recordsAfterPagingAndSorting().map((investment,i) => (
                <tr key={investment.id}>
                <th scope="row">{i + 1}</th>
                <td>{investment.username}</td>
                <td>{investment.name}</td>
                <td>{investment.email}</td>
                <td>{investment.gender}</td>
                <td>{investment.dateofbirth}</td>
                <td>{investment.mobilenumber}</td>
                <td>{investment.channelsenabled}</td>
                <td>{investment.staff}</td>
                {/* <td>
                  <div className={`table--icon ${(investment.authorizationStatus === ('Authorized') || investment.authorizationStatus === ('Rejected')) && 'disabled-delete'}`}>
                    <AiTwotoneDelete
                      onClick={() => alert('deleting')}
                    />
                  </div>
                </td> */}
                {/* <td>
                  <div className="table--icon--2">
                    <FiEdit
                    onClick={() => alert('open modal')}
                     />
                  </div>
                </td> */}

                <td>
                  <DotMenu>
                    <MenuItem onClick={() => alert('open modal')}>Edit</MenuItem>
                    <MenuItem onClick={() => alert('deleting')}>Delete</MenuItem>
                    <MenuItem>View</MenuItem>
                  </DotMenu>
                </td>
              </tr>
              ))}
            </tbody>
          </TblContainer>
          <TblPagination />
            </Paper>
        </div>
    )
}
export default ChannelUser;
import React from 'react'
import { Switch, Route } from "react-router-dom";

import TransactionSummary from './TransactionSummary/TransactionSummary';
import GlStatement from "./GlStatement/GlStatement";


const Main = () => {
    return (
        <div>
            <Switch> 
                <Route path='/dashboard/reports/transaction/summary' component={TransactionSummary}/>
                <Route path='/dashboard/reports/transaction/glstatement' component={GlStatement}/>

            </Switch>
        </div>
    )
}

export default Main

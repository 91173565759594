import { Routes } from "../../../../Api/configUrl";

import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/AccountOperations/LienAccountDetails";

const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Account Lien - Record Details"
      question="Are you sure you want to delete this Account?"
      url = {`${Routes.deleteALien}/${details.id}`}
    >
      <Details details={details}/>
    </DetailsContainer>

  );
};

export default View;
import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";
import { DateConverter } from "../../../ConvertDate";
import ErrorMessage from "../../../ErrorMessage";
import Grid from "@material-ui/core/Grid";

const EditData = ({
  handleEditSubmit,
  handleChange,
  details,
  fiscalYear,
  errorEdit,
}) => {
  return (
    <EditModal
      title="Update Period definition"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}organization/PeriodDefinition/Update/${details.id}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <label>Fiscal Year</label>
          <br />
          <select style={{height:44,borderRadius:5}}
            name="fiscalYear"
            onChange={handleChange}
            value={details.fiscalYear}
          >
            <option>Please, Select a year</option>
            {fiscalYear.map((year, index) => {
              return (
                <option key={index} value={year.year}>
                  {year.year}
                </option>
              );
            })}
          </select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <label>Period Code</label>
          <br />
          <input style={{height:44,borderRadius:5}}
            type="text"
            onChange={handleChange}
            name="periodCode"
            value={details.periodCode}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <label>Start Date</label>
          <br />
          <input style={{height:44,borderRadius:5}}
            className={errorEdit.startdate ? "error-input-z" : null}
            type="date"
            value={DateConverter(details.startdate)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <label>End Date</label>
          <br />
          <input style={{height:44,borderRadius:5}}
            className={errorEdit.enddate ? "error-input-z" : null}
            type="date"
            value={DateConverter(details.enddate)}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </EditModal>
  );
};

export default EditData;

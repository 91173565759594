import React from "react";
import { Switch, Route } from "react-router-dom";
import CorporateBorrower from "./CorporateBorrower/CorporateBorrower";
import CreditInformation from "./CreditInformation/CreditInformation";
import GuarantorsInformation from "./GuarantorsInformation/GuarantorsInformation";
import IndividualBorrower from "./IndividualBorrower/individualBorrower";
import PrincipalOfficers from "./PrincipalOfficers/PrincipalOfficers";

const Main = () => {
  return (
    <div>
      <Switch>
        <Route
          path="/dashboard/reports/crb/corporateborrower"
          component={CorporateBorrower}
        />
        <Route
          path="/dashboard/reports/crb/creditinformation"
          component={CreditInformation}
        />
        <Route
          path="/dashboard/reports/crb/guarantorsinformation"
          component={GuarantorsInformation}
        />
        <Route
          path="/dashboard/reports/crb/individualborrower"
          component={IndividualBorrower}
        />
        <Route
          path="/dashboard/reports/crb/principalofficers"
          component={PrincipalOfficers}
        />
      </Switch>
    </div>
  );
};

export default Main;

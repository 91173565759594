import { useState, useEffect, useCallback } from "react";
import "./Login.scss";
import logo from "../../assets/mainlogo.png";
// import Uhurulogo from '../../assets/uhurulogo.png'
import Nomase from "../../assets/Nomaselogo.png";
import ChangePassword from "../../assets/change-password.png";
import Lady from "../../assets/lady.png";
import useForm from "../../Api/Hooks-manager/useCustom";
import { RiErrorWarningFill } from "react-icons/ri";
import Spinner from "../Backdrop/index";
import NewModal from "../../Confirmbox/Modal";
import { isValidEmail } from "../../Helper/validator";
import { forgetPassword } from "../../Api/Hooks-manager/ForgetPassword/forgetPassword";

// import { NavLink } from 'react-router-dom'
// import validate from '../validator/validateLogin'

const Login = () => {
  const {
    handleChange,
    errors,
    values,
    loader,
    handleLoginSubmit,
    errorLogin,
    message,
  } = useForm();
  const [screenChange, setScreenChange] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [positiveDialog, setPositiveDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const data = useState({ email });

  const handleScreenChange = (i) => {
    console.log("qwswqeq", i);
    setScreenChange(i);
  };

  const ForgetEmails = useCallback(async () => {
    setLoading(true);
    if (email === "") {
      setMsg("Field cannot be empty");
    } else if (!isValidEmail(email)) {
      setMsg("Invalid email");
    } else {
      setMsg("");

      const response = await forgetPassword(email);
      console.log("---------", response);

      if (response.status && response.message === "Success") {
        // console.log("success...", response.message)
        setDialogTitle("Successful");
        setOpenDialog(true);
        setDialogMessage(
          "Please check your mail. We just sent you a link to reset your password "
        );
        setPositiveDialog(true);
      } else {
        setDialogTitle("UnSuccessful");
        setOpenDialog(true);
        setDialogMessage("Sorry, this email is not registered");
        setPositiveDialog(false);
        //handle error here
      }

      // if (response && response.data) {

      //   setEmail(response.data);
      //   //setAccountData()
      // }
    }
  }, [data]);

  // useEffect(() => {
  //   ForgetEmails()
  // }, [ForgetEmails])

  // const testState = useSelector(state => state)
  // const dispatch = useDispatch();

  return (
    <main className="login flex--2">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader} />

        <NewModal
          title={dialogTitle}
          openDialog={openDialog}
          message="Please check your mail. A link has been sent to you to reset your password"
          positiveDialog={positiveDialog}
          onClose={() => setOpenDialog(false)}
        >
          {dialogMessage}
        </NewModal>
      </div>
      {/* <img src={logo} alt="" /> */}
      <div className="ovelay">{""}</div>
      <img src={Lady} alt="background-lady" className="background-lady" />
      <section className="section--1 flex--3">
        <img src={logo} alt="" />

        {!screenChange && (
          <>
            <form
              className="flex--3 sub--section"
              noValidate
              onSubmit={handleLoginSubmit}
            >
              <h1>Login</h1>
              <div className="error-container-z">
                <input
                  alt=""
                  placeholder="Username / User ID"
                  type="text"
                  name="emailOrUserName"
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("emailOrUserName")
                      ? "error-input-z"
                      : ""
                  }`}
                  value={values.emailOrUserName}
                />
                {errors.emailOrUserName && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.emailOrUserName}</small>
                  </div>
                )}
              </div>
              <div className="error-container-z">
                <input
                  alt=""
                  placeholder="Password"
                  type="password"
                  name="password"
                  autoComplete="on"
                  onChange={handleChange}
                  className={`${
                    Object.keys(errors).includes("password")
                      ? "error-input-z"
                      : ""
                  }`}
                  value={values.password}
                />
                {errors.password && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{errors.password}</small>
                  </div>
                )}
              </div>
              {errorLogin && (
                <div className="flex--2 icon--error">
                  <RiErrorWarningFill className="error--icon" />
                  <p className="error--both">{message}</p>
                </div>
              )}
              <h6
                className="forgot-password--button"
                onClick={handleScreenChange}
              >
                Forgot Password?
              </h6>
              <button type="submit" className="login-button">
                Login
              </button>
              {/* <NavLink to='/dashboard/home' onClick={handleLoginSubmit}>
                                <button>Login</button>
                            </NavLink> */}
            </form>
          </>
        )}

        {screenChange && (
          <div className="white--paper ">
            <img src={ChangePassword} alt="" className="change--password" />
            <div className="forget--password ">
              <p className="forgot--paragraph">Forgot your password?</p>
              <p className="forgot--paragraph__1">
                Enter your registered email and we will send you a link to reset
                your password.
              </p>
              <input
                type="text"
                name="email"
                className={errors.email ? "error-input-z" : null}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <p style={{ color: "red" }}>{msg}</p>
              <div className="buttons flex--1">
                <button
                  onClick={() => handleScreenChange(false)}
                  className="cancel--button"
                >
                  Cancel
                </button>
                <button className="reset--button" onClick={ForgetEmails}>
                  Send
                </button>
              </div>
            </div>
          </div>
        )}

         {/* <img src={Nomase} alt="" className="logo-uhuru" />  */}
      </section>
      <section className="section--2">
        <h1>Welcome Back!</h1>
        <p>Experience Seamless, Swift and Secure Transaction.</p>
      </section>
    </main>
  );
};

export default Login;

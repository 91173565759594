import React, {useState} from 'react';
import './TellerTransactions.scss';
import {Radio} from '@material-ui/core';
import {Checkbox} from '@material-ui/core'


 const TellerTransactions = () => { 

    const [cash, setCash] = useState('pay-cash')
    const handleCash = (e) => {
        console.log(e.target.value)
        setCash(e.target.value)
    }
    
    const [screenChange, setScreenChange] = useState(false)
    const handleScreenChange = (i) => {
        console.log('qwswqeq', i)
        setScreenChange(i)
 }
 const [error, setError] = useState({}); 
 const [user, setUser] =useState({
    glAccountNo:"",
    glAccountCurrency:"",
    tillAccountNo:"",
    glAccountBranch:"",
    tillCurrency:"",
    branch:"",
    tellerName:"",
    amount:"",
    narration:"",
  

 });

 const handleChanger = e => {
   console.log('e.target.value', e.target.value)
   console.log('e.target.name', e.target.name)
   setUser({...user, [e.target.name]: e.target.value }); 
};

const handleNext=() =>{
    let checkError = isValidateForm(user)
    setError(checkError)
    console.log(Object.keys(checkError), 'errorvslues')
    if((Object.keys(checkError)).length > 0) {
        return
    };
    setScreenChange(true);
}


const handleSubmit = e => {

}

const isValidateForm =  (user) => {
    let errors = {};

    if (!user.glAccountNo.trim()){
        errors.glAccountNo= "Gl account number required"
    }
    if (!user.glAccountCurrency.trim()){
        errors.glAccountCurrency= "Gl account currency required"
    }
    if (!user.tillAccountNo.trim()){
        errors.tillAccountNo= "Till account number required"
    }
    if (!user.glAccountBranch.trim()){
        errors.glAccountBranch= "Gl account branch required"
    }
    if (!user.tillCurrency.trim()){
        errors.tillCurrency= "Till currency required"
    }
    if (!user.branch.trim()){
        errors.branch= "Branch required"
    }
    if (!user.tellerName.trim()){
        errors.tellerName= "Teller Name required"
    }
    if (!user.amount.trim()){
        errors.amount= "Amount required"
    }
    if (!user.narration.trim()){
        errors.narration= "Narration required"
    }
    return errors;
   }
        

    return (
        <main className="teller--transactions">
            <section className="section--1">
                <div className="heading">
                    <p>Miscellaneous Teller Transaction</p>
                </div>
                <div className="flex--3">
                    <div className='button--double flex--2'>
                        <div className='flex--3'>
                            <button className={`${!screenChange ? 'active-1' : ''}`} onClick={() => handleScreenChange(false)}>1</button> 
                            <p>Transaction</p>
                        </div>
                        <div className='flex--3'>
                            <button  className={`${screenChange ? 'active-1' : ''}`} onClick={() => handleScreenChange(true)}>2</button>
                            <p>Denominations</p>
                        </div>
                    </div>

                    {
                        !screenChange && (
                            <>
                            <div  style={{ paddingTop: 12, padding: 25 }}>
                                <div className="sub--section">
                                    <div className="sub--main">
                                        <div className="sub--main--1 flex--1">
                                            <div className="sub--radio">
                                                <Radio value="pay-cash" 
                                                checked={cash==="pay-cash"} 
                                                onChange={handleCash}/>
                                                <span>Pay Cash Out</span>
                                            </div>

                                            <div className="sub--radio">
                                                <Radio value="receive-cash" 
                                                checked={cash==="receive-cash"} 
                                                onChange={handleCash}/>
                                                <span>Receive Cash</span>
                                            </div>
                                            
                                        </div>
                                        <div className="sub--main--2">
                                            <Checkbox value="cmc-transit"/>
                                            <span>CMC Transit Transaction</span>
                                        </div>
                                        <div className="sub--main--3 flex--1">
                                            <div className="sub-main--input">
                                                <div className='label--input--GL flex--2'>
                                                    <div className='flex--3'>
                                                        <label>GL Account No</label>
                                                        <input 
                                                       
                                                         className='input'
                                                         name="glAccountNo"
                                                         value={user.glAccountNo}
                                                         onChange={handleChanger}
                                                         />
                                                    </div>
                                                    <input className='branch'/>
                                                </div>
                                                    {error.glAccountNo && <small>{error.glAccountNo}</small>}
                                                <div className='label--input GL--account flex--3'>
                                                    <label>GL Account Currency</label>
                                                    <input
                                                     
                                                      name="glAccountCurrency"
                                                      value={user.glAccountCurrency}
                                                      onChange={handleChanger}
                                                     />
                                                </div>
                                                {error.glAccountCurrency && <small>{error.glAccountCurrency}</small>}

                                                <div className='label--input flex--3'>
                                                    <label>Till Account No</label>
                                                    <input 
                                                     
                                                     name="tillAccountNo"
                                                     value={user.tillAccountNo}
                                                     onChange={handleChanger} 
                                                    />
                                                </div>
                                                {error.tillAccountNo && <small>{error.tillAccountNo}</small>}
                                            </div>

                                            <div className="sub-main--input--2">
                                                <div className='label--input--GL flex--2'>
                                                    <div className='flex--3'>
                                                        <label>GL Account Branch</label>
                                                        <input
                                                         
                                                         className='input'
                                                         name="glAccountBranch"
                                                         value={user.glAccountBranch}
                                                         onChange={handleChanger} 
                                                        />
                                                    </div>
                                                    <input className='branch'/>
                                                </div>
                                                {error.glAccountBranch && <small>{error.glAccountBranch}</small>}
                                               
                                                <div className='label--input flex--3'>
                                                    <label>Till Currency</label>
                                                    <input
                                                    
                                                     name="tillCurrency"
                                                     value={user.tillCurrency}
                                                     onChange={handleChanger}
                                                    />
                                                </div>
                                                {error.tillCurrency && <small>{error.tillCurrency}</small>}
                                            </div>
                                        </div>

                                        <div className="sub--main--4">
                                            <div className='label--input--1 flex--3'>
                                                <label>Teller Name</label>
                                                <input 
                                                
                                                 name="tellerName"
                                                 value={user.tellerName}
                                                 onChange={handleChanger}
                                                />
                                            </div>
                                            {error.tellerName && <small>{error.tellerName}</small>}

                                            <div className="sub-main--input--2 flex--1">
                                                <div>
                                                <div className='label--input--GL flex--2'>
                                                    <div className='flex--3'>
                                                        <label>Branch</label>
                                                        <input
                                                         
                                                         className='input'
                                                         name="branch"
                                                         value={user.branch}
                                                         onChange={handleChanger}
                                                         />
                                                        
                                                    </div>
                                                    
                                                    <input className='branch'style={{marginBottom:0}}/>
                                                </div>
                                                {error.branch && <small>{error.branch}</small>}
                                                </div>
                                                <div className='label--input flex--3'>
                                                    <label>Amount</label>
                                                    <input
                                                    
                                                     name="amount"
                                                     value={user.amount}
                                                     onChange={handleChanger}
                                                     />
                                                     {error.amount && <small>{error.amount}</small>}
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className='sub--section-3 flex--3'>
                                            <label>Narration</label>
                                            <input
                                             
                                             className='branch-input'
                                             name="narration"
                                             value={user.narration}
                                             onChange={handleChanger}
                                             />
                                        </div>
                                        {error.narration && <small>{error.narration}</small>}
                                        <div className='button-last'>
                                            <button onClick={handleNext}>Next</button>
                                        </div>
                                    </div>

                                </div>
                                </div>
                            </>
                        )
                    }

                    {
                        screenChange && (
                            <>
                                <main className='sub-main'>
                                    <section className='sub-main--1 flex--2'>
                                        <div className='flex--2'>
                                            <p>Amount</p>
                                            <input/>
                                        </div>
                                        <div className='flex--2'>
                                            <p>Net Amount</p>
                                            <input/>
                                        </div>
                                    </section>
                                    <section className='sub-main--2 flex--3'>
                                        <div className='multiplier-input flex--2'>
                                            <input/>
                                            <span>&times;</span>
                                            <input />
                                            <span>&#61;</span>
                                            <input />
                                        </div>
                                        <div className='multiplier-input flex--2'>
                                            <input />
                                            <span>&times;</span>
                                            <input />
                                            <span>&#61;</span>
                                            <input />
                                        </div>
                                        <div className='multiplier-input flex--2'>
                                            <input/>
                                            <span>&times;</span>
                                            <input />
                                            <span>&#61;</span>
                                            <input/>
                                        </div>
                                        <div className='multiplier-input flex--2'>
                                            <input/>
                                            <span>&times;</span>
                                            <input />
                                            <span>&#61;</span>
                                            <input/>
                                        </div>
                                    </section>
                                </main>
                                <div className='button-last button-last--1 flex--2'>
                                    <div className='button-container flex--1'>
                                        <button onClick={() => handleScreenChange(false)}>Previous</button>
                                        <button onClick={handleSubmit}>Save</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                
            </section>
        </main>
    );
}
export default TellerTransactions;
import React, { useState } from "react";
import "./CashWithdrawal.scss";
import ErrorMessage from "../../../ErrorMessage";
import { isValidEmail, isValidPhoneNumber } from "../../../../Helper/validator";

const CashWithdrawal = () => {
  const [screenChange, setScreenChange] = React.useState(false);

  const handleScreenChange = (i) => {
    console.log("qwswqeq", i);
    setScreenChange(i);
  };
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    accountNumber: "",
    product: "",
    customerBvn: "",
    emailAddress: "",
    branch: "",
    currency: "",
    phone: "",
    amount: "",
    narration: "",
    instrumentType: "",
  });

  const handleChanger = (e) => {
    console.log("e.target.value", e.target.value);
    console.log("e.target.name", e.target.name);
    //   const { name, value } = e.target;
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleNext = () => {
    let checkError = isValidateForm(user);
    console.log(Object.keys(checkError).length, "errorvslues");
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setScreenChange(true);
  };
  const isValidateForm = (user) => {
    let errors = {};

    if (!user.accountNumber.trim()) {
      errors.accountNumber = "Field is required";
    }
    if (!user.product.trim()) {
      errors.product = "Field is required";
    }
    if (!user.customerBvn.trim()) {
      errors.customerBvn = "Field is required";
    }
    if (!user.branch.trim()) {
      errors.branch = "Field is required";
    }
    if (!user.currency.trim()) {
      errors.currency = "Field is required";
    }
    if (!user.amount.trim()) {
      errors.amount = "Field is required";
    }
    if (!user.instrumentType.trim()) {
      errors.instrumentType = "Field is required";
    }
    if (!user.narration.trim()) {
      errors.narration = "Field is required";
    }
    if (!user.phone.trim() || !isValidPhoneNumber(user.phone)) {
      errors.phone = "Invalid Entry";
    }
    if (!user.emailAddress.trim() || !isValidEmail(user.emailAddress)) {
      errors.emailAddress = "Invalid Email";
    }
    return errors;
  };
  return (
    <main className="cash-withdrawal">
      <section className="section--1">
        <div className="heading">
          <p>Cash Withdrawal - Foreign Currency</p>
        </div>
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className="flex--3">
              <button
                className={`${!screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(false)}
              >
                1
              </button>
              <p>Transaction</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                2
              </button>
              <p>Denominations</p>
            </div>
          </div>
          {!screenChange && (
            <>
              <section className="sub-section" style={{ paddingTop: 6, padding: 25 }}>
                <div className="flex--1 container-main">
                  <div className="input-container flex--3">
                    <div className="input-container--1 flex--1">
                      <div className="flex--3">
                        <label>Account Number</label>
                        <input
                        type="number"
                        onChange={handleChanger}
                        name="accountNumber"
                        value={user.accountNumber}
                          className={`${
                            error.accountNumber && "error-input-z"
                          }`}
                        />
                      </div>
                      <div className="sub-input">
                        <input
                          type="number"
                          onChange={handleChanger}
                          name="accountNumber"
                          value={user.accountNumber}
                          className={`${
                            error.accountNumber && "error-input-z"
                          }`}
                        />
                      </div>
                    </div>
                    {error.accountNumber && (
                      <ErrorMessage message={error.accountNumber} />
                    )}
                    <div className="flex--3">
                      <label>Product</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="product"
                        value={user.product}
                        className={`${error.product && "error-input-z"}`}
                      />
                      {error.product && (
                        <ErrorMessage message={error.product} />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>Customer BVN</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="customerBvn"
                        value={user.customerBvn}
                        className={`${error.customerBvn && "error-input-z"}`}
                      />
                      {error.customerBvn && (
                        <ErrorMessage message={error.customerBvn} />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>Email Address</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="emailAddress"
                        value={user.emailAddress}
                        className={`${error.emailAddress && "error-input-z"}`}
                      />
                      {error.emailAddress && (
                        <ErrorMessage message={error.emailAddress} />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>Amount</label>
                      <input
                        type="number"
                        step="any"
                        onChange={handleChanger}
                        name="amount"
                        value={user.amount}
                        className={`${error.amount && "error-input-z"}`}
                      />
                      {error.amount && <ErrorMessage message={error.amount} />}
                    </div>
                  </div>
                  <div className="input-container flex--3">
                    <div className="input-container--1 flex--1">
                      <div className="flex--3">
                        <label>Branch</label>
                        <input
                          type="text"
                          onChange={handleChanger}
                          name="branch"
                          value={user.branch}
                          className={`${error.branch && "error-input-z"}`}
                        />
                      </div>
                      <div className="sub-input">
                        <input
                          className={`${error.branch && "error-input-z"}`}
                        />
                      </div>
                    </div>
                    {error.branch && <ErrorMessage message={error.branch} />}
                    <div className="flex--3">
                      <label>Currency</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name="currency"
                        value={user.currency}
                        className={`${error.currency && "error-input-z"}`}
                      />
                      {error.currency && (
                        <ErrorMessage message={error.currency} />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>Phone Number</label>
                      <input
                        type="tel"
                        onChange={handleChanger}
                        name="phone"
                        value={user.phone}
                        className={`${error.phone && "error-input-z"}`}
                      />
                      {error.phone && <ErrorMessage message={error.phone} />}
                    </div>
                    <div className="flex--3">
                      <label>Instrument Type</label>
                      <input
                        type="text"
                        onChange={handleChanger}
                        name=" instrumentType"
                        value={user.instrumentType}
                        className={`${error.instrumentType && "error-input-z"}`}
                      />
                      {error.amount && <ErrorMessage message={error.amount} />}
                    </div>
                  </div>
                </div>
                <div className="last-input flex--3">
                <label>Narration</label>
                  <input
                   
                    type="text"
                    onChange={handleChanger}
                    name="narration"
                    value={user.narration}
                    className={`${error.narration && "error-input-z"}`}
                  />
                  {error.narration && (
                    <ErrorMessage message={error.narration} />
                  )}
                </div>
              </section>

              <div className="button-last">
                <button onClick={handleNext}>Next</button>
              </div>
            </>
          )}
          {screenChange && (
            <>
              <main className="sub-main">
                <section className="sub-main--1 flex--2">
                  <div className="flex--2">
                    <p>Amount</p>
                    <input  type="number" step="any"/>
                  </div>
                  <div className="flex--2">
                    <p>Net Amount</p>
                    <input  type="number" step="any"/>
                  </div>
                </section>
                <section className="sub-main--2 flex--3">
                  <div className="multiplier-input flex--2">
                    <input  />
                    <span>&times;</span>
                    <input  />
                    <span>&#61;</span>
                    <input  />
                  </div>
                  <div className="multiplier-input flex--2">
                    <input  />
                    <span>&times;</span>
                    <input  />
                    <span>&#61;</span>
                    <input  />
                  </div>
                  <div className="multiplier-input flex--2">
                    <input  />
                    <span>&times;</span>
                    <input  />
                    <span>&#61;</span>
                    <input  />
                  </div>
                  <div className="multiplier-input flex--2">
                    <input  />
                    <span>&times;</span>
                    <input  />
                    <span>&#61;</span>
                    <input  />
                  </div>
                </section>
              </main>
              <div className="button-last button-last--1 flex--2">
                <div className="button-container flex--1">
                  <button onClick={() => handleScreenChange(false)}>
                    Previous
                  </button>
                  <button onClick={() => handleScreenChange(true)}>Save</button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default CashWithdrawal;

import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { add } from "date-fns";
import MenuItem from "@material-ui/core/MenuItem";

import { getAllStatesByCountryCode } from "../../../../Api/places/getPlaces";
import { getAllLoanProducts } from "../../../../Api/Hooks-manager/products/products";
import {
  generateSampleSchedule,
  createLoanBooking,
  getAllLoanBooking,
  deleteLoanBooking,
  authorizeLoanBooking,
  getCustomerByAccountNumber,
  getLoanFacilityLimitByAccountNum,
  getLoanByLoanAccountNumber,
} from "../../../../Api/Hooks-manager/loanAccountOperations";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import {
  getBankParameterByName,
  getAllGlAccount,
  getAllCurrencyDefinition,
  getAllBranches,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { getAllAccountOfficers } from "../../../../Api/Hooks-manager/accountEnquiry/accounteEnquiry";
import { isValidEmail, isValidPhoneNumber } from "../../../../Helper/validator";
import { DateConverter } from "../../../ConvertDate";
import useTable from "../../../Tables/useTable";
import {
  isLoading,
  openViewModal,
  openDetailsModal,
} from "../../../../Api/redux-manager/actions";

export default function BussinessLogic() {
  const dispatch = useDispatch();
  const [guarantorForm, setGuarantorForm] = useState("customer");
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [screenChange, setScreenChange] = useState(1);
  const [open, setOpen] = useState(false);
  const loading = useSelector((state) => state.req.loading);
  const countries = useSelector((state) => state.common.countries);
  const [states, setStates] = useState([]);
  const [index, setIndex] = useState(0);
  const [takeAction, setTakeAction] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [message, setMessage] = useState("");
  const [invalidAccNo, setInvalidAccNo] = useState("");
  const [allGlAccount, setAllGlAccount] = useState([]);
  const [branches, setAllBranches] = useState([]);
  const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
  const [loanOfficers, setLoanOfficers] = useState([]);
  const [allLoanBookings, setAllLoanBookings] = useState([]);
  const [allLoanProducts, setAllLoanProducts] = useState([]);
  const [allRepayments, setAllRepayments] = useState([]);
  const [oneLoanProduct, setOneLoanProduct] = useState([]);
  const [errors, setErrors] = useState({});
  const [openView, setOpenView] = useState(false);
  const [view, setView] = useState({});
  const [openBoxAuthorize, setOpenAuthorize] = useState(false);
  const [openDecision, setOpenDecision] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const [authorizeLoan, setAuthorizeLoan] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const [details, setDetails] = useState({});

  const [sampleShedule, setSampleSchedule] = useState([]);

  const workdate = useSelector((state) => state.common.branch.workdate);

  const nowDate = format(new Date(workdate), "yyyy-MM-dd");

  const date = new Date();
  
  const [loanDetails, setLoanDetails] = useState({
    accountHolder: true,
    accountNumber: "",
    accountBalance: "",
    accountName: "",
    accountBranch: "",
    currencyCode: "",
    loanProduct: "",
    amount: "",
    bookDate: nowDate,
    tenor: 0,
    tenorBasis: "",
    maturityDate: "",
    interestRate: 0,
    interestPeriod: "",
    interestRateBasis: "",
    firstRepaymentDate: date,
    interestCalcMethod: "",
    amortizationType: "",
    repaymentFrequency: "",
    autoDisbursement: false,
    autoLiquidate: false,
    gaurantorRequired: false,
    startDate: nowDate, //added
    repaymentAccount: "",
    repCustomerName: "",
    repAccountBranch: "",
    repAccountCurrency: "",
    loanOfficer: "",
    purposeOfLoan: "",
    collateralRequired: false,
    bulletpayment: false,
    customerType: true,
    repaymentFrequencyUnit: 1,
    lienGuarantorAccount: false,
    loanGuarantors: [
      {
        customerType: true,
        guarantorAccNo: "",
        guarantorAccName: "",
        accountBranch: "",
        currencyCode: "",
        guarantorAddress: "",
        city: "",
        contactName: "",
        state: "",
        country: "",
        emailAddress: "",
        contactMobile: "",
      },
    ],
    loanCharges: [
      {
        chargeName: "",
        applyChargeAt: "",
        typeOfCharge: "",
        rate: "",
        chargeLedgerAccount: "",
        //rateBasis: "", //not in rules change it to chargeLedgerAccount
        amountBasis: "",
        chargeCurrency: "",
      },
    ],
    // repayments: [
    //   {
    //     repaymentAmount: "",
    //     repaymentPrincipal: "",
    //     repaymentInterest: "",
    //     sequenceId: "",
    //     dueDate: nowDate,
    //     currency: "",
    //     debitAccount: "",
    //   }
    // ],
  });

  const [loanLimit, setLoanLimit] = useState();
  const [acctBal, setAccBal] = useState();

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccount",
      label: "Loan Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanProduct",
      label: "Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allLoanBookings[dataIndex];
                if (singleItem && singleItem.id) {
                  fetchLoanAccountDetails(singleItem.loanAccount);
                }
                // console.log(singleItem, "iteeemmmmmmm")
                // if(item && item.id){
                //   fetchLoanAccountDetails(item.loanAccount)
                // }
                // const singleItem = allLoanBookings[dataIndex];
                // setDetails(singleItem);
                // dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchCustomerAccountLoanLimit = useCallback(
    async (accNum) => {
      const response = await getLoanFacilityLimitByAccountNum(token, accNum);
      if (response && response.data) {
        // console.log(response.data, "loan limitiiiiiiii")
        if (response && response.data) {
          // setErrors(prev => ({ ...prev, accountNumber: "" }));
          setLoanLimit(response.data.loanLimitBalance);
        }
      }
    },
    [token]
  );


  const fetchCustomerDetails = useCallback(async (accNum) => {
    dispatch(isLoading());
    const response = await getCustomerByAccountNumber(token, accNum);
    if (response && response.data) {
      console.log(response.data, "response...")
      dispatch(isLoading());
      if (response.status) {
        if (response.data.customer.bvn === "") {
          setErrors((prev) => ({
            ...prev,
            accountNumber: "This account does not have a BVN",
          }));
          setLoanDetails((prev) => ({
            ...prev,
            accountName: "",
            accountBranch: "",
            currencyCode: "",
            repaymentAccount: "",
            repCustomerName: "",
            repAccountBranch: "",
            repAccountCurrency: "",
          }));
        } else if (response.data.authorizationStatus === "Authorized") {
          setErrors((prev) => ({ ...prev, accountNumber: "" }));
          setLoanDetails((prev) => ({
            ...prev,
            accountName: response.data.accountName,
            accountBranch: response.data.branchCode,
            repaymentAccount: accNum,
            repCustomerName: response.data.accountName,
            repAccountBranch: response.data.branchCode,
            currencyCode: response.data.currency,
            repAccountCurrency: response.data.currency,
            productType: response.data.accountProduct.productName,
          }));
          setAccBal(response.data.accountBalance);
        } else {
          setErrors((prev) => ({
            ...prev,
            accountNumber: "Unauthorized account number",
          }));
          setLoanDetails((prev) => ({
            ...prev,
            accountName: "",
            accountBranch: "",
            currencyCode: "",
            repaymentAccount: "",
            repCustomerName: "",
            repAccountBranch: "",
            repAccountCurrency: "",
          }));
        }
      } else {
        setErrors((prev) => ({ ...prev, accountNumber: response.message }));
        setLoanDetails((prev) => ({
          ...prev,
          accountName: "",
          accountBranch: "",
          currencyCode: "",
          repaymentAccount: "",
          repCustomerName: "",
          repAccountBranch: "",
          repAccountCurrency: "",
        }));
      }
    }
  }, []);

  const fetchCustomerRepaymentDetails = useCallback(async (accNum) => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getCustomerByAccountNumber(user.jwtToken, accNum);

    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        if (response.data.authorizationStatus === "Authorized") {
          setErrors((prev) => ({ ...prev, repaymentAccount: "" }));
          setLoanDetails((prev) => ({
            ...prev,
            repCustomerName: response.data.accountName,
            repAccountBranch: response.data.branchCode,
            repAccountCurrency: response.data.currency,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            repaymentAccount: "Unauthorized account number",
          }));
          setLoanDetails((prev) => ({
            ...prev,
            repCustomerName: "",
            repAccountBranch: "",
            repAccountCurrency: "",
            // repaymentAmount: "",
            // repaymentPrincipal: "",
            // repaymentInterest: "",
            // sequenceId: "",
            // dueDate: "",
            // currency: "",
          }));
        }
      } else {
        setErrors((prev) => ({ ...prev, repaymentAccount: response.message }));
        setLoanDetails((prev) => ({
          ...prev,
          repCustomerName: "",
          repAccountBranch: "",
          repAccountCurrency: "",
          // repaymentAmount: "",
          // repaymentPrincipal: "",
          // repaymentInterest: "",
          // sequenceId: "",
          // dueDate: "",
          // currency: "",
        }));
      }
    }
  }, []);

  const fetchGuantorDetails = useCallback(async (accNum) => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const list = [...loanDetails.loanGuarantors];
    const response = await getCustomerByAccountNumber(user.jwtToken, accNum);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        if (response.data.authorizationStatus === "Authorized") {
          list[index]["guarantorAccName"] = response.data.accountName;
          list[index]["currencyCode"] = response.data.currency;
          list[index]["accountBranch"] = response.data.branchCode;
          list[index]["guarantorAddress"] = response.data.accountName;
          list[index]["city"] = response.data.customer.city;
          list[index]["state"] = response.data.customer.state;
          list[index]["country"] = response.data.customer.country;
          list[index]["emailAddress"] = response.data.customer.email;
          list[index]["contactMobile"] = response.data.customer.phoneNumber;
          setInvalidAccNo("");
          setLoanDetails((prev) => ({
            ...prev,
            loanGuarantors: list,
          }));
        } else {
          setInvalidAccNo("Unauthorized account number");
          clearGuarantorDetails();
        }
      } else {
        setInvalidAccNo(response.message);
        clearGuarantorDetails();
      }
    }
  }, []);

  const [openShedule, setOpenSchedule] = useState(false);

  const fetchLoanShedule = async (open = true) => {
    if (sampleShedule.length < 1) {
      dispatch(isLoading());
      const response = await generateSampleSchedule(token, loanDetails);
      if (response && response.data) {
        dispatch(isLoading());

        let newSchedule = response.data;
        if (newSchedule && !Array.isArray(newSchedule)) {
          newSchedule.currency = "NGN";
          newSchedule.debitAccount = loanDetails.accountNumber;
          newSchedule = [newSchedule];
        } else if (newSchedule && Array.isArray(newSchedule)) {

          newSchedule = newSchedule.map((schedule) => {
            schedule.currency = "NGN";
            schedule.debitAccount = loanDetails.accountNumber;
            return schedule;
          });
        }
        setSampleSchedule(newSchedule);
        setOpenSchedule(open);
      }
    } else {
      setOpenSchedule(open);
      // dispatch(isLoading());
    }
  };

  const clearAccountDetailsByInvalidAccNo = () => {
    setErrors({ ...errors, accountNumber: "" });
    setLoanDetails({
      ...loanDetails,
      accountName: "",
      accountBranch: "",
      currencyCode: "",
    });
  };

  const clearAccountDetailsByRepaymentAccNo = () => {
    setErrors({ ...errors, accountNumber: "" });
    setLoanDetails({
      ...loanDetails,
      repCustomerName: "",
      repAccountBranch: "",
      repAccountCurrency: "",
      repaymentAmount: "",
      repaymentPrincipal: "",
      repaymentInterest: "",
      sequenceId: "",
      dueDate: "",
      currency: "",
    });
  };

  const clearAccountDetails = () => {
    setErrors({ ...errors, accountNumber: "", repaymentAccount: "" });
    setLoanDetails({
      ...loanDetails,
      accountName: "",
      accountBranch: "",
      accountNumber: "",
      repaymentAccount: "",
      repCustomerName: "",
      repAccountBranch: "",
    });
  };

  const clearGuarantorDetails = () => {
    //setInvalidAccNo("");
    const list = [...loanDetails.loanGuarantors];
    list[index]["guarantorAccName"] = "";
    list[index]["currencyCode"] = "";
    list[index]["accountBranch"] = "";
    list[index]["guarantorAddress"] = "";
    list[index]["city"] = "";
    list[index]["state"] = "";
    list[index]["country"] = "";
    list[index]["emailAddress"] = "";
    list[index]["contactMobile"] = "";
    setLoanDetails({
      ...loanDetails,
      loanGuarantors: list,
    });
  };

  const clearGuarantorDetailsByUnchecking = () => {
    const list = [...loanDetails.loanGuarantors];
    for (let i = 0; i < list.length; i++) {
      list[i]["guarantorAccName"] = "";
      list[i]["currencyCode"] = "";
      list[i]["accountBranch"] = "";
      list[i]["guarantorAddress"] = "";
      list[i]["city"] = "";
      list[i]["state"] = "";
      list[i]["country"] = "";
      list[i]["emailAddress"] = "";
      list[i]["contactMobile"] = "";
      list[i]["guarantorAccNo"] = "";
    }
    setLoanDetails({
      ...loanDetails,
      loanGuarantors: list,
    });
  };

  const {
    accountNumber,
    repaymentAccount,
    loanGuarantors,
    accountHolder,
    customerType,
  } = loanDetails;

  useEffect(() => {
    if (accountNumber.length === 10) {
      fetchCustomerDetails(accountNumber);
    }
  }, [fetchCustomerDetails, accountNumber]);

  useEffect(() => {
    if (accountNumber.length === 10) {
      fetchCustomerAccountLoanLimit(accountNumber);
    }
  }, [fetchCustomerAccountLoanLimit, accountNumber]);

  useEffect(() => {
    if (repaymentAccount.length === 10) {
      fetchCustomerRepaymentDetails(repaymentAccount);
    }
  }, [fetchCustomerRepaymentDetails, repaymentAccount]);

  useEffect(() => {
    if (loanGuarantors[index].guarantorAccNo.length === 10) {
      fetchGuantorDetails(loanGuarantors[index].guarantorAccNo);
    }
  }, [fetchGuantorDetails, loanGuarantors[index].guarantorAccNo]);

  useEffect(() => {
    if (loanGuarantors[index].country) {
      fetchState(loanGuarantors[index].country);
    }
  }, [loanGuarantors[index].country]);

  useEffect(() => {
    if (!accountHolder) {
      clearAccountDetails();
    }
  }, [accountHolder]);

  useEffect(() => {
    if (accountNumber.length !== 10) {
      clearAccountDetailsByInvalidAccNo();
    }
  }, [accountNumber]);

  useEffect(() => {
    if (repaymentAccount.length !== 10) {
      clearAccountDetailsByRepaymentAccNo();
    }
  }, [repaymentAccount]);

  useEffect(() => {
    if (loanGuarantors[index].guarantorAccNo.length !== 10 || !customerType) {
      clearGuarantorDetails();
    }
  }, [loanGuarantors[index].guarantorAccNo, customerType]);

  useEffect(() => {
    if (!customerType) {
      clearGuarantorDetailsByUnchecking();
    }
  }, [customerType]);

  const headCells = [
    { id: "id", label: "S/N" },
    { id: "loanAccount", label: "Loan Account No." },
    { id: "accountName", label: "Name" },
    { id: "loanProduct", label: "Product" },
    { id: "authorizationStatus", label: "Status" },
    // { id: "delete", label: "Delete", disableSorting: true },
    //{ id: "edit", label: "Edit", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(allLoanBookings, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.loanAccount.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const fetchAllLoanProducts = async () => {
    const response = await getAllLoanProducts(token);
    console.log(response, "all loan products...");
    if (response && response.data) {
      // console.log("ALL LOAN PRODUCTS-----", response.data);
      setAllLoanProducts(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  };

  // var number = 5000;
  // var percentX = 12;

  const autoAutoUpdateStateByProductCode = (value) => {
    const list = [...allLoanProducts];
    let item = list.filter((item) => item.productCode === value);
    // var percentageValueTesting;

    // const percentCalculation = (a, b) => {
    //   var c = (parseFloat(a)*parseFloat(b))/100;
    //   return parseFloat(c);
    // }
    // percentageValueTesting = percentCalculation(5000, 20); //calculate percentX% of number
    // console.log(percentageValueTesting, "this is result of percentage");
    if (item && item.length > 0) {
      item = item[0];
      setLoanDetails({
        ...loanDetails,
        amount: item.defaultPrincipal,
        interestCalcMethod: item.interestCalculationMethod,
        amortizationType: item.amortizationType,
        repaymentFrequency: item.repaymentFrequency.toUpperCase(),
        tenor: item.defaultTenor,
        tenorBasis: item.tenorBasis,
        interestRate: item.defaultInterestRate,
        interestPeriod: item.interestPeriod,
        interestRateBasis: item.rateBasis,
        equityContribution: item.equityMultiple,
        autoDisbursement:
          item.disbursementMethod === "Automatic" ? true : false,
        autoLiquidate: item.liquidationType === "Automatic" ? true : false,
        gaurantorRequired:
          item.guarantorRequired === "Automatic" ? true : false,
        //currencyCode: item.currencyCode,
        loanCharges: item.loanProductCharge.map((item) => {
          return {
            chargeName: item.chargeName.replace(/\s/g, ""),
            applyChargeAt: item.applyChargeAt.replace(/\s/g, ""),
            typeOfCharge: item.typeOfCharge.replace(/\s/g, ""),
            rate: item.rate ? Number(item.rate) : 0,
            chargeLedgerAccount: item.chargeLedgerAccount,
            amountBasis: item.amount ? Number(item.amount) : 0,
            chargeCurrency: item.chargeCurrency,
          };
        }),
        bulletpayment: item.bulletpayment,
      });
      setOneLoanProduct(item);
      setShow(true);
    }
  };


  const autoUpdateRepaymentsField = (value) => {
    const single = [...allRepayments];
    let items = single.filter((items) => items.repaymentAccount === value);

    if (items && items.length > 0) {
      items = items[0];
      setLoanDetails({
        ...loanDetails,
        repayments: items.repaymentsDetails.map((items) => {
          return {
            repaymentAmount: items.repaymentAmount.replace(/\s/g, ""),
            repaymentPrincipal: items.repaymentPrincipal.replace(/\s/g, ""),
            repaymentInterest: items.repaymentInterest.replace(/\s/g, ""),
            sequenceId: items.sequenceId,
            dueDate: items.dueDate,
            currency: items.currency,
            debitAccount: items.debitAccount,
          };
        }),
      });
    }
  };

  // console.log('loanDetails',loanDetails)

  const hideLoanDetails = () => {
    if (!loanDetails.loanProduct) setShow(false);
  };

  useEffect(() => {
    hideLoanDetails();
  }, [loanDetails.loanProduct]);

  // //---------------------for calc datediff start-------------------------//
  // const newDatediff = new Date();
  // newDatediff.setDate(10);
  // const caculateNewDays = (currentDate, noOfMonths) => {
  //   if (noOfMonths === 0) return currentDate
  //   const remainingMonths = 12 - (currentDate.getMonth() + 1)
  //   if(noOfMonths > remainingMonths){
  //       currentDate.setFullYear(currentDate.getFullYear() + 1)
  //   }
  //   const addYear = (noOfMonths - remainingMonths) / 12
  //   const newMonth = (noOfMonths - remainingMonths) % 12

  //   if (addYear >= 1){
  //       currentDate.setFullYear(currentDate.getFullYear() + addYear)
  //       currentDate.setMonth(0)
  //       currentDate.setMonth(newMonth - 1)
  //   }
  //   else{
  //       currentDate.setMonth(newMonth - 1)
  //   }
  //   console.log('calculateNewDays result', currentDate)
  //   return currentDate
  // }
  // console.log(caculateNewDays(newDatediff, 0).toLocaleString())
  // //---------------------for calc datediff end-------------------------//

  const calculateDays = (
    tenorBasis,
    tenor,
    date = new Date(loanDetails.startDate)
  ) => {
    let result = add(new Date(date), {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
    });
    // console.log(result, new Date(result), "tttttttttttttttttttttt")
    let formattedDate = format(new Date(result), "yyyy-MM-dd");
    switch (tenorBasis) {
      case "Days":
        result = add(new Date(date), {
          years: 0,
          months: 0,
          weeks: 0,
          days: tenor,
        });

        return format(new Date(result), "yyyy-MM-dd");
      // console.log(formattedDate, "this is new formattedDate")
      case "Weekly":
        result = add(new Date(date), {
          years: 0,
          months: 0,
          weeks: tenor,
          days: 0,
        });
        return format(new Date(result), "yyyy-MM-dd");
      case "Months":
        result = add(new Date(date), {
          years: 0,
          months: tenor,
          weeks: 0,
          days: 0,
        });
        return format(new Date(result), "yyyy-MM-dd");
      case "Yearly":
        result = add(new Date(date), {
          years: tenor,
          months: 0,
          weeks: 0,
          days: 0,
        });
        return format(new Date(result), "yyyy-MM-dd");

      default:
        return formattedDate;
    }
  };

  //-------------------------------------------TESTING FOR DATE ADDING START----------------------------------------------//
  const calculateDaysForRepayment = (
    repaymentFrequency,
    date = new Date(loanDetails.startDate)
  ) => {
    let result = new Date(date);
    let formattedDate = format(new Date(result), "yyyy-MM-dd");
    switch (repaymentFrequency.toLowerCase()) {
      case "daily":
        result = add(new Date(date), {
          years: 0,
          months: 0,
          weeks: 0,
          days: 1,
        });
        return format(new Date(result), "yyyy-MM-dd");
      // console.log(formattedDate, "this is new formattedDate")
      case "weekly":
        result = add(new Date(date), {
          years: 0,
          months: 0,
          weeks: 1,
          days: 0,
        });

        return format(new Date(result), "yyyy-MM-dd");
      case "monthly":
        result = add(new Date(date), {
          years: 0,
          months: 1,
          weeks: 0,
          days: 0,
        });
        return format(new Date(result), "yyyy-MM-dd");
      case "yearly":
        result = add(new Date(date), {
          years: 1,
          months: 0,
          weeks: 0,
          days: 0,
        });

        return format(new Date(result), "yyyy-MM-dd");

      default:
        return formattedDate;
    }
  };

  //-------------------------------------------TESTING FOR DATE ADDING END----------------------------------------------//

  // var addDays = require('date-fns/addDays')

  // const resultNew = addDays((new Date(2021,9,27)), 17)

  // console.log(resultNew, "lets check it out")

  const fetchState = async (value) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getAllStatesByCountryCode(user.jwtToken, value);
    if (response && response.data) {
      setStates(response.data);
    }
  };

  // useEffect(() => {

  // },[loanDetails.country])

  useEffect(() => {
    if (loanDetails.startDate) {
      // console.log(loanDetails.startDate.length, "ssdadsdsdsd")
      setLoanDetails({
        ...loanDetails,
        startDate: loanDetails.startDate,
        maturityDate: calculateDays(
          loanDetails.tenorBasis,
          loanDetails.tenor,
          loanDetails.startDate
        ),
      });
    }
  }, [loanDetails.tenor, loanDetails.tenorBasis, loanDetails.loanProduct]);

  const calculateInterestRate = (amount) => {
    const lists = [...loanDetails.loanCharges];
    for (let item of lists) {
      // console.log("got here....")
      item.amountBasis =
        item.typeOfCharge === "FlatAmount"
          ? item.amountBasis
          : item.rate * Number(amount) * 0.01;
      item.rate =
        item.typeOfCharge === "Rate"
          ? item.rate
          : (item.amountBasis * 100) / Number(amount);
      console.log("end of loop....", item);
    }
    // console.log("before setting state", lists)
    setLoanDetails((prev) => ({
      ...prev,
      loanCharges: lists,
    }));
    // console.log("after setting state", lists)
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "customerType") {
      setGuarantorForm(value);
    }
    if (
      (name === "accountNumber" || name === "repaymentAccount") &&
      value.length > 10
    )
      return;
    setLoanDetails({ ...loanDetails, [name]: value });
    if (name === "amount" || name === "tenor" || name === "interestRate") {
      const inputedVal =
        value === "" ? "" : parseFloat(value) < 0 ? 0 : parseFloat(value);
      setLoanDetails({
        ...loanDetails,
        [name]: inputedVal,
      });
    }
    if (
      name === "accountHolder" ||
      name === "autoDisbursement" ||
      name === "collateralRequired" ||
      name === "autoLiquidate" ||
      name === "gaurantorRequired" ||
      name === "customerType" ||
      name === "bulletpayment" ||
      name === "lienGuarantorAccount"
    ) {
      setLoanDetails({ ...loanDetails, [name]: checked });
    }
    if (name === "startDate") {
      setLoanDetails({
        ...loanDetails,
        // bookDate: value,
        startDate: value,
        maturityDate: calculateDays(
          loanDetails.tenorBasis,
          loanDetails.tenor,
          value
        ),
      });
      // console.log('DATE for loan ', value, format(calculateDays(loanDetails.tenorBasis, loanDetails.tenor, value), "yyyy-MM-dd"));
    }

    if (name === "loanProduct") {
      // console.log("just checking loanproduct ooo", loanDetails.repaymentFrequency)
      // console.log(calculateDaysForRepayment(loanDetails.repaymentFrequency), "checking repayment func")
    }

    // if (name === "firstRepaymentDate"){
    //   console.log("Emma", format(calculateDaysForRepayment(loanDetails.repaymentFrequency)))
    //   // const newRepaymentDate = new Date(value)
    //   setLoanDetails({
    //     ...loanDetails,
    //     startDate: value,
    //     firstRepaymentDate: format(calculateDaysForRepayment(loanDetails.repaymentFrequency), "yyyy-MM-dd")
    //   })
    //   console.log(format(calculateDaysForRepayment(loanDetails.repaymentFrequency), "yyyy-MM-dd"));
    // }
    // console.log(loanDetails,);
  };

  const handleSearchableChangeSelect = (value, type) => {
    setLoanDetails((loanDetails) => ({ ...loanDetails, [type]: value }));
  };

  const { loanProduct, repaymentFrequency, repayments } = loanDetails;

  useEffect(() => {
    if (loanProduct) {
      autoAutoUpdateStateByProductCode(loanProduct);
    }
  }, [loanProduct]);

  useEffect(() => {
    if (repaymentFrequency && loanDetails.startDate) {
      // console.log("just checking loanproduct ooo", repaymentFrequency)
      const repaymentDate = calculateDaysForRepayment(
        repaymentFrequency,
        loanDetails.startDate
      );
      setLoanDetails((prev) => ({
        ...prev,
        firstRepaymentDate: repaymentDate,
      }));
      // console.log(repaymentDate, "checking repayment func")
    }
  }, [repaymentFrequency, loanDetails.startDate]);

  const handleChargeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...loanDetails.loanCharges];
    list[index][name] =
      name === "rate" || name === "amountBasis"
        ? value === ""
          ? ""
          : parseFloat(value) < 0
          ? 0
          : parseFloat(value)
        : value;
    setLoanDetails({ ...loanDetails, loanCharges: list });
    // console.log(loanDetails.loanCharges);
  };

  const handleLoanGuarantorsChange = (e, index) => {
    setIndex(index);
    const { name, value, checked } = e.target;
    const list = [...loanDetails.loanGuarantors];
    if (name === "guarantorAccNo" && value.length > 10) return;
    list[index][name] = value;
    setLoanDetails({ ...loanDetails, loanGuarantors: list });
    // console.log(loanDetails.loanGuarantors);
    if (name === "country") {
      fetchState(value);
    }
    if( name === "lienGuarantorAccount"){
      setLoanDetails({ ...loanDetails, [name]: checked });
    }
  };

  const handleRepaymentsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...loanDetails.repayments];
    if (name === "repaymentAmount" && value.length === 0) return;
    list[index][name] = value;
    setLoanDetails({ ...loanDetails, repayments: list });
  };

  const addLoanCharges = () => {
    const list = [...loanDetails.loanCharges];
    if (list.length > 2) return;
    list.push({
      chargeName: "",
      applyChargeAt: "",
      typeOfCharge: "",
      rate: 0,
      chargeLedgerAccount: "",
      amountBasis: "",
      chargeCurrency: "",
    });
    setLoanDetails({
      ...loanDetails,
      loanCharges: list,
    });
  };

  const removeLoanCharges = (index) => {
    const list = [...loanDetails.loanCharges];
    if (list.length < 2) return;
    list.splice(index, 1);
    setLoanDetails({
      ...loanDetails,
      loanCharges: list,
    });
  };

  const addLoanGuarantors = () => {
    const list = [...loanDetails.loanGuarantors];
    list.push({
      customerType: true,
      guarantorAccNo: "",
      guarantorAccName: "",
      accountBranch: "",
      currencyCode: "",
      guarantorAddress: "",
      city: "",
      contactName: "",
      state: "",
      country: "",
      emailAddress: "",
      contactMobile: "",
    });
    setLoanDetails({
      ...loanDetails,
      loanGuarantors: list,
    });
  };

  const removeLoanGuarantors = (index) => {
    const list = [...loanDetails.loanGuarantors];
    if (list.length < 2) return;
    list.splice(index, 1);
    setLoanDetails({
      ...loanDetails,
      loanGuarantors: list,
    });
  };

  const addRepayments = (index) => {
    const list = [...loanDetails.repayments];
    list.push({
      repaymentAmount: 0,
      repaymentPrincipal: 0,
      repaymentInterest: 0,
      sequenceId: 0,
      dueDate: date,
      currency: "",
      debitAccount: "",
    });
    setLoanDetails({
      ...loanDetails,
      repayments: list,
    });
  };

  const removeRepayments = (index) => {
    const list = [...loanDetails.repayments];
    if (list.length < 2) return;
    list.splice(index, 1);
    setLoanDetails({
      ...loanDetails,
      repayments: list,
    });
  };

  const validateEquityWithAccBal = acctBal * loanDetails.equityContribution;

  const validateFirstScreen = () => {
    const errors = {};
    const {
      accountHolder,
      accountName,
      accountNumber,
      accountBranch,
      currencyCode,
      loanProduct,
      amount,
      bookDate,
      interestCalcMethod,
      tenor,
      maturityDate,
      amortizationType,
      interestRate,
      firstRepaymentDate,
      repaymentFrequency,
      repaymentFrequencyUnit,
    } = loanDetails;

    // const{loanLimitBalance} = loanLimit

    if (!accountHolder && accountName === "") {
      errors.accountName = "Field is required";
    }
    if (accountHolder && accountName === "") {
      errors.accountNumber = "Invalid account number";
    }
    if (accountHolder && accountNumber.length !== 10) {
      errors.accountNumber = "Ten characters are required";
    }
    if (!accountHolder && currencyCode === "") {
      errors.currencyCode = "Field is required";
    }
    if (accountBranch === "") {
      errors.accountBranch = "";
    }
    if (loanLimit === "") {
      errors.loanLimit = "";
    }
    if (loanProduct === "") {
      errors.loanProduct = "Invalid entry";
    }
    if (amount === "") {
      errors.amount = "Field is required";
    }

    if (amount === 0) {
      errors.amount = "Loan amount must be greater than zero";
    }

    if (amount < oneLoanProduct.minimumPrincipal) {
      errors.amount = `Min: ${oneLoanProduct.minimumPrincipal} -  Max: ${oneLoanProduct.maximumPrincipal}`;
    }
    if (amount > oneLoanProduct.maximumPrincipal) {
      errors.amount = `Min: ${oneLoanProduct.minimumPrincipal} - Max: ${oneLoanProduct.maximumPrincipal}`;
    }
    if (amount > loanLimit) {
      errors.amount = `Loan amount cannot be greater than the Loan limit balance: ${loanLimit}`;
    }

    if(acctBal < validateEquityWithAccBal){
      errors.amount = `Customer"s account balance is insufficient, please request for a lower amount`;
    }

    if (bookDate === "") {
      errors.bookDate = "Field is required";
    }
    // console.log(new Date() > new Date(new Date(bookDate)));
    // console.log(new Date(new Date(DateConverter(new Date))));
    // console.log( new Date(new Date(bookDate)));
    // if (new Date(new Date(DateConverter(new Date))) > new Date(new Date(bookDate))){
    //   errors.bookDate = "Date can not be less than today's date";
    // }
    if (interestCalcMethod === "") {
      errors.interestCalcMethod = "Field is required";
    }
    if (tenor === "") {
      errors.tenor = "Invalid entry";
    }
    if (tenor < oneLoanProduct.minimumTenor) {
      errors.tenor = `Min: ${oneLoanProduct.minimumTenor} -  Max: ${oneLoanProduct.maximumTenor}`;
    }
    if (tenor > oneLoanProduct.maximumTenor) {
      errors.tenor = `Min: ${oneLoanProduct.minimumTenor} - Max: ${oneLoanProduct.maximumTenor}`;
    }
    if (maturityDate === "") {
      errors.maturityDate = "Field is required";
    }
    if (amortizationType === "") {
      errors.amortizationType = "Field is required";
    }
    if (interestRate === "") {
      errors.interestRate = "Field is required";
    }
    if (interestRate < oneLoanProduct.minimumInterestRate) {
      errors.interestRate = `Min: ${oneLoanProduct.minimumInterestRate} -  Max: ${oneLoanProduct.maximumTenor}`;
    }
    if (interestRate > oneLoanProduct.maximumInterestRate) {
      errors.interestRate = `Min: ${oneLoanProduct.minimumInterestRate} - Max: ${oneLoanProduct.maximumInterestRate}`;
    }
    if (firstRepaymentDate === "") {
      errors.firstRepaymentDate = "Field is required";
    }
    if (repaymentFrequency === "") {
      errors.repaymentFrequency = "Field is required";
    }
    if (repaymentFrequencyUnit === "") {
      errors.frequencyUnit = "Field is required";
    }
    if (repaymentFrequencyUnit < 1) {
      errors.repaymentFrequencyUnit = "value must be 1 or greater than 1";
    }

    return errors;
  };

  const validateSecondScreen = () => {
    const errors = {
      chargeName: [],
      applyChargeAt: [],
      chargeLedgerAccount: [],
      typeOfCharge: [],
      amountBasis: [],
      rate: [],
      chargeCurrency: [],
    };

    // const errors2 = {
    //   repaymentAmount: [],
    //   repaymentPrincipal: [],
    //   repaymentInterest: [],
    //   sequenceId: [],
    //   dueDate: [],
    //   currency: [],
    //   debitAccount: [],
    // }

    const {
      accountHolder,
      repaymentAccount,
      repCustomerName,
      repAccountBranch,
      repAccountCurrency,
      repaymentAmount,
      repaymentPrincipal,
      repaymentInterest,
      sequenceId,
      dueDate,
      currency,
      debitAccount,
      loanOfficer,
      customerType,
      loanCharges,
      repayments,
      purposeOfLoan,
    } = loanDetails;

    if (!accountHolder && repaymentAccount === "") {
      errors.repaymentFrequency = "Invalid entry";
    }
    if (!accountHolder && repCustomerName === "") {
      errors.repCustomerName = "Invalid entry";
    }
    if (accountHolder && repCustomerName === "") {
      errors.repaymentAccount = "Invalid account number";
    }
    if (accountHolder && repaymentAccount.length !== 10) {
      errors.repaymentAccount = "Ten characters are required";
    }
    if (!accountHolder && repAccountBranch === "") {
      errors.repAccountBranch = "Invalid entry";
    }
    if (!accountHolder && repAccountCurrency === "") {
      errors.repAccountCurrency = "Invalid entry";
    }
    if (purposeOfLoan === "") {
      errors.purposeOfLoan = "Field is required";
    }
    if (purposeOfLoan.length > 50) {
      errors.purposeOfLoan = "Max. of 50 characters";
    }

    if (!accountHolder && repaymentAmount < 0) {
      errors.repaymentAmount = "Invalid entry";
    }

    if (!accountHolder && repaymentPrincipal < 0) {
      errors.repaymentPrincipal = "Invalid entry";
    }

    if (!accountHolder && repaymentInterest === "") {
      errors.repaymentInterest = "Field is required";
    }

    if (loanOfficer === "") {
      errors.loanOfficer = "Field is required";
    }

    if (!accountHolder && sequenceId === "") {
      errors.sequenceId = "Field is required";
    }

    if (!accountHolder && dueDate === "") {
      errors.dueDate = "Field is required";
    }

    if (!accountHolder && currency === "") {
      errors.currency = "Field is required";
    }

    if (!accountHolder && debitAccount === "") {
      errors.debitAccount = "Field is required";
    }

    // for (let i = 0; i < repayments.length; i++) {
    //   if (repayments[i].repaymentAmount === "") {
    //     errors2.repaymentAmount.push({
    //       id: i,
    //     })
    //   }
    //   if (repayments[i].repaymentPrincipal === "") {
    //     errors2.repaymentPrincipal.push({
    //       id: i,
    //     })
    //   }
    //   if (repayments[i].repaymentInterest === "") {
    //     errors2.repaymentInterest.push({
    //       id: i,
    //     })
    //   }
    //   if (repayments[i].sequenceId === "") {
    //     errors2.sequenceId.push({
    //       id: i,
    //     })
    //   }
    //   if (repayments[i].dueDate === "") {
    //     errors2.dueDate.push({
    //       id: i,
    //     })
    //   }
    //   if (repayments[i].currency === "") {
    //     errors2.currency.push({
    //       id: i,
    //     })
    //   }
    //   if (repayments[i].debitAccount === "") {
    //     errors2.debitAccount.push({
    //       id: i,
    //     })
    //   }
    // }

    for (let i = 0; i < loanCharges.length; i++) {
      if (loanCharges[i].chargeName === "") {
        errors.chargeName.push({
          id: i,
        });
      }
      if (loanCharges[i].applyChargeAt === "") {
        errors.applyChargeAt.push({
          id: i,
        });
      }
      if (loanCharges[i].chargeLedgerAccount === "") {
        errors.chargeLedgerAccount.push({
          id: i,
        });
      }
      if (loanCharges[i].typeOfCharge === "") {
        errors.typeOfCharge.push({
          id: i,
        });
      }
      if (loanCharges[i].amountBasis === "") {
        errors.amountBasis.push({
          id: i,
        });
      }
      if (loanCharges[i].rate === "") {
        errors.rate.push({
          id: i,
        });
      }
      if (loanCharges[i].chargeCurrency === "") {
        errors.chargeCurrency.push({
          id: i,
        });
      }
    }

    if (!errors.chargeName.length) delete errors.chargeName;
    if (!errors.applyChargeAt.length) delete errors.applyChargeAt;
    if (!errors.chargeLedgerAccount.length) delete errors.chargeLedgerAccount;
    if (!errors.typeOfCharge.length) delete errors.typeOfCharge;
    if (!errors.amountBasis.length) delete errors.amountBasis;
    if (!errors.rate.length) delete errors.rate;
    if (!errors.chargeCurrency.length) delete errors.chargeCurrency;
    return errors;
  };

  const validateLastPage = () => {
    const errors = {
      guarantorAccNo: [],
      guarantorAccName: [],
      accountBranch: [],
      currencyCode: [],
      guarantorAddress: [],
      city: [],
      contactName: [],
      state: [],
      country: [],
      emailAddress: [],
      contactMobile: [],
    };
    const { loanGuarantors, customerType, lienGuarantorAccount } = loanDetails;
    if(lienGuarantorAccount === false){
      errors.lienGuarantorAccount = "It is compulsory to place lien on guarantor account"
    }
    for (let i = 0; i < loanGuarantors.length; i++) {
      if (customerType && loanGuarantors[i].guarantorAccNo.length < 10) {
        setInvalidAccNo("Account No. is 10 character long");
      }


      if (
        !loanGuarantors[i].customerType &&
        loanGuarantors[i].guarantorAccName === ""
      ) {
        errors.guarantorAccName.push({
          id: i,
        });
      }

      if (!customerType && loanGuarantors[i].guarantorAddress === "") {
        errors.guarantorAddress.push({
          id: i,
        });
      }

      if (!customerType && loanGuarantors[i].city === "") {
        errors.city.push({
          id: i,
        });
      }

      if (loanGuarantors[i].contactName === "") {
        errors.contactName.push({
          id: i,
        });
      }

      if (!customerType && loanGuarantors[i].state === "") {
        errors.state.push({
          id: i,
        });
      }

      if (!customerType && loanGuarantors[i].country === "") {
        errors.country.push({
          id: i,
        });
      }

      if (
        !customerType &&
        (loanGuarantors[i].emailAddress === "" ||
          !isValidEmail(loanGuarantors[i].emailAddress))
      ) {
        errors.emailAddress.push({
          id: i,
        });
      }

      if (
        !customerType &&
        (loanGuarantors[i].contactMobile === "" ||
          !isValidPhoneNumber(loanGuarantors[i].contactMobile))
      ) {
        errors.contactMobile.push({
          id: i,
        });
      }
    }

    if (!errors.guarantorAccNo.length) delete errors.guarantorAccNo;
    if (!errors.guarantorAccName.length) delete errors.guarantorAccName;
    if (!errors.accountBranch.length) delete errors.accountBranch;
    if (!errors.currencyCode.length) delete errors.currencyCode;
    if (!errors.guarantorAddress.length) delete errors.guarantorAddress;
    if (!errors.city.length) delete errors.city;
    if (!errors.contactName.length) delete errors.contactName;
    if (!errors.state.length) delete errors.state;
    if (!errors.country.length) delete errors.country;
    if (!errors.emailAddress.length) delete errors.emailAddress;
    if (!errors.contactMobile.length) delete errors.contactMobile;

    return errors;
  };

  const handleFirstScreen = () => {
    const checkErrors = validateFirstScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    fetchLoanShedule(false);
    setErrors({});
    setScreenChange(2);
  };

  const handleSecondScreen = () => {
    const checkErrors = validateSecondScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
    setScreenChange(3);
  };

  const EmptyFieldsLoanBooking = () => {
    setLoanDetails({
      accountHolder: true,
      accountNumber: "",
      accountName: "",
      accountBranch: "",
      currencyCode: "",
      loanProduct: "",
      amount: "",
      bookDate: DateConverter(nowDate),
      tenor: 0,
      tenorBasis: "",
      maturityDate: "",
      interestRate: 0,
      interestPeriod: "",
      interestRateBasis: "",
      firstRepaymentDate: "",
      interestCalcMethod: "",
      amortizationType: "",
      repaymentFrequency: "",
      autoDisbursement: false,
      autoLiquidate: false,
      gaurantorRequired: false,
      startDate: "", //added
      repaymentAccount: "",
      repCustomerName: "",
      repAccountBranch: "",
      repAccountCurrency: "",
      loanOfficer: "",
      purposeOfLoan: "",
      collateralRequired: false,
      bulletpayment: false,
      repaymentFrequencyUnit: 1,
      lienGuarantorAccount: false,
      loanGuarantors: [
        {
          customerType: true,
          guarantorAccNo: "",
          guarantorAccName: "",
          accountBranch: "",
          currencyCode: "",
          guarantorAddress: "",
          city: "",
          contactName: "",
          state: "",
          country: "",
          emailAddress: "",
          contactMobile: "",
          lienGuarantorAccount: false,
        },
      ],
      loanCharges: [
        {
          chargeName: "",
          applyChargeAt: "",
          typeOfCharge: "",
          rate: "",
          chargeLedgerAccount: "",
          //rateBasis: "", //not in rules change it to chargeLedgerAccount
          amountBasis: "",
          chargeCurrency: "",
        },
      ],
      // repayments: [
      //   {
      //     repaymentAmount: 0,
      //     repaymentPrincipal: 0,
      //     repaymentInterest: 0,
      //     sequenceId: 0,
      //     dueDate: "",
      //     currency: "",
      //     debitAccount: "",
      //   }
      // ],
    });
  };

  const postLoanBooking = async () => {
    dispatch(isLoading());
    const data = { ...loanDetails };
    data.repayments = [...sampleShedule];
    const response = await createLoanBooking(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setMessage(
          `Your loan has been succesfully booked. Your loan account No. is ${response.data.loanAccount}`
        );
        setShow(false);
        EmptyFieldsLoanBooking();
        fetchAllLoanBooking();
        setScreenChange(1);
        return true;
      } else {
        setMessage(response.message);
        return false;
      }
    }
  };

  const fetchAllGlAccount = async () => {
    const response = await getAllGlAccount(token);
    if (response && response.data) {
      setAllGlAccount(response.data);
    }
  };

  const fetchBranches = async () => {
    const response = await getAllBranches(token);

    if (response && response.data) {
      setAllBranches(response.data);
    }
  };

  const fetchAllCurrencyDefinition = async () => {
    const response = await getAllCurrencyDefinition(token);
    if (response && response.data) {
      setAllCurrencyDefinition(response.data);
    }
  };

  const fetchAllLoanBooking = async () => {
    dispatch(isLoading());
    const response = await getAllLoanBooking(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
    }
    setAllLoanBookings(response.data);
  };

  const fetchLoanAccountDetails = async (accNum) => {
    dispatch(isLoading());
    const response = await getLoanByLoanAccountNumber(token, accNum);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setDetails(response.data);
        dispatch(openViewModal());
      }
    }
  };

  // useEffect(() => {
  //   fetchLoanAccountDetails()
  // }, [])

  const deleteALoanBooking = async (id) => {
    dispatch(isLoading());
    const response = await deleteLoanBooking(token, id);
    if (response && response.message) {
      dispatch(isLoading());
      if (response.status) {
        setMessage(response.message);
        return true;
      } else {
        return false;
      }
    }
  };

  const removeLoanBooking = () => {
    const lists = [...allLoanBookings];
    const undeletedItems = lists.filter((item) => item.id !== loanId);
    setAllLoanBookings(() => undeletedItems);
    return deleteALoanBooking(loanId);
  };

  const handleSubmit = () => {
    const lists = [...loanDetails.loanGuarantors];
    for (let i = 0; i < lists.length; i++) {
      lists[i].customerType = loanDetails.customerType;
    }
    setLoanDetails({ ...loanDetails, loanGuarantors: lists });
    if (guarantorForm === "customer") {
      const checkErrors = validateLastPage();
      setErrors(checkErrors);
      if (invalidAccNo || Object.keys(checkErrors).length > 0) return;
    }
    setOpen(true);
  };

  const parameters = "loan";

  const fetchAccountOfficers = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getAllAccountOfficers(
      user.jwtToken,
      parameters,
      1,
      1000
    );
    if (response && response.data) {
      setLoanOfficers(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  };

  // const BankParameterByName = async () => {
  //   const response = await getBankParameterByName(token, parameters);
  //   if (response && response.data.loan_Officers) {
  //     setLoanOfficers(
  //       response.data.loan_Officers.map((item) => {
  //         return {
  //           value: item.parameterValue,
  //           label: item.parameterValue,
  //         };
  //       })
  //     );
  //   }
  // };

  const decide = async () => {
    dispatch(isLoading());
    const response = await authorizeLoanBooking(token, authorizeLoan, view.id);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.statusCode === 200) {
        setOpenView(false);
        setOpenAuthorize(false);
        setAuthorizeLoan({
          reason: "",
          isApproved: true,
          authorizationStatus: 0,
        });
        fetchAllLoanBooking();
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    fetchAllLoanBooking();
    // BankParameterByName();
    fetchAllGlAccount();
    fetchAllCurrencyDefinition();
    fetchAllLoanProducts();
    fetchBranches();
    fetchAccountOfficers();
  }, []);

  useEffect(() => {
    console.log("rendering...", loanDetails.amount);
    calculateInterestRate(loanDetails.amount);
  }, [loanDetails.amount]);

  const onEditedScheduleSave = (editedSchedule) => {
    setSampleSchedule(editedSchedule);
    setLoanDetails((prev) => ({ ...prev, repayments: editedSchedule }));
  };

  return {
    loanDetails,
    setLoanDetails,
    show,
    invalidAccNo,
    index,
    addLoanCharges,
    removeLoanCharges,
    addLoanGuarantors,
    removeLoanGuarantors,
    addRepayments,
    removeRepayments,
    handleChange,
    handleChargeChange,
    handleLoanGuarantorsChange,
    handleRepaymentsChange,
    allGlAccount,
    allCurrencyDefinition,
    allLoanProducts,
    loanOfficers,
    countries,
    states,
    // cusCategory,
    // loanStatus,
    open,
    setOpen,
    loading,
    handleSearchableChangeSelect,
    sampleShedule,
    fetchLoanShedule,
    openShedule,
    setOpenSchedule,
    // handleLoanProductChange,
    // handleOverdueDayChange,
    // handleRepaymentOrderChange,
    // handleCustomerRestrictionChange,
    // addLoanProductCharges,
    // addOverdueDays,
    // addRepaymentOrder,
    // addCustomerRestriction,
    // removeCustomerRestriction,
    // removeRepaymentOrder,
    // removeOverdueDays,
    // removeLoanProductCharges,
    handleSubmit,
    postLoanBooking,
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    handleSearch,
    message,
    takeAction,
    setTakeAction,
    setLoanId,
    removeLoanBooking,
    setErrors,
    errors,
    screenChange,
    setScreenChange,
    handleFirstScreen,
    handleSecondScreen,
    openView,
    setOpenView,
    edit,
    setEdit,
    view,
    setView,
    openBoxAuthorize,
    setOpenAuthorize,
    openDecision,
    setOpenDecision,
    decide,
    authorizeLoan,
    setAuthorizeLoan,
    DateConverter,
    branches,
    columns,
    options,
    allLoanBookings,
    details,
    guarantorForm,
    setGuarantorForm,
    loanLimit,
    onEditedScheduleSave,
    acctBal,
  };
}

import React, { useState, useEffect, useCallback } from "react";
import "./GlStatement.scss";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "../../../../DatagridTable/table";
import Details from "../../../../Details/LoanAccount/LoanFacilityLimit";
import DetailsContainer from "../../../../DatagridTable/details";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../../Backdrop";
import Confirm from "../../../../../Confirmbox/Confirm";

import { format } from "date-fns";
import useTable from "../../../../Tables/useTable";
import { Routes } from "../../../../../Api/configUrl";
import ErrorMessage from "../../../../ErrorMessage";

import {
  getGLAccount,
  getCustomerByAccountNumber,
} from "../../../../../Api/Hooks-manager/Transactions";

import {
  getAllAccountStatement,
  AllAccountStatement,
} from "../../../../../Api/Hooks-manager/Transactions/index";
import {
  isLoading,
  openViewModal,
  openDetailsModal,
} from "../../../../../Api/redux-manager/actions";
import { formattedDate } from "../../../../../formatter/date";
import _ from "lodash";


const GlStatement = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [details, setDetails] = useState({});
  const loading = useSelector((state) => state.req.loading);
  const suspenceAccount = useSelector((state) => state.common.branch);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const nowDate = format(new Date(suspenceAccount.workdate), "yyyy-MM-dd");
  const [errors, setErrors] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [customerBalance, setCustomerBalance] = useState({
    accountName: "",
    availableBalance: null,
    isValidAccNum: false,
  });
  const [glBalance, setGlBalance] = useState({
    accountName: "",
    availableBalance: null,
    isValidAccNum: false,
  });

  const columns = [
    // {
    //   name: "sn",
    //   label: "S/N",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "accountNo",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "referenceNumber",
      label: "Reference Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "narration",
      label: "Narration",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "crDr",
      label: "Transaction Type",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Credit"
                  ? "green"
                  : status === "Debit"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "transactionDate",
      label: "Transaction Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "debitAmount",
      label: "Debit Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditAmount",
      label: "Credit Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountBalance",
      label: "Running Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const [data, setData] = useState({});

  const [statementDetails, setStatementDetails] = useState({
    accountNumber: "",
    startDate: nowDate,
    endDate: nowDate,
  });

  const { accountNumber } = statementDetails;

  // const fetchCustomerBalance = useCallback(
  //   async (acctNum) => {
  //     dispatch(isLoading());
  //     const response = await getCustomerByAccountNumber(user.jwtToken, acctNum);
  //     console.log(response, "for customer account");
  //     if (response && response.data) {
  //       dispatch(isLoading());
  //       if (response.status) {
  //         if (response.data.authorizationStatus === "Authorized") {
  //           setCustomerBalance({
  //             ...customerBalance,
  //             accountName: response.data.accountName,
  //             availableBalance: response.data.accountBalance,
  //             isValidAccNum: true,
  //           });
  //         }
  //       }
  //     }
  //   },
  //   [token]
  // );

  const fetchGlBalance = useCallback(
    async (accNum) => {
      dispatch(isLoading());
      const response = await getGLAccount(user.jwtToken, accNum);
      console.log(response, "for GL account");
      if (response && response.data) {
        dispatch(isLoading());
        if (response.status) {
          if (response.data.authorizationStatus === "Authorized") {
            setGlBalance({
              ...glBalance,
              availableBalance: response.data.balance,
              accountName: response.data.accountName,
              isValidAccNum: true,
            });
          }
        }
      }
    },
    [token]
  );

  // const fetchAllAccountStatement = async () => {
  //   dispatch(isLoading());
  //   const response = await getAllAccountStatement(token, statementDetails);
  //   // console.log(response, "whhhhheere");
  //   if (response && response.data) {
  //     dispatch(isLoading());
  //     if (response.data.length > 0) {
  //       for (let i = 0; i < response.data.length; i++) {
  //         response.data[i].sn = i + 1;
  //         response.data[i].transactionDate = formattedDate(
  //           response.data[i].transactionDate
  //         );
  //       }
  //     }
  //     setData(response.data);
  //   }
  // };

  const fetchAnAccountStatement = useCallback(async () => {
    dispatch(isLoading());
    const allResponse = await AllAccountStatement(token, statementDetails);
    const response = allResponse.data.filter(
      (item) => 
        item.transactionStatus === "Authorized",
        
    );


    // console.log(response, "whhhhheere");
    if (response) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.length > 0) {
        // fetchCustomerBalance(accountNumber);
        fetchGlBalance(accountNumber);
        for (let i = 0; i < response.length; i++) {
          response[i].sn = i + 1;
          response[i].transactionDate = formattedDate(
            response[i].transactionDate
          );
        }
      }
      setData(_.orderBy(response, ['dateAuthorized'], ['desc']));
    }
  },[dispatch, token, accountNumber, statementDetails]);



  // console.log(customerBalance.accountName, "jjjjjjj");



  // useEffect(() => {
  //   if (statementDetails) {
  //     fetchAnAccountStatement(statementDetails);
  //     setShow(false);
  //   }
  // }, [statementDetails]);

  // useEffect(() => {
  //   if (accountNumber.length === 10){
  //     fetchCustomerBalance(accountNumber)
  //   }
  // }, [accountNumber])

  // useEffect(() => {
  //   if (accountNumber.length === 9){
  //     fetchGlBalance(accountNumber)
  //   }
  // }, [accountNumber])

  const handleChange = (e) => {
    setStatementDetails({
      ...statementDetails,
      [e.target.name]: e.target.value,
    });
  };

  const EmptyField = () => {
    setStatementDetails({
      accountNumber: "",
      startDate: nowDate,
      endDate: nowDate,
    });
    setErrors("");
  };

  const submitHandler = () => {
    if (statementDetails.accountNumber.length === 9) {
      fetchAnAccountStatement();
      setShowSearch(true);
      setErrors("");
      setShow(false);
    };
    if(statementDetails.accountNumber === ""){
      setErrors("Account No. is required");
      setShow(true);
    }else if (statementDetails.accountNumber.length !== 9){
      setErrors("Account No. must be 10 digits");
      setShow(true);
    }
  };


  const headCells = [];
  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const resetHandler = () => {
    EmptyField();
    setCustomerBalance({
      ...customerBalance,
      availableBalance: null,
      accountName: "",
    });
    setGlBalance({ ...glBalance, availableBalance: "" });
    setShowSearch(false);
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable([], headCells, filterFn);

  return (
    <div className="gl-statement">
      <Spinner loading={loading} />
      <div className="headerdiv">
        <p>GL Statement</p>
      </div>
      <Paper
        style={{
          marginTop: 20,
          marginBottom: 50,
          padding: 25,
          boxShadow: "2px 2px 2px 2px white-smoke",
        }}
      >
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3}>
              <label>Account Number</label>
              <br />
              <input
                type="text"
                className={`pink ${
                  errors.accountNumber ? "error-input-z" : null
                }`}
                name="accountNumber"
                onChange={handleChange}
                value={statementDetails.accountNumber}
              />{" "}
              <br />
              {show && <span style={{ color: "#D0021B" }}>{errors}</span>}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3}>
              <label>Credit Account</label>
              <br />
              <input type="text" name="creditAccount" onChange={handleChange} value={statementDetails.creditAccount}/>
            </Grid> */}
            <Grid item xs={12} sm={12} md={3}>
              <label>From Date</label>
              <br />
              <input
                type="date"
                name="startDate"
                onChange={handleChange}
                value={statementDetails.startDate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <label>To Date</label>
              <br />
              <input
                type="date"
                name="endDate"
                onChange={handleChange}
                value={statementDetails.endDate}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={3} className="accountStatementButtons">
            <button className="reset-btn" type="reset" onClick={resetHandler}>
              Reset
            </button>
            <button className="search-btn" onClick={submitHandler}>
              Search
            </button>
          </Grid>
        </>
      </Paper>

      {!showSearch ||
        (!loading && (
          <Table
          title={`Account Name: ${glBalance.accountName}`}

          subtitle={`Account Balance: ${glBalance.availableBalance}`}

            name=""
            records={data}
            options={options}
            columns={columns}
          />
        ))}

      <DetailsContainer title="Account Statement - Record Details">
        <Details details={details} />
      </DetailsContainer>
      {/* <div className="row-div">
        <div>
          <label>Account Number</label>
          <br />
          <input placeholder="0865434543" />
        </div>
        <div>
          <label> From</label>
          <br />
          <input type="date" />
        </div>
        <div>
          <label>To </label>
          <br />
          <input type="date" />
        </div>

        <button className="reset-btn" type="reset" onClick={resetHandler}>
          Reset
        </button>
        <button className="search-btn" onClick={submitHandler}>
          Search
        </button>
      </div> */}
      {showSearch && (
        <div>
          {/* <div className="row-div-2">
            <div>
              <label>Customer Name</label>
              <br />
              <input placeholder="Ojedeji Oluwatobi" />
            </div>
            <div className="row-div-21">
              <label>Customer Address</label>
              <br />
              <input placeholder="59, Akin Ogunmade close, Gbagada" />
            </div>
          </div> */}
          <div className="income--div">
            {/* <TblContainer>
                <TblHead />
                <thead className="table-fill">
                    <tr>
                        <th>Date </th>
                        <th>Description </th>
                        <th>Ref</th>
                        <th>Debit </th>
                        <th>Credit </th>
                        <th>Balance</th>

                    </tr>
                </thead>
                <tbody>
                      <tr>
                        <td>2020-07-01</td>
                        <td>Previous Balance</td>
                        <td>1129</td>
                        <td></td>
                        <td>300,000</td>
                        <td>1000,000</td>
                   
                    </tr>
                    </tbody>
                </TblContainer> */}
            {/* <button className="print-btn" type>
              Print
            </button> */}
          </div>
          {/* <TblPagination /> */}
        </div>
      )}
    </div>
  );
};
export default GlStatement;

import { useState, useEffect } from "react";
import "./Customerinfo.scss";
import useCustom2 from "../../../Api/Hooks-manager/coreModules/useCustom";
import Spinner from "../../../components/Backdrop/index";
import ErrorMessage from "../../../components/ErrorMessage";
import useCustom from "../../../Api/Hooks-manager/accountOpening/useCustom";

const Customerinfo = ({ handleChange, value, errors }) => {
  const { allbankparameters, getBankParametersAll, loader } = useCustom2();
  const [passportBase64, setPassportBase64] = useState("");
  const [signatureBase64, setSignatureBase64] = useState("");
  const { setData } = useCustom();
  const [error, setError] = useState("");
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);

  useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);

  // ID_Type
  const parametersIdType = allbankparameters.filter(
    (items) => items.parameterName === "ID_Type"
  );

  const handleChangeFile = (e, type) => {
    encodeBase64(e.target.files[0], type);
    console.log(e.target.files[0]);
    let MAX_SIZE = 500000;
    if (e.target.files[0].size < MAX_SIZE) {
      const fileType =
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/jpeg";
      console.log(fileType, "fileTypefileType");
      setShow(false);
      setShow2(false);
    } else if (e.target.files[0].size > MAX_SIZE && type === "passport") {
      errors.passport =
        "Maximum file size of 500kb exceeded. Please reduce image size";
      setShow(true);
    } else if (e.target.files[0].size > MAX_SIZE && type === "signature") {
      errors.signature =
        "Maximum file size of 500kb exceeded. Please reduce image size";
      setShow2(true);
    }

    // if(type === 'passport'){

    // }
  };


  // const handleChangeFile = (e, type) => {
  //   encodeBase64(e.target.files[0], type);
  //   console.log(e.target.files[0].size);
  //   const fileType =
  //       e.target.files[0].type === "image/png" ||
  //       e.target.files[0].type === "image/jpg" ||
  //       e.target.files[0].type === "image/jpeg";
  //     console.log(fileType, "fileTypefileType");

  //   // if(type === 'passport'){

  //   // }
  // };

  useEffect(() => {
    if (passportBase64) {
      value.passport = passportBase64;
    }
    passportBase64 &&
      setData((prevState) => ({ ...prevState, passport: passportBase64 }));
  }, [passportBase64]);

  useEffect(() => {
    signatureBase64 &&
      setData((prevState) => ({ ...prevState, signature: signatureBase64 }));
    if (signatureBase64) {
      value.signature = signatureBase64;
    }
  }, [signatureBase64]);

  const encodeBase64 = (file, type) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (type === "passport") {
          var Base64 = reader.result;
          setPassportBase64(Base64);
          return;
        } else {
          var Base64 = reader.result;
          setSignatureBase64(Base64);
        }
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };

  return (
    <div className="flex--1 customer--info">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader} />
      </div>
      {value.customerType === "Individual" && (
        <div className="flex--3">
          <label>Identification Type</label>
          <select
            value={value.identificationType}
            onChange={handleChange("identificationType")}
            className={`${
              Object.keys(errors).includes("identificationType") &&
              "error-input-z"
            }`}
          >
            <option>Select An Option</option>
            {parametersIdType.map((idtype, index) => {
              return (
                <option key={index} value={idtype.parameterValue}>
                  {idtype.parameterValue}
                </option>
              );
            })}
          </select>
          {errors.identificationType && (
            <ErrorMessage message={errors.identificationType} />
          )}
        </div>
      )}
      {value.customerType === "Individual" && (
        <div className="flex--3">
          <label>Identification Number</label>
          <input
            type="num"
            onChange={handleChange("identificationNumber")}
            value={value.identificationNumber}
            className={`${
              Object.keys(errors).includes("identificationNumber") &&
              "error-input-z"
            }`}
          />
          {errors.identificationNumber && (
            <ErrorMessage message={errors.identificationNumber} />
          )}
        </div>
      )}
      <div className="flex--3">
        <label>Passport Photo</label>
        <input
          type="file"
          onChange={(e) => handleChangeFile(e, "passport")}
          accept="image/*"
          className={
            error &&
            `input--file ${
              Object.keys(errors).includes("passport") && "error-input-z"
            }`
          }
        />
        {show && errors.passport && <ErrorMessage message={errors.passport} />}
      </div>
      <div className="flex--3 signature">
        <label>Account Mandates (Image,Signature and Mandates)</label>
        <input
          type="file"
          onChange={(e) => handleChangeFile(e, "signature")}
          accept="image/*"
          className={
            error &&
            `input--file ${
              Object.keys(errors).includes("signature") && "error-input-z"
            }`
          }
        />
        {show2 && errors.signature && (
          <ErrorMessage message={errors.signature} />
        )}
      </div>
      {value.customerType === "Individual" && (
        <div className="flex--3">
          <label>KYC</label>
          <select
            value={value.kyc}
            onChange={handleChange("kyc")}
            className={`${
              Object.keys(errors).includes("kyc") && "error-input-z"
            }`}
          >
            <option>Select An Option</option>
            <option value="Pending">Pending</option>
            <option value="Complete">Complete</option>
          </select>
          {errors.kyc && <ErrorMessage message={errors.kyc} />}
        </div>
      )}
      {value.customerType === "Individual" && (
        <div className="flex--3">
          <label>BVN</label>
          <input
            type="number"
            onChange={handleChange("bvn")}
            value={value.bvn}
            className={`${
              Object.keys(errors).includes("bvn") && "error-input-z"
            }`}
          />
          {errors.bvn && <ErrorMessage message={errors.bvn} />}
        </div>
      )}
      {(value.customerType !== "Individual" || value.customerType !== "") && (
        <div className="flex--3">
          <label>TIN</label>
          <input
            type="number"
            onChange={handleChange("tin")}
            value={value.tin}
            className={`${Object.keys(errors).includes('tin') && 'error-input-z'}`}
          />
          {errors.tin &&  
            <ErrorMessage message={errors.tin} />
          }
        </div>
      )}
    </div>
  );
};
export default Customerinfo;

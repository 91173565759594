import { React, useCallback, useState, useEffect } from "react";
import "./CorporateBorrower.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
// import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
// import ErrorMessage from "../../../../ErrorMessage";
import { getCbrReports } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { formattedDate } from "../../../../../formatter/date";
import { useDispatch } from "react-redux";
// import moment from "moment";
// import { format } from "date-fns";
import { isLoading } from "../../../../../Api/redux-manager/actions";

const CorporateBorrower = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  // const branch = useSelector((state) => state.common.branch.branch);
  // const applicationDate = format(new Date(branch.workdate), "yyyy-MM-dd");

  // const [data, setData] = useState({
  //   startDate: applicationDate,
  //   endDate: "",
  // });
  const [corporateBorrower, setCorporateBorrower] = useState(null);
  // const [errors, setErrors] = useState({});
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerid",
      label: "Customer id.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "rcnumber",
      label: "Rc No.",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "business_Name",
      label: "Business Name",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "business_Category",
      label: "Business Category",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "date_of_Incorporation",
      label: "Date Of Incorporation",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },

    {
      name: "branchcode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lga",
      label: "LGA",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "secondary_Address_Line1",
      label: "Secondary Address Line1",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "secondary_Address_Line2",
      label: "Secondary Address Line2",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "secondary_Address_CityLGA",
      label: "Secondary Address City LGA",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "secondary_Address_State",
      label: "Secondary Address State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "secondary_Address_Country",
      label: "Secondary Address Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "taxID",
      label: "Tax ID",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "secondary_Phone_Number",
      label: "Secondary Phone No.",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  // const handleChange = (name) => (e) => {
  //   const { value } = e.target;
  //   setErrors((prev) => ({
  //     ...prev,
  //     [name]: undefined,
  //   }));
  //   setData({
  //     ...data,
  //     [name]: value,
  //   });
  // };
  // const resetHandlers = () => {
  //   EmptyField();
  //   setCorporateBorrower(false);
  // };

  // const EmptyField = () => {
  //   setData({
  //     startDate: "",
  //     endDate: "",
  //   });
  // };

  // const handleSearch = () => {
  //   const checkErrors = validateData();
  //   if (checkErrors || loading) {
  //     fetchCorporateBorrowerReports();
  //     console.log("i am livid");
  //   }
  //   console.log("i am believer");
  // };

  // const validateData = () => {
  //   const error = {};
  //   let errorCount = 0;
  //   const { startDate, endDate } = data;
  //   if (startDate === "") {
  //     error.startDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }
  //   if (endDate === "") {
  //     error.endDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }

  //   setErrors(error);
  //   if (errorCount) {
  //     return false;
  //   }
  //   return true;
  // };
  const fetchCorporateBorrowerReports = useCallback(async () => {
    dispatch(isLoading());
    const response = await getCbrReports(token);
    if (response && response.data) {
      dispatch(isLoading(false));

      if (response && response.data.corporates.length > 0) {
        for (let i = 0; i < response.data.corporates.length; i++) {
          response.data.corporates[i].sn = i + 1;
        }
      }
      setCorporateBorrower(response.data.corporates);
    }
  }, [token, dispatch]);
  useEffect(() => {
    fetchCorporateBorrowerReports();
  }, [fetchCorporateBorrowerReports]);

  return (
    <div className="corporateborrower border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Corporate Borrower</p>
      </div>
      {/* <main className="section--1"> */}
      {/* <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="From"
              value={data.startDate}
              onChange={handleChange("startDate")}
            ></input>
            {errors.startDate && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input
              name="To"
              type="date"
              value={data.endDate}
              onChange={handleChange("endDate")}
            ></input>
            {errors.endDate && <ErrorMessage message={errors.endDate} />}
          </div>
        </div>

        <div className="button--1 flex--1">
          <button onClick={handleSearch}>Search</button>
          <button onClick={resetHandlers}>Reset</button>
        </div>
        <hr /> */}
      {corporateBorrower && (
        <div>
          {/* <Spinner loading={loading} /> */}
          <Table
            title="Corporate  Borrower"
            subtitle=""
            name="List Of Corporate Borrower"
            records={corporateBorrower}
            options={options}
            columns={columns}
          />
        </div>
      )}
      {/* </main> */}
    </div>
  );
};

export default CorporateBorrower;

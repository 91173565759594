import Item from "../../DatagridTable/item";
import { Grid } from '@material-ui/core';

const PortfolioMaintenance = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Portfolio Code" name={details.portfolioCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Portfolio Type" name={details.portfolioType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Customer Name" name={details.customerName} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Customer Branch" name={details.customerBranch} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Statement Frequency" name={details.statementFrequency} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Currency" name={details.currency} />
          </Grid>
          {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Reason" name={details.reason} />
          </Grid>}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>
    )
}

export default PortfolioMaintenance

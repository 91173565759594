// //  // Dev
// const baseUrl = "https://instiqtme.tk:885/api/";

// // // Nomase
// const baseUrl = "https://nomase.tk:885/api/";

// // // PPAI
// const baseUrl = "https://ppai.tk:885/api/";

const baseUrl = "https://tme.ppai.ng:885/api/";

// // // Uhuru
// const baseUrl = "https://uhuru-mfb.tk:885/api/";

//Client
//const baseUrl = "https://tme.nobleservecapitalng.com:885/api/";

// old dev
//const baseUrl = "https://nobleserve.cf:885/api/";

// new client url
// const baseUrl = "https://nomase.tk:885/api/";

const Routes = {
  // onBoarding Process
  login: baseUrl + "user/UserAccess/Authenticate",

  // -------------------------------------------places--------------------------------------------------------------------//

  getAllCountries: baseUrl + "user/Country/GetAll",
  getAllStates: baseUrl + "user/State/GetAllCountryStates/", // append {countryId}
  getAllStatesByCountryCode:
    baseUrl + "user/State/GetAllCountryStatesByCountryCode/",
  // append {countryCode}

  // --------------------------------------------institution-------------------------------------------------------------------//

  getInstitutionType: baseUrl + "organization/InstitutionType/GetAll",
  instittutionDefination: baseUrl + "organization/Institution/Create",
  getAllInstitutions: baseUrl + "organization/Institution/GetAll",
  getAllUnauthorizedInstitutions: baseUrl + "organization/Institution/GetUnauthorized",
  getAnInstitution: baseUrl + "organization/Institution/Get/", // apend id
  updateAnInstitution: baseUrl + "organization/Institution/Update/", // apend id
  deleteAnInstitution: baseUrl + "organization/Institution/Delete/", // append id
  authorizeInstitutionType: baseUrl + "organization/Institution/Authorize/",
  // append id

  // -----------------------------------------------branch----------------------------------------------------------------//

  branchDefinition: baseUrl + "organization/Branch/Create",
  getAllBranches: baseUrl + "organization/Branch/GetAll",
  getAllUnauthorizedBranches: baseUrl + "organization/Branch/GetUnauthorized",
  getABranch: baseUrl + "organization/Branch/Get/", // append id
  getBranchByCode: baseUrl + "organization/Branch/GetBranch/", // append branchcode
  editABranch: baseUrl + "organization/Branch/Update/", // append id
  deleteAbranch: baseUrl + "organization/Branch/Delete/", // append id
  authorizebranchDefinition: baseUrl + "organization/Branch/Authorize/",
  // append id

  // --------------------------------------------------fiscal year-------------------------------------------------------------//

  fiscalYear: baseUrl + "organization/FiscalYear/AddFiscalYear",
  getAllFiscalYear: baseUrl + "organization/FiscalYear/GetAll",
  getAFiscalYear: baseUrl + "organization/FiscalYear/Get/", // append id
  updateAFiscalYear: baseUrl + "organization/FiscalYear/Update/", // append id
  deleteFiscalYear: baseUrl + "organization/FiscalYear/Delete/", // apopend id
  authorizeFiscalYear: baseUrl + "organization/FiscalYear/Authorize/",
  // apopend id

  // ----------------------------------------bank parameters-----------------------------------------------------------------------//

  createBankParameter: baseUrl + "organization/BankParameters/Create",
  getBankParameterByName:
    baseUrl + "organization/BankParameters/ParametersByName/",
  // append comma separated names

  // --------------------------------------------rate chart-------------------------------------------------------------------//

  createRateChart: baseUrl + "organization/RateChart/Create",
  getAllRateChart: baseUrl + "organization/RateChart/GetAllWithRateChartRange",
  getARateChart: baseUrl + "organization/RateChart/Get/", // append id
  updateRateChart: baseUrl + "organization/RateChart/Update/", // append id
  deleteRateChart: baseUrl + "organization/RateChart/Delete/", // append id
  authorizeRateChart: baseUrl + "organization/RateChart/Authorize/",
  // append id

  // ------------------------------------------------customer category---------------------------------------------------------------//

  createCustomerCategory: baseUrl + "organization/CustomerCategory/create/bulk",
  getAllCustomerCategory: baseUrl + "organization/CustomerCategory/GetAll",
  getACustomerCategory: baseUrl + "organization/CustomerCategory/Get/", // append id
  updateCustomerCategory: baseUrl + "organization/CustomerCategory/Update/", // append id
  deleteCustomerCategory: baseUrl + "organization/CustomerCategory/Delete/", // append id
  authorizeCustomerCategory:
    baseUrl + "organization/CustomerCategory/Authorize/",
  // apopend id

  // ------------------------------------------------get all GlAccount---------------------------------------------------------------//

  getAllGlAccount: baseUrl + "account/GLAccount/GetAll",

  createGLAccount: baseUrl + "account/GLAccount/Create",

  // ------------------------------------------------get all currency definition---------------------------------------------------------------//

  getAllCurrencyDefinition: baseUrl + "organization/CurrencyDefinition/GetAll",

  // ------------------------------------------------loan products---------------------------------------------------------------//

  createLoanProducts: baseUrl + "loan/LoanProduct/Create",
  getAllLoanProducts: baseUrl + "loan/LoanProduct/GetAllwithDetails",
  getALoanProducts: baseUrl + "loan/LoanProduct/Get/", // append id
  updateLoanProducts: baseUrl + "loan/LoanProduct/Update/", // append id
  deleteLoanProducts: baseUrl + "loan/LoanProduct/Delete/", // append id
  authorizeLoanProducts: baseUrl + "loan/LoanProduct/Authorize/", // append id
  updateLoanProductCharges: baseUrl + "loan/LoanProductCharge/Update/", // append id
  updateOverDueDays: baseUrl + "loan/OverdueDays/Update/", // append id
  deleteAnOverdueDays: baseUrl + "/api/loan/OverdueDays/Delete/", // append id
  bulkAdditionOfOverdueDays: baseUrl + "loan/OverdueDays/BulkAdd",
  updateRepaymentOrder: baseUrl + "loan/RepaymentOrder/Update/", // append id
  updateCustomerRestriction: baseUrl + "loan/CustomerRestriction/Update/", // append id


  // ------------------------------------------------Teller product---------------------------------------------------------------//

  createTellerProductDefinition:
    baseUrl + "organization/TellerProductDefinition/Create",
  getAllTellerProductDefinitionWithCharges:
    baseUrl + "organization/TellerProductDefinition/GetAllWithCharges",
  getATellerProductDefinition:
    baseUrl + "organization/TellerProductDefinition/Get/", // append id
  updateTellerProductDefinition:
    baseUrl + "organization/TellerProductDefinition/Update/", // append id
  updateTellerProductCharges:
    baseUrl + "organization/TellerProductCharges/BulkEdit",
  updateATellerProductCharges:
    baseUrl + "organization/TellerProductCharges/update/", // append id
  deleteTellerProductDefinition:
    baseUrl + "organization/TellerProductDefinition/Delete/", // append id
  deleteTellerProductCharges:
    baseUrl + "organization/TellerProductCharges/Delete/", // append id
  authorizeProductDefinition:
    baseUrl + "organization/TellerProductDefinition/Authorize/",
  // append id

  // //--------------------------------------------------Manage Roles------------------------------------------------------------//
  // getAllRoles: baseUrl + "user/Role/GetAll",

  // --------------------------------------------------user Access------------------------------------------------------------//
  createUser: baseUrl + "user/UserAccess/CreateUser",
  getAllUsers:
    baseUrl + "user/UserAccess/GetAllUsers?appRole=Staff&offset=0&limit=20",
  updateUserAccess: baseUrl + "user/UserAccess/UpdateUser/",
  getAllUnauthorizedUser:
    baseUrl +
    "user/UserAccess/FetchStaffsPendingAuthorization?pageNumber=1&pageSize=20",
  editProfile: baseUrl + "user/UserAccess/EditProfile/", // append id
  deleteUser: baseUrl + "user/UserAccess/deleteUser/", // append id
  authorizeCreatedUser: baseUrl + "user/UserAccess/Authorize/",
  // append id

  // --------------------------------------------------Loan Repayment------------------------------------------------------------//
  createLoanRepayment: baseUrl + "loan/Repayment",
  getAllLoanRepayment: baseUrl + "loan/Repayment/GetAll",
  getAllUnauthorizedLoanRepayment: baseUrl + "loan/Repayment/GetUnAuthorized",
  authorizeLoanRepayment: baseUrl + "loan/Repayment/Authorize/", // id
  getALoanRepayment: baseUrl + "loan/Repayment/Get/", // append id
  getLoanRepaymentByLoanAccNum: baseUrl + "loan/Repayment/GetbyLoanAccount/",
  updateRepaymentSchedule: baseUrl + "loan/Repayment/UpdateLoanRepayment",
  // append loanAccno

  // ------------------------------------------------Loan disbursement---------------------------------------------------------------//

  createLoanDisbursement: baseUrl + "loan/Disbursement/Create",
  getAllLoanDisbursement: baseUrl + "loan/Disbursement/GetAll",
  getAllUnauthorizedLoanDisbursement:
    baseUrl + "loan/Disbursement/GetUnAuthorized",
  authorizeLoanDisbursement: baseUrl + "loan/Disbursement/Authorize/", // id
  updateLoanDisbursement: baseUrl + "loan/Disbursement/Update/", // append id
  getALoanDisbursement: baseUrl + "loan/Disbursement/Get/", // append id
  getLoanDisbursementLoanAccountNum:
    baseUrl + "loan/Disbursement/GetbyLoanAccount/",
  // append id

  // ------------------------------------------------Loan Limit Facility---------------------------------------------------------------//
  createLoanLimit: baseUrl + "loan/LoanLimit/Create",
  getAllLoanLoanLimit: baseUrl + "loan/LoanLimit/GetAll",
  getAllUnauthorizedLoanLimit: baseUrl + "loan/LoanLimit/GetUnAuthorized",
  authorizeLoanLimit: baseUrl + "loan/LoanLimit/Authorize/", // id
  updateLoanLimit: baseUrl + "loan/LoanLimit/Update/", // append id
  getALoanLimit: baseUrl + "loan/LoanLimit/Get/", // append id
  getLoanLimitByAccountNum: baseUrl + "loan/LoanLimit/FetchByAccount/",

  // --------------------------------------------------Loan Booking------------------------------------------------------------//

  createLoanBooking: baseUrl + "loan/LoanDetail/Create",
  getAllLoanBooking: baseUrl + "loan/LoanDetail/GetAll",
  authorizeLoanBooking: baseUrl + "loan/LoanDetail/Authorize/", // id
  updateLoanBooking: baseUrl + "loan/LoanDetail/Update/", // id
  deleteLoanBooking: baseUrl + "loan/LoanDetail/Delete/", // id
  getALoanBooking: baseUrl + "loan/LoanDetail/Get/", // append id
  getUnauthorizedLoans: baseUrl + "loan/LoanDetail/GetUnAuthorized",
  generateSampleSchedule: baseUrl + "loan/LoanDetail/GenerateSampleSchedule?", // append query param
  getLoanByLoanAccountNumber: baseUrl + "loan/LoanDetail/GetByAccount/", // append loan acc No.
  getLoanByCustomerAccountNumber:
    baseUrl + "loan/LoanDetail/GetByCustomerAccount/",
  // append cus acc No.

  // ------------------------------------------------Teller tills---------------------------------------------------------------//
  createTellerTill: baseUrl + "account/TellerTill/create",
  getAllTellerTill: baseUrl + "account/TellerTill/GetAll",
  getAllUnauthorizedTellerTill: baseUrl + "account/TellerTill/GetUnauthorized",
  getATellerTill: baseUrl + "account/TellerTill/Get/", // append id
  updateTellerTill: baseUrl + "account/TellerTill/Update/", // append id
  deleteTellerTill: baseUrl + "account/TellerTill/Delete/", // append id
  authorizeTellerTill: baseUrl + "account/TellerTill/Authorize/",
  getCurrentUserTill: baseUrl + "account/TellerTill/GetCurrentUserTill/", // append id
  getCurrentUserCashBalance:
    baseUrl + "account/TellerTill/GetCurrentUserCashBalance/", // append id
  // append id

  // ------------------------------------------------User Teller Tills ---------------------------------------------------------------//

  createUserTellerTill: baseUrl + "account/TellerTillLinkage/Create",
  getAllUserTellerTill: baseUrl + "account/TellerTillLinkage/GetAll",
  getAllUnauthorizedUserTellerTill:
    baseUrl + "account/TellerTillLinkage/GetUnAuthorized",
  getAUserTellerTill: baseUrl + "account/TellerTillLinkage/Get/", // append id
  updateUserTellerTill: baseUrl + "account/TellerTillLinkage/Update/", // append id
  deleteUserTellerTill: baseUrl + "account/TellerTillLinkage/Delete/", // append id
  authorizeUserTellerTill: baseUrl + "account/TellerTillLinkage/Authorize/", // append id

  getGlAccountByAccountNumber:
    baseUrl + "account/GLAccount/GetbyAccountNumber/",
  // append account number

  // ------------------------------------------------Manage Roles ---------------------------------------------------------------//

  // ------------------------------------------------Customer Account ---------------------------------------------------------------//

  getCustomerByAccountNumber:
    baseUrl + "account/CustomerAccount/AccountEnquiry/",
  freezeAccountByAccountNumber: baseUrl + "account/CustomerAccount/FreezeMgmt/",

  generateNewAccountForExistingCustomer: baseUrl + "account/CustomerAccount/AddProductFromCore",
  startContribution: baseUrl + "account/Contribution/StartFromCore",
  // append account number

  // ---------------------------------------fetch account statement  ---------------------------------------//

  getAccountStatement:
    baseUrl + "transaction/TransactionDetail/FetchTransactionSummary",

  getAnAccountStatement:
    baseUrl +
    "transaction/TransactionDetail/FetchAccountStatementByAccountNumber",
  
  generateAccountStatement: baseUrl + "transaction/TransactionDetail/GenerateAccountStatement",

  // ---------------------------------------------------denomination definition--------------------------------------------------------//
  createDenominationDefinition: baseUrl + "organization/DenomDefinition/Create",
  getAllDenominationDefinition: baseUrl + "organization/DenomDefinition/GetAll",
  getAllUnauthorizedDenominationDefinition:
    baseUrl + "organization/DenomDefinition/GetUnAuthorized",
  getADenominationDefinition: baseUrl + "organization/DenomDefinition/Get/", // append id
  getDenominationdefinitionByCurrency:
    baseUrl + "organization/DenomDefinition/DenominationDefinitionByCurrency",
  updateDenominationDefinition:
    baseUrl + "organization/DenomDefinition/Update/", // append id
  deleteDenominationDefinition:
    baseUrl + "organization/DenomDefinition/Delete/", // append id
  authorizeDenominationDefinition:
    baseUrl + "organization/DenomDefinition/Authorize/", // append id
  getGLAccount: baseUrl + "account/GLAccount/GetbyAccountNumber/",
  // append gl account number

  // ------------------------------------------------Transaction ---------------------------------------------------------------//

  debit: baseUrl + "transaction/TransactionDetail/Debit",
  fetchTransactionByAccountNumber:
    baseUrl +
    "transaction/TransactionDetail/FetchTransactionByAccountNumber?accountNumber=",
  reversal: baseUrl + "transaction/TransactionDetail/Reversal",
  authorizeTransaction: baseUrl + "transaction/TransactionDetail/Authorize",

  fetchTransactionByReferenceNumber:
    baseUrl +
    "transaction/TransactionDetail/FetchTransactionByReferenceNumber?referenceNumber=",
  handleBulkTransaction:
    baseUrl + "transaction/TransactionDetail/MultipleDebit",
  handleAutoBulkTransactionDebit:
    baseUrl + "transaction/TransactionDetail/autoDebit",

  // ------------------------------------------------Loan Reports ---------------------------------------------------------------//
  getLoanReport: baseUrl + "eod/Report/GetLoanMaturityReport/", // append date
  // ------------------------------------------------Accounts Opened Today Reports ---------------------------------------------------------------//
  getOpenedAccounts: baseUrl + "eod/Report/GetAccounts/", //Append date

  // ------------------------------------------------Investment Reports ---------------------------------------------------------------//

  getInvestmentReport: baseUrl + "eod/Report/GetInvestmentMaturyReport/",

  // ------------------------------------------------EOD PROCESS ---------------------------------------------------------------//
  getAllEodProcesses: baseUrl + "eod/EndOfDay/GetAllProcesses",
  getEndOfDayLogs: baseUrl + "eod/EndOfDay/GetEndOfDayLogs?processId=",

  // ------------------------------------------------Authorization---------------------------------------------------------------//
  getPendingBranchTransaction:
    baseUrl +
    "transaction/TransactionDetail/FetchPendingBranchAuthorization?pageNumber=",
  getPendingHeadOfficeTransaction:
    baseUrl +
    "transaction/TransactionDetail/FetchPendingHeadOfficeAuthorization?pageNumber=",
  authorizeRemoteTransaction: `${baseUrl}transaction/TransactionDetail/Authorize`,

  // ------------------------------------------------Run Eod Process---------------------------------------------------------------//
  runAllEodProcess: baseUrl + "eod/EndOfDay/RunProcess?processId=",

  // ------------------------------------------------Change Password---------------------------------------------------------------//
  resetPassword: baseUrl + "user/UserAccess/ChangePassword",

  // ------------------------------------------------Forgot Password---------------------------------------------------------------//

  forgotPassword: baseUrl + "user/UserAccess/ForgotPassword",

  // ------------------------------------------------Reset Password---------------------------------------------------------------//
  newPassword: baseUrl + "user/UserAccess/ResetPassword",

  // ------------------------------------------------User Verification---------------------------------------------------------------//
  verifyNewUser: baseUrl + "user/UserAccess/VerifyEmail",

  // ------------------------------------------------Account Enquiry---------------------------------------------------------------//
  updateCustomerInfo: baseUrl + "account/Customer/Update/",
  updateNextOfKin: baseUrl + "account/NextOfKin/Update/",
  updateAccounSignatories: baseUrl + "account/AccountSignatories/Update/",
  updateAccountInstructions: baseUrl + "account/CustomerAccount/Update/",

  // ---------------------------------------Getting All Account Users
  getAllAccountAndLoanOfficers:
    baseUrl + "user/UserAccess/FetchLoanorAccountOfficers/",

  // ---------------------------------------Deactivating & activating Users---------------------------------------//
  activateAndDectivateUser: baseUrl + "user/UserAccess/AccountStatus",

  // ---------------------------------------Deleting Roles---------------------------------------//
  deleteARole: baseUrl + "user/Role/Delete/",

  // ---------------------------------------Account Record Validation---------------------------------------//
  getAndValidateAllAccounts:
    baseUrl + "account/CustomerAccount/GetCustomerorGLAccounts",

  // --------------------------------------Passsportphotoupdate------------------------------------------//
  updatePassportPhoto: baseUrl + "account/Customer/ModifyPassportorSignature",

  downloadPassportPhoto: baseUrl + "organization/Files/DownLoadImage/",

  // ---------------------------------------get all permissions by role name---------------------------------------//
  getPermissionByRoleName: baseUrl + "user/Role/GetAllPermissions/", // append roleName

  // ---------------------------------------EOD Reports---------------------------------------//

  // getTrialBalanceReport: baseUrl + "eod/Report/TrialBalanceReport/", //Append Dates
  // getBalanceSheetReport: baseUrl + "eod/Reports/BalanceSheetReport/", //Append Dates
  getBalanceSheetReports: baseUrl + "Reports/Eod/GetBalanceSheet/", //Append Work Dates
  getBalanceHistory: baseUrl + "Reports/Eod/GetBalanceHistory/", //Append Work Dates
  getIncomeStatement: baseUrl + "Reports/Eod/GetIncomeStatement/", //Append Work Dates
  getTrialBalance: baseUrl + "Reports/Eod/GetTrialBalance/", //Append Work Dates
  getCashMovement: baseUrl + "Reports/Eod/GetCashMovement/", //Append Work Dates

  // ---------------------------------------get all glReports  ---------------------------------------//
  getGlReport: baseUrl + "eod/Report/GLReport/",

  // ---------------------------------------investmentLiquidation  ---------------------------------------//
  getUnauthtorizedLiquidations:
    baseUrl +
    "investment/InvestmentLiquidation/GetAllUnauthorizedInvestmentLiquidation",
  authorizeLiquidationById:
    baseUrl +
    "investment/InvestmentLiquidation/AuthorizeInvestmentLiquidation/", // Append id,
  rejectLiquidationById:
    baseUrl +
    "investment/InvestmentLiquidation/RejectAuthorizeInvestmentLiquidation/", // Append id,
  initiateInvestmentLiquidation:
    baseUrl + "investment/InvestmentLiquidation/InitiateInvestmentLiquidation",

  // ---------------------------------------get all Loan reports  ---------------------------------------//
  getAllReportLoan: baseUrl + "Reports/Loan/GetLoanDetailsRpt/", //Append {branchCode}/{Date}
  getDisbursementLoan: baseUrl + "Reports/Loan/GetDisbursedLoanRpt/", //Append {branchCode}/{Date}
  getAllLoanAccrualReport: baseUrl + "Reports/Loan/GetLoanAccrualRpt/", //Append {branchCode}
  getParReports: baseUrl + "Reports/Loan/GetLoanPortfolioAtRiskRpt/", //Append {branchCode}
  getParReportsExtended: baseUrl + "Reports/Loan/GetCRBPortfolioAtRiskRpt/", //Append {branchCode}
  getAllLoanRepaymentReport: baseUrl + "Reports/Loan/GetLoanRepaymentRpt/", //Append {branchCode}/{Date}
  getAllLoanRepaymentDue: baseUrl + "Reports/Loan/GetDueLoanRepaymentRpt/", //Append {branchCode}/{Date}

  // ---------------------------------------reports  ---------------------------------------//
  getTransctionSummaryReport:
    baseUrl + "transaction/TransactionDetail/FetchTransactionSummary",
  getDailyTransctionSummary:
    baseUrl + "transaction/TransactionDetail/FetchDailyTransactionSummary",

  // ---------------------------------------lien  ---------------------------------------//
  getAllLien: baseUrl + "account/Lien/GetAll",
  newLienAccount: baseUrl + "account/Lien/Create",
  updateLienAccount: baseUrl + "account/Lien/Update", //Append {id}
  deleteALien: baseUrl + "account/Lien/Delete", //Append id
  removeALien: baseUrl + "account/External/Lien/RemoveLien/", //Append id
  // ---------------------------------------get all Cbr Loan reports  ---------------------------------------//
  getAllCbrReports: baseUrl + "Reports/Loan/GetCRBRpts",

  // ---------------------------------------get referral bonus details  ---------------------------------------//
  getAllReferralListByCode: baseUrl + "account/Referral/ReferralListByCode",

  getTotalEarnedByReferralID: baseUrl + "account/Referral/TotalEarnedByReferralId",
};

export { Routes, baseUrl };

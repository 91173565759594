import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/Products/AccountProducts";

const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Account Products - Record Details"
      question="Are you sure you want to delete this products?"
      url={`${baseUrl}account/AccountProduct/Delete/${details.id}`}
    >
      <Details details={details} />
    </DetailsContainer>
  );
};

export default View;

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import "react-datepicker/dist/react-datepicker.css";
import 'react-calendar/dist/Calendar.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import loader from './assets/loaderio.svg';
import theme from './theme';
import { store, persistor } from './Api/redux-manager/store';

const Loading = <img src={loader} alt='' className='loader'/>

ReactDOM.render(
  <Router>  
      <ThemeProvider theme={theme}>
      <Provider store={store}>
      <PersistGate loading={Loading} persistor={persistor}>
        <App/>
      </PersistGate>
      </Provider>
      </ThemeProvider>
  </Router>,
  document.getElementById('Gbenga')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

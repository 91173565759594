import { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Box,
  Avatar,
} from "@material-ui/core";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { downloadUploadedPhoto } from "../../../Api/Hooks-manager/accountEnquiry/PassportModification";
import Snackbar from "../../../Confirmbox/Snackbar";
import { pasportphotoupdate } from "../../../Api/Hooks-manager/accountEnquiry/PassportModification";
import { isLoading } from "../../../Api/redux-manager/actions";
import Item from "../../DatagridTable/item";
import { formattedDate } from "../../../formatter/date";
import Tooltip from "../../../commons/tooltip";
import ReferralTable from "../../../components/Dashboard/CustomerAccountOperations/AccountEnquiry/referralTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
  },
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  signatories: {
    border: "2px solid #f7e7e9",
    marginBottom: "3rem",
    padding: "2rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: theme.palette.secondary.main,
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mandate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
  },
  notApplicable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
}));

export default function SimpleTabs({ details, showTooltip }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [value, setValue] = useState(0);
  const fileInputRef = useRef();
  const [image, setImage] = useState(null);
  const [sign, setSign] = useState(null);
  const [signatoryPassports, setSignatoryPassports] = useState({});
  const [signatorySignatures, setSignatorySignatures] = useState({});

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const numberWithCommas = (amountToFormat) => {
    return amountToFormat?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const LienDetails = details?.accountLiens;

  const extractLienAmount = LienDetails?.map((value) => value.amount);

  const totalLienAmount = extractLienAmount?.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const actualBalance = details?.accountBalance + totalLienAmount;

  console.log("actualBalance", numberWithCommas(actualBalance));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substring(0, 5) === "image") {
      setImage(file);
      setSign(file);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("newValue", newValue);
  };

  //   const editPassportPhoto = useCallback(async () => {
  //   dispatch(isLoading());
  //   const response = await pasportphotoupdate(token, details.id, preview);
  //   if (response && response.data) {
  //     dispatch(isLoading());
  //     setOpen(true);
  //     if (response.message === "Success") {
  //       setStatus("success");
  //       setMessage("Succesfully uploaded");
  //     } else {
  //       setStatus("error");
  //       setMessage("failed to upload");
  //     }
  //   }
  // }, [dispatch, token, details.id, preview]);

  const getImages = useCallback(
    async (passportFileName, signatureFileName) => {
      const passportResponse = await downloadUploadedPhoto(
        token,
        passportFileName
      );
      const signatureResponse = await downloadUploadedPhoto(
        token,
        signatureFileName
      );
      console.log("here is the signatory passport", passportResponse.data);
      console.log(
        "here we have the signature",
        passportResponse.data === signatureResponse.data
      );
      // console.log("comapring file names", passportFileName === signatureFileName);
      if (signatureResponse.status) {
        // setImage(response.data)
        setSign(`data:image/jpeg;base64,${signatureResponse.data}`);
      } else {
        setSign({ sign });
      }

      if (passportResponse.status) {
        // setImage(passportResponse.data)
        setImage(`data:image/jpeg;base64,${passportResponse.data}`);
        // setSignatorypassport(``data:image/jpeg;base64,${passportResponse.data}``)
      } else {
        setImage({ image });
      }
    },
    [token]
  );

  useEffect(() => {
    getImages(details.customer.passport, details.customer.signature);
  }, [details, getImages]);
  // useEffect(() => {
  //   if (image) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64String = reader.result;
  //       setPreview(base64String);
  //     };
  //     reader.readAsDataURL(image);
  //   } else {
  //     setPreview("");
  //   }
  // }, [image]);

  // useEffect(() => {
  //   if (preview) {
  //     editPassportPhoto();
  //   }
  // }, [preview, editPassportPhoto]);

  useEffect(() => {
    const images = {};
    const signatures = {};
    details.customer.accountSignatories.forEach(
      async ({ passport, signature }) => {
        if (passport) {
          const res = await downloadUploadedPhoto(token, passport);
          images[passport] = `data:image/jpeg;base64,${res.data}`;
          setSignatoryPassports(images);
        }
        if (signature) {
          const res = await downloadUploadedPhoto(token, signature);
          signatures[signature] = `data:image/jpeg;base64,${res.data}`;
        }
      }
    );
    setSignatoryPassports(images);
    setSignatorySignatures(signatures);
  }, []);

  let jointCoporateAvatar = null;
  if (details.customer.customerType === "Joint") {
    details.customer.accountSignatories.forEach((signatory) => {
      if (signatory.passport && !jointCoporateAvatar) {
        jointCoporateAvatar = signatory.passport;
      }
    });
  }

  console.log(jointCoporateAvatar);
  console.log(image);

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        handleClose={handleClose}
        status={status}
        message={message}
      />
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Customer Information" {...a11yProps(0)} />
          <Tab label="Account Information" {...a11yProps(1)} />
          <Tab label="Next of Kin" {...a11yProps(2)} />
          <Tab label="Account Signatories" {...a11yProps(3)} />
          <Tab label="Account Instruction" {...a11yProps(4)} />
          <Tab label="Referral Information" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.image}>
            {/* <Tooltip
              title={showTooltip ? "Add photo" : "Not permitted to upload" }
              show={showTooltip}
            >
              
            </Tooltip> */}
            {details.customer.customerType === "Joint" ? (
              jointCoporateAvatar && (
                <Avatar
                  // onClick={handleClick}
                  alt={
                    details.customer.firstName || details.customer.corporateName
                  }
                  src={signatoryPassports[jointCoporateAvatar]}
                  className={classes.large}
                />
              )
            ) : (
              <Avatar
                // onClick={handleClick}
                alt={
                  details.customer.firstName || details.customer.corporateName
                }
                src={image}
                className={classes.large}
              />
            )}
            <div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
                disabled={!showTooltip}
              />
            </div>
          </Grid>
          {details.customer.title && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Title" name={details.customer.title} />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Corporate Name"
              name={details.customer.corporateName || "Nil"}
            />
          </Grid>
          {details.customer.firstName && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="FirstName" name={details.customer.firstName} />
            </Grid>
          )}
          {details.customer.middleName && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="MiddleName" name={details.customer.middleName} />
            </Grid>
          )}
          {details.customer.lastName && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="LastName" name={details.customer.lastName} />
            </Grid>
          )}
          {details.customer.memberId && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Customer ID" name={details.customer.memberId} />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Customer Type" name={details.customer.customerType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Email" name={details.customer.email} />
          </Grid>
          {details.customer.phoneNumber && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Phone Number" name={details.customer.phoneNumber} />
            </Grid>
          )}
          {details.customer.gender && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Gender" name={details.customer.gender} />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Customer Category"
              name={details.customer.customerCategory}
            />
          </Grid>
          {details.customer.dateOfBirth && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label={
                  details.customer.customerType === "Corporate"
                    ? "Date of Incorporation"
                    : "Date of Birth"
                }
                name={
                  <Moment format="DD/MM/YYYY">
                    {details.customer.dateOfBirth}
                  </Moment>
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Address" name={details.customer.address} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="City" name={details.customer.city} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Country" name={details.customer.country} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="State" name={details.customer.state} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Local Govt" name={details.customer.lga} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Nationality" name={details.customer.nationality} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Identification Type"
              name={details.customer.identificationType || "Nil"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Identification Number"
              name={details.customer.identificationNumber || "Nil"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="KYC" name={details.customer.kyc} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="BVN" name={details.customer.bvn || "Nil"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Tin" name={details.customer.tin || "Nil"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Nin" name={details.customer.nin || "Nil"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="RC Number" name={details.customer.rcNumber || "Nil"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Create Date Time"
              name={formattedDate(details.createdDateTime)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Updated Date Time"
              name={formattedDate(details.updatedDateTime)}
            />
          </Grid>
          {details.dateAuthorized && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Date Authorized"
                name={formattedDate(details.dateAuthorized)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Status"
              name={
                details.authorizationStatus === "PendingEditedAuthorization"
                  ? "Pending Edited Authorization"
                  : details.authorizationStatus
              }
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Account Name" name={details.accountName} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Account Type" name={details.accountType || "Nil"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Account Status" name={details.accountstatus} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Account Number" name={details.accountNumber} />
          </Grid>
          {LienDetails.length > 0 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Actual Balance"
                name={numberWithCommas(actualBalance)}
              />
            </Grid>
          )}
          {LienDetails.length > 0 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Lien Amount"
                name={numberWithCommas(totalLienAmount)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Available Balance"
              name={numberWithCommas(details.accountBalance)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Account Officer"
              name={details.accountOfficer || "N/A"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Old Account Number"
              name={details.oldAccountNumber || "Nil"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Account Product Code"
              name={details.accountProductCode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Account Product Name"
              name={details.accountProduct.productName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Create Date Time"
              name={formattedDate(details.createdDateTime)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Updated Date Time"
              name={formattedDate(details.updatedDateTime)}
            />
          </Grid>
          {details.dateAuthorized && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Date Authorized"
                name={formattedDate(details.dateAuthorized)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Status"
              name={
                details.authorizationStatus === "PendingEditedAuthorization"
                  ? "Pending Edited Authorization"
                  : details.authorizationStatus
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <label>Signature/mandate</label>
            <br />
            <img
              src={sign}
              alt="mandate/signature"
              className={classes.mandate}
            />
          </Grid>
        </Grid>
      </TabPanel>
      {
        <TabPanel value={value} index={2}>
          {details.customer.nextOfKin && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="First Name"
                  name={details.customer.nextOfKin.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Middle Name"
                  name={details.customer.nextOfKin.middleName || "Nil"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Last Name"
                  name={details.customer.nextOfKin.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Qualification"
                  name={details.customer.nextOfKin.qualifications || "Nil"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Relationship"
                  name={details.customer.nextOfKin.relationship}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item label="Gender" name={details.customer.nextOfKin.gender} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Profession"
                  name={details.customer.nextOfKin.profession}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Address"
                  name={details.customer.nextOfKin.address}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Date of Birth"
                  name={
                    <Moment format="DD/MM/YYYY">
                      {details.customer.nextOfKin.dateOfBirth}
                    </Moment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Marital Status"
                  name={details.customer.nextOfKin.maritalStatus}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Nationality"
                  name={details.customer.nextOfKin.nationality}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Is Dependent?"
                  name={details.customer.nextOfKin.isDependent ? "Yes" : "No"}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Create Date Time"
                  name={formattedDate(
                    details.customer.nextOfKin.createdDateTime
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Updated Date Time"
                  name={formattedDate(
                    details.customer.nextOfKin.updatedDateTime
                  )}
                />
              </Grid>
              {details.customer.nextOfKin.dateAuthorized && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Date Authorized"
                    name={formattedDate(
                      details.customer.nextOfKin.dateAuthorized
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Status"
                  name={
                    details.authorizationStatus === "PendingEditedAuthorization"
                      ? "Pending Edited Authorization"
                      : details.authorizationStatus
                  }
                />
              </Grid>
            </Grid>
          )}
          {!details.customer.nextOfKin && (
            <div className={classes.notApplicable}>
              <h2>Not applicable</h2>
            </div>
          )}
        </TabPanel>
      }

      <TabPanel value={value} index={3}>
        {details.customer.accountSignatories.map((el, i) => (
          <Grid container spacing={2} className={classes.signatories} key={i}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Signatory"
                name={el.customerIsASignatory ? "Yes" : "No"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Title" name={el.title} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Last Name" name={el.lastname} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="First Name" name={el.firstname} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Middle Name" name={el.middlename || "Nil"} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Email" name={el.email} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Phone Number" name={el.phonenumber} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Gender" name={el.gender} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Address" name={el.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Country" name={el.country} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="State" name={el.state} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="KYC" name={el.kyc} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="BVN" name={el.bvn} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item label="Identification Type" name={el.identificationType} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Identification Number"
                name={el.identificationNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Create Date Time"
                name={formattedDate(details.createdDateTime)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Updated Date Time"
                name={formattedDate(details.updatedDateTime)}
              />
            </Grid>
            {details.dateAuthorized && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Item
                  label="Date Authorized"
                  name={formattedDate(details.dateAuthorized)}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item
                label="Status"
                name={
                  details.authorizationStatus === "PendingEditedAuthorization"
                    ? "Pending Edited Authorization"
                    : details.authorizationStatus
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              {el.passport && signatoryPassports[el.passport] && (
                <>
                  <label>Passport</label>
                  <br />
                  <img
                    src={signatoryPassports[el.passport]}
                    alt="passport"
                    className={classes.mandate}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              {el.signature && signatorySignatures[el.signature] && (
                <>
                  <label>Signature</label>
                  <br />
                  <img
                    src={signatorySignatures[el.signature]}
                    alt="signature"
                    className={classes.mandate}
                  />
                </>
              )}
            </Grid>
          </Grid>
        ))}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Account Instruction 1"
              name={details.accountInstruction1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Account Instruction 2"
              name={details.accountInstruction2 || "Nil"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Account Instruction 3"
              name={details.accountInstruction3 || "Nil"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Status"
              name={
                details.authorizationStatus === "PendingEditedAuthorization"
                  ? "Pending Edited Authorization"
                  : details.authorizationStatus
              }
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ReferralTable details={details} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}

import React from "react";
import "./Main.scss";
import { Switch, Route } from "react-router-dom";
import InstitutionDefinition from "./InstitutionDefinition/InstitutionDefinition";
import BranchDefinition from "./BranchDefinition/BranchDefinition";
import BankCalendar from "./BankCalendar/BankCalendar";
import BranchCalendar from "./BranchCalendar/BranchCalendar";
import ChartofAccount from "./ChartofAccount/ChartofAccount";
import Country from "./Countries";
import CurrencyDefintion from "./CurrencyDefintion/CurrencyDefintion";
import DenominationDefinition from "./DenominationDefinition/DenominationDefinition";
import CreatablePeriods from "./CreatablePeriods/CreateablePeriods";
import FiscalYear from "./FiscalYear/FiscalYear";
import ParameterSettings from "./ParameterSettings/ParameterSettings";
import CustomerCategory from "./CustomerCategory/CustomerCategory";
import RateChart from "./RateCharts/RateCharts";
import BankClearingCodes from "./BankClearingCodes/BankClearingCodes";

const Main = () => {
  return (
    <div className="main">
      <Switch>
        <Route
          exact
          path="/dashboard/coremodule/1"
          component={InstitutionDefinition}
        />
        <Route
          exact
          path="/dashboard/coremodule/branchdefinition"
          component={BranchDefinition}
        />
        <Route
          path="/dashboard/coremodule/bankcalendar"
          component={BankCalendar}
        />
        <Route
          path="/dashboard/coremodule/branchcalendar"
          component={BranchCalendar}
        />
        <Route
          path="/dashboard/coremodule/chartofaccount"
          component={ChartofAccount}
        />
        <Route
          path="/dashboard/coremodule/countdefinition"
          component={Country}
        />
        <Route
          path="/dashboard/coremodule/currencydefinition"
          component={CurrencyDefintion}
        />
        <Route
          path="/dashboard/coremodule/denominationdefinition"
          component={DenominationDefinition}
        />
        <Route
          path="/dashboard/coremodule/creatableperiods"
          component={CreatablePeriods}
        />
        <Route path="/dashboard/coremodule/fiscalyear" component={FiscalYear} />
        <Route
          path="/dashboard/coremodule/parametersettings"
          component={ParameterSettings}
        />
        <Route
          path="/dashboard/coremodule/parametersettings"
          component={ParameterSettings}
        />
        <Route
          path="/dashboard/coremodule/customercategory"
          component={CustomerCategory}
        />
        <Route path="/dashboard/coremodule/rateChart" component={RateChart} />
        <Route
          path="/dashboard/coremodule/bankclearingcodes"
          component={BankClearingCodes}
        />
      </Switch>
    </div>
  );
};

export default Main;

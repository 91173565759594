import axios from 'axios';
import { Routes as routes} from '../../configUrl';

export const getDailyTransactionSummary = async (token, {userId, branchCode}) => {

    return await axios({
        method: "get",
        url: routes.getDailyTransctionSummary,
        headers: {
            "Content-Type":  "application/json",
            'Authorization': `Bearer: ${token}`,
        },
        params: { branchCode, userId }
    })
    .then((res) => {
        return {
            data: res.data.data
        };

    })
    .catch((err) => {
        return {
            data:[],
        };
    });
};

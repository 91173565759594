import './Main.scss'
import { Switch, Route } from 'react-router-dom'
import DealPosting from './DealPosting/DealPosting'
import PortfolioMaintenance from './PortfolioMaintenance/PortfolioMaintenance'
import ProductDefinition from './ProductDefinition/ProductDefinition'
import Report from './Report/Report'

const Main = () => {
  return (
    <div>
      <div className='Main'>
        <Switch>
          <Route
            path='/dashboard/authorizetreasury/productdefinition'
            component={ProductDefinition}
          />
          <Route
            path='/dashboard/authorizetreasury/portfoliomaintenance'
            component={PortfolioMaintenance}
          />
          <Route
            path='/dashboard/authorizetreasury/dealposting'
            component={DealPosting}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Main

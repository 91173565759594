import React from 'react'

const Loanamendment = () => {
  return (
    <div>
      loanamendment
    </div>
  )
}

export default Loanamendment

import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "./InvestmentProducts.scss";
import MenuItem from "@material-ui/core/MenuItem";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { baseUrl } from "../../../../Api/configUrl";

import useCustom from "../../../../Api/Hooks-manager/products/useCustom";
import {
  validateInvestMentProductPage1,
  validateInvestMentProductPage2,
} from "../../../../Api/validator/validate11";
import NumberFormat from "react-number-format";
import Confirm from "../../../../Confirmbox/Confirm";
import Update from "../../../../Confirmbox/Confirm";
import DeleteModal from "../../../../Confirmbox/Confirm";
import Authorize from "../../../../Confirmbox/Confirm";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import { Routes } from "../../../../Api/configUrl";
import axios from "axios";
import SearchDropdown from "../../../SearchableDropdown/Search";
import useCustom2 from "../../../../Api/Hooks-manager/coreModules/useCustom";
import useCustom3 from "../../../../Api/Hooks-manager/coreModules/useCustom";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import EditForm from "./edit";
import Details from "./view";

const InvestmentProduct = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const {
    EmptyInvestmentProduct,
    updateInvestmentProduct,
    getInvestmentProductsAll,
    handleInvestmentProductAuthorize,
    deleteInvestmentProduct,
    errMessage,
    handleInvestmentProductCreate,
    setValuesInvestProduct,
    setOpenBoxUpdate,
    valuesInvestProduct,
    handleChange,
  } = useCustom();
  const { allGlAccount } = useCustom2();
  const [screenChange, setScreenChange] = useState(true);
  const [errorPage1, setErrorPage1] = useState({});
  const [errorPage2, setErrorPage2] = useState({});
  const [openBox, setOpenBox] = useState(false);
  const [openAuthorize, setOpenAuthorize] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [allRates, setAllRates] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const {
    allCurrencyDefinition,
    getCurrencyDefinitionAll,
    loader: loaders,
  } = useCustom3();

  const fetchAllInvestmentProducts = async () => {
    dispatch(isLoading());
    const url = `${baseUrl}investment/InvestmentProduct/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  };

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productCode",
      label: "Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productAccount",
      label: "Product Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "rateBasis",
      label: "Rate Basis",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tenorBasis",
      label: "Tenor Basis",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const getAllRateChart = async (token) => {
    return await axios({
      method: "get",
      url: Routes.getAllRateChart,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };

  const allRateChart = allRates.map((item) => {
    return {
      value: item.code,
      label: item.code,
    };
  });

  // Destructuring the value from the mapped item
  const filterGlAccount = allGlAccount.filter(
    (items) =>
      items.glHierarchy === "Child" &&
      items.authorizationStatus === "Authorized"
  );

  const glAccountNumber = filterGlAccount.map((item) => {
    return {
      value: item.accountNumber,
      label: `${item.accountNumber} -- ${item.accountName}`,
    };
  });

  const handleChangeSelect = (value, type) => {
    if (type === "rateChatCode") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        rateChatCode: value,
      }));
    } else if (type === "productCode") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        productCode: value,
      }));
    } else if (type === "productAccount") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        productAccount: value,
      }));
    } else if (type === "interestPayableAccount") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        interestPayableAccount: value,
      }));
    } else if (type === "penalIncome") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        penalIncome: value,
      }));
    } else if (type === "interestExpense") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        interestExpense: value,
      }));
    } else if (type === "unclaimedInterestAccount") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        unclaimedInterestAccount: value,
      }));
    } else if (type === "withHoldingTaxAccount") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        withHoldingTaxAccount: value,
      }));
    } else if (type === "unClaimedInvestmentAccount") {
      setValuesInvestProduct((valuesInvestProduct) => ({
        ...valuesInvestProduct,
        unClaimedInvestmentAccount: value,
      }));
    }
  };

  const handleEditChangeSelect = (value, type) => {
    setDetails((prev) => ({ ...prev, [type]: value }));
  };

  const handleNextPage = () => {
    setErrorPage1(validateInvestMentProductPage1(valuesInvestProduct));
    if (
      Object.keys(validateInvestMentProductPage1(valuesInvestProduct)).length >
      0
    )
      return;
    setScreenChange(false);
  };

  const handleConfirm = () => {
    setErrorPage2(validateInvestMentProductPage2(valuesInvestProduct));
    if (
      Object.keys(validateInvestMentProductPage2(valuesInvestProduct)).length >
      0
    ) {
      return;
    }
    setOpenBox(true);
  };

  const handleFieldUpdate = (e) => {
    const { name, value, checked } = e.target;
    setDetails({ ...details, [name]: value });
    if (
      name === "defaultInvAmount" ||
      name === "minimumInvAmount" ||
      name === "maximumInvAmount" ||
      name === "maximumTenor" ||
      name === "minimumTenor" ||
      name === "defaultInterestRate" ||
      name === "maximumInterestRate" ||
      name === "minimumInterestRate"
    ) {
      const inputedVal =
        value === "" ? "" : Number(value) < 0 ? 0 : Number(value);
      setDetails({
        ...details,
        [name]: inputedVal,
      });
    }
    if (
      name === "productStatus" ||
      name === "accrualRequired" ||
      name === "prematureLiqAllowed" ||
      name === "automaticRollover"
    ) {
      setDetails({ ...details, [name]: checked });
    }
  };

  console.log(valuesInvestProduct);

  const handleEditSubmit = () => {
    dispatch(openResquestDecisionModal());
  };

  useEffect(() => {
    fetchAllInvestmentProducts();
  }, [updateTable]);

  useEffect(() => {
    getInvestmentProductsAll();
  }, [getInvestmentProductsAll]);

  useEffect(() => {
    const fetchAllRatesCharts = async () => {
      const response = await getAllRateChart(token);
      if (response && response.data) {
        setAllRates(response.data);
      }
    };
    fetchAllRatesCharts();
  }, [token]);

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, [getCurrencyDefinitionAll]);

  return (
    <div className="investment--product border--main">
      <Spinner loading={loading || loaders} />
      <Confirm
        ApiCall={() =>
          handleInvestmentProductCreate(fetchAllInvestmentProducts)
        }
        openBox={openBox}
        setOpenBoxState={setOpenBox}
      />
      <DeleteModal
        ApiCall={deleteInvestmentProduct}
        message="Are you sure you want to delete this record?"
        openBox={openDelete}
        setOpenBoxState={setOpenDelete}
        actionMsg="Your record was deleted successfully"
        errorMessage={errMessage}
        j
      />
      <Authorize
        ApiCall={handleInvestmentProductAuthorize}
        openBox={openAuthorize}
        message="Are you sure you want to authorize this record?"
        actionMsg="Your records were authorized successfully"
        setOpenBoxState={setOpenAuthorize}
      />
      <Update
        ApiCall={updateInvestmentProduct}
        openBox={openUpdate}
        message="Are you sure you want to update this record?"
        actionMsg="Your records were updated successfully"
        setOpenBoxState={setOpenUpdate}
        errorMessage={errMessage}
      />
      <div className="headerdiv">
        <p>Products | Investment Products </p>
      </div>
      {screenChange ? (
        <div style={{ marginTop: 10, padding: 25 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <label>Product Code</label>
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="productCode"
                value={valuesInvestProduct.productCode}
                className={`${
                  Object.keys(errorPage1).includes("productCode") &&
                  "error-input-z"
                }`}
                type="text"
                maxLength="6"
              />
              {errorPage1.productCode && (
                <ErrorMessage message={errorPage1.productCode} />
              )}
              <div className="checboxdiv">
                <input
                  onChange={() => handleFieldUpdate("status")}
                  name="productStatus"
                  value={valuesInvestProduct.productStatus}
                  className={`productcheckbox ${
                    Object.keys(errorPage1).includes("productStatus") &&
                    "error-input-z"
                  }`}
                  type="checkbox"
                  checked={!valuesInvestProduct.productStatus}
                />
                <label className="span">Deactivate Product</label>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Product Name</label>
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="productName"
                value={valuesInvestProduct.productName}
                className={`${
                  Object.keys(errorPage1).includes("productName") &&
                  "error-input-z"
                }`}
                type="text"
              />
              {errorPage1.productName && (
                <ErrorMessage message={errorPage1.productName} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Currency</label>
              <select
                name="currency"
                value={valuesInvestProduct.currency}
                onChange={(e) => handleChange(e, "invest")}
                className={`${
                  Object.keys(errorPage1).includes("currency") &&
                  "error-input-z"
                }`}
              >
                <option value="">Select An Option</option>
                {allCurrencyDefinition.map((item) => (
                  <option value={item.currencyCode} key={item.id}>
                    {item.currencyName}
                  </option>
                ))}
              </select>
              {errorPage1.currency && (
                <ErrorMessage message={errorPage1.currency} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Default Inv. Amount</label>
              <NumberFormat
                thousandSeparator={true}
                inputMode="numeric"
                renderText={(value) => <div>{value}</div>}
                className={`${
                  Object.keys(errorPage1).includes("defaultInvAmount") &&
                  "error-input-z"
                }`}
                onValueChange={(values) => {
                  setValuesInvestProduct((valuesInvestProduct) => ({
                    ...valuesInvestProduct,
                    defaultInvAmount: parseInt(values.value),
                  }));
                }}
              />
              {errorPage1.defaultInvAmount && (
                <ErrorMessage message={errorPage1.defaultInvAmount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Minimum Inv. Amount</label>
              <NumberFormat
                thousandSeparator={true}
                inputMode="numeric"
                renderText={(value) => <div>{value}</div>}
                className={`${
                  Object.keys(errorPage1).includes("minimumInvAmount") &&
                  "error-input-z"
                }`}
                onValueChange={(values) => {
                  setValuesInvestProduct((valuesInvestProduct) => ({
                    ...valuesInvestProduct,
                    minimumInvAmount: parseInt(values.value),
                  }));
                }}
              />
              {errorPage1.minimumInvAmount && (
                <ErrorMessage message={errorPage1.minimumInvAmount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Maximum Inv. Amount</label>
              <NumberFormat
                thousandSeparator={true}
                inputMode="numeric"
                renderText={(value) => <div>{value}</div>}
                className={`${
                  Object.keys(errorPage1).includes("maximumInvAmount") &&
                  "error-input-z"
                }`}
                onValueChange={(values) => {
                  setValuesInvestProduct((valuesInvestProduct) => ({
                    ...valuesInvestProduct,
                    maximumInvAmount: parseInt(values.value),
                  }));
                }}
              />
              {errorPage1.maximumInvAmount && (
                <ErrorMessage message={errorPage1.maximumInvAmount} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {" "}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Default Tenor</label>
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="defaultTenor"
                value={valuesInvestProduct.defaultTenor}
                className={`${
                  Object.keys(errorPage1).includes("defaultTenor") &&
                  "error-input-z"
                }`}
                type="number"
              />
              {errorPage1.defaultTenor && (
                <ErrorMessage message={errorPage1.defaultTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Minimum Tenor</label>
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="minimumTenor"
                value={valuesInvestProduct.minimumTenor}
                className={`${
                  Object.keys(errorPage1).includes("minimumTenor") &&
                  "error-input-z"
                }`}
                type="number"
              />
              {errorPage1.minimumTenor && (
                <ErrorMessage message={errorPage1.minimumTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Maximum Tenor</label>
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="maximumTenor"
                value={valuesInvestProduct.maximumTenor}
                className={`${
                  Object.keys(errorPage1).includes("maximumTenor") &&
                  "error-input-z"
                }`}
                type="number"
              />
              {errorPage1.maximumTenor && (
                <ErrorMessage message={errorPage1.maximumTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Tenor Basis</label>
              <select
                name="tenorBasis"
                value={valuesInvestProduct.tenorBasis}
                onChange={(e) => handleChange(e, "invest")}
                className={`${
                  Object.keys(errorPage1).includes("tenorBasis") &&
                  "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                <option value="Days">Days</option>
                <option value="Months">Months</option>
                <option value="Years">Years</option>
              </select>
              {errorPage1.tenorBasis && (
                <ErrorMessage message={errorPage1.tenorBasis} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Rate Type</label>
              <select
                value={valuesInvestProduct.rateType}
                name="rateType"
                onChange={(e) => handleChange(e, "invest")}
                disabled
                className={`${
                  Object.keys(errorPage1).includes("rateType") &&
                  "error-input-z"
                }`}
              >
                {/* <option value="">Select An Option</option> */}
                <option value="Flat">Fixed</option>
                {/* <option value="RateCharts">Rate Chart</option> */}
              </select>
              {errorPage1.rateType && (
                <ErrorMessage message={errorPage1.rateType} />
              )}
            </Grid>
            {/* {valuesInvestProduct.rateType !== "Flat" && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Rate Chart Code</label>
                  <div
                    className={`border--norm ${
                      Object.keys(errorPage1).includes("rateChatCode") &&
                      "error-input-z"
                    }`}
                  >
                    <SearchDropdown
                      options={allRateChart}
                      onChange={handleChangeSelect}
                      type="rateChatCode"
                    />
                  </div>
                  {errorPage1.rateChatCode && (
                    <ErrorMessage message={errorPage1.rateChatCode} />
                  )}
                </Grid>
              </>
            )} */}
            {valuesInvestProduct.rateType !== "RateCharts" && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Default Interest Rate</label>
                  <input
                    onChange={(e) => handleChange(e, "invest")}
                    name="defaultInterestRate"
                    step="any"
                    value={valuesInvestProduct.defaultInterestRate}
                    // disabled={!valuesInvestProduct.rateType}
                    className={`${
                      Object.keys(errorPage1).includes("defaultInterestRate") &&
                      "error-input-z"
                    }`}
                    type="number"
                  />
                  {errorPage1.defaultInterestRate && (
                    <ErrorMessage message={errorPage1.defaultInterestRate} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Minimum Interest Rate</label>
                  <input
                    onChange={(e) => handleChange(e, "invest")}
                    name="minimumInterestRate"
                    step="any"
                    // disabled={!valuesInvestProduct.rateType}
                    value={valuesInvestProduct.minimumInterestRate}
                    className={`${
                      Object.keys(errorPage1).includes("minimumInterestRate") &&
                      "error-input-z"
                    }`}
                    type="number"
                  />
                  {errorPage1.minimumInterestRate && (
                    <ErrorMessage message={errorPage1.minimumInterestRate} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Maximum Interest Rate</label>
                  <input
                    onChange={(e) => handleChange(e, "invest")}
                    name="maximumInterestRate"
                    step="any"
                    // disabled={!valuesInvestProduct.rateType}
                    value={valuesInvestProduct.maximumInterestRate}
                    className={`${
                      Object.keys(errorPage1).includes("maximumInterestRate") &&
                      "error-input-z"
                    }`}
                    type="number"
                  />
                  {errorPage1.maximumInterestRate && (
                    <ErrorMessage message={errorPage1.maximumInterestRate} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Rate Basis</label>
                  <select
                    // disabled={!valuesInvestProduct.rateType}
                    value={valuesInvestProduct.rateBasis}
                    name="rateBasis"
                    onChange={(e) => handleChange(e, "invest")}
                    className={`${
                      Object.keys(errorPage1).includes("rateBasis") &&
                      "error-input-z"
                    }`}
                  >
                    <option>Select An Option</option>
                    <option value="PerAnnum">Per Annum</option>
                    <option value="PerMonth">Per Month</option>
                  </select>
                  {errorPage1.rateBasis && (
                    <ErrorMessage message={errorPage1.rateBasis} />
                  )}
                </Grid>
              </>
            )}
            {/* {valuesInvestProduct.rateType !== "Flat" && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Minimum Variance</label>
                  <input
                    onChange={(e) => handleChange(e, "invest")}
                    disabled={!valuesInvestProduct.rateType}
                    name="minimumVariance"
                    value={valuesInvestProduct.minimumVariance}
                    className={`${
                      Object.keys(errorPage1).includes("minimumVariance") &&
                      "error-input-z"
                    }`}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Maximum Variance</label>
                  <input
                    onChange={(e) => handleChange(e, "invest")}
                    disabled={!valuesInvestProduct.rateType}
                    name="maximumVariance"
                    value={valuesInvestProduct.maximumVariance}
                    className={`${
                      Object.keys(errorPage1).includes("maximumVariance") &&
                      "error-input-z"
                    }`}
                    type="number"
                  />
                  {errorPage1.maximumVariance && (
                    <ErrorMessage message={errorPage1.maximumVariance} />
                  )}
                </Grid>
              </>
            )} */}
          </Grid>
          <div className="flex--1 button--cont">
            <button className="next" onClick={handleNextPage}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <main className="next-page--1">
          <section className="flex--1 section--1">
            <div className="flex--2">
              <input
                onChange={(e) => handleFieldUpdate(e, "accrual")}
                name="accrualRequired"
                id="accrual"
                value={valuesInvestProduct.accrualRequired}
                className={`${
                  Object.keys(errorPage2).includes("accrualRequired") &&
                  "error-input-z"
                }`}
                type="checkbox"
                // checked={valuesInvestProduct.accrualRequired}
              />
              <label htmlFor="accrual">Accrual Required</label>
            </div>
            <div className="flex--2">
              <input
                onChange={(e) => handleFieldUpdate(e, "prematureLiqAllowed")}
                name="prematureLiqAllowed"
                value={valuesInvestProduct.prematureLiqAllowed}
                id="prematureLiqAllowed"
                className={`${
                  Object.keys(errorPage2).includes("prematureLiqAllowed") &&
                  "error-input-z"
                }`}
                type="checkbox"
                // checked={valuesInvestProduct.prematureLiqAllowed}
              />
              <label htmlFor="prematureLiqAllowed">Premature Liq Allowed</label>
            </div>
            <div className="flex--2">
              <input
                onChange={(e) => handleFieldUpdate(e, "invest")}
                id="automaticRollover"
                name="automaticRollover"
                value={valuesInvestProduct.automaticRollover}
                className={`${
                  Object.keys(errorPage2).includes("automaticRollover") &&
                  "error-input-z"
                }`}
                type="checkbox"
              />
              <label htmlFor="automaticRollover">Automatic Rollover</label>
            </div>
            <div className="flex--2">
              <input
                onChange={(e) => handleFieldUpdate(e, "invest")}
                name="applyTax"
                id="applyTax"
                value={valuesInvestProduct.applyTax}
                className={`${
                  Object.keys(errorPage2).includes("applyTax") &&
                  "error-input-z"
                }`}
                type="checkbox"
              />
              <label htmlFor="applyTax">Apply Tax</label>
            </div>
            <div className="flex--2">
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="penaltyOnPrematureLiq"
                id="penaltyOnPrematureLiq"
                value={valuesInvestProduct.penaltyOnPrematureLiq}
                className={`${
                  Object.keys(errorPage2).includes("penaltyOnPrematureLiq") &&
                  "error-input-z"
                }`}
                type="checkbox"
              />
              <label htmlFor="penaltyOnPrematureLiq">
                Penalty On Premature Liq
              </label>
            </div>
          </section>

          <section className="grid--1 section--2">
            <div className="flex--3">
              <label>Accrual Period</label>
              <select
                className={`${
                  Object.keys(errorPage2).includes("accrualPeriod") &&
                  "error-input-z"
                }`}
                name="accrualPeriod"
                onChange={(e) => handleChange(e, "invest")}
              >
                <option>Select An Option</option>
                <option value="Daily">Daily</option>
                <option value="OnMaturity">On Maturity</option>
              </select>
              {errorPage2.accrualPeriod && (
                <ErrorMessage message={errorPage2.accrualPeriod} />
              )}
            </div>
            <div className="flex--3">
              <label>Product Account</label>
              {filterGlAccount.length === 0 && (
                <select>
                  <option value="">Select, An Option</option>
                  <option value="">No Authorized Record Found</option>
                </select>
              )}
              {filterGlAccount.length > 0 && (
                <>
                  <div
                    className={`border--norm ${
                      Object.keys(errorPage1).includes("productAccount") &&
                      "error-input-z"
                    }`}
                  >
                    <SearchDropdown
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type="productAccount"
                    />
                  </div>
                  {errorPage2.productAccount && (
                    <ErrorMessage message={errorPage2.productAccount} />
                  )}
                </>
              )}
            </div>
            <div className="flex--3">
              <label>Interest Payable Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorPage1).includes("interestPayableAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestPayableAccount"
                />
              </div>
              {errorPage2.interestPayableAccount && (
                <ErrorMessage message={errorPage2.interestPayableAccount} />
              )}
            </div>
            <div className="flex--3">
              <label>Balance Basis</label>
              <select
                value={valuesInvestProduct.balanceBasis}
                className={`${
                  Object.keys(errorPage2).includes("balanceBasis") &&
                  "error-input-z"
                }`}
                name="balanceBasis"
                onChange={(e) => handleChange(e, "invest")}
              >
                <option value="">Select An Option</option>
                <option value="1">Daily Balance</option>
              </select>
              {errorPage2.balanceBasis && (
                <ErrorMessage message={errorPage2.balanceBasis} />
              )}
            </div>
            <div className="flex--3">
              <label>Penal Income</label>
              <div
                className={`border--norm ${
                  Object.keys(errorPage1).includes("penalIncome") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="penalIncome"
                />
              </div>
              {errorPage2.penalIncome && (
                <ErrorMessage message={errorPage2.penalIncome} />
              )}
            </div>
            <div className="flex--3">
              <label>Pre Liquidation penalty rate</label>
              <div
                // className={`border--norm ${
                //   Object.keys(errorPage1).includes("penOnPrematureLiq") &&
                //   "error-input-z"
                // }`}
              >
                <input
                onChange={(e) => handleChange(e, "invest")}
                name="preLiquidationPenaltyRate"
                value={valuesInvestProduct.preLiquidationPenaltyRate}
                className={`${
                  Object.keys(errorPage2).includes(
                    "preLiquidationPenaltyRate"
                  ) && "error-input-z"
                }`}
                type="number"
              />
              </div>
              {errorPage2.preLiquidationPenaltyRate && (
                <ErrorMessage message={errorPage2.preLiquidationPenaltyRate} />
              )}
            </div>
            <div className="flex--3">
              <label>Interest Expense</label>
              <div
                className={`border--norm ${
                  Object.keys(errorPage1).includes("interestExpense") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="interestExpense"
                />
              </div>
              {errorPage2.interestExpense && (
                <ErrorMessage message={errorPage2.interestExpense} />
              )}
            </div>
            <div className="flex--3">
              <label>At Maturity</label>
              <select
                className={`${
                  Object.keys(errorPage2).includes("atMaturity") &&
                  "error-input-z"
                }`}
                name="atMaturity"
                onChange={(e) => handleChange(e, "invest")}
              >
                <option>Select An Option</option>
                <option value="Liquidate">Liquidate</option>
                <option value="MoveToSuspense">Move to Suspense</option>
              </select>
              {errorPage2.atMaturity && (
                <ErrorMessage message={errorPage2.atMaturity} />
              )}
            </div>
            <div className="flex--3">
              <label>Unclaimed Interest Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorPage1).includes(
                    "unclaimedInterestAccount"
                  ) && "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="unclaimedInterestAccount"
                />
              </div>
              {errorPage2.unclaimedInterestAccount && (
                <ErrorMessage message={errorPage2.unclaimedInterestAccount} />
              )}
            </div>
            <div className="flex--3">
              <label>Withholding Tax Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorPage1).includes("withHoldingTaxAccount") &&
                  "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="withHoldingTaxAccount"
                />
              </div>
              {errorPage2.withHoldingTaxAccount && (
                <ErrorMessage message={errorPage2.withHoldingTaxAccount} />
              )}
            </div>
            <div className="label--input flex--3">
              <label>VAT Rate</label>
              <input type="text" step="any" />
            </div>
            <div className="label--input flex--3">
              <label>WHT Rate</label>
              <input type="text" step="any" />
            </div>
          </section>
          <section className="grid--1 section--2 section--3">
            <div className="flex--3">
              <label>Interest Calculation Period</label>
              <select
                className={`${
                  Object.keys(errorPage2).includes(
                    "interestCalculationPeriod"
                  ) && "error-input-z"
                }`}
                name="interestCalculationPeriod"
                onChange={(e) => handleChange(e, "invest")}
              >
                <option>Select An Option</option>
                <option value="Daily">Daily</option>
              </select>
              {errorPage2.interestCalculationPeriod && (
                <ErrorMessage message={errorPage2.interestCalculationPeriod} />
              )}
            </div>
            <div className="flex--3">
              <label>Unclaimed Investment Account</label>
              <div
                className={`border--norm ${
                  Object.keys(errorPage1).includes(
                    "unClaimedInvestmentAccount"
                  ) && "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={glAccountNumber}
                  onChange={handleChangeSelect}
                  type="unClaimedInvestmentAccount"
                />
              </div>
              {errorPage2.unClaimedInvestmentAccount && (
                <ErrorMessage message={errorPage2.unClaimedInvestmentAccount} />
              )}
            </div>
            <div className="flex--3">
              <label>Interest Period</label>
              <select
                className={`${
                  Object.keys(errorPage2).includes("interestPeriod") &&
                  "error-input-z"
                }`}
                name="interestPeriod"
                onChange={(e) => handleChange(e, "invest")}
              >
                <option>Select An Option</option>
                <option value="Actual">Actual/Actual</option>
                <option value="Actual360">Actual/360</option>
              </select>
              {errorPage2.interestPeriod && (
                <ErrorMessage message={errorPage2.interestPeriod} />
              )}
            </div>
            <div className="flex--3">
              <label>Allow Premature Liq Only After (Days)</label>
              <input
                onChange={(e) => handleChange(e, "invest")}
                name="allowPrematureLiqOnlyAfter"
                value={valuesInvestProduct.allowPrematureLiqOnlyAfter}
                className={`${
                  Object.keys(errorPage2).includes(
                    "allowPrematureLiqOnlyAfter"
                  ) && "error-input-z"
                }`}
                type="number"
              />
            </div>
          </section>

          <section className="flex--1 save" id="button--cont">
            <button
              className="button--add"
              onClick={() => setScreenChange(true)}
            >
              Previous
            </button>
            <button className="button--add" id="button" onClick={handleConfirm}>
              Save
            </button>
          </section>
        </main>
      )}

      <section className="section--table"></section>

      {!loading && (
        <Table
          title="Investment Products"
          subtitle=""
          name="List of Investment Products"
          records={data}
          options={options}
          columns={columns}
        />
      )}

      {details && details.id && <Details details={details} />}
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorPage1}
          handleEditSubmit={handleEditSubmit}
          handleEditChange={handleChange}
          allCurrencyDefinition={allCurrencyDefinition}
          allRateChart={allRateChart}
          errorPage2={errorPage2}
          handleFieldUpdate={handleFieldUpdate}
          handleEditChangeSelect={handleEditChangeSelect}
        />
      )}
    </div>
  );
};
export default InvestmentProduct;

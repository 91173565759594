import { useState, useEffect, useCallback } from "react";
import "./CustomerCategory.scss";
import MenuItem from "@material-ui/core/MenuItem";

import {
  getBankParameterByName,
  createCustomerCategory,
  getAllCustomerCategory,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const CustomerCategory = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const [err, setErr] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorEdit, setErrorEdit] = useState({});
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [allCustomerCategory, setAllCustomerCategory] = useState([]);
  const [cusType, setCusType] = useState([]);
  const [type, setType] = useState("");
  const [data, setData] = useState([
    {
      category: "",
      name: "",
      type: "",
    },
  ]);
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "category",
      label: "Category Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Category Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Customer Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allCustomerCategory[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = allCustomerCategory[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllCustomerCategory = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllCustomerCategory(token);
    if (response && response.data) {
      console.log(response.data, "customer category")
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllCustomerCategory(response.data);
    }
  }, [dispatch, token]);

  const parameters = "CUS_TYPE";
  const BankParameterByName = useCallback(async () => {
    const response = await getBankParameterByName(token, parameters);
    if (response && response.data.cuS_TYPE) {
      setCusType(response.data.cuS_TYPE);
    }
  }, [token]);

  const add = () => {
    const tempItem = [...data];
    tempItem.push({
      category: "",
      name: "",
      type: "",
    });
    setData(tempItem);
  };

  const remove = i => {
    const tempItem = [...data];
    tempItem.splice(i, 1);
    setData(tempItem);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const tempItems = [...data];
    tempItems[index][name] = value;
    setData(tempItems);
    console.log(data);
  };

  const handleEditChange = e => {
    const { value, name } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const validateCustomerCategory = () => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].category === "") {
        setErr(i);
        setType("category");
        setErrMsg("Field is required");
        return;
      }
      if (data[i].name === "" || data[i].name < 3) {
        setErr(i);
        setType("name");
        setErrMsg("min of three characters is required");
        return;
      }
      if (data[i].type === "" || data[i].type === "Select customer type") {
        setErr(i);
        setType("type");
        setErrMsg("select a valid customer type");
        return;
      }
    }

    return true;
  };

  const validateEditCustomerCategory = () => {
    const { category, name, type } = details;
    const error = {};
    if (category === "") {
      error.category = "Field is required";
    }
    if (name === "" || name.length < 3) {
      error.name = "min of three characters is required";
    }
    if (type === "" || type === "Select customer type") {
      error.type = "select a valid customer type";
    }
    return error;
  };

  const EmptyFieldsCustomerCategory = () => {
    setData([
      {
        category: "",
        name: "",
        type: "",
      },
    ]);
  };

  const postResult = async () => {
    dispatch(isLoading());
    const response = await createCustomerCategory(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.message === "Success") {
        EmptyFieldsCustomerCategory();
        fetchAllCustomerCategory();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSunbmit = () => {
    if (validateCustomerCategory()) {
      setErr("");
      setErrMsg("");
      setType("");
      setOpen(true);
    }
  };

  const handleEditSubmit = () => {
    dispatch(openResquestDecisionModal());
  };

  useEffect(() => {
    BankParameterByName();
  }, [BankParameterByName]);

  useEffect(() => {
    fetchAllCustomerCategory();
  }, [fetchAllCustomerCategory, updateTable]);

  return (
    <div>
      <main className="customer-category border--main">
        <Confirm
          ApiCall={postResult}
          openBox={open}
          setOpenBoxState={setOpen}
          errorMessage={message}
        />
        <Spinner loading={loading} />
        <div className="heading">
          <p>Customer Category</p>
        </div>
        <div className="section-container">
          {data.map((item, index) => (
            <section className="main-section flex--1" key={index}>
              <section className="section flex--2">
                <div className="input-container">
                  <p>Category Code</p>
                  <input
                    className={
                      err === index && type === "category"
                        ? "error--input"
                        : null
                    }
                    name="category"
                    value={item.category}
                    onChange={e => handleChange(e, index)}
                  />
                  {err === index && type === "category" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </div>
              </section>
              <section className="section flex--2">
                <div className="input-container">
                  <p>Category Description</p>
                  <input
                    className={
                      err === index && type === "name" ? "error--input" : null
                    }
                    name="name"
                    value={item.name}
                    onChange={e => handleChange(e, index)}
                  />
                  {err === index && type === "name" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </div>
              </section>
              <section className="section flex--2">
                <div className="input-container">
                  <p>Category Type</p>
                  <select
                    value={item.type}
                    className={
                      err === index && type === "type" ? "error--input" : null
                    }
                    name="type"
                    onChange={e => handleChange(e, index)}
                  >
                    <option value="Select customer type">
                      Select customer type
                    </option>
                    {cusType.map((item, i) => (
                      <option
                        key={item.id}
                        name={item.parameterValue}
                        value={item.parameterValue}
                      >
                        {item.parameterValue}
                      </option>
                    ))}
                  </select>
                  {err === index && type === "type" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </div>
              </section>
              <button
                disabled={data.length < 2}
                className="button--1"
                onClick={() => remove(index)}
              >
                Delete
              </button>
            </section>
          ))}
          <div className="button-p flex--1">
            <div className="flex--3">
              <button className="button" onClick={add}>
                +
              </button>
              <p>Add More</p>
            </div>
            <button onClick={handleSunbmit} className="submit">
              Submit
            </button>
          </div>
        </div>
      </main>
      {!loading && (
        <Table
          title="Customer Category"
          subtitle=""
          name="List of categories"
          records={allCustomerCategory}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleEditSubmit}
          details={details}
          handleChange={handleEditChange}
          cusType={cusType}
        />
      )}
    </div>
  );
};
export default CustomerCategory;

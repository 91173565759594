import { useState, useEffect, useCallback } from "react";
import { Grid, Button } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes } from "../../../../Api/configUrl"

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import SearchDropdown from "../../../SearchableDropdown/Search";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  tellerProductCharge: {
    border: "1px solid black",
    marginBottom: "3rem",
    padding: "2rem",
  },
  noData: {
    border: "1px solid black",
    height:'60vh',
    width:'65vw',
    display:'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
}));

const EditData = ({
  handleEditSubmit,
  submitUpdateCharges,
  handleEditChange,
  details,
  errorEdit,
  allCurrencyDefinition,
  childAccounts,
  handleEditChangeSelect,
  charges,
  handleUpdateChargesChange,
  allGlAccount,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [url] = useState(`${Routes.updateTellerProductDefinition}${details.id}`);
  const [hide, setHide] = useState(false);


  const tellerProductSubmit = useCallback(() => {
    if (value === 0) {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [value]);

  useEffect(() => {
    tellerProductSubmit()
  }, [tellerProductSubmit]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 useEffect(() => {
   setValue(0)
 },[details])
  return (
    <EditModal
      title="Teller Products Definition"
      label="Update details"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this Teller Product record?"
      data={details}
      url={`${url}`}
      hide={hide}
    >
      <section className="teller--product">
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Product Details" {...a11yProps(0)} />
              <Tab label="Charges" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <label>Product Code</label>
                <select
                  className={errorEdit.code ? "error-input-z" : null}
                  name="code"
                  value={details.code}
                  onChange={handleEditChange}
                >
                  <option value="CashDep">CashDep</option>
                  <option value="CashWdr">CashWdr</option>
                  <option value="MiscTillDr">MiscTillDr</option>
                  <option value="MscLATrf">MscLATrf</option>
                  <option value="MisCusDr">MisCusDr</option>
                  <option value="MisCusCr">MisCusCr</option>
                  <option value="VaultIn">VaultIn</option>
                  <option value="VaultOut">VaultOut</option>
                  <option value="ChqDep - In House">ChqDep - In House</option>
                  <option value="ChqDep - Outward Clearing">
                    ChqDep - Outward Clearing
                  </option>
                  <option value="Inward Clearing Cheques">
                    Inward Clearing Cheques
                  </option>
                  <option value="ChqWdr">ChqWdr</option>
                </select>
                {errorEdit.code && <ErrorMessage message={errorEdit.code} />}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Product Description</label>
                <select
                  className={errorEdit.description ? "error-input-z" : null}
                  name="description"
                  value={details.description}
                  onChange={handleEditChange}
                >
                  <option value="Cash Deposit">Cash Deposit</option>
                  <option value="Cash Withdrawal">Cash Withdrawal</option>
                  <option value="Miscelleneous Teller Till Debi">
                    Miscelleneous Teller Till Debi
                  </option>
                  <option value="Miscelleneous Teller Till Credit">
                    Miscelleneous Teller Till Credit
                  </option>
                  <option value="Miscelleneous Teller Till Tranfers">
                    Miscelleneous Teller Till Tranfers
                  </option>
                  <option value="Miscelleneous Customer Debit">
                    Miscelleneous Customer Debit
                  </option>
                  <option value="Miscelleneous Customer Credit">
                    Miscelleneous Customer Credit
                  </option>
                  <option value="Sell Cash to Vault">Sell Cash to Vault</option>
                  <option value="Buy Cash from Vault">
                    Buy Cash from Vault
                  </option>
                  <option value="In House Cheque Deposit">
                    In House Cheque Deposit
                  </option>
                  <option value="Outward Clearing Cheque Deposit">
                    Outward Clearing Cheque Deposit
                  </option>
                  <option value="Inward Clearing Cheque Deposit">
                    Inward Clearing Cheque Deposit
                  </option>
                  <option value="Cheque Withdrawal">Cheque Withdrawal</option>
                </select>
                {errorEdit.description && (
                  <ErrorMessage message={errorEdit.description} />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Value Type</label>
                <br />
                <select
                  className={errorEdit.valueType ? "error-input-z" : null}
                  name="valueType"
                  value={details.valueType}
                  onChange={handleEditChange}
                >
                  <option value="Immediate">Immediate</option>
                  <option value="NextDay">NextDay</option>
                </select>
                {details.valueType && (
                  <ErrorMessage message={errorEdit.valueType} />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Debit Account</label>
                <br />
                <select
                  className={errorEdit.debitAccount ? "error-input-z" : null}
                  name="debitAccount"
                  value={details.debitAccount}
                  onChange={handleEditChange}
                >
                  <option value="Till Account">Till Account</option>
                  <option value="Customer Account">Customer Account</option>
                  <option value="Ledger Account">Ledger Account</option>
                </select>
                {errorEdit.debitAccount && (
                  <ErrorMessage message={details.debitAccount} />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Tax Account</label>
                <div className="error-container-z">
                  <div
                    className={`border--norm ${
                      errorEdit.taxAccount ? "error-input-z" : null
                    }`}
                  >
                    <SearchDropdown
                      options={childAccounts}
                      onChange={handleEditChangeSelect}
                      type="taxAccount"
                    />
                  </div>
                </div>
                {errorEdit.taxAccount && (
                  <ErrorMessage message={details.taxAccount} />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Credit Account</label>
                <select
                  className={errorEdit.creditAccount ? "error-input-z" : null}
                  name="creditAccount"
                  value={details.creditAccount}
                  onChange={handleEditChange}
                >
                  <option value="Till Account">Till Account</option>
                  <option value="Customer Account">Customer Account</option>
                  <option value="Ledger Account">Ledger Account</option>
                </select>
                {errorEdit.creditAccount && (
                  <ErrorMessage message={errorEdit.creditAccount} />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="checboxdiv">
                <input
                  name="trackDenomination"
                  type="checkbox"
                  className="productcheckbox"
                  checked={details.trackDenomination}
                  onChange={handleEditChange}
                />
                <label className="span">Track Denomination</label>
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="checboxdiv">
                <input
                  name="cashTransaction"
                  type="checkbox"
                  className="productcheckbox"
                  checked={details.cashTransaction}
                  onChange={handleEditChange}
                />
                <label className="span">Cash Transaction</label>
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="checboxdiv">
                <input
                  name="chargeApplicable"
                  type="checkbox"
                  className="productcheckbox"
                  checked={details.chargeApplicable}
                  onChange={handleEditChange}
                />
                <label className="span">Charge Applicable</label>
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="checboxdiv">
                <input
                  name="applyTax"
                  type="checkbox"
                  className="productcheckbox"
                  checked={details.applyTax}
                  onChange={handleEditChange}
                />
                <label className="span">Apply Tax</label>
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="checboxdiv">
                <input
                  name="instrumentRequired"
                  type="checkbox"
                  className="productcheckbox"
                  checked={details.instrumentRequired}
                  onChange={handleEditChange}
                />
                <label className="span">Instrument Required</label>
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="checboxdiv">
                <input
                  name="vaultTransaction"
                  type="checkbox"
                  className="productcheckbox"
                  checked={details.vaultTransaction}
                  onChange={handleEditChange}
                />
                <label className="span">Vault Transaction</label>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {charges.length > 0 ? charges.map((item, index) => (
              <Grid
                container
                spacing={2}
                key={index}
                className={classes.tellerProductCharge}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <label>Charge Name</label>
                  <input
                    name="name"
                    type="text"
                    value={item.name}
                    onChange={(e) => handleUpdateChargesChange(e, index)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <label>Apply Charge At</label>
                  <select
                    name="applyat"
                    value={item.applyat}
                    onChange={(e) => handleUpdateChargesChange(e, index)}
                  >
                    <option value="Booking">Booking</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Charge Ledger Account</label>
                  <select
                    name="ledgerAccountNumber"
                    value={item.ledgerAccountNumber}
                    onChange={(e) => handleUpdateChargesChange(e, index)}
                  >
                    {allGlAccount.map((item) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <label>Type of Charge</label>
                  <select
                    name="typeOf"
                    value={item.typeOf}
                    onChange={(e) => handleUpdateChargesChange(e, index)}
                  >
                    <option value="Flat Amount">Flat Amount</option>
                    <option value="percentage rate">Rate</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <label>
                    Amount Rate{" "}
                    {item.typeOf === "" ||
                    item.typeOf === "Select type of charge"
                      ? null
                      : item.typeOf === "Flat Amount"
                      ? "(₦)"
                      : "(%)"}
                  </label>
                  <input
                    name="amount"
                    type="number"
                    value={item.amount}
                    onChange={(e) => handleUpdateChargesChange(e, index)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <label>Charge Currency</label>
                  <select
                    name="currency"
                    value={item.currency}
                    onChange={(e) => handleUpdateChargesChange(e, index)}
                  >
                    {allCurrencyDefinition.map((item) => (
                      <option value={item.currencyCode} key={item.id}>
                        {item.currencyName}
                      </option>
                    ))}
                  </select>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.btnContainer}>
                    <Button
                      onClick={() => submitUpdateCharges(index)}
                      className={classes.button}
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )): <div className={classes.noData}>
            <p>Charges are not applicable</p>
        </div>}
            
          </TabPanel>
        </div>
      </section>
    </EditModal>
  );
};

export default EditData;

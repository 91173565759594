import { useState } from 'react'
import Select from 'react-select'


const Search = ({ options, onChange, type, borderType }) => {
  const [displayValue, setDisplayValue] = useState('');


  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      height: 40,
    }),
    singleValue: (provided) => ({
      ...provided,
      top: "73%",
      color: 'black'
    }),
    placeholder: (provided) => ({
        ...provided,
        height: 44,
        overflow: 'hidden',
        color: 'black',
        transform: 'translateY(0%)',
        top: '-5px',
        position:'relative',
        padding: '8px 0',
    }),

    indicatorsContainer: () => ({
      borderBottom: '1px solid grey',
      padding: 10,
      height: 42,
      color: 'hsl(0, 0%, 20%)',
      display: 'none'
    }),
    control: () => ({
        height: 42,
        padding: 0,
    }),
    
  }

    return (
        <>
        <Select
            value={displayValue}
            options={options}
            placeholder= "Select an Option"
            // className={'searchable-dropdown'}
            styles={customStyles}
            onChange={(value) => {
                setDisplayValue(value)
                onChange(value.value, type)
            } 
            }
        />
        </>
    )
}

export default Search

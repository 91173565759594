import Item from "../../DatagridTable/item";
import { Grid } from '@material-ui/core';


const FiscalYear = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Fiscal Year" name={details.year} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Start Date" name={details.startdate} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="End Date" name={details.enddate} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>
    )
}

export default FiscalYear

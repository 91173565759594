import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import NumberFormat from "react-number-format";
import "./LoanProducts.scss";
import BussinessLogic from "./bussinessLogic";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import UpadateLoanProductServices from "../../../../Confirmbox/Confirm";
import ErrorMessage from "../../../ErrorMessage";
import SearchDropdown from "../../../SearchableDropdown/Search";
import { useSelector } from "react-redux";
import Table from "../../../DatagridTable/table";
import EditForm from "./edit";
import Details from "./view";

const LoanProducts = () => {
  const loading = useSelector((state) => state.req.loading);
  const {
    product,
    setProducts,
    restriction,
    setRestriction,
    allGlAccount,
    childAccounts,
    allCurrencyDefinition,
    cusCategory,
    loanStatus,
    open,
    setOpen,
    handleChange,
    handleLoanProductChange,
    handleOverdueDayChange,
    handleRepaymentOrderChange,
    handleCustomerRestrictionChange,
    handleChangeSelect,
    addLoanProductCharges,
    addOverdueDays,
    addRepaymentOrder,
    addCustomerRestriction,
    removeCustomerRestriction,
    removeRepaymentOrder,
    removeOverdueDays,
    removeLoanProductCharges,
    handleSubmit,
    postLoanProducts,
    message,
    errors,
    screenChange,
    setScreenChange,
    handleFirstScreen,
    handleSecondScreen,
    handleThirdScreen,
    handleEditDetailsChange,
    editDetails,
    setEditDetails,
    editErrors,
    fetchAllLoanProducts,
    BankParameterByName,
    fetchAllCustomerCategory,
    fetchAllCurrencyDefinition,
    fetchAllGlAccount,
    updateTable,
    data,
    options,
    columns,
    details,
    editloanProductCharge,
    editOverdueDays,
    editRepaymentOrder,
    editCustomerRestriction,
    handleEditLoanProductChange,
    handleEditOverdueDayChange,
    handleEditRepaymentOrderChange,
    handleEditCustomerRestrictionChange,
    handleDetailsUpdateSubmit,
    handleSubmitEditLoanServices,
    service,
    errorsOfOtherServicesOtherthanProductDetails,
    openEditLoanServiceModal,
    setEditLoanServiceModal,
    postUpdateLoanServices,
    editServicesMsg,
    interestCalc,
    equitySelectType,
    setEquitySelectType,
    setEditOverdueDays,
    updateAddOverDueDays,
    removeLoanContributionProduct,
    addLoanContributionProduct,
    handleLoanContributionProduct,
    accountProducts,
  } = BussinessLogic();

  console.log("products", product);

  useEffect(() => {
    fetchAllLoanProducts();
  }, [fetchAllLoanProducts, updateTable]);

  useEffect(() => {
    BankParameterByName();
  }, [BankParameterByName]);

  useEffect(() => {
    fetchAllCustomerCategory();
  }, [fetchAllCustomerCategory]);

  useEffect(() => {
    fetchAllCurrencyDefinition();
  }, [fetchAllCurrencyDefinition]);

  useEffect(() => {
    fetchAllGlAccount();
  }, [fetchAllGlAccount]);

  const {
    loanProductCharge,
    overdueDays,
    repaymentOrder,
    customerRestriction,
    loanContributionProduct,
  } = product;

  console.log("childAccount are here", childAccounts);

  return (
    <div className="loan--product border--main">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={postLoanProducts}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <UpadateLoanProductServices
        ApiCall={postUpdateLoanServices}
        openBox={openEditLoanServiceModal}
        setOpenBoxState={setEditLoanServiceModal}
        message={`Are You sure you want to update this ${service}`}
        errorMessage={editServicesMsg}
        actionMsg={editServicesMsg}
      />
      <div className="headerdiv">
        <p>Products | Loan Products </p>
      </div>
      {screenChange === 1 && (
        <div style={{ marginTop: 20, padding: 25 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3}>
              <label>Product Code</label>
              <input
                type="text"
                className={errors.productCode ? "error-input-z" : null}
                name="productCode"
                value={product.productCode}
                onChange={handleChange}
              />
              {errors.productCode && (
                <ErrorMessage message={errors.productCode} />
              )}
              <div className="checboxdiv">
                <input type="checkbox" className="productcheckbox" />
                <label className="span">Deactivate Product</label>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Product Name</label>
              <input
                type="text"
                className={errors.productName ? "error-input-z" : null}
                name="productName"
                value={product.productName}
                onChange={handleChange}
              />
              {errors.productName && (
                <ErrorMessage message={errors.productName} />
              )}
            </Grid>
            <Grid sm={12}></Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Currency</label>
              <select
                name="currency"
                className={errors.currency ? "error-input-z" : null}
                value={product.currency}
                onChange={handleChange}
              >
                <option value="">Select a currency</option>
                {allCurrencyDefinition.map((item) => (
                  <option value={item.currencyCode} key={item.id}>
                    {item.currencyName}
                  </option>
                ))}
              </select>
              {errors.currency && <ErrorMessage message={errors.currency} />}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Default Principal</label>
              <NumberFormat
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="defaultPrincipal"
                value={product.defaultPrincipal}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => <div>{value}</div>}
                className={`some ${
                  errors.defaultPrincipal ? "error-input-z" : null
                }`}
                onValueChange={(values) => {
                  setProducts((product) => ({
                    ...product,
                    defaultPrincipal: parseInt(values.value),
                  }));
                }}
              />
              {/* <input
                type="number"
                className={errors.defaultPrincipal ? "error-input-z" : null}
                name="defaultPrincipal"
                value={product.defaultPrincipal}
                onChange={handleChange}
              /> */}
              {errors.defaultPrincipal && (
                <ErrorMessage message={errors.defaultPrincipal} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Minimum Principal</label>
              <NumberFormat
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                value={product.minimumPrincipal}
                name="minimumPrincipal"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => <div>{value}</div>}
                className={`some ${
                  errors.minimumPrincipal ? "error-input-z" : null
                }`}
                onValueChange={(values) => {
                  setProducts((product) => ({
                    ...product,
                    minimumPrincipal: parseInt(values.value),
                  }));
                }}
              />
              {/* <input
                type="number"
                className={errors.minimumPrincipal ? "error-input-z" : null}
                name="minimumPrincipal"
                value={product.minimumPrincipal}
                onChange={handleChange}
              /> */}
              {errors.minimumPrincipal && (
                <ErrorMessage message={errors.minimumPrincipal} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Maximum Principal</label>
              <NumberFormat
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="maximumPrincipal"
                value={product.maximumPrincipal}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => <div>{value}</div>}
                className={`some ${
                  errors.maximumPrincipal ? "error-input-z" : null
                }`}
                onValueChange={(values) => {
                  setProducts((product) => ({
                    ...product,
                    maximumPrincipal: parseInt(values.value),
                  }));
                }}
              />
              {/* <input
                type="number"
                className={errors.maximumPrincipal ? "error-input-z" : null}
                name="maximumPrincipal"
                value={product.maximumPrincipal}
                onChange={handleChange}
              /> */}
              {errors.maximumPrincipal && (
                <ErrorMessage message={errors.maximumPrincipal} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Default Tenor</label>
              <input
                type="number"
                className={errors.defaultTenor ? "error-input-z" : null}
                name="defaultTenor"
                value={product.defaultTenor}
                onChange={handleChange}
              />
              {errors.defaultTenor && (
                <ErrorMessage message={errors.defaultTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Minimum Tenor</label>
              <input
                type="number"
                className={errors.minimumTenor ? "error-input-z" : null}
                name="minimumTenor"
                value={product.minimumTenor}
                onChange={handleChange}
              />
              {errors.minimumTenor && (
                <ErrorMessage message={errors.minimumTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Maximum Tenor</label>
              <input
                type="number"
                className={errors.maximumTenor ? "error-input-z" : null}
                name="maximumTenor"
                value={product.maximumTenor}
                onChange={handleChange}
              />
              {errors.maximumTenor && (
                <ErrorMessage message={errors.maximumTenor} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Tenor Basis</label>
              <select
                name="tenorBasis"
                className={errors.tenorBasis ? "error-input-z" : null}
                value={product.tenorBasis}
                onChange={handleChange}
              >
                <option value="">Select tenor basis</option>
                <option value="Days">Days</option>
                <option value="Months">Month</option>
              </select>
              {errors.tenorBasis && (
                <ErrorMessage message={errors.tenorBasis} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Rate Type</label>
              <select
                name="rateType"
                className={errors.rateType ? "error-input-z" : null}
                value={product.rateType}
                onChange={handleChange}
              >
                <option value="Select rate type">Select rate type</option>
                <option value="Fixed">Fixed</option>
                {/* <option value="Floating">Floating</option> */}
              </select>
              {errors.rateType && <ErrorMessage message={errors.rateType} />}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {" "}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {" "}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {" "}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Default Interest Rate</label>
              <input
                type="number"
                className={errors.defaultInterestRate ? "error-input-z" : null}
                name="defaultInterestRate"
                step={2}
                value={product.defaultInterestRate}
                onChange={handleChange}
              />
              {errors.defaultInterestRate && (
                <ErrorMessage message={errors.defaultInterestRate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Minimum Interest Rate</label>
              <input
                type="number"
                className={errors.minimumInterestRate ? "error-input-z" : null}
                name="minimumInterestRate"
                step={2}
                value={product.minimumInterestRate}
                onChange={handleChange}
              />
              {errors.minimumInterestRate && (
                <ErrorMessage message={errors.minimumInterestRate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Maximum Interest Rate</label>
              <input
                type="number"
                className={errors.maximumInterestRate ? "error-input-z" : null}
                name="maximumInterestRate"
                step={2}
                value={product.maximumInterestRate}
                onChange={handleChange}
              />
              {errors.maximumInterestRate && (
                <ErrorMessage message={errors.maximumInterestRate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label>Rate Basis</label>
              <select
                name="rateBasis"
                className={errors.rateBasis ? "error-input-z" : null}
                value={product.rateBasis}
                onChange={handleChange}
              >
                <option value="">Select rate basis</option>
                <option value="PerAnnum">Per Annum</option>
                <option value="PerMonth">Per Month</option>
              </select>
              {errors.rateBasis && <ErrorMessage message={errors.rateBasis} />}
            </Grid>
            {product.rateType === "Floating" && (
              <Grid item xs={12} sm={6} md={3}>
                <label>Minimum Variance</label>
                <input
                  type="number"
                  className={errors.minimumVariance ? "error-input-z" : null}
                  name="minimumVariance"
                  value={product.minimumVariance}
                  onChange={handleChange}
                />
                {errors.minimumVariance && (
                  <ErrorMessage message={errors.minimumVariance} />
                )}
              </Grid>
            )}
            {product.rateType === "Floating" && (
              <Grid item xs={12} sm={6} md={3}>
                <label>Maximum Variance</label>
                <input
                  type="number"
                  className={errors.maximumVariance ? "error-input-z" : null}
                  name="maximumVariance"
                  value={product.maximumVariance}
                  onChange={handleChange}
                />
                {errors.maximumVariance && (
                  <ErrorMessage message={errors.maximumVariance} />
                )}
              </Grid>
            )}
          </Grid>
          <button className="next" onClick={handleFirstScreen}>
            Next
          </button>
        </div>
      )}

      {screenChange === 2 && (
        <main className="next-section--1">
          <section>
            <section className="flex--1 label--main">
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Repayment Frequency</label>
                  <select
                    name="repaymentFrequency"
                    className={
                      errors.repaymentFrequency ? "error-input-z" : null
                    }
                    value={product.repaymentFrequency}
                    onChange={handleChange}
                  >
                    <option value="">Select replayment frequency</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                  {errors.repaymentFrequency && (
                    <ErrorMessage message={errors.repaymentFrequency} />
                  )}
                </div>
                {/* <input placeholder="" className="sub-input" /> */}
              </div>
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Interest Calculation Method</label>
                  <select
                    name="interestCalculationMethod"
                    className={
                      errors.interestCalculationMethod ? "error-input-z" : null
                    }
                    value={product.interestCalculationMethod}
                    onChange={handleChange}
                  >
                    <option value="">Select calculation method</option>
                    <option value="DecliningBalance">Declining Balance</option>
                    <option value="FlatRate">Flat Rate</option>
                  </select>
                  {errors.interestCalculationMethod && (
                    <ErrorMessage message={errors.interestCalculationMethod} />
                  )}
                </div>
                {interestCalc === "DecliningBalance" && (
                  <div className="flex--3 label--input sub-input">
                    <label>Amortization Type</label>
                    <select
                      name="amortizationType"
                      className={
                        errors.amortizationType ? "error-input-z" : null
                      }
                      value={product.amortizationType}
                      onChange={handleChange}
                      disabled={
                        interestCalc === "DecliningBalance" ? false : true
                      }
                    >
                      {/* <option value="">Select Amortization type</option> */}
                      {interestCalc === "DecliningBalance" && (
                        <option value="EqualInstallment">
                          Equal installment
                        </option>
                      )}
                      <option value="EqualPrincipal">Equal Principal</option>
                    </select>
                    {errors.amortizationType && (
                      <ErrorMessage message={errors.amortizationType} />
                    )}
                  </div>
                )}
              </div>
            </section>
            <section className="flex--1 section--1">
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Interest Period</label>
                  <select
                    name="interestPeriod"
                    className={errors.interestPeriod ? "error-input-z" : null}
                    value={product.interestPeriod}
                    onChange={handleChange}
                  >
                    <option value="">Select interest period</option>
                    <option value="Actual">Actual/Actual</option>
                    <option value="Actual360">Actual/360</option>
                    <option value="Actual30"> 30/Actual</option>
                    <option value="30360"> 30/360</option>
                  </select>
                  {errors.interestPeriod && (
                    <ErrorMessage message={errors.interestPeriod} />
                  )}
                </div>
              </div>
              <div className="flex--2">
                <input
                  type="checkbox"
                  name="accrualRequired"
                  checked={product.accrualRequired}
                  onChange={handleChange}
                />
                <label>Accrual Required</label>
              </div>
              <div className="flex--2">
                <input
                  type="checkbox"
                  disabled
                  name="partialDisbursementAllowed"
                  checked={product.partialDisbursementAllowed}
                  onChange={handleChange}
                />
                <label>Partial Disbursement Allowed</label>
              </div>
              <div className="flex--2">
                <input
                  type="checkbox"
                  name="guarantorRequired"
                  checked={product.guarantorRequired}
                  onChange={handleChange}
                />
                <label>Guarantor Required</label>
              </div>
            </section>
            <section className="flex--1 label--main">
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Liquidation Type</label>
                  <select
                    name="liquidationType"
                    className={errors.liquidationType ? "error-input-z" : null}
                    value={product.liquidationType}
                    onChange={handleChange}
                  >
                    <option value="">Select liquidation type</option>
                    <option value="Manual">Manual</option>
                    <option value="Automatic">Automatic</option>
                  </select>
                  {errors.liquidationType && (
                    <ErrorMessage message={errors.liquidationType} />
                  )}
                </div>
              </div>
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Accrual Period</label>
                  <select
                    name="accrualPeriod"
                    className={errors.accrualPeriod ? "error-input-z" : null}
                    value={product.accrualPeriod}
                    onChange={handleChange}
                  >
                    <option value="">Select accrual period</option>
                    <option value="OnMaturity">OnMaturity</option>
                    <option value="Daily">Daily</option>
                  </select>
                  {errors.accrualPeriod && (
                    <ErrorMessage message={errors.accrualPeriod} />
                  )}
                </div>
              </div>
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Disbursement Method</label>
                  <select
                    name="disbursementMethod"
                    className={
                      errors.disbursementMethod ? "error-input-z" : null
                    }
                    value={product.disbursementMethod}
                    onChange={handleChange}
                  >
                    <option value="">Select disbursement method</option>
                    <option value="Manual">Manual</option>
                    <option value="Automatic">Automatic</option>
                  </select>
                  {errors.disbursementMethod && (
                    <ErrorMessage message={errors.disbursementMethod} />
                  )}
                </div>
              </div>
            </section>
            {/* Loan product charges */}
            <section className="last-section">
              <h5>Charges</h5>
              <section className="sub--section flex--3">
                {loanProductCharge.map((item, i) => (
                  <div className="grid--1 label--main" key={i}>
                    <div className="flex--3">
                      <label>Charge Name</label>
                      <select
                        type="text"
                        className={
                          errors.chargeName &&
                          errors.chargeName.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="chargeName"
                        value={item.chargeName}
                        onChange={(e) => handleLoanProductChange(e, i)}
                      >
                        <option value="">Select charge name</option>
                        <option value="AdminFee">AdminFee</option>
                        <option value="LegalFees">LegalFees</option>
                        <option value="DocCharge">DocCharge</option>
                        <option value="CreditSearchFee">CreditSearchFee</option>
                        <option value="MngmtFee">MngmtFee</option>
                        <option value="LandSearchFee">LandSearchFee</option>
                        <option value="ValuationFee">ValuationFee</option>
                        <option value="InsuranceFee">InsuranceFee</option>
                        <option value="RolloverFee">RolloverFee</option>
                        <option value="ProcessingFee">ProcessingFee</option>
                      </select>
                      {errors.chargeName &&
                        errors.chargeName.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    <div className="flex--3">
                      <label>Apply Charges At</label>
                      <select
                        name="applyChargesAt"
                        className={
                          errors.applyChargesAt &&
                          errors.applyChargesAt.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.applyChargesAt}
                        onChange={(e) => handleLoanProductChange(e, i)}
                      >
                        <option value="">Select charge at</option>
                        <option value="Booking">Booking</option>
                        <option value="Repayment">Repayment</option>
                        <option value="Disbursement">Disbursement</option>
                      </select>
                      {errors.applyChargesAt &&
                        errors.applyChargesAt.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    <div className="flex--3">
                      <label>Charge Ledger Account</label>
                      <select
                        name="chargeLedgerAccount"
                        className={
                          errors.chargeLedgerAccount &&
                          errors.chargeLedgerAccount.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.chargeLedgerAccount}
                        onChange={(e) => handleLoanProductChange(e, i)}
                      >
                        <option value="">Select charge ledger account</option>
                        {allGlAccount.length < 1 && (
                          <option value="" disabled>
                            No valid authorized child, income gl account
                          </option>
                        )}
                        {allGlAccount.map((item) => (
                          <option value={item.accountNumber} key={item.id}>
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                      {errors.chargeLedgerAccount &&
                        errors.chargeLedgerAccount.find(
                          (el) => el.id === i
                        ) && <ErrorMessage message="Field cannot be empty" />}
                    </div>
                    <div className="flex--3">
                      <label>Type of charge</label>
                      <select
                        name="typeOfCharge"
                        className={
                          errors.typeOfCharge &&
                          errors.typeOfCharge.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.typeOfCharge}
                        onChange={(e) => handleLoanProductChange(e, i)}
                      >
                        <option value="">Select type of charge</option>
                        <option value="FlatAmount">Flat Amount</option>
                        <option value="Rate">Rate</option>
                      </select>
                      {errors.typeOfCharge &&
                        errors.typeOfCharge.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    {item.typeOfCharge === "FlatAmount" && (
                      <div className="flex--3">
                        <label>Amount</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={item.amount}
                          thousandSeparator={true}
                          inputMode="text"
                          renderText={(value) => <div>{value}</div>}
                          className={`some ${
                            errors.amount &&
                            errors.amount.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }`}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            handleLoanProductChange(
                              "",
                              i,
                              "amount",
                              value,
                              formattedValue
                            );
                          }}
                        />
                        {errors.amount &&
                          errors.amount.find((el) => el.id === i) && (
                            <ErrorMessage message="Field is required" />
                          )}
                      </div>
                    )}
                    {item.typeOfCharge === "Rate" && (
                      <div className="flex--3">
                        <label>Rate</label>
                        <NumberFormat
                           decimalSeparator="."
                           decimalScale={2}
                           fixedDecimalScale={true}
                           thousandsGroupStyle="thousand"
                          thousandSeparator={true}
                          inputMode="numeric"
                                      className={
                            errors.rate && errors.rate.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          name="rate"
                          value={item.rate}
                          onChange={(e) => handleLoanProductChange(e, i)}
                        />
                        {/* <input
                          type="text"
                          className={
                            errors.rate && errors.rate.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          name="rate"
                          step="0.01"
                          value={item.rate}
                          onChange={(values) => {
                            const { formattedValue, value } = values;
                            handleLoanProductChange(
                              "",
                              i,
                              "rate",
                              value,
                              formattedValue
                            );
                          }}
                        /> */}
                        {errors.rate &&
                          errors.rate.find((el) => el.id === i) && (
                            <ErrorMessage message="Field is required" />
                          )}
                      </div>
                    )}
                    <div className="flex--3">
                      <label>Charge currency</label>
                      <select
                        name="chargeCurrency"
                        className={
                          errors.chargeCurrency &&
                          errors.chargeCurrency.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.chargeCurrency}
                        onChange={(e) => handleLoanProductChange(e, i)}
                      >
                        <option value="">Select charge currency</option>
                        {allCurrencyDefinition.map((item) => (
                          <option value={item.currencyCode} key={item.id}>
                            {item.currencyName}
                          </option>
                        ))}
                      </select>
                      {errors.chargeCurrency &&
                        errors.chargeCurrency.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    <button
                      className="button"
                      onClick={() => removeLoanProductCharges(i)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <div className="button-p">
                  <div className="button-add">
                    <button className="button" onClick={addLoanProductCharges}>
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div>
              </section>
            </section>

            <div className="flex--1 save" id="button--cont">
              <button
                className="button--add"
                onClick={() => setScreenChange(1)}
              >
                Previous
              </button>
              <button
                className="button--add"
                id="button"
                onClick={handleSecondScreen}
              >
                Next
              </button>
            </div>
          </section>
        </main>
      )}

      {screenChange === 3 && (
        <main className="next-section--2">
          <section>
            <div className="flex--1 heading-container">
              <h5>Arrear Status Rule</h5>
              <h5>Repayment Order</h5>
            </div>

            <div className="flex--1">
              <div className="label--input">
                <label>Status Rule Application</label>
                <select
                  name="statusRuleApplication"
                  className={
                    errors.statusRuleApplication ? "error-input-z" : null
                  }
                  value={product.statusRuleApplication}
                  onChange={handleChange}
                >
                  <option value="">Select status rule</option>
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </select>
                {errors.statusRuleApplication && (
                  <ErrorMessage message={errors.statusRuleApplication} />
                )}
              </div>

              <div className="label--input">
                <label>Debit Interest VAT Rate</label>
                <input
                  type="number"
                  name="debitInterestVATRate"
                  className={
                    errors.debitInterestVATRate ? "error-input-z" : null
                  }
                  value={product.debitInterestVATRate}
                  onChange={handleChange}
                />

                {errors.debitInterestVATRate && (
                  <ErrorMessage message={errors.debitInterestVATRate} />
                )}
              </div>
            </div>

            {/* Overduedays */}

            <div className="flex--1 side-container">
              <div className="side--1 flex--3">
                <div className="heading flex--1">
                  <h5>Overdue Days</h5>
                </div>
                {overdueDays.map((item, i) => (
                  <div className="grid--1 label-cont" key={i}>
                    <div className="flex--3">
                      <label>From</label>
                      <input
                        type="number"
                        className={
                          errors.from && errors.from.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="from"
                        value={item.from}
                        onChange={(e) => handleOverdueDayChange(e, i)}
                      />
                      {errors.from && errors.from.find((el) => el.id === i) && (
                        <ErrorMessage message={"Field is required"} />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>To</label>
                      <input
                        type="number"
                        className={
                          errors.to && errors.to.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="to"
                        value={item.to}
                        onChange={(e) => handleOverdueDayChange(e, i)}
                      />
                      {errors.to && errors.to.find((el) => el.id === i) && (
                        <ErrorMessage message={"Field is required"} />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>Status</label>
                      <select
                        name="loanStatus"
                        className={
                          errors.loanStatus &&
                          errors.loanStatus.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.loanStatus}
                        onChange={(e) => handleOverdueDayChange(e, i)}
                      >
                        <option value="">Select status</option>
                        {loanStatus.map((item, i) => (
                          <option
                            key={item.id}
                            name={item.parameterValue}
                            value={item.parameterValue}
                          >
                            {item.parameterValue}
                          </option>
                        ))}
                      </select>
                      {errors.loanStatus &&
                        errors.loanStatus.find((el) => el.id === i) && (
                          <ErrorMessage message={"Invalid entry"} />
                        )}
                    </div>
                    <button
                      className="button"
                      onClick={() => removeOverdueDays(i)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <div className="button-p">
                  <div className="button-add">
                    <button className="button" onClick={addOverdueDays}>
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div>
              </div>

              {/* repayment order */}

              <div className="side--1 s flex--3">
                <div className="heading flex--1">
                  <h5>Repayment Order</h5>
                </div>
                {repaymentOrder.map((item, i) => (
                  <div className="grid--1 label-cont label-cont--1" key={i}>
                    <div className="flex--3">
                      <input
                        type="number"
                        name="sequenceId"
                        disabled
                        className="input--1"
                        value={item.sequenceId}
                        onChange={(e) => handleRepaymentOrderChange(e, i)}
                      />
                    </div>
                    <div className="flex--3">
                      <select
                        name="repaymentType"
                        value={item.repaymentType}
                        className={
                          errors.repaymentType &&
                          errors.repaymentType.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        //className="select--s"
                        onChange={(e) => handleRepaymentOrderChange(e, i)}
                      >
                        <option value="">Select repayment type</option>
                        <option value="Interest">Interest</option>
                        <option value="Principal">Principal</option>
                        <option value="Charges">Charges</option>
                        <option value="Penalties">Penalties</option>
                        <option value="WriteOff">WriteOff</option>
                      </select>
                      {errors.repaymentType &&
                        errors.repaymentType.find((el) => el.id === i) && (
                          <ErrorMessage message={"Invalid entry"} />
                        )}
                    </div>
                    <button
                      className="button"
                      onClick={() => removeRepaymentOrder(i)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <div className="button-p">
                  <div className="button-add">
                    <button className="button" onClick={addRepaymentOrder}>
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flex--1 save" id="button--cont">
            <button className="button--add" onClick={() => setScreenChange(2)}>
              Previous
            </button>
            <button
              className="button--add"
              id="button"
              onClick={handleThirdScreen}
            >
              Next
            </button>
          </section>
        </main>
      )}

      {screenChange === 4 && (
        <main className="next-section--3">
          <section className="section--1">
            <div className="main grid--1">
              <div className="flex--3">
                <label>Loan Account</label>
                {childAccounts.length === 0 && (
                  <select>
                    <option value="">Select, An Option</option>
                    <option value="">No Authorized Record Found</option>
                  </select>
                )}
                {childAccounts.length > 0 && (
                  <>
                    <div className="error-container-z">
                      <div
                      // className={`border--norm ${
                      //   errors.loanAccount ? "error-input-z" : null
                      // }`}
                      >
                        {/* <SearchDropdown
                          options={childAccounts}
                          onChange={handleChangeSelect}
                          type="loanAccount"
                        /> */}
                        <select
                          name="loanAccount"
                          value={product.loanAccount}
                          onChange={handleChange}
                          className={`${
                            Object.keys(errors).includes("loanAccount") &&
                            "error-input-z"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select An Option</option>
                          {childAccounts.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.value} - {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.loanAccount && (
                      <ErrorMessage message={errors.loanAccount} />
                    )}
                  </>
                )}
              </div>
              <div className="flex--3">
                <label>Interest Account</label>
                {childAccounts.length === 0 && (
                  <select>
                    <option value="">Select, An Option</option>
                    <option value="">No Authorized Record Found</option>
                  </select>
                )}
                {childAccounts.length > 0 && (
                  <>
                    <div className="error-container-z">
                      <div
                      // className={`border--norm ${
                      //   errors.interestAccount ? "error-input-z" : null
                      // }`}
                      >
                        {/* <SearchDropdown
                          options={childAccounts}
                          onChange={handleChangeSelect}
                          type="interestAccount"
                        /> */}
                        <select
                          name="interestAccount"
                          value={product.interestAccount}
                          onChange={handleChange}
                          className={`${
                            Object.keys(errors).includes("interestAccount") &&
                            "error-input-z"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select An Option</option>
                          {childAccounts.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.value} - {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.interestAccount && (
                      <ErrorMessage message={errors.interestAccount} />
                    )}
                  </>
                )}
              </div>
              <div className="flex--3">
                <label>Interest Receivable Account</label>
                {childAccounts.length === 0 && (
                  <select>
                    <option value="">Select, An Option</option>
                    <option value="">No Authorized Record Found</option>
                  </select>
                )}
                {childAccounts.length > 0 && (
                  <>
                    <div className="error-container-z">
                      <div
                      // className={`border--norm ${
                      //   errors.interestReceivableAccount
                      //     ? "error-input-z"
                      //     : null
                      // }`}
                      >
                        {/* <SearchDropdown
                          options={childAccounts}
                          onChange={handleChangeSelect}
                          type="interestReceivableAccount"
                        /> */}
                        <select
                          name="interestReceivableAccount"
                          value={product.interestReceivableAccount}
                          onChange={handleChange}
                          className={`${
                            Object.keys(errors).includes(
                              "interestReceivableAccount"
                            ) && "error-input-z"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select An Option</option>
                          {childAccounts.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.value} - {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.interestReceivableAccount && (
                      <ErrorMessage
                        message={errors.interestReceivableAccount}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="flex--3">
                <label>Penal Interest Account</label>
                {childAccounts.length === 0 && (
                  <select>
                    <option value="">Select, An Option</option>
                    <option value="">No Authorized Record Found</option>
                  </select>
                )}
                {childAccounts.length > 0 && (
                  <>
                    <div className="error-container-z">
                      <div
                      // className={`border--norm ${
                      //   errors.penalInterestAccount ? "error-input-z" : null
                      // }`}
                      >
                        {/* <SearchDropdown
                          options={childAccounts}
                          onChange={handleChangeSelect}
                          type="penalInterestAccount"
                        /> */}
                        <select
                          name="penalInterestAccount"
                          value={product.penalInterestAccount}
                          onChange={handleChange}
                          className={`${
                            Object.keys(errors).includes(
                              "penalInterestAccount"
                            ) && "error-input-z"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select An Option</option>
                          {childAccounts.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.value} - {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.penalInterestAccount && (
                      <ErrorMessage message={errors.penalInterestAccount} />
                    )}
                  </>
                )}
              </div>
              <div className="flex--3">
                <label>Loan WriteOff Account</label>
                {childAccounts.length === 0 && (
                  <select>
                    <option value="">Select, An Option</option>
                    <option value="">No Authorized Record Found</option>
                  </select>
                )}
                {childAccounts.length > 0 && (
                  <>
                    <div className="error-container-z">
                      <div
                      // className={`border--norm ${
                      //   errors.loanWriteOff ? "error-input-z" : null
                      // }`}
                      >
                        {/* <SearchDropdown
                          options={childAccounts}
                          onChange={handleChangeSelect}
                          type="loanWriteOff"
                        /> */}
                        <select
                          name="loanWriteOff"
                          value={product.loanWriteOff}
                          onChange={handleChange}
                          className={`${
                            Object.keys(errors).includes("loanWriteOff") &&
                            "error-input-z"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select An Option</option>
                          {childAccounts.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.value} - {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.loanWriteOff && (
                      <ErrorMessage message={errors.loanWriteOff} />
                    )}
                  </>
                )}
              </div>

              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Equity Multiple</label>
                  <input
                    type="number"
                    className={errors.equityMultiple ? "error-input-z" : null}
                    name="equityMultiple"
                    value={product.equityMultiple}
                    onChange={handleChange}
                  />
                  {errors.equityMultiple && (
                    <ErrorMessage message={errors.equityMultiple} />
                  )}
                </div>
              </div>

              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Admin Fee</label>
                  <NumberFormat
                    inputMode="numeric"
                    name="adminFee"
                    decimalSeparator="."
                    value={product.adminFee}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(value) => <div>{value}</div>}
                    className={`some ${
                      errors.adminFee ? "error-input-z" : null
                    }`}
                    onValueChange={(values) => {
                      setProducts((product) => ({
                        ...product,
                        adminFee: parseFloat(values.value),
                      }));
                    }}
                  />
                  {errors.adminFee && (
                    <ErrorMessage message={errors.adminFee} />
                  )}
                </div>
              </div>
              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Charges for Loan Below 100K</label>
                  <NumberFormat
                    inputMode="numeric"
                    name="loanBelowHundredFee"
                    decimalSeparator="."
                    value={product.loanBelowHundredFee}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(value) => <div>{value}</div>}
                    className={`some ${
                      errors.loanBelowHundredFee ? "error-input-z" : null
                    }`}
                    onValueChange={(values) => {
                      setProducts((product) => ({
                        ...product,
                        loanBelowHundredFee: parseFloat(values.value),
                      }));
                    }}
                  />
                  {errors.loanBelowHundredFee && (
                    <ErrorMessage message={errors.loanBelowHundredFee} />
                  )}
                </div>
              </div>

              <div className="flex--1 label--container">
                <div className="flex--3 label--input">
                  <label>Charges for Loan Above 100K</label>
                  <NumberFormat
                    inputMode="numeric"
                    name="loanAboveHundredFee"
                    decimalSeparator="."
                    value={product.loanAboveHundredFee}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(value) => <div>{value}</div>}
                    className={`some ${
                      errors.loanAboveHundredFee ? "error-input-z" : null
                    }`}
                    onValueChange={(values) => {
                      setProducts((product) => ({
                        ...product,
                        loanAboveHundredFee: parseFloat(values.value),
                      }));
                    }}
                  />
                  {errors.loanAboveHundredFee && (
                    <ErrorMessage message={errors.loanAboveHundredFee} />
                  )}
                </div>
              </div>

              <div className="flex--2">
                <input
                  type="checkbox"
                  name="bulletpayment"
                  checked={product.bulletpayment}
                  onChange={handleChange}
                />
                <label>Bullet Payment</label>
              </div>
            </div>
          </section>
          {/* customer restriction */}

          <section className="section--2">
            <div className="customer-restriction">
              <h4>Customer Restriction</h4>
              <div className="sub--section">
                <div className="button--input flex--3">
                  <select
                    name="restrictionType"
                    className={
                      errors.restrictionType
                        ? "error-input-z selecter"
                        : "selecter"
                    }
                    value={restriction}
                    onChange={(e) => setRestriction(e.target.value)}
                  >
                    <option value="">Select restriction type</option>
                    <option value="Allow">Allow</option>
                    <option value="Disallow">Disallow</option>
                  </select>
                  {errors.restrictionType && (
                    <ErrorMessage message={errors.restrictionType} />
                  )}
                  {customerRestriction.map((item, i) => (
                    <div className="gapper flex--1" key={i}>
                      <div className="flex--3">
                        <label>Customer Category</label>
                        <select
                          name="customerCategory"
                          value={item.customerCategory}
                          onChange={(e) =>
                            handleCustomerRestrictionChange(e, i)
                          }
                        >
                          <option value="Select customer category">
                            Select customer Category
                          </option>
                          {cusCategory.map((item, i) => (
                            <option
                              key={item.id}
                              className={
                                errors.customerCategory &&
                                errors.customerCategory.find(
                                  (el) => el.id === i
                                )
                                  ? "error-input-z"
                                  : null
                              }
                              name={item.category}
                              value={item.category}
                            >
                              {item.category}
                            </option>
                          ))}
                        </select>
                        {errors.customerCategory &&
                          errors.customerCategory.find((el) => el.id === i) && (
                            <ErrorMessage message={"Invalid entry"} />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>Category Description</label>
                        <input
                          type="text"
                          disabled
                          className={
                            errors.customerName &&
                            errors.customerName.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          name="customerName"
                          value={item.customerName}
                          onChange={(e) =>
                            handleCustomerRestrictionChange(e, i)
                          }
                        />
                        {errors.customerName &&
                          errors.customerName.find((el) => el.id === i) && (
                            <ErrorMessage message={"Field is required"} />
                          )}
                      </div>
                      <button onClick={() => removeCustomerRestriction(i)}>
                        Delete
                      </button>
                    </div>
                  ))}
                  <div className="flex--3 button-add-cont">
                    <button
                      className="button-add"
                      onClick={addCustomerRestriction}
                    >
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="loan-contribution-product">
              <h4>Loan Contribution Product</h4>
              <div className="sub--section">
                <div className="button--input flex--3">
                  {loanContributionProduct.map((item, i) => (
                    <div className="gapper flex--1" key={i}>
                      <div className="flex--3">
                        <label>Account Products</label>
                        <select
                          name="loanProductId"
                          value={item.loanProductId}
                          onChange={(e) => handleLoanContributionProduct(e, i)}
                        >
                          <option value="Select an account product">
                            Select an account product
                          </option>
                          {accountProducts.map((newItem, i) => (
                            <option
                              key={newItem.id}
                              className={
                                errors.loanProductId &&
                                errors.loanProductId.find((el) => el.id === i)
                                  ? "error-input-z"
                                  : null
                              }
                              name={newItem.id}
                              value={newItem.id}
                            >
                              {newItem.productName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex--1 label--container">
                        <div className="flex--3 label--input">
                          <label>Product Code</label>
                          <input
                            type="text"
                            className={
                              errors.productCode &&
                              errors.productCode.find((el) => el.id === i)
                                ? "error-input-z"
                                : null
                            }
                            name="productCode"
                            value={item.productCode}
                            onChange={(e) =>
                              handleLoanContributionProduct(e, i)
                            }
                          />
                          {errors.productCode &&
                            errors.productCode.find((el) => el.id === i) && (
                              <ErrorMessage message={"Field is required"} />
                            )}
                        </div>
                      </div>
                      <button onClick={() => removeLoanContributionProduct(i)}>
                        Delete
                      </button>
                    </div>
                  ))}

                  <div className="flex--3 button-add-cont">
                    <button
                      className="button-add"
                      onClick={addLoanContributionProduct}
                    >
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="flex--1 save section--3" id="button--cont">
            <button className="button--add" onClick={() => setScreenChange(3)}>
              Previous
            </button>
            <button className="button--add" id="button" onClick={handleSubmit}>
              Save
            </button>
          </section>
        </main>
      )}
      <main className="next-section--3">
        <section className="section--4"></section>
      </main>
      {!loading && (
        <Table
          title="Loan Products"
          subtitle=""
          name="List of Loan Products"
          records={data}
          options={options}
          columns={columns}
        />
      )}

      {details && details.id && <Details details={details} />}
      {editDetails && editDetails.id && (
        <EditForm
          details={editDetails}
          errorEdit={editErrors}
          handleDetailsUpdateSubmit={handleDetailsUpdateSubmit}
          handleEditDetailsChange={handleEditDetailsChange}
          handleEditLoanProductChange={handleEditLoanProductChange}
          handleEditOverdueDayChange={handleEditOverdueDayChange}
          handleEditRepaymentOrderChange={handleEditRepaymentOrderChange}
          handleEditCustomerRestrictionChange={
            handleEditCustomerRestrictionChange
          }
          allCurrencyDefinition={allCurrencyDefinition}
          setEditDetails={setEditDetails}
          allGlAccount={allGlAccount}
          errors={errors}
          loanStatus={loanStatus}
          cusCategory={cusCategory}
          loanProductCharge={editloanProductCharge}
          overdueDays={editOverdueDays}
          repaymentOrder={editRepaymentOrder}
          customerRestriction={editCustomerRestriction}
          errorsOfOtherServicesOtherthanProductDetails={
            errorsOfOtherServicesOtherthanProductDetails
          }
          handleSubmitEditCharges={handleSubmitEditLoanServices}
          setEditOverdueDays={setEditOverdueDays}
          updateAddOverDueDays={updateAddOverDueDays}
          handleOverdueDayChange={handleOverdueDayChange}
        />
      )}
    </div>
  );
};
export default LoanProducts;

import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import MenuItem from "@material-ui/core/MenuItem";

import { formattedDate } from "../../../../formatter/date";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import {
  createLoanProducts,
  getAllLoanProducts,
  updateLoanProducts,
  deleteLoanProducts,
  authorizeLoanProducts,
  putHandler,
} from "../../../../Api/Hooks-manager/products/products";
import { Routes, baseUrl } from "../../../../Api/configUrl";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { addMultipleOverdueDays } from "../../../../Api/Hooks-manager/products/products";
import {
  getBankParameterByName,
  getAllCustomerCategory,
  getAllGlAccount,
  getAllCurrencyDefinition,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import axios from "axios";
import CustomizedSnackbars from "../../../../Confirmbox/Snackbar";

export default function BussinessLogic() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [editloanProductCharge, setEditloanProductCharge] = useState([]);
  const [editOverdueDays, setEditOverdueDays] = useState([]);
  const [editRepaymentOrder, setEditRepaymentOrder] = useState([]);
  const [editCustomerRestriction, setEditCustomerRestriction] = useState([]);
  const [editLoanContributionProduct, setEditLoanContributionProduct] =
    useState([]);
  const [details, setDetails] = useState({});
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [screenChange, setScreenChange] = useState(1);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [takeAction, setTakeAction] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [message, setMessage] = useState("");
  const [allGlAccount, setAllGlAccount] = useState([]);
  const [childAccounts, setChildAccounts] = useState([]);
  const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
  const [cusCategory, setCategory] = useState([]);
  const [accountProducts, setAccountProducts] = useState([]);
  const [loanStatus, setLoanStatus] = useState([]);
  const [allLoanProducts, setAllLoanProducts] = useState([]);
  const [errors, setErrors] = useState({});
  const [openView, setOpenView] = useState(false);
  const [view, setView] = useState({});
  const [openBoxAuthorize, setOpenAuthorize] = useState(false);
  const [openDecision, setOpenDecision] = useState(false);
  const [interestCalc, setInterestCalc] = useState(false);
  const [equitySelectType, setEquitySelectType] = useState(false);
  const [updateAddOverDueDays, setUpdateAddOverDueDays] = useState("addBulk");
  const color = (status) => {
    if (status === "Authorized") {
      return "green";
    } else if (status === "Rejected") {
      return "red";
    } else {
      return "rgb(224, 148, 33)";
    }
  };

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productCode",
      label: "Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productName",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "repaymentFrequency",
      label: "Repayment Frequency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "rateBasis",
      label: "Rate Basis",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color: color(status),
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                productDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllLoanProducts = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllLoanProducts(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  const [authorizeLoan, setAuthorizeLoan] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });
  const [restriction, setRestriction] = useState("");
  const [product, setProducts] = useState({
    productName: "",
    productCode: "",
    productStatus: true,
    currency: "",
    defaultPrincipal: 0,
    minimumPrincipal: "",
    maximumPrincipal: "",
    defaultTenor: 0,
    maximumTenor: "",
    minimumTenor: "",
    tenorBasis: "",
    rateType: "",
    defaultInterestRate: "",
    maximumInterestRate: "",
    minimumInterestRate: "",
    rateBasis: "",
    maximumVariance: 0,
    minimumVariance: 0,
    repaymentFrequency: "",
    interestCalculationMethod: "",
    amortizationType: "",
    interestPeriod: "",
    liquidationType: "",
    accrualRequired: true,
    partialDisbursementAllowed: false,
    guarantorRequired: true,
    accrualPeriod: "",
    disbursementMethod: "",
    loanProductCharge: [
      {
        chargeName: "",
        applyChargesAt: "",
        chargeLedgerAccount: "",
        typeOfCharge: "",
        amount: "",
        rate: "",
        chargeCurrency: "",
      },
    ],
    statusRuleApplication: "",
    debitInterestVATRate: 0, //this was added by Tobi
    overdueDays: [
      {
        from: 0,
        to: 0,
        loanStatus: "",
      },
    ],
    repaymentOrder: [
      {
        sequenceId: 1,
        repaymentType: "",
      },
    ],
    loanAccount: "",
    interestAccount: "",
    interestReceivableAccount: "",
    penalInterestAccount: "",
    loanWriteOff: "",
    customerRestriction: [
      {
        restrictionType: "", //remove this
        customerCategory: "",
        customerName: "",
      },
    ],
    bulletpayment: false,
    loanContributionProduct: [
      {
        loanProductId: 0,
        productCode: "",
      },
    ],
    equityMultiple: 0,
    adminFee: 0,
    loanBelowHundredFee: 0,
    loanAboveHundredFee: 0,
  });

  const [editDetails, setEditDetails] = useState({});
  const [showEditDetails, setShowEditDetails] = useState(false);

  const handleEditDetailsChange = (e) => {
    const { name, value, checked } = e.target;
    setEditDetails({ ...editDetails, [name]: value });
    if (
      name === "defaultPrincipal" ||
      name === "minimumPrincipal" ||
      name === "maximumPrincipal" ||
      name === "defaultTenor" ||
      name === "maximumTenor" ||
      name === "minimumTenor" ||
      name === "maximumVariance" ||
      name === "defaultInterestRate" ||
      name === "maximumInterestRate" ||
      name === "minimumInterestRate"
    ) {
      const inputedVal =
        value === "" ? "" : Number(value) < 0 ? 0 : Number(value);
      setEditDetails({
        ...editDetails,
        [name]: inputedVal,
      });
    }
    if (
      name === "accrualRequired" ||
      name === "partialDisbursementAllowed" ||
      name === "guarantorRequired"
    ) {
      setEditDetails({ ...editDetails, [name]: checked });
    }

    if (name === "bulletpayment") {
      setEditDetails({ ...editDetails, [name]: checked });
    }
  };

  const productDetails = (el) => {
    const str = el.repaymentFrequency.toLowerCase();
    const repaymentFrequency = () => `${str[0].toUpperCase()}${str.slice(1)}`;
    setEditDetails({
      id: el.id,
      productStatus: el.productStatus,
      productCode: el.productCode,
      productName: el.productName,
      defaultPrincipal: el.defaultPrincipal,
      minimumPrincipal: el.minimumPrincipal,
      maximumPrincipal: el.maximumPrincipal,
      defaultTenor: el.defaultTenor,
      maximumTenor: el.maximumTenor,
      minimumTenor: el.minimumTenor,
      tenorBasis: el.tenorBasis,
      rateType: el.rateType,
      currency: el.currency,
      defaultInterestRate: el.defaultInterestRate,
      maximumInterestRate: el.maximumInterestRate,
      minimumInterestRate: el.minimumInterestRate,
      rateBasis: el.rateBasis,
      maximumVariance: el.maximumVariance,
      minimumVariance: el.minimumVariance,
      repaymentFrequency: repaymentFrequency(),
      interestCalculationMethod: el.interestCalculationMethod,
      amortizationType: el.amortizationType,
      interestPeriod: el.interestPeriod,
      liquidationType: el.liquidationType,
      accrualRequired: el.accrualRequired,
      partialDisbursementAllowed: el.partialDisbursementAllowed,
      guarantorRequired: el.guarantorRequired,
      accrualPeriod: el.accrualPeriod,
      disbursementMethod: el.disbursementMethod,
      statusRuleApplication: el.statusRuleApplication,
      debitInterestVATRate: el.debitInterestVATRate,
      loanAccount: el.loanAccount,
      interestAccount: el.interestAccount,
      interestReceivableAccount: el.interestReceivableAccount,
      penalInterestAccount: el.penalInterestAccount,
      loanWriteOff: el.loanWriteOff,
      bulletpayment: el.bulletpayment,
      equityMultiple: el.equityMultiple,
      adminFee: el.adminFee,
      loanBelowHundredFee: el.loanBelowHundredFee,
      loanAboveHundredFee: el.loanAboveHundredFee,
    });
    setEditloanProductCharge(
      el.loanProductCharge.map((item) => {
        return {
          id: item.id,
          chargeName: item.chargeName.replace(/\s/g, ""),
          applyChargeAt: item.applyChargeAt.replace(/\s/g, ""),
          typeOfCharge: item.typeOfCharge.replace(/\s/g, ""),
          amount: item.amount,
          rate: item.rate,
          chargeCurrency: item.chargeCurrency,
        };
      })
    );
    setEditOverdueDays(
      el.overdueDays.map((item) => {
        return {
          id: item.id,
          from: item.from,
          to: item.to,
          loanStatus: item.loanStatus,
        };
      })
    );
    setEditRepaymentOrder(
      el.repaymentOrder.map((item) => {
        return {
          id: item.id,
          sequenceId: item.sequenceId,
          repaymentType: item.repaymentType,
        };
      })
    );
    setEditCustomerRestriction(
      el.customerRestriction.map((item) => {
        return {
          id: item.id,
          restrictionType: item.restrictionType,
          customerCategory: item.customerCategory,
          customerName: item.customerName,
        };
      })
    );
    // setEditLoanContributionProduct(
    //   el.loanContributionProduct.map((item) => {
    //     return {
    //       id: item.id,
    //       loanProductId: item.loanProductId,
    //       productCode: item.productCode,
    //     };
    //   })
    // );
  };


  const postEditDetails = async () => {
    dispatch(isLoading());
    const response = await updateLoanProducts(
      token,
      editDetails,
      editDetails.id
    );
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      return true;
    }
  };

  const validateUpdateDetails = () => {
    const errors = {};
    const {
      productName,
      productCode,
      currency,
      defaultPrincipal,
      minimumPrincipal,
      maximumPrincipal,
      defaultTenor,
      maximumTenor,
      minimumTenor,
      tenorBasis,
      rateType,
      defaultInterestRate,
      maximumInterestRate,
      minimumInterestRate,
      rateBasis,
      maximumVariance,
      minimumVariance,
      repaymentFrequency,
      interestCalculationMethod,
      amortizationType,
      interestPeriod,
      liquidationType,
      accrualPeriod,
      disbursementMethod,
      equityMultiple,
      adminFee,
    } = editDetails;

    if (productName === "") {
      errors.productName = "product name can not be empty";
    }
    if (productName > 50) {
      errors.productName = "product name can not exceed 50 character";
    }
    if (productCode.length < 6 || productCode.length > 6) {
      errors.productCode = "six characters is required";
    }
    if (currency === "") {
      errors.currency = "Field is required";
    }
    if (minimumPrincipal === "") {
      errors.minimumPrincipal = "Field is required";
    }
    if (Number(maximumPrincipal) < Number(minimumPrincipal)) {
      errors.maximumPrincipal =
        "maximum principlal cannot be less than minimum principlal";
    }
    if (Number(defaultPrincipal) < Number(minimumPrincipal)) {
      errors.minimumPrincipal =
        "minimum principal should not be greater than the default principal";
    }
    if (Number(maximumPrincipal) < Number(defaultPrincipal)) {
      errors.maximumPrincipal =
        "maximum principlal cannot be less than default Principal";
    }
    if (maximumPrincipal === "") {
      errors.maximumPrincipal = "Field is required";
    }
    if (minimumTenor === "") {
      errors.minimumTenor = "Field is required";
    }
    if (minimumTenor > maximumTenor) {
      errors.maximumTenor = "maximum tenor cannot be less than minimum tenor";
    }
    if (maximumTenor === "") {
      errors.maximumTenor = "Field is required";
    }
    if (minimumTenor > defaultTenor) {
      errors.minimumTenor =
        "minimum tenor cannot be greater than default tenor";
    }
    if (maximumTenor < defaultTenor) {
      errors.maximumTenor = "maximium tenor cannot be less than default tenor";
    }
    if (tenorBasis === "") {
      errors.tenorBasis = "Invalid entry";
    }
    if (rateType === "" || rateType === "Select rate type") {
      errors.rateType = "Invalid entry";
    }
    if (defaultInterestRate === "") {
      errors.defaultInterestRate = "Field is required";
    }
    if (minimumInterestRate === "") {
      errors.minimumInterestRate = "Field is required";
    }
    if (maximumInterestRate < minimumInterestRate) {
      errors.maximumInterestRate =
        "maximum interest rate cannot be less than minimum interest rate";
    }
    if (maximumInterestRate < defaultInterestRate) {
      errors.maximumInterestRate =
        "maximum interest rate cannot be less than default interest rate";
    }
    if (minimumInterestRate > defaultInterestRate) {
      errors.minimumInterestRate =
        "minimum interest rate cannot be greater than default interest rate";
    }
    if (maximumInterestRate === "") {
      errors.maximumInterestRate = "Field is required";
    }
    if (rateBasis === "") {
      errors.rateBasis = "Invalid entry";
    }
    if (maximumVariance === "" && rateType === "Floating") {
      errors.maximumVariance = "Field is required";
    }
    if (minimumVariance === "" && rateType === "Floating") {
      errors.minimumVariance = "Field is required";
    }
    if (maximumVariance < minimumVariance) {
      errors.maximumVariance =
        "maximum variance cannot be less than minimum variance";
    }
    if (repaymentFrequency === "") {
      errors.repaymentFrequency = "Invalid entry";
    }
    if (interestCalculationMethod === "") {
      errors.interestCalculationMethod = "Invalid entry";
    }
    if (amortizationType === "") {
      errors.amortizationType = "Invalid entry";
    }
    if (
      interestCalculationMethod === "DecliningBalance" &&
      amortizationType === "EqualPrincipal"
    ) {
      errors.amortizationType = "Entry can not be Equal Principal";
    }
    if (interestPeriod === "") {
      errors.interestPeriod = "Invalid entry";
    }
    if (liquidationType === "") {
      errors.liquidationType = "Invalid entry";
    }
    if (accrualPeriod === "") {
      errors.accrualPeriod = "Invalid entry";
    }
    if (disbursementMethod === "") {
      errors.disbursementMethod = "Invalid entry";
    }
    if (equityMultiple === "") {
      errors.equityMultiple = "Field is required";
    }
    if (adminFee === "") {
      errors.adminFee = "Field is required";
    }
    return errors;
  };

  const [openDetailsUpdateModal, setOpenDetailsUpdateModal] = useState(false);
  const [editErrors, setEditErrors] = useState({});

  const handleDetailsUpdateSubmit = () => {
    const checkErrors = validateUpdateDetails();
    setEditErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    dispatch(openResquestDecisionModal());
  };

  const searchForExistingProductCode = (value) => {
    const list = [...allLoanProducts];
    const existingCode = list.filter((item) => item.productCode === value);
    if (existingCode && existingCode.length > 0) {
      setErrors({ ...errors, productCode: "Product code already exist" });
      return true;
    } else {
      setErrors({ ...errors, productCode: "" });
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    console.log(name, value, checked, "justttttttt");
    setProducts({ ...product, [name]: value });
    if (name === "interestCalculationMethod") {
      setInterestCalc(value);
    }
    if (name === "addBulk") {
      setUpdateAddOverDueDays(value);
    }
    if (
      name === "defaultPrincipal" ||
      name === "minimumPrincipal" ||
      name === "maximumPrincipal" ||
      name === "defaultTenor" ||
      name === "maximumTenor" ||
      name === "minimumTenor" ||
      name === "maximumVariance" ||
      name === "defaultInterestRate" ||
      name === "maximumInterestRate" ||
      name === "minimumInterestRate"
    ) {
      const inputedVal =
        value === "" ? "" : Number(value) < 0 ? 0 : Number(value);
      setProducts({
        ...product,
        [name]: inputedVal,
      });
    }
    if (
      name === "accrualRequired" ||
      name === "partialDisbursementAllowed" ||
      name === "guarantorRequired" ||
      name === "bulletpayment"
    ) {
      setProducts({ ...product, [name]: checked });
    }
    if (name === "productCode") {
      searchForExistingProductCode(value);
    }
  };

  const handleLoanProductChange = (e, index, type, formattedVal) => {
    const loanProductList = [...product.loanProductCharge];
    if (!type && !formattedVal) {
      const { name, value } = e.target;
      loanProductList[index][name] =
        name === "amount" || name === "rate"
          ? value === ""
            ? ""
            : parseFloat(value) < 0
            ? 0
            : parseFloat(value)
          : value;
      setProducts({ ...product, loanProductCharge: loanProductList });
      if (
        loanProductList[index]["typeOfCharge"] === "FlatAmount" &&
        loanProductList[index]["amount"] !== ""
      ) {
        loanProductList[index]["rate"] = 0;
      }
      if (
        loanProductList[index]["typeOfCharge"] === "Rate" &&
        loanProductList[index]["rate"] !== ""
      ) {
        loanProductList[index]["amount"] = 0;
      }
    } else {
      loanProductList[index][type] = parseInt(formattedVal);
      loanProductList[index]["rate"] = 0;
      setProducts({ ...product, loanProductCharge: loanProductList });
    }
  };

  const handleEditLoanProductChange = (e, index, type, formattedVal) => {
    const loanProductList = [...editloanProductCharge];
    if (!type && !formattedVal) {
      const { name, value } = e.target;
      loanProductList[index][name] =
        name === "amount" || name === "rate"
          ? value === ""
            ? ""
            : parseFloat(value) < 0
            ? 0
            : parseFloat(value)
          : value;
      setEditloanProductCharge(loanProductList);
      if (
        loanProductList[index]["typeOfCharge"] === "FlatAmount" &&
        loanProductList[index]["amount"] !== ""
      ) {
        loanProductList[index]["rate"] = 0;
      }
      if (
        loanProductList[index]["typeOfCharge"] === "Rate" &&
        loanProductList[index]["rate"] !== ""
      ) {
        loanProductList[index]["amount"] = 0;
      }
    } else {
      loanProductList[index][type] = parseInt(formattedVal);
      loanProductList[index]["rate"] = 0;
      setEditloanProductCharge(loanProductList);
    }
  };

  const handleOverdueDayChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...product.overdueDays];
    list[index][name] =
      name === "from" || name === "to"
        ? value === ""
          ? ""
          : parseInt(value) < 0
          ? 0
          : parseInt(value)
        : value;
    setProducts({ ...product, overdueDays: list });
  };

  const handleEditOverdueDayChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...editOverdueDays];
    list[index][name] =
      name === "from" || name === "to"
        ? value === ""
          ? ""
          : parseInt(value) < 0
          ? value
          : parseInt(value)
        : value;
    setEditOverdueDays(list);
  };

  const handleRepaymentOrderChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...product.repaymentOrder];
    list[index][name] = value;
    setProducts({ ...product, repaymentOrder: list });
  };

  const handleEditRepaymentOrderChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...editRepaymentOrder];
    list[index][name] = value;
    setEditRepaymentOrder(list);
  };


  const handleEditLoanContributionProduct = (e, index) => {
    const { name, value } = e.target;
    const list = [...product.editLoanContributionProduct];
    list[index][name] = value;
    setEditLoanContributionProduct({
      ...product,
      editLoanContributionProduct: list,
    });
  };

  const findAccountProductCode = (productInfo) => {
    const returnedList = accountProducts.find((item) => item.id === parseInt(productInfo));
    return returnedList;
  };

  const handleLoanContributionProduct = (e, index) => {
    const { name, value } = e.target;
    console.log(value, "value...")
    const list = [...product.loanContributionProduct];
    list[index][name] = value;
    setProducts({ ...product, loanContributionProduct: list });
    if (list[index]["loanProductId"] !== "") {
      list[index]["productCode"] = findAccountProductCode(value)
        ? findAccountProductCode(value).productCode
        : "";
    }
  };

  const findCategoryDescription = (category) => {
    const list = [...cusCategory];
    const returnedList = list.filter((item) => item.category === category);
    console.log(returnedList, "returnedList");
    return returnedList[0];
  };

  const handleCustomerRestrictionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...product.customerRestriction];
    list[index][name] = value;
    setProducts({ ...product, customerRestriction: list });
    if (list[index]["customerCategory"] !== "") {
      list[index]["customerName"] = findCategoryDescription(value)
        ? findCategoryDescription(value).name
        : "";
    }
  };

  const handleEditCustomerRestrictionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...editCustomerRestriction];
    list[index][name] = value;
    setEditCustomerRestriction(list);
    if (list[index]["customerCategory"] !== "") {
      list[index]["customerName"] = findCategoryDescription(value)
        ? findCategoryDescription(value).name
        : "";
    }
  };

  const addLoanProductCharges = () => {
    const list = [...product.loanProductCharge];
    if (list.length > 2) return;
    list.push({
      chargeName: "",
      applyChargesAt: "",
      chargeLedgerAccount: "",
      typeOfCharge: 0,
      amount: "",
      rate: "",
      chargeCurrency: "",
    });
    setProducts({
      ...product,
      loanProductCharge: list,
    });
  };

  const removeLoanProductCharges = (index) => {
    const list = [...product.loanProductCharge];
    if (list.length < 2) return;
    list.splice(index, 1);
    setProducts({
      ...product,
      loanProductCharge: list,
    });
  };

  const addOverdueDays = () => {
    const list = [...product.overdueDays];
    list.push({
      from: 0,
      to: 0,
      loanStatus: "",
    });
    setProducts({
      ...product,
      overdueDays: list,
    });
  };

  const removeOverdueDays = (index) => {
    const list = [...product.overdueDays];
    if (list.length < 2) return;
    list.splice(index, 1);
    setProducts({
      ...product,
      overdueDays: list,
    });
  };

  const addRepaymentOrder = () => {
    const list = [...product.repaymentOrder];
    list.push({
      sequenceId: list.length + 1,
      repaymentType: "",
    });
    setProducts({
      ...product,
      repaymentOrder: list,
    });
  };

  const removeRepaymentOrder = (index) => {
    const list = [...product.repaymentOrder];
    if (list.length < 2) return;
    list.splice(index, 1);
    setProducts({
      ...product,
      repaymentOrder: list,
    });
  };

  const addCustomerRestriction = () => {
    const list = [...product.customerRestriction];
    if (list.length > 2) return;
    list.push({
      restrictionType: "",
      customerCategory: "",
      customerName: "",
    });
    setProducts({
      ...product,
      customerRestriction: list,
    });
  };

  const removeCustomerRestriction = (index) => {
    const list = [...product.customerRestriction];
    if (list.length < 2) return;
    list.splice(index, 1);
    setProducts({
      ...product,
      customerRestriction: list,
    });
  };

  const addLoanContributionProduct = () => {
    const list = [...product.loanContributionProduct];
    if (list.length > 2) return;
    list.push({
      loanProductId: "",
      productCode: "",
    });
    setProducts({
      ...product,
      loanContributionProduct: list,
    });
  };

  const removeLoanContributionProduct = (index) => {
    const list = [...product.loanContributionProduct];
    if (list.length < 2) return;
    list.splice(index, 1);
    setProducts({
      ...product,
      loanContributionProduct: list,
    });
  };

  const validateFirstScreen = () => {
    const errors = {};
    const {
      productName,
      productCode,
      currency,
      defaultPrincipal,
      minimumPrincipal,
      maximumPrincipal,
      defaultTenor,
      maximumTenor,
      minimumTenor,
      tenorBasis,
      rateType,
      defaultInterestRate,
      maximumInterestRate,
      minimumInterestRate,
      rateBasis,
      maximumVariance,
      minimumVariance,
    } = product;

    if (productName === "") {
      errors.productName = "product name can not be empty";
    }
    if (productName > 50) {
      errors.productName = "product name can not exceed 50 character";
    }
    if (productCode.length < 6 || productCode.length > 6) {
      errors.productCode = "six characters is required";
    }
    if (searchForExistingProductCode(productCode)) {
      errors.productCode = "Product code already exist";
    }
    if (currency === "") {
      errors.currency = "Field is required";
    }
    if (minimumPrincipal === "") {
      errors.minimumPrincipal = "Field is required";
    }
    if (maximumPrincipal < minimumPrincipal) {
      errors.maximumPrincipal =
        "maximum principlal cannot be less than minimum principlal";
    }
    if (defaultPrincipal < minimumPrincipal) {
      errors.minimumPrincipal =
        "minimum principal should not be greater than the default principal";
    }
    if (maximumPrincipal < defaultPrincipal) {
      errors.maximumPrincipal =
        "maximum principlal cannot be less than default Principal";
    }
    if (maximumPrincipal === "") {
      errors.maximumPrincipal = "Field is required";
    }
    if (minimumTenor === "") {
      errors.minimumTenor = "Field is required";
    }
    if (minimumTenor > maximumTenor) {
      errors.maximumTenor = "maximum tenor cannot be less than minimum tenor";
    }
    if (maximumTenor === "") {
      errors.maximumTenor = "Field is required";
    }
    if (minimumTenor > defaultTenor) {
      errors.minimumTenor =
        "minimum tenor cannot be greater than default tenor";
    }
    if (maximumTenor < defaultTenor) {
      errors.maximumTenor = "maximium tenor cannot be less than default tenor";
    }
    if (tenorBasis === "") {
      errors.tenorBasis = "Invalid entry";
    }
    if (rateType === "" || rateType === "Select rate type") {
      errors.rateType = "Invalid entry";
    }
    if (defaultInterestRate === "") {
      errors.defaultInterestRate = "Field is required";
    }
    if (minimumInterestRate === "") {
      errors.minimumInterestRate = "Field is required";
    }
    if (maximumInterestRate < minimumInterestRate) {
      errors.maximumInterestRate =
        "maximum interest rate cannot be less than minimum interest rate";
    }
    if (maximumInterestRate < defaultInterestRate) {
      errors.maximumInterestRate =
        "maximum interest rate cannot be less than default interest rate";
    }
    if (minimumInterestRate > defaultInterestRate) {
      errors.minimumInterestRate =
        "minimum interest rate cannot be greater than default interest rate";
    }
    if (maximumInterestRate === "") {
      errors.maximumInterestRate = "Field is required";
    }
    if (rateBasis === "") {
      errors.rateBasis = "Invalid entry";
    }
    if (maximumVariance === "" && rateType === "Floating") {
      errors.maximumVariance = "Field is required";
    }
    if (minimumVariance === "" && rateType === "Floating") {
      errors.minimumVariance = "Field is required";
    }
    if (maximumVariance < minimumVariance) {
      errors.maximumVariance =
        "maximum variance cannot be less than minimum variance";
    }
    return errors;
  };

  const valiadateSecondScreen = () => {
    const errors = {
      chargeName: [],
      applyChargesAt: [],
      chargeLedgerAccount: [],
      typeOfCharge: [],
      amount: [],
      rate: [],
      chargeCurrency: [],
    };
    const {
      repaymentFrequency,
      interestCalculationMethod,
      amortizationType,
      interestPeriod,
      liquidationType,
      accrualPeriod,
      disbursementMethod,
      loanProductCharge,
    } = product;

    if (repaymentFrequency === "") {
      errors.repaymentFrequency = "Invalid entry";
    }
    if (interestCalculationMethod === "") {
      errors.interestCalculationMethod = "Invalid entry";
    }
    if ( interestCalculationMethod === "DecliningBalance" && amortizationType === "") {
      errors.amortizationType = "Invalid entry";
    }
    if (
      interestCalculationMethod === "DecliningBalance" &&
      amortizationType === "EqualPrincipal"
    ) {
      errors.amortizationType = "Entry can not be Equal Principal";
    }
    if (interestPeriod === "") {
      errors.interestPeriod = "Invalid entry";
    }
    if (liquidationType === "") {
      errors.liquidationType = "Invalid entry";
    }
    if (accrualPeriod === "") {
      errors.accrualPeriod = "Invalid entry";
    }
    if (disbursementMethod === "") {
      errors.disbursementMethod = "Invalid entry";
    }

    for (let i = 0; i < loanProductCharge.length; i++) {
      if (loanProductCharge[i].chargeName === "") {
        errors.chargeName.push({
          id: i,
        });
      }
      if (loanProductCharge[i].applyChargesAt === "") {
        errors.applyChargesAt.push({
          id: i,
        });
      }
      if (loanProductCharge[i].chargeLedgerAccount === "") {
        errors.chargeLedgerAccount.push({
          id: i,
        });
      }
      if (loanProductCharge[i].typeOfCharge === "") {
        errors.typeOfCharge.push({
          id: i,
        });
      }
      if (
        loanProductCharge[i].typeOfCharge === "FlatAmount" &&
        loanProductCharge[i].amount === ""
      ) {
        errors.amount.push({
          id: i,
        });
      }
      if (
        loanProductCharge[i].typeOfCharge === "Rate" &&
        loanProductCharge[i].rate === ""
      ) {
        errors.rate.push({
          id: i,
        });
      }
      if (loanProductCharge[i].chargeCurrency === "") {
        errors.chargeCurrency.push({
          id: i,
        });
      }
    }

    if (!errors.chargeName.length) delete errors.chargeName;
    if (!errors.applyChargesAt.length) delete errors.applyChargesAt;
    if (!errors.chargeLedgerAccount.length) delete errors.chargeLedgerAccount;
    if (!errors.typeOfCharge.length) delete errors.typeOfCharge;
    if (!errors.amount.length) delete errors.amount;
    if (!errors.rate.length) delete errors.rate;
    if (!errors.chargeCurrency.length) delete errors.chargeCurrency;

    return errors;
  };

  const [service, setService] = useState("");
  const [dataOfEachItem, setDataOfEachItem] = useState(null);
  const [url, setUrl] = useState("");
  const [
    errorsOfOtherServicesOtherthanProductDetails,
    setErrorsOfOtherServicesOtherthanProductDetails,
  ] = useState({});

  const validateEditloanServices = (data, type, index) => {
    if (type === "charges" && data.chargeName === "") {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "chargeName",
      });
      return;
    }
    if (type === "charges" && data.applyChargesAt === "") {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "applyChargesAt",
      });
      return;
    }
    if (type === "charges" && data.chargeLedgerAccount === "") {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "chargeLedgerAccount",
      });
      return;
    }
    if (data.typeOfCharge === "") {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "typeOfCharge",
      });
      return;
    }
    if (
      type === "charges" &&
      data.typeOfCharge === "FlatAmount" &&
      data.amount === ""
    ) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "FlatAmount",
      });
      return;
    }
    if (
      type === "charges" &&
      data.typeOfCharge === "Rate" &&
      data.rate === ""
    ) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "Rate",
      });
      return;
    }
    if (type === "charges" && data.chargeCurrency === "") {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Invalid entry",
        index,
        type: "chargeCurrency",
      });
      return;
    }

    if (type === "overdueDays" && !data.from) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "from",
      });
      return;
    }

    if (type === "overdueDays" && data.to === null) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "to",
      });
      return;
    }

    if (type === "overdueDays" && data.to < data.from) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "This field should be greater than the preceding field",
        index,
        type: "to",
      });
      return;
    }

    if (type === "loanStatus" && !data.loanStatus) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "to",
      });
      return;
    }

    if (type === "repayment order" && !data.repaymentType) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "to",
      });
      return;
    }

    if (type === "customer restriction" && !data.customerCategory) {
      setErrorsOfOtherServicesOtherthanProductDetails({
        msg: "Field is required",
        index,
        type: "to",
      });
      return;
    }

    return true;
  };

  const validateThirdScreen = () => {
    const errors = {
      from: [],
      to: [],
      loanStatus: [],
      repaymentType: [],
    };
    const {
      statusRuleApplication,
      debitInterestVATRate,
      overdueDays,
      repaymentOrder,
    } = product;
    if (statusRuleApplication === "") {
      errors.statusRuleApplication = "Invalid entry";
    }

    if (debitInterestVATRate === "") {
      errors.debitInterestVATRate = "field required";
    }

    for (let i = 0; i < overdueDays.length; i++) {
      if (overdueDays[i].from === "") {
        errors.from.push({
          id: i,
        });
      }
      if (overdueDays[i].to === "") {
        errors.to.push({
          id: i,
        });
      }
      if (overdueDays[i].loanStatus === "") {
        errors.loanStatus.push({
          id: i,
        });
      }
    }

    for (let i = 0; i < repaymentOrder.length; i++) {
      if (repaymentOrder[i].repaymentType === "") {
        errors.repaymentType.push({
          id: i,
        });
      }
    }

    if (!errors.from.length) delete errors.from;
    if (!errors.to.length) delete errors.to;
    if (!errors.loanStatus.length) delete errors.loanStatus;
    if (!errors.repaymentType.length) delete errors.repaymentType;

    return errors;
  };

  const validateLastPage = () => {
    const errors = {
      customerName: [],
      customerCategory: [],
      loanProductId: [],
      productCode: [],
      //restrictionType: [],
    };
    const {
      loanAccount,
      interestAccount,
      interestReceivableAccount,
      penalInterestAccount,
      loanWriteOff,
      customerRestriction,
      equityMultiple,
      adminFee,
      loanContributionProduct,
    } = product;
    if (loanAccount === "") {
      errors.loanAccount = "Invalid entry";
    }
    if (interestAccount === "") {
      errors.interestAccount = "Invalid entry";
    }
    if (interestReceivableAccount === "") {
      errors.interestReceivableAccount = "Invalid entry";
    }
    if (penalInterestAccount === "") {
      errors.penalInterestAccount = "Invalid entry";
    }
    if (loanWriteOff === "") {
      errors.loanWriteOff = "Invalid entry";
    }
    if (restriction === "") {
      errors.restrictionType = "Invalid entry";
    }
    if (equityMultiple === "") {
      errors.equityMultiple = "Field is required";
    }
    if (adminFee === "") {
      errors.adminFee = "Field is required";
    }

    for (let i = 0; i < customerRestriction.length; i++) {
      if (customerRestriction[i].customerCategory === "") {
        errors.customerCategory.push({
          id: i,
        });
      }
      if (customerRestriction[i].customerName.length < 3) {
        errors.customerName.push({
          id: i,
        });
      }
    }

    for (let i = 0; i < loanContributionProduct.length; i++) {
      if (loanContributionProduct[i].loanProductid === "") {
        errors.loanProductId.push({
          id: i,
        });
      }
      if (loanContributionProduct[i].productCode === "") {
        errors.productCode.push({
          id: i,
        });
      }
    }

    if (!errors.customerCategory.length) delete errors.customerCategory;
    if (!errors.customerName.length) delete errors.customerName;
    if (!errors.loanProductId.length) delete errors.loanProductId;
    if (!errors.productCode.length) delete errors.productCode;

    return errors;
  };

  const handleFirstScreen = () => {
    const checkErrors = validateFirstScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
    setScreenChange(2);
  };

  const handleSecondScreen = () => {
    const checkErrors = valiadateSecondScreen();
    console.log("checkErrors", checkErrors);
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
    setScreenChange(3);
  };

  const handleThirdScreen = () => {
    const checkErrors = validateThirdScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setScreenChange(4);
  };

  const EmptyFieldsProduct = () => {
    setProducts({
      productName: "",
      productCode: "",
      productStatus: true,
      currency: "",
      defaultPrincipal: 0,
      minimumPrincipal: "",
      maximumPrincipal: "",
      defaultTenor: 0,
      maximumTenor: "",
      minimumTenor: "",
      tenorBasis: "",
      rateType: "",
      defaultInterestRate: "",
      maximumInterestRate: "",
      minimumInterestRate: "",
      rateBasis: "",
      maximumVariance: 0,
      minimumVariance: 0,
      repaymentFrequency: "",
      interestCalculationMethod: "",
      amortizationType: "",
      interestPeriod: "",
      liquidationType: "",
      accrualRequired: true,
      partialDisbursementAllowed: true,
      guarantorRequired: true,
      accrualPeriod: "",
      disbursementMethod: "",
      loanProductCharge: [
        {
          chargeName: "",
          applyChargesAt: "",
          chargeLedgerAccount: "",
          typeOfCharge: "",
          amount: "",
          rate: "",
          chargeCurrency: "",
        },
      ],
      statusRuleApplication: "",
      debitInterestVATRate: 0,
      overdueDays: [
        {
          from: 0,
          to: 0,
          loanStatus: "",
        },
      ],
      repaymentOrder: [
        {
          sequenceId: 1,
          repaymentType: "",
        },
      ],
      loanAccount: "",
      interestAccount: "",
      interestReceivableAccount: "",
      penalInterestAccount: "",
      loanWriteOff: "",
      customerRestriction: [
        {
          restrictionType: "",
          customerCategory: "",
          customerName: "",
        },
      ],
      bulletpayment: false,
      loanContributionProduct: [
        {
          loanProductId: 0,
          productCode: "",
        },
      ],
      equityMultiple: 0,
      adminFee: 0,
      loanBelowHundredFee: 0,
      loanAboveHundredFee: 0,
    });
  };

  const postLoanProducts = async () => {
    dispatch(isLoading());
    const response = await createLoanProducts(token, product);
    setMessage(response.message);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        fetchAllLoanProducts();
        EmptyFieldsProduct();
        setScreenChange(1);
        return true;
      } else {
        setErrorMessage(response.message);
        return false;
      }
    }
  };

  const fetchAllGlAccount = useCallback(async () => {
    const response = await getAllGlAccount(token);
    if (response && response.data) {
      setAllGlAccount(
        response.data.filter(
          (item) =>
            item.authorizationStatus === "Authorized" &&
            item.glHierarchy === "Child" &&
            item.glType === "Income"
        )
      );
      setChildAccounts(
        response.data
          .filter(
            (item) =>
              item.authorizationStatus === "Authorized" &&
              item.glHierarchy === "Child"
          )
          .map((item) => {
            return {
              value: item.accountNumber,
              label: `${item.accountNumber} - ${item.accountName}`,
            };
          })
      );
    }
  }, [token]);

  const fetchAllCurrencyDefinition = useCallback(async () => {
    const response = await getAllCurrencyDefinition(token);
    if (response && response.data) {
      setAllCurrencyDefinition(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  }, [token]);

  const deleteALoanProduct = async (id) => {
    dispatch(isLoading());
    const response = await deleteLoanProducts(token, id);
    if (response && response.message) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.status) {
        return true;
      } else {
        return false;
      }
    }
  };

  const removeLoanProduct = async () => {
    const lists = [...allLoanProducts];
    const isDeleted = await deleteALoanProduct(loanId);
    if (isDeleted) {
      const undeletedItems = lists.filter((item) => item.id !== loanId);
      setAllLoanProducts(() => undeletedItems);
    }
    return isDeleted;
  };

  const handleSubmit = () => {
    const list = [...product.customerRestriction];
    for (let i = 0; i < list.length; i++) {
      list[i].restrictionType = restriction;
    }
    setProducts({ ...product, customerRestriction: list });
    const checkErrors = validateLastPage();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setOpen(true);
  };

  const [openEditLoanServiceModal, setEditLoanServiceModal] = useState(false);
  const [editServicesMsg, setEditServicesMsg] = useState("");

  const handleSubmitEditLoanServices = (index, typeOfService) => {
    if (
      typeOfService === "charges" &&
      validateEditloanServices(
        editloanProductCharge[index],
        typeOfService,
        index
      )
    ) {
      setErrorsOfOtherServicesOtherthanProductDetails({});
      const item = editloanProductCharge[index];
      setDataOfEachItem(item);
      setService(typeOfService);
      setUrl(`${Routes.updateLoanProductCharges}${item.id}`);
      setEditLoanServiceModal(true);
      return;
    }
    if (
      typeOfService === "overdueDays" &&
      validateEditloanServices(editOverdueDays[index], typeOfService, index)
    ) {
      setErrorsOfOtherServicesOtherthanProductDetails({});
      const item = editOverdueDays[index];
      setDataOfEachItem(item);
      setService(typeOfService);
      setUrl(`${Routes.updateOverDueDays}${item.id}`);
      setEditLoanServiceModal(true);
      return;
    }
    if (
      typeOfService === "repayment order" &&
      validateEditloanServices(editRepaymentOrder[index], typeOfService, index)
    ) {
      setErrorsOfOtherServicesOtherthanProductDetails({});
      const item = editRepaymentOrder[index];
      setDataOfEachItem(item);
      setService(typeOfService);
      setUrl(`${Routes.updateRepaymentOrder}${item.id}`);
      setEditLoanServiceModal(true);
      return;
    }
    if (
      typeOfService === "customer restriction" &&
      validateEditloanServices(
        editCustomerRestriction[index],
        typeOfService,
        index
      )
    ) {
      setErrorsOfOtherServicesOtherthanProductDetails({});
      const item = editCustomerRestriction[index];
      setDataOfEachItem(item);
      setService(typeOfService);
      setUrl(`${Routes.updateCustomerRestriction}${item.id}`);
      setEditLoanServiceModal(true);
      return;
    }
  };

  const postUpdateLoanServices = async () => {
    dispatch(isLoading());
    const response = await putHandler(token, dataOfEachItem, url);
    if (response && response.data) {
      dispatch(isLoading());
      setEditServicesMsg(response.message);
      if (response.status) {
        fetchAllLoanProducts();
        return true;
      } else {
        return false;
      }
    }
  };

  const parameters = "CUS_CATEGORY,Loan_Status";
  const BankParameterByName = useCallback(async () => {
    const response = await getBankParameterByName(token, parameters);
    if (response && response.data.loan_Status) {
      setLoanStatus(response.data.loan_Status);
    }
  }, [token]);

  const fetchAllCustomerCategory = useCallback(async () => {
    const response = await getAllCustomerCategory(token);
    if (response && response.data) {
      setCategory(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  }, [token]);

  const decide = async () => {
    dispatch(isLoading());
    const response = await authorizeLoanProducts(token, authorizeLoan, view.id);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.statusCode === 200) {
        setOpenView(false);
        setOpenAuthorize(false);
        setAuthorizeLoan({
          reason: "",
          isApproved: true,
          authorizationStatus: 0,
        });
        fetchAllLoanProducts();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleChangeSelect = (value, type) => {
    setProducts((product) => ({ ...product, [type]: value }));
  };

  const fetchAllAccountProducts = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}account/AccountProduct/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      // if (response.data.length > 0) {
      //   for (let i = 0; i < response.data.length; i++) {
      //     response.data[i].sn = i + 1;
      //     response.data[i].createdDateTime = formattedDate(
      //       response.data[i].createdDateTime
      //     );
      //     response.data[i].dateAuthorized = formattedDate(
      //       response.data[i].dateAuthorized
      //     );
      //     response.data[i].updatedDateTime = formattedDate(
      //       response.data[i].updatedDateTime
      //     );
      //   }
      // }
      setAccountProducts(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllAccountProducts();
  }, [fetchAllAccountProducts]);

  // const filteredAccountProducts = accountProducts.map((item) => {
  //   return{
  //     value: item.productName,
  //     label: `${item.productName} - ${item.productCode}`,
  //   }
  // })


  return {
    product,
    setProducts,
    restriction,
    setRestriction,
    allGlAccount,
    childAccounts,
    allCurrencyDefinition,
    cusCategory,
    loanStatus,
    open,
    setOpen,
    handleChange,
    handleLoanProductChange,
    handleOverdueDayChange,
    handleRepaymentOrderChange,
    handleCustomerRestrictionChange,
    handleLoanContributionProduct,
    handleChangeSelect,
    addLoanProductCharges,
    addOverdueDays,
    addRepaymentOrder,
    addCustomerRestriction,
    removeCustomerRestriction,
    removeRepaymentOrder,
    removeOverdueDays,
    removeLoanProductCharges,
    removeLoanContributionProduct,
    addLoanContributionProduct,
    handleSubmit,
    postLoanProducts,
    errorMessage,
    message,
    takeAction,
    setTakeAction,
    setLoanId,
    removeLoanProduct,
    errors,
    screenChange,
    setScreenChange,
    handleFirstScreen,
    handleSecondScreen,
    handleThirdScreen,
    openView,
    setOpenView,
    view,
    setView,
    openBoxAuthorize,
    setOpenAuthorize,
    openDecision,
    setOpenDecision,
    decide,
    authorizeLoan,
    setAuthorizeLoan,
    handleEditDetailsChange,
    showEditDetails,
    editDetails,
    setEditDetails,
    setShowEditDetails,
    postEditDetails,
    editErrors,
    handleDetailsUpdateSubmit,
    openDetailsUpdateModal,
    setOpenDetailsUpdateModal,
    fetchAllLoanProducts,
    BankParameterByName,
    fetchAllCustomerCategory,
    fetchAllCurrencyDefinition,
    fetchAllGlAccount,
    updateTable,
    data,
    options,
    columns,
    details,
    editloanProductCharge,
    editOverdueDays,
    editRepaymentOrder,
    editCustomerRestriction,
    editLoanContributionProduct,
    handleEditLoanProductChange,
    handleEditOverdueDayChange,
    handleEditRepaymentOrderChange,
    handleEditCustomerRestrictionChange,
    handleSubmitEditLoanServices,
    handleEditLoanContributionProduct,
    dataOfEachItem,
    service,
    url,
    errorsOfOtherServicesOtherthanProductDetails,
    openEditLoanServiceModal,
    setEditLoanServiceModal,
    postUpdateLoanServices,
    editServicesMsg,
    interestCalc,
    setInterestCalc,
    equitySelectType,
    setEquitySelectType,
    setEditOverdueDays,
    setEditLoanContributionProduct,
    updateAddOverDueDays,
    accountProducts,
  };
}

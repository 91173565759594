import React from 'react'

const BankClearingCodes = () => {
  return (
    <div>
      Authorize BankClearingCodes
    </div>
  )
}

export default BankClearingCodes

import { useState, useEffect, useRef, useCallback } from "react";
import Item from "../../../DatagridTable/item";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Grid, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { formattedDate } from "../../../../formatter/date";

import { downloadUploadedPhoto} from "../../../../Api/Hooks-manager/accountEnquiry/PassportModification";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
    root: {},
    // imageAdjust: {
    //   display: "flex",
    //   justifyContent:"spaceBetween",
    // },
    text: {
      margin: "auto",
      marginTop: "1rem",
      color: theme.palette.secondary.main,
    },
    hr: {
      backgroundColor: "black",
      width: "100%",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    table: {
      width: "100%",
      "& th": {
        paddingTop: 12,
        paddingBottom: 12,
        textAlign: "left",
      },
      "& td": {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      backgroundColor: theme.palette.secondary.main,
    },
    mandate: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "350px",
      width: "350px",
    },
    noData:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"row",
    },
    item:{
      width:'100%',
      border: "1px solid black",
      marginBottom: 30,
      paddingTop: 25,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    }
  }));


  
const AccountDetails = ({details}) => {
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const fileInputRef = useRef();
  const [image, setImage] = useState(null);
  const [sign, setSign] = useState(null);
  const [signatoryPassports, setSignatoryPassports] = useState({});
  const [signatorySignatures, setSignatorySignatures] = useState({});

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(0);

    const customerType = details.customer.customerType;



    const getImages = useCallback(
      async (passportFileName, signatureFileName) => {
        const passportResponse = await downloadUploadedPhoto(token, passportFileName);
        const signatureResponse = await downloadUploadedPhoto(token, signatureFileName);
        console.log("here we have the signature", passportResponse.data === signatureResponse.data);
        console.log("comapring file names", passportFileName === signatureFileName);
        if (signatureResponse.status) {
          // setImage(response.data)
          setSign(`data:image/jpeg;base64,${signatureResponse.data}`);
        } else{
          setSign({sign});
        }
  
        if (passportResponse.status) {
        // setImage(passportResponse.data)
        setImage(`data:image/jpeg;base64,${passportResponse.data}`);
        // setSignatorypassport(``data:image/jpeg;base64,${passportResponse.data}``)
      }else{
        setImage({image})
      }
      },
      [token]
    );


    useEffect(() => {
      getImages(details.customer.passport, details.customer.signature);
    }, [details, getImages]);



    useEffect(() => {
      const images = {}
      const signatures = {}
      details.customer.accountSignatories.forEach(async ({passport, signature}) => {
        if(passport){
          const res = await downloadUploadedPhoto(token, passport)
          images[passport] = `data:image/jpeg;base64,${res.data}`
        }
        if(signature){
          const res = await downloadUploadedPhoto(token, signature)
          signatures[signature] = `data:image/jpeg;base64,${res.data}`
        }
      })
      setSignatoryPassports(images);
      setSignatorySignatures(signatures);
    },[])
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    useEffect(() => {
      setValue(0);
    }, [details]);


    return (
      <section className={classes.root}>
        <div style={{ marginTop: 50, padding: 25 }}>
          <>
            <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Account Intructions"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Account Mandates"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />

              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Instruction-1" name={details.accountInstruction1} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Instruction-2" name={details.accountInstruction2} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Instruction-3" name={details.accountInstruction3} />
                </Grid>
                
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {customerType === 'Individual' ?  (<>
              <Grid container spacing={2} className={classes.imageAdjust}>
                {<Grid item xs={12} sm={6} md={3} lg={3}>
                  <Avatar
                    // onClick={handleClick}
                    alt={
                      details.customer.firstName || details.customer.corporateName
                    }
                    src={image || signatoryPassports}
                    className={classes.large}
                  />
                  <br/>
                  <label><b>Customer Passport</b></label>

                </Grid>}
                {<Grid item xs={12} sm={6} md={9} lg={9}>
                  <img src={sign} alt="Signature/mandate" className={classes.mandate}/>
                  <br/>
                  <label><b>Signature/mandate</b></label>
                </Grid>}
              </Grid>
              </>) : (
                <>
                {details.customer.accountSignatories.map((el, i) => (
                  <Grid container spacing={2} className={classes.signatories} key={i}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Signatory"
                        name={el.customerIsASignatory ? "Yes" : "No"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Title" name={el.title} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Last Name" name={el.lastname} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="First Name" name={el.firstname} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Middle Name" name={el.middlename || "Nil"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Email" name={el.email} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Phone Number" name={el.phonenumber} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Gender" name={el.gender} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Address" name={el.address} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Country" name={el.country} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="State" name={el.state} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="KYC" name={el.kyc} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="BVN" name={el.bvn} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Identification Type" name={el.identificationType} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Identification Number"
                        name={el.identificationNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Create Date Time"
                        name={formattedDate(details.createdDateTime)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Updated Date Time"
                        name={formattedDate(details.updatedDateTime)}
                      />
                    </Grid>
                    {details.dateAuthorized && (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Item
                          label="Date Authorized"
                          name={formattedDate(details.dateAuthorized)}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Status"
                        name={
                          details.authorizationStatus === "PendingEditedAuthorization"
                            ? "Pending Edited Authorization"
                            : details.authorizationStatus
                        }
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {el.passport && signatoryPassports[el.passport] && 
                      (<>
                        <label>Passport</label>
                        <br/>
                        <img src={signatoryPassports[el.passport]} alt="passport" className={classes.mandate}/>
                      </>)}
                    </Grid>
  
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {el.signature && signatorySignatures[el.signature] && 
                      (<>
                        <label>Signature</label>
                        <br/>
                        <img src={signatorySignatures[el.signature]} alt="signature" className={classes.mandate}/>
                      </>)}
                    </Grid>
                  </Grid>
                ))}
                </>
                
              )}
              

          

            </TabPanel>

            </div>
          </>
        </div>
      </section> 

    )
}

export default AccountDetails

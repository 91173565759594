import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Slide from "@material-ui/core/Slide";

import DeleteModal from "./delete";

import {
  closeViewModal,
  openDeleteDecisionModal,
} from "../../Api/redux-manager/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiDialogTitle-root": {
      backgroundColor: "#F89B5F",
    },
    "& .MuiDialog-container": {
      background: "#312E41",
    },
    "& .MuiDialog-paperFullScreen": {
      width: "80%",
    },
    // "& .MuiDialogContent-root":{
    //   display:"flex"
    // }
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    //border:'2px solid green',
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",

    // '& p':{
    //   fontSize:14,
    // },
    // '& h5':{
    //   fontSize:16,
    // }
  },
  item: {
    padding: "0.5rem",
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  bgimg: {
    width: 118,
    height: 92,
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
    textTransform: "none",
  },
}));

const ViewModal = ({
  className,
  title,
  children,
  label,
  btnTitle,
  question,
  url,
  method,
  data,
  ...props
}) => {
  const classes = useStyles();
  const open = useSelector((state) => state.req.view);
  const dispatch = useDispatch();

  return (
    <div>
      <DeleteModal
        question={question}
        url={url}
        method={method}
        data={data}

      />
      <Dialog
        fullScreen={true}
        className={clsx(classes.root, className)}
        open={open}
        onClose={() => dispatch(closeViewModal())}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ background: "#9B0116", color: "#fff" }}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers={props.scroll === "paper"}>
          <Slide in={true}>
            <div
              className={classes.center}
              id="scroll-dialog-description"
              ref={props.descriptionElementRef}
              tabIndex={-1}
            >
              <div>{children}</div>
              <div className={classes.btnContainer}>
                <Button
                  onClick={() => dispatch(closeViewModal())}
                  className={classes.button}
                >
                  Back
                </Button>
                {url && (
                  <Button
                    onClick={() => dispatch(openDeleteDecisionModal())}
                    className={classes.button}
                  >
                    {btnTitle || "Delete"}
                  </Button>
                )}
              </div>
            </div>
          </Slide>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewModal;

import { useState, useEffect, useCallback } from "react";
import "./setNewPassword.scss"
import logo from "../../assets/mainlogo.png";
// import Uhurulogo from '../../assets/uhurulogo.png'
import Nomase from "../../assets/Nomaselogo.png";
import ChangePassword from "../../assets/change-password.png";
import Lady from "../../assets/lady.png";
import useForm from "../../Api/Hooks-manager/useCustom";
import { RiErrorWarningFill } from "react-icons/ri";
import Spinner from "../Backdrop/index";
import NewModal from "../../Confirmbox/Modal";
import {isValidEmail} from '../../Helper/validator'
import {newPasswords} from '../../Api/Hooks-manager/newPassword/newPassword';
import {useParams} from 'react-router-dom'
// import { NavLink } from 'react-router-dom'
// import validate from '../validator/validateLogin'


const SetNewPassword = (props) => {
    console.log('what is in props', props)
  const {
    handleLoginSubmit,
  } = useForm();
  const [screenChange, setScreenChange] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [positiveDialog, setPositiveDialog] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const token = props.match.params.usertoken;
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
    token
  }),
  [responseError, setResponseError] = useState("");

  const handleScreenChange = (i) => {
    console.log("qwswqeq", i);
    setScreenChange(i);
  };

  const isValidateForm = (user) => {

    if (user.password !== user.confirmPassword) {
        setError({
            oldPassword: "", 
        password : "password mis-matched", confirmPassword: "password mis-matched"});
        return false;
      }
      if (user.password.length < 6 ){
        setError({password: "password must have more than five characters", oldPassword: "", confirmPassword: "" })
        return false
    }
    if (user.confirmPassword.length < 6 ){
        setError({confirmPassword: "password must have more than five characters", password: "", oldPassword: "" })
        return false
    }
      setError({ password : "", confirmPassword: ""});
   return true;
}

const handleChange = (name) => (e) => {
    const { value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };  
  
  const SetAllNewPassword = useCallback( async () => {
    setLoading(true);
    let response;
    let noError = isValidateForm(user);
    if (noError ){
        console.log(user, 'password account') 
        
      response = await newPasswords(user);

    }
    console.log('-----I want to---- ', response)

    if(response.status && response.message ==="Success") {
      // console.log("success...", response.message)
      setDialogTitle("Successful");
      setOpenDialog(true);
      setStatus(true);
      setDialogMessage("Please check your mail. We just sent you a link to reset your password ");
      setPositiveDialog(true)
    }
    else{
      setDialogTitle("UnSuccessful");
      setOpenDialog(true)
      setDialogMessage("User reset token incorrect or expired");
      setPositiveDialog(false);
      setStatus(false);
    }
    setLoading(false);

    if(response && response.error !== ''){
        //do error check here
        setResponseError(response.error)

      }
      else{

      }
  // useEffect(() => {
  //   ForgetEmails()
  // }, [ForgetEmails])

  })

  const resetLogin = () =>{
    if(status){
      props.history.push('/')
    }else {
      setOpenDialog(false)
    }
  }

  // const testState = useSelector(state => state)
  // const dispatch = useDispatch();

  return (
    <main className="login flex--2">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />

        <NewModal
          title={dialogTitle}
          openDialog={openDialog}
          message="Please check your mail. A link has been sent to you to reset your password"
          positiveDialog={positiveDialog}
          onClose={resetLogin}
        >
          {dialogMessage}
        </NewModal>
      </div>
      <div className="ovelay">{""}</div>
      <img src={Lady} alt="background-lady" className="background-lady" />
      <section className="section--1 flex--3">
        <img src={logo} alt="" />
        {!screenChange && (
          <>
            <form
              className="flex--3 sub--section"
              noValidate
              onSubmit={handleLoginSubmit}
            >
              <h1>Reset</h1>
              <div className="error-container-z">
                <input
                  alt=""
                  placeholder="Enter New Password"
                  type="password"
                  name="password"
                  onChange={handleChange("password")}
                  className={`${error.password && "error-input-z"}`} 
                  value={user.password}
                />
                {error.password && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{error.password}</small>
                  </div>
                )}
              </div>
              <div className="error-container-z">
                <input
                  alt=""
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmPassword"
                //   autoComplete="on"
                onChange={handleChange("confirmPassword")}
                className={`${error.confirmPassword && "error-input-z"}`}
                  value={user.confirmPassword}
                />
                {error.confirmPassword && (
                  <div className="flex--2 error--p">
                    <RiErrorWarningFill className="icon" />
                    <small>{error.confirmPassword}</small>
                  </div>
                )}
              </div>
              {/* {errorLogin && (
                <div className="flex--2 icon--error">
                  <RiErrorWarningFill className="error--icon" />
                  <p className="error--both">Password mis-match</p>
                </div>
              )} */}
              <button type="submit" className="reset--button" onClick={SetAllNewPassword}>
                Reset
              </button>
              {/* <NavLink to='/dashboard/home' onClick={handleLoginSubmit}>
                                <button>Login</button>
                            </NavLink> */}
            </form>
          </>
        )}

         {/* <img src={Nomase} alt="" className="logo-uhuru" />  */}
      </section>
      <section className="section--2">
        <h1>Welcome Back!</h1>
        <p>Experience Seamless, Swift and Secure Transaction.</p>
      </section>
    </main>
  );
};

export default SetNewPassword;

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';



const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiDialogTitle-root": {
      backgroundColor: "#F89B5F",
    },
    "& .MuiDialog-container": {
      background: "#312E41",
    },
    "& .MuiDialog-paperFullScreen": {
      width: "80%",
    },
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
  },
  item: {
    padding: "0.5rem",
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  bgimg: {
    width: 118,
    height: 92,
  },
  btnContainer:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight:'2rem',
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
}));

const Details = ({
  className,
  title,
  open,
  handleClose,
  edit,
  onScheduleSave,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen={true}
        className={clsx(classes.root, className)}
        open={open}
        onClose={handleClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ background: "#9B0116", color: "#fff" }}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers={props.scroll === "paper"}>
         <Slide in={true}>
         <div
            className={classes.center}
            id="scroll-dialog-description"
            ref={props.descriptionElementRef}
            tabIndex={-1}
          >
            <div>{children}</div>
            <div className={classes.btnContainer}>
            {!edit ? (<>
              <Button onClick={handleClose} className={classes.button}>ok</Button>
              <Button onClick={()=> window.print()} className={classes.button}>print</Button>
            </>)
            :<Button className={classes.button} onClick={onScheduleSave}>Save</Button>
            }
            </div>
          </div>
         </Slide>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Details;

import { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes, baseUrl } from "../../../../Api/configUrl";

import NumberFormat from "react-number-format";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  checkbox: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& input": {
      height: 15,
      borderRadius: 0,
      width: 20,
      marginLeft: 0,
    },
  },
  border: {
    border: "1px solid black",
    marginBottom: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttondiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  item: {
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const EditData = ({
  details,
  handleDetailsUpdateSubmit,
  handleFieldUpdate,
  errorEdit,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);

  //   const displayErr = (i, name) => {
  //     return (
  //       errorsOfOtherServicesOtherthanProductDetails.index === i &&
  //       errorsOfOtherServicesOtherthanProductDetails.type === name
  //     );
  //   };
  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  useEffect(() => {
    setValue(0);
  }, [details]);

  console.log(details, "this is edit details");

  return (
    <EditModal
      title="Account Lien/Block"
      onClick={handleDetailsUpdateSubmit}
      question="Are you sure you want to update the lien of this account?"
      data={details}
      url={`${baseUrl}account/Lien/Update/${details.id}`}
    >
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={12} md={6}>
          <label>Account Number</label>
          <input
            onChange={handleFieldUpdate}
            name="accountNumber"
            disabled
            value={details.accountNumber}
            className={`${
              Object.keys(errorEdit).includes("accountNumber") &&
              "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.accountNumber && (
            <ErrorMessage message={errorEdit.accountNumber} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Lien Code</label>
          <input
            onChange={handleFieldUpdate}
            name="lienCode"
            disabled
            value={details.lienCode}
            className={`${
              Object.keys(errorEdit).includes("lienCode") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.lienCode && <ErrorMessage message={errorEdit.lienCode} />}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <label>Amount</label>
          <NumberFormat
            thousandsGroupStyle="thousand"
            thousandSeparator={true}
            inputMode="numeric"
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale={true}
            onChange={handleFieldUpdate}
            name="amount"
            value={details.amount}
            className={`${
              Object.keys(errorEdit).includes("amount") && "error-input-z"
            }`}
            onValueChange={({ floatValue }) =>
              handleFieldUpdate({
                target: {
                  name: "amount",
                  value: floatValue,
                },
              })
            }
          />
          {errorEdit.amount && <ErrorMessage message={errorEdit.amount} />}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Start Date</label>
          <input
            onChange={handleFieldUpdate}
            name="startDate"
            disabled
            value={details.startDate}
            className={`${
              Object.keys(errorEdit).includes("startDate") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.startDate && (
            <ErrorMessage message={errorEdit.startDate} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label>Status</label>
          <input
            onChange={handleFieldUpdate}
            disabled
            name="status"
            value={details.status}
            className={`${
              Object.keys(errorEdit).includes("status") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.status && (
            <ErrorMessage message={errorEdit.status} />
          )}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6}>
          <label>End Date</label>
          <input
            onChange={handleFieldUpdate}
            name="endDate"
            disabled
            value={details.endDate}
            className={`${
              Object.keys(errorEdit).includes("endDate") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.endDate && <ErrorMessage message={errorEdit.endDate} />}
        </Grid> */}

        {/* <Grid item xs={12} sm={12} md={6}>
          <label>Description</label>
          <input
            onChange={handleFieldUpdate}
            name="description"
            value={details.description}
            className={`${
              Object.keys(errorEdit).includes("description") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.description && (
            <ErrorMessage message={errorEdit.description} />
          )}
        </Grid> */}

        {/* <Grid item xs={12} sm={12} md={6}>
          <label>Loan Limit Balance</label>
          <input
            onChange={handleFieldUpdate}
            name="loanLimitBalance"
            disabled
            value={details.loanLimitBalance}
            className={`${
              Object.keys(errorEdit).includes("loanLimitBalance") && "error-input-z"
            }`}
            type="text"
          />
          {errorEdit.loanLimitBalance && (
            <ErrorMessage message={errorEdit.loanLimitBalance} />
          )}
        </Grid> */}
      </Grid>
    </EditModal>
  );
};

export default EditData;

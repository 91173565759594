import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/Treasury/DealPosting";


const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Deal Posting - Record Details"
      question="Are you sure you want to delete this Deal?"
      url={`${baseUrl}treasury/DealPosting/delete/${details.id}`}
    >
       <Details details={details}/>   
    </DetailsContainer>
  );
};

export default View;

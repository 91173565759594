import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  createLoanDisbursement,
  getAllLoanDisbursement,
  updateLoanDisbursement,
  getLoanDisbursementLoanAccountNum,
  authorizeLoanDisbursement,
  getLoanByLoanAccountNumber,
  getLoanByCustomerAccountNumber,
  getCustomerByAccountNumber,
} from "../../../../Api/Hooks-manager/loanAccountOperations";
import {
  getBankParameterByName,
  getAllGlAccount,
  getAllCurrencyDefinition,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import useTable from "../../../Tables/useTable";
import { formattedDate } from "../../../../formatter/date";
import {
  isLoading,
} from "../../../../Api/redux-manager/actions";

export default function BussinessLogic() {
  const [screenChange, setScreenChange] = useState(1);
  const loading = useSelector((state) => state.req.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [open, setOpen] = useState(false);
  const [takeAction, setTakeAction] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [message, setMessage] = useState("");
  const [accNum, setAccountNumber] = useState("");
  const [allLoanAccountNum, setAllLoanAccNum] = useState([]);
  const [invalidAccount, setInvalidAccount] = useState("");
  // const [allGlAccount, setAllGlAccount] = useState([]);
  const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
  // const [cusCategory, setCategory] = useState([]);
  const [loanStatus, setLoanStatus] = useState([]);
  const [allLoanDisbursement, setAllLoanDisbursement] = useState([]);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [view, setView] = useState({});
  const [openBoxAuthorize, setOpenAuthorize] = useState(false);
  const [openDecision, setOpenDecision] = useState(false);
  const [authorizeDisbursement, setAuthorizeDisbursement] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const [disbursement, setDisbursement] = useState({
    disbursementReferenceNo: uuidv4().substring(0, 10),
    loanAccountNo: "",
    disbursementMethod: "Transfer",
    loanAccountNumber: "",
    creditAccount: "",
    creditAccountBranch:"",
    amount: 0,
    branchId: "",
    tellerTillAccount: "",
    denominations: [],
  });

  const [editDisbursement, setEditDisbursement] = useState({
    disbursementReferenceNo: "",
    loanAccountNo: "",
    disbursementMethod: "",
    loanAccountNumber: "",
    amount: 0,
    branchId: "",
    tellerTillAccount: "",
    denominations: [
      {
        total: 0,
        denom: 0,
        count: 0,
      },
    ],
  });

  const headCells = [
    { id: "id", label: "S/N" },
    { id: "disbursementReferenceNo", label: "Disbursement Reference No" },
    { id: "disbursementMethod", label: "Disbursement Method" },
    { id: "creditAccount", label: "Credit Account" },
    { id: "amount", label: "Amount" },
    { id: "Status", label: "Status" },
    // { id: "delete", label: "Delete", disableSorting: true },
    // { id: "edit", label: "Edit", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(allLoanDisbursement, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.disbursementReferenceNo.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "creditAccount" && value.length > 10) return;
    setDisbursement({ ...disbursement, [name]: value });
    if (name === "amount") {
      const inputedVal =
        value === "" ? "" : parseInt(value) < 0 ? 0 : parseInt(value);
      setDisbursement({
        ...disbursement,
        [name]: inputedVal,
      });
    }
    console.log("dis", disbursement);
  };

  const handleAccNum = (e) => {
    const { value } = e.target;
    if (value.length > 10) return;
    setAccountNumber(value);
    console.log(disbursement);
  };

  const handleDenominationsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...disbursement.denominations];
    list[index][name] =
      name === "total" || name === "denom" || name === "count"
        ? value === ""
          ? ""
          : parseInt(value) < 0
          ? 0
          : parseInt(value)
        : value;
    setDisbursement({ ...disbursement, denominations: list });
  };

  const addDenomination = () => {
    const list = [...disbursement.denominations];
    if (list.length > 8) return;
    list.push({
      total: 0,
      denom: 0,
      count: 0,
    });
    setDisbursement({
      ...disbursement,
      denominations: list,
    });
  };

  const removeDenomination = (index) => {
    const list = [...disbursement.denominations];
    if (list.length < 2) return;
    list.splice(index, 1);
    setDisbursement({
      ...disbursement,
      denominations: list,
    });
  };

  const openEdit = (item) => {
    setEditDisbursement({
      disbursementReferenceNo: item.disbursementReferenceNo,
      loanAccountNo: item.loanAccount,
      disbursementMethod: item.disbursementMethod,
      loanAccountNumber: item.loanAccountNumber,
      amount: item.amount,
      branchId: item.branchId,
      tellerTillAccount: item.tellerTillAccount,
      denominations: item.denominations
        ? item.denominations.map((el) => {
            return {
              total: el.total,
              denom: el.denom,
              count: el.count,
            };
          })
        : [],
    });
    setLoanId(item.id);
    setEdit(true);
  };

  const [details, setDetails] = useState({});
  const [creditAccountDetails, setCreditAccountDetails] = useState({});

  console.log("details...", details);

  const fetchLoanAccountDetails = async (accNum) => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getLoanByLoanAccountNumber(user.jwtToken, accNum);
    console.log("loan acc.........", response);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setErrors({ ...errors, loanAccountNumber: "" });
        setDisbursement({
          ...disbursement,
          //amount: response.data.amount,
          creditAccount: response.data.accountNumber,
          branchId: response.data.accountBranch,
          loanAccountNo: accNum,
        });
        setDetails({
          accountName: response.data.accountName,
          amount: response.data.amount,
          currencyCode: response.data.currencyCode,
          accountBranch: response.data.accountBranch,
        });
      } else {
        setErrors({ ...errors, loanAccountNumber: response.message });
        clearAccountDetails(true);
      }
    }
  };

  const fetchAllLoanAccountNumber = async () => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getLoanByCustomerAccountNumber(
      user.jwtToken,
      accNum
    );
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        const authorizedLoans = response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        );
        setInvalidAccount(
          response.data.length < 1 ? "Could not find any loan" : ""
        );
        if (response.data.length > 0 && authorizedLoans.length < 1) {
          setInvalidAccount("This loan is not currently authorized");
        }
        setAllLoanAccNum(
          authorizedLoans.map((el) => {
            return {
              label: el.loanAccount,
              value: el.loanAccount,
            };
          })
        );
      } else {
        setAllLoanAccNum([]);
        setDisbursement({ ...disbursement, loanAccountNumber: "" });
        clearAccountDetails(true);
        setInvalidAccount(response.message);
      }
    }
  };

  const fetchCustomerDetails = async (accNum) => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getCustomerByAccountNumber(user.jwtToken, accNum);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setErrors({ ...errors, creditAccount: "" });
        setDisbursement((prev) => ({...prev, creditAccountBranch:response.data.branchCode}))
        setCreditAccountDetails({
          accountName: response.data.accountName,
          accountBranch: response.data.branchCode,
          currencyCode: response.data.currency,
        });
      } else {
        setErrors({ ...errors, creditAccount: response.message });
        clearCustomerDetails(true);
      }
    }
  };

  const [sumLoanDisbursed, setSumLoanDisbursed] = useState(0);

  const listOfPayment = (arr) => arr.map((item) => item.amount);

  const totalDisbursementAmount = (arr) =>
    listOfPayment(arr).reduce((total, amount) => total + amount);

  const fetchAllLoanDisbursementsByAccNum = async (loanAccNum) => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getLoanDisbursementLoanAccountNum(
      user.jwtToken,
      loanAccNum
    );

    console.log('------res------',response.data)

    if (response && response.data) {
      const length = response.data.length;
    const sum = totalDisbursementAmount(
      length > 0 ? response.data : [{ amount: 0 }]
    );
    dispatch(isLoading());
      setSumLoanDisbursed(sum);
    }
  };

  const disbursementBalance = () => {
    let loanDisbursed = sumLoanDisbursed
    if(!loanDisbursed){
      loanDisbursed = 0
    }
    if (details.amount) {
      const difference = details.amount - loanDisbursed;
      setDisbursement({ ...disbursement, amount: difference });
      //setRepaymentAmount(difference);
    }
  };

  useEffect(() => {
    disbursementBalance();
  }, [sumLoanDisbursed, details.amount]);

  const clearCustomerDetails = (invalid) => {
    if (disbursement.creditAccount.length < 10 || invalid) {
      setCreditAccountDetails({});
    }
  };

  const clearAccountDetails = (invalid) => {
    if (disbursement.loanAccountNumber === "" || invalid) {
      setDisbursement({
        ...disbursement,
        loanAccountNo: "",
        amount: 0,
        branchId: "",
      });
      setDetails({});
    }
  };

  const clearCreditAccountDetails = () => {
    if (disbursement.creditAccount.length !== 10) {
      setDetails({});
    }
  };

  useEffect(() => {
    clearCreditAccountDetails();
  }, [disbursement.creditAccount]);

  useEffect(() => {
    if (accNum.length !== 10) {
      clearAccountDetails(true);
      setAllLoanAccNum([]);
      setDisbursement({
        ...disbursement,
        loanAccountNumber: "",
        loanAccountNo: "",
      });
    }
  }, [accNum]);

  useEffect(() => {
    clearAccountDetails(true);
  }, [disbursement.loanAccountNumber]);

  useEffect(() => {
    clearCustomerDetails(true);
  }, [disbursement.creditAccount]);

  useEffect(() => {
    if (disbursement.loanAccountNumber) {
      fetchAllLoanDisbursementsByAccNum(disbursement.loanAccountNumber);
      fetchLoanAccountDetails(disbursement.loanAccountNumber);
    }
  }, [disbursement.loanAccountNumber]);

  useEffect(() => {
    if (accNum.length === 10) {
      fetchAllLoanAccountNumber();
    }
  }, [accNum]);

  useEffect(() => {
    if (disbursement.creditAccount.length === 10) {
      fetchCustomerDetails(disbursement.creditAccount);
    }
  }, [disbursement.creditAccount]);

  const validateFirstScreen = () => {
    const errors = {};
    const {
      disbursementMethod,
      loanAccountNumber,
      amount,
      branchId,
      tellerTillAccount,
      creditAccount,
    } = disbursement;

    if (disbursementMethod === "") {
      errors.disbursementMethod = "Field is required";
    }
    if (accNum.length !== 10) {
      setInvalidAccount("Account number should be 10 character long");
    }
    if (amount === "") {
      errors.amount = "Field is required";
    }
    if (details.amount && amount > details.amount - sumLoanDisbursed) {
      errors.amount = `Hold on!! You are about to transfer an excess of ${
        creditAccountDetails.currencyCode
      }${amount - (details.amount - sumLoanDisbursed)}`;
    }
    if (disbursementMethod === "Cash" && tellerTillAccount === "") {
      errors.tellerTillAccount = "Field is required";
    }
    if (loanAccountNumber === "") {
      errors.loanAccountNumber = "Field is required";
    }
    if (loanAccountNumber !== "" && branchId === "") {
      errors.loanAccountNumber = "No record found";
    }
    if (creditAccount === "") {
      errors.creditAccount = "Field is required";
    }
    if (creditAccount.length !== 10) {
      errors.creditAccount = "Account number should be 10 character long";
    }
    if (creditAccount.length === 10 && !creditAccountDetails.accountName) {
      errors.creditAccount = "Invalid account number";
    }
    return errors;
  };

  const validateLastPage = () => {
    const errors = {
      denom: [],
      count: [],
    };
    const { denominations } = disbursement;

    for (let i = 0; i < denominations.length; i++) {
      if (denominations[i].denom === "") {
        errors.denom.push({
          id: i,
        });
      }
      if (denominations[i].count === "") {
        errors.count.push({
          id: i,
        });
      }
    }

    if (!errors.denom.length) delete errors.denom;
    if (!errors.count.length) delete errors.count;

    return errors;
  };

  const handleFirstScreen = () => {
    const checkErrors = validateFirstScreen();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrors({});
    setScreenChange(2);
  };

  const postLoanDisbursement = async () => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await createLoanDisbursement(user.jwtToken, disbursement);
    if (response && response.data) {
      setMessage(response.message);
      dispatch(isLoading());
      if (response.status) {
        setDisbursement({
          disbursementReferenceNo: uuidv4().substring(0, 10),
          loanAccountNo: "",
          disbursementMethod: "Transfer",
          loanAccountNumber: "",
          creditAccount: "",
          creditAccountBranch:"",
          amount: 0,
          branchId: "",
          tellerTillAccount: "",
          denominations: [],
        });
        fetchAllLoanRepayments();
        setScreenChange(1);
        return true;
      } else {
        return false;
      }
    }
  };

  const editLoanDisbursement = async () => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await updateLoanDisbursement(
      user.jwtToken,
      editDisbursement,
      loanId
    );
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setEditDisbursement({
          disbursementReferenceNo: "",
          loanAccountNo: "",
          disbursementMethod: "",
          loanAccountNumber: "",
          amount: 0,
          branchId: "",
          tellerTillAccount: "",
          denominations: [
            {
              total: 0,
              denom: 0,
              count: 0,
            },
          ],
        });
        fetchAllLoanRepayments();
        setScreenChange(1);
        return true;
      } else {
        return false;
      }
    }
  };

  // const fetchAllGlAccount = async () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const response = await getAllGlAccount(user.jwtToken);
  //   if (response && response.data) {
  //     setAllGlAccount(response.data);
  //   }
  // };

  const fetchAllCurrencyDefinition = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getAllCurrencyDefinition(user.jwtToken);
    if (response && response.data) {
      setAllCurrencyDefinition(response.data);
    }
  };

  const fetchAllLoanRepayments = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllLoanDisbursement(token);
    if (response && response.data) {
      console.log(response, "lDM")
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
 
      setAllLoanDisbursement(response.data);
    }
  },[dispatch, token]);

  const handleSubmit = () => {
    const checkErrors = validateFirstScreen();
    console.log("checking..", checkErrors);
    console.log("invalidAccount..", invalidAccount);
    setErrors(checkErrors);
    if (invalidAccount || Object.keys(checkErrors).length > 0) return;
    setOpen(true);
  };
  const parameters = "CUS_CATEGORY,Loan_Status";
  const BankParameterByName = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getBankParameterByName(user.jwtToken, parameters);
    if (response && response.data.cuS_CATEGORY) {
      setLoanStatus(response.data.loan_Status);
    }
    if (response && response.data.loan_Status) {
      setLoanStatus(response.data.loan_Status);
    }
  };

  const decide = async () => {
    dispatch(isLoading());
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await authorizeLoanDisbursement(
      user.jwtToken,
      authorizeDisbursement,
      view.id
    );
    if (response && response.data) {
      dispatch(isLoading());
      if (response.statusCode === 200) {
        setOpenView(false);
        setOpenAuthorize(false);
        setAuthorizeDisbursement({
          reason: "",
          isApproved: true,
          authorizationStatus: 0,
        });
        fetchAllLoanRepayments();
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    fetchAllLoanRepayments();
    // BankParameterByName();
    // fetchAllGlAccount();
    fetchAllCurrencyDefinition();
  }, []);

  return {
    disbursement,
    details,
    allLoanDisbursement,
    setDetails,
    accNum,
    allLoanAccountNum,
    creditAccountDetails,
    invalidAccount,
    sumLoanDisbursed,
    addDenomination,
    removeDenomination,
    handleChange,
    handleAccNum,
    handleDenominationsChange,
    handleFirstScreen,
    postLoanDisbursement,
    screenChange,
    setScreenChange,
    handleSubmit,
    errors,
    open,
    setOpen,
    loading,
    openEdit, //function to open the edit modal as well as supplying data to editDisbursement
    edit, //boolen to open the edit modal
    setEdit, //function to close or open the edit modal
    editDisbursement, //edit data
    editLoanDisbursement, //api call that returns a boolean
    // allGlAccount,
    allCurrencyDefinition,
    // cusCategory,
    // loanStatus,
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    handleSearch,
    message,
    takeAction, //boolean used to open the decision modal
    setTakeAction, // function for opening or closing the decision modal
    setLoanId,
    openView,
    setOpenView,
    view,
    setView,
    openBoxAuthorize,
    setOpenAuthorize,
    openDecision,
    setOpenDecision,
    decide,
    authorizeDisbursement,
    setAuthorizeDisbursement,
    fetchCustomerDetails,
  };
}

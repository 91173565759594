import axios from 'axios';
import { Routes } from '../../configUrl';

const getPendingBranchTransactions = async (token, data, pageNumber, pageSize) => {
  return await axios({
    method: 'get',
    url: `${Routes.getPendingBranchTransaction}${data.start}?&pageNumber=${pageNumber=1}&pageSize=${pageSize=1000}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};

const getPendingHeadOfficeTransactions = async (token, start, pageSize) => {
  return await axios({
    method: 'get',
    url: `${Routes.getPendingHeadOfficeTransaction}${start}&pageSize=${pageSize}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        data: res.data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        data: [],
      };
    });
};

const authorizeRemoteTransaction = async (token, data) => {
  return await axios({
    method: 'post',
    url: `${Routes.authorizeRemoteTransaction}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${token}`,
    },
    data,
  })
    .then((res) => {
      // console.log('response--aaaa---', res.data);
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        statusCode: res.data.statusCode,
      };
    })
    .catch((err) => {
      // console.log(err.response.data, 'Error during authorization...');
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        statusCode: err.response.data.statusCode,
      };
    });
};

export {
  getPendingBranchTransactions,
  getPendingHeadOfficeTransactions,
  authorizeRemoteTransaction,
};

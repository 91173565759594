import {
  LOADER_ON,
  LOADER_OFF,
  LOGIN_ATTEMPT,
  LOGOUT_USER,
} from "../constants";

//---------------------------------------------------- start ---------------------------------

export const attemptLogin = () => ({
  type: LOGIN_ATTEMPT,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const loaderON = () => ({
  type: LOADER_ON,
});


export const loaderOFF = () => ({
  type: LOADER_OFF,
});

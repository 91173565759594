import React, {useState, useEffect} from 'react'
import DetailsContainer from "../../../DatagridTable/view";
import RepaymetDetailsContainer from "../../../DatagridTable/otherDetails";
import Details from "../../../Details/LoanAccount/LoanBooking";
import RepaymentSample from "../../../Details/LoanAccount/RepaymentSample";
import CustomizedSnackbars from '../../../../Confirmbox/Snackbar';

const View = ({details}) => {
    return (
        <DetailsContainer
      title="Loan booking - Record Details"
      question=""
    >
      <Details details={details}/>
    </DetailsContainer>
    )
}

export const RepaymentSchedule = ({open, onClose, details, sampleShedule, onEditedScheduleSave}) => {
  const [scheduleEdit, setScheduleEdit] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarContent, setSnackBarContent] = useState({
    status: "",
    message: ""
  })
  const [editedSchedule, setEditedSchedule] = useState(sampleShedule.map(row => ({...row})))
  const [totals, setTotals] = useState({
    repaymentPrincipal: 0,
    repaymentInterest: 0,
    repaymentAmount: 0,
  })
  const [errors, setErrors] = useState(Array(sampleShedule.length).fill({}))

  useEffect(() => {
    // Calculate Totals
    const schedule = scheduleEdit ? editedSchedule : sampleShedule
    const newTotals = schedule.reduce((previous, next) => {
      previous.repaymentAmount = parseFloat(parseFloat(previous.repaymentAmount + next.repaymentAmount).toFixed(2))
      previous.repaymentInterest = parseFloat(parseFloat(previous.repaymentInterest + next.repaymentInterest).toFixed(2))
      previous.repaymentPrincipal = parseFloat(parseFloat(previous.repaymentPrincipal + next.repaymentPrincipal).toFixed(2))
      return previous
    }, {
      repaymentPrincipal: 0.00,
      repaymentInterest: 0.00,
      repaymentAmount: 0.00,
    })
    setTotals(newTotals)
  }, [sampleShedule])
  
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackBarOpen(false);
  };
  

  const clearScheduleError= () => {
    setErrors(Array(sampleShedule.length).fill({}))
  }
  
  const onScheduleSave = () => {
    let errorExist = validateRepaymentSchedule()
    if(!errorExist){
      onEditedScheduleSave(editedSchedule)
      setScheduleEdit(false)
    }    
  }
  const validateRepaymentSchedule = () => {
    let errorCount = 0
    const newErrors = sampleShedule.map(() => ({}))
    // check totals for errors
    const editedTotals = editedSchedule.reduce((previous, next) => {
      previous.repaymentAmount = parseFloat((previous.repaymentAmount + next.repaymentAmount).toFixed(2))
      previous.repaymentInterest = parseFloat(parseFloat(previous.repaymentInterest + next.repaymentInterest).toFixed(2))
      previous.repaymentPrincipal = parseFloat(parseFloat(previous.repaymentPrincipal + next.repaymentPrincipal).toFixed(2))
      return previous
    }, {
      repaymentPrincipal: 0.00,
      repaymentInterest: 0.00,
      repaymentAmount: 0.00,
    })

    const totalsError = {}
    if(editedTotals.repaymentPrincipal !== totals.repaymentPrincipal){
      totalsError.repaymentPrincipal = "Invalid principal total"
      errorCount += 1
    }
    if(editedTotals.repaymentInterest !== totals.repaymentInterest){
      totalsError.repaymentInterest = "Invalid Intrest total"
      errorCount += 1
    }
    newErrors.push(totalsError)
    setErrors(newErrors)
    const validationStatus = Boolean(errorCount) // True if Validation fails
    if(validationStatus){
      setSnackBarContent({
        status: "error",
        message: "Schedule Validation Failed"
      })
      setSnackBarOpen(true)
    }
    else{
      setSnackBarContent({
        status: "success",
        message: "Validation successful"
      })
      setSnackBarOpen(true)
    }
    return validationStatus
  }

  const toggleScheduleEdit = (value) => {
    clearScheduleError()
    setScheduleEdit(value)
  }

  const onScheduleReset = () => {
    setEditedSchedule(sampleShedule.map(row => ({...row})))
    clearScheduleError()
  }

  const onScheduleChange = (field, index, value) => {
    const newSchedule = [...editedSchedule]
    newSchedule[index][field] = parseFloat(value || 0) // Updated Schedule is an array of objects
    if(field === "repaymentInterest" || field === "repaymentPrincipal"){
      newSchedule[index]['repaymentAmount'] = newSchedule[index].repaymentPrincipal + newSchedule[index].repaymentInterest
    }
    setEditedSchedule(newSchedule)
    if(errors[index][field]){
      const updatedErrors = [...errors]
      updatedErrors[index][field] = ""
      setErrors(updatedErrors)
    }
  }

  return (
    <RepaymetDetailsContainer
    title="Repayments Schedule - Record Details"
    question=""
    open={open || false}
    handleClose={onClose}
    edit={scheduleEdit}
    onScheduleSave={onScheduleSave}
  >
    <RepaymentSample
      details={details} 
      sampleShedule={sampleShedule} 
      editedSchedule={editedSchedule} 
      edit={scheduleEdit} 
      setEdit={toggleScheduleEdit}
      onScheduleReset={onScheduleReset}
      onScheduleChange={onScheduleChange}
      onScheduleValidate={validateRepaymentSchedule}
      errors={errors}
      totals={totals}
    />
    <CustomizedSnackbars 
      handleClose={handleCloseSnackBar} 
      message={snackBarContent.message} 
      status={snackBarContent.status} 
      open={snackBarOpen}
    />
  </RepaymetDetailsContainer>
  )
}

export default View

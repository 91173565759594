import React from 'react'

const CountDefinition = () => {
    return (
        <div>
            Auhtorize CountDefinition
        </div>
    )
}

export default CountDefinition

import "./Loanrepayment.scss";
import {useState} from 'react';
// import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BusinessLogic from "./businessLogic";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import DecisonModal from "../../../../Confirmbox/Confirm";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from "react-number-format";
// import Details from "./view";
// import LoanDetailsView from "./loanDetailsView";
import LoanRepaymentTable from "../../../DatagridTable/table";
import { useDispatch } from "react-redux";
// import {
//   openViewModal,
// } from "../../../../Api/redux-manager/actions";

const LoanRepaymentSubTable = ({data}) => {
  return (
    <>
      <tr>
        <td colSpan={12} style={{padding: "0 1rem"}}>
          <TableContainer component={Paper}>
            <Table style={{minWidth: 650}} aria-label="Bulk table">
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>

                  <TableCell>Due Date</TableCell>
                  <TableCell>Repayment Principal</TableCell>
                  <TableCell>Repayment Interest</TableCell>
                  <TableCell>Repayment Amount</TableCell>
                  <TableCell>Payment Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow hover style={{cursor: "pointer"}} key={row.referenceNumber}>
                    <TableCell align="left">{row.sequenceId}</TableCell>
                    <TableCell>{new Date(row.dueDate).toLocaleDateString()}</TableCell>
                    <TableCell>{row.repaymentPrincipal}</TableCell>
                    <TableCell>{row.repaymentInterest}</TableCell>
                    <TableCell>{row.repaymentAmount}</TableCell>
                    <TableCell>
                      {(
                        <p
                          style={{
                            color:
                              row.paymentStatus.toLowerCase() === "paid"
                                ? "green"
                                : row.paymentStatus.toLowerCase() === "unpaid"
                                ? "red"
                                : "rgb(224, 148, 33)",
                          }}
                        >
                          {row.paymentStatus}
                        </p>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </td>
      </tr>
    </>
  )
}

const Loanrepayment = () => {
  const {
    repayment,
    accNum,
    allLoanAccountNum,
    invalidAccount,
    details,
    debitAccountDetails,
    amount,
    repaymentAccountDetails,
    allLoanRepayment,
    setAmount,
    setDetails,
    handleChange,
    handleAccNum,
    postLoanRepayment,
    screenChange,
    handleSubmit,
    errors,
    open,
    setOpen,
    loading,
    allCurrencyDefinition,
    message,
    openDecision,
    setOpenDecision,
    decide,
    repaymentSchedule,
    fullLoanDetails,
    setRepayment  
  } = BusinessLogic();

  // console.log(repayment, "repayment details");
  // console.log(details, "details details");

  const dispatch = useDispatch();

  const columns = [
    {
      name: "loanAccount",
      label: "Loan Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Debit Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bookDate",
      label: "Book Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
            return new Date(value).toLocaleDateString();
        },
      },
    },
    {
      name: "loanProductName",
      label: "Loan Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "loanStatus",
    //   label: "Loan Status",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "authorizationStatus",
      label: "Authorization Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status="") => (
            <p
              style={{
                color:
                  status.toLowerCase() === "authorized"
                    ? "green"
                    : status.toLowerCase() === "rejected"
                    ? "yellow"
                    : "rgb(224, 148, 33)",
              }}
            >
              {status}
            </p>
          ),
      },
    },
    // {
    //   name: "sn",
    //   label: "Action",
    //   options: {
    //     customBodyRenderLite: (dataIndex, rowIndex) => (
    //       <DotMenu>
    //         <MenuItem
    //           onClick={() => {
    //             const singleItem = repaymentSchedule[dataIndex];
    //             setRecordData(singleItem);
    //             dispatch(openViewModal())
    //           }}
    //         >
    //           View
    //         </MenuItem>
    //       </DotMenu>
    //     ),
    //   },
    // },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(fullLoanDetails.repayments)
      return (
      <LoanRepaymentSubTable
        data={repaymentSchedule}
        setDetails={setDetails}
        dispatch={dispatch}
      />)
    },
  };
  return (
    <div className="loan--repayment">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={postLoanRepayment}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <DecisonModal
        ApiCall={decide}
        message="Are you sure you want to authorize this record?"
        openBox={openDecision}
        actionMsg="Your records were authorized successfully"
        setOpenBoxState={setOpenDecision}
        errorMessage={message}
      />
      <div className="headerdiv">
        <p>Loan Repayment</p>
      </div>
      <div className="flex--3">
        <div className="button--double flex--2">
                    {/* <div className="flex--3">
            <button
              className={`${screenChange === 1 ? "active-1" : ""}`}
              //onClick={() => handleScreenChange(1)}
            >
              1
            </button>
            <p className="button-name">Transactions</p>
          </div> */}
        </div>
      </div>
      <Paper
        style={{
          marginTop: 10,
          padding: " 25px 50px 0 25px ",
          boxShadow: "2px 2px 2px 2px white-smoke",
        }}
      >
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <label>Repayment Method</label>
                <br />
                <select
                  disabled
                  name="repaymentType"
                  value={repayment.repaymentType}
                  onChange={handleChange}
                >
                  <option value="">Select repayment method</option>
                  <option value="Transfer">Account Transfer</option>
                  <option value="Cash">By Cash</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={12} md={8}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label>Customer Account No.</label>
                <br />
                <input
                  type="text"
                  className={invalidAccount ? "error-input-z" : null}
                  value={accNum}
                  onChange={handleAccNum}
                />
                <ErrorMessage message={invalidAccount} />
              </Grid>
              {allLoanAccountNum.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                  <label>Loan Account No</label>
                  <br />
                  <select
                    className={
                      errors.loanAccountNo ? "error-input-z" : null
                    }
                    name="loanAccountNo"
                    value={repayment.loanAccountNo}
                    onChange={handleChange}
                  >
                    <option value="">Select an account number</option>
                    {allLoanAccountNum.map((el, i) => (
                      <option key={i} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </select>
                  {errors.loanAccountNo && (
                    <ErrorMessage message={errors.loanAccountNo} />
                  )}
                </Grid>
              )}
              {details.accountName && (
                <Grid item xs={12} sm={6} md={4}>
                  <label>Customer Name</label>
                  <br />
                  <input type="text" value={details.accountName} disabled />
                </Grid>
              )}
              {repayment.branchCode && (
                <Grid item xs={12} sm={12} md={3}>
                  <label>Branch</label>
                  <br />
                  <input
                    type="text"
                    disabled
                    name="branchCode"
                    value={repayment.branchCode}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              {repayment.branchCode && (
                <Grid item xs={12} sm={12} md={3}>
                  <label>Loan Currency</label>
                  <br />
                  <input type="text" disabled value={repayment.branchCode} />
                </Grid>
              )}
              {details.hasOwnProperty('amount') && (
                <Grid item xs={12} sm={6} md={3}>
                  <label>Loan Amount</label>
                  <br />
                  <input type="text" value={details.amount} disabled />
                </Grid>
              )}
              {details.hasOwnProperty('loanBalance') && <Grid item xs={12} sm={6} md={3}>
                <label>Loan Balance</label>
                <br />
                <input type="text" value={details.loanBalance} disabled />
              </Grid>}
              <Grid item xs={12} sm={6} md={3}>
                <label>Principal Due</label>
                <br />
                <input type="number" step="any" value={repaymentAccountDetails.repaymentPrincipal} disabled/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label>Interest Due</label>
                <br />
                <input type="number" step="any" value={repaymentAccountDetails.repaymentInterest} disabled/>
              </Grid>
              {repayment.repaymentType === "Cash" && (
                <Grid item xs={12} sm={6} md={4}>
                  <label>Teller Till Account</label>
                  <br />
                  <input
                    type="text"
                    name="repaymentType"
                    value={repayment.repaymentType}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              {repayment.repaymentType === "Transfer" && (
                <Grid item xs={12} sm={6} md={4}>
                  <label>Debit Account</label>
                  <br />
                  <input
                    type="text"
                    className={
                      errors.debitAccount ? "error-input-z" : null
                    }
                    name="debitAccount"
                    value={repayment.debitAccount}
                    onChange={handleChange}
                  />
                  <ErrorMessage message={errors.debitAccount} />
                </Grid>
              )}
              {debitAccountDetails.accountName &&
                repayment.repaymentType === "Transfer" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <label>Account Name</label>
                    <br />
                    <input
                      type="text"
                      value={debitAccountDetails.accountName}
                      disabled
                    />
                  </Grid>
                )}
              {debitAccountDetails.accountBranch &&
                repayment.repaymentType === "Transfer" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <label>Debit Account Branch</label>
                    <br />
                    <input
                      type="text"
                      value={debitAccountDetails.accountBranch}
                      disabled
                    />
                  </Grid>
                )}
              {repayment.currency && (
                <Grid item xs={12} sm={4} md={2}>
                  <label>Payment Amount</label>
                  <br />
                  <select
                    className="select--input"
                    name="currency"
                    value={repayment.currency}
                    disabled
                  >
                    <option value="">Select a currency</option>
                    {allCurrencyDefinition.map((item) => (
                      <option value={item.currencyCode} key={item.id}>
                        {item.currencyName}
                      </option>
                    ))}
                  </select>
                </Grid>
              )}
              {repayment.currency && (
                <Grid item xs={12} sm={4} md={2}>
                  <label className="empty--label">Payment Amount</label>
                  <br />
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator={true}
                    inputMode="numeric"
                    name="amount"
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(value) => <div>{value}</div>}
                    className={
                      errors.amount ? "error-input-z" : null
                    }
                    onValueChange={(values) => {
                      setRepayment((repayment) => ({
                        ...repayment,
                        amount: parseFloat(values.value),
                      }));
                    }}                    
                    />
                  {/* <input
                    type="number"
                    className={
                      errors.amount ? "error-input-z" : null
                    }
                    name="amount"
                    value={repayment.amount}
                    onChange={handleChange}
                  /> */}
                   <ErrorMessage message={errors.amount} />
                </Grid>
              )}
              <Grid item xs={12}></Grid>
              {/* {repayment.repaymentType === "Cash" && <button className="next">Next</button>} */}
              {repayment.loanAccountNo && <button className="next" onClick={handleSubmit}>Save</button>}
            </Grid>
          </>
      </Paper>
      {fullLoanDetails.repayments && fullLoanDetails.repayments.length > 0 && (
        <LoanRepaymentTable
            title="Repayment Schedule"
            subtitle=""
            name="Repayment Schedule"
            records={[fullLoanDetails]}
            options={options}
            columns={columns}
        />
      )}
      {/* {recordData && recordData.id && <Details details={recordData} />} */}
      {/* {loanDetailsData && <LoanDetailsView details={loanDetailsData} />} */}
    </div>
  );
};
export default Loanrepayment;

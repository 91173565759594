import React from 'react'

const ManagePermission = () => {
  return (
    <div>
      Auhtorize manage permission
    </div>
  )
}

export default ManagePermission

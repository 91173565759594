import React from 'react'
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/InvestmentAccount/InvestmentLiquidation";


const view = ({details}) => {
    return (
        <DetailsContainer
      title="Investment Liquidation - Record Details"
      question="Are you sure you want to delete this record?"
    >
      <Details details={details} showTooltip={true}/>
    </DetailsContainer>
    )
}

export default view

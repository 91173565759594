import Details from "../../../Details/CoreModules/InstitutionDefintion";
import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";

const View = ({details}) => {
    return (
        <DetailsContainer
        title="Institution Definition - Record Details"
        question="Are you sure you want to delete this institution?"
        url={`${Routes.deleteAnInstitution}${details.id}`}
      >
        <Details details={details}/>
      </DetailsContainer>
    )
}

export default View

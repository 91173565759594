import { useState, useEffect } from "react";
import "./Customerbio.scss";
import { useSelector } from "react-redux";



import {
  getBankParameterByName,
  getAllCustomerCategory,
} from "../../../Api/Hooks-manager/coreModules/coreModules";
import ExistingCustomerSection from "../ExistingCustomer/ExistingCustomerSection";
import { getAllStatesByCountryCode } from "../../../Api/places/getPlaces";
import useCustom2 from "../../../Api/Hooks-manager/coreModules/useCustom";
import Spinner from "../../../components/Backdrop/index";
import ErrorMessage from "../../../components/ErrorMessage";

const Customerbio = ({ handleChange, value, errors }) => {
  const [subscreenChange, setSubScreenChange] = useState(2),
    [customerTypes, setCustomerTypes] = useState([]),
    [customerCategories, setCustomerCategories] = useState([]),
    countries = useSelector((state) => state.common.countries),
    [states, setStates] = useState([]),
    { allbankparameters, getBankParametersAll, loader } = useCustom2();

  const handleSubScreenChange = (i) => {
    setSubScreenChange(i);
  };

  useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);
  // console.log(countries, "hello countries");

  const parametersTitle = allbankparameters.filter((items) =>
    items.parameterName.toLowerCase().includes("cus_title")
  );
  console.log("parametersTitleparametersTitle", allbankparameters);

  // console.log(countries, "jddagasdsakjh");

  useEffect(() => {
    //get customer types
    const parameter = "CUS_TYPE";
    const BankParameterByName = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await getBankParameterByName(user.jwtToken, parameter);
      // console.log(response)
      if (response && response.data.cuS_TYPE) {
        setCustomerTypes(response.data.cuS_TYPE);
      }
    };

    BankParameterByName();
  }, []);

  useEffect(() => {
    const fetchAllCustomerCategory = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await getAllCustomerCategory(user.jwtToken);
      if (response && response.data) {
        setCustomerCategories(response.data);
      }
    };

    fetchAllCustomerCategory();
  }, []);

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     const response = await getCountries(user.jwtToken);
  //     if (response && response.data) {
  //       setCountries(response.data);
  //     }
  //   };

  //   fetchCountries();
  // }, []);

  useEffect(() => {
    value.country && fetchStateByCode(value.country);
  }, [value.country]);

  const fetchStateByCode = async (value) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await getAllStatesByCountryCode(user.jwtToken, value);
      if (response && response.data) {
        setStates(response.data);
      }
    } catch {}
  };

  return (
    <div className="customer--bio">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader} />
      </div>
      <div className="flex-3">
        <div className="button--new flex-2">
          <div className="flex-3">
            <button
              className={`${subscreenChange === 1 ? "active-a" : ""}`}
              onClick={() => handleSubScreenChange(1)}
              disabled
            ></button>
            <span className="span">Existing Customer</span>
          </div>
          <div className="flex-3">
            <button
              className={`${subscreenChange === 2 ? "active-b" : ""}`}
              onClick={() => handleSubScreenChange(2)}
            ></button>
            <span className="span">New Customer</span>
          </div>
        </div>
      </div>
      {subscreenChange === 1 && (
        <ExistingCustomerSection/>)
       
      }
      {subscreenChange === 2 && (
        <>
          <div className="new-grid flex--2">
            <div className="input--select">
              <label>Customer Type</label>
              <br />
              <select
                onChange={handleChange("customerType")}
                value={value.customerType}
                className={`${
                  Object.keys(errors).includes("customerType") &&
                  "error-input-z"
                }`}
              >
                <option value="">Select An Option</option>
                {customerTypes && customerTypes.length > 0
                  ? customerTypes.map((types, i) => {
                      return (
                        <option key={i} value={types.parameterValue}>
                          {types.parameterValue}
                        </option>
                      );
                    })
                  : null}
              </select>
              {errors.customerType && (
                <ErrorMessage message={errors.customerType} />
              )}
            </div>

            {value.customerType === "Joint" && (
              <div className="input--field">
                <label>Joint Account Name</label>
                <br />
                <input
                  type="text"
                  onChange={handleChange("jaName")}
                  value={value.jaName}
                  className={`${
                    Object.keys(errors).includes("jaName") && "error-input-z"
                  }`}
                />
                {errors.jaName && <ErrorMessage message={errors.jaName} />}
              </div>
            )}
            <div></div>
            {value.customerType === "Joint" && (
              <div className="maincus">
                <p>First Customer Details</p>
                <br />
              </div>
            )}

            {(value.customerType === "" ||
              value.customerType === "Individual" ||
              value.customerType === "Joint") && (
              <div className="input--select">
                <label>Title</label>
                <br />
                <select
                  value={value.title}
                  onChange={handleChange("title")}
                  className={`${
                    Object.keys(errors).includes("title") && "error-input-z"
                  }`}
                >
                  <option>Select An Option</option>
                  {parametersTitle.map((title, index) => {
                    return (
                      <option key={index} value={title.parameterValue}>
                        {title.parameterValue}
                      </option>
                    );
                  })}
                </select>
                {errors.title && <ErrorMessage message={errors.title} />}
              </div>
            )}
            {value.customerType !== "" &&
              value.customerType !== "Individual" &&
              value.customerType !== "Joint" && (
                <>
                  <div className="input--field">
                    <label>RC Number</label>
                    <br />
                    <input
                      type="text"
                      onChange={handleChange("rcNumber")}
                      value={value.rcNumber}
                      className={`${
                        Object.keys(errors).includes("rcNumber") &&
                        "error-input-z"
                      }`}
                    />
                    {errors.rcNumber && (
                      <ErrorMessage message={errors.rcNumber} />
                    )}
                  </div>

                  <div className="input--field">
                    <label>TIN</label>
                    <br />
                    <input
                      type="text"
                      onChange={handleChange("tin")}
                      value={value.tin}
                      className={`${
                        Object.keys(errors).includes("tin") && "error-input-z"
                      }`}
                    />
                    {errors.tin && <ErrorMessage message={errors.tin} />}
                  </div>

                  <div className="input--field">
                    <label>Organization Name</label>
                    <br />
                    <input
                      type="text"
                      onChange={handleChange("corporateName")}
                      value={value.corporateName}
                      className={`${
                        Object.keys(errors).includes("corporateName") &&
                        "error-input-z"
                      }`}
                    />
                    {errors.corporateName && (
                      <ErrorMessage message={errors.corporateName} />
                    )}
                  </div>
                </>
              )}

            {(value.customerType === "" ||
              value.customerType === "Individual" ||
              value.customerType === "Joint") && (
              <>
                <div className="input--field">
                  <label>First Name</label>
                  <br />
                  <input
                    type="text"
                    onChange={handleChange("firstName")}
                    value={value.firstName}
                    className={`${
                      Object.keys(errors).includes("firstName") &&
                      "error-input-z"
                    }`}
                  />
                  {errors.firstName && (
                    <ErrorMessage message={errors.firstName} />
                  )}
                </div>
                <div className="input--field">
                  <label>Middle Name</label>
                  <br />
                  <input
                    type="text"
                    onChange={handleChange("middleName")}
                    value={value.middleName}
                    className={`${
                      Object.keys(errors).includes("middleName") &&
                      "error-input-z"
                    }`}
                  />
                  {errors.middleName && (
                    <ErrorMessage message={errors.middleName} />
                  )}
                </div>
                <div className="input--field">
                  <label>Last Name</label>
                  <br />
                  <input
                    type="text"
                    onChange={handleChange("lastName")}
                    value={value.lastName}
                    className={`${
                      Object.keys(errors).includes("lastName") &&
                      "error-input-z"
                    }`}
                  />
                  {errors.lastName && (
                    <ErrorMessage message={errors.lastName} />
                  )}
                </div>
              </>
            )}
            <div className="input--field">
              <label>Email</label>
              <br />
              <input
                type="email"
                onChange={handleChange("email")}
                value={value.email}
                // className={`${
                //   Object.keys(errors).includes("email") && "error-input-z"
                // }`}
              />
              {/* {errors.email && <ErrorMessage message={errors.email} />} */}
            </div>
            <div className="input--field">
              <label>Phone Number</label>
              <br />
              <input
                type="tel"
                onChange={handleChange("phoneNumber")}
                value={value.phoneNumber}
                className={`${
                  Object.keys(errors).includes("phoneNumber") && "error-input-z"
                }`}
              />
              {errors.phoneNumber && (
                <ErrorMessage message={errors.phoneNumber} />
              )}
            </div>
            {(value.customerType === "" ||
              value.customerType === "Individual" ||
              value.customerType === "Joint") && (
              <div className="input--select">
                <label>Gender</label>
                <br />
                <select
                  onChange={handleChange("gender")}
                  value={value.gender}
                  className={`${
                    Object.keys(errors).includes("gender") && "error-input-z"
                  }`}
                >
                  <option value="">Select, An Option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Not Applicable">Not Applicable</option>
                </select>
                {errors.gender && <ErrorMessage message={errors.gender} />}
              </div>
            )}
            <div className="input--select">
              <label>Customer Category</label>
              <br />
              <select
                onChange={handleChange("customerCategory")}
                value={value.customerCategory}
                className={`${
                  Object.keys(errors).includes("customerCategory") &&
                  "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                <option value = "INDIV">INDIV</option>
                {customerCategories && customerCategories.length > 0
                  ? customerCategories.map((category, i) => {
                      return (
                        <option key={i} value={category.category}>
                          {category.category}
                        </option>
                      );
                    })
                  : null}
              </select>
              {errors.customerCategory && (
                <ErrorMessage message={errors.customerCategory} />
              )}
            </div>
            <div className="input--field">
              {value.customerType === "" ||
              value.customerType === "Individual" ||
              value.customerType === "Joint" ? (
                <label>Date of Birth</label>
              ) : (
                <label>Date of Incorporation</label>
              )}
              <br />
              <input
                type="date"
                onChange={handleChange("dateOfBirth")}
                value={value.dateOfBirth}
                className={`${
                  Object.keys(errors).includes("dateOfBirth") && "error-input-z"
                }`}
              />
              {errors.dateOfBirth && (
                <ErrorMessage message={errors.dateOfBirth} />
              )}
            </div>
            <div className="input--field">
              <label>Address</label>
              <br />
              <input
                type="text"
                onChange={handleChange("address")}
                value={value.address}
                className={`${
                  Object.keys(errors).includes("address") && "error-input-z"
                }`}
              />
              {errors.address && <ErrorMessage message={errors.address} />}
            </div>
            <div className="input--field">
              <label>City</label>
              <br />
              <input
                type="text"
                onChange={handleChange("city")}
                value={value.city}
                className={`${
                  Object.keys(errors).includes("city") && "error-input-z"
                }`}
              />
              {errors.city && <ErrorMessage message={errors.city} />}
            </div>
            <div className="input--select">
              <label>Country</label>
              <br />
              <select
                onChange={handleChange("country")}
                value={value.country}
                className={`${
                  Object.keys(errors).includes("country") && "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                {countries && countries.length > 0
                  ? countries.map((country, i) => {
                      return (
                        <option key={i} value={country.countryCode2}>
                          {country.name}
                        </option>
                      );
                    })
                  : null}
              </select>
              {errors.country && <ErrorMessage message={errors.country} />}
            </div>
            <div className="input--select">
              <label>State</label>
              <br />
              <select
                onChange={handleChange("state")}
                value={value.state}
                className={`${
                  Object.keys(errors).includes("state") && "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                {states.map((state) => (
                  <option value={state.name} key={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
              {errors.state && <ErrorMessage message={errors.state} />}
            </div>
            <div className="input--field">
              <label>Local Govt</label>
              <br />
              <input
                type="text"
                onChange={handleChange("lga")}
                value={value.lga}
                className={`${
                  Object.keys(errors).includes("lga") && "error-input-z"
                }`}
              />
              {errors.lga && <ErrorMessage message={errors.lga} />}
            </div>
            <div className="input--select">
              {value.customerType === "" ||
              value.customerType === "Individual" ||
              value.customerType === "Joint" ? (
                <label>Country of Origin</label>
              ) : (
                <label>Country of Incorporation</label>
              )}
              <br />
              <select
                onChange={handleChange("nationality")}
                value={value.nationality}
                className={`${
                  Object.keys(errors).includes("nationality") && "error-input-z"
                }`}
              >
                <option>Select An Option</option>
                {/* <option value = "Nigeria">Nigeria</option> */}
                {countries && countries.length > 0
                  ? countries.map((country, i) => {
                      return (
                        <option key={i} value={country.countryCode2}>
                          {country.name}
                        </option>
                      );
                    })
                  : null}
              </select>
              {errors.nationality && (
                <ErrorMessage message={errors.nationality}/>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Customerbio;

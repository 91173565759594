import { useState, useEffect } from 'react';
import './InvestmentMaturity.scss';
import { baseUrl } from "../../../../../Api/configUrl";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { getHandler } from "../../../../../Api/Hooks-manager/coreModules/coreModules";
import { getCustomerByAccountNumber } from "../../../../../Api/Hooks-manager/loanAccountOperations";

import { useDispatch } from "react-redux";
import useCustom from "../../../../../Api/Hooks-manager/accountOpening/useCustom";
// import useCustom from "../../../../Api/Hooks-manager/loanMaturityReport/useCustom";
import DotMenu from "../../../../../commons/dotMenu/dotMenu";
import {
  openUpdateFormModal,
  openViewModal,
  isLoading,
} from "../../../../../Api/redux-manager/actions";


const InvestmentMaturityReport = () => {
    // const [loader, setLoader] = useState(false);
    // const [view, setView] = useState({});
    // const [openView, setOpenView] = useState(false);
    const dispatch = useDispatch();
    const updateTable = useSelector((state) => state.req.updateTable);
    const token = useSelector((state) => state.common.userDetails.jwtToken);
    const loading = useSelector((state) => state.req.loading);
    const [data, setData] = useState([]);
    const [details, setDetails] = useState({});
  
    const { addSignatory, removeSignatory } = useCustom();
   
    const columns = [
      {
        name: "sn",
        label: "S/N",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountName",
        label: "Investment Account Number",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountNumber",
        label: "Customer Name",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountType",
        label: "Account No.",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountBalance",
        label: "Investment Product",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "currency",
        label: " Investment Amount",
        options: {
          filter: true,
          sort: false,
        },
      },
     
      {
        name: "currency",
        label: "Investment Book Date",
        options: {
          filter: true,
          sort: false,
        },
      }, {
        name: "currency",
        label: "Investment Start Date",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "currency",
        label: "Tenor",
        options: {
          filter: true,
          sort: false,
        },
      }, {
        name: "currency",
        label: "Maturity Date",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "currency",
        label: "Interest Rate",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "currency",
        label: "Maturity Amount",
        options: {
          filter: true,
          sort: false,
        },
      }, {
        name: "currency",
        label: " Expected Interest Amount",
        options: {
          filter: true,
          sort: false,
        },
      },
     
      {
        name: "authorizationStatus",
        label: "Status",
        options: {
          customBodyRender: (status) => (
            <p
              style={{
                color:
                  status === "Authorized"
                    ? "green"
                    : status === "Rejected"
                    ? "red"
                    : "rgb(224, 148, 33)",
              }}
            >
              {status}
            </p>
          ),
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => (
            <DotMenu>
              <MenuItem
                onClick={() => {
                  const singleItem = data[dataIndex];
                  if (singleItem && singleItem.id) {
                    fetchAccountDetails(
                      singleItem.accountNumber,
                      openUpdateFormModal
                    );
                  }
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const singleItem = data[dataIndex];
                  if (singleItem && singleItem.id) {
                    fetchAccountDetails(singleItem.accountNumber, openViewModal);
                  }
                }}
              >
                View
              </MenuItem>
            </DotMenu>
          ),
        },
      },
    ];
  
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    const fetchAccountDetails = async (accNo, action) => {
      dispatch(isLoading());
      const response = await getCustomerByAccountNumber(token, accNo);
      if (response && response.data) {
        dispatch(isLoading());
        if (response.status) {
          setDetails(response.data);
          dispatch(action());
        }
      }
    };
  
    useEffect(() => {
      const fetchAllAccountEnquiry = async () => {
        dispatch(isLoading());
        const url = `${baseUrl}account/CustomerAccount/GetAll`;
        const response = await getHandler(token, url);
        if (response && response.data) {
          console.log("data is here",response.data)
          dispatch(isLoading());
          console.log(response.data);
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].sn = i + 1;
            }
          }
          setData(response.data);
        }
      };
      fetchAllAccountEnquiry();
    }, [dispatch, token, updateTable]);
     
    return (
        <div className='InvestmentMaturity border--main'>
            <div style={{ position: 'absolute'}}>
            </div>
            <div className="headerdiv">
                <p>Reports | Investment Maturity Report</p>
            </div>

            <main className="section--1">
        <div className="sub-main flex--1">
              
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Branch Code</label>
            <input type="text"></input>
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input type="date"></input>
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input type="date"></input>
          </div>
        
        </div>

        <div className="button--1 flex--1">
          <button>Search</button>
          <button>Reset</button>
        </div>
        </main>
        <hr />
             <div>
          <Spinner loading={loading} />
          {!loading && (
            <Table
              title="Investment Maturity Report"
              subtitle=""
              name="List of Reports"
              records={data}
              options={options}
              columns={columns}
            />
          )}
          {/* {details && details.id && <Details details={details} />}
          {details && details.id && (
            <EditForm
              details={details}
              removeSignatory={removeSignatory}
              addSignatory={addSignatory}
            />
          )} */}
        </div>
        </div>
    )
}

export default InvestmentMaturityReport;

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from '../../../Details/LoanAccount/LoanFacilityLimit'
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getAllLoanFacilityLimit, getLoanFacilityLimitByAccountNum } from "../../../../Api/Hooks-manager/loanAccountOperations";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
} from "../../../../Api/redux-manager/actions";
import { Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";



const LoanFacilityLimit = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
        name: "sn",
        label: "S/N",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountName",
        label: "Customer Name",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountNo",
        label: "Account No.",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountBranch",
        label: "Account Branch",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "accountCurrency",
        label: "Currency",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "approvedLoanLimit",
        label: "Approved Loan Limit",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "loanLimitBalance",
        label: "Loan Limit Balance",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "authorizationStatus",
        label: "Status",
        options: {
          customBodyRender: (status) => (
            <p
              style={{
                color:
                  status === "Authorized"
                    ? "green"
                    : status === "Rejected"
                    ? "red"
                    : "rgb(224, 148, 33)",
              }}
            >
              {status}
            </p>
          ),
        },
      },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };

  const fetchAllLoanLimitFacility = useCallback(async () => {
    dispatch(loading());
    const allResponse = await getAllLoanFacilityLimit(token);
    dispatch(loading());
    const response = allResponse.data.filter(
      (item) =>
        item.authorizationStatus === "Pending" ||
        item.authorizationStatus === "PendingEditedAuthorization"
    );
    if (response && response.length > 0){
      for (let i = 0; i < response.length; i++) {
        response[i].sn = i + 1;
        response[i].dateAuthorized = formattedDate(
          response[i].dateAuthorized
        );
        response[i].updatedDateTime = formattedDate(
          response[i].updatedDateTime
        );
      };
      setData(response);
}
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllLoanLimitFacility();
  }, [fetchAllLoanLimitFacility, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && (<Table
        title="Loan Facility Limit"
        subtitle=""
        name="Lists of account loan limits"
        records={data}
        options={options}
        columns={columns}
      />)}
      <DetailsContainer title="Loan facility limits - Record Details">
        <Details details={details}/>
        </DetailsContainer>
      <AuthorizationModal
        title="Authorize Loan Limit"
        question="Are you sure you want to authorize this loan limit?"
        url={`${Routes.authorizeLoanLimit}${details.id}`}
      />
    </div>
  );
};

export default LoanFacilityLimit;

import Item from "../../DatagridTable/item";
import { Grid } from "@material-ui/core";

const DenominationDefinition = ({ details }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Denomination" name={details.denomination} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Currency" name={details.currency} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Currency" name={details.currencySymbol} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Create Date Time" name={details.createdDateTime} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Updated Date Time" name={details.updatedDateTime} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Date Authorized" name={details.dateAuthorized} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Status"
          name={
            details.authorizationStatus === "PendingEditedAuthorization"
              ? "Pending Edited Authorization"
              : details.authorizationStatus
          }
        />
      </Grid>
    </Grid>
  );
};

export default DenominationDefinition;

import { Grid } from '@material-ui/core';

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
  countries,
  states,
  allBranches,
  allRoles
}) => {
  return (
    <EditModal
      title="Update user"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this user record?"
      data={details}
      url={`${Routes.updateUserAccess}${details.id}`}
    >
        <Grid container spacing={1} className="user--account">
              <Grid item xs={12} sm={12} md={6}>
                <label>Username</label>
                <input
                  type="text"
                  value={details.userName}
                  name="userName"
                  onChange={handleEditChange("userName")}
                  className={`${errorEdit.userName && "error-input-z"}`}
                />
                {errorEdit.userName && <ErrorMessage message={errorEdit.userName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>First Name</label>
                <input
                  type="text"
                  value={details.firstName}
                  name="firstName"
                  onChange={handleEditChange("firstName")}
                  className={`${errorEdit.firstName && "error-input-z"}`}
                />
                {errorEdit.firstName && <ErrorMessage message={errorEdit.firstName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Middle Name</label>
                <input
                  type="text"
                  value={details.middleName}
                  name="middleName"
                  onChange={handleEditChange("middleName")}
                  className={`${errorEdit.middleName && "error-input-z"}`}
                />
                {errorEdit.middleName && (
                  <ErrorMessage message={errorEdit.middleName} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Last Name</label>
                <input
                  type="text"
                  value={details.lastName}
                  name="lastName"
                  onChange={handleEditChange("lastName")}
                  className={`${errorEdit.lastName && "error-input-z"}`}
                />
                {errorEdit.lastName && <ErrorMessage message={errorEdit.lastName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Mobile</label>
                <input
                  type="tel"
                  name="mobileNumber"
                  value={details.mobileNumber}
                  maxLength='12'
                  onChange={handleEditChange("mobileNumber")}
                  
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <label>Country </label>
                <br />
                <select
                  onChange={handleEditChange("countryCode")}
                  className={errorEdit.countryCode ? "error-input-z" : null}
                  value={details.countryCode}
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option value={country.countryCode2} key={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errorEdit.countryCode && (
                  <ErrorMessage message={errorEdit.countryCode} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>State</label>
                <br />
                <select
                  value={details.stateName}
                  name="stateName"
                  onChange={handleEditChange("stateName")}
                  className={`${errorEdit.stateName && "error-input-z"}`}
                >
                  <option value="">Select a state</option>
                  {states.map((state) => (
                    <option value={state.name} key={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errorEdit.stateName && <ErrorMessage message={errorEdit.stateName} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Role</label>
                <br />
                <select
                  value={details.role}
                  name="role"
                  onChange={handleEditChange("role")}
                  className={`${errorEdit.role && "error-input-z"}`}
                >
                  <option value="">select</option>
                  {allRoles.map((item) =>(
                    <option
                    value ={item.name}
                    key={item.id}
                    >{item.name}</option>
                  ))}
                </select>
                {errorEdit.role && <ErrorMessage message={errorEdit.role} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Branch Code</label>
                <br />
                <select
                  value={details.branchCode}
                  name="branchCode"
                  onChange={handleEditChange("branchCode")}
                  className={`${errorEdit.branchCode && "error-input-z"}`}
                >
                  <option value="Select a branch">Select a branch</option>
                  {allBranches.map((branch) => (
                    <option
                      value={branch.code}
                      key={branch.id}
                    >{`${branch.name} - ${branch.code}`}</option>
                  ))}
                </select>
                {errorEdit.branchCode && (
                  <ErrorMessage message={errorEdit.branchCode} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Home Branch</label>
                <input type="text" value={details.homeBranch} disabled />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label>Gender</label>
                <br />
                <select
                  value={details.gender}
                  name="gender"
                  onChange={handleEditChange("gender")}
                  className={`${errorEdit.gender && "error-input-z"}`}
                >
                  <option value="">select</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
                {errorEdit.gender && <ErrorMessage message={errorEdit.gender} />}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label>Email</label>
                <input
                  type="email"
                  value={details.emailAddress}
                  name="emailAddress"
                  onChange={handleEditChange("emailAddress")}
                  className={`${errorEdit.emailAddress && "error-input-z"}`}
                />
                {errorEdit.emailAddress && (
                  <ErrorMessage message={errorEdit.emailAddress} />
                )}
                <br />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <div className='checkboxdiv flex--2'>
                <div className='label-input flex--2'>
                  <label>Loan Officer</label>
                  <input  
                  checked={details.isLoanOfficer}
                  name="isLoanOfficer"
                  type="checkbox"
                  className="inputcheck"
                  onChange={handleEditChange("isLoanOfficer")}
                />
                </div>
                <div className='label-input flex--2'>
                  <label>Account Officer</label>
                  <input
                    checked={details.isAccountOfficer}
                    name="isAccountOfficer"
                    type="checkbox"
                    className="inputcheck"
                    onChange={handleEditChange("isAccountOfficer")}
                    
                    />
                </div>
              </div>
          </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>
            </Grid>
    </EditModal>
  );
};

export default EditData;

import React, { useState } from "react";
import "./CustomerCredit.scss";
import Paper from "@material-ui/core/Paper";
import BusinessLogic from "./businessLogic";
import NumberFormat from "react-number-format";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";

const CustomerCredit = () => {

  const {
    glTransaction,
    allBranches,
    loading,
    creditAccDetails,
    debitAccDetails,
    errors,
    handleChange,
    makeTransfer,
    open,
    setOpen,
    message,
    handleSubmit,
    EmptyFieldMakeTransfer,
    clearCreditAccountDetails,
    clearDebitAccountDetails,

  } = BusinessLogic();

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(value);

  return (
    <div className="customer--credit">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={makeTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <div className="headerdiv">
        <p>Miscellaneous Customer Credit</p>
      </div>
      <Paper style={{ padding: 25 }}>
        {/* <Grid container spacing={1}> */}
          <div className="input-container--1 flex--1">
            <div className="flex--3">
              <label>GL Account Number</label>
              <input
                type="text"
                className={`pink ${errors.debitAccount ? "error-input-z" : null}`}
                name="debitAccount"
                value={glTransaction.debitAccount}
                onChange={handleChange}
              />
              {errors.debitAccount && (
                <ErrorMessage message={errors.debitAccount} />
              )}
            </div>
            {/* <div className="flex--3">
              <label style={{ color: "white" }}>Account Name</label>
              <input
                type="text" 
              
                disabled
                value={debitAccDetails.accountName}
                onChange={() => {}}
              />
            </div> */}
            <div className="flex--3">
              <label>GL Branch</label>
              <select
              disabled
              name="entryBranch"
              value={debitAccDetails.accountBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
            </div>
            <div className="flex--3">
              <label> Debit Branch</label>
              <select
              name="debitAccountBranch"
              className={`${
                errors.debitAccountBranch ? "error-input-z" : null
              }`}
              value={glTransaction.entryBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
            {errors.debitAccountBranch && (
                <ErrorMessage message={errors.debitAccountBranch} />
              )}
            </div>
          </div>
          <div className="input-container--1 flex--1">
            <div className="currency flex--3">
            <label>GL Currency</label>
            <input
              type="text"
              disabled  
              className="pink"
              name="currencyCode"
              value={glTransaction.currencyCode}
              onChange={() => ""}
            />
            </div>
          </div>
          <div className="input-container--1 flex--1">
            <div className="flex--3">
              <label>Customer Account Number</label>
              <input
                type="number"
                className={`pink ${
                  errors.creditAccount ? "error-input-z" : null
                }`}
                name="creditAccount"
                value={glTransaction.creditAccount}
                onChange={handleChange}
              />
              {errors.creditAccount && (
                <ErrorMessage message={errors.creditAccount} />
              )}
            </div>
            <div className="flex--3">
              <label style={{ color: "white" }}>Account Name</label>
              <input
                type="text"
                disabled
                value={creditAccDetails.accountName}
              />  
            </div>
            <div className="flex--3">
              <label>Destination Branch</label>
              <select
              disabled
              className="pink"
              name="creditAccountBranch"
              value={creditAccDetails.accountBranch}
              onChange={handleChange}
            >
              <option>Select a branch</option>
              {allBranches.map((item) => (
                <option
                  value={item.code}
                  key={item.id}
                >{`${item.name} - ${item.code}`}</option>
              ))}
            </select>
            </div>
          </div>
          <div className="input-container--1 flex--1">
            <div className="currency flex--3">
              <label>Destination Currency</label>
              <input
                disabled
                name="currencyCode"
                value={creditAccDetails.currencyCode}
                onChange={() => {}}
              />
            </div>
            <div className="currency flex--3">
              <label>Amount</label>
              <NumberFormat
                value={glTransaction.postAmount}
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="postAmount"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={({floatValue})=>handleChange({
                  target: {
                    name:'postAmount', 
                    value: floatValue
                  }
                })}
                // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                className={`some ${
                  errors.postAmount ? "error-input-z" : null
                }`}
                // onValueChange={(values) => {
                //   setGlTransaction((prev) => ({
                //     ...prev,
                //     postAmount: parseInt(values.value),
                //   }));
                // }}
              />
              {/* <input
                type="number"
                className={`debit--GL--input ${
                  errors.postAmount ? "error-input-z" : null
                }`}
                name="postAmount"
                value={glTransaction.postAmount.toFixed(2)}
                onChange={handleChange}
              /> */}
              {errors.postAmount && <ErrorMessage message={errors.postAmount} />}
            </div>
          </div>
          <div className="flex--3">
            <label>Narration</label>
            <input
              type="text"
              className={`input ${
                errors.narration ? "error-input-z" : null
              }`}
              name="narration"
             value={glTransaction.narration}
             onChange={handleChange}
            />
            {errors.narration && <ErrorMessage message={errors.narration} />}
          </div>
        {/* </Grid> */}
        <button className="btn" onClick={handleSubmit}>
          {" "}
          Save
        </button>
      </Paper>
    </div>
  );
};
export default CustomerCredit;

import * as actionTypes from '../constants';
import axios from 'axios'

//----------------------spinner----------------------------

export const loading = () => {
    return {
        type : actionTypes.LOAD_SPINNER
    };
};

//----------------------DETAILS modal----------------------------

export const openDetailsModal = () => {
    return {
        type : actionTypes.OPEN_DETAILS_MODAL
    };
};

export const closeDetailsModal = () => {
    return {
        type : actionTypes.CLOSE_DETAILS_MODAL
    };
};

  
//----------------------form modal----------------------------

export const openFormModal = () => {
    return {
        type : actionTypes.OPEN_FORM_DIALOGUE
    };
};

export const closeFormModal = () => {
    return {
        type : actionTypes.CLOSE_FORM_DIALOGUE
    };
};

//----------------------DECISION modal----------------------------

export const openModal = (payload) => {
    return {
        type : actionTypes.OPEN_DECISION_MODAL,
        payload
    };
};

export const newOpenModal = (payload) => {
    return {
        type : actionTypes.NEW_OPEN_DECISION_MODAL,
        payload
    };
};

export const closeDecisionModal = () => {
    return {
        type : actionTypes.CLOSE_DECISION_MODAL
    };
};



export const openDecisionModal = (payload) => {
    return dispatch => {
        dispatch(closeFormModal());
        dispatch(openModal(payload));
    }
};

export const newOpenDecisionModal = (payload) => {
    return dispatch => {
        dispatch(closeFormModal());
        dispatch(newOpenModal(payload));
    }
};

//----------------------Authorize form----------------------------

export const authFormStart = () => {
    return {
        type : actionTypes.APPROVE_FORM_START
    };
};

export const authFormSuccess = (payload) => {
    return {
        type : actionTypes.APPROVE_FORM_SUCCESS,
        status: payload.status,
        message: payload.responseMessage
    };
};

export const authFormFail = (error) => {
    return {
        type : actionTypes.APPROVE_FORM_SUCCESS,
        status: error.status,
        message: error.responseMessage
    };
};

export const authorizeForm = (token, data, url, method) => {
    
    return dispatch => {
        dispatch(authFormStart());
         axios({
            method : method || 'put', //this line should allow only post or put. the only file we use post is when we want to authorize fund transactions
            data: data,
            url: url,
            headers : {
              'Authorization' : `Bearer: ${token}`
            }
          })
          .then(res => {
              dispatch(authFormSuccess(res.data))
          })
          .catch(err => {
            dispatch(authFormFail(err.response.data))
          })
    }
};

//----------------------CLOSE RESPONSE MODAL----------------------------

export const closeResponseModal = () => {
    return {
        type : actionTypes.CLOSE_RESPONSE_MODAL
    };
};

export const openResponseModal = () => {
    return {
        type : actionTypes.OPEN_RESPONSE_MODAL
    };
};
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
}));

export default function SimpleTooltips({ children, title, show }) {
  const classes = useStyles();

  return (
    <div>
      {show ? (
        <Tooltip title={title} aria-label="add">
          <Fab color="primary" className={classes.fab}>
            {children}
          </Fab>
        </Tooltip>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
}

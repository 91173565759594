import react from "react";
import "./LoanScheduleDetails.scss";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ErrorMessage from "../../../ErrorMessage";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../../../Backdrop";
import { useDispatch } from "react-redux";
import BusinessLogic from "./businessLogic";
import LoanRepaymentTable from "../../../DatagridTable/table";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import Table from "../../../DatagridTable/table";
import RepaymentSchedule from "./view";
import {
  isLoading,
  openViewModal,
  openUpdateFormModal,
  openResquestDecisionModal,
} from "../../../../Api/redux-manager/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  item: {
    width: "100%",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    width: "90%",
    padding: "0.5rem",
    // height: 46,
    marginTop: 20,
    marginRight: "2rem",
    borderRadius: "5px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
}));

const LoanScheduleDetails = () => {
  const {
    loanDetails,
    setLoanDetails,
    repayment,
    accNum,
    allLoanAccountNum,
    invalidAccount,
    details,
    debitAccountDetails,
    amount,
    repaymentAccountDetails,
    allLoanRepayment,
    setAmount,
    setDetails,
    handleChange,
    handleAccNum,
    postLoanRepayment,
    screenChange,
    handleSubmit,
    errors,
    open,
    setOpen,
    loading,
    allCurrencyDefinition,
    message,
    openDecision,
    setOpenDecision,
    decide,
    repaymentSchedule,
    fullLoanDetails,
    setFullLoanDetails,
    setRepayment,
    sampleShedule,
    fetchLoanShedule,
    openShedule,
    openRepayment,
    setOpenRepayment,
    setOpenSchedule,
    onEditedScheduleSave,
    updateLoanSchedule,
    fetchLoanAccountDetails,
    repaymentDetails,
    fetchAllLoanRepaymentsByAccNum,
    updateData,
  } = BusinessLogic();
  const dispatch = useDispatch();
  const classes = useStyles();

  const loanAccountNum = repayment.loanAcountNo;

  // console.log(repayment);

  const columns = [
    {
      name: "loanAccount",
      label: "Loan Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Debit Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bookDate",
      label: "Book Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString();
        },
      },
    },
    {
      name: "loanProductName",
      label: "Loan Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "loanStatus",
    //   label: "Loan Status",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "authorizationStatus",
      label: "Authorization Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status = "") => (
          <p
            style={{
              color:
                status.toLowerCase() === "authorized"
                  ? "green"
                  : status.toLowerCase() === "rejected"
                  ? "yellow"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "sn",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <button
            className={classes.button}
            onClick={() => fetchAllLoanRepaymentsByAccNum(loanAccountNum)}
          >
            Edit schedule
          </button>
        ),
      },
    },
  ];

  // const newDetails = fullLoanDetails.repayments;

  // console.log(newDetails, "new details o loan")

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    expandableRows: false,
    renderExpandableRow: (rowData, rowMeta) => {
      // return (
      // // <LoanRepaymentSubTable
      // //   data={repaymentSchedule}
      // //   setDetails={setDetails}
      // //   dispatch={dispatch}
      // // />
      // )
    },
  };

  return (
    <div className="loanschedule-details">
      <Spinner loading={loading} />
      <div className="headerdiv">
        <p>Loan Schedule Details</p>
      </div>
      <Paper
        style={{
          marginTop: 10,
          padding: " 25px 50px 0 25px ",
          boxShadow: "2px 2px 2px 2px white-smoke",
        }}
      >
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4}>
              <label>Repayment Method</label>
              <br />
              <select disabled name="repaymentType">
                <option value="">Select repayment method</option>
                <option value="Transfer">Account Transfer</option>
                <option value="Cash">By Cash</option>
              </select>
            </Grid>
            <Grid item xs={12} sm={12} md={8}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Customer Account No.</label>
              <br />
              <input
                type="text"
                className={invalidAccount ? "error-input-z" : null}
                value={accNum}
                onChange={handleAccNum}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Loan Account No</label>
              <br />
              <select
                className={errors.loanAcountNo ? "error-input-z" : null}
                name="loanAcountNo"
                value={repayment.loanAcountNo}
                onChange={handleChange}
              >
                <option value="">Select an account number</option>
                {allLoanAccountNum.map((el, i) => (
                  <option key={i} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </select>
              {errors.loanAcountNo && (
                <ErrorMessage message={errors.loanAcountNo} />
              )}
            </Grid>
            {details.accountName && (
              <Grid item xs={12} sm={6} md={4}>
                <label>Customer Name</label>
                <br />
                <input type="text" value={details.accountName} disabled />
              </Grid>
            )}
          </Grid>
        </>
      </Paper>

      {openRepayment && (
        <RepaymentSchedule
          open={openRepayment}
          onClose={() => setOpenRepayment(false)}
          details={details}
          repaymentShedule={repaymentDetails}
          onEditedScheduleSave={onEditedScheduleSave}
          loanAccountNum={loanAccountNum}
        />
      )}
      {fullLoanDetails.repayments && fullLoanDetails.repayments.length > 0 && (
        <LoanRepaymentTable
          title="Repayment Schedule"
          subtitle=""
          name="Repayment Schedule"
          records={[fullLoanDetails]}
          options={options}
          columns={columns}
        />
      )}

      {/* {details && details.id && <Details details={details} />} */}
    </div>
  );
};

export default LoanScheduleDetails;

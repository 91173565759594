import React from 'react'
import { Switch, Route } from "react-router-dom";
import CreditInterestReport from './CreditInterestReport/CreditInterestReport'
import AccountsOpenedToday from './AccountsOpenedToday/AccountsOpenedToday'
import DebitInterestReport from './DebitInterestReport/DebitInterestReport'
import AccountAccrualReport from './AccountAccrualReport/AccountAccrualReport'
import CustomerBalanceAccount from "./CustomerBalanceReport/Cabal"

const Main = () => {
    return (
        <div>
            <Switch> 
                <Route path='/dashboard/reports/account/opened' component={AccountsOpenedToday}/>
                <Route path='/dashboard/reports/account/debitinterest' component={DebitInterestReport}/>
                <Route path='/dashboard/reports/account/creditinterest' component={CreditInterestReport}/>
                <Route path='/dashboard/reports/account/accrual' component={AccountAccrualReport}/>
                <Route path='/dashboard/reports/account/cabal' component={CustomerBalanceAccount}/>

            </Switch>
        </div>
    )
}

export default Main

import { useSelector } from "react-redux";
import {
  Grid,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import ErrorMessage from "../../../ErrorMessage";
import EditModal from "../../../DatagridTable/edit";
import { Routes } from "../../../../Api/configUrl";

const EditBranchDef = ({
  editBranch,
  errorEdit,
  handleEditChange,
  states,
  allBranches,
  children,
  handleEditSubmit,
}) => {
  const countries = useSelector(state => state.common.countries);
  return (
    <EditModal
      title="Update instution"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={editBranch}
      url={`${Routes.editABranch}${editBranch.id}`}
    >
      <Grid container spacing={1} className="institution--definition">
        <Grid item xs={12} sm={6} md={6}>
          <label>Branch Name</label>
          <br />
          <input
            type="text"
            className={errorEdit.name ? "error-input-z" : null}
            value={editBranch.name}
            onChange={handleEditChange("name")}
          />
          {errorEdit.name && <ErrorMessage message={errorEdit.name} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Branch Address</label>
          <br />
          <input
            className={errorEdit.address ? "error-input-z" : null}
            type="text"
            value={editBranch.address}
            onChange={handleEditChange("address")}
          />
          {errorEdit.address && <ErrorMessage message={errorEdit.address} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Country</label>
          <br />
          <select
            onChange={handleEditChange("countryId")}
            className={errorEdit.countryId ? "error-input-z" : null}
            value={editBranch.countryId}
          >
            <option value="">Select a country</option>
            {countries.map(country => (
                <option value={country.countryCode2} key={country.id}>
                  {country.name}{" "}
                </option>
              ))}{" "}
          </select>
          {errorEdit.countryId && (
            <ErrorMessage message={errorEdit.countryId} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>State</label>
          <br />
          <select
            onChange={handleEditChange("stateId")}
            className={errorEdit.stateId ? "error-input-z" : null}
            value={editBranch.stateId}
          >
            <option value="">Select a state</option>
            {states.map(state => (
                <option value={state.name} key={state.id}>
                  {state.name}{" "}
                </option>
              ))}{" "}
          </select>
          {errorEdit.stateId && <ErrorMessage message={errorEdit.stateId} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>LGA</label>
          <br />
          <input
            className={errorEdit.lga ? "error-input-z" : null}
            type="text"
            value={editBranch.lga}
            onChange={handleEditChange("lga")}
          />
          {errorEdit.lga && <ErrorMessage message={errorEdit.lga} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Parent Branch Code</label>
          <br />
          <select
            value={editBranch.code}
            onChange={handleEditChange("parentBranchId")}
          >
            <option value="">Select parient branch</option>
            {allBranches.map((item) => (
              <option
                value={item.code}
                key={item.id}
              >{`${item.name} - ${item.code}`}</option>
            ))}
          </select>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Branch Code</label>
          <br />
          <input
            type="text"
            className={errorEdit.code ? "error-input-z" : null}
            value={editBranch.code}
            onChange={handleEditChange("code")}
          />
          {errorEdit.code && <ErrorMessage message={errorEdit.code} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Branch Suspense LA</label>
          <br />
          <select
            className={errorEdit.suspenseLedgerAccount ? "error-input-z" : null}
            value={editBranch.suspenseLedgerAccount}
            onChange={handleEditChange("suspenseLedgerAccount")}
          >
            <option value="">Select branch suspense account</option>
            {children.map((child) => (
              <option
                value={child.accountNumber}
                key={child.id}
              >{`${child.accountName} - ${child.accountNumber}`}</option>
            ))}
          </select>
          {errorEdit.suspenseLedgerAccount && (
            <ErrorMessage message={errorEdit.suspenseLedgerAccount} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Branch Vault Account</label>
          <br />
          <select
            className={errorEdit.vaultAccount ? "error-input-z" : null}
            value={editBranch.vaultAccount}
            onChange={handleEditChange("vaultAccount")}
          >
            <option value="">Select branch suspense account</option>
            {children.map((child) => (
              <option
                value={child.accountNumber}
                key={child.id}
              >{`${child.accountName} - ${child.accountNumber}`}</option>
            ))}
          </select>
          {errorEdit.vaultAccount && (
            <ErrorMessage message={errorEdit.vaultAccount} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Work Time</label>
          <div className="work-time">
            <span>Start Time</span>
            <input
              className={errorEdit.startTime ? "error-input-z" : null}
              type="time"
              value={editBranch.startTime}
              style={{ minWidth: "30px", marginLeft: 120 }}
              onChange={handleEditChange("startTime")}
            />
            {errorEdit.startTime && (
              <ErrorMessage message={errorEdit.startTime} />
            )}
            <br />
            <span>Stop Time</span>
            <input
              className={errorEdit.endTime ? "error-input-z" : null}
              type="time"
              value={editBranch.endTime}
              style={{ minWidth: "30px", marginLeft: 120 }}
              onChange={handleEditChange("endTime")}
            />
            {errorEdit.endTime && <ErrorMessage message={errorEdit.endTime} />}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          Status
          <FormControl component="fieldset" className="status">
            <RadioGroup
              aria-label="status"
              name="status"
              value={editBranch.status.toString()}
              onChange={handleEditChange("status")}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </EditModal>
  );
};

export default EditBranchDef;

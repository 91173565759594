import React from 'react';
import './McLiquidation.scss';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 


 const McLiquidation = () => {
    return (
           <div className="mc--liquidation">
      <div className="headerdiv">
        <p>Managers Cheque - Liquidation</p>
      </div>
      <Paper style={{ marginTop: 20, padding: 25 }}>
        <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6}>
            <label>Transaction Type</label>
            <br />
            <select>
              <option>Teller Production Definition</option>
              <option>Cash</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <label>Account Number</label>
            <br />
            <input type="text" className='pink'/>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <label style={{color:'white'}}>Account</label>
            <input type="text" />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <label>Product</label>
            <br />
            <input type="text" className='pink'/>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <label style={{color:'white'}}>Account</label>
            <input type="text" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Account Currency</label>
            <br />
            <input type="text" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Issuing Branch Number</label>
            <br />
            <input type="text" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <label>Demand Draft Number</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <label>Amount</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <label>Net Amount</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <label>Beneficiary Name</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <label>Beneficiary document</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <label>Date Issue</label>
            <br />
            <input type="date" />
            </Grid>
            <Grid  item xs={12} sm={6} md={6}>
          <div className="radiodiv">
              <span>Transaction Type:</span>
             <input type="radio" className="productradio" name="type" value='tci' style={{marginLeft:10}} />
             <label className="span">TCI</label>
             <input type="radio" className="productradio" name='type' value='tbi' style={{marginLeft:20}}/>
             <label className="span">TBI</label>
          </div>
          
          </Grid>
          <Grid item xs={12} sm={12} md={6}>

          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <label>Mc Liquidation Date</label>
            <br />
            <input type="date" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <label>Narration</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <label>Instrument Status</label>
            <br />
            <input type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            </Grid>
            <div className='flex--1 button--cont'>
              <button className='btn'> Save</button>
            </div>
            </Grid>
          </Paper>
        </div>  
      
    )
} 
export default McLiquidation;
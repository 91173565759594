import { useState, useCallback } from 'react'
import { baseUrl } from  '../../../../Api/configUrl';
import axios from 'axios' 
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../../formatter/date";
import {
    isLoading,
  } from "../../../../Api/redux-manager/actions";

const useBusinessLogic = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [allPortfolioMaintainance, setAllPortfolioMaintainance] = useState([]);
    const token = useSelector((state) => state.common.userDetails.jwtToken);
    const [data, setData] = useState([]);


   
    const getAllPortfolioMaintainance = useCallback(async () => {
        dispatch(isLoading());
        const url = `${baseUrl}treasury/PortfolioMaintenance/GetAll`;
        const response = await getHandler(token, url);
        if (response && response.data) {
          dispatch(isLoading());
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].sn = i + 1;
              response.data[i].createdDateTime = formattedDate(
                response.data[i].createdDateTime
              );
              response.data[i].dateAuthorized = formattedDate(
                response.data[i].dateAuthorized
              );
              response.data[i].updatedDateTime = formattedDate(
                response.data[i].updatedDateTime
              );
            }
          }
          setData(response.data);
        }
      },[]);

    return { getAllPortfolioMaintainance, loader, setLoader, allPortfolioMaintainance, data }
}

export default useBusinessLogic

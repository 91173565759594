import * as actionTypes from '../constants';
import axios from 'axios'


// ----------------------spinner----------------------------

export const isLoading = () => {
    return {type: actionTypes.IS_LOADING};
};

// ----------------------view modal----------------------------

export const openViewModal = () => {
    return {type: actionTypes.OPEN_VIEW_MODAL};
};

export const closeViewModal = () => {
    return {type: actionTypes.CLOSE_VIEW_MODAL};
};


// ----------------------form modal----------------------------

export const openUpdateFormModal = () => {
    return {type: actionTypes.OPEN_UPDATE_FORM_MODAL};
};

export const closeUpdateFormModal = () => {
    return {type: actionTypes.CLOSE_UPDATE_FORM_MODAL};
};

// ----------------------Decision modal----------------------------

export const openResquestDecisionModal = () => {
    return {type: actionTypes.OPEN_REQUEST_MODAL};
};

export const closeResquestDecisionModal = () => {
    return {type: actionTypes.CLOSE_REQUEST_MODAL};
};

// ----------------------Response modal----------------------------

export const openResquestResponseModal = () => {
    return {type: actionTypes.OPEN_REQUEST_RESPONSE_MODAL};
};

export const closeResquestResponseModal = () => {
    return {type: actionTypes.CLOSE_REQUEST_RESPONSE_MODAL};
};

// ----------------------DELETE Decision modal----------------------------

export const openDeleteDecisionModal = () => {
    return {type: actionTypes.OPEN_DELETE_DECISION_MODAL};
};

export const closeDeleteDecisionModal = () => {
    return {type: actionTypes.CLOSE_DELETE_DECISION_MODAL};
};

// ----------------------delete Response modal----------------------------

export const openDeleteResponseModal = () => {
    return {type: actionTypes.OPEN_DELETE_RESPONSE_MODAL};
};

export const closeDeleteResponseModal = () => {
    return {type: actionTypes.CLOSE_DELETE_RESPONSE_MODAL};
};


// ----------------------Interact with db ----------------------------

export const authFormStart = () => {
    return {type: actionTypes.MAKE_REQUEST_START};
};

export const authFormSuccess = (payload) => {
    return {type: actionTypes.MAKE_REQUEST_SUCCESS, status: payload.status, message: payload.responseMessage};
};

export const authFormFail = (error) => {
    return {type: actionTypes.MAKE_REQUEST_FAIL, status: error.status, message: error.responseMessage};
};

export const deleteItemStart = () => {
    return {type: actionTypes.DELETE_ITEM_START};
};

export const deleteItemSuccess = (payload) => {
    return {type: actionTypes.DELETE_ITEM_SUCCESS, status: payload.status, message: payload.responseMessage};
};

export const deleteItemFail = (error) => {
    return {type: actionTypes.DELETE_ITEM_FAIL, status: error.status, message: error.responseMessage};
};

export const makeRequest = (token, data, url, method) => {
    return dispatch => {
        dispatch(authFormStart());
        axios({
            method: method || 'put', // add a method if you are not making a put request
            data: data,
            url: url,
            headers: {
                'Authorization': `Bearer: ${token}`
            }
        }).then(res => {
            dispatch(authFormSuccess(res.data))
        }).catch(err => {
            dispatch(authFormFail(err.response.data))
        })
    }
};

export const deleteItem = (token, url, method,data) => {
    return dispatch => {
        dispatch(deleteItemStart());
        axios({
            method: method || 'delete',
            data: method && data ? data : null,
            url: url,
            headers: {
                'Authorization': `Bearer: ${token}`
            }
        }).then(res => {
            dispatch(deleteItemSuccess(res.data))
        }).catch(err => {
            dispatch(deleteItemFail(err.response.data))
        })
    }
};

import React, { useState, useEffect, useCallback } from "react";
import "./DenominationDefinition.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ErrorMessage from "../../../ErrorMessage";
import useCustom from "../../../../Api/Hooks-manager/coreModules/useCustom";
import {
  getAllDenominationDefinition,
  createDenominationDefinition,
} from "../../../../Api/Hooks-manager/coreModules/denominationDefinition";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const DenominationDefinition = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const [error, setError] = useState({});
  const [errorEdit, setErrorEdit] = useState({});
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const { allCurrencyDefinition, getCurrencyDefinitionAll } = useCustom();
  const [valuesDenomination, setValuesDenomination] = useState({
    denomination: "",
    currency: "",
    currencySymbol: "",
    status: true,
  });
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "denomination",
      label: "Denomination",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencySymbol",
      label: "Currency Symbol",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllDefinedDenomination = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllDenominationDefinition(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllDefinedDenomination();
  }, [fetchAllDefinedDenomination, updateTable]);

  useEffect(() => {
    if (valuesDenomination.currency) {
      setValuesDenomination(valuesDenomination => ({
        ...valuesDenomination,
        currencySymbol: valuesDenomination.currency,
      }));
    }
  }, [valuesDenomination.currency]);

  const handleChange = name => e => {
    const { value } = e.target;
    setValuesDenomination({
      ...valuesDenomination,
      [name]: value,
    });
  };

  const EmptyFields = () => {
    setValuesDenomination({
      denomination: "",
      currency: "",
      currencySymbol: "",
      status: true,
    });
  };
  const postDenomination = async () => {
    dispatch(isLoading());
    const response = await createDenominationDefinition(
      token,
      valuesDenomination
    );
    if (response && response.data) {
      setMessage(response.message);
      dispatch(isLoading());
      if (response.message === "Success") {
        EmptyFields();
        fetchAllDefinedDenomination();
        return true;
      } else {
        return false;
      }
    }
  };

  const isValidateForm = values => {
    let errors = {};
    console.log(values, "denvaluesssss");
    if (!values.denomination) {
      errors.denomination = "Field is required";
    }
    if (values.currency === "") {
      errors.currency = "Select an option ";
    }
    return errors;
  };

  const handleSave = () => {
    let checkError = isValidateForm(valuesDenomination);
    setError(checkError);
    if (Object.keys(isValidateForm(valuesDenomination)).length > 0) return;
    setError({});
    setOpen(true);
  };

  const handleEditSubmit = () => {
    const checkErrors = validateEditDenominationDefinition();
    setErrorEdit(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setErrorEdit(checkErrors);
    dispatch(openResquestDecisionModal());
  };

  const validateEditDenominationDefinition = () => {
    const { denomination, currency } = details;

    const error = {};

    if (denomination === "") {
      error.denomination = "Field is required";
    }
    if (currency === "") {
      error.currency = "Select an option";
    }

    return error;
  };

  const handleEditChange = e => {
    const { value, name } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, [getCurrencyDefinitionAll]);

  return (
    <div>
      <div className="denomination--defination">
        <Spinner loading={loading} />
        <Confirm
          ApiCall={postDenomination}
          errMessage={message}
          openBox={open}
          setOpenBoxState={setOpen}
        />
        <div className="heading">
          <p>Create Denominations</p>
        </div>
        <Paper style={{ padding: 32 }}>
          <section className="section--1">
            <div className="sub--section grid--1">
              <div className="label--input flex--3">
                <label>Denomination</label>
                <input
                  type="text"
                  name="denomination"
                  onChange={handleChange("denomination")}
                  value={valuesDenomination.denomination}
                  className={`${error.denomination && "error-input-z"}`}
                />
                {error.denomination && (
                  <ErrorMessage message={error.denomination} />
                )}
              </div>
              <div className="label--input flex--3">
                <label>Currency</label>
                <select
                  className={`currency ${error.currency && "error-input-z"}`}
                  onChange={handleChange("currency")}
                  value={valuesDenomination.currency}
                >
                  <option value="">Select a currency</option>
                  {allCurrencyDefinition.map(item => (
                    <option value={item.currencyCode} key={item.id}>
                      {item.currencyName}
                    </option>
                  ))}
                </select>
                {error.currency && <ErrorMessage message={error.currency} />}
              </div>
              <div className="label--input flex--3">
                <label>Currency Symbol</label>
                <select
                  className={`currency ${
                    error.currencySymbol && "error-input-z"
                  }`}
                  disabled
                  value={valuesDenomination.currencySymbol}
                >
                  <option value="">Select a currency</option>
                  {allCurrencyDefinition.map(item => (
                    <option value={item.currencyCode} key={item.id}>
                      {item.currencyCode}
                    </option>
                  ))}
                </select>
                {error.currencySymbol && (
                  <ErrorMessage message={error.currencySymbol} />
                )}
              </div>
            </div>

            <div className="button">
              <button onClick={handleSave}>Save</button>
            </div>
          </section>
        </Paper>
      </div>
      {!loading && (
        <Table
          title="Denomination Definition"
          subtitle=""
          name="List of denominations"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleEditSubmit}
          details={details}
          handleChange={handleEditChange}
          allCurrencyDefinition={allCurrencyDefinition}
          error={errorEdit}
        />
      )}
    </div>
  );
};
export default DenominationDefinition;

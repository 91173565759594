import { useState, useCallback, useEffect } from "react";
import "./CreditInformation.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
// import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
// import ErrorMessage from "../../../../ErrorMessage";
import { getCbrReports } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { ChangedDate } from "../../../../../formatter/date";
import { useDispatch } from "react-redux";
// import moment from "moment";
// import { format } from "date-fns";

import { isLoading } from "../../../../../Api/redux-manager/actions";

const CreditInformation = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  const [creditInformationReport, setCreditInformationReport] = useState(null);
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerId",
      label: "Customer Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountStatus",
      label: "Account Status",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "accountStatusDate",
      label: "Account Status Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{ChangedDate(value)}</p>,
      },
    },
    {
      name: "dateOfLoan",
      label: "Date Of Loan",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{ChangedDate(value)}</p>,
      },
    },
    {
      name: "creditLimit",
      label: "Credit Limit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "availedAmount",
      label: "Availed Limit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "outstandingBalance",
      label: "Outstanding Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "installmentAmount",
      label: "Installment Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "daysInArears",
      label: "Days In Arears",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "overDueAmount",
      label: "OverDue Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loantype",
      label: "Loan Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanTenor",
      label: "Loan Tenor",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "repaymentFrequency",
      label: "Repayment Frequency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastPaymentDate",
      label: "Last Payment Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{ChangedDate(value)}</p>,
      },
    },
    {
      name: "lastPaymentAmount",
      label: "Last Payment Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{ChangedDate(value)}</p>,
      },
    },
    {
      name: "loanClassification",
      label: "Loan Classification",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "legalChallengeStatus",
      label: "Legal Challenge Status",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "litigationDate",
      label: "Litigation Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const isValid = new Date(value).getFullYear() > 1975
          return (<p>{isValid ?ChangedDate(value) : ""}</p>)
      },
      },
    },
    {
      name: "consentStatus",
      label: "Consent Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanSecurityStatus",
      label: "Loan Security Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "collateralType",
      label: "Collateral Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "collateralDetails",
      label: "Collateral Details",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "previousAccountNumber",
      label: "Previous Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "previousName",
      label: "Previous Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "previousCustomerId",
      label: "Previous CustomerId",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "previousBranchCode",
      label: "Previous BranchCode",
      options: {
        filter: true,
        sort: false,
      },
    },
    //  {
    //   name: "loanStatus" ,
    //   label: "loan Status" ,
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  // if (creditInformationReport.litigationDate <  "0001-01-01T00:00:00" ){
  //   return null
  // }
  const fetchCreditInformation = useCallback(async () => {
    dispatch(isLoading());
    const response = await getCbrReports(token);
    if (response && response.data.parReports) {
      dispatch(isLoading());
      if (response && response.data.parReports.length > 0) {
        for (let i = 0; i < response.data.parReports.length; i++) {
          response.data.parReports[i].sn = i + 1;
          
        }
      }
      // if (response && response.data.parReports.litigationDate <) return null;
      
      setCreditInformationReport(response.data.parReports);
    }
  }, [token, dispatch]);
  useEffect(() => {
    fetchCreditInformation();
  }, [fetchCreditInformation]);

  return (
    <div className="creditinformation border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Credit Information</p>
      </div>{" "}
      {creditInformationReport && (
        <div>
          <Table
            title="Credit Information"
            subtitle=""
            name="List Of Credit Information"
            records={creditInformationReport}
            options={options}
            columns={columns}
          />
        </div>
      )}
      {/* </main> */}
    </div>
  );
};

export default CreditInformation;

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default function Checkboxes() {

  const handleChange = (event) => {
    // setChecked(event.target.checked);
  };

  return (
    <div>
      <span>Mobile</span>
      <Checkbox
        onChange={handleChange}
        color='white'
      
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span style={{marginLeft:'60%',}}>Internet</span>
      <Checkbox
        color='white'
    
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
}

import React from 'react';
import './Accountinstruction.scss';
import Grid from '@material-ui/core/Grid' 

 const Accountinstructions = ({ handleChange, value }) => { 
    return (
        <div className='account--instructions'>
            <Grid className='sub--main'container spacing={1} style={{padding:50}}>
                <Grid className='label--input' item xs={12} sm={12} md={6}>
                </Grid>
                <Grid className='label--input' item xs={12} sm={12} md={12}>
                    <label>Account Instruction 1</label><br/>
                    <input 
                        type='text'
                        value={value.customerAccount[0].accountInstruction1}
                        onChange={handleChange('accountInstruction1', 'customerInfo')}
                    />
                </Grid>
                <Grid className='label--input' item xs={12} sm={12} md={12}>
                    <label>Account Instruction 2</label><br/>
                    <input 
                        type='text'
                        value={value.customerAccount[0].accountInstruction2}
                        onChange={handleChange('accountInstruction2', 'customerInfo')}
                    />
                </Grid>
                <Grid className='label--input' item xs={12} sm={12} md={12}>
                    <label>Account Instruction 3</label><br/>
                    <input 
                        type='text'
                        value={value.customerAccount[0].accountInstruction3}
                        onChange={handleChange('accountInstruction3', 'customerInfo')}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
export default Accountinstructions;
import axios from 'axios';
import { Routes } from '../../configUrl';

const runTmeEod = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.runAllEodProcess}${data.processId}&branchCode=${data.branch}&branchDate=${data.branchDate}`,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: [],
          message: res.data.responseMessage,
          status: res.data.status,
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
          message:err.response.data.responseMessage,
          status:err.response.data.status,
        };
      });
  };


  const getEodLogs = async (token, data) => {
    return await axios({
      method: "get",
      url: `${Routes.getEndOfDayLogs}${data.processId}&branchCode=${data.branch}&branchDate=${data.branchDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer: ${token}`, 
      },
    })
    .then((res) => {
      return{
          data: res.data.data,
          message: res.data.responseMessage,
          status: res.data.status,
      };
    })
    .catch((err) => {
      return{
        data: [],
        message: err.response.data.responseMessage,
        status: err.response.data.status,
      }
    });
  }
  

export {runTmeEod, getEodLogs};

import "./Loanbooking.scss";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import { format } from "date-fns";
import NumberFormat from "react-number-format";
import BusinessLogic from "./businessLogic";
import Confirm from "../../../../Confirmbox/Confirm";
import DecisonModal from "../../../../Confirmbox/Confirm";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import SearchDropdown from "../../../SearchableDropdown/Search";
import Table from "../../../DatagridTable/table";
import Details, { RepaymentSchedule } from "./view";

const Loanbooking = () => {
  const {
    loanDetails,
    setLoanDetails,
    loanOfficers,
    countries,
    states,
    show,
    index,
    invalidAccNo,
    screenChange,
    setScreenChange,
    handleChange,
    handleSearchableChangeSelect,
    handleFirstScreen,
    handleSecondScreen,
    handleChargeChange,
    sampleShedule,
    openShedule,
    setOpenSchedule,
    fetchLoanShedule,
    handleLoanGuarantorsChange,
    allCurrencyDefinition,
    allGlAccount,
    allLoanProducts,
    setErrors,
    errors,
    open,
    setOpen,
    loading,
    message,
    handleSubmit,
    postLoanBooking,
    openDecision,
    setOpenDecision,
    decide,
    branches,
    columns,
    options,
    allLoanBookings,
    details,
    guarantorForm,
    loanLimit,
    onEditedScheduleSave,
    acctBal,
  } = BusinessLogic();

  console.log(loanDetails, "loandetails");

  const { accountHolder, loanCharges, loanGuarantors, accountNumber } = loanDetails;

  console.log(loanCharges, "loan charges");

  return (
    <div className="loan--booking">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={postLoanBooking}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <DecisonModal
        ApiCall={decide}
        message="Are you sure you want to authorize this record?"
        openBox={openDecision}
        actionMsg="Your records were authorized successfully"
        setOpenBoxState={setOpenDecision}
        errorMessage={message}
      />
      <div className="headerdiv">
        <p>Loan Booking - Loan Details</p>
      </div>
      <div className="flex--3">
        <div className="button--double flex--2">
          <div className="flex--3">
            <button
              className={`${screenChange === 1 ? "active-1" : ""}`}
              //onClick={() => setScreenChange(1)}
            >
              1
            </button>
            <p className="button-name">Loan Details</p>
          </div>
          <div className="flex--3">
            <button
              className={`${screenChange === 2 ? "active-1" : ""}`}
              //onClick={() => setScreenChange(2)}
            >
              2
            </button>
            <p className="button-name">Repayment Details & Charges</p>
          </div>
          <div className="flex--3">
            <button
              className={`${screenChange === 3 ? "active-1" : ""}`}
              //onClick={() => setScreenChange(3)}
            >
              3
            </button>
            <p className="button-name">Guarantor Details</p>
          </div>
        </div>
      </div>
      <Paper
        style={{
          marginTop: 20,
          marginBottom: 50,
          padding: 25,
          boxShadow: "2px 2px 2px 2px white-smoke",
        }}
      >
        {screenChange === 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div>
                  <input
                    disabled
                    type="checkbox"
                    name="accountHolder"
                    checked={loanDetails.accountHolder}
                    onChange={handleChange}
                  />
                  <label>Account Holder</label>
                </div>
              </Grid>
              {!accountHolder && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <label>Customer ID</label> <br />
                  <input type="text" disabled />
                </Grid>
              )}
              {accountHolder && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <label>Customer Account No.</label>
                  <br />
                  <input
                    type="text"
                    name="accountNumber"
                    className={errors.accountNumber ? "error-input-z" : null}
                    value={loanDetails.accountNumber}
                    onChange={handleChange}
                  />
                  {errors.accountNumber && (
                    <ErrorMessage message={errors.accountNumber} />
                  )}
                </Grid>
              )}
              {!accountHolder ||
                (loanDetails.accountName && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <label>Customer Name</label>
                    <br />
                    <input
                      disabled={accountHolder}
                      type="text"
                      name="accountName"
                      className={errors.accountName ? "error-input-z" : null}
                      value={loanDetails.accountName}
                      onChange={handleChange}
                    />
                    {errors.accountName && (
                      <ErrorMessage message={errors.accountName} />
                    )}
                  </Grid>
                ))}
              {!accountHolder ||
                (acctBal && accountNumber && (
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <label>Account Balance</label>
                    <br />
                    <NumberFormat
                      value={acctBal}
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={(value) => <div>{value}</div>}
                      className={`some ${
                        errors.amount ? "error-input-z" : null
                      }`}
                      onValueChange={(values) => {
                        setLoanDetails((prev) => ({
                          ...prev,
                          accountBalance: parseInt(values.value),
                        }));
                      }}
                      disabled={accountHolder}
                    />
                    {/* {errors.accountBalance && (
                      <ErrorMessage message={errors.accountBalance} />
                    )} */}
                  </Grid>
                ))}

              {accountHolder && loanDetails.currencyCode && (
                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <label>Account Currency</label> <br />
                  <input
                    disabled
                    type="text"
                    name="currencyCode"
                    className={errors.currencyCode ? "error-input-z" : null}
                    value={loanDetails.currencyCode}
                    onChange={handleChange}
                  />
                  {errors.currencyCode && (
                    <ErrorMessage message={errors.currencyCode} />
                  )}
                </Grid>
              )}
              {!accountHolder ||
                (loanDetails.accountBranch && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <label>Account Product</label> <br />
                    <input
                      disabled={accountHolder}
                      type="text"
                      name="productType"
                      className={errors.productType ? "error-input-z" : null}
                      value={loanDetails.productType}
                      onChange={handleChange}
                    />
                    {errors.productType && (
                      <ErrorMessage message={errors.productType} />
                    )}
                  </Grid>
                ))}
              {!accountHolder ||
                (loanDetails.accountBranch && (
                  <Grid item xs={12} sm={6} md={2} lg={2}>
                    <label>Account Branch</label> <br />
                    <input
                      disabled={accountHolder}
                      type="text"
                      name="accountBranch"
                      className={errors.accountBranch ? "error-input-z" : null}
                      value={loanDetails.accountBranch}
                      onChange={handleChange}
                    />
                    {errors.accountBranch && (
                      <ErrorMessage message={errors.accountBranch} />
                    )}
                  </Grid>
                ))}

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className="loanFacilityLimit"
              >
                <label>Loan Facility Limit</label> <br />
                <input
                  disabled
                  type="text"
                  name="loanLimit"
                  className={errors.loanLimit ? "error-input-z" : null}
                  value={loanLimit}
                  onChange={handleChange}
                />
                {errors.loanLimit && (
                  <ErrorMessage message={errors.loanLimit} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <label>Loan Product</label>
                <br />
                <select
                  name="loanProduct"
                  className={errors.loanProduct ? "error-input-z" : null}
                  value={loanDetails.loanProduct}
                  onChange={handleChange}
                >
                  <option value="">Select loan product</option>
                  {allLoanProducts.map((item) => (
                    <option
                      key={item.id}
                      value={item.productCode}
                    >{`${item.productCode} - ${item.productName}`}</option>
                  ))}
                </select>
                {errors.loanProduct && (
                  <ErrorMessage message={errors.loanProduct} />
                )}
              </Grid>
            </Grid>
            {show && (
              <section className="borrowed-class flex--1">
                <div className="label-input">
                  <label>Loan Amount (NGN)</label>
                  <div className="flex--2 input-span">
                    {/* <span className="span-abs">NGN</span> */}
                    <NumberFormat
                      value={loanDetails.amount}
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={(value) => <div>{value}</div>}
                      className={`some ${
                        errors.amount ? "error-input-z" : null
                      }`}
                      onValueChange={(values) => {
                        setLoanDetails((prev) => ({
                          ...prev,
                          amount: parseInt(values.value),
                        }));
                      }}
                    />
                    {/* <input
                      type="text"
                      name="amount"
                      className={
                        errors.amount ? "error-input-z input-abs" : "input-abs"
                      }
                      value={loanDetails.amount}
                      onChange={handleChange}
                    /> */}
                  </div>
                  {errors.amount && <ErrorMessage message={errors.amount} />}
                </div>
                <div className="label-input">
                  <label>Loan Start Date</label>
                  <div className="input-span input-span--1">
                    <input
                      type="date"
                      name="startDate"
                      //min={DateConverter(new Date())}
                      className={errors.startDate ? "error-input-z" : null}
                      value={loanDetails.startDate}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.startDate && (
                    <ErrorMessage message={errors.startDate} />
                  )}
                </div>

                <div className="label-input flex--3">
                  <label>Interest Calculation Method</label>
                  <div className="input-span input-span--1">
                    <select
                      disabled
                      name="interestCalcMethod"
                      className={
                        errors.interestCalcMethod ? "error-input-z" : null
                      }
                      value={loanDetails.interestCalcMethod}
                      onChange={handleChange}
                    >
                      <option value="">Select calculation method</option>
                      <option value="DecliningBalance">
                        Declining Balance
                      </option>
                      <option value="FlatRate">Flat Rate</option>
                    </select>
                  </div>
                  {errors.interestCalcMethod && (
                    <ErrorMessage message={errors.interestCalcMethod} />
                  )}
                </div>
                <div className="label-input">
                  <label>Tenor</label>
                  <div className="flex--2 input-span">
                    {/* <span>{loanDetails.tenor}</span> */}
                    {/* <span> */}
                    <input
                      type="number"
                      name="tenor"
                      className={errors.tenor ? "error-input-z" : null}
                      value={loanDetails.tenor}
                      onChange={handleChange}
                    />
                    {/* </span> */}
                    <select
                      disabled
                      //class="span-input"
                      name="tenor"
                      className={errors.tenor ? "error-input-z" : null}
                      value={loanDetails.tenorBasis}
                      onChange={handleChange}
                    >
                      <option value="">Select tenor basis</option>
                      <option value="Days">Days</option>
                      <option value="Months">Months</option>
                    </select>
                  </div>
                  {errors.tenor && <ErrorMessage message={errors.tenor} />}
                </div>
                <div className="label-input">
                  <label>Loan Maturity Date</label>
                  <div className="input-span input-span--1">
                    <input
                      type="date"
                      disabled
                      name="maturityDate"
                      className={errors.maturityDate ? "error-input-z" : null}
                      value={loanDetails.maturityDate}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.maturityDate && (
                    <ErrorMessage message={errors.maturityDate} />
                  )}
                </div>
                <div className="label-input flex--3">
                  <label>Amortization Type</label>
                  <div className="input-span input-span--1">
                    <select
                      disabled
                      name="amortizationType"
                      className={
                        errors.amortizationType ? "error-input-z" : null
                      }
                      value={loanDetails.amortizationType}
                      onChange={handleChange}
                    >
                      <option value="">{loanDetails.amortizationType}</option>
                      {/* <option value="EqualInstallment">
                        Equal installment
                      </option>
                      <option value="EqualPrincipal">Equal Principal</option> */}
                    </select>
                  </div>
                  {errors.amortizationType && (
                    <ErrorMessage message={errors.amortizationType} />
                  )}
                </div>
                <div className="label-input">
                  <label>Interest Rate</label>
                  <div className="flex--2 input-span">
                    {/* <span>6</span> */}
                    <input
                      type="number"
                      step={0.01}
                      name="interestRate"
                      className={errors.interestRate ? "error-input-z" : null}
                      value={loanDetails.interestRate}
                      onChange={handleChange}
                      // disabled
                    />
                    <select
                      disabled
                      name="rateBasis"
                      className={
                        errors.interestRateBasis ? "error-input-z" : null
                      }
                      value={loanDetails.interestRateBasis}
                      onChange={handleChange}
                    >
                      <option value="">Select rate basis</option>
                      <option value="PerAnnum">Per Annum</option>
                      <option value="PerMonth">Per Month</option>
                    </select>
                  </div>
                  {errors.interestRate && (
                    <ErrorMessage message={errors.interestRate} />
                  )}
                </div>
                <div className="label-input">
                  <label>Interest Period</label>
                  <div className="input-span input-span--1">
                    <select
                      // disabled
                      name="interestPeriod"
                      className={errors.interestPeriod ? "error-input-z" : null}
                      value={loanDetails.interestPeriod}
                      onChange={handleChange}
                    >
                      <option value="">Select interest period</option>
                      <option value="Actual">Actual/Actual</option>
                      <option value="Actual360">Actual/360</option>
                      <option value="Actual30"> 30/Actual</option>
                      <option value="30360"> 30/360</option>
                    </select>
                    {/* <input
                      type="text"
                      // disabled
                      name="interestPeriod"
                      className={errors.interestPeriod ? "error-input-z" : null}
                      value={loanDetails.interestPeriod}
                      onChange={handleChange}
                    /> */}
                  </div>
                  {errors.interestPeriod && (
                    <ErrorMessage message={errors.interestPeriod} />
                  )}
                </div>
                <div className="label-input">
                  <label>First Repayment Date</label>
                  <div className="input-span input-span--1">
                    <input
                      type="date"
                      name="firstRepaymentDate"
                      className={
                        errors.firstRepaymentDate ? "error-input-z" : null
                      }
                      value={loanDetails.firstRepaymentDate}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                  {errors.firstRepaymentDate && (
                    <ErrorMessage message={errors.firstRepaymentDate} />
                  )}
                </div>
                <div className="repaymentmerge flex--2">
                  <div className="label-input">
                    <label>Repayment Frequency</label>
                    <div className="flex--2">
                      {/* <span className="span-abs">1</span> */}
                      {/* <div>date emma{loanDetails.repaymentFrequency}</div> */}
                      <select
                        //class="span-input"
                        disabled
                        name="repaymentFrequency"
                        className={
                          errors.repaymentFrequency
                            ? "error-input-z input-abs"
                            : "input-abs"
                        }
                        value={loanDetails.repaymentFrequency}
                        onChange={handleChange}
                      >
                        <option value="">Select replayment frequency</option>
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Yearly</option>
                      </select>
                    </div>
                    {errors.repaymentFrequency && (
                      <ErrorMessage message={errors.repaymentFrequency} />
                    )}
                  </div>
                  <div className="label-input">
                    <label>Repayment Frequency Unit</label>
                    <input
                      // disabled
                      type="text"
                      name="repaymentFrequencyUnit"
                      value={loanDetails.repaymentFrequencyUnit}
                      className={
                        errors.repaymentFrequencyUnit ? "error-input-z" : null
                      }
                      onChange={handleChange}
                    />
                    {errors.repaymentFrequencyUnit && (
                      <ErrorMessage message={errors.repaymentFrequencyUnit} />
                    )}
                  </div>
                </div>
                {/* <div className="label-input flex--2">
                  <input
                    // disabled
                    type="checkbox"
                    name="autoDisbursement"
                    checked={loanDetails.autoDisbursement}
                    className="debitcheckbox"
                    onChange={handleChange}
                  />
                  <label className="span2">Auto Disbursement</label>
                </div> */}
                {/* <div className="label-input flex--2">
                  <input
                    // disabled
                    type="checkbox"
                    name="autoLiquidate"
                    checked={loanDetails.autoLiquidate}
                    className="debitcheckbox"
                    onChange={handleChange}
                  />
                  <label className="span2">Auto Liquidate</label>
                </div> */}
                <div className="label-input flex--2">
                  <input
                    // disabled
                    type="checkbox"
                    name="gaurantorRequired"
                    checked={loanDetails.gaurantorRequired}
                    className="debitcheckbox"
                    onChange={handleChange}
                  />
                  <label className="span2">Guarantor Required</label>
                </div>
                <div className="label-input flex--2">
                  <input
                    // disabled
                    type="checkbox"
                    name="bulletpayment"
                    checked={loanDetails.bulletpayment}
                    className="debitcheckbox"
                    onChange={handleChange}
                  />
                  <label className="span2">Bullet Payment</label>
                </div>
              </section>
            )}

            {show && (
              <div className="flex--1 flex--button">
                <button className="next--button" onClick={handleFirstScreen}>
                  Next
                </button>
              </div>
            )}
          </>
        )}
        {/* // ----------------------------Screen2---------------------------- */}

        {screenChange === 2 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <label>Repayment Account</label>
                <br />
                <input
                  type="text"
                  name="repaymentAccount"
                  className={errors.repaymentAccount ? "error-input-z" : null}
                  value={loanDetails.repaymentAccount}
                  onChange={handleChange}
                />
                {errors.repaymentAccount && (
                  <ErrorMessage message={errors.repaymentAccount} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <label>Repayment Customer Name</label>
                <br />
                <input
                  disabled={accountHolder}
                  type="text"
                  name="repCustomerName"
                  className={errors.repCustomerName ? "error-input-z" : null}
                  value={loanDetails.repCustomerName}
                  onChange={handleChange}
                />
                {errors.repCustomerName && (
                  <ErrorMessage message={errors.repCustomerName} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <label>Rep. Account Branch</label>
                <br />
                <input
                  disabled={accountHolder}
                  type="text"
                  name="repAccountBranch"
                  className={errors.repAccountBranch ? "error-input-z" : null}
                  value={loanDetails.repAccountBranch}
                  onChange={handleChange}
                />
                {errors.repAccountBranch && (
                  <ErrorMessage message={errors.repAccountBranch} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <label>Rep. Account Currency</label>
                <br />
                <input
                  disabled
                  type="text"
                  name="repAccountCurrency"
                  className={errors.repAccountCurrency ? "error-input-z" : null}
                  value={loanDetails.repAccountCurrency}
                  onChange={handleChange}
                />
                {errors.repAccountCurrency && (
                  <ErrorMessage message={errors.repAccountCurrency} />
                )}
              </Grid>
              {/* <Grid item xs={4} sm={12} md={6}>
                <label>Loan Account No.</label>
                <br />
                <input
                type="text"
                name="interestRate"
                className={errors.interestRate ? "error-input-z" : null}
                value={loanDetails.interestRate}
                onChange={handleChange}
                 />
                 {errors.repaymentFrequency && (
                  <ErrorMessage message={errors.repaymentFrequency} />
                )}
              </Grid> */}
              <Grid item xs={4} sm={6} md={4} lg={4}>
                <div className="flex--3">
                  <label>Loan Officer</label>
                  <div className="error-container-z">
                    <select
                      name="loanOfficer"
                      className={errors.loanOfficer ? "error-input-z" : null}
                      value={loanDetails.loanOfficer}
                      onChange={handleChange}
                    >
                      <option value="">Select an option</option>
                      {loanOfficers.map((item) => (
                        <option
                          key={item.id}
                          value={item.firstName}
                        >{`${item.firstName} - ${item.lastName}`}</option>
                      ))}
                    </select>

                    {/* <div
                      className={`border--norm ${
                        errors.loanOfficer ? "error-input-z" : null
                      }`}
                    >
                      <SearchDropdown
                        value={""}
                        options={loanOfficers}
                        onChange={handleSearchableChangeSelect}
                        type="loanOfficer"
                      />
                      {errors.loanOfficer && (
                      <ErrorMessage message={errors.loanOfficer} />
                      )}
                    </div> */}
                  </div>
                  {errors.loanOfficer && (
                    <ErrorMessage message={errors.loanOfficer} />
                  )}
                  {/* <br />
                <input
                  type="text"
                  name="loanOfficer"
                  className={errors.loanOfficer ? "error-input-z" : null}
                  value={loanDetails.loanOfficer}
                  onChange={handleChange}
                /> */}
                </div>
              </Grid>
              <Grid item xs={4} sm={6} md={4} lg={4}>
                <label>Purpose of Loan</label>
                <br />
                <input
                  type="text"
                  name="purposeOfLoan"
                  className={errors.purposeOfLoan ? "error-input-z" : null}
                  value={loanDetails.purposeOfLoan}
                  onChange={handleChange}
                />
                {errors.purposeOfLoan && (
                  <ErrorMessage message={errors.purposeOfLoan} />
                )}
              </Grid>
              {/* <Grid item xs={4} sm={6} md={3}>
                <div className="input__button">
                  <div className="checboxdiv1">
                    <input
                      type="checkbox"
                      checked={loanDetails.collateralRequired}
                      className="checkbox"
                      onChange={handleChange}
                    />
                    <label className="name">Collateral Required</label>
                  </div>

                  <div className="addbtn--1">
                    <button>Add/View collaterals</button>
                  </div>
                </div>
              </Grid> */}

            </Grid>

            <section className="charges-section">
              <h5>Charges</h5>
              <section className="sub--section flex--3">
                {loanCharges.map((item, i) => (
                  <div className="grid--1 label--main" key={i}>
                    <div className="flex--3">
                      <label>Charge Name</label>
                      <select
                        disabled
                        type="text"
                        className={
                          errors.chargeName &&
                          errors.chargeName.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="chargeName"
                        value={item.chargeName}
                        onChange={(e) => handleChargeChange(e, i)}
                      >
                        <option value="">{item.chargeName}</option>
                      </select>
                      {errors.chargeName &&
                        errors.chargeName.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    <div className="flex--3">
                      <label>Apply Charges At</label>
                      <select
                        disabled
                        name="applyChargeAt"
                        className={
                          errors.applyChargeAt &&
                          errors.applyChargeAt.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.applyChargeAt}
                        onChange={(e) => handleChargeChange(e, i)}
                      >
                        <option value="">Select charge at</option>
                        <option value="Booking">Booking</option>
                        <option value="Repayment">Repayment</option>
                        <option value="Disbursement">Disbursement</option>
                      </select>
                      {errors.applyChargeAt &&
                        errors.applyChargeAt.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    <div className="flex--3">
                      <label>Charge Ledger Account</label>
                      <select
                        disabled
                        name="chargeLedgerAccount"
                        className={
                          errors.chargeLedgerAccount &&
                          errors.chargeLedgerAccount.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.chargeLedgerAccount}
                        onChange={(e) => handleChargeChange(e, i)}
                      >
                        <option value="">Select charge ledger account</option>
                        {allGlAccount.map((item) => (
                          <option value={item.accountNumber} key={item.id}>
                            {item.accountName} - {item.accountNumber}
                          </option>
                        ))}
                      </select>
                      {/* <input
                        type="text"
                        className={
                          errors.chargeLedgerAccount &&
                          errors.chargeLedgerAccount.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="chargeLedgerAccount"
                        value={item.chargeLedgerAccount}
                        onChange={(e) => handleChargeChange(e, i)}
                      /> */}
                      {errors.chargeLedgerAccount &&
                        errors.chargeLedgerAccount.find(
                          (el) => el.id === i
                        ) && <ErrorMessage message="Field cannot be empty" />}
                    </div>
                    <div className="flex--3">
                      <label>Type of charge</label>
                      <select
                        disabled
                        name="typeOfCharge"
                        className={
                          errors.typeOfCharge &&
                          errors.typeOfCharge.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.typeOfCharge}
                        onChange={(e) => handleChargeChange(e, i)}
                      >
                        <option value="">Select type of charge</option>
                        <option value="FlatAmount">Flat Amount</option>
                        <option value="Rate">Percentage Rate</option>
                        {/* <option value="Percentage">Percentage</option> */}
                      </select>
                      {errors.typeOfCharge &&
                        errors.typeOfCharge.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    <div className="flex--3">
                      <label>Amount</label>
                      <input
                        // disabled
                        type="number"
                        className={
                          errors.amountBasis &&
                          errors.amountBasis.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="amountBasis"
                        value={item.amountBasis}
                        onChange={(e) => handleChargeChange(e, i)}
                      />
                      {errors.amountBasis &&
                        errors.amountBasis.find((el) => el.id === i) && (
                          <ErrorMessage message="Field is required" />
                        )}
                    </div>
                    <div className="flex--3">
                      <label>Rate</label>
                      <input
                        disabled
                        type="number"
                        className={
                          errors.rate && errors.rate.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        name="rate"
                        value={item.rate}
                        onChange={(e) => handleChargeChange(e, i)}
                      />
                      {errors.rate && errors.rate.find((el) => el.id === i) && (
                        <ErrorMessage message="Field is required" />
                      )}
                    </div>
                    <div className="flex--3">
                      <label>Charge currency</label>
                      <select
                        disabled
                        name="chargeCurrency"
                        className={
                          errors.chargeCurrency &&
                          errors.chargeCurrency.find((el) => el.id === i)
                            ? "error-input-z"
                            : null
                        }
                        value={item.chargeCurrency}
                        onChange={(e) => handleChargeChange(e, i)}
                      >
                        <option value="">Select charge name</option>
                        {allCurrencyDefinition.map((item) => (
                          <option value={item.currencyCode} key={item.id}>
                            {item.currencyName}
                          </option>
                        ))}
                      </select>
                      {errors.chargeCurrency &&
                        errors.chargeCurrency.find((el) => el.id === i) && (
                          <ErrorMessage message="Invalid entry" />
                        )}
                    </div>
                    {/* <button onClick={() => removeLoanCharges(i)}>Delete</button> */}
                  </div>
                ))}
                {/* <div className="button-p">
                  <div className="button-add">
                    <button className="button" onClick={addLoanCharges}>
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div> */}
              </section>
            </section>

            <button className="view--button" onClick={fetchLoanShedule}>
              View Loan Schedule
            </button>
            <br />
            <div className="button--booking flex--1">
              <button
                onClick={() => {
                  setErrors({});
                  setScreenChange(1);
                }}
                className="previous"
              >
                Previous
              </button>
              <button onClick={handleSecondScreen} className="next">
                Next
              </button>
            </div>
          </>
        )}
        {/* -----------------screen3----------- */}
        {screenChange === 3 && (
          <>
            <div className="third__screen">
              <div className="check-div">
                <input
                  className="checkbox-noncust"
                  y
                  type="radio"
                  name="customerType"
                  value={"customer"}
                  checked={guarantorForm === "customer"}
                  onChange={handleChange}
                />
                <label>Customer</label>
                <span style={{ color: "white" }}>Name</span>
                {/* <input
                  className="checkbox-noncust"
                  type="radio"
                  name="customerType"
                  value={"nonCustomer"}
                  checked={guarantorForm === "nonCustomer"}
                  onChange={handleChange}
                />
                <label>Non Customer</label> */}
              </div>
              <section className="charges-section">
                <section className="sub--section flex--3">
                  {loanGuarantors.map((item, i) => (
                    <div className="grid--1 label--main" key={i}>
                      {guarantorForm === "customer" && (
                        <div className="flex--3">
                          <label>Guarantor Account No.</label>
                          <input
                            type="text"
                            name="guarantorAccNo"
                            className={
                              errors.guarantorAccNo &&
                              errors.guarantorAccNo.find((el) => el.id === i)
                                ? "error-input-z"
                                : null
                            }
                            value={item.guarantorAccNo}
                            // value={item.guarantorAccNo}
                            onChange={(e) => handleLoanGuarantorsChange(e, i)}
                          />
                          {errors.guarantorAccNo &&
                            errors.guarantorAccNo.find((el) => el.id === i) && (
                              <ErrorMessage message="Invalid entry" />
                            )}
                          {index === i && (
                            <ErrorMessage message={invalidAccNo} />
                          )}
                        </div>
                      )}
                      <div className="flex--3">
                        <label>Guarantor Name</label>
                        <input
                          disabled={guarantorForm === "customer"}
                          type="text"
                          name="guarantorAccName"
                          className={
                            errors.guarantorAccName &&
                            errors.guarantorAccName.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.guarantorAccName}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        />
                        {errors.guarantorAccName &&
                          errors.guarantorAccName.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid entry" />
                          )}
                      </div>
                      {guarantorForm === "customer" && (
                        <div className="flex--3">
                          <label>Account Branch</label>
                          <select
                            disabled={guarantorForm === "customer"}
                            name="accountBranch"
                            className={
                              errors.accountBranch &&
                              errors.accountBranch.find((el) => el.id === i)
                                ? "error-input-z"
                                : null
                            }
                            value={item.accountBranch}
                            onChange={(e) => handleLoanGuarantorsChange(e, i)}
                          >
                            <option value="">Select account branch</option>
                            {branches.map((item) => (
                              <option value={item.code} key={item.id}>
                                {item.name} - {item.code}
                              </option>
                            ))}
                          </select>
                          {errors.accountBranch &&
                            errors.accountBranch.find((el) => el.id === i) && (
                              <ErrorMessage message="Field cannot be empty" />
                            )}
                        </div>
                      )}
                      {guarantorForm === "customer" && (
                        <div className="flex--3">
                          <label>Account Currency</label>
                          <select
                            disabled={guarantorForm === "customer"}
                            name="currencyCode"
                            className={
                              errors.currencyCode &&
                              errors.currencyCode.find((el) => el.id === i)
                                ? "error-input-z"
                                : null
                            }
                            value={item.currencyCode}
                            onChange={(e) => handleLoanGuarantorsChange(e, i)}
                          >
                            <option value="">Select currency</option>
                            {allCurrencyDefinition.map((item) => (
                              <option value={item.currencyCode} key={item.id}>
                                {item.currencyName}
                              </option>
                            ))}
                          </select>
                          {errors.chargeCurrency &&
                            errors.chargeCurrency.find((el) => el.id === i) && (
                              <ErrorMessage message="Invalid entry" />
                            )}
                        </div>
                      )}
                      <div className="flex--3">
                        <label>Guarantor Address</label>
                        <input
                          disabled={guarantorForm === "customer"}
                          type="text"
                          name="guarantorAddress"
                          className={
                            errors.guarantorAddress &&
                            errors.guarantorAddress.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.guarantorAddress}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        />
                        {errors.guarantorAddress &&
                          errors.guarantorAddress.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid entry" />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>City</label>
                        <input
                          disabled={guarantorForm === "customer"}
                          type="text"
                          name="city"
                          className={
                            errors.city && errors.city.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.city}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        />
                        {errors.city &&
                          errors.city.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid entry" />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>Country</label>
                        <select
                          disabled={guarantorForm === "customer"}
                          name="country"
                          className={
                            errors.country &&
                            errors.country.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.country}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        >
                          <option value="">Select a country</option>
                          {countries.map((country) => (
                            <option
                              value={country.countryCode2}
                              key={country.id}
                            >
                              {country.name}
                            </option>
                          ))}
                        </select>
                        {errors.country &&
                          errors.country.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid entry" />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>State</label>
                        <select
                          disabled={guarantorForm === "customer"}
                          name="state"
                          className={
                            errors.state &&
                            errors.state.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.state}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        >
                          <option value="">Select a state</option>
                          {states.map((state) => (
                            <option value={state.name} key={state.id}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        {errors.state &&
                          errors.state.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid entry" />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>Contact Name</label>
                        <input
                          type="text"
                          name="contactName"
                          className={
                            errors.contactName &&
                            errors.contactName.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.contactName}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        />
                        {errors.contactName &&
                          errors.contactName.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid entry" />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>Contact Phone Number</label>
                        <input
                          disabled={guarantorForm === "customer"}
                          type="tel"
                          name="contactMobile"
                          className={
                            errors.contactMobile &&
                            errors.contactMobile.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.contactMobile}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        />
                        {errors.contactMobile &&
                          errors.contactMobile.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid phone number" />
                          )}
                      </div>
                      <div className="flex--3">
                        <label>Email</label>
                        <input
                          disabled={guarantorForm === "customer"}
                          type="email"
                          name="emailAddress"
                          className={
                            errors.emailAddress &&
                            errors.emailAddress.find((el) => el.id === i)
                              ? "error-input-z"
                              : null
                          }
                          value={item.emailAddress}
                          onChange={(e) => handleLoanGuarantorsChange(e, i)}
                        />
                        {errors.emailAddress &&
                          errors.emailAddress.find((el) => el.id === i) && (
                            <ErrorMessage message="Invalid email" />
                          )}
                      </div>

                      {/* <button onClick={() => removeLoanCharges(i)}>Delete</button> */}
                    </div>
                  ))}

                  <div className="label-input flex--2">
                    <input
                      // disabled
                      type="checkbox"
                      name="lienGuarantorAccount"
                      checked={loanDetails.lienGuarantorAccount}
                      className="debitcheckbox"
                      onChange={handleChange}
                    />
                    <label className="span2">
                      Place lien on guarantor's account
                    </label>
                    <br />
                    {errors.lienGuarantorAccount && (
                      <ErrorMessage message={errors.lienGuarantorAccount} />
                    )}
                  </div>

                  {/* <div className="button-p">
                  <div className="button-add">
                    <button className="button" onClick={addLoanGuarantors}>
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                </div> */}
                </section>
              </section>
            </div>

            <div className="button--booking flex--1">
              <button onClick={() => setScreenChange(2)} className="previous">
                Previous
              </button>
              <button onClick={handleSubmit} className="next">
                save
              </button>
            </div>
          </>
        )}
      </Paper>
      {openShedule && (
        <RepaymentSchedule
          open={openShedule}
          onClose={() => setOpenSchedule(false)}
          details={loanDetails}
          sampleShedule={sampleShedule}
          onEditedScheduleSave={onEditedScheduleSave}
        />
      )}
      {!loading && (
        <Table
          title="Loan booking"
          subtitle=""
          name="Lists of loan booking"
          records={allLoanBookings}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
    </div>
  );
};
export default Loanbooking;

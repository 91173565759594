import React from 'react'
// import './Main.scss'
import { Switch, Route } from "react-router-dom";
import AccountEnquiry from './AccountEnquiry';
import LienAccountBlock from './LienAccountBlock';






const Main = () => { 
    return (
        <div className='main'>
            <Switch> 
                <Route path='/dashboard/authorizeaccountoperations/accountenquiry'component={AccountEnquiry}/>
                <Route path='/dashboard/authorizeaccountoperations/lienAccountBlock'component={LienAccountBlock}/>

            </Switch>
        </div>
    )
}

export default Main;

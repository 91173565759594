import React from 'react'
import { Switch, Route } from "react-router-dom";
import InvestmentAccrual from './InvestmentAccrual/InvestmentAccrual'
import InvestmentMaturity from './InvestmentMaturity/InvestmentMaturity'
import InvestmentCertificate from './InvestmentCertificate/InvestmentCertificate'


const Main = () => {
    return (
        <div>
            <Switch> 
                <Route path='/dashboard/reports/investment/certificate' component={InvestmentCertificate}/>
                <Route path='/dashboard/reports/investment/maturity' component={InvestmentMaturity}/>
                <Route path='/dashboard/reports/investment/accrual' component={InvestmentAccrual}/>
            </Switch>
        </div>
    )
}

export default Main

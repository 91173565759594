

const NoTransaction = () => {
    return (
        <div
            style={{
                width: '100%',
                minHeight: '275px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h4>No Transactions Available</h4>
        </div>
    )
}

export default NoTransaction

import React from "react";
import "./LoanFacilityLimit.scss";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BusinessLogic from "./businessLogic";
import Confirm from "../../../../Confirmbox/Confirm";
import DecisonModal from "../../../../Confirmbox/Confirm";
import NumberFormat from "react-number-format";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Table from "../../../DatagridTable/table";
import EditForm from "./edit";
import Details from "./view";

const LoanFacilityLimit = () => {
  const {
        details,
        editDetails,
        accNum,
        errors,
        loading,
        options,
        columns,
        open,
        message,
        editErrors,
        loanFacilityLimit,
        setOpen,
        setLoanFacilitylimit,
        handleChange,
        handleSubmit,
        // decide,
        postLoanLimitFacility,
        allLoanLimits,
        openDecision,
        setOpenDecision,
        handleEditDetailsChange,
        handleDetailsUpdateSubmit,
  } = BusinessLogic();
  return (
    <div className="loan--facilitylimit">
      <Spinner loading={loading}/>
      <Confirm
        ApiCall={postLoanLimitFacility}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      {/* <DecisonModal
        ApiCall={decide}
        message="Are you sure you want to authorize this record?"
        openBox={openDecision}
        actionMsg="Your records were authorized successfully"
        setOpenBoxState={setOpenDecision}
        errorMessage={message}
      /> */}
      <div className="headerdiv">
        <p>Loan Facility Limit Amount</p>
      </div>

      <Paper
        style={{
          marginTop: 20,
          marginBottom: 50,
          padding: 25,
          boxShadow: "2px 2px 2px 2px white-smoke",
        }}
      >
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <label>Account Number</label>
              <input
                type="text"
                name="accountNo"
                className={errors.accountNo ? "error-input-z" : null}
                value={loanFacilityLimit.accountNo}
                onChange={handleChange}
              />
              {errors.accountNo && (
                <ErrorMessage message={errors.accountNo} />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <label>Account Name</label>
              <input
                disabled
                type="text"
                name="accountName"
                className={errors.accountName ? "error-input-z" : null}
                value={loanFacilityLimit.accountName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Account Branch</label>
              <input
                disabled
                type="text"
                name="accountBranch"
                className={errors.accountBranch ? "error-input-z" : null}
                value={loanFacilityLimit.accountBranch}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Account Currency</label>
              <input
                disabled
                type="text"
                name="accountCurrency"
                className={errors.accountCurrency ? "error-input-z" : null}
                value={loanFacilityLimit.accountCurrency}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Approved Loan Limit</label>
              <NumberFormat
                value={loanFacilityLimit.approvedLoanLimit}
                thousandSeparator={true}
                    inputMode="numeric"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => <div>{value}</div>}
                className={`some ${
                errors.approvedLoanLimit ? "error-input-z" : null
                }`}
                onValueChange={(values) => {
                setLoanFacilitylimit((prev) => ({
                ...prev,
                approvedLoanLimit: parseInt(values.value),
                }));
                }}
                />
              {/* <input
                type="number"
                name="approvedLoanLimit"
                className={errors.approvedLoanLimit ? "error-input-z" : null}
                value={loanFacilityLimit.approvedLoanLimit}
              /> */}
              {errors.approvedLoanLimit && (
                <ErrorMessage message={errors.approvedLoanLimit} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Loan Limit Balance</label>
              <NumberFormat
                disabled={true}
                value={loanFacilityLimit.loanLimitBalance}
                thousandSeparator={true}
                inputMode="numeric"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => <div>{value}</div>}
                className={`some ${
                errors.loanLimitBalance ? "error-input-z" : null
                }`}
                onValueChange={(values) => {
                setLoanFacilitylimit((prev) => ({
                ...prev,
                loanLimitBalance: parseInt(values.value),
                }));
                }}
                />
              {/* <input
                disabled
                type="number"
                name="loanLimitBalance"
                className={errors.loanLimitBalance ? "error-input-z" : null}
                value={loanFacilityLimit.loanLimitBalance}
              /> */}
            </Grid>
            <div className="one__button flex--button">
                <button className="save__button" onClick={handleSubmit}>
                Save
                </button>
            </div>

          </Grid>
        </>
      </Paper>

      {!loading && <Table
        title="Loan Facility Limits"
        subtitle=""
        name="Lists of facility limits"
        records={allLoanLimits}
        options={options}
        columns={columns}
      />}
      {details && details.id && <Details details={details} />}
      {editDetails && editDetails.id && (
      <EditForm
        details={editDetails}
        handleDetailsUpdateSubmit={handleDetailsUpdateSubmit}
        errorEdit={editErrors}
        handleFieldUpdate={handleEditDetailsChange}
      />
      )}
    </div>
  );
};

export default LoanFacilityLimit;

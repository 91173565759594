import { useState, useEffect, useCallback } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import "./AccountProduct.scss";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import {
  // getBankParameterByName,
  getAllCustomerCategory,
  // getAllGlAccount,
  // getAllCurrencyDefinition,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { baseUrl } from "../../../../Api/configUrl";

import useCustom from "../../../../Api/Hooks-manager/products/useCustom";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop/index";
import useCustom2 from "../../../../Api/Hooks-manager/coreModules/useCustom";
import {
  validateAccoutProductPage1,
  validateAccoutProductPage2,
  validateAccountProductPage3,
} from "../../../../Api/validator/validate11";
import ErrorMessage from "../../../ErrorMessage";
import NumberFormat from "react-number-format";
import SearchDropdown from "../../../SearchableDropdown/Search";
import BussinessLogic from "../LoanProducts/bussinessLogic";
import useCustom3 from "../../../../Api/Hooks-manager/coreModules/useCustom";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import EditForm from "./edit";
import Details from "./view";
import set from "date-fns/esm/set/index.js";
import { Select } from "@material-ui/core";

export const AccountProduct = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const {
    getAccountDefinitionAll,
    valuesProduct,
    setValuesProduct,
    allAccountProduct,
    handleaAccountProductCreate,
    handleChange,
  } = useCustom();
  const { allGlAccount } = useCustom2();
  const { allCurrencyDefinition, getCurrencyDefinitionAll } = useCustom3();
  const [editCustomerRestriction, setEditCustomerRestriction] = useState([]);
  const [screenChange, setScreenChange] = useState(1);
  const [openBox, setOpenBox] = useState(false);
  const [errorPage1, setErrorPage1] = useState({});
  const [errorPage2, setErrorPage2] = useState({});
  const [errorPage3, setErrorPage3] = useState({});
  const [cusCategory, setCategory] = useState([]);

  const { createCustomerRestriction } = valuesProduct;

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productCode",
      label: "Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productName",
      label: "Product Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountType",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "debitAccount",
      label: "Debit Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditAccount",
      label: "Credit Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllAccountProducts = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}account/AccountProduct/GetAll`;
    const response = await getHandler(token, url);
    console.log(response, "all account products");
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllAccountProducts();
  }, [fetchAllAccountProducts, updateTable]);

  const fetchAllCustomerCategory = useCallback(async () => {
    const response = await getAllCustomerCategory(token);
    if (response && response.data) {
      setCategory(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  }, [token]);

  // const { cusCategory } = BussinessLogic();

  // Destructuring the value from the mapped item
  const filterGlAccount = allGlAccount.filter(
    (items) =>
      items.glHierarchy === "Child" &&
      items.authorizationStatus === "Authorized"
  );

  const glAccountNumber = filterGlAccount.map((item) => {
    return {
      value: item.accountNumber,
      label: `${item.accountNumber} - ${item.accountName}`,
    };
  });

  useEffect(() => {
    getAccountDefinitionAll();
  }, [getAccountDefinitionAll]);

  useEffect(() => {
    getCurrencyDefinitionAll();
  }, [getCurrencyDefinitionAll]);

  useEffect(() => {
    fetchAllCustomerCategory();
  }, [fetchAllCustomerCategory]);

  const handleChangeSelect = (value, type) => {
    if (type === "debitAccount") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        debitAccount: value,
      }));
    } else if (type === "interestReceivableAccount") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        interestReceivableAccount: value,
      }));
    } else if (type === "interestIncome") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        interestIncome: value,
      }));
    } else if (type === "interestPayableAccount") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        interestPayableAccount: value,
      }));
    } else if (type === "creditAccount") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        creditAccount: value,
      }));
    } else if (type === "interestExpense") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        interestExpense: value,
      }));
    } else if (type === "vatAccountNo") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        vatAccountNo: value,
      }));
    } else if (type === "whtAccountNo") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        whtAccountNo: value,
      }));
    } else if (type === "gateWayCreditAccount") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        gateWayCreditAccount: value,
      }));
    } else if (type === "gateWayDebitAccount") {
      setValuesProduct((valuesProduct) => ({
        ...valuesProduct,
        gateWayDebitAccount: value,
      }));
    }
  };

  useEffect(() => {}, [allAccountProduct]);

  const handleEditSubmit = () => {
    dispatch(openResquestDecisionModal());
  };

  const handleFieldUpdate = (e) => {
    if (e === "status") {
      setValuesProduct({ ...valuesProduct, status: !valuesProduct.status });
    } else if (e === "trackDormancy") {
      setValuesProduct({
        ...valuesProduct,
        trackDormancy: !valuesProduct.trackDormancy,
      });
    } else if (e === "debitBalanceAllowed") {
      setValuesProduct({
        ...valuesProduct,
        debitBalanceAllowed: !valuesProduct.debitBalanceAllowed,
      });
    } else if (e === "applyDebitInterest") {
      setValuesProduct({
        ...valuesProduct,
        applyDebitInterest: !valuesProduct.applyDebitInterest,
      });
    } else if (e === "applyCreditInterest") {
      setValuesProduct({
        ...valuesProduct,
        applyCreditInterest: !valuesProduct.applyCreditInterest,
      });
    } else if (e === "enforceMinBalCheck") {
      setValuesProduct({
        ...valuesProduct,
        enforceMinBalCheck: !valuesProduct.enforceMinBalCheck,
      });
    } else if (e === "mobileApplicable") {
      setValuesProduct({
        ...valuesProduct,
        mobileApplicable: !valuesProduct.mobileApplicable,
      });
    } else if (e === "isEligibleForLoan") {
      setValuesProduct({
        ...valuesProduct,
        isEligibleForLoan: !valuesProduct.isEligibleForLoan,
      });
    } else if (e === "requireMemberShipFee") {
      setValuesProduct({
        ...valuesProduct,
        requireMemberShipFee: !valuesProduct.requireMemberShipFee,
      });
    }
  };

  const handleFirstPage = () => {
    console.log("checking handleFirstPage....");

    setErrorPage1(validateAccoutProductPage1(valuesProduct));
    if (Object.keys(validateAccoutProductPage1(valuesProduct)).length > 0)
      return;
    setScreenChange(2);
  };

  const handleSecondPage = () => {
    setErrorPage2(validateAccoutProductPage2(valuesProduct));
    if (Object.keys(validateAccoutProductPage2(valuesProduct)).length > 0)
      return;
    setScreenChange(3);
  };

  const handleConfirm = () => {
    setErrorPage3(validateAccountProductPage3(valuesProduct));
    if (Object.keys(validateAccountProductPage3(valuesProduct)).length > 0)
      return;
    setOpenBox(true);
  };

  const add = () => {
    const tempItem = [...valuesProduct.createCustomerRestriction];
    tempItem.push({
      customerCategory: "",
      customerName: "",
    });
    setValuesProduct({ ...valuesProduct, createCustomerRestriction: tempItem });
  };

  const remove = (i) => {
    const tempItem = [...valuesProduct.createCustomerRestriction];
    tempItem.splice(i, 1);
    setValuesProduct({ ...valuesProduct, createCustomerRestriction: tempItem });
  };

  const findCategoryDescription = (category) => {
    const list = [...cusCategory];
    const returnedList = list.filter((item) => item.category === category);
    return returnedList[0];
  };

  const handleChangeCustomerRestriction = (e, index) => {
    const { name, value } = e.target;
    const list = [...valuesProduct.createCustomerRestriction];
    list[index][name] = value;
    setValuesProduct({ ...valuesProduct, createCustomerRestriction: list });
    if (list[index]["customerCategory"] !== "") {
      list[index]["customerName"] = findCategoryDescription(value)
        ? findCategoryDescription(value).name
        : "";
    }
  };

  const handleEditChange = (e) => {
    const { value, name, checked } = e.target;
    if (
      name === "status" ||
      name === "trackDormancy" ||
      name === "debitBalanceAllowed" ||
      name === "applyDebitInterest" ||
      name === "applyCreditInterest" ||
      name === "enforceMinBalCheck" ||
      name === "mobileApplicable" ||
      name === "isEligibleForLoan" ||
      name === "requireMemberShipFee"
    ) {
      setDetails({
        ...details,
        [name]: checked,
      });
      return;
    }
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const EditDetails = (el) => {
    setEditCustomerRestriction(
      el.customerRestriction.map((item) => {
        return {
          id: item.id,
          customerCategory: item.customerCategory,
          customerName: item.customerName,
        };
      })
    );
  };

  const handleEditCustomerRestrictionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...editCustomerRestriction];
    list[index][name] = value;
    setEditCustomerRestriction(list);
    if (list[index]["customerCategory"] !== "") {
      list[index]["customerName"] = findCategoryDescription(value)
        ? findCategoryDescription(value).name
        : "";
    }
  };

  console.log(valuesProduct, "asddfffffffff");

  return (
    <main className="account--product">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={() => handleaAccountProductCreate(fetchAllAccountProducts)}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
      />
      <div className="headerdiv">
        <p>Products | Account Products Setup</p>
      </div>
      <div className="border--main">
        {screenChange === 1 && (
          <>
            <div style={{ paddingTop: 20, padding: 25 }}>
              <div className="product--flex flex--1">
                <div className="flex--3">
                  <label>Product Code</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="productCode"
                    value={valuesProduct.productCode}
                    className={`input--1 ${
                      Object.keys(errorPage1).includes("productCode") &&
                      "error-input-z"
                    }`}
                    maxLength="6"
                  />
                  {errorPage1.productCode && (
                    <div style={{ width: 120 }}>
                      <ErrorMessage message={errorPage1.productCode} />
                    </div>
                  )}
                </div>
                <div className="flex--3">
                  <label>Product Name</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="productName"
                    maxLength="50"
                    value={valuesProduct.productName}
                    className={`input--2 ${
                      Object.keys(errorPage1).includes("productName") &&
                      "error-input-z"
                    }`}
                  />
                  {errorPage1.productName && (
                    <ErrorMessage message={errorPage1.productName} />
                  )}
                </div>
                <div className="checboxdiv flex--2">
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => handleFieldUpdate("status")}
                      className="productcheckbox"
                      checked={valuesProduct.status}
                    />
                  </div>
                  <label className="span">Deactivate Product</label>
                </div>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Type</label>
                  <div className="error-container-z">
                    <select
                      name="accountType"
                      onChange={handleChange}
                      value={valuesProduct.accountType}
                      className={`${
                        Object.keys(errorPage1).includes("accountType") &&
                        "error-input-z"
                      }`}
                    >
                      <option>Please, Select An Option</option>
                      <option value="Savings">Savings</option>
                      <option value="Current">Current</option>
                    </select>
                    {errorPage1.accountType && (
                      <ErrorMessage message={errorPage1.accountType} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Currency</label>
                  <div className="error-container-z">
                    <select
                      name="currency"
                      value={valuesProduct.currency}
                      onChange={handleChange}
                      className={`${
                        Object.keys(errorPage1).includes("currency") &&
                        "error-input-z"
                      }`}
                    >
                      <option value="">Select An Option</option>
                      {allCurrencyDefinition.map((item) => (
                        <option value={item.currencyCode} key={item.id}>
                          {item.currencyName}
                        </option>
                      ))}
                    </select>
                    {errorPage1.currency && (
                      <ErrorMessage message={errorPage1.currency} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Credit Account</label>
                  <div className="error-container-z">
                    <div
                      // className={`border--norm ${
                      //   Object.keys(errorPage1).includes("creditAccount") &&
                      //   "error-input-z"
                      // }`}
                      style={{ width: "100%" }}
                    >
                      {/* <SearchDropdown
                        options={glAccountNumber}
                        onChange={handleChangeSelect}
                        type="creditAccount"
                      /> */}
                      <select
                        name="creditAccount"
                        value={valuesProduct.creditAccount}
                        onChange={handleChange}
                        className={`${
                          Object.keys(errorPage1).includes("creditAccount") &&
                          "error-input-z"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select An Option</option>
                        {filterGlAccount.map((item) => (
                          <option
                            value={item.accountNumber}
                            key={item.accountNumber}
                          >
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorPage1.creditAccount && (
                      <ErrorMessage message={errorPage1.creditAccount} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Interest Payable Account</label>
                  <div className="error-container-z">
                    <div
                    // className={`border--norm ${
                    //   Object.keys(errorPage1).includes(
                    //     "interestPayableAccount"
                    //   ) && "error-input-z"
                    // }`}
                    >
                      {/* <SearchDropdown
                        options={glAccountNumber}
                        onChange={handleChangeSelect}
                        type="interestPayableAccount"
                      /> */}
                      <select
                        name="interestPayableAccount"
                        value={valuesProduct.interestPayableAccount}
                        onChange={handleChange}
                        className={`${
                          Object.keys(errorPage1).includes(
                            "interestPayableAccount"
                          ) && "error-input-z"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select An Option</option>
                        {filterGlAccount.map((item) => (
                          <option
                            value={item.accountNumber}
                            key={item.accountNumber}
                          >
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorPage1.interestPayableAccount && (
                      <ErrorMessage
                        message={errorPage1.interestPayableAccount}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>No. of Days to Activate Inactivity</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="noOfDaysToActivateInactivity"
                    value={valuesProduct.noOfDaysToActivateInactivity}
                    className={`${
                      Object.keys(errorPage1).includes(
                        "noOfDaysToActivateInactivity"
                      ) && "error-input-z"
                    }`}
                  />
                  {errorPage1.noOfDaysToActivateInactivity && (
                    <ErrorMessage
                      message={errorPage1.noOfDaysToActivateInactivity}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Debit Account</label>
                  <div className="error-container-z">
                    <div
                    // className={`border--norm ${
                    //   Object.keys(errorPage1).includes("debitAccount") &&
                    //   "error-input-z"
                    // }`}
                    >
                      {/* <SearchDropdown
                        options={glAccountNumber}
                        onChange={handleChangeSelect}
                        type="debitAccount"
                      /> */}
                      <select
                        name="debitAccount"
                        value={valuesProduct.debitAccount}
                        onChange={handleChange}
                        className={`${
                          Object.keys(errorPage1).includes("debitAccount") &&
                          "error-input-z"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select An Option</option>
                        {filterGlAccount.map((item) => (
                          <option
                            value={item.accountNumber}
                            key={item.accountNumber}
                          >
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorPage1.debitAccount && (
                      <ErrorMessage message={errorPage1.debitAccount} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Interest Receivable Account</label>
                  <div className="error-container-z">
                    <div
                    // className={`border--norm ${
                    //   Object.keys(errorPage1).includes(
                    //     "interestReceivableAccount"
                    //   ) && "error-input-z"
                    // }`}
                    >
                      {/* <SearchDropdown
                        options={glAccountNumber}
                        onChange={handleChangeSelect}
                        type="interestReceivableAccount"
                      /> */}
                      <select
                        name="interestReceivableAccount"
                        value={valuesProduct.interestReceivableAccount}
                        onChange={handleChange}
                        className={`${
                          Object.keys(errorPage1).includes(
                            "interestReceivableAccount"
                          ) && "error-input-z"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select An Option</option>
                        {filterGlAccount.map((item) => (
                          <option
                            value={item.accountNumber}
                            key={item.accountNumber}
                          >
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorPage1.interestReceivableAccount && (
                      <ErrorMessage
                        message={errorPage1.interestReceivableAccount}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Interest Income</label>
                  <div className="error-container-z">
                    <div
                    // className={`border--norm ${
                    //   Object.keys(errorPage1).includes("interestIncome") &&
                    //   "error-input-z"
                    // }`}
                    >
                      {/* <SearchDropdown
                        options={glAccountNumber}
                        onChange={handleChangeSelect}
                        type="interestIncome"
                      /> */}
                      <select
                        name="interestIncome"
                        value={valuesProduct.interestIncome}
                        onChange={handleChange}
                        className={`${
                          Object.keys(errorPage1).includes("interestIncome") &&
                          "error-input-z"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select An Option</option>
                        {filterGlAccount.map((item) => (
                          <option
                            value={item.accountNumber}
                            key={item.accountNumber}
                          >
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorPage1.interestIncome && (
                      <ErrorMessage message={errorPage1.interestIncome} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Interest Expense</label>
                  <div className="error-container-z">
                    <div
                    // className={`border--norm ${
                    //   Object.keys(errorPage1).includes("interestExpense") &&
                    //   "error-input-z"
                    // }`}
                    >
                      {/* <SearchDropdown
                        options={glAccountNumber}
                        onChange={handleChangeSelect}
                        type="interestExpense"
                      /> */}
                      <select
                        name="interestExpense"
                        value={valuesProduct.interestExpense}
                        onChange={handleChange}
                        className={`${
                          Object.keys(errorPage1).includes("interestExpense") &&
                          "error-input-z"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select An Option</option>
                        {filterGlAccount.map((item) => (
                          <option
                            value={item.accountNumber}
                            key={item.accountNumber}
                          >
                            {item.accountNumber} - {item.accountName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorPage1.interestExpense && (
                      <ErrorMessage message={errorPage1.interestExpense} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>No. of Inactive Days Till Dormant</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="noOfInactiveDormantDays"
                    value={valuesProduct.noOfInactiveDormantDays}
                    className={`${
                      Object.keys(errorPage1).includes(
                        "noOfInactiveDormantDays"
                      ) && "error-input-z"
                    }`}
                  />
                  {errorPage1.noOfInactiveDormantDays && (
                    <ErrorMessage
                      message={errorPage1.noOfInactiveDormantDays}
                    />
                  )}
                </Grid>
                <div className="debitdiv">
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      className="debitcheckbox"
                      name="trackDormancy"
                      checked={valuesProduct.trackDormancy}
                      onChange={() => handleFieldUpdate("trackDormancy")}
                    />
                    <label className="span2">Track Domancy</label>
                  </div>
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      className="debitcheckbox"
                      name="debitBalanceAllowed"
                      checked={valuesProduct.debitBalanceAllowed}
                      onChange={() => handleFieldUpdate("debitBalanceAllowed")}
                    />
                    <label className="span2">Debit Balance Allowed</label>
                  </div>
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      className="debitcheckbox"
                      name="applyDebitInterest"
                      checked={valuesProduct.applyDebitInterest}
                      onChange={() => handleFieldUpdate("applyDebitInterest")}
                    />
                    <label className="span2">Apply Debit Interest</label>
                  </div>

                  <div className="flex--2">
                    <input
                      type="checkbox"
                      className="debitcheckbox"
                      name="applyCreditInterest"
                      checked={valuesProduct.applyCreditInterest}
                      onChange={() => handleFieldUpdate("applyCreditInterest")}
                    />
                    <label className="span2">Apply Credit Interest</label>
                  </div>
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      className="debitcheckbox"
                      name="enforceMinBalCheck"
                      onChange={() => handleFieldUpdate("enforceMinBalCheck")}
                      checked={valuesProduct.enforceMinBalCheck}
                    />
                    <label className="span2">Enforce Min Bal Check</label>
                  </div>
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      name="mobileApplicable"
                      className="mobileApplicable"
                      checked={valuesProduct.mobileApplicable}
                      onChange={() => handleFieldUpdate("mobileApplicable")}
                    />
                    <label className="span2">Mobile Applicable</label>
                  </div>
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      name="requireMemberShipFee"
                      className="requireMemberShipFee"
                      checked={valuesProduct.requireMemberShipFee}
                      onChange={() => handleFieldUpdate("requireMemberShipFee")}
                    />
                    <label className="span2">Require Membership Fee</label>
                  </div>
                  <div className="flex--2">
                    <input
                      type="checkbox"
                      className="isEligibleForLoan"
                      name="isEligibleForLoan"
                      checked={valuesProduct.isEligibleForLoan}
                      onChange={() => handleFieldUpdate("isEligibleForLoan")}
                    />
                    <label className="span2">Loan Eligibility</label>
                  </div>
                </div>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Reactivation Type</label>
                  <div className="error-container-z">
                    <select
                      className={`${
                        Object.keys(errorPage1).includes(
                          "accountReactivationType"
                        ) && "error-input-z"
                      }`}
                      name="accountReactivationType"
                      onChange={handleChange}
                      value={valuesProduct.accountReactivationType}
                    >
                      <option>Please, Select An Option</option>
                      <option value="Manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                    {errorPage1.accountReactivationType && (
                      <ErrorMessage
                        message={errorPage1.accountReactivationType}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Reactivation Trigger</label>
                  <select
                    className={`${
                      Object.keys(errorPage1).includes("reactivationTrigger") &&
                      "error-input-z"
                    }`}
                    name="reactivationTrigger"
                    onChange={handleChange}
                    value={valuesProduct.reactivationTrigger}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Credit">Credit</option>
                    <option value="Debit">Debit</option>
                    <option value="Both">Both</option>
                  </select>
                  {errorPage1.reactivationTrigger && (
                    <ErrorMessage message={errorPage1.reactivationTrigger} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Expected Minimum Balance</label>
                  <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    value={valuesProduct.expectedMinBalance}
                    renderText={(value) => <div>{value}</div>}
                    className={`some ${
                      Object.keys(errorPage1).includes("expectedMinBalance") &&
                      "error-input-z"
                    }`}
                    onValueChange={(values) => {
                      // eslint-disable-next-line
                      const { formattedValue, value } = values;
                      // formattedValue = $2,223
                      // value ie, 2223
                      setValuesProduct((valuesProduct) => ({
                        ...valuesProduct,
                        expectedMinBalance: value,
                      }));
                    }}
                  />
                  {/* <input 
                type="number" 
                onChange={handleChange}
                name='expectedMinBalance'
                value={valuesProduct.expectedMinBalance}
                className={`${Object.keys(errorPage1).includes('expectedMinBalance') && 'error-input-z'}`}
              /> */}
                  {errorPage1.expectedMinBalance && (
                    <ErrorMessage message={errorPage1.expectedMinBalance} />
                  )}
                </Grid>
              </Grid>
              <div className="next-cont">
                <button className="next" onClick={handleFirstPage}>
                  Next
                </button>
              </div>
            </div>
          </>
        )}

        {screenChange === 2 && (
          <main className="main-section--2">
            <section className="next-section--1">
              <div className="row-container row-container--1 grid--1">
                <div className="label--input flex--3">
                  <label>Interest Rate (Debit)</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="debitInterestRate"
                    value={valuesProduct.debitInterestRate}
                    className={`${
                      Object.keys(errorPage2).includes("debitInterestRate") &&
                      "error-input-z"
                    }`}
                  />
                  {errorPage2.debitInterestRate && (
                    <ErrorMessage message={errorPage2.debitInterestRate} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Rate Basis (Debit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes("debitRateBasis") &&
                      "error-input-z"
                    }`}
                    name="debitRateBasis"
                    onChange={handleChange}
                    value={valuesProduct.debitRateBasis}
                  >
                    <option>Please, Select An Option</option>
                    <option value="PerAnnum">Per Annum</option>
                    <option value="PerMonth">Per Month</option>
                  </select>
                  {errorPage2.debitRateBasis && (
                    <ErrorMessage message={errorPage2.debitRateBasis} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Interest Period (Debit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes("debitInterestPeriod") &&
                      "error-input-z"
                    }`}
                    name="debitInterestPeriod"
                    onChange={handleChange}
                    value={valuesProduct.debitInterestPeriod}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Actual/Actual">Actual/Actual</option>
                    <option value="Actual/360">Actual/360</option>
                    <option value="30/Actual">30/Actual</option>
                  </select>
                  {errorPage2.debitInterestPeriod && (
                    <ErrorMessage message={errorPage2.debitInterestPeriod} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Balance Basis (Debit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes("debitBalanceBasis") &&
                      "error-input-z"
                    }`}
                    name="debitBalanceBasis"
                    onChange={handleChange}
                    value={valuesProduct.debitBalanceBasis}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Daily Balance">Daily Balance</option>
                    <option value="Average Balance">Average Balance</option>
                  </select>
                  {errorPage2.debitBalanceBasis && (
                    <ErrorMessage message={errorPage2.debitBalanceBasis} />
                  )}
                </div>

                <div className="label--input flex--3">
                  <label>Interest Calc. Period (Debit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes(
                        "debitInterestCalculationPeriod"
                      ) && "error-input-z"
                    }`}
                    name="debitInterestCalculationPeriod"
                    onChange={handleChange}
                    value={valuesProduct.debitInterestCalculationPeriod}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Daily">Daily</option>
                  </select>
                  {errorPage2.debitInterestCalculationPeriod && (
                    <ErrorMessage
                      message={errorPage2.debitInterestCalculationPeriod}
                    />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Accrue Interest (Debit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes("debitAccrueInterest") &&
                      "error-input-z"
                    }`}
                    name="debitAccrueInterest"
                    onChange={handleChange}
                    value={valuesProduct.debitAccrueInterest}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errorPage2.debitAccrueInterest && (
                    <ErrorMessage message={errorPage2.debitAccrueInterest} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>VAT Rate</label>
                  <input
                    type="text"
                    name={"debitInterestVATRate"}
                    value={valuesProduct.debitInterestVATRate}
                    onChange={handleChange}
                    className={`${
                      Object.keys(errorPage2).includes(
                        "debitInterestVATRate"
                      ) && "error-input-z"
                    }`}
                  />
                  {errorPage2.debitInterestVATRate && (
                    <ErrorMessage message={errorPage2.debitInterestVATRate} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>VAT Account</label>
                  <div
                  // className={`border--norm ${
                  //   Object.keys(errorPage2).includes("vatAccountNo") &&
                  //   "error-input-z"
                  // }`}
                  >
                    {/* <SearchDropdown
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type="vatAccountNo"
                    /> */}
                    <select
                      name="vatAccountNo"
                      value={valuesProduct.vatAccountNo}
                      onChange={handleChange}
                      className={`${
                        Object.keys(errorPage1).includes("vatAccountNo") &&
                        "error-input-z"
                      }`}
                      style={{ width: "100%" }}
                    >
                      <option value="">Select An Option</option>
                      {filterGlAccount.map((item) => (
                        <option
                          value={item.accountNumber}
                          key={item.accountNumber}
                        >
                          {item.accountNumber} - {item.accountName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errorPage2.vatAccountNo && (
                    <ErrorMessage message={errorPage2.vatAccountNo} />
                  )}
                </div>
              </div>

              <div className="row-container row-container--1 grid--1">
                <div className="label--input flex--3">
                  <label> Interest Rate (Credit)</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="creditInterestRate"
                    value={valuesProduct.creditInterestRate}
                    className={`${
                      Object.keys(errorPage2).includes("creditInterestRate") &&
                      "error-input-z"
                    }`}
                  />
                  {errorPage2.creditInterestRate && (
                    <ErrorMessage message={errorPage2.creditInterestRate} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label> Rate Basis (Credit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes("creditRateBasis") &&
                      "error-input-z"
                    }`}
                    name="creditRateBasis"
                    onChange={handleChange}
                    value={valuesProduct.creditRateBasis}
                  >
                    <option>Please, Select An Option</option>
                    <option value="PerAnnum">Per Annum</option>
                    <option value="PerMonth">Per Month</option>
                  </select>
                  {errorPage2.creditRateBasis && (
                    <ErrorMessage message={errorPage2.creditRateBasis} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label> Interest Period (Credit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes(
                        "creditInterestPeriod"
                      ) && "error-input-z"
                    }`}
                    name="creditInterestPeriod"
                    onChange={handleChange}
                    value={valuesProduct.creditInterestPeriod}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Actual/Actual">Actual/Actual</option>
                    <option value="Actual/360">Actual/360</option>
                    <option value="30/Actual">30/Actual</option>
                  </select>
                  {errorPage2.creditInterestPeriod && (
                    <ErrorMessage message={errorPage2.creditInterestPeriod} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Balance Basis (Credit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes("creditBalanceBasis") &&
                      "error-input-z"
                    }`}
                    name="creditBalanceBasis"
                    onChange={handleChange}
                    value={valuesProduct.creditBalanceBasis}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Daily Balance">Daily Balance</option>
                    <option value="Average Balance">Average Balance</option>
                  </select>
                  {errorPage2.creditBalanceBasis && (
                    <ErrorMessage message={errorPage2.creditBalanceBasis} />
                  )}
                </div>

                <div className="label--input flex--3">
                  <label>Interest Calc. Period (Credit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes(
                        "creditInterestCalculationPeriod"
                      ) && "error-input-z"
                    }`}
                    name="creditInterestCalculationPeriod"
                    onChange={handleChange}
                    value={valuesProduct.creditInterestCalculationPeriod}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Daily">Daily</option>
                  </select>
                  {errorPage2.creditInterestCalculationPeriod && (
                    <ErrorMessage
                      message={errorPage2.creditInterestCalculationPeriod}
                    />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Accrue Interest (Credit)</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes(
                        "creditAccrueInterest"
                      ) && "error-input-z"
                    }`}
                    name="creditAccrueInterest"
                    onChange={handleChange}
                    value={valuesProduct.creditAccrueInterest}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errorPage2.creditAccrueInterest && (
                    <ErrorMessage message={errorPage2.creditAccrueInterest} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>WHT Rate</label>
                  <input
                    type="text"
                    name={"creditInterestWHTRate"}
                    value={valuesProduct.creditInterestWHTRate}
                    onChange={handleChange}
                    className={`${
                      Object.keys(errorPage2).includes(
                        "creditInterestWHTRate"
                      ) && "error-input-z"
                    }`}
                  />
                  {errorPage2.creditInterestWHTRate && (
                    <ErrorMessage message={errorPage2.creditInterestWHTRate} />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>WHT Account</label>
                  <div
                  // className={`border--norm ${
                  //   Object.keys(errorPage2).includes("whtAccountNo") &&
                  //   "error-input-z"
                  // }`}
                  >
                    {/* <SearchDropdown
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type="whtAccountNo"
                    /> */}
                    <select
                      name="whtAccountNo"
                      value={valuesProduct.whtAccountNo}
                      onChange={handleChange}
                      className={`${
                        Object.keys(errorPage1).includes("whtAccountNo") &&
                        "error-input-z"
                      }`}
                      style={{ width: "100%" }}
                    >
                      <option value="">Select An Option</option>
                      {filterGlAccount.map((item) => (
                        <option
                          value={item.accountNumber}
                          key={item.accountNumber}
                        >
                          {item.accountNumber} - {item.accountName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errorPage2.whtAccountNo && (
                    <ErrorMessage message={errorPage2.whtAccountNo} />
                  )}
                </div>
              </div>

              <div className="row-container row-container--3 grid--1">
                <div className="label--input flex--3">
                  <label>Balance Req For Credit Interest</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="balanceRequestCreditInterest"
                    value={valuesProduct.balanceRequestCreditInterest}
                    className={`${
                      Object.keys(errorPage2).includes(
                        "balanceRequestCreditInterest"
                      ) && "error-input-z"
                    }`}
                  />
                  {errorPage2.balanceRequestCreditInterest && (
                    <ErrorMessage
                      message={errorPage2.balanceRequestCreditInterest}
                    />
                  )}
                </div>
                <div className="label--input flex--3">
                  <label>Interest Liquidation Period</label>
                  <select
                    className={`${
                      Object.keys(errorPage2).includes(
                        "interestLiquidationPeriod"
                      ) && "error-input-z"
                    }`}
                    name="interestLiquidationPeriod"
                    onChange={handleChange}
                    value={valuesProduct.interestLiquidationPeriod}
                  >
                    <option>Please, Select An Option</option>
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                  {errorPage2.interestLiquidationPeriod && (
                    <ErrorMessage
                      message={errorPage2.interestLiquidationPeriod}
                    />
                  )}
                </div>
              </div>

              <div className="flex--1 save" id="button--cont">
                <button
                  className="button--add"
                  onClick={() => setScreenChange(1)}
                >
                  Previous
                </button>
                <button
                  className="button--add"
                  id="button"
                  onClick={handleSecondPage}
                >
                  Next
                </button>
              </div>
            </section>
          </main>
        )}

        {screenChange === 3 && (
          <div>
            <Grid
              container
              spacing={2}
              className="main-section--2"
              sx={{ padding: "1rem" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Gateway Credit Account</label>
                <div
                // className={`border--norm ${
                //   Object.keys(errorPage3).includes("gateWayCreditAccount") &&
                //   "error-input-z"
                // }`}
                >
                  {/* <SearchDropdown
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type="gateWayCreditAccount"
                  /> */}
                  <select
                    name="gateWayCreditAccount"
                    value={valuesProduct.gateWayCreditAccount}
                    onChange={handleChange}
                    className={`${
                      Object.keys(errorPage1).includes(
                        "gateWayCreditAccount"
                      ) && "error-input-z"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <option value="">Select An Option</option>
                    {filterGlAccount.map((item) => (
                      <option
                        value={item.accountNumber}
                        key={item.accountNumber}
                      >
                        {item.accountNumber} - {item.accountName}
                      </option>
                    ))}
                  </select>
                </div>
                {errorPage3.gateWayCreditAccount && (
                  <ErrorMessage message={errorPage3.gateWayCreditAccount} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Gateway Debit Account</label>
                <div
                // className={`border--norm ${
                //   Object.keys(errorPage3).includes("gateWayDebitAccount") &&
                //   "error-input-z"
                // }`}
                >
                  {/* <SearchDropdown
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type="gateWayDebitAccount"
                  /> */}
                  <select
                    name="gateWayDebitAccount"
                    value={valuesProduct.gateWayDebitAccount}
                    onChange={handleChange}
                    className={`${
                      Object.keys(errorPage1).includes("gateWayDebitAccount") &&
                      "error-input-z"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <option value="">Select An Option</option>
                    {filterGlAccount.map((item) => (
                      <option
                        value={item.accountNumber}
                        key={item.accountNumber}
                      >
                        {item.accountNumber} - {item.accountName}
                      </option>
                    ))}
                  </select>
                </div>
                {errorPage3.gateWayDebitAccount && (
                  <ErrorMessage message={errorPage3.gateWayDebitAccount} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Membership fee</label>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  value={valuesProduct.membershipFee}
                  renderText={(value) => <div>{value}</div>}
                  className={`some ${
                    Object.keys(errorPage3).includes("membershipFee") &&
                    "error-input-z"
                  }`}
                  onValueChange={(values) => {
                    // eslint-disable-next-line
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setValuesProduct((valuesProduct) => ({
                      ...valuesProduct,
                      membershipFee: value,
                    }));
                  }}
                />
                {errorPage3.membershipFee && (
                  <ErrorMessage message={errorPage3.membershipFee} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Deductions</label>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  value={valuesProduct.deductions}
                  renderText={(value) => <div>{value}</div>}
                  className={`some ${
                    Object.keys(errorPage3).includes("deductions") &&
                    "error-input-z"
                  }`}
                  onValueChange={(values) => {
                    // eslint-disable-next-line
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setValuesProduct((valuesProduct) => ({
                      ...valuesProduct,
                      deductions: value,
                    }));
                  }}
                />
                {errorPage3.deductions && (
                  <ErrorMessage message={errorPage3.deductions} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Minimum Contribution</label>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  value={valuesProduct.minimumContribution}
                  renderText={(value) => <div>{value}</div>}
                  className={`some ${
                    Object.keys(errorPage3).includes("minimumContribution") &&
                    "error-input-z"
                  }`}
                  onValueChange={(values) => {
                    // eslint-disable-next-line
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setValuesProduct((valuesProduct) => ({
                      ...valuesProduct,
                      minimumContribution: value,
                    }));
                  }}
                />
                {errorPage3.minimumContribution && (
                  <ErrorMessage message={errorPage3.minimumContribution} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Tenure Type</label>
                <select
                  className={`${
                    Object.keys(errorPage3).includes("tenureType") &&
                    "error-input-z"
                  }`}
                  name="tenureType"
                  onChange={handleChange}
                  value={valuesProduct.tenureType}
                >
                  <option>Please, Select An Option</option>
                  <option value="None">None</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
                {errorPage3.tenureType && (
                  <ErrorMessage message={errorPage3.tenureType} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Frequency of Contribution</label>
                <input
                  type="number"
                  name="tenureForContribution"
                  value={valuesProduct.tenureForContribution}
                  className={`some ${
                    Object.keys(errorPage3).includes("tenureForContribution") &&
                    "error-input-z"
                  }`}
                  onChange={handleChange}
                />
                {errorPage3.tenureForContribution && (
                  <ErrorMessage message={errorPage3.tenureForContribution} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <label>Base Allowable Contribution</label>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  value={valuesProduct.baseAllowableContribution}
                  renderText={(value) => <div>{value}</div>}
                  className={`some ${
                    Object.keys(errorPage3).includes(
                      "baseAllowableContribution"
                    ) && "error-input-z"
                  }`}
                  onValueChange={(values) => {
                    // eslint-disable-next-line
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setValuesProduct((valuesProduct) => ({
                      ...valuesProduct,
                      baseAllowableContribution: value,
                    }));
                  }}
                />
                {errorPage3.baseAllowableContribution && (
                  <ErrorMessage
                    message={errorPage3.baseAllowableContribution}
                  />
                )}
              </Grid>
              <hr style={{ marginTop: 90 }} />

              <Grid
                container
                spacing={2}
                direction="column"
                className="next-section--2"
              >
                <h3>Customer Restriction</h3>
                <main className="main grid--1">
                  <div className="body-seperator flex--3">
                    <div className="restrict--type">
                      <label>Restriction Type</label>
                      <select>
                        <option>Allow</option>
                        <option>Disallow</option>
                      </select>
                    </div>
                    {createCustomerRestriction.map((item, index) => (
                      <div className="label--input flex--1" key={index}>
                        <div className="flex--3">
                          <label>Category Code</label>
                          <select
                            value={item.customerCategory}
                            name="customerCategory"
                            onChange={(e) =>
                              handleChangeCustomerRestriction(e, index)
                            }
                          >
                            <option>Select An Option</option>
                            {cusCategory.map((item, i) => (
                              <option
                                key={item.id}
                                className={
                                  errorPage3.customerCategory &&
                                  errorPage3.customerCategory.find(
                                    (el) => el.id === i
                                  )
                                    ? "error-input-z"
                                    : null
                                }
                                name={item.category}
                                value={item.category}
                              >
                                {item.category}
                              </option>
                            ))}
                          </select>
                          {errorPage3.customerRestCustomerCat &&
                            errorPage3.customerRestCustomerCat.map((el) => {
                              if (el.id === index) {
                                return (
                                  <ErrorMessage
                                    message={el.customerCategory}
                                    key={el.id}
                                  />
                                );
                              }
                              return null;
                            })}
                        </div>
                        <div className="flex--3">
                          <label>Category Description</label>
                          <input
                            value={item.customerName}
                            name="customerName"
                            disabled
                            onChange={(e) =>
                              handleChangeCustomerRestriction(e, index)
                            }
                            className={
                              errorPage3.customerRestCustomerName &&
                              errorPage3.customerRestCustomerName.find(
                                (el) => el.id === index
                              ) &&
                              "error-input-z"
                            }
                          />
                          {errorPage3.customerRestCustomerName &&
                            errorPage3.customerRestCustomerName.map((el, i) => {
                              if (el.id === index) {
                                return (
                                  <ErrorMessage
                                    message={el.customerName}
                                    key={el.id}
                                  />
                                );
                              }
                              return null;
                            })}
                        </div>
                        <button
                          disabled={
                            valuesProduct.createCustomerRestriction.length < 2
                          }
                          onClick={() => remove(index)}
                        >
                          delete
                        </button>
                      </div>
                    ))}
                    {/* <div className='flex--1 label--input'>
                      <div className='flex--3'>
                        <input/> 
                      </div>
                      <div className='flex--3'>
                        <input/>
                      </div>
                      <button>delete</button>
                    </div> */}
                  </div>
                  <div className="flex--3 button-add-cont">
                    <button className="button--add" onClick={add}>
                      +
                    </button>
                    <p>Add More</p>
                  </div>
                  {/* <div className='flex--3'>
                    <label>Customer Category</label>
                    <input/>
                    <input/>
                    <input/>
                  </div> */}
                </main>
              </Grid>

              <div className="flex--1 save" id="button--cont">
                <button
                  className="button--add"
                  onClick={() => setScreenChange(2)}
                >
                  Previous
                </button>
                <button
                  className="button--add"
                  id="button"
                  onClick={handleConfirm}
                >
                  Save
                </button>
              </div>
            </Grid>
          </div>
        )}
      </div>

      {!loading && (
        <Table
          title="Account Products"
          subtitle=""
          name="List of Account Products"
          records={data}
          options={options}
          columns={columns}
        />
      )}

      {details && details.id && <Details details={details} />}
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorPage1}
          handleEditSubmit={handleEditSubmit}
          handleEditChange={handleEditChange}
          remove={remove}
          add={add}
          filterGlAccount={filterGlAccount}
          handleChangeCustomerRestriction={handleChangeCustomerRestriction}
          cusCategory={cusCategory}
          createCustomerRestriction={editCustomerRestriction}
        />
      )}
    </main>
  );
};
export default AccountProduct;

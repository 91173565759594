import React from 'react'
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import AccountProduct from './AccountProducts/AccountProduct';
import LoanProduct from './LoanProducts/LoanProducts';
import InvestmentProduct from './InvestmentProducts/InvestmentProducts';
import TellerProductDefinition from './TellerProductDefinition/TellerProductDefinition';




const Main = () => { 
    return (
        <div className='main'>
            <Switch> 
                <Route path='/dashboard/authorizeproducts/account'component={AccountProduct}/>
                <Route path='/dashboard/authorizeproducts/loan'component={LoanProduct}/>
                <Route path='/dashboard/authorizeproducts/investment'component={InvestmentProduct}/>
                <Route path='/dashboard/authorizeproducts/teller'component={TellerProductDefinition }/>
            </Switch>
        </div>
    )
}

export default Main

import { useEffect, useState } from 'react'
import "./ProductDefinition.scss";
import MenuItem from '@material-ui/core/MenuItem';
import { FaWindowClose } from "react-icons/fa";
import ErrorMessage from "../../../ErrorMessage";
import BusinessLogic from "./businessLogic";
import useCustom2 from '../../../../Api/Hooks-manager/coreModules/useCustom'
import useCustom3 from '../../../../Api/Hooks-manager/coreModules/useCustom'
import SearchDropdown from '../../../SearchableDropdown/Search'
import Confirm from '../../../../Confirmbox/Confirm'
import Spinner from '../../../Backdrop/index'
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";
const ProductDefinition = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const loader = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const {
    screenChange,
    message,
    loading,
    handleConfirmUpdate,
    handleCloseAndScreenReset,
    openBoxUpdate,
    handleScreenChange,
    error,
    product,
    handleChanger,
    handleNext,
    handleChangeSelect,
    handleFieldUpdate,
    add,
    remove,
    openBox,
    setOpenBox,
    handleProductDefinitionCreate,
    handleConfirm,
    handleChangeOtherFeesAndIncome,
    data,
    getAllProductDefintionTreasury,
  } = BusinessLogic();
  const { allGlAccount } = useCustom2()
  const { allCurrencyDefinition, getCurrencyDefinitionAll, loader: loaders  } = useCustom3()

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productCode",
      label: "Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productName",
      label: "Product Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portFolioType",
      label: "PortFolio Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productBuyLeg",
      label: "Product BuyLeg",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productSellLeg",
      label: "Product SellLeg",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "defaultInterestRate",
      label: "Default Intere. Rate",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];
  
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  useEffect(() => {
    getAllProductDefintionTreasury()
  }, [getAllProductDefintionTreasury,updateTable])
      
  const [errorEdit, setErrorEdit] = useState()
  const handleEditSubmit = () => {
    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };
  const handleEditChange = (name) => (e) => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const filterGlAccount = allGlAccount.filter(
    (items) => items.glHierarchy === "Child"
  );


  const glAccountNumber = filterGlAccount.map((item) => {
    return {
      value: item.accountNumber,
      label: `${item.accountNumber} -- ${item.accountName}`,
    };
  });

  useEffect(() => {
    getCurrencyDefinitionAll()
}, [getCurrencyDefinitionAll])




  return (
    <div>
    <div className="Product--definition border--main">
      <div className="headerdiv">
        <p>Treasury | Product Definition</p>
      </div>
      <div style={{ position: 'absolute'}}>
          <Spinner loading={loading}/>
      </div> 
      <Confirm 
        ApiCall={handleProductDefinitionCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
        actionMsg={message}
        errorMessage={message}
      />
      {!screenChange && (
        <>
          <div style={{ paddingTop: 20, padding: 25 }}>
            <div className="product--def flex--1">
              <div className="flex--3 input--1">
                <label>Product Code</label>
                <input
                  type="text"
                  className={error.productCode ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.productCode}
                  name="productCode"
                  maxLength="6"
                />
                {error.productCode && (
                  <ErrorMessage message={error.productCode} />
                )}
              </div>
              <div className="flex--3 input--2">
                <label>Product Name</label>
                <input
                  type="text"
                  className={error.productName ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.productName}
                  name="productName"
                />
                {error.productName && (
                  <ErrorMessage message={error.productName} />
                )}
              </div>
            </div>
            <div className="checboxdiv flex--2">
              <div>
                <input
                  type="checkbox"
                  name="deactivateProduct"
                  onChange={() => handleFieldUpdate("deactivateProduct")}
                  checked={product.deactivateProduct}
                  id="deactivateProduct"
                />
              </div>
              <label className="span" htmlFor="deactivateProduct">
                {" "}
                Deactivate Product
              </label>
            </div>
          </div>
          <section style={{ paddingLeft: 30, paddingRight: 30 }}>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Portfolio Type</label>
                <select
                  className={error.portFolioType ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.portFolioType}
                  name="portFolioType"
                >
                  <option value="">Select An Option</option>
                  <option value="Bank">Bank</option>
                  <option value="Customer">Customer</option>
                </select>
                {error.portFolioType && (
                  <ErrorMessage message={error.portFolioType} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Products Buy-leg</label>
                <select
                  className={error.productBuyLeg ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.productBuyLeg}
                  name="productBuyLeg"
                >
                  <option value="">Select An Option</option>
                  <option value="Bank">Bank</option>
                  <option value="Customer">Customer</option>
                </select>
                {error.productBuyLeg && (
                  <ErrorMessage message={error.productBuyLeg} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Products Sell-leg</label>
                <select
                  className={error.productSellLeg ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.productSellLeg}
                  name="productSellLeg"
                >
                  <option value="">Select An Option</option>
                  <option value="Bank">Bank</option>
                  <option value="Customer">Customer</option>
                </select>
                {error.productSellLeg && (
                  <ErrorMessage message={error.productSellLeg} />
                )}
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--4">
                <label>Default Interest Rate</label>
                <input
                  type="number"
                  step="any"
                  className={error.defaultInterestRate ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.defaultInterestRate}
                  name="defaultInterestRate"
                />
                {error.defaultInterestRate && (
                  <ErrorMessage message={error.defaultInterestRate} />
                )}
              </div>
              <div className="flex--3 input--4">
                <label>Minimum Interest Rate</label>
                <input
                  type="number"
                  step="any"
                  className={error.minimumInterestRate ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.minimumInterestRate}
                  name="minimumInterestRate"
                />
                {error.minimumInterestRate && (
                  <ErrorMessage message={error.minimumInterestRate} />
                )}
              </div>
              <div className="flex--3 input--4">
                <label>Maximum Interest Rate</label>
                <input
                  type="number"
                  step="any"
                  className={error.maximumInterestRate ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.maximumInterestRate}
                  name="maximumInterestRate"
                />
                {error.maximumInterestRate && (
                  <ErrorMessage message={error.maximumInterestRate} />
                )}
              </div>
              <div className="flex--3 input--4">
                <label>Rate Basis</label>
                <select
                  className={error.rateBasis ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.rateBasis}
                  name="rateBasis"
                >
                  <option value="">Select An Option</option>
                  <option value="Monthly">Per Month</option>
                  <option value="Annum">Per Annum</option>
                </select>
                {error.rateBasis && <ErrorMessage message={error.rateBasis} />}
              </div>
              <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Coupon Maturity Basis</label>
                  <select
                    className={
                      error.couponMaturityBasis ? "error-input-z" : null
                    }
                    onChange={handleChanger}
                    value={product.couponMaturityBasis}
                    name="couponMaturityBasis"
                  >
                    <option value="">Select An Option</option>
                    <option value="ZeroCoupon">Zero Coupon</option>
                    <option value="UpFront">Upfront Interest</option>
                  </select>
                  {error.couponMaturityBasis && (
                    <ErrorMessage message={error.couponMaturityBasis} />
                  )}
                </div>
                <div className="flex--3 input--3">
                  <label>Effective Rate Date</label>
                  <input
                    type="date"
                    className={error.effectiveRateDate ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.effectiveRateDate}
                    name="effectiveRateDate"
                  />
                  {error.effectiveRateDate && (
                    <ErrorMessage message={error.effectiveRateDate} />
                  )}
                </div>
              </div>
              <div className="flex--3 input--4">
                <label>Default Tenor</label>
                <input
                  type="number"
                  className={error.defaultTenor ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.defaultTenor}
                  name="defaultTenor"
                />
                {error.defaultTenor && (
                  <ErrorMessage message={error.defaultTenor} />
                )}
              </div>
              <div className="flex--3 input--4">
                <label>Minimum Tenor</label>
                <input
                  type="number"
                  className={error.minimumTenor ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.minimumTenor}
                  name="minimumTenor"
                />
                {error.minimumTenor && (
                  <ErrorMessage message={error.minimumTenor} />
                )}
              </div>
              <div className="flex--3 input--4">
                <label>Maximum Tenor</label>
                <input
                  type="number"
                  className={error.maximumTenor ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.maximumTenor}
                  name="maximumTenor"
                />
                {error.maximumTenor && (
                  <ErrorMessage message={error.maximumTenor} />
                )}
              </div>
              <div className="flex--3 input--4">
                <label>Tenor Basis</label>
                <select
                  className={error.tenorBasis ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.tenorBasis}
                  name="tenorBasis"
                >
                  <option value="">Select An Option</option>
                  <option value="Days">Days</option>
                  <option value="Months">Months</option>
                  <option value="Years">Years</option>
                </select>
                {error.tenorBasis && (
                  <ErrorMessage message={error.tenorBasis} />
                )}
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Issue Date</label>
                <input
                  type="date"
                  className={error.issueDate ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.issueDate}
                  name="issueDate"
                />
                {error.issueDate && <ErrorMessage message={error.issueDate} />}
              </div>
              <div className="flex--3 input--2">
                <label>Issuer Name</label>
                <input
                  type="text"
                  className={error.issuerName ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.issuerName}
                  name="issuerName"
                />
                {error.issuerName && (
                  <ErrorMessage message={error.issuerName} />
                )}
              </div>
              <div className="flex--3 input--2">
                <label>Issue Price</label>
                <input
                  type="number"
                  step="any"
                  className={error.issuerPrice ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.issuerPrice}
                  name="issuerPrice"
                />

                {error.issuerPrice && (
                  <ErrorMessage message={error.issuerPrice} />
                )}
              </div>
            </div>
            <div className="btn-next flex--1">
              <button className="next" onClick={handleNext}>
                Next
              </button>
            </div>
          </section>
        </>
      )}
      {screenChange && (
        <>
          <div style={{ paddingTop: 20, padding: 25 }}>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Start of Trading Date</label>
                <input
                  type="date"
                  className={error.startofTradingDate ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.startofTradingDate}
                  name="startofTradingDate"
                />
                {error.startofTradingDate && (
                  <ErrorMessage message={error.startofTradingDate} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Profit/Loss Account</label>
                <input
                  type="text"
                  className={error.profitOrLossAccount ? "error-input-z" : null}
                  onChange={handleChanger}
                  value={product.profitOrLossAccount}
                  name="profitOrLossAccount"
                />
                {error.profitOrLossAccount && (
                  <ErrorMessage message={error.profitOrLossAccount} />
                )}
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Product Credit Account</label>
                <div className={`border--norm ${Object.keys(error).includes('productCreditAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='productCreditAccount'
                  />
                </div>
                {error.productCreditAccount && (
                  <ErrorMessage message={error.productCreditAccount} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Products Debit Account</label>
                <div className={`border--norm ${Object.keys(error).includes('productDebitAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='productDebitAccount'
                  />
                </div>
                {error.productDebitAccount && (
                  <ErrorMessage message={error.productDebitAccount} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Interest Account</label>
                <div className={`border--norm ${Object.keys(error).includes('interestAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='interestAccount'
                  />
                </div>
                {error.interestAccount && (
                  <ErrorMessage message={error.interestAccount} />
                )}
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Interest Income</label>
                <div className={`border--norm ${Object.keys(error).includes('interestIncomeAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='interestIncomeAccount'
                  />
                </div>
                {error.interestIncomeAccount && (
                  <ErrorMessage message={error.interestIncomeAccount} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Interest Expense</label>
                <div className={`border--norm ${Object.keys(error).includes('interestExpenseAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='interestExpenseAccount'
                  />
                </div>
                {error.interestExpenseAccount && (
                  <ErrorMessage message={error.interestExpenseAccount} />
                )}
              </div>
              <div className="flex--3 input--3">
                <label>Interest Payable Account</label>
                <div className={`border--norm ${Object.keys(error).includes('interestPayableAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='interestPayableAccount'
                  />
                </div>
                {error.interestPayableAccount && (
                  <ErrorMessage message={error.interestPayableAccount} />
                )}
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Interest Receivable Account</label>
                <div className={`border--norm ${Object.keys(error).includes('interestReceivableAccount') && 'error-input-z'}`}>
                  <SearchDropdown 
                    options={glAccountNumber}
                    onChange={handleChangeSelect}
                    type='interestReceivableAccount'
                  />
                </div>
                {error.interestReceivableAccount && (
                  <ErrorMessage message={error.interestReceivableAccount} />
                )}
              </div>
            </div>

            <section className="section--2">
              <div className="divspan">
                <span className="span2">Other Fees and Income</span>
              </div>
              <div className="clearingbox">
                {product.otherFeesAndIncome.map((item, index) => (
                  <div className="product--def flex--1"  key={index}>
                    <div className="flex--3 input--5">
                      <label>Charge Name</label>
                      <input
                        type="text"
                        className={error.effectiveRateDate ? "error-input-z" : null}
                        onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                        value={item.chargeName}
                        name="chargeName"
                        maxLength='10'
                      />
                    </div>
                    <div className="flex--3 input--5">
                      <label>Apply Charge At</label>
                      <input
                        type="text"
                        className={error.effectiveRateDate ? "error-input-z" : null}
                        onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                        value={item.applyChargeAccount}
                        name="applyChargeAccount"
                        maxLength='10'
                      />
                    </div>
                    <div className="flex--3 input--5">
                      <label>Charge Ledger Acc</label>
                      <input
                        type="text"
                        className={error.effectiveRateDate ? "error-input-z" : null}
                        onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                        value={item.chargeLedgerAccount}
                        name="chargeLedgerAccount"
                        maxLength='10'
                      />
                    </div>
                    <div className="flex--3 input--5">
                      <label>Type of Charge</label>
                      <select
                        className={error.portFolioType ? "error-input-z" : null}
                        onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                        value={item.chargeType}
                        name="chargeType"
                      >
                        <option value="">Select An Option</option>
                        <option value="FlatAmount">Flat Amount </option>
                        <option value="PercentageRate">Percentage Rate</option>
                      </select>
                    </div>
                    <div className="flex--3 input--5">
                      <label>Amount/Rate</label>
                      <input
                        type="text"
                        className={error.effectiveRateDate ? "error-input-z" : null}
                        onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                        value={item.amountRate}
                        name="amountRate"
                        maxLength='10'
                      />
                    </div>
                    <div className="flex--3 input--5">
                      <label>Charge Currency</label>
                      <select name='chargeCurrency' value={item.chargeCurrency} onChange={(e) => handleChangeOtherFeesAndIncome(e, index)} className={`${Object.keys(error).includes('chargeCurrency') && 'error-input-z'}`}>
                        <option value=''>Select An Option</option>
                        {allCurrencyDefinition.map((currency) => (
                              <option value={currency.currencyCode} key={currency.id}>
                              {currency.currencyName}
                              </option>
                        ))}
                      </select>
                    </div>
                    <button disabled={product.otherFeesAndIncome.length<2} className="cancel--icon" onClick={() => remove(index)}>&times;</button>
                  </div>
                ))
                }
                <div className="button-flex flex--3">
                  <button className="add" onClick={add}>+</button>
                  <label>Add</label>
                </div>
              </div>
              <div className="button--2 flex--1">
                <button
                  className="previous"
                  onClick={() => handleScreenChange(false)}
                >
                  Previous
                </button>
                <button className="save" onClick={handleConfirm}>
                  Save
                </button>
              </div>
            </section>
          </div>
        </>
      )}


    {openBoxUpdate &&
      <section className='updater--box'>
        <section className='update--content'>
          <FaWindowClose className='close-box' onClick={handleCloseAndScreenReset}/>
          {!screenChange &&
          <>
            <div style={{ paddingTop: 20, padding: 25 }}>
              <div className="product--def flex--1">
                <div className="flex--3 input--1">
                  <label>Product Code</label>
                  <input
                    type="text"
                    className={error.productCode ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.productCode}
                    name="productCode"
                    maxLength="6"
                  />
                  {error.productCode && (
                    <ErrorMessage message={error.productCode} />
                  )}
                </div>
                <div className="flex--3 input--2">
                  <label>Product Name</label>
                  <input
                    type="text"
                    className={error.productName ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.productName}
                    name="productName"
                  />
                  {error.productName && (
                    <ErrorMessage message={error.productName} />
                  )}
                </div>
              </div>
              <div className="checboxdiv flex--2">
                <div>
                  <input
                    type="checkbox"
                    name="deactivateProduct"
                    onChange={() => handleFieldUpdate("deactivateProduct")}
                    checked={product.deactivateProduct}
                    id="deactivateProduct"
                  />
                </div>
                <label className="span" htmlFor="deactivateProduct">
                  {" "}
                  Deactivate Product
                </label>
              </div>
            </div>
            <section style={{ paddingLeft: 30, paddingRight: 30 }}>
              <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Portfolio Type</label>
                  <select
                    className={error.portFolioType ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.portFolioType}
                    name="portFolioType"
                  >
                    <option value="">Select An Option</option>
                    <option value="Bank">Bank</option>
                    <option value="Customer">Customer</option>
                  </select>
                  {error.portFolioType && (
                    <ErrorMessage message={error.portFolioType} />
                  )}
                </div>
                <div className="flex--3 input--3">
                  <label>Products Buy-leg</label>
                  <select
                    className={error.productBuyLeg ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.productBuyLeg}
                    name="productBuyLeg"
                  >
                    <option value="">Select An Option</option>
                    <option value="Bank">Bank</option>
                    <option value="Customer">Customer</option>
                  </select>
                  {error.productBuyLeg && (
                    <ErrorMessage message={error.productBuyLeg} />
                  )}
                </div>
                <div className="flex--3 input--3">
                  <label>Products Sell-leg</label>
                  <select
                    className={error.productSellLeg ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.productSellLeg}
                    name="productSellLeg"
                  >
                    <option value="">Select An Option</option>
                    <option value="Bank">Bank</option>
                    <option value="Customer">Customer</option>
                  </select>
                  {error.productSellLeg && (
                    <ErrorMessage message={error.productSellLeg} />
                  )}
                </div>
              </div>
              <div className="product--def flex--1">
                <div className="flex--3 input--4">
                  <label>Default Interest Rate</label>
                  <input
                    type="number"
                    step="any"
                    className={error.defaultInterestRate ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.defaultInterestRate}
                    name="defaultInterestRate"
                  />
                  {error.defaultInterestRate && (
                    <ErrorMessage message={error.defaultInterestRate} />
                  )}
                </div>
                <div className="flex--3 input--4">
                  <label>Minimum Interest Rate</label>
                  <input
                    type="number"
                    step="any"
                    className={error.minimumInterestRate ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.minimumInterestRate}
                    name="minimumInterestRate"
                  />
                  {error.minimumInterestRate && (
                    <ErrorMessage message={error.minimumInterestRate} />
                  )}
                </div>
                <div className="flex--3 input--4">
                  <label>Maximum Interest Rate</label>
                  <input
                    type="number"
                    step="any"
                    className={error.maximumInterestRate ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.maximumInterestRate}
                    name="maximumInterestRate"
                  />
                  {error.maximumInterestRate && (
                    <ErrorMessage message={error.maximumInterestRate} />
                  )}
                </div>
                <div className="flex--3 input--4">
                  <label>Rate Basis</label>
                  <select
                    className={error.rateBasis ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.rateBasis}
                    name="rateBasis"
                  >
                    <option value="">Select An Option</option>
                    <option value="Monthly">Per Month</option>
                    <option value="Annum">Per Annum</option>
                  </select>
                  {error.rateBasis && <ErrorMessage message={error.rateBasis} />}
                </div>
                <div className="product--def flex--1">
                  <div className="flex--3 input--3">
                    <label>Coupon Maturity Basis</label>
                    <select
                      className={
                        error.couponMaturityBasis ? "error-input-z" : null
                      }
                      onChange={handleChanger}
                      value={product.couponMaturityBasis}
                      name="couponMaturityBasis"
                    >
                      <option value="">Select An Option</option>
                      <option value="ZeroCoupon ">Zero Coupon</option>
                      <option value="UpFront">Upfront Interest</option>

                    </select>
                    {error.couponMaturityBasis && (
                      <ErrorMessage message={error.couponMaturityBasis} />
                    )}
                  </div>
                  <div className="flex--3 input--3">
                    <label>Effective Rate Date</label>
                    <input
                      type="date"
                      className={error.effectiveRateDate ? "error-input-z" : null}
                      onChange={handleChanger}
                      value={product.effectiveRateDate}
                      name="effectiveRateDate"
                    />
                    {error.effectiveRateDate && (
                      <ErrorMessage message={error.effectiveRateDate} />
                    )}
                  </div>
                </div>
                <div className="flex--3 input--4">
                  <label>Default Tenor</label>
                  <input
                    type="number"
                    className={error.defaultTenor ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.defaultTenor}
                    name="defaultTenor"
                  />
                  {error.defaultTenor && (
                    <ErrorMessage message={error.defaultTenor} />
                  )}
                </div>
                <div className="flex--3 input--4">
                  <label>Minimum Tenor</label>
                  <input
                    type="number"
                    className={error.minimumTenor ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.minimumTenor}
                    name="minimumTenor"
                  />
                  {error.minimumTenor && (
                    <ErrorMessage message={error.minimumTenor} />
                  )}
                </div>
                <div className="flex--3 input--4">
                  <label>Maximum Tenor</label>
                  <input
                    type="number"
                    className={error.maximumTenor ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.maximumTenor}
                    name="maximumTenor"
                  />
                  {error.maximumTenor && (
                    <ErrorMessage message={error.maximumTenor} />
                  )}
                </div>
                <div className="flex--3 input--4">
                  <label>Tenor Basis</label>
                  <select
                    className={error.tenorBasis ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.tenorBasis}
                    name="tenorBasis"
                  >
                    <option value="">Select An Option</option>
                    <option value="Days">Days</option>
                    <option value="Months">Months</option>
                    <option value="Years">Years</option>
                  </select>
                  {error.tenorBasis && (
                    <ErrorMessage message={error.tenorBasis} />
                  )}
                </div>
              </div>
              <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Issue Date</label>
                  <input
                    type="date"
                    className={error.issueDate ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.issueDate}
                    name="issueDate"
                  />
                  {error.issueDate && <ErrorMessage message={error.issueDate} />}
                </div>
                <div className="flex--3 input--2">
                  <label>Issuer Name</label>
                  <input
                    type="text"
                    className={error.issuerName ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.issuerName}
                    name="issuerName"
                  />
                  {error.issuerName && (
                    <ErrorMessage message={error.issuerName} />
                  )}
                </div>
                <div className="flex--3 input--2">
                  <label>Issue Price</label>
                  <input
                    type="text"
                    className={error.issuerPrice ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.issuerPrice}
                    name="issuerPrice"
                  />
                  {error.issuerPrice && (
                    <ErrorMessage message={error.issuerPrice} />
                  )}
                </div>
              </div>
              <div className="btn-next flex--1">
                <button className="next" onClick={handleNext}>
                  Next
                </button>
              </div>
            </section>
          </>
          }
          {screenChange && (
          <>
            <div style={{ paddingTop: 20, padding: 25 }}>
              {/* <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Start of Trading Date</label>
                  <input
                    type="date"
                    className={error.startofTradingDate ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.startofTradingDate}
                    name="startofTradingDate"
                  />
                  {error.startofTradingDate && (
                    <ErrorMessage message={error.startofTradingDate} />
                  )}
                </div> */}
                {/* <div className="flex--3 input--3">
                  <label>Profit/Loss Account</label>
                  <input
                    type="text"
                    className={error.profitOrLossAccount ? "error-input-z" : null}
                    onChange={handleChanger}
                    value={product.profitOrLossAccount}
                    name="profitOrLossAccount"
                  />
                  {error.profitOrLossAccount && (
                    <ErrorMessage message={error.profitOrLossAccount} />
                  )}
                </div>
              </div> */}
              {/* <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Product Credit Account</label>
                  <div className={`border--norm ${Object.keys(error).includes('productCreditAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='productCreditAccount'
                    />
                  </div>
                  {error.productCreditAccount && (
                    <ErrorMessage message={error.productCreditAccount} />
                  )}
                </div> */}
                {/* <div className="flex--3 input--3">
                  <label>Products Debit Account</label>
                  <div className={`border--norm ${Object.keys(error).includes('productDebitAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='productDebitAccount'
                    />
                  </div>
                  {error.productDebitAccount && (
                    <ErrorMessage message={error.productDebitAccount} />
                  )}
                </div> */}
                {/* <div className="flex--3 input--3">
                  <label>Interest Account</label>
                  <div className={`border--norm ${Object.keys(error).includes('interestAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='interestAccount'
                    />
                  </div>
                  {error.interestAccount && (
                    <ErrorMessage message={error.interestAccount} />
                  )}
                </div>
              </div> */}
              {/* <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Interest Income</label>
                  <div className={`border--norm ${Object.keys(error).includes('interestIncomeAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='interestIncomeAccount'
                    />
                  </div>
                  {error.interestIncomeAccount && (
                    <ErrorMessage message={error.interestIncomeAccount} />
                  )}
                </div> */}
                {/* <div className="flex--3 input--3">
                  <label>Interest Expense</label>
                  <div className={`border--norm ${Object.keys(error).includes('interestExpenseAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='interestExpenseAccount'
                    />
                  </div>
                  {error.interestExpenseAccount && (
                    <ErrorMessage message={error.interestExpenseAccount} />
                  )}
                </div> */}
                {/* <div className="flex--3 input--3">
                  <label>Interest Payable Account</label>
                  <div className={`border--norm ${Object.keys(error).includes('interestPayableAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='interestPayableAccount'
                    />
                  </div>
                  {error.interestPayableAccount && (
                    <ErrorMessage message={error.interestPayableAccount} />
                  )}
                </div>
              </div> */}
              {/* <div className="product--def flex--1">
                <div className="flex--3 input--3">
                  <label>Interest Receivable Account</label>
                  <div className={`border--norm ${Object.keys(error).includes('interestReceivableAccount') && 'error-input-z'}`}>
                    <SearchDropdown 
                      options={glAccountNumber}
                      onChange={handleChangeSelect}
                      type='interestReceivableAccount'
                    />
                  </div>
                  {error.interestReceivableAccount && (
                    <ErrorMessage message={error.interestReceivableAccount} />
                  )}
                </div>
              </div> */}
              <section className="section--2">
                <div className="divspan">
                  <span className="span2">Other Fees and Income</span>
                </div>
                <div className="clearingbox">
                  {product.otherFeesAndIncome.map((item, index) => (
                    <div className="product--def flex--1"  key={index}>
                      <div className="flex--3 input--5">
                        <label>Charge Name</label>
                        <input
                          type="text"
                          className={error.effectiveRateDate ? "error-input-z" : null}
                          onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                          value={item.chargeName}
                          name="chargeName"
                          maxLength='10'
                        />
                      </div>
                      <div className="flex--3 input--5">
                        <label>Apply Charge At</label>
                        <input
                          type="text"
                          className={error.effectiveRateDate ? "error-input-z" : null}
                          onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                          value={item.applyChargeAccount}
                          name="applyChargeAccount"
                          maxLength='10'
                        />
                      </div>
                      <div className="flex--3 input--5">
                        <label>Charge Ledger Acc</label>
                        <input
                          type="text"
                          className={error.effectiveRateDate ? "error-input-z" : null}
                          onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                          value={item.chargeLedgerAccount}
                          name="chargeLedgerAccount"
                          maxLength='10'
                        />
                      </div>
                      <div className="flex--3 input--5">
                        <label>Type of Charge</label>
                        <select
                          className={error.portFolioType ? "error-input-z" : null}
                          onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                          value={item.chargeType}
                          name="chargeType"
                        >
                          <option value="">Select An Option</option>
                          <option value="FlatAmount">Flat Amount </option>
                          <option value="PercentageRate">Percentage Rate</option>
                        </select>
                      </div>
                      <div className="flex--3 input--5">
                        <label>Amount/Rate</label>
                        <input
                          type="text"
                          className={error.effectiveRateDate ? "error-input-z" : null}
                          onChange={(e) => handleChangeOtherFeesAndIncome(e, index)}
                          value={item.amountRate.toFixed(2)}
                          name="amountRate"
                          maxLength='10'
                        />
                      </div>
                      <div className="flex--3 input--5">
                        <label>Charge Currency</label>
                        <select name='chargeCurrency' value={item.chargeCurrency} onChange={(e) => handleChangeOtherFeesAndIncome(e, index)} className={`${Object.keys(error).includes('chargeCurrency') && 'error-input-z'}`}>
                          <option value=''>Select An Option</option>
                          {allCurrencyDefinition.map((currency) => (
                                <option value={currency.currencyCode} key={currency.id}>
                                {currency.currencyName}
                                </option>
                          ))}
                        </select>
                      </div>
                      <button disabled={product.otherFeesAndIncome.length<2} className="cancel--icon" onClick={() => remove(index)}>&times;</button>
                    </div>
                  ))
                  }
                  <div className="button-flex flex--3">
                    <button className="add" onClick={add}>+</button>
                    <label>Add</label>
                  </div>
                </div>
                <div className="button--2 flex--1">
                  <button
                    className="previous"
                    onClick={() => handleScreenChange(false)}
                  >
                    Previous
                  </button>
                  <button className="save" onClick={handleConfirmUpdate}>
                    Update
                  </button>
                </div>
              </section>
            </div>
          </>
          )}
        </section>
      </section>
    }

    </div>
      {!loading && (
        <Table
          title="Product Definition"
          subtitle=""
          name="List of Products"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleEditSubmit}
          details={details}
          handleEditChange={handleEditChange}
          errorEdit={{}}
          handleChangeSelect={handleChangeSelect}
          glAccountNumber={glAccountNumber}
          allCurrencyDefinition={allCurrencyDefinition}
          handleChangeOtherFeesAndIncome={handleChangeOtherFeesAndIncome}
        />
      )}
  </div>
  );
};

export default ProductDefinition;

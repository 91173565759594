import React from "react";
import "./CashDeposit.scss";

const CashDeposit = () => {
  const [screenChange, setScreenChange] = React.useState(false);

  const handleScreenChange = (i) => {
    console.log("qwswqeq", i);
    setScreenChange(i);
  };

  return (
    <main className="cash-deposit--fcy">
      <div className="heading">
        <p>Cash Deposit - Foriegn Currency</p>
      </div>
      <div className="button--double flex--2">
        <div className="flex--3">
          <button
            className={`${!screenChange ? "active-1" : ""}`}
            onClick={() => handleScreenChange(false)}
          >
            1
          </button>
          <p>Transaction</p>
        </div>
        <div className="flex--3">
          <button
            className={`${screenChange ? "active-1" : ""}`}
            onClick={() => handleScreenChange(true)}
          >
            2
          </button>
          <p>Denominations</p>
        </div>
      </div>
      {!screenChange && (
        <>
        
          <div style={{ paddingTop: 12, padding: 25 }}>
            <div className="product--def flex--1">
              <div className="flex--3 input--1">
                <label>Account Number</label>
                <input type="text" />
              </div>
              <div className="sub-input input--2">
                <input />
              </div>
              <div className="flex--3 input--1">
                <label>Branch</label>
                <input type="text" />
              </div>
              <div className="sub-input input--2">
                <input />
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--3">
                <label>Product</label>
                <input type="text" />
              </div>
              <div className="flex--3 input--3">
                <label>Currency</label>
                <input type="text" />
              </div>
              <div className="flex--3 input--3">
                <label>Customer BVN</label>
                <input type="text" />
              </div>
              <div className="flex--3 input--2">
                <label>Available Balance</label>
                <input type="number" step="any" />
              </div>
              <div className="flex--3 input--6">
                <label>Amount</label>
                <input type="number" step="any" />
              </div>
            </div>
            <div className="product--def flex--1">
              <div className="flex--3 input--4">
                <label>Narration</label>
                <input type="text" />
              </div>
            </div>
            <div className="flex--5">
              <button onClick={() => handleScreenChange(true)}>Next</button>
            </div>

            <hr />
            <h4>Cash Deposit</h4>
            <div className="sub--section flex--2">
              <div className="main-filter flex--2">
                <input
                  className="filter"
                  placeholder="Filter"
                  //   onChange={handleSearch}
                />
                <button className="button--second">Remove Filter</button>
              </div>
            </div>
          </div>
          {/* <section className='sub-section'>
                            <div className='flex--1 container-main'>
                                <div className='input-container flex--3'>
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Account Number</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Product</label>
                                        <input/>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Customer BVN</label>
                                        <input/>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Email Address</label>
                                        <input/>
                                    </div>
                                </div>
                                <div className='input-container flex--3'> 
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Branch</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Currency</label>
                                        <input/>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Phone Number</label>
                                        <input/>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Amount</label>
                                        <input/>
                                    </div>
                                </div>
                            </div> */}

          {/* <div>
                                <input type='checkbox' id='check'/>
                                <label id='check'>Car</label>
                                <input type='checkbox' id='check'/>
                            </div> */}
          {/* <div className='last-input'>
                                <input />
                            </div>
                        </section> */}

          {/* <div className='button-last'>
                            <button onClick={() => handleScreenChange(true)}>Next</button>
                        </div> */}
        </>
      )}
      {screenChange && (
        <>
          <main className="sub-main">
            <section className="sub-main--1 flex--2">
              <div className="flex--2">
                <p>Amount</p>
                <input type="number" step="any" />
              </div>
              <div className="flex--2">
                <p>Net Amount</p>
                <input type="number" step="any" />
              </div>
            </section>
            <section className="sub-main--2 flex--3">
              <div className="multiplier-input flex--2">
                <input />
                <span>&times;</span>
                <input />
                <span>&#61;</span>
                <input />
              </div>
              <div className="multiplier-input flex--2">
                <input />
                <span>&times;</span>
                <input />
                <span>&#61;</span>
                <input />
              </div>
              <div className="multiplier-input flex--2">
                <input />
                <span>&times;</span>
                <input />
                <span>&#61;</span>
                <input />
              </div>
              <div className="multiplier-input flex--2">
                <input />
                <span>&times;</span>
                <input />
                <span>&#61;</span>
                <input />
              </div>
            </section>
          </main>
          <div className="button-last button-last--1 flex--2">
            <div className="button-container flex--1">
              <button onClick={() => handleScreenChange(false)}>
                Previous
              </button>
              <button onClick={() => handleScreenChange(true)}>Save</button>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default CashDeposit;

import React from "react";
import "./FundTransfer.scss";
import BusinessLogic from "./businessLogic";
import { useDispatch } from "react-redux";

import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import NumberFormat from "react-number-format";
import Details from "./View";


const FundTransfer = () => {
  const {
    glTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    errors,
    details,
    handleChange,
    makeTransfer,
    open,
    setOpen,
    message,
    handleSubmit,
    fetchDebitAccountDetails,
  } = BusinessLogic();
  
  const dispatch = useDispatch();


  return (
    <main className="fundTransfer">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={makeTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <section className="section--1">
        <div className="heading">
          <p>Fund Transfer</p>
        </div>
        <div className="main flex--3">
          <>
            <section className="sub-section">
              <div className="flex--1 container-main">
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>From Account Number</label>
                      <input
                        type="number"
                        className={`${
                          errors.debitAccount ? "error-input-z" : null
                        }`}
                        name="debitAccount"
                        value={glTransaction.debitAccount}
                        onChange={handleChange}
                      />
                      {errors.debitAccount && (
                        <ErrorMessage message={errors.debitAccount} />
                      )}
                    </div>
                    <div className="sub-input">
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={debitAccDetails.accountName}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="flex--3">
                    <label>Product</label>
                    <input
                      type="text"
                      placeholder=""
                      disabled
                      value={debitAccDetails.accountProductCode}
                    />
                  </div>
                  <div className="flex--3">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      placeholder=""
                      disabled
                      value={debitAccDetails.phoneNumber}
                      onChange={() => {}}
                    />
                  </div>
                  
          
                  {/* <div className="flex--3">
                    <label>Email Address</label>
                    <input
                     type="text"
                     placeholder=""
                     disabled
                     value={debitAccDetails.email}
                     onChange={() => {}} 
                    />
                  </div> */}
                </div>
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Branch</label>
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={debitAccDetails.branchCode}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="sub-input">
                      <label>Currency</label>
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={debitAccDetails.accountCurrency}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Customer BVN </label>
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={debitAccDetails.bvn}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="sub-input">
                      <label>Amount</label>
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        thousandSeparator={true}
                        inputMode="numeric"
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        // renderText={value => <div>{glTransaction.postAmount}</div>}
                        // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                        className={`${
                          errors.postAmount ? "error-input-z" : null
                        }`}
                        name="postAmount"
                        value={glTransaction.postAmount}
                        onValueChange={({floatValue})=>handleChange({
                          target: {
                            name:'postAmount', 
                            value: floatValue
                          }
                        })}
                      />
                      {/* <input
                        type="number"
                        className={`${
                          errors.postAmount ? "error-input-z" : null
                        }`}
                        name="postAmount"
                        value={glTransaction.postAmount.toFixed(2)}
                        onChange={handleChange}
                      /> */}
                      {errors.postAmount && (
                        <ErrorMessage message={errors.postAmount} />
                      )}
                    </div>

                    
                  </div>
                  <div className="input-container-new">
                    {glTransaction && glTransaction.debitAccount && <div className="flex--3">
                    <label>Account Balance</label>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={false}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled
                      value={debitAccDetails.availableBalance}
                      onChange={() => {}}
                    />
                    </div>}
                    {glTransaction && glTransaction.debitAccount && <div className="fundTransferButton">
                      <button onClick = {() => {
                        const singleItem = debitAccDetails;
                        if (singleItem && singleItem){
                          fetchDebitAccountDetails(glTransaction.debitAccount);
                          dispatch(openViewModal());

                        }
                      }} className="fundTransferDetails">View Account Instructions</button>
                    </div>}

                  </div>
                </div>
              </div>
              <div className="flex--1 container-main">
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>To Account Number</label>
                      <input
                        type="number"
                        className={`${
                          errors.creditAccount ? "error-input-z" : null
                        }`}
                        name="creditAccount"
                        value={glTransaction.creditAccount}
                        onChange={handleChange}
                      />
                      {errors.creditAccount && (
                        <ErrorMessage message={errors.creditAccount} />
                      )}
                    </div>
                    <div className="sub-input">
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={creditAccDetails.accountName}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="flex--3">
                    <label>Product</label>
                    <input
                      type="text"
                      placeholder=""
                      disabled
                      value={creditAccDetails.accountProductCode}
                      onChange={() => {}}
                    />
                  </div>
                  <div className="flex--3">
                    <label>Net Amount</label>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      value={creditAccDetails.availableBalance}
                      onChange={() => {}}
                    />
                    {/* <input
                    type="number"
                    placeholder=""
                    disabled
                    value={creditAccDetails.availableBalance}
                    onChange={() => {}}
                     /> */}
                  </div>

                  {/* <div className="flex--3">
                    <label>Customer BVN 11</label>
                    <input
                    type="text"
                    placeholder=""
                    disabled
                    value={creditAccDetails.bvn}
                    onChange={() => {}}
                     />
                  </div> */}
                  {/* <div className="flex--3">
                    <label>Charges</label>
                    <input />
                  </div> */}
                </div>
                <div className="input-container flex--3">
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Branch</label>
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={creditAccDetails.branchCode}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="sub-input">
                      <label>Currency</label>
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={creditAccDetails.accountCurrency}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="input-container--1 flex--1">
                    <div className="flex--3">
                      <label>Customer BVN </label>
                      <input
                        type="text"
                        placeholder=""
                        disabled
                        value={creditAccDetails.bvn}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="sub-input">
                      <label>Amount</label>
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        thousandSeparator={true}
                        inputMode="numeric"
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                        className={`${
                          errors.postAmount ? "error-input-z" : null
                        }`}
                        name="postAmount"
                        value={glTransaction.postAmount}
                        onValueChange={({floatValue})=>handleChange({
                          target: {
                            name:'postAmount', 
                            value: floatValue
                          }
                        })}
                      />
                      {/* <input
                        type="number"
                        className={`${
                          errors.postAmount ? "error-input-z" : null
                        }`}
                        name="postAmount"
                        value={glTransaction.postAmount.toFixed(2)}
                        onChange={handleChange}
                      /> */}
                      {errors.postAmount && (
                        <ErrorMessage message={errors.postAmount} />
                      )}
                    </div>
                  </div>

                  <div className="flex--3 input--7">
                    <label>Narration </label>
                    <input type="text" />
                  </div>
                  {/* <div className="flex--3">
                    <label>Amount</label>
                    <input
                        type="number"
                        className={`${
                          errors.postAmount ? "error-input-z" : null
                        }`}
                        name="postAmount"
                        value={glTransaction.postAmount}
                        onChange={handleChange}
                      />
                      {errors.postAmount && (
                        <ErrorMessage message={errors.postAmount} />
                      )}
                  </div> */}
                </div>
              </div>
              {/* Radiobuttons comes in */}
              {/* <div className="flex--3 input--6">
                <label>Email </label>
                <input type="text" />
              </div> */}
            </section>
            {details && <Details details={details} glTransaction={glTransaction}/>}

            <div className="flex--5">
              <button onClick={handleSubmit}>Save</button>
            </div>

            {/* <hr />
            <h4>Funds Transfer</h4> */}
            {/* <div className="sub--section flex--2">
              <div className="main-filter flex--2">
                <input
                  className="filter"
                  placeholder="Filter"
                  //   onChange={handleSearch}
                />
                
                <button className="button--second">Remove Filter</button>
              </div>
            </div> */}
          </>
        </div>
      </section>
    </main>
  );
};

export default FundTransfer;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../configUrl";
// import { }

const useCustom = () => {
  const [loader, setLoader] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [permission, setPermission] = useState([]);
  const [allRoles, setAllROles] = useState([]);
  const [roleByID, setRoleByID] = useState({});
  const [singlePermission, setSinglePermission] = useState([]);
  const [roleExist, setRoleExist] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false);
  const [openBoxUpdate, setOpenBoxUpdate] = useState(false);
  const [updateId, setUpdateID] = useState("");
  const [openView, setOpenView] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let Token = user && user.jwtToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
  }, [user]);

  useEffect(() => {
    getAllRoles();
  }, []);

  const [values, setValues] = useState({
    emailOrUserName: "",
    password: "",
  });

  const [roleValues, setRoleValues] = useState({
    name: "",
    description: "",
    rolePermissions: [],
  });

  const [editUserData, setEditUserData] = useState({
    name: "",
    description: "",
    rolePermissions: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((values) => ({ ...values, [name]: value }));
    setRoleValues((roleValues) => ({ ...roleValues, [name]: value }));
  };

  const clearRoleValues = () => {
    setRoleValues({
      name: "",
      description: "",
      rolePermissions: [],
    });
  };

  const getAllRoles = async () => {
    try {
      const response = await axios.get(`${baseUrl}user/Role/GetAll`);
      if (response.data.statusCode === 200) {
        setAllROles(response.data.data);
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const getPermissionWithRoleName = async (roleName) => {
    try {
      const response = await axios.get(
        `${baseUrl}user/Role/GetAllPermissions/${roleName}`
      );
      if (response.data.statusCode === 200) {
        const permissions = response.data.data.permissions;
        const permissionIds = permissions.map((item) => item.id);
        console.log("permission. from the owner......", permissionIds);
        return permissions;
      }
    } catch (err) {
      return [];
    } finally {
      setLoader(false);
    }
  };

  const getAllRolesById = async (e) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let Authorize = user && user.jwtToken;
    let headers = {
      Authorization: `Bearer ${Authorize}`,
      "Content-Type": "application/json",
    };

    const requestOptions = {
      headers,
    };
    try {
      const response = await fetch(
        `${baseUrl}/user/Role/GetAllPermissions/${e}`,
        requestOptions
      );
      const maindata = await response.json();
      if (maindata.statusCode === 200) {
        setRoleByID(maindata.data);
        setSinglePermission(maindata.data.permissions);
      }
      if (maindata.statusCode !== 200) {
        // Would be fine tuned for error sake later on
        setErrorLogin(true);
      }
    } catch (err) {
    } finally {
    }
  };

  const [authorize, setAuthorize] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const authorizeACreatedUser = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}user/Role/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: true,
        });
        let a = allRoles.map((items) => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllROles(a);
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleCreateRoleWithPermission = async (e, resetPermission, refreshTable) => {
    let data = {
      ...roleValues,
    };
    setLoader(true);

    try {
      const response = await axios.post(
        `${baseUrl}user/Role/CreateRoleWithPermission`,
        data
      );
      console.log(response, "responseresponse");
      if (response.data.statusCode === 200) {
        localStorage.removeItem("permission");
        resetPermission([]);
        clearRoleValues();
        refreshTable();
        return true;
      }
    } catch (err) {
      resetPermission([]);
      console.log(err.response.data.responseMessage, "err.da");
      if (err.response.data.responseMessage === "Role name already exist.") {
        setRoleExist(true);
        setErrorMsg("Unsuccessful. Role name already exists.");
        return false;
      }
    } finally {
      setLoader(false);
    }
  };

  const [correctWayOfAddingList, setCorrectWayOfAddingList] = useState([]);

  const getAllPermissions = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}user/Role/GetAllPermissions?pageNumber=1&pageSize=99999`
      );
      console.log(response, "InvestmentBookingInvestmentBooking");
      if (response.data.status) {
        let lists = response.data.data;
        for (let i = 0; i < lists.length; i++) {
          lists[i].sn = i + 1;
          lists[i].isSelected = false;
          lists[i].isInDatabase = false;
        }
        setCorrectWayOfAddingList(lists);
        setPermission(response.data.data);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getCountryStateByID = async () => {
    try {
      const response = await fetch(
        `http://3.23.168.105:83/State/GetAllCountryStates/${12}`
      );
      console.log(response);
      // if(maindata.statusCode === 200){
      //     setPermission(maindata.data)
      // }
      // if(maindata.statusCode !== 200){
      //     setErrorLogin(true)
      // }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const getCountAllStaff = async () => {
    try {
      const response = await fetch(
        `http://3.23.168.105:83/UserAccess/CountAllStaffs`
      );
      console.log(response);

      // if(maindata.statusCode === 200){
      //     setPermission(maindata.data)
      // }
      // if(maindata.statusCode !== 200){
      //     setErrorLogin(true)
      // }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const getCountAllCustomers = async () => {
    try {
      const response = await fetch(
        `http://3.23.168.105:83/UserAccess/CountAllCustomers`
      );
      console.log(response);
      // if(maindata.statusCode === 200){
      //     setPermission(maindata.data)
      // }
      // if(maindata.statusCode !== 200){
      //     setErrorLogin(true)
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const updateRoles = async () => {
    setLoader(true);
    let data = { ...editUserData };
    try {
      //   const response = await axios.put(
      //     `${baseUrl}user/Role/UpdateRoleWithPermission/${updateId}`,
      //     data
      //   );
      const response = await axios.post(
        `${baseUrl}user/Role/UpdateRoleWithPermission`,
        data
      );
      if (response.data.statusCode === 200) {
        getAllRoles();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setErrMessage(error.response.data.responseMessage);
      return false;
    } finally {
      setLoader(false);
    }
  };

  return {
    updateRoles,
    errorMsg,
    updateId,
    setUpdateID,
    setRoleValues,
    handleChange,
    getAllPermissions,
    roleExist,
    getCountAllCustomers,
    getCountAllStaff,
    getCountryStateByID,
    allRoles,
    roleByID,
    singlePermission,
    getAllRolesById,
    handleCreateRoleWithPermission,
    roleValues,
    permission,
    setPermission,
    loader,
    setLoader,
    errorLogin,
    values,
    getAllRoles,
    setAllROles,
    openView,
    setOpenView,
    authorizeACreatedUser,
    openBoxAuthorize,
    setOpenBoxAuthorize,
    authorize,
    setAuthorize,
    editUserData,
    setEditUserData,
    errMessage,
    getPermissionWithRoleName,
    correctWayOfAddingList,
    setCorrectWayOfAddingList,
  };
};

export default useCustom;

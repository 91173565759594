import { makeStyles } from "@material-ui/core";
import Item from "../../DatagridTable/item";
import { Grid, Divider } from "@material-ui/core";
import { formattedDate } from "../../../formatter/date";

const useStyles = makeStyles((theme) => ({
  charges: {
    backgroundColor: "#D3BCC0",
    color: "#fff",
    width: "100%",
    padding: ".5rem",
    borderRadius: "5px",
    textTransform: "capitalize",
  },
  chargesCont: {
    marginBottom: 70,
    border: "1px solid #D3BCC0",
  },
  title: {
    marginTop: 50,
  },
}));

const ProductDefinition = ({ details }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Product Name" name={details.productName} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Portfolio Code" name={details.portfolioCode} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Portfolio Type" name={details.portfolioType} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Products Buy-leg" name={details.productBuyLeg} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Products Sell-leg" name={details.productSellLeg} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Default Interest Rate"
          name={details.defaultInterestRate}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Default Interest Rate"
          name={details.defaultInterestRate}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Maximum Interest Rate"
          name={details.maximumInterestRate}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Minimum Interest Rate"
          name={details.minimumInterestRate}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Rate Basis" name={details.rateBasis} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Tenor Basis" name={details.tenorBasis} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Default Tenor" name={details.defaultTenor} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Maximum Tenor" name={details.maximumTenor} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Minimum Tenor" name={details.minimumTenor} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Issuer Name" name={details.issuerName} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Issue Date"
          name={formattedDate(new Date(details.issueDate), "dd/mm/yyyy")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Issue Price" name={details.issuerPrice} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Start of Trading Date"
          name={formattedDate(
            new Date(details.startOfTradingDate),
            "dd/mm/yyyy"
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Profit/Loss Account" name={details.profitOrLossAccount} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Product Credit Account"
          name={details.productCreditAccount}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Products Debit Account"
          name={details.productDebitAccount}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item label="Interest Account" name={details.interestAccount} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Interest Income Account"
          name={details.interestIncomeAccount}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Interest Expense Account"
          name={details.interestExpenseAccount}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Interest Payable Account"
          name={details.interestPayableAccount}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Interest Receivable Account"
          name={details.interestReceivableAccount}
        />
      </Grid>
      {details.reason && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Reason" name={details.reason} />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Item
          label="Status"
          name={
            details.authorizationStatus === "PendingEditedAuthorization"
              ? "Pending Edited Authorization"
              : details.authorizationStatus
          }
        />
      </Grid>

      <h4 className={classes.title}>Other Fees and Income</h4>
      <Grid item xs={12}>
        {details.otherFeesAndIncome.map((item, i) => (
          <Grid container spacing={2} className={classes.chargesCont}>
            <Grid item xs={12} sm={6} md={4}>
              <label>Charge Name</label>
              <p className={classes.charges}>{item.chargeName}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Apply Charge At</label>
              <p className={classes.charges}>{item.applyChargeAccount}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Charge Ledger Acc</label>
              <p className={classes.charges}>{item.chargeLedgerAccount}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Type of Charge</label>
              <p className={classes.charges}>{item.chargeType}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Amount/Rate</label>
              <p className={classes.charges}>{item.amountRate}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Charge Currency</label>
              <p className={classes.charges}>{item.chargeCurrency}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Treasury Product</label>
              <p className={classes.charges}>{item.treasuryProductId}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Created Date</label>
              <p className={classes.charges}>
                {formattedDate(new Date(item.createdDateTime), "dd/mm/yyyy")}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Updated Date</label>
              <p className={classes.charges}>
                {formattedDate(new Date(item.updatedDateTime), "dd/mm/yyyy")}
              </p>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ProductDefinition;

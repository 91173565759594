import { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useCustom from '../../../../Api/Hooks-manager/accountOpening/useCustom'

import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { getCustomerByAccountNumber } from "../../../../Api/Hooks-manager/loanAccountOperations";

import DotMenu from "../../../../commons/dotMenu/dotMenu";
import Spinner from "../../../Backdrop/index";
import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "../AccountEnquiry/edit";
import Details from "../AccountEnquiry/view";

const AccountEnquiry = () => {
  const dispatch = useDispatch();
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const loading = useSelector(state => state.req.loading);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});

  const{ addSignatory, removeSignatory} = useCustom();
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Acc No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "productName",
      label: "Account Product",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountstatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Active"
                  ? "green"
                  : status === "Inactive"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                if (singleItem && singleItem.customerId) {
                  fetchAccountDetails(
                    singleItem.accountNumber,
                    openUpdateFormModal
                  );
                }
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                console.log("It is clicking...", singleItem)
                if (singleItem && singleItem.customerId) {
                  fetchAccountDetails(singleItem.accountNumber, openViewModal);
                }
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAccountDetails = async (accNo, action) => {
    dispatch(isLoading());
    const response = await getCustomerByAccountNumber(token, accNo);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.status) {
        setDetails(response.data);
        dispatch(action());
      }
    }
  };

  useEffect(() => {
    const fetchAllAccountEnquiry = async () => {
      dispatch(isLoading());
      const url = `${baseUrl}account/CustomerAccount/GetAll`;
      const response = await getHandler(token, url);
      if (response && response.data) {
        dispatch(isLoading());
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].sn = i + 1;
          }
        }
        setData(response.data);
      }
    };
    fetchAllAccountEnquiry();
  }, [dispatch, token, updateTable]);
  
  return (
    <div>
      <Spinner loading={loading} />
      {!loading && (
        <Table
          title="Account Enquiry"
          subtitle=""
          name="List of Accounts"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      {details && details.customerId && <Details details={details} />}
      {details && details.customerId && <EditForm details={details} removeSignatory={removeSignatory}  addSignatory={addSignatory}/>}
    </div>
  );
};

export default AccountEnquiry;
import { Grid } from "@material-ui/core";
import Item from "../../DatagridTable/item";


const UserTellerTill = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="User Till Limit" name={details.userTillLimit} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="User CR Cash Limit" name={details.userCrCashLimit} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="User DR Cash Limit" name={details.userDrCashLimit} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Branch Code" name={details.branchId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Till Id" name={details.tillId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="User Id" name={details.userId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>
    );
};

export default UserTellerTill

import { useState, useEffect, useCallback } from "react";
import "./RateCharts.scss";
import MenuItem from "@material-ui/core/MenuItem";

import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import {
  createRateChart,
  getAllRateChart,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { DateConverter } from "../../../ConvertDate";
import ErrorMessage from "../../../ErrorMessage";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import useValidation from "./useValidation";
import {
  openUpdateFormModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const RateChart = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const {
    err,
    errMsg,
    setErr,
    setErrMsg,
    type,
    setType,
    validateRateChartSetUp,
  } = useValidation();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [rate, setRate] = useState({
    code: "",
    description: "",
    effectiveDate: "",
    basis: "",
    tenorBasis: "Days",
    rateChartRanges: [],
  });

  const [rateChartRanges, setRateChartRanges] = useState([
    {
      tenorFrom: 0,
      tenorTo: 0,
      amountFrom: 0,
      amountTo: 0,
      rate: 0,
      rateBasis: "",
      effectiveDate: DateConverter(new Date()),
    },
  ]);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "code",
      label: "Rate Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Rate Code Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "basis",
      label: "Rate Chart Basis",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tenorBasis",
      label: "Tenor Basis",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}{" "}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllRatesCharts = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllRateChart(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllRatesCharts();
  }, [fetchAllRatesCharts, updateTable]);

  const add = () => {
    const list = [...rateChartRanges];
    list.push({
      tenorFrom: 0,
      tenorTo: 0,
      amountFrom: 0,
      amountTo: 0,
      rate: 0,
      rateBasis: "",
      effectiveDate: DateConverter(new Date()),
    });
    setRateChartRanges(list);
    setRate({
      ...rate,
      rateChartRanges: list,
    });
  };

  const remove = index => {
    const list = [...rateChartRanges];
    list.splice(index, 1);
    setRateChartRanges(list);
    setRate({
      ...rate,
      rateChartRanges: list,
    });
  };

  const handleChange = name => e => {
    const { value } = e.target;
    setRate({
      ...rate,
      [name]: value,
    });
  };
  const handleRateChartRangesChange = (e, index) => {
    const { name, value } = e.target;
    const tempItems = [...rateChartRanges];
    tempItems[index][name] =
      name === "tenorFrom" ||
      name === "tenorTo" ||
      name === "amountFrom" ||
      name === "amountTo" ||
      name === "rate"
        ? parseInt(value) < 0
          ? 0
          : parseInt(value)
        : value;
    if (rate.basis === "Amount") {
      tempItems[index]["tenorFrom"] = 0;
      tempItems[index]["tenorTo"] = 0;
    }
    if (rate.basis === "Tenor") {
      tempItems[index]["amountFrom"] = 0;
      tempItems[index]["amountTo "] = 0;
    }
    setRateChartRanges(tempItems);
    setRate({
      ...rate,
      rateChartRanges: tempItems,
    });
    console.log(rateChartRanges);
  };

  const EmptyFieldsRateCharts = () => {
    setRate({
      code: "",
      description: "",
      effectiveDate: "",
      basis: "",
      tenorBasis: "Days",
      rateChartRanges: [],
    });
    setRateChartRanges([
      {
        tenorFrom: 0,
        tenorTo: 0,
        amountFrom: 0,
        amountTo: 0,
        rate: 0,
        rateBasis: "",
        effectiveDate: "",
      },
    ]);
  };

  const postResult = async () => {
    dispatch(isLoading());
    const response = await createRateChart(token, rate);
    if (response && response.message) {
      dispatch(isLoading());
      setMessage(response.message);
    }
    if (response.status) {
      EmptyFieldsRateCharts();
      fetchAllRatesCharts();
      return true;
    } else {
      return false;
    }
  };

  const handleSunbmit = () => {
    if (validateRateChartSetUp(rate, rateChartRanges)) {
      setErr("");
      setErrMsg("");
      setType("");
      setOpen(true);
      // postResult();
    }
  };

  return (
    <div>
      <main className="rate-Charts border--main">
        <Spinner loading={loading} />
        <Confirm
          ApiCall={postResult}
          openBox={open}
          setOpenBoxState={setOpen}
          errorMessage={message}
        />
        <div className="heading">
          <p>Rate Chart Setup</p>
        </div>
        <section className="main-section--1">
          <div className="main-container flex--1">
            <div className="main-container--1">
              <p>Rate Code</p>
              <input
                className={err === "code" ? "error--input" : null}
                value={rate.code}
                onChange={handleChange("code")}
              />{" "}
              {err === "code" && <ErrorMessage message={errMsg} />}{" "}
            </div>
            <div className="main-container--2">
              <p>Rate Code Description</p>
              <input
                className={err === "description" ? "error--input" : null}
                value={rate.description}
                onChange={handleChange("description")}
              />{" "}
              {err === "description" && <ErrorMessage message={errMsg} />}{" "}
            </div>
          </div>
          <div className="sub-container flex--1">
            <div className="sub-container--2">
              <p>Rate Chart Basis</p>
              <select
                className={err === "basis" ? "error--input" : null}
                value={rate.basis}
                onChange={handleChange("basis")}
              >
                <option value="">Select Rate basis</option>
                <option value="Tenor">Tenor</option>
                <option value="Amount">Amount</option>
              </select>
              {err === "basis" && <ErrorMessage message={errMsg} />}{" "}
            </div>
            {rate.basis === "Tenor" && (
              <div className="sub-container--3">
                <p>Tenor Basis</p>
                <select
                  className={err === "tenorBasis" ? "error--input" : null}
                  value={rate.tenorBasis}
                  onChange={handleChange("tenorBasis")}
                >
                  <option value="Select tenor basis">Select tenor basis</option>
                  <option value="Days">Days</option>
                  <option value="Month">Month</option>
                </select>
                {err === "tenorBasis" && <ErrorMessage message={errMsg} />}{" "}
              </div>
            )}{" "}
          </div>
        </section>
        {rate.basis === "Tenor" && (
          <div className="section-container">
            <h1 className="sub-heading">Rate Chart</h1>
            {rateChartRanges.map((item, i) => (
              <section className="main-section--2 flex--1" key={i}>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Tenor From</p>
                    <input
                      className={
                        err === i && type === "tenorFrom"
                          ? "error--input"
                          : null
                      }
                      type="number"
                      name="tenorFrom"
                      value={item.tenorFrom}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "tenorFrom" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <span>&#8722;</span>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Tenor To</p>
                    <input
                      className={
                        err === i && type === "tenorTo" ? "error--input" : null
                      }
                      type="number"
                      name="tenorTo"
                      value={item.tenorTo}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "tenorTo" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Effective Date</p>
                    <input
                      className={
                        err === i && type === "effectiveDate"
                          ? "error--input"
                          : null
                      }
                      type="date"
                      name="effectiveDate"
                      value={item.effectiveDate}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "effectiveDate" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Rate</p>
                    <input
                      className={
                        err === i && type === "rate" ? "error--input" : null
                      }
                      type="number"
                      name="rate"
                      value={item.rate}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "rate" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Rate Basis</p>
                    <select
                      className={
                        err === i && type === "rateBasis"
                          ? "error--input"
                          : null
                      }
                      value={item.rateBasis}
                      name="rateBasis"
                      onChange={e => handleRateChartRangesChange(e, i)}
                    >
                      <option value="Select Rate basis">
                        Select Rate basis
                      </option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Annum">Per Annum</option>
                    </select>
                    {err === i && type === "rateBasis" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <button
                  disabled={rateChartRanges.length < 2}
                  className="button--1"
                  onClick={() => remove(i)}
                >
                  Delete
                </button>
              </section>
            ))}
            <div className="button-p flex--1">
              <div className="flex--3">
                <button className="button" onClick={add}>
                  +
                </button>
                <p>Add More</p>
              </div>
              <button className="submit" onClick={handleSunbmit}>
                Submit
              </button>
            </div>
          </div>
        )}
        {rate.basis === "Amount" && (
          <div className="section-container">
            <h1 className="sub-heading">Rate Chart</h1>
            {rateChartRanges.map((item, i) => (
              <section className="main-section--2 flex--1" key={i}>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Amount From</p>
                    <input
                      className={
                        err === i && type === "amountFrom"
                          ? "error--input"
                          : null
                      }
                      type="number"
                      name="amountFrom"
                      value={item.amountFrom}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "amountFrom" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <span>&#8722;</span>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Amount To</p>
                    <input
                      className={
                        err === i && type === "amountTo" ? "error--input" : null
                      }
                      type="number"
                      name="amountTo"
                      value={item.amountTo}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "amountTo" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Effective Date</p>
                    <input
                      className={
                        err === i && type === "effectiveDate"
                          ? "error--input"
                          : null
                      }
                      type="date"
                      name="effectiveDate"
                      value={item.effectiveDate}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "effectiveDate" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Rate</p>
                    <input
                      className={
                        err === i && type === "rate" ? "error--input" : null
                      }
                      type="number"
                      name="rate"
                      value={item.rate}
                      onChange={e => handleRateChartRangesChange(e, i)}
                    />{" "}
                    {err === i && type === "rate" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <section className="section flex--2">
                  <div className="input-container">
                    <p>Rate Basis</p>
                    <select
                      className={
                        err === i && type === "rateBasis"
                          ? "error--input"
                          : null
                      }
                      value={item.rateBasis}
                      name="rateBasis"
                      onChange={e => handleRateChartRangesChange(e, i)}
                    >
                      <option value="Select Rate basis">
                        Select Rate basis
                      </option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Annum">Per Annum</option>
                    </select>
                    {err === i && type === "rateBasis" && (
                      <ErrorMessage message={errMsg} />
                    )}{" "}
                  </div>
                </section>
                <button
                  disabled={rateChartRanges.length < 2}
                  className="button--1"
                  onClick={() => remove(i)}
                >
                  Delete
                </button>
              </section>
            ))}
            <div className="button-p flex--1">
              <div className="flex--3">
                <button className="button" onClick={add}>
                  +
                </button>
                <p>Add More</p>
              </div>
              <button className="submit" onClick={handleSunbmit}>
                Submit
              </button>
            </div>
          </div>
        )}{" "}
      </main>
      {!loading && (
        <Table
          title="Rate Chart"
          subtitle=""
          name="List of rates"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />{" "}
      {details && details.id && (
        <EditForm details={details} callBack={fetchAllRatesCharts} />
      )}{" "}
    </div>
  );
};
export default RateChart;

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import DecisionModal from "../../Confirmbox/decision";
import ResponseModal from "../../Confirmbox/Modal";
import {
  closeResquestDecisionModal,
  closeResquestResponseModal,
  makeRequest,
} from '../../Api/redux-manager/actions'

const useStyles = makeStyles((theme) => ({
  children:{
    padding:"1.5rem 0px"
  }
}));

const Modal = ({
  question,
  url,
  data,
  method, // default option is put,
  beforeAction // runs an action before request
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const open = useSelector(state => state.req.open);
  const openresponseModal = useSelector(state => state.req.openResponseModal);
  const status = useSelector(state => state.req.status);
  const message = useSelector(state => state.req.message);
  
  const handleClick = async () => {
    if(beforeAction){
      await beforeAction()
    }
    dispatch(makeRequest(token,data, url, method))
  }

  return (
    <div>
      <DecisionModal
        question={question}
        openDialog={open}
        onClick={handleClick}
        onClose={() => dispatch(closeResquestDecisionModal())}
      />
      <ResponseModal
        openDialog={openresponseModal}
        onClose={() => dispatch(closeResquestResponseModal())}
        positiveDialog={status}
      >
        <div className={classes.children}>
        {message}
        </div>
      </ResponseModal>
    </div>
  );
};

export default Modal;

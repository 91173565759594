import { React, useState, useEffect, useCallback } from "react";
import "./LienAmountBlock.scss";
import Confirm from "../../../../Confirmbox/Confirm";
import BusinessLogic from "./businessLogic";
import NumberFormat from "react-number-format";
import Table from "../../../DatagridTable/table";
import Details from "./viewLien";
import EditForm from "./editLien";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";

const LienAmountBlock = () => {
  const {
    details,
    editDetails,
    errors,
    error,
    editErrors,
    loading,
    options,
    columns,
    open,
    setOpen,
    takeAction,
    message,
    handleChange,
    openModal,
    SendLienDetails,
    setOpenModal,
    account,
    forms,
    lienTable,
    handleSubmit,
    handleEditDetailsChange,
    handleDetailsUpdateSubmit,
    postEditDetails,
    openEditDetails,
    showEditDetails,
    setShowEditDetails,
    RemovingLienModal,
    actionAccount,
    setActionAccount,
    openRemoveLienModal,
    lienType,
    updateDescription,
  } = BusinessLogic();

  return (
    <div className="lien--amount">
      <Spinner loading={loading} />

      <Confirm
        ApiCall={SendLienDetails}
        openBox={open}
        actionMsg={message}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <div className="headerdiv">
        <p>Lien/Amount Block</p>
      </div>
      <div style={{ paddingTop: 20, padding: 25 }}>
        <div className="product--def flex--1">
          <div className="flex--3 input--3">
            <label>Account Number</label>
            <input
              type="text"
              className={`pink ${errors.accntNo ? "error-input-z" : null}`}
              name="accountNumber"
              value={forms.accountNumber}
              onChange={handleChange("accountNumber")}
            />
            {errors.accountNumber && (
              <ErrorMessage message={errors.accountNumber} />
            )}
          </div>
          <div className="flex--3 input--3">
            <label>Account Name</label>
            <input
              disabled
              type="text"
              name="accountName"
              value={forms.accountName}
              onChange={handleChange("accountName")}
            />
          </div>
          <div className="flex--3 input--3">
            <label>Lien Status</label>
            <input
              disabled
              type="text"
              name="status"
              value={forms.status}
              // onChange={handleChange("accntStatus")}
            />
          </div>
        </div>
        <div className="product--def flex--1">
          <div className="flex--3 input--3">
            <label>Branch Code</label>
            <input
              type="text"
              name="branchCode"
              value={forms.branchCode}
              onChange={handleChange("branchCode")}
            />
            {errors.branchCode && <ErrorMessage message={errors.branchCode} />}
          </div>
          <div className="flex--3 input--3">
            <label>Lien Amount</label>
            <input
              type="text"
              name="amount"
              value={forms.amount}
              onChange={handleChange("amount")}
            />
            {/* <NumberFormat
              thousandsGroupStyle="thousand"
              thousandSeparator={true}
              inputMode="numeric"
              name="amount"
              value={forms.amount}
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              // renderText={(value) => <div>{glTransaction.postAmount}</div>}
              className={`${errors.amount ? "error-input-z" : null}`}
              onValueChange={({ floatValue }) =>
                handleChange({
                  target: {
                    name: "amount",
                    value: floatValue,
                  },
                })
              }
            /> */}
            {errors.amount && <ErrorMessage message={errors.amount} />}
          </div>
          <div className="flex--3 input--3">
            <label>Start Date</label>
            <input
              type="date"
              name="startDate"
              value={forms.startDate}
              onChange={handleChange("startDate")}
            />
            {/* {errors.startDate && <ErrorMessage message={errors.startDate} />} */}
          </div>

          <div className="flex--3 input--3">
            <label>End Date</label>
            <input
              type="date"
              name="endDate"
              value={forms.endDate}
              onChange={handleChange("endDate")}
            />{" "}
            {/* {errors.endDate && <ErrorMessage message={errors.endDate} />} */}
          </div>

          <div className="flex--3 input--3 endDateHide">
            <label>Lien Code</label>
            <select
              value={forms.lienCode}
              onChange={handleChange("lienCode")}
              className={`${
                Object.keys(errors).includes("lienCode") && "error-input-z"
              }`}
            >
              <option value="">Select an option</option>
              {lienType && lienType.length > 0
                ? lienType.map((index, i) => {
                    return (
                      <option
                        key={i}
                        value={index.parameterValue}
                      >
                        {index.parameterValue}
                      </option>
                    );
                  })
                : null}
            </select>
            {/* <input
              type="text"
              name="lienCode"
              value={forms.lienCode}
              onChange={handleChange("lienCode")}
            /> */}
            {/* {errors.lienCode && <ErrorMessage message={errors.lienCode} />} */}
          </div>
        </div>
        <div className="product--def flex--1">
          
        </div>

        <div className="btn-save flex--1">
          <button className="save" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <hr />
      {!loading && (
        <Table
          title="Lien Details"
          subtitle=""
          name="List of Lien "
          records={lienTable}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}

      {editDetails && editDetails.id && (
        <EditForm
          details={editDetails}
          handleDetailsUpdateSubmit={handleDetailsUpdateSubmit}
          errorEdit={editErrors}
          handleFieldUpdate={handleEditDetailsChange}
        />
      )}

      {actionAccount && (
        <RemovingLienModal
          actionAccountObject={actionAccount}
          actionOnClose={() => {
            setActionAccount({});
          }}
          onSuccess={() => {}}
          open={openRemoveLienModal}
        />
      )}
    </div>
  );
};

export default LienAmountBlock;

import { useState, useEffect, useCallback } from "react";
import "./Dash.scss";
import PieChart from "./PieChart";
import BarChart from "./Barchart";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  openViewModal,
  loading,
} from "../../../Api/redux-manager/actions";
import { getAllCurrencyDefinition } from "../../../Api/Hooks-manager/coreModules/coreModules";
import {getAUserCashBalance} from "../../../Api/Hooks-manager/securitymanagement/tellerTills";
import {getAUserTillDetails} from "../../../Api/Hooks-manager/securitymanagement/tellerTills";
import { GiBeveledStar } from "react-icons/gi";
import Details from "./View";
import { formattedDate } from "../../../formatter/date";
import { getDailyTransactionSummary } from '../../../Api/Hooks-manager/dash/dash';
import MiniLoader from './MiniLoader';
import NoTransaction from './NoTransaction';

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 46,
    textTransform: "none",
    padding: "0 0.5rem",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
}));

const Dash = () => {

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const username = useSelector((state) => state.common.userDetails.userName);
  const userDetails = useSelector((state) => state.common.userDetails);
  const [errors, setErrors] = useState("");
  const {id: userId, jwtToken: token, operatingBranch: branchCode} = userDetails
  const [transactionData, setTransactionData] = useState([])
  const [loadingTrx, setLoadingTrx] = useState(true)
    useEffect(() => {
        (async () => {
            const res = await getDailyTransactionSummary(token, {userId, branchCode})
            setTransactionData(res.data)
            setLoadingTrx(false)
        })()
    }, [])
  const classes = useStyles();
  const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
  const [denom, setDenom] = useState({
    denominations: {},
    balance: null,
    currencyCode: "",
    dateAuthorized: "",
  });
  
  const transactionCount = {}
    transactionData.forEach((trx) => {
        transactionCount[trx.transactionType] = transactionCount[trx.transactionType] + 1 ||1
    })

    const transactionMap = transactionData.slice(0,5).map((trx) => {
        return(
            <tr>
                {/* <th scope="row">1</th> */}
                <td>{trx.referenceNumber}</td>
                <td>{trx.transactionType}</td>
                <td>{formattedDate(trx.postDate)}</td>
                <td>{trx.currencyCode}</td>
                <td>{trx.entryBranch}</td>
                <td>{trx.postAmount}</td>
                <td>{trx.transactionStatus}</td>
            </tr>
        )
    })

  const fetchAllCurrencyDefinition = useCallback(async () => {
    const response = await getAllCurrencyDefinition(token);
    if (response && response.data) {
      setAllCurrencyDefinition(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  }, [token]);

  const checkValues = () => {
    const {balance, denominations} = denom;
    const errors = {};

    if (balance === ""){
      errors.balance = "Balance is required";
    }
    if(denominations === ""){
      errors.denominations = "Denomination is empty"
    }

  }



const fetchUserTillBalanceWithDenom = useCallback(async () => {
  dispatch(loading());
  const allResponse = await getAUserTillDetails(token, username);
  // console.log(allResponse.data[0].accountGL.balance, "new user tills")
  dispatch(loading());
  if(allResponse && allResponse.data && allResponse.data.length > 0){
    const{denoM1000, denoM500, denoM200, denoM100,
      denoM50, denoM20, denoM10, denoM5, denoM1} = allResponse.data[0]
   const denominations = {
     1000: denoM1000,
     500: denoM500,
     200: denoM200,
     100: denoM100,
     50: denoM50,
     20: denoM20,
     10: denoM10,
     5: denoM5,
     1: denoM1,
   }

    let balance = 0
    if(allResponse.data[0].accountGL){
     balance = allResponse.data[0].accountGL.balance
    }
    setDenom({denominations, balance});
  }

},[token, dispatch, username])

// console.log(denom.balance, "balance")

  // const fetchCurrentUserCashBalance = useCallback(async () => {
  //   dispatch(loading());
  //   const newResponse = await getAUserCashBalance(token, username);
  //   console.log(newResponse, "cash balance details");
  //   dispatch(loading());
  //   if (newResponse && newResponse.data) {
  //     const{balance, denoM1000, denoM500, denoM200, denoM100,
  //        denoM50, denoM20, denoM10, denoM5, denoM1} = newResponse.data[0]
  //     const denominations = {
  //       1000: denoM1000,
  //       500: denoM500,
  //       200: denoM200,
  //       100: denoM100,
  //       50: denoM50,
  //       20: denoM20,
  //       10: denoM10,
  //       5: denoM5,
  //       1: denoM1,
  //     }
  //     setDenom({denominations, balance});
  //   }
  // }, [token, username, dispatch]);

  // const fetchAllAccountProducts = useCallback(async () => {
  //   dispatch(loading());
  //   const url = `${baseUrl}account/AccountProduct/GetAll`;
  //   const allResponse = await getHandler(token, url);
  //   dispatch(loading());
  //   const response = allResponse.data.filter(
  //     (item) =>
  //       item.authorizationStatus === "Pending" ||
  //       item.authorizationStatus === "PendingEditedAuthorization"
  //   );
  //   if (response && response.length) {
  //     for (let i = 0; i < response.length; i++) {
  //       response[i].sn = i + 1;
  //       response[i].createdDateTime = formattedDate(
  //         response[i].createdDateTime
  //       );
  //       response[i].dateAuthorized = formattedDate(response[i].dateAuthorized);
  //       response[i].updatedDateTime = formattedDate(
  //         response[i].updatedDateTime
  //       );
  //     }
  //     setData(response);
  //   }
  // }, [dispatch, token]);


  const handleChange = (e) => {
    const { name, value, checked } = e.target;
  };

  // useEffect(() => {
  //   fetchAllAccountProducts();
  // }, [fetchAllAccountProducts, updateTable]);

  useEffect(() => {
    fetchAllCurrencyDefinition();
  }, [fetchAllCurrencyDefinition]);

  useEffect(() => {
    // fetchCurrentUserCashBalance();
    fetchUserTillBalanceWithDenom();
  },[]);

  return (
    <main className="dash">
      <div className="heading">
        <p>Dashboard</p>
      </div>
       <main className='border--main'>
                <section className='section flex--1'>
                    <div className='line--chart border--main'>
                        <h6>Transaction Summary</h6>
                        <BarChart data={transactionCount} loading={loadingTrx}/>
                    </div>
                    <div className='line--chart border--main'>
                        <h6>Transaction Counts</h6> 
                        <PieChart  data={transactionCount} loading={loadingTrx}/>
                    </div>
        </section>
        <section className="section--2 flex--1">
          <div className="sub flex--3 border--main">
            <div className="sub-section--1">
              <h6>Batch Summary</h6>
              <select onChange={handleChange} disabled>
                  <option value="NGN">NGN</option>
                {allCurrencyDefinition.map((item) => (
                  <option value={item.currencyCode} key={item.id}>
                    {item.currencyName}
                  </option>
                ))}
              </select>
            </div>
            <div className="sub-section--2 flex--3">
              <GiBeveledStar className="icon" />
              <h6>NGN{denom.balance}</h6>
              <p
                className="openBatchDetails"
                onClick={() => {
                  const singleItem = data;
                  if (singleItem && singleItem) {
                    fetchUserTillBalanceWithDenom();
                    dispatch(openViewModal());
                  }
                }}
              >
                Batch Balance
              </p>
            </div>
          </div>

          <div className='table-main table-responsive border--main'>
                        {loadingTrx ? <MiniLoader/>
                        :!loadingTrx && transactionMap.length <1 ? <NoTransaction/>
                        :(<>
                        <div className=''>
                            <h6>Recent Transactions</h6>
                        </div>
                        <table className="table table-bordered">
                            <thead className='thead-light'>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Ref No</th>
                                    <th scope="col">TXN Type</th>
                                    <th scope="col">TXN Date</th>
                                    <th scope="col">Currency</th>
                                    <th scope="col">Branch</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">Authorized</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {transactionMap}
                            </tbody>
                        </table></>)
                        }
                    </div>
                </section>
            </main>
      {details && <Details details={details} />}
    </main>
  );
};

export default Dash;

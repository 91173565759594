import { Routes } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/Products/LoanProduct";

const View = ({ details }) => {
  console.log('details',details);
  return (
    <DetailsContainer
      title="Loan Products - Record Details"
      question="Are you sure you want to delete this products?"
      url={`${Routes.deleteLoanProducts}${details.id}`}
    >
      <Details details={details} />
    </DetailsContainer>
  );
};

export default View;

import { Grid } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
}) => {
  return (
    <EditModal
      title="Update Portfolio Maintainance"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}treasury/PortfolioMaintenance/update/${details.id}`}
    >
          <Grid container spacing={3} className="branchteller--tills">
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label>Portfolio Code</label>
              <input
                type="text"
                value={details.portfolioCode}
                onChange={handleEditChange("portfolioCode")}
                
              />
              {errorEdit.portfolioCode && <ErrorMessage message={errorEdit.portfolioCode} />}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label>Portfolio Type</label>
              <select
                    type="text"
                    name="portfolioType"
                   onChange={handleEditChange("portfolioType")}
                    value={details.portfolioType}
                    >
                    <option value="">Select an option</option>
                    <option value="Bank">Bank</option>
                    <option value="Customer">Customer</option>
                  </select>
              {errorEdit.portfolioType && (
                <ErrorMessage message={errorEdit.portfolioType} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Customer name</label>
              <input
                type="text"
                value={details.customerName}
                onChange={handleEditChange("customerName")}
            
              />
              {errorEdit.customerName && (
                <ErrorMessage message={errorEdit.customerName} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Customer Branch</label>
              <input
                type="text"
                value={details.customerBranch}
                onChange={handleEditChange("customerBranch")}
                
              />
              {errorEdit.customerBranch && (
                <ErrorMessage message={errorEdit.customerBranch} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Statement Frequency</label>
              <select
                    type="number"
                    name="statementFrequency"
                    onChange={handleEditChange("statementFrequency")}
                    
                    value={details.statementFrequency}
                  >
                    <option value=''>Select An Option</option>
                    <option value='Daily'>Daily</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Quartely'>Quarterly</option>
                    <option value='Half_Yearly'>Half_Yearly</option>
                    <option value='Yearly'>Yearly</option>
                  </select>
              {errorEdit.statementFrequency && (
                <ErrorMessage message={errorEdit.statementFrequency} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Currency</label>
              <select
                  
                  type="text"
                  onChange={handleEditChange("currency")}
                  name="currency"
                  value={details.currency}
                  >
                    <option></option>
                    <option>NGN</option>
                  </select>
              {errorEdit.currency && (
                <ErrorMessage message={errorEdit.currency} />
              )}
            </Grid>
           
          </Grid>
    </EditModal>
  );
};

export default EditData;

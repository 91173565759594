import { useState, useEffect,useCallback } from "react";
import useTable from "../../../Tables/useTable";
import { baseUrl } from  '../../../../Api/configUrl';
import axios from 'axios' 
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../../formatter/date";
import {
    isLoading,
  } from "../../../../Api/redux-manager/actions";

const BusinessLogic = () => {
  const [screenChange, setScreenChange] = useState(false);
  const [openBox, setOpenBox] = useState(false)
  const [loader, setLoader] = useState(false);
  const [allProductDefintion, setAllProductDefintion] = useState([]) 
  const [viewAll, setViewAll] = useState({})
  const [openView, setOpenView] = useState(false)
  const [updateId, setUpdateID] = useState('')
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false)
  const [openAuthorize, setOpenAuthorize] = useState(false)
  const [errMessage, setErrMessage] = useState('');
  const [message, setMessage] = useState('');
  const [deleteId, setDeleteId] = useState('')
  const [openDelete, setOpenDelete] = useState(false) 
  const [openBoxUpdate, setOpenBoxUpdate] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.req.loading);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const [data, setData] = useState([]);


  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, [token])


  const getAllProductDefintionTreasury = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}treasury/TreasuryProduct/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  },[dispatch, token]);

  useEffect(() => {
    getAllProductDefintionTreasury()
  }, [getAllProductDefintionTreasury])
      
      
  // useEffect(() => {
  // }, [allProductDefintion])
  const handleScreenChange = (i) => {
    setScreenChange(i);
  };

  const handleConfirmAuthorize = () => {
    setOpenAuthorize(true)
  }

  const handleUpdate = (item) => {
    setUpdateID(item.id)
    setProduct({...product, ...item})
    setOpenBoxUpdate(true)
  } 
  
  const handleCloseAndScreenReset = () => {
    setOpenBoxUpdate(false)
    setScreenChange(false)
    EmptyProductFields()
  }

  

  const [error, setError] = useState(false);
  const [product, setProduct] = useState({
    productCode: "",
    productName: "",
    portFolioType: "",
    deactivateProduct: false,
    productBuyLeg: "",
    productSellLeg: "",
    defaultInterestRate: "",
    maximumInterestRate: "",
    minimumInterestRate: "",
    rateBasis: "",
    couponMaturityBasis: "",
    effectiveRateDate: "",
    defaultTenor: "",
    minimumTenor: "",
    maximumTenor: "",
    tenorBasis: "",
    issueDate: "",
    issuerName: "",
    issuerPrice: "",
    startofTradingDate: "",
    profitOrLossAccount: "",
    productCreditAccount: "",
    productDebitAccount: "",
    interestAccount: "",
    interestIncomeAccount: "",
    interestExpenseAccount: "",
    interestPayableAccount: "",
    interestReceivableAccount: "",
    otherFeesAndIncome: [ 
      {
        chargeName: "",
        applyChargeAccount: "",
        chargeLedgerAccount: "",
        chargeType: 0,
        amountRate: 0,
        chargeCurrency: "",
      },
    ],
  });

  const [authorize, setAuthorize] = useState({
    reason: '',
    isApproved: true,
    authorizationStatus: '0'
  });

  const add = () => {
    const tempItem = [...product.otherFeesAndIncome]; 
    tempItem.push({
      chargeName: "",
      applyChargeAccount: "",
      chargeLedgerAccount: "",
      chargeType: 0,
      amountRate: 0,
      chargeCurrency: "",
    });
    setProduct({...product, otherFeesAndIncome: tempItem});
  };
  
  const remove = (i) => {
    const tempItem = [...product.otherFeesAndIncome]; 
    tempItem.splice(i, 1);
    setProduct({...product, otherFeesAndIncome: tempItem});
  };
  
  const handleChangeOtherFeesAndIncome = (e, index) => {
    const { name, value } = e.target;
    const list = [...product.otherFeesAndIncome];
    list[index][name] = value;
    setProduct({...product, otherFeesAndIncome: list});
  };

  const handleChanger = (e, type) => {
    const { name, value } = e.target;
    if(type === 'authorize') {
      setAuthorize(authorize => ({...authorize, [name]: value })); 
      return;
    }
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleFieldUpdate = (type) => {
    if(type === 'deactivateProduct') {
      setProduct({...product, deactivateProduct: !product.deactivateProduct})
    }
  }

  const handleChangeSelect = (value, type) => {
    if(type === 'productCreditAccount') {
      setProduct(product => ({...product, productCreditAccount: value }))
    } else if(type === 'productDebitAccount') {
      setProduct(product => ({...product, productDebitAccount: value }))
    } else if(type === 'interestAccount') {
      setProduct(product => ({...product, interestAccount: value }))
    } else if(type === 'interestIncomeAccount') {
      setProduct(product => ({...product, interestIncomeAccount: value }))
    } else if(type === 'interestExpenseAccount') {
      setProduct(product => ({...product, interestExpenseAccount: value }))
    } else if(type === 'interestPayableAccount') {
      setProduct(product => ({...product, interestPayableAccount: value }))
    } else if(type === 'interestReceivableAccount') {
      setProduct(product => ({...product, interestReceivableAccount: value }))
    }
  }

  const handleNext = () => {
    let checkError = isValidateForm(product);
    setError(checkError);
    if (Object.keys(checkError).length > 0) {
      return;
    }
    setError({});
    setScreenChange(true);
  };
  
  const handleConfirm = () => {
    let checkError = isValidateForm(product);
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;
    setError({});
    setOpenBox(true)
  };
  
  const handleAuthorizeOpen = () => {
    setUpdateID(viewAll.id)
    setOpenBoxAuthorize(true)
  }

  const handleConfirmUpdate = () => {
    let checkError = isValidateForm(product);
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;
    setOpenUpdate(true)
  }


  const EmptyProductFields = () => {
    setProduct({
      productCode: "",
      productName: "",
      portFolioType: "",
      deactivateProduct: false,
      productBuyLeg: "",
      productSellLeg: "",
      defaultInterestRate: "",
      maximumInterestRate: "",
      minimumInterestRate: "",
      rateBasis: "",
      couponMaturityBasis: "",
      effectiveRateDate: "",
      defaultTenor: "",
      minimumTenor: "",
      maximumTenor: "",
      tenorBasis: "",
      issueDate: "",
      issuerName: "",
      issuerPrice: "",
      startofTradingDate: "",
      profitOrLossAccount: "",
      productCreditAccount: "",
      productDebitAccount: "",
      interestAccount: "",
      interestIncomeAccount: "",
      interestExpenseAccount: "",
      interestPayableAccount: "",
      interestReceivableAccount: "",
      otherFeesAndIncome: [ 
        {
          chargeName: "",
          applyChargeAccount: "",
          chargeLedgerAccount: "",
          chargeType: 0,
          amountRate: 0,
          chargeCurrency: "",
        },
      ],
    })
  }

  const handleProductDefinitionCreate =  async (e) => {
    let data = {...product};

    try {
      isLoading()
        const response = await axios.post(`${baseUrl}treasury/TreasuryProduct/Create`, data);
       setMessage(response.data.responseMessage);
        if(response.data.status){
            EmptyProductFields()
            const newProduct = [...data]
            newProduct.push(response.data.data)
            setData(newProduct)
            return true
        } else {
            return false
        }
    } catch (err) {
        setMessage(err.response.data.responseMessage);
        return false
    } finally {
      isLoading()
    }
}


const updateProductDefinition = async () => {
  setLoader(true);
  let data = {...product};
  try {
      const response = await axios.put(`${baseUrl}treasury/TreasuryProduct/update/${updateId}`, data);
      if(response.data.statusCode === 200){
          // getCurrencyDefinitionAll()
          let a = allProductDefintion.map(items => {
            if(items.id === updateId) {
              items = {...response.data.data}
            }
            return items
          })
          setAllProductDefintion(a)
          EmptyProductFields()
          setScreenChange(false)
          setOpenBoxUpdate(false)
          return true
      } else {
          return false
      }
  } catch (error) {
      if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
          setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
          return false
      }
      return false
  } finally {
      setLoader(false);
  }
}

const deleteProductDefinition = async () => {
  setLoader(true);
  try {
      const response = await axios.delete(`${baseUrl}treasury/TreasuryProduct/delete/${deleteId}`);
      if(response.data.status){
          const filteredProduct = allProductDefintion.filter(items => items.id !== deleteId)
          setAllProductDefintion(filteredProduct)
          return true
      } else {
          return false
      }
  } catch {
      return false
  } finally {
      setLoader(false);
  }
}

const handleProductDefAuthorize = async () => {
  setLoader(true);
  let data = {...authorize};
  try {
      const response = await axios.put(`${baseUrl}treasury/TreasuryProduct/Authorize/${updateId}`, data);
      if(response.data.data){
          setOpenBoxAuthorize(false)
          setOpenView(false)
          return true
      } else {
          return false
      }
  } catch (error) {
          if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
              setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
              return false
          }
      return false
  } finally {
      setLoader(false);
  }
}

const handleViewAll = (item) => {
  setViewAll(item)
  setOpenView(true)
};



const handleConfirmDelete = (item) => {
  setDeleteId(item.id)
  setOpenDelete(true)
}



  const isValidateForm = (product) => {
    let errors = {};


    if (!screenChange && product.productCode.length !== 6) {
      errors.productCode = "Field must be 6 characters";
    }

    if (!screenChange && product.productCode === '') {
      errors.productCode = "Field is required";
    }
    if (!screenChange && product.productName === '') {
      errors.productName = "Field is required";
    }
    if (!screenChange && product.portFolioType === '') {
      errors.portFolioType = "Select an option";
    }
    if (!screenChange && product.productBuyLeg === '') {
      errors.productBuyLeg = "Select an option";
    }
    if (!screenChange && product.productSellLeg  === '') {
      errors.productSellLeg = "Select an option";
    }
    if (!screenChange && product.defaultInterestRate === '') {
      errors.defaultInterestRate = "Field is required";
    }
    if (!screenChange && product.maximumInterestRate === '') {
      errors.maximumInterestRate = "Field is required";
    }
    if (!screenChange && product.minimumInterestRate === '') {
      errors.minimumInterestRate = "Field is required";
    }
    if (!screenChange && product.rateBasis === '') {
      errors.rateBasis = "Select an option";
    }
    if (!screenChange && product.couponMaturityBasis === '') {
      errors.couponMaturityBasis = "Select an option";
    }
    if (!screenChange && product.effectiveRateDate === '') {
      errors.effectiveRateDate = "Field is required";
    }
    if (!screenChange && product.defaultTenor === '') {
      errors.defaultTenor = "Field is required";
    }
    if (!screenChange && product.minimumTenor === '') {
      errors.minimumTenor = "Field is required";
    }
    if (!screenChange && product.maximumTenor === '') {
      errors.maximumTenor = "Field is required";
    }
    if (!screenChange && product.tenorBasis === '') {
      errors.tenorBasis = "Select an option";
    }
    if (!screenChange && product.issueDate === '') {
      errors.issueDate = "Field is required";
    }
    if (!screenChange && product.issuerName === '') {
      errors.issuerName = "Field is required";
    }
    if (screenChange && product.issuerPrice === '') {
      errors.issuerPrice = "Field is required";
    }
    if (screenChange && product.startofTradingDate === '') {
      errors.startofTradingDate = "Field is required";
    }
    if (screenChange && product.profitOrLossAccount === '') {
      errors.profitOrLossAccount = "Field is required";
    }
    if (screenChange && product.productCreditAccount === '') {
      errors.productCreditAccount = "Field is required";
    }
    if (screenChange && product.productDebitAccount === '') {
      errors.productDebitAccount = "Field is required";
    }
    if (screenChange && product.interestAccount === '') {
      errors.interestAccount = "Field is required";
    }
    if (screenChange && product.interestIncomeAccount === '') {
      errors.interestIncomeAccount = "Field is required";
    }
    if (screenChange && product.interestExpenseAccount === '') {
      errors.interestExpenseAccount = "Field is required";
    }
    if (screenChange && product.interestPayableAccount === '') {
      errors.interestPayableAccount = "Field is required";
    }
    if (screenChange && product.interestReceivableAccount === '') {
      errors.interestReceivableAccount = "Field is required";
    }
    return errors;
  };

  const headCells = [
    { id: "id", label: "S/N" },
    { id: "productCode", label: "Product Code" },
    { id: "productName", label: "Product Name" },
    { id: "portFolioType", label: "PortFolio Type" },
    { id: "productBuyLeg", label: "Product BuyLeg" },
    { id: "productSellLeg", label: "Product SellLeg" },
    { id: "defaultInterestRate", label: "Default Intere. Rate" },
    { id: "authorizationStatus", label: "Auth. Status" },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.productName.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(allProductDefintion, headCells, filterFn);

  return {
    screenChange,
    message,
    handleScreenChange,
    handleSearch,
    error,
    product,
    deleteProductDefinition,
    handleChanger,
    handleNext,
    isValidateForm,
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    filterFn,
    handleFieldUpdate,
    handleChangeSelect,
    add,
    remove,
    handleConfirm,
    openBox,
    setOpenBox,
    loader,
    loading,
    viewAll,
    openView,
    setOpenView,
    handleProductDefinitionCreate,
    handleChangeOtherFeesAndIncome,
    handleViewAll,
    handleAuthorizeOpen,
    getAllProductDefintionTreasury,
    authorize,
    openBoxAuthorize,
    setOpenBoxAuthorize,
    handleConfirmAuthorize,
    openAuthorize,
    setOpenAuthorize,
    errMessage,
    handleProductDefAuthorize,
    handleConfirmDelete,
    openDelete, 
    handleUpdate, 
    setOpenDelete,
    openBoxUpdate,
    handleCloseAndScreenReset,
    setOpenBoxUpdate,
    updateProductDefinition,
    openUpdate, 
    setOpenUpdate,
    handleConfirmUpdate,
    data
  };
};

export default BusinessLogic;

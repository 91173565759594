import { Grid } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
}) => {
  return (
    <EditModal
      title="Update Teller Tills"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this Teller Till record?"
      data={details}
      url={`${Routes.updateTellerTill}${details.id}`}
    >
          <Grid container spacing={3} className="branchteller--tills">
            <Grid item xs={12} sm={6} md={6} className="label--input flex--3">
              <label>Till Id</label>
              <input
                type="text"
                value={details.tillId}
                onChange={handleEditChange("tillId")}
                disabled
              />
              {errorEdit.tillId && <ErrorMessage message={errorEdit.tillId} />}
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="label--input flex--3">
              <label>Branch Id</label>
              <input
                type="text"
                value={details.branchId}
                onChange={handleEditChange("branchId")}
              />
              {errorEdit.branchId && (
                <ErrorMessage message={errorEdit.branchId} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Currency</label>
              <input
                type="text"
                value={details.currencyCode}
                onChange={handleEditChange("currencyCode")}
              />
              {errorEdit.currencyCode && (
                <ErrorMessage message={errorEdit.currencyCode} />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <label>Account Number</label>
              <input
                type="text"
                value={details.accountNumber}
                onChange={handleEditChange("accountNumber")}
                disabled
              />
              {errorEdit.accountNumber && (
                <ErrorMessage message={errorEdit.accountNumber} />
              )}
            </Grid>
          </Grid>
    </EditModal>
  );
};

export default EditData;

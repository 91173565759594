//import crypto from 'crypto'
import CryptoJS from "crypto-js";
import * as crypto from "crypto";

//const crypto = require('crypto');

// Function to add PKCS#7 padding
export const addPadding = (data, blockSize) => {
  const paddingLength = blockSize - (data.length % blockSize);
  const padding = Buffer.alloc(paddingLength, paddingLength);
  return Buffer.concat([Buffer.from(data), padding]);
};

export const encryptAES = async (dataToEncrypt, key, iv) => {
  const blockSize = 16;
  const paddedData = addPadding(dataToEncrypt, blockSize);
  const aes = crypto.createCipheriv("aes-128-cbc", key, iv);
  let encryptedData = aes?.update(paddedData, "utf-8", "hex");
  encryptedData += aes?.final("hex");
  return encryptedData;
};

export const newEncrypter = (data) => {
  const encryptedPassword = CryptoJS.AES.encrypt(
    data,
    process.env.NEXT_PUBLIC_SECRETKEY
  ).toString();
  return encryptedPassword;
};


import React from 'react';
import './ChequeTransfer.scss';
import MenuItem from '@material-ui/core/MenuItem';
import { AiTwotoneDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import useTable from "../../../Tables/useTable";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

const ChequeTransfer = () => {


    const headCells = [
        { id: "id", label: "S/N" },
        { id: "tillId", label: "Till Id" },
        { id: "branchId", label: "Branch" },
        { id: "accountNumber", label: "Till CCYI Account" },
        { id: "authorizationStatus", label: "Auth. Status" },
        { id: "action", label: "Action", disableSorting: true },
        
      ];
      
      const [filterFn, setFilterFn] = React.useState({
        fn: (items) => {
          return items;
        },
      }); 
    
      const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
      } = useTable([], headCells, filterFn);
    
    return (
        <div className='chequeTransfer'>
                <div className='headerdiv'>
                    <p>Cheque Transfer - Local Currency</p> 
                 </div>
            <>

                <div style={{ paddingTop: 12, padding: 25 }}>
                    <div className="product--def flex--1">
                        <div className="flex--3 input--5">
                            <label>Debit Account Number</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        <div className="flex--3 input--5">
                            <label>Debit Account Currency</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        </div>
                  <div className="product--def flex--1">
                    <div className="flex--3 input--4">
                            <label>Customer BVN</label>
                            <input type="text" />
                        </div>
                    <div className="flex--3 input--4">
                            <label>Cheque BVN</label>
                            <input type="text" />
                        </div>
                    <div className="flex--3 input--4">
                            <label>Cheque Issue Date</label>
                            <input type="date" />
                        </div>
                        </div>
                        <div className="product--def flex--1">
                        <div className="flex--3 input--5">
                            <label>Control Number</label>
                            <input type="text" />
                        </div>
                        <div className="flex--3 input--5">
                            <label>Cheque Amount</label>
                            <input type="number" step="any" />
                        </div>
                        </div>
                        <div className="product--def flex--1">
                        <div className="flex--3 input--5">
                            <label>Credit Account Number</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        <div className="flex--3 input--5">
                            <label> Account Branch</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        </div>
                        <div className="product--def flex--1">
                        <div className="flex--3 input--5">
                            <label> Account Product</label>
                            <input type="text" />
                        </div>
                        <div className='sub-input input--2'>
                            <input/>
                        </div>
                        <div className="flex--3 input--4">
                            <label>Credit Account Currency</label>
                            <input type="text" />
                        </div>
                        </div>
                        <div className="product--def flex--1">
                        <div className="flex--3 input--4">
                            <label>Narration </label>
                            <input type="text" />
                        </div>
                        </div>
                    <div className='flex--5'>
                        <button className="save--button">Save</button>
                    </div>

          <hr />
              <h4>Cheque Transfer</h4>
                <div className="sub--section flex--2">
                    <div className="main-filter flex--2">
                    <input
                        className="filter"
                        placeholder="Filter"
                        //   onChange={handleSearch}
                    />
                    
                    <button className="button--second">Remove Filter</button>
                    </div>
                </div>
            </div>

        <TblContainer>
            <TblHead />
            <tbody>
              <tr>
                <th scope="row">{1}</th>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>
                  <div className="dropdownMenu">
                    <DotMenu className="dotMenu--button">
                      <MenuItem>Edit</MenuItem>
                      <MenuItem>Delete</MenuItem>
                      <MenuItem>View</MenuItem>
                    </DotMenu>
                  </div>
                </td>
              </tr>
            </tbody>
        </TblContainer>
                        {/* <section className='sub-section'>
                            <div className='flex--1 container-main'>
                                <div className='input-container flex--3'>
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Debit Account Number</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Customer BVN</label>
                                        <input/>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Cheque Issue Date</label>
                                        <input type='date'/>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Cheque Amount</label>
                                        <input/>
                                    </div>
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Credit Account Number</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Account Product</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                </div>
                                <div className='input-container flex--3'> 
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Debit Account Currency</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Cheque Number</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Control Number</label>
                                        <input/>
                                    </div>
                                    {/* <div className='flex--3'>
                                        <label>Account Amount</label>
                                        <input/>
                                    </div> */}
                                    {/* <div className='input-container--1 flex--1'>
                                        <div className='flex--3'>
                                            <label>Account Branch</label>
                                            <input/>
                                        </div>
                                        <div className='sub-input'>
                                            <input/>
                                        </div>
                                    </div>
                                    <div className='flex--3'>
                                        <label>Credit Account Currency</label>
                                        <input/>
                                    </div>
                                </div>
                            </div>
                            <div className='last-input'>
                                <input/>
                            </div>
                        </section>  */}
          {/* <TblPagination /> */}
                    </>  
        </div>
    )
}

export default ChequeTransfer

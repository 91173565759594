import axios from 'axios';
import { Routes } from '../../configUrl';



const getAccountsOpened = async (token,data) => {
    return await axios({
        method: "get",
        url: `${Routes.getOpenedAccounts}${data.From}/${data.To}`,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data.data
        };
      })
      .catch((err) => {
          console.log(err)
        return {
          data:[],
        };
      });
  };

  export{getAccountsOpened}
import { useState } from "react";
import Dash from "./Dash/Dash";
import { Switch, Route } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import "./Main.scss";
import Navbar from "./Navbar/Navbar";
import ChequeTransactions from "./ChequeTransactions/Main";
import CoreModule from "./CoreModule/Main";
import AuthorizeCoreModule from "./AuthorizeCoreModule/Main";
import CustomerAccount from "./CustomerAccount/Main";
import SecurityManagement from "./SecurityManagement/Main";
import AuthorizeSecurityManagement from "./AuthorizeSecurityManagement/Main";
import VaultOperations from "./VaultOperations/Main";
import Products from "./Products/Main";
import AuthorizeProducts from "./AuthorizeProducts/Main";
import CashTransaction from "./CashTransaction-LCY/Main";
import CashTransactionFCY from "./CashTransaction-FCY/Main";
import Miscellaneous from "./MiscellaneousTransactions/Main";
import CustomerAccountOperations from "./CustomerAccountOperations/Main";
import InvestmentAccountOperations from "./InvestmentAccountOperations/Main";
import AuthorizeLoanAccountOperations from "./AuthorizeLoanAccountOperations/Main";
import AuthorizeInvestmentAccountOperations from "./AuthorizeInvestmentAccountOperations/Main";
import AuthorizeAccountOperations from "./AuthorizeAccountOperations/Main";
import LoanAccountOperations from "./LoanAccountOperations/Main";
import Treasury from "./Treasury/Main";
import AuthorizeTreasury from "./AuthorizeTreasury/Main";
import TmeEodProcesses from "./TmeEodProcesses/TmeEodProcesses";
import LoanReports from "./Reports/LoanReports/Main";
import AccountReports from "./Reports/AccountReports/Main";
import TransactionReports from "./Reports/TransactionReports/Main";
import InvestmentReports from "./Reports/InvestmentReports/Main";
import CRBReports from "./Reports/CRBReports/Main";
import RemoteAuthorization from "./RemoteAuthorization/Main";
import ChangePassword from "./ChangePassword/ChangePassword";
import EodReports from "./EODReports/Main";
import { TimeRestrictedRoute } from "../RouteCheckers";

const Main = () => {
  const [toggle, setToggle] = useState(false);
  window.scrollTo(0, 0);

  const handleToggler = () => {
    setToggle(!toggle);
  };

  return (
    <div className="main">
      <Navbar toggler={handleToggler} />
      <Sidebar toggle={toggle} />
      <div className={`main--body ${toggle && "slide-body"}`}>
        <Switch>
          <Route path="/dashboard/home" component={Dash} />
          <Route path="/dashboard/coremodule" component={CoreModule} />
          <Route
            path="/dashboard/authorizecoremodule"
            component={AuthorizeCoreModule}
          />
          <Route
            path="/dashboard/customeraccount"
            component={CustomerAccount}
          />
          <Route path="/dashboard/security" component={SecurityManagement} />
          <Route
            path="/dashboard/authorizesecurity"
            component={AuthorizeSecurityManagement}
          />
          <Route
            path="/dashboard/vaultoperations"
            component={VaultOperations}
          />
          <Route
            path="/dashboard/chequetransaction"
            component={ChequeTransactions}
          />
          <Route path="/dashboard/products" component={Products} />
          <Route
            path="/dashboard/authorizeproducts"
            component={AuthorizeProducts}
          />
          <TimeRestrictedRoute
            path="/dashboard/cashtransactions-lcy"
            component={CashTransaction}
          />
          <Route
            path="/dashboard/cashtransactions-fcy"
            component={CashTransactionFCY}
          />
          
          <TimeRestrictedRoute
            path="/dashboard/miscellaneoustransactions"
            component={Miscellaneous}
          />
          <Route
            path="/dashboard/customeraccountoperations"
            component={CustomerAccountOperations}
          />
          <Route
            path="/dashboard/authorizeaccountoperations"
            component={AuthorizeAccountOperations}
          />
          <Route
            path="/dashboard/investmentaccountoperations"
            component={InvestmentAccountOperations}
          />
          <Route
            path="/dashboard/authorizeinvestmentaccountoperations"
            component={AuthorizeInvestmentAccountOperations}
          />
          <Route
            path="/dashboard/loanaccountoperations"
            component={LoanAccountOperations}
          />
          <Route
            path="/dashboard/authorizeloanaccountoperations"
            component={AuthorizeLoanAccountOperations}
          />
          <Route path="/dashboard/treasury" component={Treasury} />
          <Route
            path="/dashboard/authorizetreasury"
            component={AuthorizeTreasury}
          />
          <Route
            path="/dashboard/tmeeodprocesses"
            component={TmeEodProcesses}
          />
          <Route path="/dashboard/reports/loan" component={LoanReports} />
          <Route path="/dashboard/reports/account" component={AccountReports} />
          <Route
            path="/dashboard/reports/investment"
            component={InvestmentReports}
          />
          <Route
            path="/dashboard/reports/transaction"
            component={TransactionReports}
          />
          <Route path="/dashboard/reports/crb" component={CRBReports} />

          <Route
            path="/dashboard/remoteauthorization"
            component={RemoteAuthorization}
          />
          <Route path="/dashboard/changepassword" component={ChangePassword} />
          <Route path="/dashboard/eodreports" component={EodReports} />
        </Switch>
      </div>
    </div>
  );
};

export default Main;

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../DatagridTable/table";
import DetailsContainer from "../../DatagridTable/details";
import AuthorizationModal from "../../DatagridTable/authorizationModal";
import { getHandler } from "../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../Backdrop";
import { openDetailsModal, loading } from "../../../Api/redux-manager/actions";
import { baseUrl } from "../../../Api/configUrl";
import { getCustomerByAccountNumber } from "../../../Api/Hooks-manager/loanAccountOperations";
import Details from "../../Details/AccountOperations/AccountEnquiry";

const AccountEnquiry = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountType",
      label: "Acc Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountBalance",
      label: "Balances",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  console.log(details);

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        fetchAccountDetails(item.accountNumber);
      }
    },
  };

  const fetchAllAccountEnquiry = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}account/CustomerAccount/GetUnAuthorized`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  const fetchAccountDetails = async (accNo) => {
    dispatch(loading());
    const response = await getCustomerByAccountNumber(token, accNo);
    if (response && response.data) {
      dispatch(loading());
      if (response.status) {
        setDetails(response.data);
        dispatch(openDetailsModal());
      }
    }
  };

  useEffect(() => {
    fetchAllAccountEnquiry();
  }, [fetchAllAccountEnquiry, updateTable]);

  const accountStatus = details.authorizationStatus;

  console.log(accountStatus);

  return (
    <div>
      <Spinner loading={load} />
      {!load && (
        <Table
          title="Account Enquiry"
          subtitle=""
          name="List of Accounts"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <DetailsContainer title="Account Enquiry - Record Details">
        <Details details={details} />
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize Account"
        question="Are you sure you want to authorize this Account?"
        url={
          accountStatus === "Pending"
            ? `${baseUrl}account/CustomerAccount/AuthorizeNewCustomerAccount/${details.id}`
            : `${baseUrl}account/CustomerAccount/Authorize/${details.id}`
        }
      />
    </div>
  );
};

export default AccountEnquiry;

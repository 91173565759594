import { Grid } from "@material-ui/core";
import Item from "../../DatagridTable/item";


const ChannelUser = ({details}) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Instution Name" name={details.name} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Institution Type" name={details.institutionType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Instution Currency" name={details.currencyCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Instution Address" name={details.name} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Instution Name" name={details.address} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Country code" name={details.countryCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="State" name={details.stateCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item
              label="Head Office Branch Code"
              name={details.headOfficeBranchCode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Institution Code" name={details.code} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Start Time" name={details.startTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="End Time" name={details.endTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>
    )
}

export default ChannelUser

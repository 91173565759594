import { useState, useCallback, useEffect } from "react";
import { validateChartOfAccount } from "../../validator/validate11";
import { baseUrl } from "../../configUrl";
import axios from "axios";
import { useSelector } from "react-redux";

const useCustom = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let Token = user && user.jwtToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
  }, [user]);

  const [loader, setLoader] = useState(false);
  const [allbankparameters, setAllbankparameters] = useState([]);
  const [allCurrencyDefinition, setAllCurrencyDefinition] = useState([]);
  const [allPeriodDefinition, setAllPeriodDefinition] = useState([]);
  const [allGlAccount, setAllGlAccount] = useState([]);
  const [allBranchCalendar, setAllBranchCalendar] = useState([]);
  const [allBankCalendar, setAllBankCalendar] = useState([]);
  const getCountries = useSelector(state => state.common.countries);
  const [fiscalYear, setGetFiscalYear] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success] = useState(false);
  const [openBoxUpdate, setOpenBoxUpdate] = useState(false);
  const [openBoxAuthorize, setOpenBoxAuthorize] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [updateId, setUpdateID] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [slicedmontstate, setSlicedmontstate] = useState([]);

  // useEffect(() => {
  //     getAllCountries()
  // }, [])

  // const getAllCountries =  async (e) => {
  //     try {
  //         const response = await axios.get(`${baseUrl}user/Country/GetAll`);
  //         if(response.data.statusCode === 200) {
  //             setGetCountries(response.data.data)
  //         }
  //     } catch (err) {
  //     } finally {
  //     }
  // }

  useEffect(() => {
    getFiscalYear();
  }, []);

  const getFiscalYear = async e => {
    try {
      const response = await axios.get(
        `${baseUrl}organization/FiscalYear/GetAll`
      );
      if (response.data.statusCode === 200) {
        setGetFiscalYear(response.data.data);
      }
    } catch (err) {
    } finally {
    }
  };
  // GL Account
  const [valuesCreate, setValuesCreate] = useState({
    accountName: "",
    accountNumber: "",
    glType: "",
    glHierarchy: "",
    isGLActive: true,
    parentGLID: "",
    reckonType: "None",
    productAccount: false,
    entityType: "Both",
    branchCode: "",
  });

  const resetValues = () => {
    setValuesCreate({
      accountName: "",
      accountNumber: "",
      glType: "",
      glHierarchy: "",
      isGLActive: true,
      parentGLID: "",
      reckonType: "None",
      productAccount: false,
      entityType: "Both",
      branchCode: "",
    });
    setValuesBankParametersCreate({
      parameterName: "",
      parameterValue: "",
      parameterLength: 0,
    });
    setValuesCurrencyDef({
      currencyName: "",
      currencyCode: "",
      currencySymbols: "",
      numericCode: 0,
    });
    setValuesPeriodDef({
      fiscalYear: "",
      periodCode: "",
      startdate: "",
      enddate: "",
    });
    setValuesBranchHolidayDef({
      branchCode: "",
      holidays: [
        {
          month: "",
          days: "",
        },
      ],
      year: new Date().getFullYear(),
      bankId: "",
      startTime: "",
      closeTime: "",
      exemptFlg: "y",
    });
    setValuesBankHolidays({
      holidays: [
        {
          month: "",
          days: "",
        },
      ],
      year: new Date().getFullYear(),
      bankId: "",
      startTime: "",
      closeTime: "",
      exemptFlg: "y",
    });
  };

  const [valuesBankParametersCreate, setValuesBankParametersCreate] = useState({
    parameterName: "",
    parameterValue: "",
    parameterLength: 0,
  });

  const [valuesCurrencyDef, setValuesCurrencyDef] = useState({
    currencyName: "",
    currencyCode: "",
    currencySymbols: "",
    numericCode: 0,
  });

  const [valuesPeriodDef, setValuesPeriodDef] = useState({
    fiscalYear: "",
    periodCode: "",
    startdate: "",
    enddate: "",
  });

  const [valuesBranchHolidayDef, setValuesBranchHolidayDef] = useState({
    branchCode: "",
    holidays: [
      {
        month: "",
        days: "",
      },
    ],
    year: new Date().getFullYear(),
    bankId: "",
    startTime: "",
    closeTime: "",
    exemptFlg: "y",
  });
  const [valuesBankHolidays, setValuesBankHolidays] = useState({
    holidays: [
      {
        month: "",
        days: "",
      },
    ],
    year: new Date().getFullYear(),
    bankId: "",
    startTime: "",
    closeTime: "",
    exemptFlg: "y",
  });

  const [editHoliday, setEditHoliday] = useState({
    holiday: "",
    month: "",
    year: new Date().getFullYear(),
    bankId: "",
    startTime: "",
    closeTime: "",
    exemptFlg: "",
  });

  const [editBranchHoliday, setEditBranchHoliday] = useState({
    branchCode: "",
    holiday: "",
    month: "",
    year: new Date().getFullYear(),
    bankId: "",
    startTime: "",
    closeTime: "",
    exemptFlg: "",
  });

  useEffect(() => {}, [slicedmontstate]);

  // console.log

  const [authorize, setAuthorize] = useState({
    reason: "",
    isApproved: true,
    authorizationStatus: 0,
  });

  const handleChange = (e, change) => {
    const { name, value } = e.target;
    if (change === "currencyDef") {
      setValuesCurrencyDef(valuesCurrencyDef => ({
        ...valuesCurrencyDef,
        [name]: value,
      }));
    } else if (change === "createPeriods") {
      setValuesPeriodDef(valuesPeriodDef => ({
        ...valuesPeriodDef,
        [name]: value,
      }));
    } else if (change === "authorize") {
      setAuthorize(authorize => ({ ...authorize, [name]: value }));
    } else if (change === "branchCalendar") {
      setValuesBranchHolidayDef(valuesBranchHolidayDef => ({
        ...valuesBranchHolidayDef,
        [name]: value,
      }));
    } else if (change === "bankCalendar") {
      setValuesBankHolidays(valuesBankHolidays => ({
        ...valuesBankHolidays,
        [name]: value,
      }));
    } else {
      setValuesCreate(valuesCreate => ({
        ...valuesCreate,
        [e.target.name]: value,
      }));
      setValuesBankParametersCreate(valuesBankParametersCreate => ({
        ...valuesBankParametersCreate,
        [name]: value,
      }));
    }
  };

  useEffect(() => {}, [errors]);

  const EmptyGlFields = () => {
    setValuesCreate({
      accountName: "",
      accountNumber: "",
      glType: "",
      glHierarchy: "",
      isGLActive: "",
      parentGLID: "",
      reckonType: "None",
      productAccount: false,
      entityType: "Both",
      branchCode: "",
    });
  };

  const handleGLAccountCreate = async callback => {
    setErrors(validateChartOfAccount(valuesCreate));
    setLoader(true);
    let data = { ...valuesCreate };
    try {
      const response = await axios.post(
        `${baseUrl}account/GLAccount/Create`,
        data
      );
      if (response.data.status) {
        getGLAccountGetAll();
        EmptyGlFields();
        setOpen(true);
        callback();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      if (err.message === "Request failed with status code 400") {
        return false;
      } else if (err.message === "Failed to save record.") {
        setMessage("Invalid request. Account may exist already !");
        return false;
      } else {
        setMessage("An error occured !");
        return false;
      }
    } finally {
      setLoader(false);
    }
  };

  const updateGlAccount = async () => {
    setLoader(true);
    let data = { ...valuesCreate };
    try {
      const response = await axios.put(
        `${baseUrl}account/GLAccount/Update/${updateId}`,
        data
      );
      if (response.data.status) {
        getGLAccountGetAll();
        EmptyGlFields();
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleGlAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}account/GLAccount/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        let a = allGlAccount.map(items => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllGlAccount(a);
        // getGLAccountGetAll()
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getGLAccountGetAll = useCallback(async e => {
    try {
      const response = await axios.get(`${baseUrl}account/GLAccount/GetAll`);
      if (response.data.status) {
        setAllGlAccount(response.data.data);
      }
    } catch (err) {
    } finally {
    }
  }, []);

  const deleteGLAccountByid = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}account/GLAccount/Delete/${deleteId}`
      );
      if (response.data.status) {
        getGLAccountGetAll();
        return true;
      }
      if (
        response.data.responseMessage.includes(
          "This Record has been authorized and possibled used"
        )
      ) {
        console.log("valid");
        setErrMessage("Unsuccessful, You cannot delete an authorized record.");
        return false;
      }
    } catch {
      console.log("innnvalid");
      return false;
    } finally {
      setLoader(false);
    }
  };

  const EmptyFieldsDefinition = () => {
    setValuesPeriodDef({
      fiscalYear: "",
      periodCode: "",
      startdate: "",
      enddate: "",
    });
  };

  // End of GLAccount APIsss

  // Beginning of Period defintion
  const handlePeriodDefinitionCreate = async (callback) => {
    setLoader(true);
    let data = { ...valuesPeriodDef };
    console.log(data, "dadadad");
    try {
      const response = await axios.post(
        `${baseUrl}organization/PeriodDefinition/Create`,
        data
      );
      if (response.data.status) {
        getPeriodDefinitionAll();
        EmptyFieldsDefinition();
        callback();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      if (err.message === "Request failed with status code 400") {
        return false;
      }
    } finally {
      setLoader(false);
    }
  };

  const updatePeriodDefinition = async () => {
    setLoader(true);
    let data = { ...valuesPeriodDef };
    try {
      const response = await axios.put(
        `${baseUrl}organization/PeriodDefinition/Update/${updateId}`,
        data
      );
      if (response.data.statusCode === 200) {
        // getPeriodDefinitionAll()
        setValuesPeriodDef({
          fiscalYear: "",
          periodCode: "",
          startdate: "",
          enddate: "",
        });
        let a = allPeriodDefinition.map(items => {
          if (items.id === updateId) {
            items.authorizationStatus = "Pending";
          }
          return items;
        });
        setAllPeriodDefinition(a);
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handlePeriodDefAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}organization/PeriodDefinition/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        let a = allPeriodDefinition.map(items => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllPeriodDefinition(a);
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getPeriodDefinitionAll = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}organization/PeriodDefinition/GetAll`
      );
      // const maindata = await response.json();

      if (response.data.status) {
        setAllPeriodDefinition(response.data.data);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 400") {
        setMessage("Invalid request !");
      } else if (err.message === "Failed to save record.") {
        setMessage("Invalid request. Account may exist already !");
      } else {
        setMessage("An error occured !");
      }
      setOpen(true);
    } finally {
    }
  }, []);

  const deletePeriodDefinition = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}organization/PeriodDefinition/Delete/${deleteId}`
      );
      if (response.data.status) {
        let a = allPeriodDefinition.filter(items => items.id !== deleteId);
        setAllPeriodDefinition(a);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
      //     setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
      //     return false
      // }
      return false;
    } finally {
      setLoader(false);
    }
  };

  // End of period defintion

  // Beggining of Currency Definition APi

  const EmptyCurrencyDefFields = () => {
    setValuesCurrencyDef({
      currencyName: "",
      currencyCode: "",
      currencySymbols: "",
      numericCode: 0,
    });
  };
  const handleCurrencyDefinitionCreate = async e => {
    setLoader(true);
    let data = { ...valuesCurrencyDef };
    try {
      const response = await axios.post(
        `${baseUrl}organization/CurrencyDefinition/Create`,
        data
      );
      if (response.data.statusCode === 200) {
        getCurrencyDefinitionAll();
        EmptyCurrencyDefFields();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      setErrMessage(err.response.data.responseMessage);
      if (err.message === "Request failed with status code 400") {
        return false;
      } else if (err.message === "Failed to save record.") {
        setMessage("Invalid request. Account may exist already !");
        return false;
      } else {
        setMessage("An error occured !");
        return false;
      }
    } finally {
      setLoader(false);
    }
  };

  const updateCurrencyDefinition = async () => {
    setLoader(true);
    let data = { ...valuesCurrencyDef };
    try {
      const response = await axios.put(
        `${baseUrl}organization/CurrencyDefinition/Update/${updateId}`,
        data
      );
      if (response.data.statusCode === 200) {
        // getCurrencyDefinitionAll()
        setValuesCurrencyDef({
          currencyName: "",
          currencyCode: "",
          currencySymbols: "",
          numericCode: 0,
        });
        let a = allCurrencyDefinition.map(items => {
          if (items.id === updateId) {
            items = { ...valuesCurrencyDef };
          }
          return items;
        });
        setAllCurrencyDefinition(a);
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };
  const deleteCurrencyDefinition = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}organization/CurrencyDefinition/Delete/${deleteId}`
      );
      if (response.data.status) {
        let a = allCurrencyDefinition.filter(items => items.id !== deleteId);
        setAllCurrencyDefinition(a);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
      //     setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
      //     return false
      // }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleCurrencyDefAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}organization/CurrencyDefinition/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setAuthorize({
          reason: "",
          isApproved: "",
        });
        let a = allCurrencyDefinition.map(items => {
          if (items.id === updateId) {
            items.authorizationStatus = "Authorized";
          }
          return items;
        });
        setAllCurrencyDefinition(a);
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getCurrencyDefinitionAll = useCallback(async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}organization/CurrencyDefinition/GetAll`
      );
      // const maindata = await response.json();
      if (response.data.statusCode === 200) {
        setAllCurrencyDefinition(response.data.data);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 400") {
        setMessage("Invalid request !");
      } else if (err.message === "Failed to save record.") {
        setMessage("Invalid request. Account may exist already !");
      } else {
        setMessage("An error occured !");
      }
      setOpen(true);
    } finally {
      setLoader(false);
    }
  }, []);

  // Beggining of BankParameter APi
  const EmptyFieldsParameters = () => {
    setValuesBankParametersCreate({
      parameterName: "",
      parameterValue: "",
      parameterLength: 0,
    });
  };

  const handleBankParametersCreate = async callBack => {
    setLoader(true);
    let data = { ...valuesBankParametersCreate };

    try {
      const response = await axios.post(
        `${baseUrl}organization/BankParameters/Create`,
        data
      );

      if (response.data.statusCode === 200) {
        getBankParametersAll();
        EmptyFieldsParameters();
        callBack();
        return true;
      } else {
        return false;
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const getBankParametersAll = useCallback(async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}organization/BankParameters/GetAll`
      );
      // const maindata = await response.json();
      if (response.data.statusCode === 200) {
        setAllbankparameters(response.data.data);
      }
      console.log(response.data.data, "parrrrraaaammmm")
      if (!response) {
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  }, []);

  const updateParameterSettings = async () => {
    setLoader(true);
    let data = { ...valuesBankParametersCreate };
    try {
      const response = await axios.put(
        `${baseUrl}organization/BankParameters/Update/${updateId}`,
        data
      );
      console.log(response, "aqswde");
      if (response.data.statusCode === 200) {
        // getCurrencyDefinitionAll()
        setValuesBankParametersCreate({
          parameterName: "",
          parameterValue: "",
          parameterLength: "",
        });
        let a = allbankparameters.map(items => {
          if (items.id === updateId) {
            let des = response.data.data;
            items = { ...items, ...des };
          }
          return items;
        });
        setAllbankparameters(a);
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (
        error.response.data.responseMessage ===
        "This Record was modified recently and awaiting authorization."
      ) {
        setErrMessage(
          "Unsuccessful, You cannot update an unauthorized record."
        );
        return false;
      }
      return false;
    } finally {
      setLoader(false);
    }
  };

  const deleteParameterSettings = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}organization/BankParameters/Delete/${deleteId}`
      );
      if (response.data.status) {
        let a = allbankparameters.filter(items => items.id !== deleteId);
        setAllbankparameters(a);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // if(error.response.data.responseMessage === 'This Record was modified recently and awaiting authorization.'){
      //     setErrMessage('Unsuccessful, You cannot update an unauthorized record.')
      //     return false
      // }
      return false;
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getGLAccountGetAll();
  }, [getGLAccountGetAll]);

  // Branch Calendar Starts
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleBranchCalendarCreate = async e => {
    setLoader(true);
    let data = { ...valuesBranchHolidayDef };
    try {
      const response = await axios.post(
        `${baseUrl}organization/BranchHoliday/Create`,
        data
      );

      if (response.data.statusCode === 200) {
        resetValues();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      if (err.message === "Request failed with status code 400") {
        return false;
      } else if (err.message === "Failed to save record.") {
        setMessage("Invalid request. Account may exist already !");
        return false;
      } else {
        setMessage("An error occured !");
        return false;
      }
    } finally {
      setLoader(false);
    }
  };

  const getAllBranchCalendar = useCallback(async e => {
    try {
      const response = await axios.get(
        `${baseUrl}organization/BranchHoliday/GetAll`
      );
      if (response.data.status) {
        setAllBranchCalendar(response.data.data);
      }
    } catch (err) {
    } finally {
    }
  }, []);

  const handleBranchHolidayAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}organization/BranchHoliday/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const deleteBranchCalendar = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}organization/BranchHoliday/Delete/${deleteId}`
      );
      if (response.data.status) {
        let filteredBranchCalendar = allBranchCalendar.filter(
          items => items.id !== deleteId
        );
        setAllBranchCalendar(filteredBranchCalendar);
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const updateBranchCalendar = async () => {
    setLoader(true);
    let data = { ...editBranchHoliday };
    try {
      const response = await axios.put(
        `${baseUrl}organization/BranchHoliday/Update/${updateId}`,
        data
      );
      if (response.data.statusCode === 200) {
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    } finally {
      setLoader(false);
    }
  };

  // Bank Calendar
  const handleBankCalendarCreate = async e => {
    setLoader(true);
    let data = { ...valuesBankHolidays };
    try {
      const response = await axios.post(
        `${baseUrl}organization/BankHoliday/Create`,
        data
      );

      if (response.data.statusCode === 200) {
        resetValues();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const getAllBankCalendar = useCallback(async e => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${baseUrl}organization/BankHoliday/GetAll`
      );
      if (response.data.status) {
        setAllBankCalendar(response.data.data);
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  }, []);

  const updateBankCalendar = async () => {
    setLoader(true);
    let data = { ...editHoliday };
    try {
      const response = await axios.put(
        `${baseUrl}organization/BankHoliday/Update/${updateId}`,
        data
      );
      if (response.data.statusCode === 200) {
        setOpenBoxUpdate(false);
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleBankHolidayAuthorize = async () => {
    setLoader(true);
    let data = { ...authorize };
    try {
      const response = await axios.put(
        `${baseUrl}organization/BankHoliday/Authorize/${updateId}`,
        data
      );
      if (response.data.status) {
        setOpenBoxAuthorize(false);
        setOpenView(false);
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    } finally {
      setLoader(false);
    }
  };

  const deleteBankCalendar = async () => {
    setLoader(true);
    try {
      const response = await axios.delete(
        `${baseUrl}organization/BankHoliday/Delete/${deleteId}`
      );
      if (response.data.status) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    } finally {
      setLoader(false);
    }
  };

  return {
    editBranchHoliday,
    handleBranchHolidayAuthorize,
    setEditBranchHoliday,
    deleteBankCalendar,
    handleBankHolidayAuthorize,
    editHoliday,
    setEditHoliday,
    updateBankCalendar,
    months,
    getAllBankCalendar,
    allBankCalendar,
    handleBankCalendarCreate,
    valuesBankHolidays,
    setValuesBankHolidays,
    updateBranchCalendar,
    setAllGlAccount,
    deleteBranchCalendar,
    setValuesBranchHolidayDef,
    allBranchCalendar,
    getAllBranchCalendar,
    slicedmontstate,
    setSlicedmontstate,
    handleBranchCalendarCreate,
    getBankParametersAll,
    getCurrencyDefinitionAll,
    updateParameterSettings,
    setValuesBankParametersCreate,
    handlePeriodDefAuthorize,
    deleteParameterSettings,
    setValuesPeriodDef,
    updatePeriodDefinition,
    deletePeriodDefinition,
    allPeriodDefinition,
    getPeriodDefinitionAll,
    openView,
    setOpenView,
    openBoxAuthorize,
    setOpenBoxAuthorize,
    handleCurrencyDefAuthorize,
    handleGlAuthorize,
    authorize,
    updateGlAccount,
    updateCurrencyDefinition,
    deleteCurrencyDefinition,
    setDeleteId,
    errMessage,
    open,
    setOpen,
    openBoxUpdate,
    setUpdateID,
    setOpenBoxUpdate,
    fiscalYear,
    handlePeriodDefinitionCreate,
    success,
    message,
    handleCurrencyDefinitionCreate,
    getCountries,
    allCurrencyDefinition,
    valuesCurrencyDef,
    allGlAccount,
    errors,
    EmptyGlFields,
    setValuesCreate,
    setValuesCurrencyDef,
    valuesBankParametersCreate,
    allbankparameters,
    valuesPeriodDef,
    handleBankParametersCreate,
    handleChange,
    getGLAccountGetAll,
    deleteGLAccountByid,
    valuesCreate,
    handleGLAccountCreate,
    loader,
    valuesBranchHolidayDef,
  };
};

export default useCustom;

import { useState, useCallback, useEffect } from "react";
import "./PrincipalOfficers.scss";
import Spinner from "../../../../Backdrop/index";
import Table from "../../../../DatagridTable/table";
// import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
// import ErrorMessage from "../../../../ErrorMessage";
import { getCbrReports } from "../../../../../Api/Hooks-manager/reports/loanDetails";
import { formattedDate } from "../../../../../formatter/date";
import { useDispatch } from "react-redux";
// import moment from "moment";
// import { format } from "date-fns";

import { isLoading } from "../../../../../Api/redux-manager/actions";

const PrincipalOfficers = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const loading = useSelector((state) => state.req.loading);
  // const branch = useSelector((state) => state.common.branch.branch);
  // const applicationDate = format(new Date(branch.workdate), "yyyy-MM-dd");

  // const [data, setData] = useState({
  //   startDate: applicationDate,
  //   endDate: "",
  // });
  const [principalOfficers, setPrincipalOfficers] = useState(null);
  // const [errors, setErrors] = useState({});
   const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerid",
      label: "Customer id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountnumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "firstname",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "middlename",
      label: "Middle Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastname",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dateofbirth",
      label: "Date Of Birth",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "passport_No",
      label: "Passport No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lga",
      label: "LGA",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nationalID",
      label: "National ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "drivers_License_No",
      label: "Drivers License No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bvn",
      label: "Bvn",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phonenumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "positionInBusiness",
      label: "Position In Business",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  // const handleChange = (name) => (e) => {
  //   const { value } = e.target;
  //   setErrors((prev) => ({
  //     ...prev,
  //     [name]: undefined,
  //   }));
  //   setData({
  //     ...data,
  //     [name]: value,
  //   });
  // };
  // const resetHandlers = () => {
  //   EmptyField();
  //   setPrincipalOfficers(false);
  // };

  // const EmptyField = () => {
  //   setData({
  //     startDate: "",
  //     endDate: "",
  //   });
  // };

  // const handleSearch = () => {
  //   const checkErrors = validateData();
  //   if (checkErrors || loading) {
  //     fetchPrincipalOfficersReports();
  //     console.log("i am livid");
  //   }
  //   console.log("i am believer");
  // };

  // const validateData = () => {
  //   const error = {};
  //   let errorCount = 0;
  //   const { startDate, endDate } = data;
  //   if (startDate === "") {
  //     error.startDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }
  //   if (endDate === "") {
  //     error.endDate = "Field is required";
  //     errorCount = errorCount + 1;
  //   }

  //   setErrors(error);
  //   if (errorCount) {
  //     return false;
  //   }
  //   return true;
  // };
  const fetchPrincipalOfficersReports = useCallback(async () => {
    dispatch(isLoading());
    const response = await getCbrReports(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.principals.length ) {
        for (let i = 0; i < response.data.principals.length; i++) {
          response.data.principals[i].sn = i + 1;
        }
      }
      setPrincipalOfficers(response.data.principals);
    }
  }, [ token, dispatch]);
  useEffect(() => {
    fetchPrincipalOfficersReports();
  }, [fetchPrincipalOfficersReports]);

  return (
    <div className="principalofficers border--main">
      <div style={{ position: "absolute" }}>
        <Spinner loading={loading} />
      </div>
      <div className="headerdiv">
        <p> Reports | Principal Officers</p>
      </div>
      {/* <main className="section--1">
        <div className="sub-main flex--1">
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="From"
              value={data.startDate}
              onChange={handleChange("startDate")}
            ></input>
            {errors.startDate && <ErrorMessage message={errors.startDate} />}
          </div>
          <div className=" sub-main--1 flex--3">
            <label htmlFor="">End Date</label>
            <input
              name="To"
              type="date"
              value={data.endDate}
              onChange={handleChange("endDate")}
            ></input>
            {errors.endDate && <ErrorMessage message={errors.endDate} />}
          </div>
        </div> */}
{/* 
        <div className="button--1 flex--1">
          <button onClick={handleSearch}>Search</button>
          <button onClick={resetHandlers}>Reset</button>
        </div>
        <hr /> */}
        {principalOfficers && (
          <div>
            <Spinner loading={loading} />
            <Table
              title="Principal Officer's"
              subtitle=""
              name="List Of Principal Officer's"
              records={principalOfficers}
              options={options}
              columns={columns}
            />
          </div>
        )}
      {/* </main> */}
    </div>
  );
};

export default PrincipalOfficers;

import { useState, useEffect } from "react";
import "./BulkUploadAuthorization.scss";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import BulkTable from "../../../DatagridTable/table";
import Details from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import Item from "../../../DatagridTable/item";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
  openFormModal
} from "../../../../Api/redux-manager/actions";
import {
  getPendingBranchTransactions,
} from '../../../../Api/Hooks-manager/authorizeTransaction/authorizeTransaction';
import { Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 46,
    textTransform: "none",
    padding: '0 0.5rem',
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
}))

const BulkSubTable = ({data, onRowClick}) => {

  return (
    <>
      <tr>
        <td colSpan={12} style={{padding: '0 1rem'}}>
          <TableContainer component={Paper}>
            <Table style={{minWidth: 650}} aria-label="Bulk table">
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Debit</TableCell>
                  <TableCell>Credit</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Reference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow hover style={{cursor: 'pointer'}} key={row.referenceNumber} onClick={() => onRowClick(row)}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell>{row.debitAccount}</TableCell>
                    <TableCell>{row.creditAccount}</TableCell>
                    <TableCell>{row.currencyCode}</TableCell>
                    <TableCell>{row.postAmount}</TableCell>
                    <TableCell>{row.referenceNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </td>
      </tr>
    </>
  )
}

const BulkUploadAuthorization = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const classes = useStyles()

  const onRowClick = (rowData) => {
    if (rowData && rowData.id) {
      setDetails(rowData);
      dispatch(openDetailsModal());
    }
  }

  const bulkIdList = _.uniqBy(data, 'bulkId').map((row, index) => ({...row, sn: index +1}))
  
  const dataGroupedByBulkId = _.mapValues(_.groupBy(data, 'bulkId'))

  const authorizeRecord = (bulkId, referenceNumber) =>{
    setDetails(items => ({...items, bulkId, referenceNumber}))
    dispatch(openFormModal())
  }

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bulkId",
      label: "Bulk Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "transactionDate",
      label: "Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorised",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === true
                  ? "green"
                  : status === false
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status ? 'Authorised' : 'Not Authorized'}
          </p>
        ),
      },
    },
    {
      name: "bulkId",
      label: " ",
      options: {
        customBodyRender: (bulkId, {rowIndex}) => (
          <Button onClick={() => authorizeRecord(bulkId, bulkIdList[rowIndex].referenceNumber)} className={classes.button}>
            Authorize
          </Button>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: 'none',
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (<BulkSubTable data={dataGroupedByBulkId[rowData[1]]} onRowClick={onRowClick} />)
    },
  };

  const fetchAllTransaction = async () => {
    dispatch(loading());
    const allResponse = await getPendingBranchTransactions(token, data);
    dispatch(loading());
    console.log('...transactions..', allResponse);
    const response = allResponse.data.filter(
      (item) =>item.bulkType === "MDMC" && item.transactionStatus === "Pending"
    );

    console.log(response, "MDMC transactions");
    if (response) {
    if (response.length > 0){
      for (let i = 0; i < response.length; i++) {
        response[i].sn = i + 1;
        response[i].createdDateTime = formattedDate(response[i].createdDateTime);
        response[i].dateAuthorized = formattedDate(response[i].dateAuthorized);
        response[i].updatedDateTime = formattedDate(response[i].updatedDateTime);
        response[i].transactionDate = formattedDate(response[i].transactionDate);
      };
    }
      setData(response);
    }
  };

  useEffect(() => {
    fetchAllTransaction();
  }, [updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      <BulkTable
        title="Bulk Upload Authorization"
        subtitle=""
        name="List of Bulk Authorization"
        records={bulkIdList}
        options={options}
        columns={columns}
      />
      <Details
        title="Bulk Authorization - Record Details"
        noAuthorize
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Bulk ID" name={details.bulkId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Debit Account" name={details.debitAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Credit Account" name={details.creditAccount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Currency" name={details.currencyCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Transaction Amount" name={details.postAmount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Transaction Date" name={details.transactionDate} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Reference" name={details.referenceNumber} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Bulk ID" name={details.bulkId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid> */}
        </Grid>
      </Details>
      <AuthorizationModal
        title="Authorize Bulk Transaction"
        question="Are you sure you want to authorize this Transaction?"
        url={`${Routes.authorizeRemoteTransaction}`}
        bulkId={details.bulkId}
        referenceNumber={details.referenceNumber}
        method='post'
      />
    </div>
  );
};

export default BulkUploadAuthorization;

import { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import Item from "../../../DatagridTable/item";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";

const DealPosting = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Acc.Num.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portfolioCode",
      label: "Port.Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "portfolioType",
      label: "Port.Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dealReference",
      label: "Deal Ref.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tradeDate",
      label: "Trade Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "externalReferenceNo",
      label: "Ext.Ref.No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dealQuantity",
      label: "Deal Qty.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllDealPosting = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}treasury/DealPosting/GetAll`;
    const allResponse = await getHandler(token, url);
    dispatch(loading());
    const response = allResponse.data.filter(
      (item) =>
        item.authorizationStatus === "Pending" ||
        item.authorizationStatus === "PendingEditedAuthorization"
    );
    if (response.length > 0){
      for (let i = 0; i < response.length; i++) {
        response[i].sn = i + 1;
        response[i].maturityDate = formattedDate(response[i].maturityDate);
        response[i].tradeDate = formattedDate(response[i].tradeDate);
        response[i].createdDateTime = formattedDate(response[i].createdDateTime);
        response[i].dateAuthorized = formattedDate(response[i].dateAuthorized);
        response[i].updatedDateTime = formattedDate(response[i].updatedDateTime);
      };
      setData(response);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllDealPosting();
  }, [fetchAllDealPosting,updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Deal Posting"
        subtitle=""
        name="List of Deals"
        records={data}
        options={options}
        columns={columns}
      />}
      <Details
        title="Deal Postings - Record Details"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Account Number" name={details.accountNumber} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="portfolio Codet" name={details.portfolioCode} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Portfolio Type" name={details.portfolioType} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Trade Date" name={details.tradeDate} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Maturity Date" name={details.maturityDate} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="External Reference No" name={details.externalReferenceNo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Deal Quantity" name={details.dealQuantity} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Deal Reference" name={details.dealReference} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Create Date Time" name={details.createdDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Updated Date Time" name={details.updatedDateTime} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Date Authorized" name={details.dateAuthorized}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
          </Grid>
        </Grid>
      </Details>
      <AuthorizationModal
        title="Authorize deal"
        question="Are you sure you want to authorize this deal?"
        url={`${baseUrl}treasury/DealPosting/Authorize/${details.id}`}
      />
    </div>
  );
};

export default DealPosting;

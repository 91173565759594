import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from "../../../../Api/configUrl";
import Item from "../../../DatagridTable/item";
import PermissionTable from "../../../DatagridTable/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.main,
  },
  hr: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  noData: {
    flexDirection: "row",
  },
  item: {
    width: "20%",
  },
  item1: {
    width: "100%",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
    textTransform: "none",
  },
  isSelected: {
    color: "#fff",
    backgroundColor: theme.palette.success.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "& label": {
      color: "red",
    },
  },
}));

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
  onClick,
  errors,
  addPermissionsToRole,
  showEditPermissionModal,
  correctWayOfAddingList,
  setShowEditPermissionModal,
}) => {
  const classes = useStyles();
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "parameter",
      label: "Permissions",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Selector",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const permission = correctWayOfAddingList[dataIndex];
          return (
            <Button
              onClick={() => addPermissionsToRole(permission.id)}
              className={
                permission.isSelected ? classes.isSelected : classes.button
              }
             // disabled={permission.isInDatabase}
            >
              {permission.isSelected ? "Selected" : "Select"}
            </Button>
          );
          // <button
          //   disabled={permission.isInDatabase}
          //   className={`button ${permission.isSelected ? "button--2" : ""}`}
          //   //onClick={() => addPermissionsToRole(permission.id)}
          // >
          //   {permission.isSelected ? "Selected" : "Select"}
          // </button>
        },
      },
    },
  ];
  const payload = {
    roleId: details.id,
    name: details.name,
    description: details.description,
    rolePermissions: details.rolePermissions,
  };
  return (
    <EditModal
      title="Update Roles"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this role?"
      data={payload}
      url={`${baseUrl}user/Role/UpdateRoleWithPermission`}
      method="post"
    >
      <Grid container spacing={1} className="institution--definition">
        <Grid item xs={12} sm={6} md={6}>
          <label>Role Name</label>
          <input
            type="text"
            value={details.name}
            name="name"
            onChange={handleEditChange("name")}
            className={`${errorEdit.userName && "error-input-z"}`}
          />
          {errorEdit.name && <ErrorMessage message={errorEdit.name} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Description</label>
          <input
            type="text"
            value={details.description}
            onChange={handleEditChange("description")}
          />
          {errorEdit.description && (
            <ErrorMessage message={errorEdit.description} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button onClick={onClick} className={classes.button}>
            Select permissions
          </Button>
        </Grid>
        {details &&
        details.permissionArray &&
        details.permissionArray.length > 0 ? (
          details.permissionArray.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
              <Item label="" name={item.parameter} className={classes.item1} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={classes.noData}>
            <Item
              label="Role permissions"
              name="No permmision"
              className={classes.item}
            />
          </Grid>
        )}
      </Grid>
      <PermissionTable
        data={correctWayOfAddingList}
        open={showEditPermissionModal}
        onClose={() => setShowEditPermissionModal(false)}
        options={options}
        columns={columns}
      />
    </EditModal>
  );
};

export default EditData;

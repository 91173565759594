import { useState, useEffect, useCallback } from "react";
import "./UserTellerTills.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import { formattedDate } from "../../../../formatter/date";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
// import {fetchAllTills} from "../BranchTellerTills/BranchTellerTills"

import { getAllTellerTill } from "../../../../Api/Hooks-manager/securitymanagement/tellerTills";
import { getAllUsers } from "../../../../Api/Hooks-manager/securitymanagement/userCreation";
import {
  getAllUserTellerTill,
  createUserTellerTill,
} from "../../../../Api/Hooks-manager/securitymanagement/userTellerTill";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop";
import ErrorMessage from "../../../ErrorMessage";
import SearchDropdown from "../../../SearchableDropdown/Search";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import EditForm from "./edit";
import Details from "./view";
import NumberFormat from "react-number-format";


const UserTellerTills = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.approve.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});

  const [message, setMessage] = useState("");
  const [errorEdit, setErrorEdit] = useState({});
  const [allUserTellerTills, setAllUserTellerTills] = useState([]);

  const [allTellerTills, setAllTellerTills] = useState([]);
  const [allUserId, setAllUserId] = useState([]);
  const [tellerTillId, setTellerTillId] = useState([]);
  const [data, setData] = useState({
    tillId: "",
    branchId: "",
    userId: "",
    userTillLimit: 0,
    userCrCashLimit: 0,
    userDrCashLimit: 0,
  });

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tillId",
      label: "Till Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchId",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "userId",
      label: "User ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "userTillLimit",
      label: "Till Limit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "userCrCashLimit",
      label: "Cr Cash Limit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "userDrCashLimit",
      label: "Dr Cash Limit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allUserTellerTills[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = allUserTellerTills[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const fetchAllTellerTills = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllTellerTill(token);
    console.log(response.data, "i'm in.....")
    if (response && response.data) {
      dispatch(isLoading());
      setAllTellerTills(response.data);
      setTellerTillId(response.data)
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllTellerTills();
  }, [fetchAllTellerTills]);

  useEffect(() => {
    if (data.tillId) {
      allTellerTills.forEach(item => {
        if (item.tillId === data.tillId) {
          setData(data => ({ ...data, branchId: item.branchId }));
        }
      });
    }
  }, [data.tillId, allTellerTills]);

  const fetchAllUserTellerTills = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllUserTellerTill(token);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setAllUserTellerTills(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllUserTellerTills();
  }, [fetchAllUserTellerTills, updateTable]);

  const fetchAllUserId = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllUsers(token);
    if (response && response.data) {
      dispatch(isLoading());
      setAllUserId(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllUserId();
  }, [fetchAllUserId]);

  const handleChangeSelect = (value, type) => {
    if (type === "tillId") {
      setData(data => ({ ...data, tillId: value }));
    }

    if (type === "userId") {
      setData(data => ({ ...data, userId: value }));
    }
  };

  const allMappedUserId = allUserId.map(item => {
    return {
      value: item.userName,
      label: item.userName,
    };
  });
  const allMappedTillId = tellerTillId.map(item => {
    return {
      value: item.tillId,
      label: item.tillId,
    };
  });

  const isValidateForm = () => {
    let errors = {};
    if (!data.tillId.trim()) {
      errors.tillId = "Field is Required";
    }
    if (!data.userId.trim() === "") {
      errors.userId = "Field is Required";
    }
    if (!data.userTillLimit) {
      errors.userTillLimit = "Field is Required";
    }
    if (!data.userCrCashLimit) {
      errors.userCrCashLimit = "Field is Required";
    }
    if (!data.userDrCashLimit) {
      errors.userDrCashLimit = "Field is Required";
    }
    return errors;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSave = () => {
    let checkError = isValidateForm(data);
    setError(checkError);
    if (Object.keys(isValidateForm(data)).length > 0) return;
    setOpen(true);
  };

  const EmptyFieldTellerTills = () => {
    setData({
      tillId: "",
      branchId: "",
      userId: "",
      userTillLimit: 0,
      userCrCashLimit: 0,
      userDrCashLimit: 0,
    });
  };

  const postUserTellerTills = async () => {
    dispatch(isLoading());
    const response = await createUserTellerTill(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      setMessage(response.message);
      if (response.message === "Success") {
        EmptyFieldTellerTills();
        fetchAllUserTellerTills();
        return true;
      } else {
        return false;
      }
    }
  };

  const handleEditChange = name => e => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleEditSubmit = () => {
    const checkError = validateEditTellerTills();
    setErrorEdit(checkError);
    if (Object.keys(checkError).length > 0) return;
    setErrorEdit({});
    dispatch(openResquestDecisionModal());
  };
  const validateEditTellerTills = () => {
    const { tillId, userId, userTillLimit, userCrCashLimit, userDrCashLimit } =
      details;

    const error = {};

    if (userId === "") {
      error.userId = "Field is required";
    }
    if (userTillLimit === "") {
      error.userTillLimit = "Select an option";
    }
    if (tillId === "") {
      error.tillId = "Select an option";
    }
    if (userCrCashLimit === "") {
      error.userCrCashLimit = "Field is required";
    }
    if (userDrCashLimit === "") {
      error.userDrCashLimit = "Field is required";
    }

    return error;
  };

  return (
    <main>
      <div className="userteller--tills">
        <Spinner loading={loading} />
        <Confirm
          ApiCall={postUserTellerTills}
          //message='Are you sure you want to delete this record?'
          openBox={open}
          setOpenBoxState={setOpen}
          actionMsg={message}
          errorMessage={message}
        />
        <div className="headerdiv">
          <p>User Teller Till Linkage</p>
        </div>
        <Paper style={{ marginTop: 20, padding: 25 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <label>Till ID</label>
              <div className={`border--norm ${Object.keys(error).includes('tillId') && 'error-input-z'}`}>
              {/* <input
                type="number"
                name="tillId"
                value={data.tillId}
                onChange={handleChange}
                className={`${error.tillId && "error-input-z"}`}
              /> */}
                 <SearchDropdown
                  options={allMappedTillId}
                  onChange={handleChangeSelect}
                  type="tillId"
                  
                />
              </div>
              {error.tillId && <ErrorMessage message={error.tillId} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Branch</label>
              <input disabled value={data.branchId} type="text" />
              {error.branchId && <ErrorMessage message={error.branchId} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>User ID</label>
              <div
                className={`border--norm ${
                  Object.keys(error).includes("userId") && "error-input-z"
                }`}
              >
                <SearchDropdown
                  options={allMappedUserId}
                  onChange={handleChangeSelect}
                  type="userId"
                />
              </div>
              {error.userId && <ErrorMessage message={error.userId} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>User Till Limit</label>
                <NumberFormat
                value={data.userTillLimit}
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="userTillLimit"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                onChange={handleChange}
                className={`${error.userTillLimit && "error-input-z"}`}
                />
              {/* <input
                type="text"
                name="userTillLimit"
                value={data.userTillLimit}
                onChange={handleChange}
                className={`${error.userTillLimit && "error-input-z"}`}
              /> */}
              {error.userTillLimit && (
                <ErrorMessage message={error.userTillLimit} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>User CR Cash Limit</label>
              <NumberFormat
                value={data.userCrCashLimit}
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="userCrCashLimit"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                onChange={handleChange}
                className={`${error.userCrCashLimit && "error-input-z"}`}
                />
              {/* <input
                type="text"
                name="userCrCashLimit"
                value={data.userCrCashLimit}
                onChange={handleChange}
                className={`${error.userCrCashLimit && "error-input-z"}`}
              /> */}
              {error.userCrCashLimit && (
                <ErrorMessage message={error.userCrCashLimit} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>User DR Cash Limit</label>
              <NumberFormat
                value={data.userDrCashLimit}
                thousandsGroupStyle="thousand"
                thousandSeparator={true}
                inputMode="numeric"
                name="userDrCashLimit"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                onChange={handleChange}
                className={`${error.userDrCashLimit && "error-input-z"}`}
                />
              {/* <input
                type="text"
                name="userDrCashLimit"
                value={data.userDrCashLimit}
                onChange={handleChange}
                className={`${error.userDrCashLimit && "error-input-z"}`}
              /> */}
              {error.userDrCashLimit && (
                <ErrorMessage message={error.userDrCashLimit} />
              )}
            </Grid>
          </Grid>
          <button className="save" onClick={handleSave}>
            Save
          </button>

          {/* Table comes in here */}
        </Paper>
      </div>
      {!loading && (
        <Table
          title="User Teller Tills"
          subtitle=""
          name="List of User Teller Tills"
          records={allUserTellerTills}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorEdit}
          handleEditSubmit={handleEditSubmit}
          handleEditChange={handleEditChange}
        />
      )}
    </main>
  );
};
export default UserTellerTills;

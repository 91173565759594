import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    bgText:{
        backgroundColor:'#D3BCC0',
        padding:'.5rem',
        color:'#fff',
        borderRadius:'5px',
        textTransform:'capitalize',
        width:'100%'
    },
    noLabel:{
        width:'90%'
    }
  }));
  

const Item = ({label, name, className}) => {
    const classes = useStyles();
    return (
        <div>
            <label>{label}</label>
            <div className={clsx(classes.bgText, !label && classes.noLabel,className )}>
            <p>{name}</p>
            </div>
        </div>
    )
}

export default Item

const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Account_Type",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Account_No",
      label: "Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountCurrency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Debit_Or_Credit",
      label: "Operation",
      options: {
        customBodyRender: (text) => (
          <p
            style={{
              color:
                text === "cr"
                  ? "green"
                  : 'red'
            }}
          >
            {text}
          </p>
        ),
      },
    },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Narration",
      label: "Narration",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  
  export default columns;
  
import React from 'react'
import './ChequeDeposit.scss'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
 
const ChequeDeposit = () => {
    return (
        <div className='chequedeposit'>
             <div className="headerdiv">
        <p>Cheque Deposit</p>
      </div>
      <Paper style={{ marginTop: 20, padding: 25, boxShadow:'2px 2px 2px 2px white-smoke'}}>
          <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Beneficiary Account Number</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Account Name</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Cheque Account Number</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Cheque Account Name</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Check Amount</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Cheque Number</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Clearing Type</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Posting Date</label>
            <br />
            <input type="text"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>External Ref No.</label>
            <br />
            <input type="number"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='grid--width'>
              <label>Routing Number</label>
            <br />
            <input type="number"/>
              </Grid>
          </Grid>
          <div className="savebtn">
          <button className="save">Save</button>
          <button className="cancel">Cancel</button>
          </div>
      </Paper>
      <div>asdfghjhgfd</div>
        </div>
    )
}

export default ChequeDeposit;

import { useState, useEffect } from "react";
import Item from "../../../DatagridTable/item";
import { Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
    root: {},
    text: {
      margin: "auto",
      marginTop: "1rem",
      color: theme.palette.secondary.main,
    },
    hr: {
      backgroundColor: "black",
      width: "100%",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    table: {
      width: "100%",
      "& th": {
        paddingTop: 12,
        paddingBottom: 12,
        textAlign: "left",
      },
      "& td": {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    noData:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"row",
    },
    item:{
      width:'100%',
      border: "1px solid black",
      marginBottom: 30,
      paddingTop: 25,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    }
  }));
  
const AccountDetails = ({details}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    useEffect(() => {
      setValue(0);
    }, [details]);

    return (
      <section className={classes.root}>
        <div style={{ marginTop: 50, padding: 25 }}>
          <>
            <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Account Intructions"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Account Mandates"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Instruction-1" name={details.accountInstruction1} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Instruction-2" name={details.accountInstruction2} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Instruction-3" name={details.accountInstruction3} />
                </Grid>
                
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={2} className={classes.root}>
                
              </Grid>
            </TabPanel>

            </div>
          </>
        </div>
      </section> 

    )
}

export default AccountDetails

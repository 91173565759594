import {
  isValidEmail,
  isValidPhoneNumber,
  isValidBvn,
  // isValidTin,
  isValidIntPassport,
  isValidDriversLicense,
  isValidVotersId,
  isValidNin,
  isValidTinNumber,
  isValidRcNumber,
  isValidRcNumberNum,
  isValidRcNumberAlpha,
  isValidDriversLicenseNum,
  isValidDriversLicenseAlpha,
  isValidVotersIdNum,
  isValidVotersIdAlpha,
  isValidIntPassportNum,
  isValidIntPassportAlpha,
  isValidLienCodeAlpha,
  isValidReferralCode,
} from "../../Helper/validator";

export function validateLogin(values) {
  let errors = {};

  if (!values.emailOrUserName.trim()) {
    errors.emailOrUserName = "Username is required";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
}

export function validateLienCode(values) {
  let errors = {};

  if (isValidLienCodeAlpha(values.lienCode)) {
    errors.lienCode = "Field should be alphanumeric";
  }

  if (values.lienCode.length !== 4) {
    errors.lienCode = "Lien code must have 4 characters";
  }
  if (values.lienCode === 4) {
    errors.lienCode = "Field is required";
  }
}

export function validateChartOfAccount(values) {
  let errors = {};

  if (!values.accountNumber.trim() || values.accountNumber.length !== 9) {
    errors.accountNumber = "Field needs to be 9 characters";
  } else if (values.accountNumber.includes(" ")) {
    errors.accountNumber = "No spaces are allowed";
  }

  if (!values.accountName.trim()) {
    errors.accountName = "Field is required";
  }

  if (values.productAccount === "") {
    errors.productAccount = "Select an option";
  }

  if (values.glHierarchy !== "Head") {
    if (values.parentGLID === "") {
      errors.parentGLID = "Select an option";
    }
  }

  if (values.glType === "") {
    errors.glType = "Select an option";
  }

  if (values.glHierarchy === "") {
    errors.glHierarchy = "Select an option";
  }

  if (values.isGLActive === "") {
    errors.isGLActive = "Select an option";
  }

  if (!values.entityType.trim()) {
    errors.entityType = "Select an option";
  }

  if (!values.branchCode.trim()) {
    errors.branchCode = "Select an option";
  }

  return errors;
}

export function validateCurrencyDef(values) {
  let errors = {};
  console.log(values, "currvaluesvalues");

  if (values.numericCode.toString().length < 3) {
    errors.numericCode = "Field cannot be less than 3 characters";
  }
  if (!values.currencyName.trim()) {
    errors.currencyName = "Field is required";
  } else if (values.currencyName.includes(" ")) {
    errors.currencyName = "No spaces are allowed";
  }

  if (!values.currencyCode.trim()) {
    errors.currencyCode = "Field is required";
  } else if (values.currencyCode.includes(" ")) {
    errors.currencyCode = "No spaces are allowed";
  }
  return errors;
}

export function validatePeriodDef(values) {
  let errors = {};

  if (!values.fiscalYear.trim()) {
    errors.fiscalYear = "Select an option";
  }

  if (!values.periodCode.trim()) {
    errors.periodCode = "Field is required";
  }

  if (!values.startdate.trim()) {
    errors.startdate = "Field is required";
  }

  if (!values.enddate.trim()) {
    errors.enddate = "Field is required";
  }

  return errors;
}

export function validateParameterSettings(values) {
  let errors = {};

  if (!values.parameterName.trim()) {
    errors.parameterName = "Field is required";
  }

  if (!values.parameterValue.trim()) {
    errors.parameterValue = "Field is required";
  }

  if (values.parameterLength === "") {
    errors.parameterLength = "Field is required";
  }

  return errors;
}

export function validateAccoutProductPage1(values) {
  let errors = {};

  if (!values.productCode.trim()) {
    errors.productCode = "Field is required";
  }

  if (values.productCode.length !== 6) {
    errors.productCode = "Field must be 6 characters";
  }

  if (!values.productName.trim()) {
    errors.productName = "Field is required";
  }
  if (!values.currency.trim()) {
    errors.currency = "Select an option";
  }

  if (!values.accountType.trim()) {
    errors.accountType = "Select an option";
  }
  if (!values.creditAccount.trim()) {
    errors.creditAccount = "Select an option";
  }
  if (!values.debitAccount) {
    errors.debitAccount = "Select an option";
  }
  if (!values.interestPayableAccount) {
    errors.interestPayableAccount = "Select an option";
  }
  if (!values.interestReceivableAccount.trim()) {
    errors.interestReceivableAccount = "Select an option";
  }
  if (!values.interestIncome.trim()) {
    errors.interestIncome = "Select an option";
  }
  if (!values.interestExpense.trim()) {
    errors.interestExpense = "Select an option";
  }

  if (!values.accountReactivationType.trim()) {
    errors.accountReactivationType = "Select an option";
  }

  if (!values.reactivationTrigger.trim()) {
    errors.reactivationTrigger = "Field is required";
  }

  if (values.expectedMinBalance === "") {
    errors.expectedMinBalance = "Field is required";
  }

  if (values.noOfDaysToActivateInactivity === "") {
    errors.noOfDaysToActivateInactivity = "Field is required";
  }
  if (values.noOfInactiveDormantDays === "") {
    errors.noOfInactiveDormantDays = "Field is required";
  }

  // if()

  // if (!values.fiscalYear.trim()) {
  //   errors.fiscalYear = 'Select an option';
  // }

  // if (!values.periodCode.trim()) {
  //   errors.periodCode = 'Field is required';
  // }

  // if (!values.startdate.trim()) {
  //   errors.startdate = 'Field is required';
  // }

  // if (!values.enddate.trim()) {
  //   errors.enddate = 'Field is required';
  // }

  return errors;
}

export function validateAccoutProductPage2(values) {
  let errors = {};

  if (!values.debitRateBasis.trim()) {
    errors.debitRateBasis = "Select an option";
  }

  if (!values.debitInterestPeriod.trim()) {
    errors.debitInterestPeriod = "Select an option";
  }
  if (!values.debitBalanceBasis.trim()) {
    errors.debitBalanceBasis = "Select an option";
  }
  if (!values.debitInterestCalculationPeriod.trim()) {
    errors.debitInterestCalculationPeriod = "Select an option";
  }
  if (!values.debitAccrueInterest.trim()) {
    errors.debitAccrueInterest = "Select an option";
  }
  if (!values.creditRateBasis.trim()) {
    errors.creditRateBasis = "Select an option";
  }
  if (!values.creditInterestPeriod.trim()) {
    errors.creditInterestPeriod = "Select an option";
  }
  if (!values.creditBalanceBasis.trim()) {
    errors.creditBalanceBasis = "Select an option";
  }
  if (!values.debitInterestVATRate.trim()) {
    errors.debitInterestVATRate = "Select an option";
  }
  if (!values.creditInterestWHTRate.trim()) {
    errors.creditInterestWHTRate = "Field is required";
  }
  if (!values.creditInterestCalculationPeriod.trim()) {
    errors.creditInterestCalculationPeriod = "Select an option";
  }
  if (!values.creditAccrueInterest.trim()) {
    errors.creditAccrueInterest = "Select an option";
  }
  if (values.balanceRequestCreditInterest === "") {
    errors.balanceRequestCreditInterest = "Field is required";
  }
  if (values.debitInterestRate === "") {
    errors.debitInterestRate = "Field is required";
  }
  if (values.creditInterestRate === "") {
    errors.creditInterestRate = "Field is required";
  }
  // if (values.vatAccountNo === '') {
  //   errors.vatAccountNo = 'Field is required';
  // }
  // if (values.whtAccountNo === '') {
  //   errors.whtAccountNo = 'Field is required';
  // }
  if (!values.interestLiquidationPeriod.trim()) {
    errors.interestLiquidationPeriod = "Select an option";
  }

  return errors;
}

export function validateAccountProductPage3(values) {
  let errors = {};

  if (!values.gateWayCreditAccount.trim()) {
    errors.gateWayCreditAccount = "Select an account";
  }

  if (!values.gateWayDebitAccount.trim()) {
    errors.gateWayDebitAccount = "Select an account";
  }

  if (!values.membershipFee) {
    errors.membershipFee = "This field required";
  }

  if (!values.deductions) {
    errors.deductions = "This field required";
  }

  if (!values.minimumContribution) {
    errors.minimumContribution = "This field required";
  }

  if (!values.tenureType.trim()) {
    errors.tenureType = "Select a tenure type";
  }

  if (!values.tenureForContribution) {
    errors.tenureForContribution = "This field is required";
  }

  if (!values.baseAllowableContribution) {
    errors.baseAllowableContribution = "This field is required";
  }

  errors.customerRestCustomerCat = [];
  errors.customerRestCustomerName = [];
  for (let i = 0; i < values.createCustomerRestriction.length; i++) {
    if (!values.createCustomerRestriction[i].customerCategory.trim()) {
      errors.customerRestCustomerCat.push({
        id: i,
        customerCategory: "Field is required",
      });
    }
    if (!values.createCustomerRestriction[i].customerName.trim()) {
      errors.customerRestCustomerName.push({
        id: i,
        customerName: "Field is required",
      });
    }
  }

  if (!errors.customerRestCustomerCat.length)
    delete errors.customerRestCustomerCat;
  if (!errors.customerRestCustomerName.length)
    delete errors.customerRestCustomerName;

  return errors;
}

export function validateInvestMentProductPage1(values) {
  let errors = {};

  if (!values.productCode.trim()) {
    errors.productCode = "Field is required";
  }

  if (!values.productName.trim()) {
    errors.productName = "Field is required";
  }

  if (values.currency === "") {
    errors.currency = "Field is required";
  }
  if (!values.tenorBasis.trim()) {
    errors.tenorBasis = "Field is required";
  }
  // if (!values.rateType.trim()) {
  //   errors.rateType = 'Field is required';
  // }
  if (values.rateType === "1") {
    if (!values.rateChatCode.trim()) {
      errors.rateChatCode = "Field is required";
    }
  }

  if (parseInt(values.defaultInvAmount) < parseInt(values.minimumInvAmount)) {
    errors.minimumInvAmount =
      "Minimum Amount cannot be greater than Default Amount";
  }

  if (parseInt(values.defaultInvAmount) > parseInt(values.maximumInvAmount)) {
    errors.defaultInvAmount =
      "Default Amount cannot be greater than Maximum Amount";
  }

  if (parseInt(values.minimumInvAmount) > parseInt(values.maximumInvAmount)) {
    errors.maximumInvAmount =
      "Maximum Amount cannot be less than Minimum Amount";
  }

  if (parseInt(values.defaultTenor) < parseInt(parseInt(values.minimumTenor))) {
    errors.minimumTenor = "Minimum Tenor cannot be greater than Default Tenor";
  }
  if (parseInt(values.defaultTenor) > parseInt(values.maximumTenor)) {
    errors.defaultTenor = "Default Tenor cannot be greater than Maximum Tenor";
  }

  if (parseInt(values.minimumTenor) > parseInt(values.maximumTenor)) {
    errors.maximumTenor = "Maximum Tenor cannot be less than Minimum Tenor";
  }

  if (parseInt(values.minimumVariance) > parseInt(values.maximumVariance)) {
    errors.maximumVariance =
      "Maximum Variance cannot be less than Minimum Variance";
  }

  if (values.rateType === "0") {
    if (!values.rateBasis.trim()) {
      errors.rateBasis = "Field is required";
    }

    if (
      parseInt(values.defaultInterestRate) <
      parseInt(values.minimumInterestRate)
    ) {
      errors.minimumInterestRate =
        "Minimum Rate cannot be greater than Default Rate";
    }
    if (
      parseInt(values.defaultInterestRate) >
      parseInt(values.maximumInterestRate)
    ) {
      errors.defaultInterestRate =
        "Default Rate cannot be greater than Maximum Rate";
    }

    if (
      parseInt(values.minimumInterestRate) >
      parseInt(values.maximumInterestRate)
    ) {
      errors.maximumInterestRate =
        "Maximum Rate cannot be less than Minimum Rate";
    }
  }

  // if (!values.creditAccount.trim()) {
  //   errors.creditAccount = 'Select an option';
  // }

  return errors;
}

export function validateInvestMentProductPage2(values) {
  let errors = {};

  if (!values.accrualPeriod.trim()) {
    errors.accrualPeriod = "Select an option";
  }

  if (!values.productAccount.trim()) {
    errors.productAccount = "Field is required";
  }
  if (!values.interestPayableAccount.trim()) {
    errors.interestPayableAccount = "Field is required";
  }

  if (!values.penalIncome.trim()) {
    errors.penalIncome = "Field is required";
  }

  if (!values.interestExpense.trim()) {
    errors.interestExpense = "Field is required";
  }
  if (!values.atMaturity.trim()) {
    errors.atMaturity = "Field is required";
  }
  if (!values.unclaimedInterestAccount.trim()) {
    errors.unclaimedInterestAccount = "Field is required";
  }
  if (!values.withHoldingTaxAccount.trim()) {
    errors.withHoldingTaxAccount = "Field is required";
  }
  if (!values.unClaimedInvestmentAccount.trim()) {
    errors.unClaimedInvestmentAccount = "Field is required";
  }

  if (!values.interestCalculationPeriod) {
    errors.interestCalculationPeriod = "Select an option";
  }
  if (!values.interestPeriod) {
    errors.interestPeriod = "Select an option";
  }

  return errors;
}
export function validateAccountOpening1(values) {
  let errors = {};

  if (!values.customerType.trim()) {
    errors.customerType = "Select an option";
  }
  if (
    values.customerType === "Individual" &&
    values.customerType !== "" &&
    values.customerType === "Joint"
  ) {
    if (values.title === "") {
      errors.title = "Select an option";
    }
    if (!values.firstName.trim()) {
      errors.firstName = "Field is required";
    }
    if (!values.jaName.trim()) {
      errors.jaName = "Field is required";
    }
    // if (!isValidEmail(values.email)) {
    //   errors.email = "Invalid Email";
    // }

    if (values.gender === "") {
      errors.gender = "Select an option";
    }
    if (!values.lastName.trim()) {
      errors.lastName = "Field is required";
    }
  }

  if (values.customerType !== "" && values.customerType === "Joint") {
    if (values.title === "") {
      errors.title = "Select an option";
    }
    if (!values.firstName.trim()) {
      errors.firstName = "Field is required";
    }
    // if (!values.middleName.trim()) {
    //   errors.middleName = 'Field is required';
    // }
    if (values.jaName === " ") {
      errors.jaName = "Field is required";
    }
    // if (!isValidEmail(values.email)) {
    //   errors.email = "Invalid Email";
    // }

    if (values.gender === "") {
      errors.gender = "Select an option";
    }
    if (!values.lastName.trim()) {
      errors.lastName = "Field is required";
    }
  }

  if (!isValidPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = "Invalid Phone Number";
  }
  if (!values.customerCategory.trim()) {
    errors.customerCategory = "Select an option";
  }
  if (!values.country.trim()) {
    errors.country = "Select an option";
  }
  if (!values.state.trim()) {
    errors.state = "Select an option";
  }
  if (!values.nationality.trim()) {
    errors.nationality = "Select an option";
  }
  if (!values.dateOfBirth.trim()) {
    errors.dateOfBirth = "Field is required";
  }
  if (!values.address.trim()) {
    errors.address = "Field is required";
  }
  if (!values.city.trim()) {
    errors.city = "Field is required";
  }
  if (!values.lga.trim()) {
    errors.lga = "Field is required";
  }

  if (
    values.customerType !== "Individual" &&
    values.customerType !== "Joint" &&
    values.customerType !== ""
  ) {
    if (!values.corporateName.trim()) {
      errors.corporateName = "Field is required";
    }
    if (!isValidRcNumber(values.rcNumber)) {
      errors.rcNumber = "Field should be 9 character long";
    }
    if (isValidRcNumberNum(values.rcNumber)) {
      errors.rcNumber = "Field should be alphanumeric";
    }
    if (isValidRcNumberAlpha(values.rcNumber)) {
      errors.rcNumber = "Field should be alphanumeric";
    }

    if (!isValidTinNumber(values.tin)) {
      errors.tin = "Field should be 14 character long";
    }

    // if (!values.tin.trim()) {
    //   errors.tin = 'Field is required';
    // }

    // if (!isValidEmail(values.email)) {
    //   errors.email = "Invalid Email";
    // }
  }

  // if (!values.atMaturity.trim()) {
  //   errors.atMaturity = 'Field is required';
  // }
  console.table(errors);
  return errors;
}

export function validateAccountOpening2(values) {
  let errors = {};

  if (
    !values.identificationType.trim() &&
    values.customerType === "Individual"
  ) {
    errors.identificationType = "Select an option";
  }
  //   if (values.identificationType === 'International Passport') {
  //    if (!isValidIntPassport (values.identificationNumber)) {
  //     errors.identificationNumber = 'Invalid ID';
  //   }
  // }

  if (
    values.identificationType === "International Passport" &&
    !isValidIntPassport(values.identificationNumber)
  ) {
    errors.identificationNumber = "Field should be 9 character long";
  }
  if (
    (values.identificationType === "International Passport" &&
      isValidIntPassportNum(values.identificationNumber)) ||
    isValidIntPassportAlpha(values.identificationNumber)
  ) {
    errors.identificationNumber = "Field should be alphanumeric";
  }
  if (
    values.identificationType === "Drivers License" &&
    !isValidDriversLicense(values.identificationNumber)
  ) {
    errors.identificationNumber = "Field should be 12 character long";
  }
  if (
    (values.identificationType === "Drivers License" &&
      isValidDriversLicenseNum(values.identificationNumber)) ||
    isValidDriversLicenseAlpha(values.identificationNumber)
  ) {
    errors.identificationNumber = "Field should be alphanumeric";
  }
  if (
    values.identificationType === "Voters Card" &&
    !isValidVotersId(values.identificationNumber.replace(/ /g, ""))
  ) {
    errors.identificationNumber = "Field should be 19 character long";
  }
  if (
    (values.identificationType === "Voters Card" &&
      isValidVotersIdNum(values.identificationNumber.replace(/ /g, ""))) ||
    isValidVotersIdAlpha(values.identificationNumber.replace(/ /g, ""))
  ) {
    errors.identificationNumber = "Field should be alphanumeric";
  }
  if (
    values.identificationType === "National ID Card" &&
    !isValidNin(values.identificationNumber)
  ) {
    errors.identificationNumber = "Field should be 11 character long";
  }
  if (
    !values.identificationNumber.trim() &&
    values.customerType === "Individual"
  ) {
    errors.identificationNumber = "Field is required";
  }
  console.log("passport", values.passport);
  if (
    (values.passport === "" && values.customerType === "Individual") ||
    (values.passport === "" && values.customerType === "Corporate")
  ) {
    errors.passport = "Field is required";
  }
  if (
    (values.signature === "" && values.customerType === "Individual") ||
    (values.signature === "" && values.customerType === "Corporate")
  ) {
    errors.signature = "Field is required";
  }

  if (!values.kyc.trim() && values.customerType === "Individual") {
    errors.kyc = "Field is required";
  }
  if (!isValidBvn(values.bvn) && values.customerType === 'Individual') {
    errors.bvn = 'Field should be 11 character long';
  }
  if (!isValidTinNumber(values.tin) && values.customerType !== 'Individual') {
    errors.TIN = 'Field should be 14 character long';
  }

  console.table(errors);

  return errors;
}

export function validateAccountOpening3(values) {
  let errors = {};

  if (values.nextOfKin.firstName === "") {
    errors.firstName = "Field is required";
  }

  if (values.nextOfKin.lastName === "") {
    errors.lastName = "Field is required";
  }

  if (values.nextOfKin.relationship === "") {
    errors.relationship = "Field is required";
  }
  // if (values.nextOfKin.dateOfBirth === '') {
  //   errors.dateOfBirth = 'Field is required';
  // }
  // if (values.nextOfKin.phoneNumber === '') {
  //   errors.phoneNumber = 'Field is required';
  // }

  //   if (values.nextOfKin.profession === '') {
  //   errors.profession = 'Field is required';
  // }
  if (values.nextOfKin.address === "") {
    errors.address = "Select An Option";
  }
  if (values.nextOfKin.relationship === "") {
    errors.relationship = "Select An Option";
  }
  if (values.nextOfKin.gender === "") {
    errors.gender = "Select An Option";
  }
  if (values.nextOfKin.maritalStatus === "") {
    errors.maritalStatus = "Select An Option";
  }
  if (values.nextOfKin.nationality === "") {
    errors.nationality = "Select An Option";
  }

  return errors;
}
export function validateAccountOpening4(values) {
  let errors = {};

  if (values.customerAccount[0].accountProductCode === "") {
    errors.accountProductCode = "Select An Option";
  }
  if (values.customerAccount[0].accountName === "") {
    errors.accountName = "Field is required";
  }
  // if (!isValidReferralCode(values.referralCode) && values.customerType === 'Individual'){
  //   errors.referralCode = "Field must be 7 character long"
  // }

  return errors;
}

export function validateAccountOpening5(values) {
  const errors = {
    title: [],
    firstName: [],
    lastName: [],
    phonenumber: [],
    email: [],
    gender: [],
    country: [],
    state: [],
    nationality: [],
    kyc: [],
    identificationType: [],
    identificationNumber: [],
    bvn: [],
    address: [],
    city: [],
  };

  const lists = [...values.accountSignatories];

  for (let i = 0; i < lists.length; i++) {
    if (lists[i].title === "") {
      errors.title.push({
        id: i,
      });
    }
    if (lists[i].firstName === "") {
      errors.firstName.push({
        id: i,
      });
    }
    if (lists[i].lastName === "") {
      errors.lastName.push({
        id: i,
      });
    }
    if (!isValidPhoneNumber(lists[i].phonenumber)) {
      errors.phonenumber.push({
        id: i,
      });
    }
    if (lists[i].gender === "") {
      errors.gender.push({
        id: i,
      });
    }
    if (!isValidEmail(lists[i].email)) {
      errors.email.push({
        id: i,
      });
    }
    if (lists[i].country === "") {
      errors.country.push({
        id: i,
      });
    }
    if (lists[i].state === "") {
      errors.state.push({
        id: i,
      });
    }
    if (lists[i].nationality === "") {
      errors.nationality.push({
        id: i,
      });
    }
    if (lists[i].kyc === "") {
      errors.kyc.push({
        id: i,
      });
    }
    if (lists[i].identificationType === "") {
      errors.identificationType.push({
        id: i,
      });
    }
    if (lists[i].identificationNumber === "") {
      errors.identificationNumber.push({
        id: i,
      });
    }
    if (lists[i].bvn === "") {
      errors.bvn.push({
        id: i,
      });
    }
    if (lists[i].address === "") {
      errors.address.push({
        id: i,
      });
    }
    if (lists[i].customerType === "Individual" && lists[i].city === "") {
      errors.city.push({
        id: i,
      });
    }
  }

  if (!errors.title.length) delete errors.title;
  if (!errors.firstName.length) delete errors.firstName;
  if (!errors.lastName.length) delete errors.lastName;
  if (!errors.email.length) delete errors.email;
  if (!errors.phonenumber.length) delete errors.phonenumber;
  if (!errors.gender.length) delete errors.gender;
  if (!errors.country.length) delete errors.country;
  if (!errors.state.length) delete errors.state;
  if (!errors.nationality.length) delete errors.nationality;
  if (!errors.kyc.length) delete errors.kyc;
  if (!errors.identificationType.length) delete errors.identificationType;
  if (!errors.identificationNumber.length) delete errors.identificationNumber;
  if (!errors.bvn.length) delete errors.bvn;
  if (!errors.address.length) delete errors.address;
  if (!errors.city.length) delete errors.city;
  return errors;
}

export function validateAccountOpening6(values) {
  let errors = {};

  if (values.customerAccount[0].accountProductCode === "") {
    errors.accountProductCode = "Select An Option";
  }
  if (values.customerAccount[0].accountName === "") {
    errors.accountName = "Field is required";
  }
  return errors;
}

export function validateInvestmentBooking(values, valuesUx) {
  let errors = {};
  if (!values.CustomerAccountNumber.trim()) {
    errors.CustomerAccountNumber = "Field is required";
  }
  if (values.Amount === "") {
    errors.Amount = "Field is required";
  }
  if (!values.ProductCode) {
    errors.productAccount = "Select an option";
  }
  if (!values.BookDate) {
    errors.BookDate = "Field is required";
  }
  if (values.Tenor === "") {
    errors.Tenor = "Field is required";
  }
  if (!values.MaturityDate) {
    errors.MaturityDate = "Field is required";
  }
  // if (values.Variance === ''){
  //   errors.Variance = "Field is required";
  // }
  if (values.MaturityAmount === "") {
    errors.MaturityAmount = "Field is required";
  }
  if (values.interestPeriod === "") {
    errors.interestPeriod = "Field is required";
  }
  if (values.ExpectedInterestAmount === "") {
    errors.ExpectedInterestAmount = "Field is required";
  }
  if (!values.CreditInterestTo) {
    errors.CreditInterestTo = "Select an option";
  }
  if (!values.BeneficiaryAccountNumber) {
    errors.BeneficiaryAccountNumber = "Field is required";
  }

  if (valuesUx) {
    // if (parseInt(valuesUx.minimumVariance) > parseInt(values.Variance)){
    //   errors.Variance = `Variance cannot be less than minimum variance of ${valuesUx.minimumVariance}`;
    // }

    // if (parseInt(values.Variance) > parseInt(valuesUx.maximumVariance)){
    //   errors.Variance = `Variance cannot be greater than maximum Variance of ${valuesUx.maximumVariance}`;
    // }
    if (parseInt(values.FinalRate) < parseInt(valuesUx.minimumInterestRate)) {
      errors.FinalRate = `Rate cannot be less than minimum Rate of ${valuesUx.minimumInterestRate}`;
    }
    if (parseInt(values.FinalRate) > parseInt(valuesUx.maximumInterestRate)) {
      errors.FinalRate = `Rate cannot be greater than maximum Rate of ${valuesUx.maximumInterestRate}`;
    }

    if (parseInt(valuesUx.defaultInvAmount) > parseInt(values.Amount)) {
      errors.Amount = `Amount cannot be less than minimum Amount of ${valuesUx.defaultInvAmount}`;
    }
    if (parseInt(values.Amount) > parseInt(valuesUx.maximumInvAmount)) {
      errors.Amount = `Amount cannot be greater than maximum Amount of ${valuesUx.maximumInvAmount}`;
    }

    if (parseInt(valuesUx.defaultTenor) > parseInt(values.Tenor)) {
      errors.Tenor = `Tenor cannot be less than minimum Amount of ${valuesUx.defaultTenor}`;
    }
    if (parseInt(values.Tenor) > parseInt(valuesUx.maximumTenor)) {
      errors.Tenor = `Tenor cannot be greater than maximum Tenor of ${valuesUx.maximumTenor}`;
    }
    // if (parseInt(values.Variance) > parseInt(valuesUx.maximumVariance)){
    //   errors.Variance = "Variance cannot be greater than maximum variance";
    // }
  }

  return errors;
}

export function validateInvestmentLiquidation(values) {
  let errors = {};
  if (!values.LiquidationType) {
    errors.LiquidationType = "Select an option";
  }
  return errors;
}

export function validateInvestmentAmendment1(values) {
  let errors = {};

  if (values.Tenor === "") {
    errors.Tenor = "This field is required";
  }
  if (values.Amount === "") {
    errors.Amount = "This field is required";
  }
  if (!values.CustomerAccountNumber) {
    errors.CustomerAccountNumber = "This field is required";
  }
  if (values.Variance === "") {
    errors.Variance = "This field is required";
  }
  return errors;
}

export function validateDealPosting(values) {
  let errors = {};

  if (!values.CustomerAccountNumber) {
    errors.CustomerAccountNumber = "This field is required";
  }
  if (values.portfolioId === "") {
    errors.portfolioId = "This field is required";
  }
  if (!values.portfolioType) {
    errors.portfolioType = "This field is required";
  }
  if (values.product === "") {
    errors.product = "This field is required";
  }
  if (values.productName === "") {
    errors.productName = "This field is required";
  }
  if (values.dealReference === "") {
    errors.dealReference = "This field is required";
  }
  if (values.externalReferenceNumber === "") {
    errors.externalReferenceNumber = "This field is required";
  }
  if (values.dealQuantity === "") {
    errors.dealQuantity = "This field is required";
  }
  if (values.priceQuote === "") {
    errors.priceQuote = "This field is required";
  }
  if (values.currency === "") {
    errors.currency = "This field is required";
  }
  if (values.inputPrice === "") {
    errors.inputPrice = "This field is required";
  }
  if (values.priceInLocalCurrency === "") {
    errors.priceInLocalCurrency = "This field is required";
  }
  if (values.tradeDate === "") {
    errors.tradeDate = "This field is required";
  }
  if (values.quantityOfLocalCurrency === "") {
    errors.quantityOfLocalCurrency = "This field is required";
  }
  if (values.dealInputDate === "") {
    errors.dealInputDate = "This field is required";
  }
  if (values.interest === "") {
    errors.interest = "This field is required";
  }
  if (values.maturtiyDate === "") {
    errors.maturtiyDate = "This field is required";
  }
  // if (values.userReference === '') {
  //   errors.userReference = 'This field is required';
  // }
  if (values.brokerId === "") {
    errors.brokerId = "This field is required";
  }
  return errors;
}

export function validateManageRoles(values, valuesUX) {
  let errors = {};

  if (!values.name.trim()) {
    errors.name = "Field is required";
  }

  if (!values.description.trim()) {
    errors.description = "Field is required";
  }

  if (valuesUX) {
    valuesUX.length === 0 && (errors.rolePermissions = "Select Permissions");
  }

  return errors;
}

import { useState, useEffect, useCallback } from "react";
import "./Details.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../DatagridTable/table";
import DetailsContainer from "../../DatagridTable/details";
import Details from "../../Details/Products/AccountProducts";
import Button from "@material-ui/core/Button";
import AuthorizationModal from "../../DatagridTable/authorizationModal";
import { getHandler } from "../../../Api/Hooks-manager/coreModules/coreModules";
import { getAUserTillDetails } from "../../../Api/Hooks-manager/securitymanagement/tellerTills";

import Spinner from "../../Backdrop";
import { openDetailsModal, loading } from "../../../Api/redux-manager/actions";
import { baseUrl } from "../../../Api/configUrl";
import { format } from "date-fns";
import { formattedDate } from "../../../formatter/date";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 46,
    textTransform: "none",
    padding: "0 0.5rem",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
}));

const CurrencyDetails = () => {
  const dispatch = useDispatch();
  const load = useSelector((state) => state.approve.loading);
  const userId = useSelector((state) => state.common.userDetails.userName);
  const workDate = useSelector((state) => state.common.branch.workdate);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const classes = useStyles();
  const [denom, setDenom] = useState({
    denominations: {},
    balance: null,
    currencyCode: "",
    dateAuthorized: "",
  });

  const fetchUserTillBalanceWithDenom = useCallback(async () => {
    dispatch(loading());
    const allResponse = await getAUserTillDetails(token, userId);
    // console.log(allResponse.data[0].accountGL.balance, "new user tills")
    dispatch(loading());
    if (allResponse && allResponse.data) {
      const {
        denoM1000,
        denoM500,
        denoM200,
        denoM100,
        denoM50,
        denoM20,
        denoM10,
        denoM5,
        denoM1,
      } = allResponse.data[0];
      const denominations = {
        1000: denoM1000,
        500: denoM500,
        200: denoM200,
        100: denoM100,
        50: denoM50,
        20: denoM20,
        10: denoM10,
        5: denoM5,
        1: denoM1,
      };
      setDenom({
        denominations,
        balance: allResponse.data[0].accountGL.balance,
        currencyCode: allResponse.data[0].currencyCode,
        dateAuthorized: allResponse.data[0].dateAuthorized,
      });
    } else {
      clearCashBalance();
      // setDenom({...denom, denominations: [{}]})
    }
  }, [token, dispatch, userId]);

  useEffect(() => {
    fetchUserTillBalanceWithDenom();
  }, []);

  const clearCashBalance = () => {
    setDenom({
      denominations: {},
      balance: null,
      currencyCode: "",
      dateAuthorized: "",
    });
  };

  const { denominations } = denom;

  return (
    <div>
      <Spinner loading={load} />
      {denominations && (
        <div className="table-main table-responsive border--main">
          <div className="batchBalance">
            {/* <h6>List of Denominations</h6> */}
            <h6 className="totalCashAvail">
              Total Cash Available: NGN{denom.balance}
            </h6>
          </div>
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">Denominations</th>
                <th scope="col">Denomination Count</th>
                <th scope="col">Currency</th>
                <th scope="col">Batch Date</th>
                <th scope="col">Total cash</th>
                {/* <th scope="col">Authorized</th> */}
              </tr>
            </thead>
            {Object.keys(denominations)
              .reverse()
              .map((item, i) => (
                <tbody>
                  <tr key={i}>
                    {/* <th scope="row">1</th> */}
                    <td>{item}</td>
                    <td>{denominations[item]}</td>
                    <td>{denom.currencyCode}</td>
                    <td>
                      {format(new Date(denom.dateAuthorized), "yyyy-MM-dd")}
                    </td>
                    <td>{denominations[item] * parseInt(item)}</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default CurrencyDetails;

import axios from "axios";
import { Routes } from "../../configUrl";

//---------------------------------------------------- Customer Account------------------------------------------

const getCustomerByAccountNumber = async (token, accNum) => {
    return await axios({
      method: "get",
      url: Routes.getCustomerByAccountNumber + accNum,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };

  //---------------------------------------------------- gl Account------------------------------------------

const getGLAccount = async (token, accNum) => {
  return await axios({
    method: "get",
    url: Routes.getGLAccount + accNum,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

 //---------------------------------------------------- customer or gl Account------------------------------------------

 const getCustomerOrGLAccount = async (token, accNum, type) => {
   const url = type === 'customer' ? Routes.getCustomerByAccountNumber : Routes.getGLAccount;
  return await axios({
    method: "get",
    url: url + accNum,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((res) => {
      return {
        status: res.data.status,
        message: res.data.responseMessage,
        data: res.data.data,
      };
    })
    .catch((err) => {
      return {
        status: err.response.data.status,
        message: err.response.data.responseMessage,
        data: [],
      };
    });
};

  //---------------------------------------------------- Transaction ------------------------------------------
  const getAllAccountStatement = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.getAccountStatement,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };

  //---------------------------------------------------- Generate Customer Account Statement Transaction Starts Here ------------------------------------------
  const generateCustomerAccountStatement = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.generateAccountStatement,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.message,
          data: res.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };
  //---------------------------------------------------- Generate Customer Account Statement Transaction Ends Here ------------------------------------------

  const AllAccountStatement = async (token, body, pageNumber, pageSize) => {
    return await axios({
      method: "post",
      data: body,
      url: `${Routes.getAnAccountStatement}?&pageNumber=${pageNumber=1}&pageSize=${pageSize=1000}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };

  const debit = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.debit,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status,
          data: [],
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status,
          data: [],
        };
      });
  };

  const reversal = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.reversal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          message: res.data.responseMessage,
          status: res.data.status,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          message: err.response.data.responseMessage,
          status: err.response.data.status,
          data: [],
        };
      });
  };

const fetchTransactionByAccountNumber = async (token, data) => {
    return await axios({
      method: "get",
      url: `${Routes.fetchTransactionByAccountNumber}${data.accNum}&offset=${data.start}&limit=${data.numOfItems}
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };

  const fetchTransactionByReferenceNumbers = async (token, data) => {
    return await axios({
      method: "get",
      url: `${Routes.fetchTransactionByReferenceNumber}${data.referenceNumber}
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };


  const authorizeTransaction = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.authorizeTransaction,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          statusCode: res.data.statusCode,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          statusCode: err.response.data.statusCode,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };

  const handleBulkTransaction = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.handleBulkTransaction,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        console.log(err.response)
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };
  //---------------------------checking auto debit start-----------------------------//
  const handleAutoDebitForBulk = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.handleAutoBulkTransactionDebit,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        console.log(err.response)
        return {
          status: err.response.data.status,
          message: err.response.data.responseMessage,
          data: [],
        };
      });
  };
//---------------------------checking auto debit end-----------------------------//
  
const ValidateAllAccounts = async (token, body) => {
    return await axios({
      method: "post",
      data: body,
      url: Routes.getAndValidateAllAccounts,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((res) => {
        return {
          status: res.data.status,
          message: res.data.responseMessage,
          data: res.data.data,
        };
      })
      .catch((err) => {
        console.log(err.response)
        return {
          // status: err.response.data.status,
          // message: err.response.data.responseMessage,
          data: [],
        };
      });
  };


  export {
    getCustomerByAccountNumber,
    getGLAccount,
    debit,
    reversal,
    fetchTransactionByAccountNumber,
    authorizeTransaction,
    fetchTransactionByReferenceNumbers,
    handleBulkTransaction,
    ValidateAllAccounts,
    getCustomerOrGLAccount,
    handleAutoDebitForBulk,
    getAllAccountStatement,
    AllAccountStatement,
    generateCustomerAccountStatement,  
  }
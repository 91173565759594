import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from '../../../Details/LoanAccount/LoanBooking'
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getAllLoanBooking, getLoanByLoanAccountNumber, getAllUnauthorizedLoans } from "../../../../Api/Hooks-manager/loanAccountOperations";
import Spinner from "../../../Backdrop";
import {
  openDetailsModal,
  loading,
} from "../../../../Api/redux-manager/actions";
import { Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";



const LoanBooking = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccount",
      label: "Loan Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanProduct",
      label: "Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        fetchLoanAccountDetails(item.loanAccount)
      }
    },
  };

  const fetchAllUnauthorizedLoans = useCallback(async () => {
    dispatch(loading());
    const allResponse = await getAllUnauthorizedLoans(token);
    dispatch(loading());
    const response = allResponse.data.filter(
      (item) =>
        item.authorizationStatus === "Pending" ||
        item.authorizationStatus === "PendingEditedAuthorization"
    );
    if (response && response.length > 0){
      for (let i = 0; i < response.length; i++) {
        response[i].sn = i + 1;
        response[i].bookDate = formattedDate(response[i].bookDate);
        response[i].firstRepaymentDate = formattedDate(
          response[i].firstRepaymentDate
        );
        response[i].maturityDate = formattedDate(
          response[i].maturityDate
        );
        response[i].nextDueDate = formattedDate(
          response[i].nextDueDate
        );
        response[i].startDate = formattedDate(response[i].startDate);
        response[i].createdDateTime = formattedDate(
          response[i].createdDateTime
        );
        response[i].dateAuthorized = formattedDate(
          response[i].dateAuthorized
        );
        response[i].updatedDateTime = formattedDate(
          response[i].updatedDateTime
        );
      };
      setData(response);
}
  }, [dispatch, token]);

  const fetchLoanAccountDetails = async (accNum) => {
    dispatch(loading());
    const response = await getLoanByLoanAccountNumber(token, accNum);
    if (response && response.data) {
      dispatch(loading());
      if (response.status){
        setDetails(response.data);
        dispatch(openDetailsModal());
      }
    }
  };

  useEffect(() => {
    fetchAllUnauthorizedLoans();
  }, [fetchAllUnauthorizedLoans, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Loan booking"
        subtitle=""
        name="Lists of loan booking"
        records={data}
        options={options}
        columns={columns}
      />}
      {details && details.id && <DetailsContainer title="Loan bookings - Record Details">
        <Details details={details}/>
        </DetailsContainer>}
      <AuthorizationModal
        title="Authorize Loan booking"
        question="Are you sure you want to authorize this loan?"
        url={`${Routes.authorizeLoanBooking}${details.id}`}
      />
    </div>
  );
};

export default LoanBooking;

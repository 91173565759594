import { useSelector } from "react-redux";
import {
  Grid,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import ErrorMessage from "../../../ErrorMessage";
import EditModal from "../../../DatagridTable/edit";
import { Routes } from "../../../../Api/configUrl";

const EditInstitutionDef = ({
  editData,
  errorEdit,
  handleEditChange,
  states,
  allCurrencyDefinition,
  allBranches,
  institutions,
  handleEditSubmit,
}) => {
  const countries = useSelector((state) => state.common.countries);
  return (
    <EditModal
      title="Update instution"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={editData}
      url={`${Routes.updateAnInstitution}${editData.id}`}
    >
      <Grid container spacing={1} className="institution--definition">
        <Grid item xs={12} sm={6} md={6}>
          <label>Institution Name</label>
          <br />
          <input
            type="text"
            className={errorEdit.name ? "error-input-z" : null}
            value={editData.name}
            onChange={handleEditChange("name")}
          />{" "}
          {errorEdit.name && <ErrorMessage message={errorEdit.name} />}{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Institution Type</label>
          <br />
          <select
            onChange={handleEditChange("institutionType")}
            className={errorEdit.institutionType ? "error-input-z" : null}
            value={editData.institutionType}
          >
            <option value="Select an institution">Select an institution</option>
            {institutions.map((institution) => (
              <option value={institution.id} key={institution.id}>
                {institution.parameterValue}{" "}
              </option>
            ))}{" "}
          </select>
          {errorEdit.institutionType && (
            <ErrorMessage message={errorEdit.institutionType} />
          )}{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Institution Currency</label>
          <br />
          <select
            onChange={handleEditChange("currencyCode")}
            className={errorEdit.currencyCode ? "error-input-z" : null}
            value={editData.currencyCode}
          >
            <option value="Select a currency">Select a currency</option>
            {allCurrencyDefinition.map((item) => (
              <option value={item.currencyCode} key={item.id}>
                {item.currencyName}{" "}
              </option>
            ))}{" "}
          </select>
          {errorEdit.currencyCode && (
            <ErrorMessage message={errorEdit.currencyCode} />
          )}{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Institution Address</label>
          <br />
          <input
            className={errorEdit.address ? "error-input-z" : null}
            type="text"
            value={editData.address}
            onChange={handleEditChange("address")}
          />{" "}
          {errorEdit.address && <ErrorMessage message={errorEdit.address} />}{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Country</label>
          <br />
          <select
            onChange={handleEditChange("countryCode")}
            className={errorEdit.countryCode ? "error-input-z" : null}
            value={editData.countryCode}
          >
            <option value="Select a country">Select a country</option>
            {countries.map((country) => (
              <option value={country.countryCode2} key={country.id}>
                {country.name}{" "}
              </option>
            ))}{" "}
          </select>
          {errorEdit.countryCode && (
            <ErrorMessage message={errorEdit.countryCode} />
          )}{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>State</label>
          <br />
          <select
            onChange={handleEditChange("stateCode")}
            className={errorEdit.stateCode ? "error-input-z" : null}
            value={editData.stateCode}
          >
            <option value="Select a state">Select a state</option>
            {states.map((state) => (
              <option value={state.name} key={state.id}>
                {state.name}{" "}
              </option>
            ))}{" "}
          </select>
          {errorEdit.stateCode && (
            <ErrorMessage message={errorEdit.stateCode} />
          )}{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Head Office Branch Code</label>
          <br />
          <select
            className={errorEdit.headOfficeBranchCode ? "error-input-z" : null}
            value={editData.headOfficeBranchCode}
            onChange={handleEditChange("headOfficeBranchCode")}
          >
            <option value="Select a branch">Select a branch</option>
            {allBranches.map((branch) => (
              <option value={branch.code} key={branch.id}>
                {`${branch.name} - ${branch.code}`}
              </option>
            ))}{" "}
          </select>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>Work Time</label>
          <div className="work-time">
            <span>Start Time</span>
            <input
              className={errorEdit.startTime ? "error-input-z" : null}
              type="time"
              value={editData.startTime}
              style={{
                minWidth: "30px",
                marginLeft: 120,
              }}
              onChange={handleEditChange("startTime")}
            />{" "}
            {errorEdit.startTime && (
              <ErrorMessage message={errorEdit.startTime} />
            )}
            <br />
            <span>Stop Time</span>
            <input
              className={errorEdit.endTime ? "error-input-z" : null}
              type="time"
              value={editData.endTime}
              style={{
                minWidth: "30px",
                marginLeft: 120,
              }}
              onChange={handleEditChange("endTime")}
            />{" "}
            {errorEdit.endTime && <ErrorMessage message={errorEdit.endTime} />}{" "}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          Status
          <FormControl component="fieldset" className="status">
            <RadioGroup
              aria-label="status"
              name="status"
              value={editData.status.toString()}
              onChange={handleEditChange("status")}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </EditModal>
  );
};

export default EditInstitutionDef;

import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Button } from "@material-ui/core";
import Item from "../../DatagridTable/item";
import { numberFormatter } from "../../../formatter";
// import { formattedDate } from "../../../formatter/date";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import ErrorMessage from "../../ErrorMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  text: {
    margin: "auto",
    marginTop: "2rem",
    marginBottom: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& tr": {
      minHeight: 10,
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  item: {
    width: "100%",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  editField: {
    width: "210px",
  },
  editInput: {
    height: "40px",
    borderRadius: "5px",
    padding: "0 0.75rem",
    margin: "0 auto",
    marginTop: "20px",
    width: "80%",
  },
  error: {
    border: "1px solid red",
  },
}));

const LoanScheduleDetails = ({
  details,
  repaymentShedule,
  edit,
  setEdit,
  editedSchedule,
  onScheduleChange,
  onScheduleReset,
  errors,
  totals,
  onScheduleValidate,
}) => {
  const classes = useStyles();
  // console.log(repaymentShedule);

  // console.log(totals, "totals...");





  const totalError = errors[errors.length - 1];

  const scheduleMap = !edit
    ? repaymentShedule.map((el, i) => (
        <tr key={i}>
          <td>
            <Item label="" name={el.sequenceId} />
          </td>
          <td>
            <Item
              label=""
              name={<Moment format="D MMM YYYY">{el.dueDate}</Moment>}
            />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.repaymentPrincipal)} />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.repaymentInterest)} />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.repaymentAmount)} />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.interestAmountPaid)} />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.principalAmountPaid)} />
          </td>
          <td>
            <Item label="" name={el.paymentStatus} />
          </td>
        </tr>
      ))
    : editedSchedule.map((el, i) => (
        <tr key={i}>
          <td>
            <Item label="" name={el.sequenceId} />
          </td>
          <td>
            <Item
              label=""
              name={<Moment format="D MMM YYYY">{el.dueDate}</Moment>}
            />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.repaymentPrincipal)} />
            {/* <NumberFormat
              value={el.repaymentPrincipal}
              thousandSeparator={true}
              inputMode="numeric"
              decimalSeparator="."
              decimalScale={2}
              disabled={true}
              fixedDecimalScale={true}
              renderText={(value) => <div>{value}</div>}
              className={`${classes.editInput} ${
                errors[i]["repaymentPrincipal"] ? classes.error : null
              }`}
              onValueChange={({ value }) => {
                onScheduleChange("repaymentPrincipal", i, value);
              }}
            /> */}
            {/* {errors[i]["repaymentPrincipal"] && (
              <ErrorMessage message={errors[i]["repaymentPrincipal"]} />
            )} */}
          </td>
          <td className={classes.editField}>
            {el.paymentStatus === "Paid" 
            ? (<Item label="" name={numberFormatter(el.repaymentInterest)} />)
            : (<NumberFormat
              value={el.repaymentInterest}
              thousandSeparator={true}
              inputMode="numeric"
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={(value) => <div>{value}</div>}
              className={`${classes.editInput} ${
                errors[i]["repaymentInterest"] ? classes.error : null
              }`}
              onValueChange={({ value }) => {
                onScheduleChange("repaymentInterest", i, value);
              }}
            />)}
            {errors[i]["repaymentInterest"] && (
              <ErrorMessage message={errors[i]["repaymentInterest"]} />
            )}
          </td>
          <td>
            <Item label="" name={numberFormatter(el.repaymentAmount)} />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.interestAmountPaid)} />
          </td>
          <td>
            <Item label="" name={numberFormatter(el.principalAmountPaid)} />
          </td>
          <td>
            <Item label="" name={el.paymentStatus} />
          </td>
        </tr>
      ));

  return (
    <Grid container spacing={2} className={classes.root}>
      {!edit ? (
        <Button className={classes.button} onClick={() => setEdit(true)}>
          Edit
        </Button>
      ) : (
        <>
          {/* <Button className={classes.button} onClick={onScheduleValidate}>
            Validate
          </Button> */}
          <Button className={classes.button} onClick={onScheduleReset}>
            Reset
          </Button>
          <Button className={classes.button} onClick={() => setEdit(false)}>
            Cancel
          </Button>
        </>
      )}

      {repaymentShedule && repaymentShedule.length > 0 ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Repayment No</th>
              <th>Due Date</th>
              <th>Rep. Principal</th>
              <th>Rep. Interest</th>
              <th>Repayment Amount</th>
              <th>Interest Amount Paid</th>
              <th>Principal Amount Paid</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {scheduleMap}
            <tr>
              <td>
                <Item label="" name={"Total"} />
              </td>
              <td></td>
              <td>
                <Item
                  label=""
                  name={numberFormatter(totals.repaymentPrincipal)}
                  className={`${
                    totalError["repaymentPrincipal"] ? classes.error : null
                  }`}
                />
                <ErrorMessage message={totalError["repaymentPrincipal"]}/>
              </td>
              <td>
                <Item
                  label=""
                  name={numberFormatter(totals.repaymentInterest)}
                  className={`${
                    totalError["repaymentInterest"] ? classes.error : null
                  }`}
                />
                <ErrorMessage message={totalError["repaymentInterest"]} />
              </td>
              <td>
                <Item
                  label=""
                  name={numberFormatter(totals.repaymentAmount)}
                  className={`${
                    totalError["repaymentAmount"] ? classes.error : null
                  }`}
                />
                <ErrorMessage message={totalError["repaymentAmount"]} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      ) : (
        <Grid xs={12}>
          <div className={classes.noData}>
            <Item label="" name="Not available" className={classes.item} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default LoanScheduleDetails;

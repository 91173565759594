import React from "react";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import SaveIcon from "@material-ui/icons/Save";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    maxWidth: 500,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 7,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bgimg: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
    marginBottom: "1rem",
  },
  modalContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  success: {
    color: "green",
  },
  error: {
    //color:'red'
  },
  message: {
    font: "normal normal normal 16px/19px Work Sans",
    textAlign: "center",
    "& ul": {
      margin: "10px",
      "& li": {
        listStyleType: "none",
      },
    },
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    width: 46,
    height: 46,
    margin: 15,

    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export default function TransitionsModal({
  onClose,
  openDialog,
  onClick,
  question,
}) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDialog ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={openDialog ?? false}>
          <div className={classes.paper}>
            <div className={classes.center}>
              <Avatar className={classes.bgimg}>
                <SaveIcon />
              </Avatar>
            </div>
            <div className={clsx(classes.modalContent)}>
              <div
                id="transition-modal-description"
                className={classes.message}
              >
                <b>{question}</b>
              </div>
              <div className={classes.btnContainer}>
                <Button className={classes.button} onClick={handleClose}>
                  No
                </Button>
                <Button className={classes.button} onClick={onClick}>
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}

import React, {useState, useEffect} from 'react';
import { Grid, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCustomerByAccountNumber } from "../../../Api/Hooks-manager/loanAccountOperations";
import {
  loading,
} from "../../../Api/redux-manager/actions";
import Item from "../../DatagridTable/item";
import { numberFormatter } from "../../../formatter";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDialogContent-root":{
        display:"flex"
      }
    },
    text: {
      margin: "auto",
      marginTop: "1rem",
      color: theme.palette.secondary.main,
    },
    hr: {
      backgroundColor: "black",
      width: "100%",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    table: {
      width: "100%",
      "& th": {
        paddingTop: 12,
        paddingBottom: 12,
        textAlign: "left",
      },
      "& td": {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    noData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    item: {
      width: "100%",
    },
  }));
  
const LoanDisbursement = ({details}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const load = useSelector((state) => state.approve.loading);
  const [accountInfo, setAccountInfo] = useState({});

console.log(details, "sdfdgd")

  const fetchCustomerDetails = async (accNum) => {
    dispatch(loading());
    const response = await getCustomerByAccountNumber(token, accNum);
    if (response && response.data) {
      dispatch(loading());
      if (response.status) {
        setAccountInfo(prev => ({
          ...prev,
          creditAccountName: response.data.accountName
        }))
      }
    }
  };


  useEffect(() => {
    if(details.creditAccount){
      fetchCustomerDetails(details.creditAccount)
    }
  },[details.creditAccount]);


    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Teller Till Account" name={details.tellerTillAccount || 'Nil'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Loan Account No" name={details.loanAccountNo || 'Nil'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Credit Account" name={details.creditAccount} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Credit Account Name" name={accountInfo.creditAccountName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Disbursement Reference No" name={details.disbursementReferenceNo} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Disbursement Method" name={details.disbursementMethod} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Amount" name={numberFormatter(details.amount)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            label="Status"
            name={
              details.authorizationStatus === "PendingEditedAuthorization"
                ? "Pending Edited Authorization"
                : details.authorizationStatus
            }
          />
        </Grid>
      </Grid>
    )
}

export default LoanDisbursement

import { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Tab,
  Tabs,
  AppBar,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import EditModal from "../../../DatagridTable/edit";
import { Routes, baseUrl } from "../../../../Api/configUrl";
import { DateConverter } from "../../../ConvertDate";
import ErrorMessage from "../../../ErrorMessage";
import useValidation from "./useValidation";
import Confirm from "../../../../Confirmbox/Confirm";
import {
  openResquestDecisionModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import { postHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  rateChartRanges: {
    border: "1px solid black",
    marginBottom: "3rem",
    padding: "2rem",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
    textTransform: "none",
  },
}));

const EditData = ({ callBack, details }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [value, setValue] = useState(0);
  const {
    err,
    errMsg,
    setErr,
    setErrMsg,
    type,
    setType,
    validateRateChartSetUp,
  } = useValidation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [rate, setRate] = useState({});
  const [rateChartRanges, setRateChartRanges] = useState([]);
  const [singleRateChartRange, setSingleRateChartRange] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRate({
      ...rate,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (validateRateChartSetUp(rate, rateChartRanges)) {
      setErr("");
      setErrMsg("");
      setType("");
      dispatch(openResquestDecisionModal());
    }
  };

  const postResult = async () => {
    dispatch(isLoading());
    const url = `${baseUrl}organization/RateChart/UpdateRateChartRange/${singleRateChartRange.id}`;
    const response = await postHandler(token, singleRateChartRange, url);
    console.log(response.message);
    if (response && response.message) {
      dispatch(isLoading());
      setMessage(response.message);
    }
    if (response.status) {
      callBack();
      return true;
    } else {
      return false;
    }
  };

  const handleUpdateRateChart = async (item) => {
    if (validateRateChartSetUp(rate, rateChartRanges)) {
      setErr("");
      setErrMsg("");
      setType("");
      setSingleRateChartRange(item);
      setOpen(true);
    }
  };

  const handleTab = (event, newValue) => {
    setValue(newValue);
    console.log("newValue", newValue);
  };
  const handleRateChartRangesChange = (e, index) => {
    const { name, value } = e.target;
    const tempItems = [...rateChartRanges];
    tempItems[index][name] =
      name === "tenorFrom" ||
      name === "tenorTo" ||
      name === "amountFrom" ||
      name === "amountTo" ||
      name === "rate"
        ? parseInt(value) < 0
          ? 0
          : parseInt(value)
        : value;
    if (rate.basis === "Amount") {
      tempItems[index]["tenorFrom"] = 0;
      tempItems[index]["tenorTo"] = 0;
    }
    if (rate.basis === "Tenor") {
      tempItems[index]["amountFrom"] = 0;
      tempItems[index]["amountTo "] = 0;
    }
    setRateChartRanges(tempItems);
    console.log(rateChartRanges);
  };
  useEffect(() => {
    if (value == 0) {
      setRate({
        code: details.code,
        description: details.description,
        basis: details.basis,
        tenorBasis: details.tenorBasis,
        rateChartRanges: [],
      });
    }
    if (value == 1) {
      setRateChartRanges(
        details.rateChartRanges.map((item) => {
          return {
            id: item.id,
            tenorFrom: item.tenorFrom,
            tenorTo: item.tenorTo,
            amountFrom: item.tenorFrom,
            amountTo: item.amountTo,
            rate: item.rate,
            rateBasis: item.rateBasis,
            effectiveDate: DateConverter(item.effectiveDate),
          };
        })
      );
    }
  }, [value, details]);

  console.log("chart", rateChartRanges);
  console.log("chart", details);
  return (
    <EditModal
      title="Update Customer Category"
      onClick={handleSubmit}
      question="Are you sure you want to update this record?"
      data={rate}
      url={`${Routes.updateRateChart}${details.id}`}
      hide={value == 1 ? true : false}
    >
      <Confirm
        ApiCall={postResult}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleTab}
            aria-label="simple tabs example"
          >
            <Tab label="Rate Chart" {...a11yProps(0)} />
            <Tab label="Rate Charts Range" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <p>Rate Code</p>
              <input
                type="text"
                className={err === "code" ? "error--input" : null}
                onChange={handleChange}
                name="code"
                value={rate.code}
              />
              {err === "code" && <ErrorMessage message={errMsg} />}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Rate Code Description</label>
              <input
                className={err === "description" ? "error--input" : null}
                type="text"
                onChange={handleChange}
                name="description"
                value={rate.description}
              />
              {err === "description" && <ErrorMessage message={errMsg} />}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Rate Chart Basis</label>
              <select
                disabled
                value={rate.basis}
                name="basis"
                onChange={handleChange}
                className={err === "basis" ? "error--input" : null}
              >
                <option value="">Select Rate basis</option>
                <option value="Tenor">Tenor</option>
                <option value="Amount">Amount</option>
              </select>
              {err === "basis" && <ErrorMessage message={errMsg} />}
            </Grid>
            {rate.basis === "Tenor" && (
              <Grid item xs={12} sm={6} md={4}>
                <label>Tenor Basis</label>
                <select
                  className={err === "tenorBasis" ? "error--input" : null}
                  name="tenorBasis"
                  value={rate.tenorBasis}
                  onChange={handleChange}
                >
                  <option value="Select tenor basis">Select tenor basis</option>
                  <option value="Days">Days</option>
                  <option value="Month">Month</option>
                </select>
                {err === "tenorBasis" && <ErrorMessage message={errMsg} />}
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className="account-enquiry">
          <div style={{ paddingBottom: "1.5rem" }}>
            {rateChartRanges.map((item, i) => (
              <Grid
                container
                spacing={2}
                key={i}
                className={classes.rateChartRanges}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <p>{rate.basis === "Tenor" ? "Tenor From" : "Amount From"}</p>
                  <input
                    className={
                      err === i && type === "tenorFrom" ? "error--input" : null
                    }
                    type="number"
                    name={rate.basis === "Tenor" ? "tenorFrom" : "amountFrom"}
                    value={
                      rate.basis === "Tenor" ? item.tenorFrom : item.amountFrom
                    }
                    onChange={(e) => handleRateChartRangesChange(e, i)}
                  />
                  {err === i && (type === rate.basis) === "Tenor"
                    ? "tenorFrom"
                    : "amountFrom" && <ErrorMessage message={errMsg} />}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <p>{rate.basis === "Tenor" ? "Tenor To" : "Amount To"}</p>
                  <input
                    className={
                      err === i && type === "tenorTo" ? "error--input" : null
                    }
                    type="number"
                    name="tenorTo"
                    value={item.tenorTo}
                    onChange={(e) => handleRateChartRangesChange(e, i)}
                  />
                  {err === i && type === "tenorTo" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <p>Effective Date</p>
                  <input
                    className={
                      err === i && type === "effectiveDate"
                        ? "error--input"
                        : null
                    }
                    type="date"
                    name="effectiveDate"
                    value={item.effectiveDate}
                    onChange={(e) => handleRateChartRangesChange(e, i)}
                  />
                  {err === i && type === "effectiveDate" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <p>Rate</p>
                  <input
                    className={
                      err === i && type === "rate" ? "error--input" : null
                    }
                    type="number"
                    name="rate"
                    value={item.rate}
                    onChange={(e) => handleRateChartRangesChange(e, i)}
                  />
                  {err === i && type === "rate" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <p>Rate Basis</p>
                  <select
                    className={
                      err === i && type === "rateBasis" ? "error--input" : null
                    }
                    value={item.rateBasis}
                    name="rateBasis"
                    onChange={(e) => handleRateChartRangesChange(e, i)}
                  >
                    <option value="Select Rate basis">Select Rate basis</option>
                    <option value="Per Month">Per Month</option>
                    <option value="Per Annum">Per Annum</option>
                  </select>
                  {err === i && type === "rateBasis" && (
                    <ErrorMessage message={errMsg} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.btnContainer}>
                    <Button
                      onClick={() => handleUpdateRateChart(item)}
                      className={classes.button}
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ))}
          </div>
        </TabPanel>
        <p>{errMsg}</p>
      </div>
    </EditModal>
  );
};

export default EditData;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  openDecisionModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import { InitiateContribution } from "../../../../Api/Hooks-manager/accountOpening/existingCustomer";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Modal,
  Divider,
} from "@mui/material";
import ErrorMessage from "../../../ErrorMessage";
import BasicModal from "./CustomModal";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "200px",
  },
  centerBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "2rem",
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    // marginTop: 20,
    // marginRight: "2rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
  },
  children: {
    padding: "1.5rem 0px",
  },
}));

const StartContribution = ({ details }) => {
  console.log(details.accountProduct.productCode);
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const suspenceAccount = useSelector((state) => state.common.branch);

  const nowDate = format(new Date(suspenceAccount.workdate), "yyyy-MM-dd");

  console.log(format(new Date(), "yyyy-MM-dd"));

  const [message, setMessage] = useState(null);

  const [result, setResult] = useState(null);

  const [err, setErr] = useState({});

  const [contributionInfo, setContributionInfo] = useState({
    accountNumber: details.accountNumber,
    startNow: true,
    startDate: nowDate,
    targetedAmount: 0,
    dailyAmountForThrift: 0,
    targetEndDate: nowDate,
  });

  console.log(contributionInfo, "contributionInfo");

  const ValidateFields = () => {
    const {
      accountNumber,
      startNow,
      startDate,
      targetedAmount,
      targetEndDate,
      dailyAmountForThrift,
    } = contributionInfo;
    const errors = {};

    if (accountNumber === "" || accountNumber.length < 10) {
      errors.accountNumber = "Please enter a valid account number";
    }
    if (startDate === "") {
      errors.startDate = "This field is required";
    }
    if (targetedAmount === "") {
      errors.targetEndDate = "This field is required";
    }
    if (targetEndDate === "") {
      errors.targetEndDate = "This field is required";
    }
    if (dailyAmountForThrift === "") {
      errors.dailyAmountForThrift = "This field is required";
    }

    return errors;
  };

  // const startNewConstribution = async () => {
  //   dispatch(openResquestDecisionModal());
  // };

  console.log(message);

  const EmptyAllFields = () => {
    setContributionInfo({
      accountNumber: details.accountNumber,
      startNow: true,
      startDate: nowDate,
      targetedAmount: 0,
      dailyAmountForThrift: 0,
      targetEndDate: nowDate,
    });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    EmptyAllFields();
  };

  const StartANewContribution = useCallback(
    async (preData) => {
      dispatch(isLoading());
      const response = await InitiateContribution(token, preData);
      console.log(response.message, "response");
      if (response && response.data) {
        dispatch(isLoading());
        console.log("check here...");
        setMessage(response.data);
        setResult(response.status);
        handleOpen();
      } else {
        console.log("or here here here...");
        setMessage(response.message);
        setResult(response.status);
        handleOpen();
      }
    },
    [dispatch, token]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContributionInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // const handleSubmit = () => {
  //   const checkErrors = ValidateFields();
  //   setErr(checkErrors);
  //   if (Object.keys(checkErrors).length > 0) return;
  //   ;
  // };

  return (
    <div>
      <Typography variant="h6">{`ACCOUNT PRODUCT:${details.accountProduct.productName}`}</Typography>
      <Divider />
      <Grid container spacing={2} sx={{ padding: "2rem" }}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <label>Account Number</label> <br />
          <TextField
            variant="outlined"
            type="number"
            name="accountNumber"
            value={contributionInfo.accountNumber}
            onChange={handleChange}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <label>Start Date</label> <br />
          <TextField
            variant="outlined"
            type="date"
            name="startDate"
            value={contributionInfo.startDate}
            onChange={handleChange}
          />
        </Grid>
        {details && details.accountProductCode === "PTS02" && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <label>Targeted Amount</label> <br />
            <TextField
              variant="outlined"
              type="number"
              name="targetedAmount"
              value={contributionInfo.targetedAmount}
              onChange={handleChange}
            />
            {err.targetedAmount && (
              <ErrorMessage message={err.targetedAmount} />
            )}
          </Grid>
        )}
        {details && details.accountProductCode !== "PTS02" && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <label>Daily Amount For Thrift</label> <br />
            <TextField
              variant="outlined"
              type="number"
              name="dailyAmountForThrift"
              value={contributionInfo.dailyAmountForThrift}
              onChange={handleChange}
            />
            {err.dailyAmountForThrift && (
              <ErrorMessage message={err.dailyAmountForThrift} />
            )}
          </Grid>
        )}
        {details && details.accountProductCode === "PTS02" && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <label>Target End Date</label> <br />
            <TextField
              variant="outlined"
              type="date"
              name="targetEndDate"
              value={contributionInfo.targetEndDate}
              onChange={handleChange}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={4} className={classes.centerBtn}>
          <br />
          {contributionInfo.targetEndDate !== "" && (
            <Button
              variant="contained"
              sx={{
                height: "40px",
                bgcolor: "#D0021B",
                color: "#fff",
              }}
              onClick={() => StartANewContribution(contributionInfo)}
            >
              Submit
            </Button>
          )}
        </Grid>
      </Grid>
      <BasicModal
        message={message}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        result={result}
      />
    </div>
  );
};

export default StartContribution;

import { useState, useEffect, useCallback } from "react";
import "./Navbar.scss";
import Logo from "../../../assets/mainlogo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import useForm from "../../../Api/Hooks-manager/useCustom";
import axios from "axios";
import { baseUrl } from "../../../Api/configUrl";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const Navbar = ({ toggler }) => {
  const { handleLogout } = useForm();
  const [setMessageHour, setSetMessageHour] = useState("");
  const [branchUx, setBranchUx] = useState("");
  const [roleUx, setRoleUx] = useState("");
  const [nameUx, setNameUx] = useState("");
  const [dayUx, setDayUx] = useState("");
  const [actualDayUx, setActualDayUx] = useState("");
  const [monthUx, setmonthUx] = useState("");
  const [yearUx, setYearUx] = useState("");
  const suspenceAccount = useSelector((state) => state.common.branch.branch);

  // Get Branch Date
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {}, [user]);

  useEffect(() => {
    let Token = user && user.jwtToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
    if (user) {
      let Branch = user.operatingBranch;
      let Role = user.role;
      let Name = user.firstName;
      setBranchUx(Branch);
      setRoleUx(Role);
      setNameUx(Name);
    }
  }, [user]);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const curHr = new Date().getHours();

  const getBranchDate = useCallback(
    async (e) => {
      try {
        if (suspenceAccount) {
          // setAllGlAccount(response.data.data)
          const getDay = new Date(suspenceAccount.workdate).getDay();
          const actualDay = days[parseInt(getDay)];
          setActualDayUx(actualDay);
          const formattedDate = format(
            new Date(suspenceAccount.workdate),
            "dd/MM/yyyy"
          );
          console.log(formattedDate, "application date is here");
          const monthFormated = formattedDate.slice(3, 5);
          const month = months[parseInt(monthFormated) - 1];
          setmonthUx(month);
          const dayFormated = formattedDate.slice(0, 2);
          setDayUx(dayFormated);
          const yearFormated = formattedDate.slice(6);
          setYearUx(yearFormated);
        }
      } catch (err) {
      } finally {
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [branchUx, suspenceAccount]
  );

  useEffect(() => {
    getBranchDate();
  }, [getBranchDate]);

  const getActualTime = () => {
    if (curHr < 12) {
      setSetMessageHour("Good Morning");
    } else if (curHr < 18) {
      setSetMessageHour("Good Afternoon");
    } else {
      setSetMessageHour("Good Evening");
    }
  };

  useEffect(() => {
    getActualTime();
  });

  useEffect(() => {}, [setMessageHour]);

  return (
    <main className="navbar flex--2">
      <GiHamburgerMenu className="hamburger" onClick={() => toggler()} />
      <img src={Logo} alt="logo" />
      <div className="flex--2 navbar--description">
        <div className="flex--2 TME">
          <p>TME</p>
          <p>Transaction Made Easy</p>
        </div>
        {actualDayUx && (
          <p className="TME">{`${actualDayUx}, ${dayUx} ${monthUx}, ${yearUx}`}</p>
        )}
        <div className="flex--2 TME">
          <p>{roleUx}</p>
          <p>{branchUx}</p>
        </div>
        <p>
          {" "}
          {setMessageHour}, {nameUx}
        </p>
        <button onClick={() => handleLogout()}>Logout</button>
      </div>
    </main>
  );
};

export default Navbar;

import React from "react";
import "./LoanDisbursement.scss";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import BusinessLogic from "./businessLogic";
import Confirm from "../../../../Confirmbox/Confirm";
import DecisonModal from "../../../../Confirmbox/Confirm";
import ErrorMessage from "../../../ErrorMessage";
import Spinner from "../../../Backdrop";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import Details from "./view";
import Table from "../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  openViewModal,
} from "../../../../Api/redux-manager/actions";


const LoanDisbursement = () => {
  const {
    disbursement,
    details,
    setDetails,
    allLoanDisbursement,
    accNum,
    allLoanAccountNum,
    creditAccountDetails,
    invalidAccount,
    sumLoanDisbursed,
    handleChange,
    handleAccNum,
    postLoanDisbursement,
    screenChange,
    handleSubmit,
    errors,
    open,
    setOpen,
    loading,
    allCurrencyDefinition,
    message,
    openDecision,
    setOpenDecision,
    decide,
    fetchCustomerDetails,
  } = BusinessLogic();

  const dispatch = useDispatch();
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "loanAccountNo",
      label: "Loan Account No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "creditAccount",
      label: "Credit Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "disbursementMethod",
      label: "Disbursement Method",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "disbursementReferenceNo",
      label: "Disbursement Ref.No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allLoanDisbursement[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  return (
    <div className="Loan--disbursement">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={postLoanDisbursement}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <DecisonModal
        ApiCall={decide}
        message="Are you sure you want to authorize this record?"
        openBox={openDecision}
        actionMsg="Your records were authorized successfully"
        setOpenBoxState={setOpenDecision}
        errorMessage={message}
      />
      <div className="headerdiv">
        <p>Loan Disbursement</p>
      </div>
      <Paper style={{ marginTop: 20, padding: 25 }}>
        {screenChange === 1 && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2} md={4}>
              <label>Disbursement Method</label>
              <br />
              <select
                disabled
                className={errors.disbursementMethod ? "error-input-z" : null}
                name="disbursementMethod"
                value={disbursement.disbursementMethod}
                onChange={handleChange}
              >
                <option value="">Select a method</option>
                <option value="Transfer">Account transfer</option>
                <option value="Cash">By Cash</option>
              </select>
              {errors.disbursementMethod && (
                <ErrorMessage message={errors.disbursementMethod} />
              )}
            </Grid>
            <Grid item xs={12} sm={10} md={8}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Customer Account No.</label>
              <br />
              <input
                type="text"
                className={invalidAccount ? "error-input-z" : null}
                value={accNum}
                onChange={handleAccNum}
              />
              <ErrorMessage message={invalidAccount} />
            </Grid>
            {allLoanAccountNum.length > 0 && (
              <Grid item xs={12} sm={4} md={4}>
                <label>Loan Account No</label>
                <br />
                <select
                  className={errors.loanAccountNumber ? "error-input-z" : null}
                  name="loanAccountNumber"
                  value={disbursement.loanAccountNumber}
                  onChange={handleChange}
                >
                  <option value="">Select an account number</option>
                  {allLoanAccountNum.map((el, i) => (
                    <option key={i} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </select>
                {errors.loanAccountNumber && (
                  <ErrorMessage message={errors.loanAccountNumber} />
                )}
              </Grid>
            )}
            {details.accountName && (
              <Grid item xs={12} sm={4} md={4}>
                <label>Customer Name</label>
                <br />
                <input disabled type="text" value={details.accountName} />
              </Grid>
            )}
            {details.accountBranch && (
              <Grid item xs={12} sm={4} md={2}>
                <label>Branch</label>
                <br />
                <input disabled type="text" value={details.accountBranch} />
              </Grid>
            )}
            {details.currencyCode && (
              <Grid item xs={12} sm={4} md={2}>
                <label>Loan Currency</label>
                <br />
                <input disabled type="text" value={details.currencyCode} />
              </Grid>
            )}
            {details.amount && (
              <Grid item xs={12} sm={4} md={2}>
                <label>Loan Amount</label>
                <br />
                <input disabled type="text" value={details.amount} />
              </Grid>
            )}
            {details.amount && (
              <Grid item xs={12} sm={4} md={2}>
                <label>Amount Disbursed</label>
                <br />
                <input disabled type="text" value={sumLoanDisbursed} />
              </Grid>
            )}
            {disbursement.disbursementMethod === "Cash" && (
              <Grid item xs={12} sm={4} md={4}>
                <label>Teller Till Account</label>
                <br />
                <input
                  type="text"
                  name="tellerTillAccount"
                  value={disbursement.tellerTillAccount}
                  onChange={handleChange}
                />
              </Grid>
            )}
            {disbursement.disbursementMethod === "Transfer" && (
              <Grid item xs={12} sm={4} md={4}>
                <label>Credit Account Number</label>
                <br />
                <input
                  type="text"
                  className={errors.creditAccount ? "error-input-z" : null}
                  name="creditAccount"
                  value={disbursement.creditAccount}
                  onChange={handleChange}
                />
                {errors.creditAccount && (
                  <ErrorMessage message={errors.creditAccount} />
                )}
              </Grid>
            )}
            {creditAccountDetails.accountName &&
              disbursement.disbursementMethod === "Transfer" && (
                <Grid item xs={12} sm={6} md={4}>
                  <label>Account Name</label>
                  <br />
                  <input
                    type="text"
                    value={creditAccountDetails.accountName}
                    disabled
                  />
                </Grid>
              )}
            {creditAccountDetails.accountBranch &&
              disbursement.disbursementMethod === "Transfer" && (
                <Grid item xs={12} sm={3} md={4}>
                  <label>Account Branch</label>
                  <br />
                  <input
                    type="text"
                    value={creditAccountDetails.accountBranch}
                    disabled
                  />
                </Grid>
              )}
            {creditAccountDetails.currencyCode &&
              disbursement.disbursementMethod === "Transfer" && (
                <Grid item xs={12} sm={3} md={4}>
                  <label>Account Currency</label>
                  <br />
                  <input
                    type="text"
                    value={creditAccountDetails.currencyCode}
                    disabled
                  />
                </Grid>
              )}
            {creditAccountDetails.currencyCode && (
              <Grid item xs={12} sm={4} md={4}>
                <label>Disbursement Amount</label>
                <select value={creditAccountDetails.currencyCode} disabled>
                  <option value=''>Select a currency</option>
                  {allCurrencyDefinition.map((item) => (
                    <option value={item.currencyCode} key={item.id}>
                      {item.currencyName}
                    </option>
                  ))}
                </select>
              </Grid>
            )}
            {creditAccountDetails.currencyCode && (
              <Grid item xs={12} sm={4} md={4}>
                <label style={{ color: "white" }}>Loan Account No</label>
                <br />
                <input
                  disabled
                  type="text"
                  className={errors.amount ? "error-input-z" : null}
                  name="amount"
                  value={disbursement.amount}
                  onChange={handleChange}
                />
                {errors.amount && <ErrorMessage message={errors.amount} />}
              </Grid>
            )}
            {disbursement.disbursementMethod === "Transfer" && (
              <button className="btnz" onClick={handleSubmit}>
                Disburse Loan
              </button>
            )}
            {disbursement.disbursementMethod === "Cash" && (
              <button
                className={` btn ${screenChange === 1 ? "active-1" : ""}`}
              >
                Next
              </button>
            )}
          </Grid>
        )}
        {screenChange === 2 && (
          <div>
            <Grid container spacing={1} className="sub-main">
              <Grid item xs={12} sm={12} md={12}>
                <div className="sect1">
                  <div>
                    <span>Amount</span>
                    <input/>
                  </div>
                  <div>
                    <span>Net Amount</span>
                    <input/>
                  </div>
                </div>
              </Grid>

              <Grid item xs={2} sm={2} md={2}>
                <input/>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&times;</span>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&#61;</span>
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                <input />
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                {" "}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input/>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&times;</span>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&#61;</span>
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                <input />
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                {" "}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input/>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&times;</span>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&#61;</span>
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                <input />
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                {" "}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input/>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&times;</span>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&#61;</span>
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                <input />
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                {" "}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input/>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&times;</span>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <input />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <span>&#61;</span>
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                <input />
              </Grid>
              <Grid item xs={2} sm={3} md={3}>
                {" "}
              </Grid>
            </Grid>
            <button className="btn--1">
              Previous
            </button>
            <button className="btn--2">Disburse Loan</button>
          </div>
        )}
      </Paper>
       {!loading && (
        <Table
          title="Loan Disbursement"
          subtitle=""
          name="List of Disbursement"
          records={allLoanDisbursement}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details}/>}
    </div>
  );
};
export default LoanDisbursement;

import React, { useState, useCallback } from "react";
import "./cabal.scss";
import Table from "../../../../DatagridTable/table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../../../Backdrop/index";
import ErrorMessage from "../../../../ErrorMessage";
import { getAllBalanceHistory } from "../../../../../Api/Hooks-manager/eodReports/balanceSheet";
import { isLoading } from "../../../../../Api/redux-manager/actions";
import { formattedDate } from "../../../../../formatter/date";
import moment from "moment";

const TrialBalance = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.approve.loading);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [data, setData] = useState({
    workDate: "",
  });
  const [allCustomerBalanceReports, setAllCustomerBalanceReports] =
    useState(null);
  const [errors, setErrors] = useState({});

  const columns = [
    {
        name: "sn",
        label: "S/N",
        options: {
          filter: true,
          sort: false,
        },
      },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "accountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountProductcode",
      label: "Account Product Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountType",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "workDate",
      label: "Work Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <p>{formattedDate(value)}</p>,
      },
    },
    {
      name: "openingBalance",
      label: "Opening  Balance",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "closingBalance",
      label: "Closing Balance",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "accountOfficer",
      label: "Account Officer",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const validateData = () => {
    const error = {};
    let errorCount = 0;
    const { workDate } = data;
    if (workDate === "") {
      error.workDate = "Field is required";
      errorCount = errorCount + 1;
    }
    setErrors(error);
    if (errorCount) {
      return false;
    }
    return true;
  };

  const handleReports = () => {
    const checkErrors = validateData();
    if (checkErrors || loading) {
      fetchAllCustomerBalance();
      console.log("i am livid");
    }
    console.log("i am believer");
  };
  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const EmptyField = () => {
    setData({
      workDate: "",
    });
  };
  const handleReset = () => {
    EmptyField();

    setAllCustomerBalanceReports(false);
  };

  const fetchAllCustomerBalance = useCallback(async () => {
    dispatch(isLoading());
    const response = await getAllBalanceHistory(token, data);
    console.log(response, "this trial balance");
    if (response && response.data) {
      dispatch(isLoading());
      if (response && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
        }
      }
      setAllCustomerBalanceReports(response.data);
    }
  }, [data, token, dispatch]);

  let toDate = moment(data.workDate).format("MMMM Do YYYY");
  console.log(toDate, "this is date");

  return (
    <main className="cabal">
      <div className="headerdiv">
        <p> Account Reports | Customer Account Balance</p>
      </div>

      <div className="export-button"></div>
      <Spinner loading={loading} />

      <div className="profit">
        <div className="date-range">
          <div className="sub__range">
            <div className="sub__range__1">
              <div className="sub__range__2">
                <div className="date__from">
                  <label>Date</label>
                  <input
                    type="date"
                    name="workDate"
                    value={data.workDate}
                    onChange={handleChange("workDate")}
                  />
                  {errors.workDate && (
                    <ErrorMessage message={errors.workDate} />
                  )}
                </div>
              </div>
            </div>
            <div className="last-btn">
              <button onClick={handleReports}>Search</button>
              <button onClick={handleReset}>Reset</button>
            </div>
          </div>
          {allCustomerBalanceReports && (
            <div>
              <Table
                title={`Customer Account Balance as at ` + toDate}
                subtitle=""
                name="Customer Account Balance"
                records={allCustomerBalanceReports}
                options={options}
                columns={columns}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default TrialBalance;

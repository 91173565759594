import React, { useState, useEffect } from "react";
import "./Investmentbooking.scss";
import MenuItem from '@material-ui/core/MenuItem';

import useCustom from "../../../../Api/Hooks-manager/investmentAccountOperations/useCustom";
import useCustom3 from "../../../../Api/Hooks-manager/accountOpening/useCustom";
import useCustom2 from "../../../../Api/Hooks-manager/products/useCustom";
import Confirm from "../../../../Confirmbox/Confirm";
import Authorize from "../../../../Confirmbox/Confirm";
import DeleteModal from "../../../../Confirmbox/Confirm";
import SearchDropdown from "../../../SearchableDropdown/Search";
import Spinner from "../../../Backdrop/index";
import ErrorMessage from "../../../ErrorMessage";
import { validateInvestmentBooking } from "../../../../Api/validator/validate11";
import { format } from "date-fns";
import { add } from "date-fns";
import NumberFormat from "react-number-format";
import DotMenu from "../../../../commons/dotMenu/dotMenu";


import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  openViewModal,
} from "../../../../Api/redux-manager/actions";

import Table from "../../../DatagridTable/table";

import Details from "./view";


const Investmentbooking = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);

  const {
    errMessage,
    message,
    valuesGetExpectedInterest,
    getExpectedInterest,
    handleInvestmentBookingAuthorize,
    allInvestmentBooking,
    getInvestmentBookingAll,
    deleteInvestmentBooking,
    handleInvestmentBookingCreate,
    valuesInvestmentBooking,
    handleChange,
    setValuesInvestmentBooking,
    loader,
  } = useCustom();
  const [error, setError] = useState(false);
  const {
    allAccountProduct,
    getAccountDefinitionAll,
    allInvestmentProduct,
    getInvestmentProductsAll,
    loader: loader2,
  } = useCustom2();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openBox, setOpenBox] = React.useState(false);
  const [openAuthorize, setOpenAuthorize] = useState(false);
  const {
    accountFetchMessage,
    getAccountByAccountNumber,
    valuesStaticInvestmentBook,
    loader: loader3,
  } = useCustom3();

  // console.log('sssssssssssssssssss', valuesInvestmentBooking)
const newError = {};
const {maximumInterestRate, minimumInterestRate} = allInvestmentProduct;
const {FinalRate} = valuesInvestmentBooking;

// if (valuesInvestmentBooking.FinalRate < allInvestmentProduct.minimumInterestRate) {
//   error.FinalRate = "Value cannot be less than minimumInterestRate"
// }
// if (valuesInvestmentBooking.FinalRate > allInvestmentProduct.maximumInterestRate) {
//   error.FinalRate = "Value cannot be greater than minimumInterestRate"
// }

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "investmentAccountNumber",
      label: "Invest.Acc.Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customerAccountNumber",
      label: "Customer.Acc.Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Invest.Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "expectedInterestAmount",
      label: "Exp.Int.Amt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bookDate",
      label: "Book Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = allInvestmentBooking[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };


  const [
    valuesStaticInvestmentBook2,
    setValuesStaticInvestmentBook2,
  ] = useState({
    accName2: "",
    accBranch2: "",
    accCurrency2: "",
  });

  console.log(
    valuesInvestmentBooking,
    "valuesInvestmentBookingvaluesInvestmentBooking"
  );




  // useEffect(() => {
  //   const result = add(new Date(), {
  //     years: 0,
  //     months: 0,
  //     weeks: 0,
  //     days: valuesInvestmentBooking.Tenor,
  //   });
  //   let formattedDate = format(new Date(result), "yyyy-MM-dd");
  //   console.log(formattedDate, "formattedDateformattedDate");
  //   setValuesInvestmentBooking({
  //     ...valuesInvestmentBooking,
  //     BookDate: valuesInvestmentBooking.BookDate,
  //     MaturityDate: formattedDate,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [valuesInvestmentBooking.Tenor]);

  useEffect(() => {
    getInvestmentBookingAll();
  }, [getInvestmentBookingAll, updateTable]);

  const handleConfirm = () => {
    let checkError = validateInvestmentBooking(
      valuesInvestmentBooking,
      valueForUX
    );
    console.log(checkError, "checkErrorcheckError");
    setError(checkError);
    if (Object.keys(checkError).length > 0 || accountFetchMessage) {
      return;
    }
    setError({});
    setOpenBox(true);
  };



  useEffect(() => {
    if (valuesInvestmentBooking.CustomerAccountNumber.length === 10) {
      getAccountByAccountNumber(valuesInvestmentBooking.CustomerAccountNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesInvestmentBooking.CustomerAccountNumber]);

  useEffect(() => {
    const handleCreditedTo = () => {
      if (valuesInvestmentBooking.CreditInterestTo === "SameAccount") {
        getAccountByAccountNumber(
          valuesInvestmentBooking.CustomerAccountNumber
        );
        setValuesInvestmentBooking((valuesInvestmentBooking) => ({
          ...valuesInvestmentBooking,
          BeneficiaryAccountNumber:
            valuesInvestmentBooking.CustomerAccountNumber,
        }));
        setValuesStaticInvestmentBook2((valuesStaticInvestmentBook2) => ({
          ...valuesStaticInvestmentBook2,
          accName2: valuesStaticInvestmentBook.accName,
          accBranch2: valuesStaticInvestmentBook.accBranch,
          accCurrency2: valuesStaticInvestmentBook.accCurrency,
        }));
      }
    };
    handleCreditedTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesInvestmentBooking.CreditInterestTo]);

  useEffect(() => {
    valuesStaticInvestmentBook.accName &&
      setValuesInvestmentBooking((valuesInvestmentBooking) => ({
        ...valuesInvestmentBooking,
        CustomerAccountName: valuesStaticInvestmentBook.accName,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesStaticInvestmentBook.accName]);

  useEffect(() => {
    getInvestmentProductsAll();
  }, [getInvestmentProductsAll]);

  useEffect(() => {}, [allAccountProduct]);

  const filteredAllInvestmentProduct = allInvestmentProduct.filter(
    (items) => items.authorizationStatus === "Authorized"
  );

  const productAccountList = filteredAllInvestmentProduct?.map((item) => {
    return {
      value: item.productCode,
      label: `${item.productCode} - ${item.productName}`,
    };
  });

  const [valueForUX, setvalueForUX] = useState({});

  const handleChangeSelect = (value) => {
    let actualItem = filteredAllInvestmentProduct.filter(
      (item) => item.productCode === value
    );
    setvalueForUX(...actualItem);
    if (!actualItem[0].automaticRollover) {
      setValuesInvestmentBooking({
        ...valuesInvestmentBooking,
        AutoClose: true,
        Tenor: actualItem[0].defaultTenor,
        ProductCode: actualItem[0].productCode,
        RateBasis: actualItem[0].rateBasis,
      });
    }
    setValuesInvestmentBooking({
      ...valuesInvestmentBooking,
      Tenor: actualItem[0].defaultTenor,
      ProductCode: actualItem[0].productCode,
      RateBasis: actualItem[0].rateBasis,
    });
    // console.log('sssaaaaaaaassssss', valuesInvestmentBooking)
  };

  useEffect(() => {
    getAccountDefinitionAll();
  }, [getAccountDefinitionAll]);

  useEffect(() => {
    if (valueForUX && valueForUX.rateChatCode) {
      setValuesInvestmentBooking((valuesInvestmentBooking) => ({
        ...valuesInvestmentBooking,
        RateChatCode: valueForUX.rateChatCode,
        SystemRate: valueForUX.defaultInterestRate,
      }));
    }
    if (valueForUX && !valueForUX.rateChatCode) {
      setValuesInvestmentBooking((valuesInvestmentBooking) => ({
        ...valuesInvestmentBooking,
        RateChatCode: "",
        SystemRate: valueForUX.defaultInterestRate,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueForUX]);

  useEffect(() => {
    getInvestmentProductsAll();
  }, [getInvestmentProductsAll]);

  const handleFieldUpdate = (e) => {
    // console.log(e, "autocloserrrr");
    if (e === "autoClose") {
      setValuesInvestmentBooking({
        ...valuesInvestmentBooking,
        AutoClose: !valuesInvestmentBooking.AutoClose,
      });
    }
  };


  return (
    <div className="investment--booking border--main">
      <Confirm
        ApiCall={handleInvestmentBookingCreate}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
        errorMessage={errMessage}
        actionMsg={message}
      />
      <Authorize
        ApiCall={handleInvestmentBookingAuthorize}
        openBox={openAuthorize}
        message="Are you sure you want to authorize this record?"
        actionMsg="Your records were authorized successfully"
        setOpenBoxState={setOpenAuthorize}
      />
      <DeleteModal
        ApiCall={deleteInvestmentBooking}
        message="Are you sure you want to delete this record?"
        openBox={openDelete}
        setOpenBoxState={setOpenDelete}
        actionMsg="Your record was deleted successfully"
      />
      <div style={{ position: "absolute" }}>
        <Spinner loading={loader || loader2 || loader3} />
      </div>
      <div className="headerdiv">
        <p>Investment Booking</p>
      </div>
      <div style={{ padding: 25, boxShadow: "2px 2px 2px 2px white-smoke" }}>
        <section className="flex--4 main-container">
          <div className="flex--3">
            <label>Customer Account No.</label>
            <input
              type="num"
              onChange={(e) => handleChange(e, "booking")}
              name="CustomerAccountNumber"
              value={valuesInvestmentBooking.CustomerAccountNumber}
              className={`${
                (Object.keys(error).includes("CustomerAccountNumber") ||
                  accountFetchMessage) &&
                "error-input-z"
              }`}
              maxLength="10"
            />
            {error.CustomerAccountNumber && (
              <ErrorMessage message={error.CustomerAccountNumber} />
            )}
            {accountFetchMessage && (
              <ErrorMessage message={accountFetchMessage} />
            )}
          </div>
          <div className="flex--3">
            <label>Account Name</label>
            <input
              type="text"
              name="CustomerAccountName"
              value={valuesInvestmentBooking.CustomerAccountName}
              disabled
              className={`${
                Object.keys(error).includes("InvestmentProductName") &&
                "error-input-z"
              }`}
            />
          </div>
          <div className="flex--3">
            <label>Account Branch</label>
            <input
              type="text"
              disabled
              value={valuesStaticInvestmentBook.accBranch}
            />
          </div>
          <div className="flex--3">
            <label>Account Currency</label>
            <input
              type="text"
              onChange={(e) => handleChange(e, "booking")}
              name="CurrentBalance"
              disabled
              value={valuesStaticInvestmentBook.accCurrency}
              className={`${
                Object.keys(error).includes("CurrentBalance") && "error-input-z"
              }`}
            />
          </div>
          <div className="flex--3 investmentProduct">
            <label>Investment Product</label>
            {filteredAllInvestmentProduct.length === 0 && (
              <select>
                <option value="">Select, An Option</option>
                <option value="">No Authorized Record Found</option>
              </select>
            )}

            {filteredAllInvestmentProduct.length > 0 && (
              <>
                <div
                  className={`border--norm ${
                    Object.keys(error).includes("productAccount") &&
                    "error-input-z"
                  }`}
                >
                  <SearchDropdown
                    options={productAccountList}
                    onChange={handleChangeSelect}
                  />
                </div>
                {error.productAccount && (
                  <ErrorMessage message={error.productAccount} />
                )}
              </>
            )}
          </div>
          <div className="flex--3">
            <label>Investment Amount</label>
            <NumberFormat
              thousandSeparator={true}
              inputMode="numeric"
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={(value) => <div>{value}</div>}
              className={`${
                Object.keys(error).includes("Amount") && "error-input-z"
              }`}
              onValueChange={(values) => {
                setValuesInvestmentBooking((valuesInvestmentBooking) => ({
                  ...valuesInvestmentBooking,
                  Amount: parseInt(values.value),
                }));
              }}
            />
            {error.Amount && <ErrorMessage message={error.Amount} />}
          </div>
          <div className="flex--3">
            <label>Investment Start Date</label>
            
            <input
              type="date"
              onChange={(e) => handleChange(e, "StartDate")}
              name="StartDate"
              value={valuesInvestmentBooking.StartDate}
              className={`${
                Object.keys(error).includes("StartDate") && "error-input-z"
              }`}
            />
            {error.StartDate && <ErrorMessage message={error.StartDate} />}
          </div>
          <div className="flex--3">
            <label>Tenor (in Days)</label>
            <input
              type="number"
              onChange={(e) => handleChange(e, "booking")}
              name="Tenor"
              value={valuesInvestmentBooking.Tenor}
              className={`${
                Object.keys(error).includes("Tenor") && "error-input-z"
              }`}
            />
            {error.Tenor && <ErrorMessage message={error.Tenor} />}
          </div>
          <div className="flex--3 input--1">
            <label>Investment Maturity Date</label>
            <input
              type="date"
              onChange={handleChange}
              name="MaturityDate"
              disabled
              value={valuesInvestmentBooking.MaturityDate}
              className={`${
                Object.keys(error).includes("MaturityDate") && "error-input-z"
              }`}
            />
            {error.MaturityDate && (
              <ErrorMessage message={error.MaturityDate} />
            )}
          </div>
          {/* <div className="flex--3">
            <label>Rate Chart Code</label>
            <input
              type="text"
              onChange={(e) => handleChange(e, "booking")}
              name="RateChatCode"
              disabled
              value={valuesInvestmentBooking.RateChatCode}
              className={`${
                Object.keys(error).includes("RateChatCode") && "error-input-z"
              }`}
            />
          </div> */}
          <div className="flex--3">
            <label>System Rate</label>
            {/* <select style={{minWidth:250}} name='SystemRate' onChange={(e) => handleChange(e, 'booking')} className={`${Object.keys(errors).includes('SystemRate') && 'error-input-z'}`}>
                <option>select</option>
            </select> */}
            <input
              type="number"
              onChange={(e) => handleChange(e, "booking")}
              name="SystemRate"
              disabled
              value={valuesInvestmentBooking.SystemRate}
              className={`${
                Object.keys(error).includes("SystemRate") && "error-input-z"
              }`}
            />
          </div>
          <div className="flex--3">
            <label>Variance</label>
            <input
              type="number"
              onChange={(e) => handleChange(e, "booking")}
              name="Variance"
              value={valuesInvestmentBooking.Variance}
              // className={`${
              //   Object.keys(error).includes("Variance") && "error-input-z"
              // }`}
            />
            {/* {error.Variance && <ErrorMessage message={error.Variance} />} */}
          </div>
          <div className="flex--3">
            <label>Final Rate</label>
            <input
              type="number"
              onChange={(e) => handleChange(e, "booking")}
              name="FinalRate"
              disabled
              value={valuesInvestmentBooking.FinalRate}
              className={`${
                Object.keys(error).includes("FinalRate") && "error-input-z"
              }`}
            />
            {error.FinalRate && <ErrorMessage message={error.FinalRate} />}
          </div>
          <div className="flex--3">
            <label>Rate Basis</label>
            <input
              type="text"
              disabled={true}
              value={valuesInvestmentBooking.RateBasis}
              onChange={(e) => handleChange(e, "booking")}
              // name='FinalRate'
              // disabled
              // className={`${Object.keys(errors).includes('FinalRate') && 'error-input-z'}`}
            />
          </div>
            <div className="flex--3">
              <label>Interest Period</label>
              <select 
              onChange={(e) => handleChange(e, 'invest')}
              name='interestPeriod'
              value={valuesInvestmentBooking.interestPeriod}
              className={`${
                Object.keys(error).includes("interestPeriod") && "error-input-z"
              }`}
              >
                <option value="">Select an option</option>
                <option value='Actual'>Actual/Actual</option>
                <option value='Actual360'>Actual/360</option>
              </select>
              {error.interestPeriod && (
              <ErrorMessage message={error.interestPeriod} />
            )}
            </div>
          <div className="checboxdiv flex--2">
            <input
              type="checkbox"
              className="productcheckbox"
              onClick={() => handleFieldUpdate("autoClose")}
              checked={valuesInvestmentBooking.AutoClose}
              id="checkbox"
              readOnly
            />
            <label className="span" htmlFor="checkbox">
              Auto Close
            </label>
          </div>
        </section>
        <section className="section--2 flex--4">
          <div className="flex--3">
            <label>Maturity Amount</label>
            <NumberFormat
              className={`input--amount input-disable--2 ${
                Object.keys(error).includes("MaturityAmount") && "error-input-z"
              }`}
              value={valuesInvestmentBooking.MaturityAmount.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
            {error.MaturityAmount && (
              <ErrorMessage message={error.MaturityAmount} />
            )}
          </div>
          <div className="flex--3 input--1">
            <label>Expected Interest Amount</label>
            <NumberFormat
              className={`input--amount input-disable--2 ${
                Object.keys(error).includes("ExpectedInterestAmount") &&
                "error-input-z"
              }`}
              value={valuesInvestmentBooking.ExpectedInterestAmount.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
            {error.ExpectedInterestAmount && (
              <ErrorMessage message={error.ExpectedInterestAmount} />
            )}
          </div>
          <div className="button">
            <button
              onClick={getExpectedInterest}
              disabled={
                valuesGetExpectedInterest.finalRate === "" ||
                valuesInvestmentBooking.Amount === ""
              }
            >
              Apply
            </button>
          </div>
        </section>
        <section className="flex--3 next-section">
          <div className="flex--3 credit--to">
            <label>Credit Interest To</label>
            <select
              name="CreditInterestTo"
              onChange={(e) => handleChange(e, "booking")}
              className={`${
                Object.keys(error).includes("CreditInterestTo") &&
                "error-input-z"
              }`}
            >
              <option>Select An Option</option>
              <option value="SameAccount">Same Account</option>
              <option value="DifferentAccount">Different Account</option>
            </select>
            {error.CreditInterestTo && (
              <ErrorMessage message={error.CreditInterestTo} />
            )}
          </div>
          <div className="flex--4">
            {/* <div>
                <button className='checkbtn'>Apply</button>
            </div> */}
            <div className="flex--3">
              <label>Account No.</label>
              <input
                type="text"
                maxLength="10"
                onChange={(e) => handleChange(e, "booking")}
                name="BeneficiaryAccountNumber"
                value={valuesInvestmentBooking.BeneficiaryAccountNumber}
                className={`${
                  Object.keys(error).includes("BeneficiaryAccountNumber") &&
                  "error-input-z"
                }`}
              />
              {error.BeneficiaryAccountNumber && (
                <ErrorMessage message={error.BeneficiaryAccountNumber} />
              )}
            </div>
            <div className="flex--3">
              <label>Account Name</label>
              <input
                type="text"
                disabled
                name="accName2"
                value={valuesStaticInvestmentBook2.accName2}
                className={`${
                  Object.keys(error).includes("accName2") && "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Account Branch</label>
              <input
                type="text"
                name="accBranch2"
                disabled
                value={valuesStaticInvestmentBook2.accBranch2}
                className={`${
                  Object.keys(error).includes("accBranch2") && "error-input-z"
                }`}
              />
            </div>
            <div className="flex--3">
              <label>Account Currency</label>
              <input
                type="text"
                name="accCurrency2"
                disabled
                value={valuesStaticInvestmentBook2.accCurrency2}
                className={`${
                  Object.keys(error).includes("accCurrency2") && "error-input-z"
                }`}
              />
            </div>
          </div>
        </section>
        <div className="flex--1 btn-cont">
          <button className="btnz" onClick={handleConfirm}>
            {" "}
            Save
          </button>
        </div>
      </div>
          {!loading && (
        <Table
          title="Investment Booking"
          subtitle=""
          name="List of Investments"
          records={allInvestmentBooking}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
    </div>
  );
};
export default Investmentbooking;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Details from '../../../Details/CoreModules/CurrencyDefinition';
import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import { formattedDate } from "../../../../formatter/date";
import { useCallback } from "react";

const CurrencyDefinition = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyName",
      label: "Currency Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currencyCode",
      label: "Currency Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "numericCode",
      label: "Numeric Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllDefinedCurrency = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}organization/CurrencyDefinition/GetUnauthorized`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllDefinedCurrency();
  }, [fetchAllDefinedCurrency, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Currency Definition"
        subtitle=""
        name="List ofcurrencies"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer
        title="Currency Definition - Record Details"
      >
       <Details details={details}/> 
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize currency"
        question="Are you sure you want to authorize this currency?"
        url={`${baseUrl}organization/CurrencyDefinition/Authorize/ ${details.id}`}
      />
    </div>
  );
};

export default CurrencyDefinition;

import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import { getAllLienDetails } from "../../../../Api/Hooks-manager/Lien/lienApi";
import Table from "../../../DatagridTable/table";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { getCustomerByAccountNumber } from "../../../../Api/Hooks-manager/loanAccountOperations";
import {
  getBankParameterByName,
} from "../../../../Api/Hooks-manager/coreModules/coreModules";
import {
  postLienDetails,
  updateLienCustomerDetail,
} from "../../../../Api/Hooks-manager/Lien/lienApi";
import { format } from "date-fns";
import {
  openUpdateFormModal,
  openViewModal,
  isLoading,
  openResquestDecisionModal,
  openFormModal,
} from "../../../../Api/redux-manager/actions";
import RemovingLienModal from "../../../RemoveLienModal";
import {validateLienCode} from "../../../../Api/validator/validate11"


export default function BusinessLogic() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const [open, setOpen] = useState(false);
  const loading = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const [actionAccount, setActionAccount] = useState(null);
  const openRemoveLienModal = useSelector(state => state.approve.openForm);
  const [takeAction, setTakeAction] = useState(false);
  const branch = useSelector((state) => state.common.branch);
  const [viewAll, setViewAll] = useState({});
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [openDecision, setOpenDecision] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [openView, setOpenView] = useState({});
  const [editErrors, setEditErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [lienType, setLienType] = useState([]);


  const [errors, setErrors] = useState({});
  const [lienTable, setLienTable] = useState([]);
  const applicationDate = format(new Date(branch.workdate), "yyyy-MM-dd");
  const endDateApp = format(new Date(2100/1/1), "yyyy-MM-dd");
  // const [openModal, setOpenModal] = useState(false);
  // const [account, setAccount] = useState({
  //   accntNo: "",
  // });
  const [details, setDetails] = useState({});

  const [forms, setForms] = useState({
    accountNumber: "",
    status: true,
    startDate: applicationDate,
    endDate: applicationDate,
    amount: "",
    description: "",
    branchCode: "",
    lienCode: "",
  });



  

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lienCode",
      label: "Lien Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Lien Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "description",
    //   label: "Description",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleLien = lienTable[dataIndex];
                setEditDetails(singleLien);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleLien = lienTable[dataIndex];
                setDetails(singleLien);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleLien = lienTable[dataIndex];
                if(singleLien && singleLien.id){
                  setActionAccount(singleLien)
                  dispatch(openFormModal())
                }

              }}
            >
              Remove Lien
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const isValidLienCodeAlpha = (alpha) => {
    return /^[A-Za-z]{4}$/.test(alpha);
  };

  // const Data = {...forms,accountNumber:account.accntNo,status:true}
  // console.log(Data)
  //   console.log(response)
  //   if (response.message === "Success"){
  //     // reset logic//
  //     return true
  //   }
  //   return false

  const fetchAllCustomerDetails = useCallback(
    async (accNum) => {
      dispatch(isLoading());
      const response = await getCustomerByAccountNumber(token, accNum);
      if (response && response.data) {
        dispatch(isLoading());
        if (response.status) {
          if (
            response.data.authorizationStatus === "Authorized"
          ) {
            setErrors((prev) => ({ ...prev, accountNumber: "" }));
            setForms((prev) => ({
              ...prev,
              accountName: response.data.accountName,
              branchCode: response.data.branchCode,
            }));
          } else {
            clearAccountDetails();
            setErrors((prev) => ({
              ...prev,
              accountNumber: "Unauthorized account number",
            }));
            setForms((prev) => ({
              ...prev,
              ...prev,
              accountNumber: "",
              status: true,
              startDate: applicationDate,
              endDate: "",
              amount: "",
              description: "",
              branchCode: "",
              lienCode: "",
            }));
          }
          // setLoanFacilitylimit((prev) => ({...prev, creditAccountBranch:response.data.branchCode}))
        } else {
          clearAccountDetails();
          setErrors({ ...errors, accountNumber: response.message });
        }
      }
      // console.log('lien account details',forms)
    },
    [dispatch, token, forms]
  );

  //   const fetchAllCustomerDetails = useCallback(
  //     async (accNo) => {
  //       const response = await getCustomerByAccountNumber(token, accNo);
  //       if (response && response.data) {
  //         setForms({
  //           ...forms,
  //           accntName: response.data.accountName,
  //           accntStatus: response.data.accountstatus,
  //           accntBalance: response.data.accountBalance,
  //           description: response.data.description,
  //         });
  //       }
  //     },
  //     [token, forms]
  //   );

  const fetchAllLienTable = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}account/Lien/GetAll`;
    const response = await getAllLienDetails(token, url);
    if (response && response.data) {
      console.log("data is here", response.data);
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
          response.data[i].startDate = formattedDate(
            response.data[i].startDate
          );
          response.data[i].endDate = formattedDate(response.data[i].endDate);
        }
      }
      setLienTable(response.data);
    }
  }, [dispatch, token]);

  const SendLienDetails = async () => {
    dispatch(isLoading());
    const response = await postLienDetails(token, forms);
    setMessage(
      `A lien has been successfully placed on this account number: ${forms.accountNumber} and is awaiting authorization`
    );
    if (response && response.data) {
      dispatch(isLoading());
      if (response.message === "Success") {
        clearAccountDetails();
        fetchAllLienTable();
        setShow(false);
        return true;
      } else {
        setErrorMessage(response.message);
        return false;
      }
    }
  };

  useEffect(() => {
    //get customer types
    const parameter = "LIEN_CODE";
    const BankParameterByName = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await getBankParameterByName(user.jwtToken, parameter);
      // console.log(response)
      if (response && response.data.lieN_CODE) {
        setLienType(response.data.lieN_CODE);
      }
    };

    BankParameterByName();
  }, []);

  console.log(lienType);

  // const openEditDetails = (el) => {
  //   setEditDetails({
  //     id: el.id,
  //     accountNumber: el.accountNumber,
  //     accountName: el.accountName,
  //     accountCurrency: el.accountCurrency,
  //     accountBranch: el.accountBranch,
  //     approvedLoanLimit: el.approvedLoanLimit,
  //     // loanFacilityLimit: el.loanFacilityLimit,
  //   });
  //   setShowEditDetails(true);
  // };

  const validateUpdateDetails = () => {
    const error = {};

    const { accountNumber } = editDetails;

    if (accountNumber === "") {
      error.accountNumber = "Field is required";
    }

    if (accountNumber.length !== 10) {
      error.accountNumber = "Account number must be 10 digits";
    }
    // if(loanLimitBalance === ""){
    //   errors.loanLimitBalance = "Field is required";
    // }
    // if(Number(loanLimitBalance) > Number(approvedLoanLimit)){
    //   errors.loanLimitBalance = "Loan limit balance cannot be greater than the approved loan limit"
    // }

    return error;
  };

  console.log(forms, "form data is here");

  const clearAccountDetails = () => {
    setForms({
      accountNumber: "",
      status: "",
      startDate: applicationDate,
      endDate: applicationDate,
      amount: "",
      description: "",
      branchCode: "",
      lienCode: "",
    });
  };

  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setForms((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditDetailsChange = (e) => {
    const { value, name } = e.target;
    setEditDetails({
      ...editDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    if (forms.accountNumber.length === 10) {
      fetchAllCustomerDetails(forms.accountNumber);
    }
  }, [forms.accountNumber]);

  useEffect(() => {
    fetchAllLienTable();
  }, [fetchAllLienTable, updateTable]);

  const validateData = () => {
    const errors = {};
    const {
      amount,
      endDate,
      description,
      branchCode,
      status,
      lienCode,
      accountNumber,
    } = forms;
    if (endDate === "") {
      errors.endDate = "Field is required";
    }
    if (amount === "") {
      errors.amount = "Field is required";
    }
    // if (description === "") {
    //   errors.description = "Field is required";
    //   errorCount = errorCount + 1;
    // }

    if (branchCode === "") {
      errors.branchCode = "Field is required";
    }
    // if (!isValidLienCodeAlpha(lienCode)) { 
    //   errors.lienCode = 'Field should be alphanumeric';
    // }
  
    // if(lienCode.length !== 4){
    //   errors.lienCode = "Lien code must have 4 characters"
    // }
    // if(lienCode === ""){
    //   errors.lienCode = "Field is required"
    // }
    if (status === "") {
      errors.status = "Field is required";
    }
    if (accountNumber.length !== 10 || accountNumber === "") {
      errors.accountNumber = "Field is required";
    }

    return errors;
  };

  const handleSubmit = () => {
    const checkErrors = validateData();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    setOpen(true);
    console.log("i am believer");
  };

  const handleDetailsUpdateSubmit = () => {
    const checkErrors = validateUpdateDetails();
    setEditErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) return;
    // setEditErrors({});
    dispatch(openResquestDecisionModal());
  };

  const postEditDetails = async () => {
    dispatch(isLoading());
    const response = await updateLienCustomerDetail(
      token,
      editDetails,
      editDetails.id
    );
    if (response && response.data) {
      dispatch(isLoading());
      clearAccountDetails();
      setMessage(response.message);
      return true;
    }
  };

  return {
    details,
    editDetails,
    errors,
    editErrors,
    errorMessage,
    loading,
    options,
    columns,
    open,
    setOpen,
    takeAction,
    message,
    handleChange,
    SendLienDetails,
    forms,
    lienTable,
    handleSubmit,
    handleEditDetailsChange,
    handleDetailsUpdateSubmit,
    postEditDetails,
    showEditDetails,
    setShowEditDetails,
    RemovingLienModal,
    actionAccount,
    setActionAccount,
    openRemoveLienModal,
    lienType,
  };
}

import { useState } from "react";
import "./Sidebar.scss";
import { BsGridFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import vaultSvg from "../../../assets/money.svg";
import Permissions from "./permissions";

const Sidebar = ({ toggle }) => {
  const [toggler, setToggler] = useState(true);

  const handleToggle = () => {
    setToggler(!toggler);
  };

  const {
    securityManagement,
    AuthorizeSecurityManagement,
    manageRoles,
    authorizeManageRole,
    userCreation,
    AuthorizeUserCreation,
    userTellerTills,
    authorizeUserTellerTills,
    branchTellerTills,
    authorizeBranchTellerTills,
    miscellaneoustransactions,
    authorizeMiscellanousTransaction,
    eodTmeProcess,
    reports,
    loanAccountOperations,
    authorizeLoanAccountOperations,
    loanBooking,
    authorizeLoanBooking,
    loanDisbursement,
    authorizeLoanDisbursement,
    loanRepayment,
    authorizeLoanRepayment,
    loanFacilityLimit,
    authorizeLoanFacilityLimit,
    products,
    authorizeProducts,
    accountProduct,
    authorizeAccountProduct,
    loanProduct,
    authorizeLoanProduct,
    investmentProduct,
    authorizeInvestmentProduct,
    tellerProductDefinition,
    authorizeTellerProductDefinition,
    investmentAccountOperations,
    authorizeInvestmentAccountOperations,
    customerAccountOperations,
    authorizeCustomerAccountOperations,
    accountOpening,
    authorizeAccountOpening,
    accounEnquiry,
    accountStatement,
    allAccountEnquiry,
    authorizeAccountEnquiry,
    lienBock,
    authorizeLienBlock,
    coreModules,
    authorizeCoreModules,
    institutionDefinition,
    authorizeInstitutionDefinition,
    branchDefinition,
    authorizeBranchDefinition,
    bankCalendar,
    authorizeBankCalendar,
    branchCalendar,
    authorizeBranchCalendar,
    chartsofAccount,
    authorizeChartsofAccount,
    countryDefintiion,
    authorizeCountryDefinition,
    currencyDefinition,
    authorizeCurrencyDefinition,
    denominationDefinition,
    authorizeDenominationDefinition,
    periodDefinition,
    authorizePeriodDefinition,
    fiscalYear,
    authorizeFiscalYear,
    parameterSettings,
    authorizeParameterSettings,
    customerCategory,
    authorizeCustomerCategory,
    rateCharts,
    authorizeRateCharts,
    bankClearingCodes,
    authorizeBankClearingCodes,
  } = Permissions();

  return (
    <div className={`sidebar ${toggle && "hide-sidebar"}`}>
      <div className="main--2 flex--3">
        <div className="icon-p">
          <label htmlFor="btn--1" className="each-link each-link--2 flex--2">
            <NavLink
              exact
              className="flex--2"
              to="/dashboard/home"
              activeClassName="active"
            >
              <div className="icon">
                <BsGridFill className="bsfill" />
              </div>
              <p>Dashboard</p>
            </NavLink>
          </label>
          <input id="btn--1" type="checkbox" />
        </div>
        {miscellaneoustransactions && (
          <div className="icon-p">
            <label htmlFor="btn--23" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Cash Transactions-LCY</p>
            </label>
            <input id="btn--23" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-lcy/001"
                  activeClassName="active"
                >
                  <li>Cash Deposit</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-lcy/002"
                  activeClassName="active"
                >
                  <li>Cash Withdrawal</li>
                </NavLink>
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-lcy/004"
                  activeClassName="active"
                >
                  <li>Cheque Transaction</li>
                </NavLink> */}

                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-lcy/005"
                  activeClassName="active"
                >
                  <li>Miscellaneous Teller Transaction</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
        {/* {miscellaneoustransactions && (
          <div className="icon-p">
            <label
              htmlFor="btn--231"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Cash Transactions-FCY</p>
            </label>
            <input id="btn--231" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-fcy/001"
                  activeClassName="active"
                >
                  <li>Cash Deposit</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-fcy/002"
                  activeClassName="active"
                >
                  <li>Cash Withdrawal</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-fcy/003"
                  activeClassName="active"
                >
                  <li>Fund Transfer</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/cashtransactions-fcy/004"
                  activeClassName="active"
                >
                  <li>Cheque Transaction</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )} */}
        {miscellaneoustransactions && (
          <div className="icon-p">
            <input id="btn--7" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/customeraccount/1"
                  activeClassName="active"
                >
                  <li>MC-Issuance</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/customeraccount/2"
                  activeClassName="active"
                >
                  <li>MC-Liquidation</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/customeraccount/3"
                  activeClassName="active"
                >
                  <li>MC-Liquidation Walkin</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/customeraccount/4"
                  activeClassName="active"
                >
                  <li>MC-Branch Unprinted</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
        {miscellaneoustransactions && (
          <div className="icon-p">
            <label htmlFor="btn--9" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Miscellaneous Transactions</p>
            </label>
            <input id="btn--9" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/customercredit"
                  activeClassName="active"
                >
                  <li>Customer Credit</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/customerdebit"
                  activeClassName="active"
                >
                  <li>Customer Debit</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/gltransfers"
                  activeClassName="active"
                >
                  <li>GL Transfers</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/fundstransfer"
                  activeClassName="active"
                >
                  <li>Fund Transfer</li>
                </NavLink>
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/tellertransaction"
                  activeClassName="active"
                >
                  <li>Teller Transaction</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/singledebitmultiplecredit"
                  activeClassName="active"
                >
                  <li>Single Debit Multiple Credit</li>
                </NavLink> */}
                {/* <NavLink className="each-link each-link--2 flex--2" to="/dashboard/miscellaneoustransactions/bulkjournalposting" activeClassName="active">
                                <li>Bulk Journal Posting</li>
                            </NavLink>  */}
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/miscellaneoustransactions/bulktransaction"
                  activeClassName="active"
                >
                  <li>Bulk Transaction Posting</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
        {miscellaneoustransactions && (
          <div className="icon-p">
            <label htmlFor="btn--4" className="each-link each-link--2 flex--2">
              <img src={vaultSvg} alt="" />
              <p>Vault Operations</p>
            </label>
            <input id="btn--4" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/vaultoperations/001"
                  activeClassName="active"
                >
                  <li>Buy Cash</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/vaultoperations/002"
                  activeClassName="active"
                >
                  <li>Sell Cash</li>
                </NavLink>
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/vaultoperations/003"
                  activeClassName="active"
                >
                  <li>Denomination Exchange</li>
                </NavLink> */}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/vaultoperations/005"
                  activeClassName="active"
                >
                  <li>Till Management</li>
                </NavLink> */}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/vaultoperations/004"
                  activeClassName="active"
                >
                  <li>Teller Till Exchange</li>
                </NavLink> */}
              </ul>
            </div>
          </div>
        )}
        {customerAccountOperations && (
          <div className="icon-p">
            <label htmlFor="btn--10" className="each-link each-link--2 flex--2">
              <img src={vaultSvg} alt="" />
              <p>Customer Account Operations</p>
            </label>
            <input id="btn--10" type="checkbox" />
            <div className="sub-link">
              <ul>
                {(accountOpening || accounEnquiry) && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/customeraccountoperations/accountopening"
                    activeClassName="active"
                  >
                    <li>Account Opening</li>
                  </NavLink>
                )}
                {(accountOpening || accounEnquiry) && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/customeraccountoperations/accountenquiry"
                    activeClassName="active"
                  >
                    <li>Account Enquiry</li>
                  </NavLink>
                )}
                {allAccountEnquiry && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/customeraccountoperations/allaccountenquiry"
                    activeClassName="active"
                  >
                    <li>All Account Enquiry</li>
                  </NavLink>
                )}
                {lienBock && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/customeraccountoperations/lienamountblock"
                    activeClassName="active"
                  >
                    <li>Lien/Amount Block</li>
                  </NavLink>
                )}

                {accountStatement && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/customeraccountoperations/accountstatement"
                    activeClassName="active"
                  >
                    <li> Account Statement</li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
        )}
        {authorizeCustomerAccountOperations && (
          <div className="icon-p">
            <label htmlFor="btn--21" className="each-link each-link--2 flex--2">
              <img src={vaultSvg} alt="" />
              <p>Customer Account Operations<br/> Authorization</p>
            </label>
            <input id="btn--21" type="checkbox" />
            <div className="sub-link">
              <ul>
                {authorizeAccountEnquiry && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeaccountoperations/accountenquiry"
                    activeClassName="active"
                  >
                    <li>Account Enquiry</li>
                  </NavLink>
                )}
              </ul>
              <ul>
                {authorizeAccountEnquiry && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeaccountoperations/lienAccountBlock"
                    activeClassName="active"
                  >
                    <li>Lien/Amount Block</li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
        )}
        {investmentAccountOperations && (
          <div className="icon-p">
            <label htmlFor="btn--11" className="each-link each-link--2 flex--2">
              <img src={vaultSvg} alt="" />
              <p>Investment Account Operations</p>
            </label>
            <input id="btn--11" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/investmentaccountoperations/investmentbooking"
                  activeClassName="active"
                >
                  <li>Investment Booking</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/investmentaccountoperations/investmentamendment"
                  activeClassName="active"
                >
                  <li>Investment Amendment</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/investmentaccountoperations/investmentliquidation"
                  activeClassName="active"
                >
                  <li>Investment Liquidation</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
        {authorizeInvestmentAccountOperations && (
          <div className="icon-p">
            <label
              htmlFor="btn--authinvestmentoperaton"
              className="each-link each-link--2 flex--2"
            >
              <img src={vaultSvg} alt="" />
              <p>Investment Account Operations<br/> Authorization</p>
            </label>
            <input id="btn--authinvestmentoperaton" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizeinvestmentaccountoperations/investmentbooking"
                  activeClassName="active"
                >
                  <li>Investment Booking</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizeinvestmentaccountoperations/investmentliquidation"
                  activeClassName="active"
                >
                  <li>Investment Liquidation</li>
                </NavLink>
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizeinvestmentaccountoperations/investmentamendment"
                  activeClassName="active"
                >
                  <li>Investment Amendment</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizeinvestmentaccountoperations/investmentliquidation"
                  activeClassName="active"
                >
                  <li>Investment Liquidation</li>
                </NavLink> */}
              </ul>
            </div>
          </div>
        )}
        {loanAccountOperations && (
          <div className="icon-p">
            <label htmlFor="btn--12" className="each-link each-link--2 flex--2">
              <img src={vaultSvg} alt="" />
              <p>Loan Account Operations</p>
            </label>
            <input id="btn--12" type="checkbox" />
            <div className="sub-link">
              <ul>
                {loanBooking && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/loanaccountoperations/loanbooking"
                    activeClassName="active"
                  >
                    <li>Loan Booking</li>
                  </NavLink>
                )}
                {loanDisbursement && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/loanaccountoperations/loandisbursement"
                    activeClassName="active"
                  >
                    <li>Loan Disbursement</li>
                  </NavLink>
                )}
                {loanRepayment && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/loanaccountoperations/loanrepayment"
                    activeClassName="active"
                  >
                    <li>Loan Repayment</li>
                  </NavLink>
                )}
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/loanaccountoperations/loanramendment"
                  activeClassName="active"
                >
                  <li>Loan Amendment</li>
                </NavLink>
                {loanRepayment && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/loanaccountoperations/loanscheduledetails"
                    activeClassName="active"
                  >
                    <li>Loan Schedule Details</li>
                  </NavLink>
                )}
                {loanFacilityLimit && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/loanaccountoperations/loanfacilitylimit"
                    activeClassName="active"
                  >
                    <li>Loan Facility Limit</li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
        )}

        {authorizeLoanAccountOperations && (
          <div className="icon-p">
            <label
              htmlFor="btn--authloanacctoperations"
              className="each-link each-link--2 flex--2"
            >
              <img src={vaultSvg} alt="" />
              <p>Loan Account Operations<br/> Authorization</p>
            </label>
            <input id="btn--authloanacctoperations" type="checkbox" />
            <div className="sub-link">
              <ul>
                {authorizeLoanBooking && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeloanaccountoperations/loanbooking"
                    activeClassName="active"
                  >
                    <li>Loan Booking</li>
                  </NavLink>
                )}
                {authorizeLoanDisbursement && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeloanaccountoperations/loandisbursement"
                    activeClassName="active"
                  >
                    <li>Loan Disbursement</li>
                  </NavLink>
                )}
                {authorizeLoanRepayment && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeloanaccountoperations/loanrepayment"
                    activeClassName="active"
                  >
                    <li>Loan Repayment</li>
                  </NavLink>
                )}
                {authorizeLoanFacilityLimit && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeloanaccountoperations/loanfacilitylimit"
                    activeClassName="active"
                  >
                    <li>Loan Facility Limit</li>
                  </NavLink>
                )}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizeloanaccountoperations/loanramendment"
                  activeClassName="active"
                >
                  <li>Loan Amendment</li>
                </NavLink> */}
              </ul>
            </div>
          </div>
        )}
        {/* <div className='icon-p'>
                    <label htmlFor='btn--5' className="each-link each-link--2 flex--2">
                        <svg className='bsfill' xmlns="http://www.w3.org/2000/svg" width="36" height="24" viewBox="0 0 36 24"><defs><style></style></defs><g transform="translate(0 -0.128)"><g transform="translate(0 0.128)"><path className="a" d="M18.461,18H5.539C2.485,18,0,16.137,0,13.846V0L5.619,1.167A7.843,7.843,0,0,0,7.933,5.76a6.564,6.564,0,0,0,4.528,1.855A6.86,6.86,0,0,0,18.446,3.83L24,4.983v8.863C24,16.137,21.515,18,18.461,18Z" transform="translate(18 24) rotate(-90)"/><path className="a" d="M18.461,18H5.539C2.485,18,0,16.137,0,13.846V0L5.619,1.167A7.843,7.843,0,0,0,7.933,5.76a6.564,6.564,0,0,0,4.528,1.855A6.86,6.86,0,0,0,18.446,3.83L24,4.983v8.863C24,16.137,21.515,18,18.461,18Z" transform="translate(18 0) rotate(90)"/></g></g></svg>
                        <p>Cheque Transactions</p>
                    </label>
                    <input id='btn--5' type='checkbox'/>
                    <div className='sub-link'>
                        <ul>
                        <NavLink className="each-link each-link--2 flex--2" to="/dashboard/chequetransaction/001" activeClassName="active">
                                <li>Cheque Deposit</li>
                            </NavLink> 
                            <NavLink className="each-link each-link--2 flex--2" to="/dashboard/chequetransaction/002" activeClassName="active">
                                <li>Cheque Withdrawal(Clearing)</li>
                            </NavLink> 
                        </ul>
                    </div>
                </div> */}
        {products && (
          <div className="icon-p">
            <label htmlFor="btn--8" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Products</p>
            </label>
            <input id="btn--8" type="checkbox" />
            <div className="sub-link">
              <ul>
                {accountProduct && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/products/account"
                    activeClassName="active"
                  >
                    <li>Account Products</li>
                  </NavLink>
                )}
                {loanProduct && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/products/loan"
                    activeClassName="active"
                  >
                    <li>Loan Products</li>
                  </NavLink>
                )}
                {investmentProduct && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/products/investment"
                    activeClassName="active"
                  >
                    <li>Investment Products</li>
                  </NavLink>
                )}
                {/* {tellerProductDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/products/teller"
                    activeClassName="active"
                  >
                    <li>Teller Product Definition</li>
                  </NavLink>
                )} */}
              </ul>
            </div>
          </div>
        )}

        {authorizeProducts && (
          <div className="icon-p">
            <label
              htmlFor="btn--authproducts"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Products Authorization</p>
            </label>
            <input id="btn--authproducts" type="checkbox" />
            <div className="sub-link">
              <ul>
                {authorizeAccountProduct && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeproducts/account"
                    activeClassName="active"
                  >
                    <li>Account Products</li>
                  </NavLink>
                )}
                {authorizeLoanProduct && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeproducts/loan"
                    activeClassName="active"
                  >
                    <li>Loan Products</li>
                  </NavLink>
                )}
                {authorizeInvestmentProduct && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeproducts/investment"
                    activeClassName="active"
                  >
                    <li>Investment Products</li>
                  </NavLink>
                )}
                {/* {authorizeTellerProductDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizeproducts/teller"
                    activeClassName="active"
                  >
                    <li>Teller Product Definition</li>
                  </NavLink>
                )} */}
              </ul>
            </div>
          </div>
        )}
        {coreModules && (
          <div className="icon-p">
            <label htmlFor="btn--6" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Core Module</p>
            </label>
            <input id="btn--6" type="checkbox" />
            <div className="sub-link">
              <ul>
                {institutionDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/1"
                    activeClassName="active"
                  >
                    <li>Institution Definition</li>
                  </NavLink>
                )}
                {branchDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/branchdefinition"
                    activeClassName="active"
                  >
                    <li>Branch Definition</li>
                  </NavLink>
                )}
                {bankCalendar && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/bankcalendar"
                    activeClassName="active"
                  >
                    <li>Bank Calendar</li>
                  </NavLink>
                )}
                {branchCalendar && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/branchcalendar"
                    activeClassName="active"
                  >
                    <li>Branch Calendar</li>
                  </NavLink>
                )}
                {chartsofAccount && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/chartofaccount"
                    activeClassName="active"
                  >
                    <li>Charts Of Accounts</li>
                  </NavLink>
                )}
                {countryDefintiion && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/countdefinition"
                    activeClassName="active"
                  >
                    <li>Country Definition</li>
                  </NavLink>
                )}
                {currencyDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/currencydefinition"
                    activeClassName="active"
                  >
                    <li>Currency Definition</li>
                  </NavLink>
                )}
                {denominationDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/denominationdefinition"
                    activeClassName="active"
                  >
                    <li>Denomination Definition</li>
                  </NavLink>
                )}
                {periodDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/creatableperiods"
                    activeClassName="active"
                  >
                    <li>Period Definition</li>
                  </NavLink>
                )}
                {fiscalYear && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/fiscalyear"
                    activeClassName="active"
                  >
                    <li>Fiscal Year</li>
                  </NavLink>
                )}
                {parameterSettings && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/parametersettings"
                    activeClassName="active"
                  >
                    <li>Parameter Settings</li>
                  </NavLink>
                )}
                {customerCategory && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/customercategory"
                    activeClassName="active"
                  >
                    <li>Customer Category</li>
                  </NavLink>
                )}
                {rateCharts && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/rateChart"
                    activeClassName="active"
                  >
                    <li>Rate Charts</li>
                  </NavLink>
                )}
                {bankClearingCodes && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/coremodule/bankclearingcodes"
                    activeClassName="active"
                  >
                    <li>Bank Clearing Codes</li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
        )}
        {authorizeCoreModules && (
          <div className="icon-p">
            <label htmlFor="btn--20" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p> Core Module<br/> Authorization</p>
            </label>
            <input id="btn--20" type="checkbox" />
            <div className="sub-link">
              <ul>
                {authorizeInstitutionDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/1"
                    activeClassName="active"
                  >
                    <li>Institution Definition</li>
                  </NavLink>
                )}
                {authorizeBranchDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/branchdefinition"
                    activeClassName="active"
                  >
                    <li>Branch Definition</li>
                  </NavLink>
                )}
                {authorizeBankCalendar && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/bankcalendar"
                    activeClassName="active"
                  >
                    <li>Bank Calendar</li>
                  </NavLink>
                )}
                {authorizeBranchCalendar && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/branchcalendar"
                    activeClassName="active"
                  >
                    <li>Branch Calendar</li>
                  </NavLink>
                )}
                {authorizeChartsofAccount && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/chartofaccount"
                    activeClassName="active"
                  >
                    <li>Charts Of Accounts</li>
                  </NavLink>
                )}
                {authorizeCurrencyDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/currencydefinition"
                    activeClassName="active"
                  >
                    <li>Currency Definition</li>
                  </NavLink>
                )}
                {authorizeDenominationDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/denominationdefinition"
                    activeClassName="active"
                  >
                    <li>Denomination Definition</li>
                  </NavLink>
                )}
                {authorizePeriodDefinition && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/creatableperiods"
                    activeClassName="active"
                  >
                    <li>Period Definition</li>
                  </NavLink>
                )}
                {authorizeFiscalYear && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/fiscalyear"
                    activeClassName="active"
                  >
                    <li>Fiscal Year</li>
                  </NavLink>
                )}
                {authorizeParameterSettings && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/parametersettings"
                    activeClassName="active"
                  >
                    <li>Parameter Settings</li>
                  </NavLink>
                )}
                {authorizeCustomerCategory && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/customercategory"
                    activeClassName="active"
                  >
                    <li>Customer Category</li>
                  </NavLink>
                )}
                {authorizeRateCharts && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizecoremodule/rateChart"
                    activeClassName="active"
                  >
                    <li>Rate Charts</li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
        )}
        {securityManagement && (
          <div className="icon-p">
            <label htmlFor="btn--2" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Security Management</p>
            </label>
            <input id="btn--2" type="checkbox" />
            <div className="sub-link">
              <ul>
                {userCreation && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/security/1"
                    activeClassName="active"
                  >
                    <li>User Creation</li>
                  </NavLink>
                )}
                {branchTellerTills && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/security/2"
                    activeClassName="active"
                  >
                    <li>Branch Teller Tills</li>
                  </NavLink>
                )}
                {userTellerTills && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/security/3"
                    activeClassName="active"
                  >
                    <li>User Teller Till Linkage</li>
                  </NavLink>
                )}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/security/4"
                  activeClassName="active"
                >
                  <li>Channel User</li>
                </NavLink> */}
                {manageRoles && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/security/5"
                    activeClassName="active"
                  >
                    <li>Manage Roles</li>
                  </NavLink>
                )}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/security/6"
                  activeClassName="active"
                >
                  <li>Manage Permission</li>
                </NavLink> */}
              </ul>
            </div>
          </div>
        )}

        {AuthorizeSecurityManagement && (
          <div className="icon-p">
            <label
              htmlFor="btn--authsecurity"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Security Management<br/> Authorization</p>
            </label>
            <input id="btn--authsecurity" type="checkbox" />
            <div className="sub-link">
              <ul>
                {AuthorizeUserCreation && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizesecurity/1"
                    activeClassName="active"
                  >
                    <li>User Creation</li>
                  </NavLink>
                )}
                {authorizeBranchTellerTills && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizesecurity/2"
                    activeClassName="active"
                  >
                    <li>Branch Teller Tills</li>
                  </NavLink>
                )}
                {authorizeUserTellerTills && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizesecurity/3"
                    activeClassName="active"
                  >
                    <li>User Teller Till Linkage</li>
                  </NavLink>
                )}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizesecurity/4"
                  activeClassName="active"
                >
                  <li>Channel User</li>
                </NavLink> */}
                {manageRoles && (
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/authorizesecurity/5"
                    activeClassName="active"
                  >
                    <li>Manage Roles</li>
                  </NavLink>
                )}
                {/* <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizesecurity/6"
                  activeClassName="active"
                >
                  <li>Manage Permission</li>
                </NavLink> */}
              </ul>
            </div>
          </div>
        )}
        {/* {true && (
          <div className="icon-p">
            <label htmlFor="btn--13" className="each-link each-link--2 flex--2">
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Treasury</p>
            </label>
            <input id="btn--13" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/treasury/productdefinition"
                  activeClassName="active"
                >
                  <li>Product Definition</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/treasury/portfoliomaintenance"
                  activeClassName="active"
                >
                  <li>Portfolio Maintenance</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/treasury/dealposting"
                  activeClassName="active"
                >
                  <li>Deal Posting</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/treasury/report"
                  activeClassName="active"
                >
                  <li>Report</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )} */}

        {/* {true && (
          <div className="icon-p">
            <label
              htmlFor="btn--authtreasury"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Treasury Authorization</p>
            </label>
            <input id="btn--authtreasury" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizetreasury/productdefinition"
                  activeClassName="active"
                >
                  <li>Product Definition</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizetreasury/portfoliomaintenance"
                  activeClassName="active"
                >
                  <li>Portfolio Maintenance</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/authorizetreasury/dealposting"
                  activeClassName="active"
                >
                  <li>Deal Posting</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )} */}
        <div className="icon-p">
          <label
            htmlFor="btn--14a"
            className="each-link each-link--2 flex--2"
            onClick={handleToggle}
          >
            <div className="icon">
              <svg
                className="bsfill"
                xmlns="http://www.w3.org/2000/svg"
                width="19.489"
                height="15.158"
                viewBox="0 0 19.489 15.158"
              >
                <defs>
                  <style></style>
                </defs>
                <path
                  className="a"
                  d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                  transform="translate(0 -2.25)"
                />
              </svg>
            </div>
            <p>Reports</p>
          </label>
          <input id="btn--14a" type="checkbox" />
          <div className={`sub-link ${toggler && "hide--options"}`}>
            <div className="icon-p">
              <label
                htmlFor="btn--141a"
                className="each-link each-link--2 flex--2"
              >
                <h2>&#8227;</h2>
                <p>Loan Reports</p>
              </label>
              <input id="btn--141a" type="checkbox" />
              <div className="sub-link">
                <ul>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/maturity"
                    activeClassName="active"
                  >
                    <li>Loan Maturity Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/accrual"
                    activeClassName="active"
                  >
                    <li>Loan Accrual Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/disbursement"
                    activeClassName="active"
                  >
                    <li>Loan Disbursement Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/repayment"
                    activeClassName="active"
                  >
                    <li>Loan Repayment Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/repaymentDue"
                    activeClassName="active"
                  >
                    <li>Loan Repayment Due Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/par"
                    activeClassName="active"
                  >
                    <li>PAR Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/loan/parextended"
                    activeClassName="active"
                  >
                    <li>PAR Report (Extended)</li>
                  </NavLink>
                </ul>
              </div>
            </div>
            <div className="icon-p">
              <label
                htmlFor="btn--11a"
                className="each-link each-link--2 flex--2"
              >
                <h2>&#8227;</h2>
                <p>Account Reports</p>
              </label>
              <input id="btn--11a" type="checkbox" />
              <div className="sub-link">
                <ul>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/account/opened"
                    activeClassName="active"
                  >
                    <li>Account Opened Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/account/accrual"
                    activeClassName="active"
                  >
                    <li>Account Accrual Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/account/debitinterest"
                    activeClassName="active"
                  >
                    <li>Debit Interest Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/account/creditinterest"
                    activeClassName="active"
                  >
                    <li>Credit Interest Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/account/cabal"
                    activeClassName="active"
                  >
                    <li>Customer Account Balance Report</li>
                  </NavLink>
                </ul>
              </div>
            </div>
            <div className="icon-p">
              <label
                htmlFor="btn--12a"
                className="each-link each-link--2 flex--2"
              >
                <h2>&#8227;</h2>
                <p>Investment Reports</p>
              </label>
              <input id="btn--12a" type="checkbox" />
              <div className="sub-link">
                <ul>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/investment/certificate"
                    activeClassName="active"
                  >
                    <li>Investment Certificate</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/investment/maturity"
                    activeClassName="active"
                  >
                    <li>Investment Maturity Report</li>
                  </NavLink>
                  <NavLink
                    className="each-link each-link--2 flex--2"
                    to="/dashboard/reports/investment/accrual"
                    activeClassName="active"
                  >
                    <li>Investment Accrual Report</li>
                  </NavLink>
                </ul>
              </div>
            </div>
            {reports && (
              <div className="icon-p">
                <label
                  htmlFor="btn--12b"
                  className="each-link each-link--2 flex--2"
                >
                  <h2>&#8227;</h2>
                  <p>Transaction Reports</p>
                </label>
                <input id="btn--12b" type="checkbox" />
                <div className="sub-link">
                  <ul>
                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/transaction/summary"
                      activeClassName="active"
                    >
                      <li>Transaction summary</li>
                    </NavLink>

                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/transaction/glstatement"
                      activeClassName="active"
                    >
                      <li>GL Statement</li>
                    </NavLink>
                  </ul>
                </div>
              </div>
            )}
            {reports && (
              <div className="icon-p">
                <label
                  htmlFor="btn--12c"
                  className="each-link each-link--2 flex--2"
                >
                  <h2>&#8227;</h2>
                  <p>CRB Reports</p>
                </label>
                <input id="btn--12c" type="checkbox" />
                <div className="sub-link">
                  <ul>
                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/crb/individualborrower"
                      activeClassName="active"
                    >
                      <li>Individual Borrower</li>
                    </NavLink>
                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/crb/corporateborrower"
                      activeClassName="active"
                    >
                      <li>Corporate Borrrower</li>
                    </NavLink>
                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/crb/principalofficers"
                      activeClassName="active"
                    >
                      <li>Principal Officers</li>
                    </NavLink>
                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/crb/creditinformation"
                      activeClassName="active"
                    >
                      <li>Credit Information</li>
                    </NavLink>
                    <NavLink
                      className="each-link each-link--2 flex--2"
                      to="/dashboard/reports/crb/guarantorsinformation"
                      activeClassName="active"
                    >
                      <li>Guarantors Information</li>
                    </NavLink>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        {eodTmeProcess && (
          <div className="icon-p">
            <label
              htmlFor="btn--14123"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>EOD TME Processes</p>
            </label>
            <input id="btn--14123" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/tmeeodprocesses"
                  activeClassName="active"
                >
                  <li>End of Day</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
        {true && (
          <div className="icon-p">
            <label
              htmlFor="btn--14124"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>EOD Reports</p>
            </label>
            <input id="btn--14124" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/eodreports/balancesheets"
                  activeClassName="active"
                >
                  <li>Balance Sheets</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/eodreports/trialbalance"
                  activeClassName="active"
                >
                  <li>Trial Balance</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/eodreports/profitandloss"
                  activeClassName="active"
                >
                  <li>Profit and Loss</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/eodreports/glreports"
                  activeClassName="active"
                >
                  <li>GL</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}

        {authorizeMiscellanousTransaction && (
          <div className="icon-p">
            <label
              htmlFor="btn--1499"
              className="each-link each-link--2 flex--2"
            >
              <div className="icon">
                <svg
                  className="bsfill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.489"
                  height="15.158"
                  viewBox="0 0 19.489 15.158"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                    transform="translate(0 -2.25)"
                  />
                </svg>
              </div>
              <p>Remote Authorization</p>
            </label>
            <input id="btn--1499" type="checkbox" />
            <div className="sub-link">
              <ul>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/remoteauthorization/bulkuploadauthorization"
                  activeClassName="active"
                >
                  <li>Bulk Upload Authorization</li>
                </NavLink>
                <NavLink
                  className="each-link each-link--2 flex--2"
                  to="/dashboard/remoteauthorization/transactionauthorization"
                  activeClassName="active"
                >
                  <li>Transaction Authorization</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
        <div className="icon-p">
          <label htmlFor="btn--1222" className="each-link each-link--2 flex--2">
            <div className="icon">
              <svg
                className="bsfill"
                xmlns="http://www.w3.org/2000/svg"
                width="19.489"
                height="15.158"
                viewBox="0 0 19.489 15.158"
              >
                <defs>
                  <style></style>
                </defs>
                <path
                  className="a"
                  d="M0,15.784a1.625,1.625,0,0,0,1.624,1.624H17.865a1.625,1.625,0,0,0,1.624-1.624V9.829H0Zm6.5-2.3a.407.407,0,0,1,.406-.406h4.6a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H6.9a.407.407,0,0,1-.406-.406Zm-4.331,0a.407.407,0,0,1,.406-.406H5.008a.407.407,0,0,1,.406.406v1.353a.407.407,0,0,1-.406.406H2.571a.407.407,0,0,1-.406-.406ZM19.489,3.874V5.5H0V3.874A1.625,1.625,0,0,1,1.624,2.25H17.865A1.625,1.625,0,0,1,19.489,3.874Z"
                  transform="translate(0 -2.25)"
                />
              </svg>
            </div>
            <p>Change Password</p>
          </label>
          <input id="btn--1222" type="checkbox" />
          <div className="sub-link">
            <ul>
              <NavLink
                className="each-link each-link--2 flex--2"
                to="/dashboard/changepassword"
                activeClassName="active"
              >
                <li>Change Password</li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

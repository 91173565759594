import { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiDialogTitle-root": {
      backgroundColor: "#F89B5F",
    },
    "& .MuiDialog-container": {
      background: "#312E41",
    },
    "& .MuiDialog-paperFullScreen": {
      width: "60%",
    },
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    //border:'2px solid green',
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
    // '& p':{
    //   fontSize:14,
    // },
    // '& h5':{
    //   fontSize:16,
    // }
  },
  item: {
    padding: "0.5rem",
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  bgimg: {
    width: 118,
    height: 92,
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    width: 46,
    height: 46,
    marginTop: 20,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
    textTransform: "none",
  },
}));

const PaymentDetails = ({
  open,
  message,
  status,
  handleClose,
  transactionSummary,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen={true}
        className={classes.root}
        open={open ?? false}
        onClose={handleClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ background: "#9B0116", color: "#fff" }}
        >
          {status ? message : 'Failed!!!'}
        </DialogTitle>
        <DialogContent dividers={props.scroll === "paper"}>
         <Slide in={true}>
         <div
            className={classes.center}
            id="scroll-dialog-description"
            ref={props.descriptionElementRef}
            tabIndex={-1}
          >
            {status && <CheckCircleIcon className={clsx(classes.bgimg, classes.success)} />}
            {!status && <ErrorIcon  className={clsx(classes.bgimg, classes.error)}/>}
            {!status && <div className={classes.error}>{message}</div>}
             <ol>
              {transactionSummary.map((item, index) => (
                <li
                  className={clsx(
                    classes.item,
                    item.status ? classes.success : classes.error
                  )}
                  key={index}
                >
                  {item.responseMessage}
                </li>
              ))}
            </ol>
            <Button onClick={handleClose} className={classes.button}>ok</Button>
          </div>
         </Slide>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaymentDetails;

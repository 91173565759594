import EditModal from "../../../DatagridTable/edit";
import { baseUrl } from "../../../../Api/configUrl";
import { DateConverter } from "../../../ConvertDate";
import ErrorMessage from "../../../ErrorMessage";
import Grid from "@material-ui/core/Grid"


const EditData = ({
  handleEditSubmit,
  handleChange,
  details,
  cusType
}) => {
  return (
    <EditModal
      title="Update Customer Category"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this record?"
      data={details}
      url={`${baseUrl}organization/CustomerCategory/Update/${details.id}`}
    >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
            <label>Category Code</label>
                     <input style={{height:44,borderRadius:5}}
                         type="text"
                         onChange={handleChange}
                         name='category'
                         value={details.category}
                     />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <label>Category Description</label>
                     <input style={{height:44,borderRadius:5}}
                         type="text"
                         onChange={handleChange}
                         name='name'
                         value={details.name}
                     />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <label>Category Type</label>
            <br/>
            <select style={{height:44,borderRadius:5}}
           value={details.type}
           name="type"
           onChange={handleChange}
         >
           <option value="Select customer type">
           Select customer type
           </option>
           {cusType.map((item, i) => (
             <option
               key={item.id}
              // name={item.parameterValue}
               value={item.parameterValue}
             >
               {item.parameterValue}
             </option>
           ))}
         </select>
            </Grid>
        </Grid>
    </EditModal>
  );
};

export default EditData;

import { Grid } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { baseUrl } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
}) => {
  return (
    <EditModal
      title="Update DealPosting"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this Deal record?"
      data={details}
      url={`${baseUrl}treasury/DealPosting/update/${details.id}`}
    >
          <Grid container spacing={3} className="branchteller--tills">
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label>Account Number</label>
              <input
                type="text"
                value={details.accountNumber}
                onChange={handleEditChange("accountNumber")}
                disabled
              />
              {errorEdit.accountNumber && <ErrorMessage message={errorEdit.accountNumber} />}
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="label--input flex--3">
              <label>Portfolio ID</label>
              <input
                type="text"
                value={details.portfolioCode}
                onChange={handleEditChange("portfolioCode")}
              />
              {errorEdit.portfolioCode && (
                <ErrorMessage message={errorEdit.portfolioCode} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Portfolio Type</label>
              <select
                    type="text"
                    onChange={handleEditChange("portfolioType")}
                    name="portfolioType"
                    value={details.portfolioType}
                    >
                    <option value="">Select an option</option>
                    <option value="Bank">Bank</option>
                    <option value="Customer">Customer</option>
                  </select>
              {errorEdit.portfolioType && (
                <ErrorMessage message={errorEdit.portfolioType} />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>Product</label>
              <input
                type="text"
                value={details.product}
                onChange={handleEditChange("product")}
                disabled
              />
              {errorEdit.product && (
                <ErrorMessage message={errorEdit.product} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Product Name</label>
              <input
                type="text"
                value={details.productDescription}
                onChange={handleEditChange("productDescription")}
                disabled
              />
              {errorEdit.productDescription && (
                <ErrorMessage message={errorEdit.productDescription} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Deal Reference</label>
              <input
                type="text"
                value={details.dealReference}
                onChange={handleEditChange("dealReference")}
                disabled
              />
              {errorEdit.dealReference && (
                <ErrorMessage message={errorEdit.dealReference} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>External Reference No.</label>
              <input
                type="text"
                value={details.externalReferenceNo}
                onChange={handleEditChange("externalReferenceNo")}
                
              />
              {errorEdit.externalReferenceNo && (
                <ErrorMessage message={errorEdit.externalReferenceNo} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Deal Quantity</label>
              <input
                type="text"
                value={details.dealQuantity}
                onChange={handleEditChange("dealQuantity")}
              />
              {errorEdit.dealQuantity && (
                <ErrorMessage message={errorEdit.dealQuantity} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Price Quote</label>
              <select
                    name="priceQuote"
                    value={details.priceQuote}
                   onChange={handleEditChange("priceQuote")}
                    >
                    <option value="">Select an option</option>
                    <option value="Price">Price</option>
                    <option value="PercentagePrice">PercentagePrice</option>
                    <option value="Discount">Discount</option>
                    <option value="PercentagePremium">PercentagePremium</option>
                    <option value="PercentageDiscount">
                      PercentageDiscount
                    </option>
                    <option value="Premium">Premium</option>
                    <option value="YieldToMaturity">YieldToMaturity</option>
                  </select>
              {errorEdit.priceQuote && (
                <ErrorMessage message={errorEdit.priceQuote} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Currency</label>
              <select
                    name="currency"
                    value={details.currency}
                   onChange={handleEditChange("currency")}
                  >
                    <option value="">Select an option</option>
                    <option value="NGN">NGN</option>
                    <option value="GDP">GDP</option>
                  </select>
              {errorEdit.currency && (
                <ErrorMessage message={errorEdit.currency} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Input Price</label>
              <input
                type="text"
                value={details.inputPrice}
                onChange={handleEditChange("inputPrice")}
                
              />
              {errorEdit.inputPrice && (
                <ErrorMessage message={errorEdit.inputPrice} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Price In Local Currency</label>
              <input
                type="text"
                value={details.priceInLocalCurrency}
                onChange={handleEditChange("priceInLocalCurrency")}
                
              />
              {errorEdit.priceInLocalCurrency && (
                <ErrorMessage message={errorEdit.priceInLocalCurrency} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Trade Date</label>
              <input
                type="text"
                value={details.tradeDate}
                onChange={handleEditChange("tradeDate")}
                
              />
              {errorEdit.tradeDate && (
                <ErrorMessage message={errorEdit.tradeDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Qty. of Local Currency</label>
              <input
                type="text"
                value={details.quantityOfLocalCurrency}
                onChange={handleEditChange("quantityOfLocalCurrency")}
              
              />
              {errorEdit.quantityOfLocalCurrency && (
                <ErrorMessage message={errorEdit.quantityOfLocalCurrency} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Deal Input Date</label>
              <input
                type="date"
                value={details.dealInputDate}
                onChange={handleEditChange("dealInputDate")}
              
              />
              {errorEdit.dealInputDate && (
                <ErrorMessage message={errorEdit.dealInputDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Interest</label>
              <select
                    name="interest"
                    type="number"
                    step="any"
                    onChange={handleEditChange("dealInputDate")}
                    value={details.interest}
                  >
                    <option value="">Select an option</option>
                    <option value="PlusAccrued">Plus Accrued</option>
                    <option value="Flat">Flat</option>
                  </select>
              {errorEdit.interest && (
                <ErrorMessage message={errorEdit.interest} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Maturity Date</label>
              <input
                type="date"
                value={details.maturtiyDate}
                onChange={handleEditChange("maturtiyDate")}
              
              />
              {errorEdit.maturtiyDate && (
                <ErrorMessage message={errorEdit.maturtiyDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Quote By</label>
              <select
                    name="quoteBy"
                onChange={handleEditChange("quoteBy")}
                    value={details.quoteBy}
                   
                  >
                    <option value="">Select an option</option>
                    <option value="Units">Units</option>
                    <option value="Nominal">Nominal</option>
                  </select>
              {errorEdit.quoteBy && (
                <ErrorMessage message={errorEdit.quoteBy} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Broker ID</label>
              <input
                type="text"
                value={details.brokerId}
                onChange={handleEditChange("brokerId")}
              
              />
              {errorEdit.brokerId && (
                <ErrorMessage message={errorEdit.brokerId} />
              )}
            </Grid>
          </Grid>
    </EditModal>
  );
};

export default EditData;

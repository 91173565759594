import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import Table from './table';


const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiDialogTitle-root": {
      backgroundColor: "#F89B5F",
    },
    "& .MuiDialog-container": {
      //background: theme.palette.primary.dark,
    },
    "& .MuiDialog-paperFullScreen": {
      width: "80%",
    },
    // "& .MuiDialogContent-root":{
    //   display:"flex"
    // }
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    //border:'2px solid green',
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
    
    // '& p':{
    //   fontSize:14,
    // },
    // '& h5':{
    //   fontSize:16,
    // }
  },
  item: {
    padding: "0.5rem",
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  bgimg: {
    width: 118,
    height: 92,
  },
  btnContainer:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
  },
  button: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    //width: 46,
    height: 46,
    marginTop: 20,
    marginRight:'2rem',
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D0021B",
    },
    textTransform: "none",
  },
}));

const Permissions = ({
  data,
  open,
  onClose,
  options,
  columns,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen={true}
        className={clsx(classes.root)}
        open={open}
        onClose={onClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={props.scroll === "paper"}>
        <Table
          title="Permission"
          subtitle="List of Permission - - Kindly select permissions for the role"
          name="List of permissions"
          records={data}
          options={options}
          columns={columns}
        />
        <div className={classes.btnContainer}>
        <Button onClick={onClose} className={classes.button}>Ok</Button>
        </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Permissions;

import Item from "../../DatagridTable/item";
import { Grid } from '@material-ui/core';

const CurrencyDefinition = ({details}) => {
    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Currency Name" name={details.currencyName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Currency Code" name={details.currencyCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Numberic Code" name={details.numericCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
        </Grid>
      </Grid>
    )
}

export default CurrencyDefinition

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./BulkTransaction.scss";
import XLSX from "xlsx";
import { make_cols } from "../Bulk/makeColumn";
import { SheetJSFT } from "../Bulk/type";
import { CSVLink } from "react-csv";
import NumberFormat from "react-number-format";
import { getAllTellerTill } from "../../../../Api/Hooks-manager/securitymanagement/tellerTills";
import { isLoading } from "../../../../Api/redux-manager/actions";
import { TransactionType, ModuleType, BulkType } from "../../../../Helper/enum";
import { DateConverter } from "../../../ConvertDate";
import { formattedDate } from "../../../../formatter/date";

import {
  handleBulkTransaction,
  ValidateAllAccounts,
  getCustomerOrGLAccount,
  handleAutoDebitForBulk,
} from "../../../../Api/Hooks-manager/Transactions";

import ErrorMsg from "../../../ErrorMessage";
import BulkTransactionModal from "../../../../Confirmbox/BulkTransactionModal";
import ErrorModal from "../../../../Confirmbox/myModal";
import DecisionModal from "../../../../Confirmbox/decision";
import Spinner from "../../../Backdrop";
import AutoTransactionTable from "./components/table";

const BulkTransaction = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const allTellerTills = useSelector((state) => state.common.allTellerTills);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [openDecision, setOpenDecision] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableUploadBtn, setDisableUploadBtn] = useState(true);
  const [type, setType] = useState("manual");
  const [errors, setErrors] = useState({});
  const [errorSummary, setErrorSummary] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [status, setStatus] = useState(false);

  console.log("errorSummary", errorSummary);
  console.log("openErrorModal", openErrorModal);

  //console.log('disableUploadBtn....',disableUploadBtn)

  const [bulkTransfer, setBulkTransfer] = useState({
    transactions: [],
    transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
    entryBranch: user.operatingBranch,
    inputterIpAddress: "",
    bankId: user.institution,
    bulkType: BulkType.MDMC,
    chequeNumber: "",
    controlNumber: "",
    clearingType: "",
    issueDate: "",
    routingNumber: "",
    terminalId: "",
    msgType: "",
    msgStan: "",
    depositorName: "",
    depositorMobile: "",
    depositorAddress: "",
    denominations: [],
    authorizedBy: 0,
    amlDataStatus: true,
    amlAccountNumber: "",
    amlCustomerNumber: "",
    amlIdNumber: "",
    amlBvnNumber: "",
    amlCustomerType: "",
    amlCustomer: "",
    amlWaiveId: true,
    amlTitle: "",
    amlFirstName: "",
    amlMiddleName: "",
    amlLastName: "",
    amlPhoneNumber: "",
    amlDob: "",
    amlPob: "",
    amlNationality: "",
    amlAddress: "",
    amlFundSource: "",
    amlOccupation: "",
    amlNumber: "",
    amlType: "",
    amlIssuer: "",
    amlCountry: "",
    amlState: "",
    amlExpiration: "",
    amlIssueDate: "",
  });
  const [state, setState] = useState({
    file: {},
    data: [],
    cols: [],
  });
  const [transactionSummary, setTransactionSummary] = useState([]);
  const [sumOfDebit, setSumOfDebit] = useState(0);
  const [sumOfCredit, setSumOfCredit] = useState(0);
  const [bulkUpload, setBulkUpload] = useState([
    {
      Account_Type: "internal",
      Account_No: "",
      Debit_Or_Credit: "dr",
      accountName: "",
      accountCurrency: "",
      Amount: "",
      Narration: "",
    },
    {
      Account_Type: "customer",
      Account_No: "",
      Debit_Or_Credit: "cr",
      accountName: "",
      accountCurrency: "",
      Amount: "",
      Narration: "",
    },
  ]);

  const debitArray = (arr) =>
    arr
      .filter((item) => item.Debit_Or_Credit.toLowerCase() === "dr")
      .map((el) => parseFloat(el.Amount));

  const creditArray = (arr) =>
    arr
      .filter((item) => item.Debit_Or_Credit.toLowerCase() === "cr")
      .map((el) => parseFloat(el.Amount));

  const reducer = (a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2);

  const clearManualRecords = () => {
    setSumOfDebit(0);
    setSumOfCredit(0);
    setState({
      file: {},
      data: [],
      cols: [],
    });
    setBulkTransfer((prev) => ({ ...prev, transactions: [] }));
    setBulkUpload(() => [
      {
        Account_Type: "internal",
        Account_No: "",
        Debit_Or_Credit: "dr",
        accountName: "",
        accountCurrency: "",
        Amount: "",
        Narration: "",
      },
      {
        Account_Type: "customer",
        Account_No: "",
        Debit_Or_Credit: "cr",
        accountName: "",
        accountCurrency: "",
        Amount: "",
        Narration: "",
      },
    ]);
  };

  const tillGLAcct = (arr) => arr.map(({ accountNumber }) => accountNumber);
  const tillAccount = tillGLAcct(allTellerTills);

  console.log("allTellerTills", allTellerTills);
  console.log("tillAccount", tillAccount);

  const addBulkUploadInput = () => {
    const list = [...bulkUpload];
    list.push({
      Account_Type: "customer",
      Account_No: "",
      Debit_Or_Credit: "dr",
      accountName: "",
      accountCurrency: "",
      Amount: "",
      Narration: "",
    });
    setBulkUpload(list);
  };
  const removeBulkUpload = (index) => {
    const list = [...bulkUpload];
    if (list.length < 3) return;
    list.splice(index, 1);
    setBulkUpload((prev) => [...list]);
  };
  const [showBulkForm, setShowBulkForm] = useState(false);
  const [msg, setMsg] = useState("");
  const suspenceAccount = useSelector((state) => state.common.branch);

  const excelCells = [
    { key: "id", label: "id" },
    { key: "Account_Type", label: "Account_Type" },
    { key: "Account_No", label: "Account_No" },
    { key: "Debit_Or_Credit", label: "Debit_Or_Credit" },
    { key: "Amount", label: "Amount" },
    { key: "Narration", label: "Narration" },
    // {key: "Account_Balance", label: "Account_Balance"},
  ];

  const sampleData = [
    {
      id: "1",
      Account_Type: "customer",
      Account_No: "0734474102",
      Debit_Or_Credit: "Dr",
      Amount: "100000",
      Narration: "Payment of monthly salary",
    },
    {
      id: "2",
      Account_Type: "Internal",
      Account_No: "0122222222",
      Debit_Or_Credit: "Cr",
      Amount: "750000",
      Narration: "Fund transfer",
    },
  ];

  const manualClick = () => {
    setType("manual");
    clearManualRecords();
  };

  const autoClick = () => {
    setSumOfDebit(0);
    setSumOfCredit(0);
    setBulkUpload([]);
    setBulkTransfer((prev) => ({ ...prev, transactions: [] }));
    setType("auto");
    setShowBulkForm(false);
    setErrors({});
    state.data = [];
  };

  console.log("bulkupload...............", bulkUpload);

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setState({ file: files[0] });
      setDisableUploadBtn(false);
    }
  };

  const handleBulkUploadChange = (e, index) => {
    //setIndex(index);
    const { name, value } = e.target;
    const lists = [...bulkUpload];
    const accountType = lists[index]["Account_Type"];
    const condition =
      (name === "Account_No" &&
        accountType === "internal" &&
        value.length === 9) ||
      (name === "Account_No" &&
        accountType === "customer" &&
        value.length === 10);
    const clearField =
      (name === "Account_No" &&
        accountType === "internal" &&
        value.length < 9) ||
      (name === "Account_No" &&
        accountType === "customer" &&
        value.length < 10);
    if (
      name === "Account_No" &&
      accountType === "internal" &&
      value.length > 9
    ) {
      return;
    }
    if (name === "Account_No" && value.length > 10) {
      return;
    }
    if (
      (name === "Account_Type" && value === "customer") ||
      (name === "Account_Type" && value === "internal")
    ) {
      lists[index]["Account_No"] = "";
      lists[index]["accountName"] = "";
      lists[index]["accountCurrency"] = "";
      lists[index]["accountBalance"] = "";
      lists[index]["error"] = "";
    }
    if (clearField) {
      lists[index]["accountName"] = "";
      lists[index]["accountCurrency"] = "";
      lists[index]["accountBalance"] = "";
      lists[index]["error"] = "";
    }

    if (condition) {
      (async () => {
        setLoading(true);
        lists[index][name] = value;
        const response = await getCustomerOrGLAccount(
          user.jwtToken,
          value,
          accountType
        );
        if (tillAccount.includes(value)) {
          setLoading(false);
          lists[index]["error"] =
            "This GL cannot be used because it is a till account";
          lists[index]["accountName"] = "";
          lists[index]["accountCurrency"] = "";
          lists[index]["accountBalance"] = "";
          lists[index]["branchCode"] = "";
          setBulkUpload(lists);
          return;
        } else if (response && response.data) {
          setLoading(false);
          if (response.status) {
            if (
              response.data.glHierarchy &&
              response.data.glHierarchy !== "Child"
            ) {
              // setInvalidAccNo("Gl acount No. must be a child");
              lists[index]["error"] = "Gl account No. must be a child";
              setBulkUpload(lists);
              return;
            }
            if (response.data.authorizationStatus !== "Authorized") {
              lists[index]["error"] = "Unauthorized account No";
              lists[index]["accountName"] = "";
              lists[index]["accountCurrency"] = "";
              lists[index]["accountBalance"] = "";
              lists[index]["branchCode"] = "";
              setBulkUpload(lists);
              return;
            }

            lists[index]["accountName"] = response.data.accountName;
            lists[index]["accountCurrency"] = response.data.currency;
            lists[index]["accountBalance"] =
              response.data.accountBalance !== undefined
                ? response.data.accountBalance
                : response.data.balance;
            lists[index]["branchCode"] = response.data.branchCode;
            lists[index]["error"] = "";
            setBulkUpload(lists);
          } else {
            lists[index]["accountName"] = "";
            lists[index]["accountCurrency"] = "";
            lists[index]["accountBalance"] = "";
            lists[index]["branchCode"] = "";
            lists[index]["error"] = "Invalid account No.";
            setBulkUpload(lists);
          }
        }
      })();
      return;
    }

    lists[index][name] = value;
    setBulkUpload(() => lists);
  };

  const validateUploadedFile = (arr) => {
    setErrorSummary(null);
    setBulkUpload([]);
    setBulkTransfer((prev) => ({ ...prev, transactions: [] }));
    setShowBulkForm(false);
    const lists = [...arr];
    if (lists.length < 1) {
      setMsg("Empty files are not allowed");
      return;
    }
    const userKeys = (i) => Object.keys(lists[i]);
    const developerKeys = excelCells.map((item) => item.label);

    for (let i = 0; i < lists.length; i++) {
      for (let j = 0; j < developerKeys.length; j++) {
        if (!developerKeys.includes(userKeys(i)[j])) {
          setMsg(
            `Something went wrong. It's either one of your cell is empty or the header does not match ours. Kindly download the template and ensure you input the right data`
          );
          setOpenErrorModal(true);
          return;
        }
      }
    }

    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].Account_Type.toLowerCase() !== "customer" &&
        arr[i].Account_Type.toLowerCase() !== "internal"
      ) {
        setMsg(
          `One of customer or internal are the only Account_Type allowed in row ${
            i + 2
          }`
        );
        setOpenErrorModal(true);
        return;
      }
      let accountNumber = arr[i].Account_No.toString();

      console.log(accountNumber, "account number in string");

      if (
        arr[i].Account_Type.toLowerCase() === "customer" &&
        accountNumber.length !== 10
      ) {
        // console.log('arr[i].Account_No.length',arr[i].Account_No.length)
        setMsg(
          `Account number of customer must be ten character long, correct row ${
            i + 2
          } and try again`
        );
        setOpenErrorModal(true);
        return;
      }
      if (
        arr[i].Account_Type.toLowerCase() === "internal" &&
        accountNumber.length !== 9
      ) {
        setMsg(
          `Account number of a gl must be nine character long, correct row ${
            i + 2
          } and try again`
        );
        setOpenErrorModal(true);
        return;
      }
      if (
        arr[i].Debit_Or_Credit.toLowerCase() !== "dr" &&
        arr[i].Debit_Or_Credit.toLowerCase() !== "cr"
      ) {
        setMsg(
          `One of dr or cr are the only Debit_Or_Credit allowed in row ${i + 2}`
        );
        setOpenErrorModal(true);
        return;
      }

      // let accountBalance = arr.[i].balance.toString();
    }

    const debitArr = debitArray(arr);
    const creditArr = creditArray(arr);
    console.log(debitArr, "dddddddd");
    console.log(creditArr, "ccccccc");
    //const reducer = (a, b) => a + b;

    if (debitArr.length < 1) {
      setErrorSummary({
        notBalance: `There should be atleast on debitor`,
      });
      setOpenErrorModal(true);
      return;
    }
    if (creditArr.length < 1) {
      setErrorSummary({
        notBalance: `There should be atleast on creditor`,
      });
      setOpenErrorModal(true);
      return;
    }

    const debitTotal = debitArr.reduce(reducer, 0);
    const creditTotal = creditArr.reduce(reducer, 0);

    // console.log(debitTotal, "debitTotal")
    // console.log(creditTotal, "creditTotal")

    if (debitTotal !== creditTotal) {
      setMsg(
        `Total debit transaction:${debitTotal} is not equal to the total credit transaction:${creditTotal}.`
      );
      setOpenErrorModal(true);
      return;
    } else {
      setSumOfDebit(debitTotal);
      setSumOfCredit(creditTotal);
    }
    return true;
  };

  // console.log(sumOfCredit, "sumOfCredit")
  // console.log(sumOfDebit, "sumOfDebit")

  const validateManualForm = () => {
    const errors = {
      Account_Type: [],
      Account_No: [],
      Debit_Or_Credit: [],
      Amount: [],
      Narration: [],
    };
    for (let i = 0; i < bulkUpload.length; i++) {
      if (bulkUpload[i].Account_Type === "") {
        errors.Account_Type.push({
          id: i,
        });
      }
      if (
        bulkUpload[i].Account_Type === "customer" &&
        bulkUpload[i].Account_No.length !== 10
      ) {
        errors.Account_No.push({
          id: i,
          msg: "10 Characters are required",
        });
      }
      if (
        bulkUpload[i].Account_Type === "internal" &&
        bulkUpload[i].Account_No.length !== 9
      ) {
        errors.Account_No.push({
          id: i,
          msg: "9 Characters are required",
        });
      }
      if (bulkUpload[i].Debit_Or_Credit === "") {
        errors.Debit_Or_Credit.push({
          id: i,
        });
      }
      if (bulkUpload[i].Amount === "") {
        errors.Amount.push({
          id: i,
          msg: "Field is required",
        });
      }
      console.log(
        bulkUpload[i].Account_Type === "internal",
        bulkUpload[i].Debit_Or_Credit === "dr",
        bulkUpload[i].accountBalance,
        parseFloat(bulkUpload[i].Amount) > bulkUpload[i].accountBalance,
        "checccckkkkking"
      );
      if (
        bulkUpload[i].Account_Type === "customer" &&
        bulkUpload[i].Debit_Or_Credit === "dr" &&
        bulkUpload[i].accountBalance != undefined &&
        parseFloat(bulkUpload[i].Amount) > bulkUpload[i].accountBalance
      ) {
        // console.log(bulkUpload[i].Amount, bulkUpload[i].accountBalance, "account balanceeeee")
        errors.Amount.push({
          id: i,
          msg: "Insufficient balance",
        });
      }
      if (bulkUpload[i].Narration === "") {
        errors.Narration.push({
          id: i,
        });
      }
    }

    if (!errors.Account_Type.length) delete errors.Account_Type;
    if (!errors.Account_No.length) delete errors.Account_No;
    if (!errors.Debit_Or_Credit.length) delete errors.Debit_Or_Credit;
    if (!errors.Amount.length) delete errors.Amount;
    if (!errors.Narration.length) delete errors.Narration;

    return errors;
  };

  const downloadxls = (e, data) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "bulkUpload.xlsx");
  };

  const handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      setDisableUploadBtn(true);
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      if (validateUploadedFile(data)) {
        data.forEach((element, index) => {
          element.Debit_Or_Credit = element.Debit_Or_Credit.toLowerCase();
          element.Account_Type = element.Account_Type.toLowerCase();
          element.sn = index + 1;
        });
        setMsg("");
        setState({ data, cols: make_cols(ws["!ref"]) });
        validateBulkUploadDetails(data);
        //setBulkUpload(data);
      }
    };

    if (rABS) {
      reader.readAsBinaryString(state.file);
    } else {
      reader.readAsArrayBuffer(state.file);
    }
  };

  // useEffect(() => {
  //   const accountNumbers = ["201300012"];
  //   const validateAccountNumbers = async () => {
  //     const response = await ValidateAllAccounts(token, accountNumbers);

  //     console.log("response...", response);
  //   };
  //   validateAccountNumbers();
  // }, []);

  const processBulkTransaction = async () => {
    // setLoading(true);
    // const response = await handleBulkTransaction(token, bulkTransfer);
    // if (response && response.data) {
    //   setLoading(false);
    //   setMessage(response.message);
    //   setTransactionSummary(response.data);
    //   setOpenDecision(false);
    //   setOpen(true);
    //   if (response.status) {
    //     setStatus(true);
    //     manualClick();
    //   } else {
    //     setStatus(false);
    //   }
    // }
  };

  const validateBulkUploadDetails = async (bulkUpload) => {
    //_______________________________ use backend data to validate account numbers  _________________________________________//

    setLoading(true);
    const copyState = [...bulkUpload];
    const invalidAccNumbers = [];
    const insufficientAccountBalance = [];
    const unAuthorizedGlAccounts = [];
    const disallowedGlAccounts = [];
    const unAuthorizedCustomerAccounts = [];
    const transactionsObject = [];
    const accountNumbers = bulkUpload.map((el) => el.Account_No);
    const validateAccountNumbers = await ValidateAllAccounts(
      token,
      accountNumbers
    );

    console.log("accountNumbers", accountNumbers);
    console.log("validateAccountNumbers", validateAccountNumbers);

    setLoading(false);
    if (validateAccountNumbers && validateAccountNumbers.data.length > 0) {
      setErrorSummary(null);
      const backendCreditAccountNumbers = validateAccountNumbers.data.map(
        (item) => item.accountNumber
      );

      console.log("backendCreditAccountNumbers", backendCreditAccountNumbers);

      for (const x of accountNumbers) {
        console.log("xxxxxxxxx", x);
        console.log("checking accountssss", !backendCreditAccountNumbers.includes(x));
        if (!backendCreditAccountNumbers.includes(x)) {
          invalidAccNumbers.push(x);
        }
      }

      for (let y of bulkUpload) {
        let findItem = validateAccountNumbers.data.find(
          (item) => item.accountNumber === y.Account_No
        );
        if (findItem) {
          if (
            y.Account_Type === "customer" &&
            y.Debit_Or_Credit === "dr" &&
            parseFloat(y.Amount) > (findItem.accountBalance || findItem.balance)
          ) {
            insufficientAccountBalance.push(findItem.accountNumber);
          }
          if (
            y.Account_Type === "customer" &&
            findItem.authorizationStatus !== "Authorized"
          ) {
            unAuthorizedCustomerAccounts.push(findItem.accountNumber);
          }
          if (
            y.Account_Type === "internal" &&
            (findItem.authorizationStatus !== "Authorized" ||
              findItem.glHierarchy !== "Child")
          ) {
            unAuthorizedGlAccounts.push(findItem.accountNumber);
          }
          if (
            y.Account_Type === "internal" &&
            tillAccount.includes(findItem.accountNumber)
          ) {
            disallowedGlAccounts.push(findItem.accountNumber);
          }
        }
      }

      console.log("invalidAccNumbers", invalidAccNumbers);

      if (
        invalidAccNumbers.length > 0 ||
        insufficientAccountBalance.length > 0 ||
        unAuthorizedCustomerAccounts.length > 0 ||
        unAuthorizedGlAccounts.length > 0 ||
        disallowedGlAccounts.length > 0
      ) {
        setErrorSummary({
          invalidAccNumbers,
          insufficientAccountBalance,
          unAuthorizedCustomerAccounts,
          unAuthorizedGlAccounts,
          disallowedGlAccounts,
        });
        return setOpenErrorModal(true);
      }

      for (let account of copyState) {
        setErrorSummary(null);
        let findItemForTransaction = validateAccountNumbers.data.find(
          (item) => item.accountNumber === account.Account_No
        );
        if (findItemForTransaction) {
          let index = copyState.indexOf(account);
          let updateCopy = copyState[index];
          updateCopy.accountName = findItemForTransaction.accountName;
          updateCopy.accountCurrency = findItemForTransaction.currency;
          transactionsObject.push({
            debitAccount:
              account.Debit_Or_Credit === "dr"
                ? findItemForTransaction?.accountNumber
                : suspenceAccount?.suspenseLedgerAccount,
            creditAccount:
              account.Debit_Or_Credit === "cr"
                ? findItemForTransaction.accountNumber
                : suspenceAccount?.suspenseLedgerAccount,
            postAmount: account.Amount,
            transactionType: TransactionType.MDMC,
            narration: account.Narration,
            currencyCode: "NGN",
            debitModule:
              account.Account_Type === "customer" &&
              account.Debit_Or_Credit === "dr"
                ? ModuleType.CustomerAccount
                : ModuleType.GlAccount,
            creditModule:
              account.Account_Type === "customer" &&
              account.Debit_Or_Credit === "cr"
                ? ModuleType.CustomerAccount
                : ModuleType.GlAccount,
            debitAccountBranch:
              account.Debit_Or_Credit === "dr"
                ? findItemForTransaction.branchCode
                : suspenceAccount.code,
            creditAccountBranch:
              account.Debit_Or_Credit === "dr"
                ? findItemForTransaction.branchCode
                : suspenceAccount.code,
          });
        }
      }

      if (errorSummary === null && transactionsObject.length > 0) {
        setBulkUpload(() => copyState);
        setShowBulkForm(true);
        setBulkTransfer((prev) => ({
          ...prev,
          transactions: transactionsObject,
        }));
        return;
      }
    } else {
      setErrorSummary({
        notFound: "no valid account number was found",
        invalidAccNumbers: [],
      });
      return setOpenErrorModal(true);
    }
  };

  const validateRecord = () => {
    if (type === "manual") {
      const checkErrors = validateManualForm();
      setErrors(checkErrors);
      if (Object.keys(checkErrors).length > 0) return;
      setErrors({});
      const debitArr = debitArray(bulkUpload);
      const creditArr = creditArray(bulkUpload);
      if (debitArr.length < 1) {
        setErrorSummary({
          notBalance: `There should be atleast one debitor`,
        });
        setOpenErrorModal(true);
        return;
      }
      if (creditArr.length < 1) {
        setErrorSummary({
          notBalance: `There should be atleast one creditor`,
        });
        setOpenErrorModal(true);
        return;
      }

      const sumOfDebit = debitArr.reduce(reducer, 0);
      const sumOfCredit = creditArr.reduce(reducer, 0);
      setSumOfDebit(sumOfDebit);
      setSumOfCredit(sumOfCredit);

      console.log(debitArr, "debitArr");
      console.log(creditArr, "creditArr");
      if (sumOfCredit !== sumOfDebit) {
        setErrorSummary({
          notBalance: `Total debit transaction:${sumOfDebit} is not equal to the total credit transaction:${sumOfCredit}.`,
        });
        setOpenErrorModal(true);
        return;
      }
      // if(debitAccount.postAmount > ){
      //   setErrorSummary({
      //     insufficientBalance: `Total debit transaction:${sumOfDebit} is less than the account balance`,
      //   });
      // }

      const transactions = bulkUpload.map((account) => {
        return {
          debitAccount:
            account.Debit_Or_Credit === "dr"
              ? account.Account_No
              : suspenceAccount.suspenseLedgerAccount,
          creditAccount:
            account.Debit_Or_Credit === "cr"
              ? account.Account_No
              : suspenceAccount.suspenseLedgerAccount,
          postAmount: account.Amount,
          transactionType: TransactionType.MDMC,
          narration: account.Narration,
          currencyCode: "NGN",
          debitModule:
            account.Account_Type === "customer" &&
            account.Debit_Or_Credit === "dr"
              ? ModuleType.CustomerAccount
              : ModuleType.GlAccount,
          creditModule:
            account.Account_Type === "customer" &&
            account.Debit_Or_Credit === "cr"
              ? ModuleType.CustomerAccount
              : ModuleType.GlAccount,
          debitAccountBranch:
            account.Debit_Or_Credit === "dr"
              ? account.branchCode
              : suspenceAccount.code,
          creditAccountBranch:
            account.Debit_Or_Credit === "dr"
              ? account.branchCode
              : suspenceAccount.code,
        };
      });
      setBulkTransfer((prev) => ({
        ...prev,
        transactions,
      }));
    }
    setOpenDecision(true);
  };

  const makePayment = () => {
    processBulkTransaction();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="bulk--transaction">
      <Spinner loading={loading} />
      <BulkTransactionModal
        open={open}
        status={status}
        handleClose={handleClose}
        message={message}
        transactionSummary={transactionSummary}
      />
      <DecisionModal
        question="Are you sure you want to make this payment?"
        openDialog={openDecision}
        onClick={makePayment}
        onClose={() => setOpenDecision(false)}
      />
      <ErrorModal
        title="Error Summary"
        openDialog={openErrorModal}
        positiveDialog={false}
        onClose={() => setOpenErrorModal(false)}
      >
        <div>
          {msg && <div>{msg}</div>}
          {errorSummary && errorSummary.notFound && (
            <div>{errorSummary.notFound}</div>
          )}
          {errorSummary && errorSummary.notBalance && (
            <div>{errorSummary.notBalance}</div>
          )}
          {errorSummary && errorSummary.invalidAccNumbers && (
            <div>
              {errorSummary.invalidAccNumbers.length > 0 && (
                <h6>
                  <b>List of invalid account numbers</b>
                </h6>
              )}
              <ul>
                {errorSummary.invalidAccNumbers.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              {/* {errorSummary.invalidAccNumbers.toString()} */}
            </div>
          )}
          {errorSummary && errorSummary.insufficientAccountBalance && (
            <div>
              {errorSummary.insufficientAccountBalance.length > 0 && (
                <h6>
                  <b>
                    List of valid account numbers that has insufficient
                    AccountBalance
                  </b>
                </h6>
              )}
              <ul>
                {errorSummary.insufficientAccountBalance.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              {/* {errorSummary.insufficientAccountBalance.toString()} */}
            </div>
          )}
          {errorSummary && errorSummary.unAuthorizedCustomerAccounts && (
            <div>
              {errorSummary.unAuthorizedCustomerAccounts.length > 0 && (
                <h6>
                  <b>List of unauthorized customers accounts</b>
                </h6>
              )}
              <ul>
                {errorSummary.unAuthorizedCustomerAccounts.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              {/* {errorSummary.unAuthorizedCustomerAccounts.toString()} */}
            </div>
          )}
          {errorSummary && errorSummary.unAuthorizedGlAccounts && (
            <div>
              {errorSummary.unAuthorizedGlAccounts.length > 0 && (
                <h6>
                  <b>List of unauthorized gl accounts</b>
                </h6>
              )}
              <ul>
                {errorSummary.unAuthorizedGlAccounts.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              {/* {errorSummary.unAuthorizedGlAccounts.toString()} */}
            </div>
          )}
          {errorSummary && errorSummary.disallowedGlAccounts && (
            <div>
              {errorSummary.disallowedGlAccounts.length > 0 && (
                <h6>
                  <b>List of disallowed GL because they are till accounts</b>
                </h6>
              )}
              <ul>
                {errorSummary.disallowedGlAccounts.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </ErrorModal>
      <div className="headerdiv">
        <p>Bulk Transaction Posting</p>
      </div>
      {/* <ErrorMsg message={msg} /> */}
      <main className="section--1">
        <div className="togglediv">
          <button
            className={`uploadbtn ${type === "manual" ? "active" : null}`}
            onClick={manualClick}
          >
            Bulk Manual Posting
          </button>
          <button
            className={`autobtn uploadbtn ${
              type === "auto" ? " active" : null
            }`}
            onClick={autoClick}
          >
            Bulk upload Posting
          </button>
        </div>
        {type === "auto" && (
          <div className="sub-main flex--1">
            <div className=" sub-main--1 flex--3">
              <label>No. of Records</label>
              <input
                type="text"
                disabled
                value={bulkUpload.length}
                onChange={handleChange}
              />
            </div>
            <div className="sub-main--2 flex--1">
              <div>
                <input type="file" accept={SheetJSFT} onChange={handleChange} />
              </div>
              <div className="file--buttons flex--1">
                <div className="upload--buttons">
                  <button disabled={disableUploadBtn} onClick={handleFile}>
                    Upload
                  </button>
                </div>

                {/* <CSVLink
                  data={sampleData}
                  headers={excelCells}
                  filename={"BulkTransactionUpload.csv"}
                  className="download--button"
                >
                  Download
                </CSVLink> */}
                <button
                  onClick={(e) => {
                    downloadxls(e, sampleData);
                  }}
                  className="download--button"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
        {type === "manual" && (
          <section className="section1 flex--1">
            {type === "manual" && (
              <div className="adddiv">
                <div className="flex--3 flex--input1">
                  <label>No. of Entries</label>
                  <input value={bulkUpload.length} onChange={handleChange} />
                </div>
                <div className="flex--btn">
                  <label>Add</label>
                  <button className="addbtn" onClick={addBulkUploadInput}>
                    +
                  </button>
                </div>
              </div>
            )}
            <main className="flex--4">
              <div
                style={{
                  display: "flex",
                  width: "93.5%",
                  justifyContent: "space-between",
                }}
              >
                <div className="flex--3 flex--ind">
                  <label>S/No</label>
                </div>

                <div className="flex--3 select--input">
                  <label>A/c Type</label>
                </div>
                <div className="flex--3 select--input">
                  <label>Account No</label>
                </div>
                <div className="flex--3 flex--ind2">
                  <label>Account Name</label>
                </div>
                <div className="flex--3">
                  <label>A/c currency</label>
                </div>
                <div className="flex--3 select--input">
                  <label>Debit/Credit</label>
                </div>
                <div className="flex--3 select--input">
                  <label>Amount</label>
                </div>
                <div className="flex--3 flex--ind2">
                  <label>Narration</label>
                </div>
              </div>

              {bulkUpload.map((item, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  key={i}
                >
                  <div className="flex--3 flex--ind">
                    <input
                      type="text"
                      value={i + 1}
                      name="S_N"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                      disabled
                    />
                  </div>
                  <div className="flex--3 select--input">
                    <select
                      disabled={type === "auto"}
                      className={
                        errors.Account_Type &&
                        errors.Account_Type.find((el) => el.id === i)
                          ? "error-input-z"
                          : null
                      }
                      value={item.Account_Type}
                      name="Account_Type"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                    >
                      <option value="customer">Customer</option>
                      <option value="internal">Internal </option>
                    </select>
                    {errors.Account_Type &&
                      errors.Account_Type.find((el) => el.id === i) && (
                        <ErrorMsg message={"Field is required"} />
                      )}
                  </div>
                  <div className="flex--3 select--input-1">
                    <input
                      disabled={type === "auto"}
                      value={item.Account_No}
                      name="Account_No"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                      className={`ind ${
                        errors.Account_No &&
                        errors.Account_No.find((el) => el.id === i)
                          ? "error-input-z"
                          : null
                      }`}
                    />
                    <ErrorMsg message={item.error ?? ""} />
                    {!item.error &&
                      errors.Account_No &&
                      errors.Account_No.find((el) => el.id === i) && (
                        <ErrorMsg
                          message={
                            errors.Account_No.find((el) => el.id === i).msg
                          }
                        />
                      )}
                  </div>
                  <div className="flex--3 flex--ind2">
                    <input
                      disabled
                      value={item.accountName}
                      name="accountName"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                    />
                  </div>
                  <div className="flex--3 ">
                    <input
                      type="text"
                      disabled
                      value={item.accountCurrency}
                      name="accountCurrency"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                    />
                  </div>
                  <div className="flex--3 select--input">
                    <select
                      disabled={type === "auto"}
                      value={item.Debit_Or_Credit}
                      className={
                        errors.Debit_Or_Credit &&
                        errors.Debit_Or_Credit.find((el) => el.id === i)
                          ? "error-input-z"
                          : null
                      }
                      name="Debit_Or_Credit"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                    >
                      <option value="">Select an option</option>
                      <option value="dr">Debit</option>
                      <option value="cr">Credit</option>
                    </select>
                    {errors.Debit_Or_Credit &&
                      errors.Debit_Or_Credit.find((el) => el.id === i) && (
                        <ErrorMsg message={"Field is required"} />
                      )}
                  </div>
                  <div className="flex--3 select--input-22">
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      value={item.Amount}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={(value) => <div>{item.Amount}</div>}
                      className={`some ${
                        errors.Amount ? "error-input-z" : null
                      }`}
                      onValueChange={(values) => {
                        const lists = [...bulkUpload];
                        lists[i]["Amount"] = values.floatValue;
                        setBulkUpload(() => lists);
                      }}
                    />
                    {errors.Amount &&
                      errors.Amount.find((el) => el.id === i) && (
                        <ErrorMsg
                          message={errors.Amount.find((el) => el.id === i).msg}
                        />
                      )}
                  </div>
                  <div className="flex--3 flex--ind2">
                    <input
                      disabled={type === "auto"}
                      className={
                        errors.Narration &&
                        errors.Narration.find((el) => el.id === i)
                          ? "error-input-z"
                          : null
                      }
                      value={item.Narration}
                      name="Narration"
                      onChange={(e) => handleBulkUploadChange(e, i)}
                    />
                    {errors.Narration &&
                      errors.Narration.find((el) => el.id === i) && (
                        <ErrorMsg message={"Field is required"} />
                      )}
                  </div>
                  <div className="flex--2 deldiv">
                    <button
                      disabled={type === "auto"}
                      className="delbtn"
                      onClick={() => removeBulkUpload(i)}
                    >
                      Del
                    </button>
                  </div>
                </div>
              ))}
            </main>

            <div className="down-div-flex">
              <div className="flex--input1 flex--3">
                <label> Sum of Debit</label>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator={true}
                  inputMode="numeric"
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled
                  value={sumOfDebit}
                  onValueChange={({ floatValue }) =>
                    handleChange({
                      target: {
                        name: "postAmount",
                        value: floatValue,
                      },
                    })
                  }
                />
                {/* <input disabled value={sumOfDebit} onChange={handleChange} /> */}
              </div>
              <div className="flex--3 flex--input1 down-div-flex-1">
                <label>Sum of Credit</label>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator={true}
                  inputMode="numeric"
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled
                  value={sumOfCredit}
                  onValueChange={({ floatValue }) =>
                    handleChange({
                      target: {
                        name: "postAmount",
                        value: floatValue,
                      },
                    })
                  }
                />
                {/* <input disabled value={sumOfCredit} onChange={handleChange} /> */}
              </div>
            </div>
          </section>
        )}
        <AutoTransactionTable
          records={bulkUpload}
          show={type === "auto" && showBulkForm}
          sumOfDebit={sumOfDebit}
          sumOfCredit={sumOfCredit}
        />
        <div className="validate--buttons flex--1">
          <button className="cancelbtn">Cancel</button>
          <button disabled={bulkUpload.length < 2} onClick={validateRecord}>
            {type === "auto" ? "Submit" : "Validate Record"}
          </button>
        </div>
      </main>
    </div>
    // </div>
  );
};

export default BulkTransaction;

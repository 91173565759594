import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Item from "../../DatagridTable/item";
import { numberFormatter } from "../../../formatter";
import { formattedDate } from "../../../formatter/date";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // display:"flex",
    // alignItems:"center",
    // flexDirection:"row"
  },
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  item: {
    border: "1px solid black",
    marginBottom: 30,
    paddingTop: 25,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const LoanProduct = ({ details }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hide, setHide] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [details]);

  return (
    <section className={classes.root}>
      <div style={{ marginTop: 50, padding: 25 }}>
        <>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="simple tabs example"
              >
                <Tab
                  label="Product Details"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Loan product Charges"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Overdue Days"
                  {...a11yProps(2)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Repayment Order"
                  {...a11yProps(3)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Customer Restriction"
                  {...a11yProps(4)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Loan Contribution Product"
                  {...a11yProps(5)}
                  onClick={() => setHide(true)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Product Code" name={details.productCode} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Product Name" name={details.productName} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Product Deactivated"
                    name={details.productStatus ? "active" : "inactive"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Currency" name={details.currency} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Default Principal"
                    name={numberFormatter(details.defaultPrincipal)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Minimum Principal"
                    name={numberFormatter(details.minimumPrincipal)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Maximum Principal"
                    name={numberFormatter(details.maximumPrincipal)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Default Tenor" name={details.defaultTenor} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Minimum Tenor" name={details.minimumTenor} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Tenor Basis" name={details.maximumTenor} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Rate Type" name={details.tenorBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Default Interest Rate"
                    name={details.defaultInterestRate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Minimum Interest Rate"
                    name={details.minimumInterestRate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Maximum Interest Rate"
                    name={details.maximumInterestRate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Rate Basis" name={details.rateBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Minimum Variance"
                    name={details.minimumVariance}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Maximum Variance"
                    name={details.maximumVariance}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Minimum Interest Rate"
                    name={details.minimumInterestRate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Repayment Frequency"
                    name={details.repaymentFrequency}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Interest Calculation Method"
                    name={details.interestCalculationMethod}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Period" name={details.interestPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Liquidation Type"
                    name={details.liquidationType}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Accrual Period" name={details.accrualPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Disbursement Method"
                    name={details.disbursementMethod}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Loan Account" name={details.loanAccount} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Interest Account"
                    name={details.interestAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Interest Receivable Account"
                    name={details.interestReceivableAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Penal Interest Account"
                    name={details.penalInterestAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Loan Write-off account"
                    name={details.loanWriteOff}
                  />
                </Grid>
                {details && details.equityMultiple && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Equity Multiple"
                      name={details.equityMultiple}
                    />
                  </Grid>
                )}
                {details && details.adminFee && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item label="Admin Fee" name={details.adminFee} />
                  </Grid>
                )}
                {details && details.loanBelowHundredFee && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Charges For Loan Below 100K"
                      name={details.loanBelowHundredFee}
                    />
                  </Grid>
                )}
                {details && details.loanAboveHundredFee && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      label="Charges For Loan Above 100K"
                      name={details.loanAboveHundredFee}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Date Authorized" name={details.dateAuthorized} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Status"
                    name={
                      details.authorizationStatus ===
                      "PendingEditedAuthorization"
                        ? "Pending Edited Authorization"
                        : details.authorizationStatus
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Created Date Time"
                    name={details.createdDateTime}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Updated Date Time"
                    name={details.updatedDateTime}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <input type="checkbox" checked={details.accrualRequired} />
                  <label>Accrual Required</label>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <input
                    type="checkbox"
                    checked={details.partialDisbursementAllowed}
                  />
                  <label>Partial Disbursement</label>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <input type="checkbox" checked={details.guarantorRequired} />
                  <label>Guarantor Required</label>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <input type="checkbox" checked={details.bulletpayment} />
                  <label>Bullet Payment</label>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {details &&
                details.loanProductCharge &&
                details.loanProductCharge.map((item, i) => (
                  <Grid
                    container
                    spacing={2}
                    border={1}
                    className={classes.item}
                    key={item.id}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Charge Name" name={item.chargeName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Apply Charge At" name={item.applyChargeAt} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Charge Ledger Account"
                        name={item.chargeLedgerAccount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Type of charge" name={item.typeOfCharge} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Amount" name={item.amount} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Rate" name={item.rate} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Charge Currency"
                        name={item.chargeCurrency}
                      />
                    </Grid>
                  </Grid>
                ))}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {details &&
                details.overdueDays &&
                details.overdueDays.map((item, i) => (
                  <Grid
                    container
                    spacing={1}
                    className={classes.item}
                    key={item.id}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="From" name={item.from} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="To" name={item.to} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Loan Status" name={item.loanStatus} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Created Date Time"
                        name={formattedDate(item.createdDateTime)}
                      />
                    </Grid>
                  </Grid>
                ))}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {details &&
                details.repaymentOrder &&
                details.repaymentOrder.map((item, i) => (
                  <Grid
                    container
                    spacing={1}
                    className={classes.item}
                    key={item.id}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Sequence Id" name={item.sequenceId} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Repayment Type" name={item.repaymentType} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Created Date Time"
                        name={formattedDate(item.createdDateTime)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Updated Date Time"
                        name={formattedDate(item.updatedDateTime)}
                      />
                    </Grid>
                  </Grid>
                ))}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {details &&
                details.customerRestriction &&
                details.customerRestriction.map((item, i) => (
                  <Grid container spacing={1} className={classes.item} key={i}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Restriction Type"
                        name={item.restrictionType}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Customer Category"
                        name={item.customerCategory}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item label="Customer Name" name={item.customerName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Created Date Time"
                        name={formattedDate(item.createdDateTime)}
                      />
                    </Grid>
                  </Grid>
                ))}
            </TabPanel>
            <TabPanel value={value} index={5}>
              {details &&
                details.loanContributionProduct &&
                details.loanContributionProduct.map((item, i) => (
                  <Grid
                    container
                    spacing={1}
                    className={classes.item}
                    key={item.id}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="Account Product ID"
                        name={item.loanProductId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item
                        label="account Product Code"
                        name={item.productCode}
                      />
                    </Grid>
                  </Grid>
                ))}
            </TabPanel>
          </div>
        </>
      </div>
    </section>
    // <Grid container spacing={2} className={classes.root}>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Product Code" name={details.productCode} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Product Name" name={details.productName} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Product Deactivated"
    //       name={details.productStatus ? "active" : "inactive"}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Currency" name={details.currency} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Default Principal"
    //       name={numberFormatter(details.defaultPrincipal)}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Minimum Principal"
    //       name={numberFormatter(details.minimumPrincipal)}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Maximum Principal"
    //       name={numberFormatter(details.maximumPrincipal)}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Default Tenor" name={details.defaultTenor} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Minimum Tenor" name={details.minimumTenor} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Tenor Basis" name={details.maximumTenor} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Rate Type" name={details.tenorBasis} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Default Interest Rate"
    //       name={details.defaultInterestRate}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Minimum Interest Rate"
    //       name={details.minimumInterestRate}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Maximum Interest Rate"
    //       name={details.maximumInterestRate}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Rate Basis" name={details.rateBasis} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Minimum Variance" name={details.minimumVariance} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Maximum Variance" name={details.maximumVariance} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Minimum Interest Rate"
    //       name={details.minimumInterestRate}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Repayment Frequency"
    //       name={details.repaymentFrequency}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Interest Calculation Method"
    //       name={details.interestCalculationMethod}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Interest Period"
    //       name={details.interestPeriod}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Liquidation Type"
    //       name={details.liquidationType}
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Accrual Period" name={details.accrualPeriod} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Disbursement Method" name={details.disbursementMethod} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Loan Account" name={details.loanAccount} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Interest Account" name={details.interestAccount} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Interest Receivable Account" name={details.interestReceivableAccount} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Penal Interest Account" name={details.penalInterestAccount} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Loan Write-off account" name={details.loanWriteOff} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Created Date Time" name={details.createdDateTime} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Updated Date Time" name={details.updatedDateTime} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <input type="checkbox" checked={details.accrualRequired}/>
    //     <label>Accrual Required</label>
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <input type="checkbox" checked={details.partialDisbursementAllowed}/>
    //     <label>Partial Disbursement</label>
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <input type="checkbox" checked={details.guarantorRequired}/>
    //     <label>Guarantor Required</label>
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <input type="checkbox" checked={details.bulletpayment}/>
    //     <label>Bullet Payment</label>
    //   </Grid>
    //   <Divider className={classes.hr} />
    //   <h6 className={classes.text}>loan Product Charge</h6>
    //   <table className={classes.table}>
    //     <thead>
    //       <tr>
    //         <th>Name</th>
    //         <th>Charges</th>
    //         <th>Account</th>
    //         <th>Type</th>
    //         <th>Amount</th>
    //         <th>Rate</th>
    //         <th>Currency</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {details &&
    //         details.loanProductCharge &&
    //         details.loanProductCharge.map((el, i) => (
    //           <tr key={i}>
    //             <td>
    //               <Item label="" name={el.chargeName} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.applyChargeAt} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.chargeLedgerAccount} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.typeOfCharge} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.amount} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.rate} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.chargeCurrency} />
    //             </td>
    //           </tr>
    //         ))}
    //     </tbody>
    //   </table>
    //   <h6 className={classes.text}>Overdue Days</h6>
    //   <table className={classes.table}>
    //     <thead>
    //       <tr>
    //         <th>From</th>
    //         <th>To</th>
    //         <th>Loan Status</th>
    //         <th>Created Date Time</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {details &&
    //         details.overdueDays &&
    //         details.overdueDays.map((el, i) => (
    //           <tr key={i}>
    //             <td>
    //               <Item label="" name={el.from} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.to} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.loanStatus} />
    //             </td>
    //             <td>
    //               <Item label="" name={formattedDate(el.createdDateTime)} />
    //             </td>
    //           </tr>
    //         ))}
    //     </tbody>
    //   </table>
    //   <h6 className={classes.text}>Customer Restriction</h6>
    //   <table className={classes.table}>
    //     <thead>
    //       <tr>
    //         <th>Restriction Type</th>
    //         <th>Customer Category</th>
    //         <th>Customer Name</th>
    //         <th>Created Date Time</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {details &&
    //         details.customerRestriction &&
    //         details.customerRestriction.map((el, i) => (
    //           <tr key={i}>
    //             <td>
    //               <Item label="" name={el.restrictionType} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.customerCategory} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.customerName} />
    //             </td>
    //             <td>
    //               <Item label="" name={formattedDate(el.createdDateTime)} />
    //             </td>
    //           </tr>
    //         ))}
    //     </tbody>
    //   </table>
    //   <h6 className={classes.text}>Repayment Order</h6>
    //   <table className={classes.table}>
    //     <thead>
    //       <tr>
    //         <th>sequence Id</th>
    //         <th>Repayment Type</th>
    //         <th>Created Date Time</th>
    //         {/* <th>updated Date Time</th> */}
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {details &&
    //         details.repaymentOrder &&
    //         details.repaymentOrder.map((el, i) => (
    //           <tr key={i}>
    //             <td>
    //               <Item label="" name={el.sequenceId} />
    //             </td>
    //             <td>
    //               <Item label="" name={el.repaymentType} />
    //             </td>
    //             <td>
    //               <Item label="" name={formattedDate(el.createdDateTime)} />
    //             </td>
    //             {/* <td>
    //                 <Item label="" name={formattedDate(el.updatedDateTime)} />
    //               </td> */}
    //           </tr>
    //         ))}
    //     </tbody>
    //   </table>
    //   <Divider className={classes.hr} />
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item label="Date Authorized" name={details.dateAuthorized} />
    //   </Grid>
    //   <Grid item xs={12} sm={6} md={4} lg={3}>
    //     <Item
    //       label="Status"
    //       name={
    //         details.authorizationStatus === "PendingEditedAuthorization"
    //           ? "Pending Edited Authorization"
    //           : details.authorizationStatus
    //       }
    //     />
    //   </Grid>
    // </Grid>
  );
};

export default LoanProduct;

import { Grid } from "@material-ui/core";

import { baseUrl } from "../../../../Api/configUrl";
import DetailsContainer from "../../../DatagridTable/view";
import Details from "../../../Details/Treasury/PortfolioMaintainance";
import Item from "../../../DatagridTable/item";
import { formattedDate } from "../../../../formatter/date";


const View = ({ details }) => {
  return (
    <DetailsContainer
      title="Portfolio Maintainance - Record Details"
      question="Are you sure you want to delete this Portfolio?"
      url={`${baseUrl}treasury/PortfolioMaintenance/delete/${details.id}`}
    >
       <Details details={details}/>   
    </DetailsContainer>
  );
};

export default View;

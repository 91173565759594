import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Table from "../../../DatagridTable/table";
import DetailsContainer from "../../../DatagridTable/details";
import Details from "../../../Details/CoreModules/InstitutionDefintion";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { Routes } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { useCallback } from "react";

const InstitutionDefinition = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "institutionType",
      label: "Institution Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "stateCode",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "countryCode",
      label: "Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "code",
      label: "institution Code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllInstitution = useCallback(async () => {
    dispatch(loading());
    const response = await getHandler(token, Routes.getAllUnauthorizedInstitutions);
    if (response && response.data) {
      dispatch(loading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].createdDateTime = formattedDate(response.data[i].createdDateTime);
        response.data[i].dateAuthorized = formattedDate(response.data[i].dateAuthorized);
        response.data[i].updatedDateTime = formattedDate(response.data[i].updatedDateTime);
      };
    };
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllInstitution();
  }, [fetchAllInstitution,updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      {!load && <Table
        title="Institution Definition"
        subtitle=""
        name="List of institutions"
        records={data}
        options={options}
        columns={columns}
      />}
      <DetailsContainer
        title="Institution Definition - Record Details"
      >
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize institution"
        question="Are you sure you want to authorize this institution?"
        url={`${Routes.authorizeInstitutionType}${details.id}`}
      />
    </div>
  );
};

export default InstitutionDefinition;

import { useState, useEffect } from "react";
import Item from "../../DatagridTable/item";
import { Grid, Divider } from "@material-ui/core";
import { formattedDate } from "../../../formatter/date";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
    root: {},
    text: {
      margin: "auto",
      marginTop: "1rem",
      color: theme.palette.secondary.main,
    },
    hr: {
      backgroundColor: "black",
      width: "100%",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    table: {
      width: "100%",
      "& th": {
        paddingTop: 12,
        paddingBottom: 12,
        textAlign: "left",
      },
      "& td": {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    noData:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"row",
    },
    item:{
      width:'100%',
      border: "1px solid black",
      marginBottom: 30,
      paddingTop: 25,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    }
  }));
  
const AccountProducts = ({details}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    useEffect(() => {
      setValue(0);
    }, [details]);

    return (
      <section className={classes.root}>
        <div style={{ marginTop: 50, padding: 25 }}>
          <>
            <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Product Details (1)"
                  {...a11yProps(0)}
                  onClick={() => setHide(false)}
                />
                <Tab
                  label="Product Details (2)"
                  {...a11yProps(1)}
                  onClick={() => setHide(true)}
                />
                <Tab
                  label="Customer Restriction"
                  {...a11yProps(4)}
                  onClick={() => setHide(true)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Product Code" name={details.productCode} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Product Name" name={details.productName} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Deactivated" name={details.status ? 'Yes' : 'No'} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Type" name={details.accountType} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Payable Account" name={details.interestPayableAccount} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Interest Receivable Account" name={details.interestReceivableAccount} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Item label="Interest Income" name={details.interestIncome} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Item label="Interest Expense" name={details.interestExpense} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Debit Account"
                    name={details.debitAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Credit Account"
                    name={details.creditAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="No. of Days to activate Inactivity" name={details.noOfDaysToActivateInactivity} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="No. of Inactive Days Till Dorm" name={details.noOfInactiveDormantDays} />
                </Grid>
                {details && details.gateWayCreditAccount &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Gateway Credit Account" name={details.gateWayCreditAccount} />
                </Grid>)}
                {details && details.gateWayDebitAccount &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Gateway Debit Account" name={details.gateWayDebitAccount} />
                </Grid>)}
                {details && details.membershipFee &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Membership Fee" name={details.membershipFee} />
                </Grid>)}
                {details && details.deductions &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Deductions" name={details.deductions} />
                </Grid>)}
                {details && details.minimumContribution &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Minimum Contribution" name={details.minimumContribution} />
                </Grid>)}
                {details && details.tenureType &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Tenure Type" name={details.tenureType} />
                </Grid>)}
                {details && details.tenureForContribution &&(<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Tenure For Contribution" name={details.tenureForContribution} />
                </Grid>)}
                {details && details.baseAllowableContribution && (<Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Base Allowable Contribution" name={details.baseAllowableContribution} />
                </Grid>)}
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Item
                    label="Track Domancy"
                    name={details.trackDormancy ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Debit Balance Allowed"
                    name={details.debitBalanceAllowed ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Apply Debit Interest"
                    name={details.applyDeditInterest ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Apply Credit Interest"
                    name={details.applyCreditInterest ? "Yes" : "No"}
                  />
                </Grid>
                {details && details.isEligibleForLoan &&(<Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Loan Eligibility"
                    name={details.isEligibleForLoan ? "Yes" : "No"}
                  />
                </Grid>)}
                {details && details.requireMemberShipFee &&(<Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Require Membership Fee"
                    name={details.requireMemberShipFee ? "Yes" : "No"}
                  />
                </Grid>)}

                {/* <Divider className={classes.hr} />
                <h6 className={classes.text}>Customer Restriction</h6> */}
                
                {/* <Divider className={classes.hr} /> */}
                
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Enforce Min Bal Check"
                    name={details.enforceMinBalCheck ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Reactivation Type" name={details.accountReactivationType} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Reactivation Type" name={details.reactivationTrigger} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Account Reactivation Type" name={details.accountReactivationType} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Expected Minimum Balance" name={details.expectedMinBalance} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Rate (Debit)" name={details.debitInterestRate} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Rate Basis (Debit)" name={details.debitRateBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Period (Debit)" name={details.debitInterestPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Balance Basis (Debit)" name={details.debitBalanceBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="VAT Rate" name={details.debitInterestVATRate} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="VAT Account" name={details.vatAccountNo} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="WHT Rate" name={details.creditInterestWHTRate} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="WHT Account" name={details.whtAccountNo} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Calc. Period (Debit)" name={details.debitInterestCalculationPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Accrue Interest (Debit)" name={details.debitAccrueInterest} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Rate (Credit)" name={details.creditInterestRate} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Rate Basis (Credit)" name={details.creditRateBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Period (Credit)" name={details.creditInterestPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Balance Basis (Credit)" name={details.creditBalanceBasis} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Calc. Period (Credit)" name={details.creditInterestCalculationPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Item label="Balance Req For Credit Interest" name={details.balanceRequestCreditInterest} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Item label="Currency" name={details.currency} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Accrue Interest (Credit)" name={details.creditAccrueInterest} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Interest Liquidation Period" name={details.interestLiquidationPeriod} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Created Date Time" name={details.createdDateTime} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Updated Date Time" name={details.updatedDateTime} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Date Authorized" name={details.dateAuthorized} />
                </Grid>
                {details.reason && <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item label="Reason" name={details.reason} />
                </Grid>}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Item
                    label="Status"
                    name={
                      details.authorizationStatus === "PendingEditedAuthorization"
                        ? "Pending Edited Authorization"
                        : details.authorizationStatus
                    }
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Grid container spacing={1}>
              {details && details.createCustomerRestriction && details.createCustomerRestriction.length > 0 ? (
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Customer Category</th>
                        <th>Customer Name</th>
                        <th>Created Date Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.createCustomerRestriction.map((el, i) => (
                        <tr key={i}>
                          <td>
                            <Item label="" name={el.customerCategory} />
                          </td>
                          <td>
                            <Item label="" name={el.customerName} />
                          </td>
                          <td>
                            <Item label="" name={formattedDate(el.createdDateTime)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ):  <Grid xs={12}>
                <div className={classes.noData}>
                <Item label="" name="Customer restrictions are not applicable" className={classes.item}/>
                </div>
              </Grid>}
              </Grid>
            </TabPanel>

            </div>
          </>
        </div>
      </section> 

    )
}

export default AccountProducts

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Table from "../../../DatagridTable/table";
import Details from "../../../DatagridTable/details";
import DetailsContainer from "../../../DatagridTable/details";
import AuthorizationModal from "../../../DatagridTable/authorizationModal";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import Spinner from "../../../Backdrop";
import { openDetailsModal, loading } from "../../../../Api/redux-manager/actions";
import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { useCallback } from "react";


const InvestmentAmendment = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const load = useSelector((state) => state.approve.loading);
  const updateTable = useSelector((state) => state.approve.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "investmentAccountNumber",
      label: "Investment Acc. No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "expectedInterestAmount",
      label: "Expected Interest Amt.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bookDate",
      label: "Book Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      const index = rowMeta.dataIndex;
      const item = data[index];
      if (item && item.id) {
        setDetails(item);
        dispatch(openDetailsModal());
      }
    },
  };


  const fetchAllBranches = useCallback(async () => {
    dispatch(loading());
    const url = `${baseUrl}investment/InvestmentBooking/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(loading());
    if (response.data.length > 0){
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].sn = i + 1;
        response.data[i].bookDate = formattedDate(response.data[i].bookDate);
        response.data[i].maturityDate = formattedDate(response.data[i].maturityDate);
        response.data[i].createdDateTime = formattedDate(response.data[i].createdDateTime);
        response.data[i].dateAuthorized = formattedDate(response.data[i].dateAuthorized);
        response.data[i].updatedDateTime = formattedDate(response.data[i].updatedDateTime);
      };
    };
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllBranches();
  }, [fetchAllBranches, updateTable]);

  return (
    <div>
      <Spinner loading={load} />
      <Table
        title="Investment Booking"
        subtitle=""
        name="List of investments"
        records={data}
        options={options}
        columns={columns}
      />
      <DetailsContainer
        title="Investment Amendment - Record Details"
      >
        <Details details={details}/>
      </DetailsContainer>
      <AuthorizationModal
        title="Authorize investment"
        question="Are you sure you want to authorize this investment?"
        url={`${baseUrl}investment/InvestmentBooking/Authorize/${details.id}`}
      />
    </div>
  );
};

export default InvestmentAmendment;

import React from 'react'
import './Main.scss'
import { Switch, Route} from 'react-router-dom'
import BalanceSheets from './BalanceSheets/BalanceSheets';
import GLReports from './GLReports/GLReports';
import ProfitandLoss from './ProfitandLoss/ProfitandLoss';
import TrialBalance from './TrialBalance/TrialBalance';

const Main = () => {
    return (
        <div className='main'>
           <Switch>
               <Route exact path='/dashboard/eodreports/balancesheets' component={BalanceSheets}/>
               <Route exact path='/dashboard/eodreports/glreports' component={GLReports}/>
               <Route exact path='/dashboard/eodreports/profitandloss' component={ProfitandLoss}/>
               <Route exact path='/dashboard/eodreports/trialbalance' component={TrialBalance}/>
               </Switch> 
        </div>
    )
}

export default Main

import React, { useEffect, useState } from "react";
import "./ExistingCustomerSection.scss";
import { Grid, TextField, MenuItem, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { createNewAccountForExistingMember } from "../../../Api/Hooks-manager/accountOpening/existingCustomer";
import {
  isLoading,
  openViewModal,
  openDetailsModal,
} from "../../../Api/redux-manager/actions";
import useCustom2 from "../../../Api/Hooks-manager/products/useCustom";
import ErrorMessage from "../../../components/ErrorMessage";

const ExistingCustomerSection = ({ errors }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.common.userDetails.jwtToken);

  const { allAccountProduct, getAccountDefinitionAll } = useCustom2();

  const [existingData, setExistingData] = useState({
    productId: "",
    customerId: "",
  });

  const [msg, setMsg] = useState("");

  useEffect(() => {
    getAccountDefinitionAll();
  }, [getAccountDefinitionAll]);

  console.log(existingData);

  const filteredAccountProduct = allAccountProduct.filter(
    (items) => items.authorizationStatus === "Authorized"
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExistingData((prev) => {
      return {
        ...prev, // Spread Operator
        [name]: value,
      };
    });
  };

  const createAccountForExistingCustomer = async () => {
    dispatch(isLoading());
    const data = { ...existingData };
    const response = createNewAccountForExistingMember(token, data);
    if (response && response.data) {
      dispatch(isLoading());
      setMsg(response.message);
      console.log("success");
    } else {
      setMsg(response.message);
    }
  };

  return (
    <div className="menu">
      <Grid
        container
        spacing={1}
        sx={{ padding: "2rem", justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            variant="outlined"
            type="text"
            label="Enter customer ID"
            name="customerId"
            value={existingData.customerId}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          {!filteredAccountProduct.length && (
            <TextField
              name="productId"
              onChange={handleChange}
              variant="outlined"
              value={existingData.productId}
              select
              label="Select account product"
              fullWidth
              type="number"
            >
              <MenuItem>Select An Option</MenuItem>
              <MenuItem>No Authorized Record Found</MenuItem>
            </TextField>
          )}
          {filteredAccountProduct.length > 0 && (
            <>
              <TextField
                name="productId"
                onChange={handleChange}
                variant="outlined"
                value={existingData.productId}
                select
                label="Select account product"
                fullWidth
                // className={`${
                //   Object.keys(errors).includes("accountProductCode") &&
                //   "error-input-z"
                // }`}
              >
                <MenuItem>Select An Option</MenuItem>
                {filteredAccountProduct.map((items, index) => {
                  return (
                    <MenuItem key={index} value={items.productCode}>
                      {`${items.productCode} -- ${items.productName}`}
                    </MenuItem>
                  );
                })}
              </TextField>
              {/* {errors.productId && (
                <ErrorMessage message={errors.productId} />
              )} */}
            </>
          )}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{ color: "#fff", bgcolor: "#D0021B" }}
            onClick={createAccountForExistingCustomer}
          >
            Create Account
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExistingCustomerSection;

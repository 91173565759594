import React from 'react'
import './Main.scss'
import { Switch, Route } from "react-router-dom";
import BuyCash from './BuyCash/BuyCash';
import SellCash from './SellCash/SellCash';
import DenominationExchange from './DenominationExchange/DenominationExchange';
import TellerTillExchange from './TellerTillExchange/TellerTillExchange';
import TillManagement from './TillManagement/TillManagement';





const Main = () => { 
    return ( 
        <div className='main'>
            <Switch> 
                <Route path='/dashboard/vaultoperations/001'component={BuyCash}/>
                <Route path='/dashboard/vaultoperations/002'component={SellCash}/>
                <Route path='/dashboard/vaultoperations/003'component={DenominationExchange}/>
                <Route path='/dashboard/vaultoperations/004'component={TellerTillExchange}/>
                <Route path='/dashboard/vaultoperations/005'component={TillManagement}/>
            </Switch>
        </div>
    )
}

export default Main

import React, { useState, useEffect } from "react";
import "./BuyCash.scss";
import MenuItem from '@material-ui/core/MenuItem';
import { AiTwotoneDelete } from "react-icons/ai";
import BusinessLogic from "./businessLogic";
import Grid from "@material-ui/core/Grid";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import NumberFormat from "react-number-format";
import { FiEdit } from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";
import useTable from "../../../Tables/useTable";
import ErrorMessage from "../../../ErrorMessage";
import ErrorModal from "../../../../Confirmbox/myModal";

import { getCountries } from "../../../../Api/places/getPlaces";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

const BuyCash = () => {
  const {
    glTransaction,
    setGlTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    denomTotalValue,
    denominationValue,
    setDenominationValue,
    screenChange,
    handleScreenChange,
    user,
    error,
    errors,
    index,
    setErrors,
    validateInput,
    handleChange,
    handleChanger,
    handleNext,
    handleDenominationChange,
    makeTransfer,
    makeAutoTransfer,
    open,
    setOpen,
    openErrorModal,
    setOpenErrorModal,
    errorMsg,
    errorSummary,
    message,
    handleSubmit,
    allBranches,
  } = BusinessLogic();

  const { denominations } = glTransaction;

  console.log("glTransaction", glTransaction);

  // const [filterFn, setFilterFn] = useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });
  // const headCells = [
  //   { id: "id", label: "S/N" },
  //   { id: "tillAccountNumber", label: "Till Account No" },
  //   { id: "branchId", label: "Branch" },
  //   { id: "country", label: "Country" },
  //   { id: "tellerName", label: "Teller Name" },
  //   { id: "amount", label: "Amount" },
  //   { id: "action", label: "Action", disableSorting: true },
  // ];
  // const {
  //   TblContainer,
  //   TblHead,
  //   TblPagination,
  //   recordsAfterPagingAndSorting,
  //   setPage
  // } = useTable(allTellerTills, headCells, filterFn);

  // const fetchCountries = async () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const response = await getCountries(user.jwtToken);
  //   console.log(response, "wertyui");
  //   if (response) {
  //     setCountries(response.data);
  //   }
  // };

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setPage(0)
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value === "") return items;
  //       else
  //         return items.filter((x) =>
  //           x.name.toLowerCase().includes(target.value)
  //         );
  //     },
  //   });
  // };



// useEffect(() => {
//     fetchCountries();
// }, [])


  return (
    <main className="buy--cash">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={makeTransfer}
        actionMsg={message}
        openBox={open}
        setOpenBoxState={setOpen}
        errorMessage={message}
      />
      <ErrorModal
        title="Error Summary"
        openDialog={openErrorModal}
        positiveDialog={false}
        onClose={() => setOpenErrorModal(false)}
      >
        <div>
        {errorSummary && errorSummary.notBalance && (
            <div>{errorSummary.notBalance}</div>
          )}
        </div>
      </ErrorModal>
        <div className="heading">
          <p>Buy Cash</p>
        </div>
      <section className="section--1">
        <div className="flex--3">
          <div className="button--double flex--2">
            <div className="flex--3">
              <button
                className={`${!screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                1
              </button>
              <p>Transaction</p>
            </div>
            <div className="flex--3">
              <button
                className={`${screenChange ? "active-1" : ""}`}
                onClick={() => handleScreenChange(true)}
              >
                2
              </button>
              <p>Denominations</p>
            </div>
          </div>
          {!screenChange && (
            <>
              <Grid
                container
                spacing={1}
                style={{ padding: 40 }}
                className="sub--section"
              >
                <Grid item xs={12} sm={6} md={4}>
                  <label>Currency</label>
                  <br />
                  <input
                    type="text"
                    disabled
                    className={`pink ${
                      errors.currencyCode ? "error-input-z" : null
                    }`}
                    name="currencyCode"
                    value={glTransaction.currencyCode}
                    onChange={handleChange}
                  />
                  {errors.currencyCode && (
                    <ErrorMessage message={errors.currencyCode} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Till Account No.</label>
                  <br />
                  <input
                    disabled
                    name="debitAccount"
                    className={`pink ${
                      errors.debitAccount ? "error-input-z" : null
                    }`}
                    value={glTransaction.debitAccount}
                    onChange={handleChange}
                  />
                  {errors.debitAccount && (
                    <ErrorMessage message={errors.debitAccount} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Till Account Name</label>
                  <br/>
                  <input
                    disabled
                    name="accountName"
                    value={debitAccDetails.accountName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Teller Name</label>
                  <br />
                  <input
                    disabled
                    name="product"
                    value={user.firstName + " " + user.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Branch</label>
                  <br />
                  <input
                    disabled
                    name="entryBranch"
                    value={glTransaction.entryBranch}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Amount</label>
                  <br />
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator={true}
                    inputMode="numeric"
                    name="postAmount"
                    value={glTransaction.postAmount}
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                    className={`${
                      errors.postAmount ? "error-input-z" : null
                    }`}
                    onValueChange={({ floatValue }) =>
                      handleChange({
                        target: {
                          name: "postAmount",
                          value: floatValue,
                        },
                      })
                    }
                  />
                  {errors.postAmount && (
                    <ErrorMessage message={errors.postAmount} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label>Narration</label>
                  <br />
                  <input
                    type="text"
                    name="narration"
                    onChange={handleChange}
                    value={glTransaction.narration}
                  />
                </Grid>
              </Grid>
                <div className="flex--5">
                  <button onClick={() => handleScreenChange(true)}>Next</button>
                </div>
              {/* <div className="sub--section-3 flex--3">
                <label>Narration</label>
                <input
                
                  name="narration"
                  // value={user.narration}
                  onChange={handleChanger}
                  className={`branch-input ${error.narration && 'error-input-z'}`}
                />
                {error.narration && <ErrorMessage message={error.narration} />}
              </div> */}
              {/* <div className="button-last">
                <button onClick={handleNext}>Next</button>
              </div> */}
              {/* <hr /> */}
              {/* <h4>Buy Cash</h4>
              <div className="sub--section flex--2">
                <div className="main-filter flex--2">
                  <input
                    className="filter"
                    placeholder="Filter"
                    onChange={handleSearch}
                  />
                  <button className="button--second">Remove Filter</button>
                </div>
              </div> */}
              {/* Table comes in here */}
              {/* <TblContainer>
                <TblHead />
                <tbody>
                  {recordsAfterPagingAndSorting().map((investment, i) => (
                    <tr key={investment.id}>
                      {console.log(investment, "investmentinvestment")}
                      <th scope="row">{i + 1}</th>
                      <td>{investment.tillId}e</td>
                      <td>{investment.branchId}</td>
                      <td>{investment.accountNumber}</td>
                      <td>{investment.authorizationStatus}</td>
                      <td>
                        <div className={`table--icon ${(investment.authorizationStatus === ('Authorized') || investment.authorizationStatus === ('Rejected')) && 'disabled-delete'}`}>
                        <DotMenu>
                          <MenuItem>Edit</MenuItem>
                          <MenuItem>Delete</MenuItem>
                          <MenuItem>View</MenuItem>
                        </DotMenu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TblContainer>
              <TblPagination /> */}
            </>
          )}
          {screenChange && (
            <>
              <main className="sub-main">
              <section className="sub-main--1 flex--2">
                  <div className="flex--2">
                    <p>Amount</p>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      value={glTransaction.postAmount}
                      onValueChange={({ floatValue }) =>
                        handleChange({
                          target: {
                            name: "postAmount",
                            value: floatValue,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="flex--2">
                    <p>Net Amount</p>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // renderText={value => <div>{glTransaction.postAmount}</div>}
                      // renderText={(value) => <div>{glTransaction.postAmount}</div>}
                      className={`${
                        errors.postAmount ? "error-input-z" : null
                      }`}
                      name="postAmount"
                      disabled
                      value={denomTotalValue}
                      onChange={() => {}}
                    />
                  </div>
                </section>
                <section className="sub-main--2 flex--3">
                  {denominations.map((item, i) => (<div key={i}>
                    <div className="multiplier-input flex--2">
                      <input type="number"
                        value={item.denomNo}
                        disabled
                        name="denomNo"
                      />
                      <span>&times;</span>
                      <input
                        type="number"
                        value={item.denomCount}
                        name="denomCount"
                        onChange={(e) => handleDenominationChange(e, i)}
                      />
                      <span>&#61;</span>
                      <input
                        disabled
                        name="denomTotal"
                        type="number"
                        // onValueChange= {(values) => {
                        //   setGlTransaction((glTransaction) => ({
                        //     ...glTransaction,
                        //     denomTotal: (values.value)
                        //   }))
                        // }}
                        value={item.denomTotal}
                      />
                    </div>                
                  </div>))}
                  
                </section>
              </main>
              <div className="button-last button-last--1 flex--2">
                <div className="button-container flex--1">
                  <button onClick={() => handleScreenChange(false)}>
                    Previous
                  </button>
                  <button onClick={handleSubmit}>Save</button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default BuyCash;

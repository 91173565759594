import { useState, useEffect, useCallback } from "react";
import "./ManageRoles.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { formattedDate } from "../../../../formatter/date";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";
// import {
//   openDetailsModal,
//   loading,
// } from "../../../../Api/redux-manager/actions";


import useForm from "../../../../Api/Hooks-manager/securitymanagement/useCustom";
import manageRolesTable from "../../../Tables/useTable";
import Spinner from "../../../Backdrop";
import Confirm from "../../../../Confirmbox/Confirm";
import { validateManageRoles } from "../../../../Api/validator/validate11";
import ErrorMessage from "../../../ErrorMessage";
import DotMenu from "../../../../commons/dotMenu/dotMenu";
import { baseUrl } from "../../../../Api/configUrl";
import EditForm from "./edit";
import Details from "./view";
// import {authorizeARole} from "../../../../Api/Hooks-manager/securitymanagement/manageRoles"

const ManageRoles = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector((state) => state.req.loading);
  const updateTable = useSelector((state) => state.req.updateTable);
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const {
    errorMsg,
    setRoleValues,
    roleExist,
    getAllPermissions,
    allRoles,
    getAllRolesById,
    roleValues,
    permission,
    handleChange,
    handleCreateRoleWithPermission,
    editUserData,
    correctWayOfAddingList,
    setCorrectWayOfAddingList,
  } = useForm();
  const [permissionArray, setPermissionArray] = useState([]);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [errors, setErrors] = useState({});
  const [permissionArrayUX, setPermissionArrayUX] = useState([]);
  const [errorEdit, setErrorEdit] = useState({});
  const [showEditPermissionModal, setShowEditPermissionModal] = useState(false);


  const [user, setUser] = useState({
    // title: "",
    name: "",
    description: "",
    authorizationStatus: "",
  });

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Role Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Role Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: (status) => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={async () => {
                const singleItem = data[dataIndex];
                if (singleItem && singleItem.id) {
                  const permissions = await getPermissionsByRoleName(
                    singleItem.name
                  );
                  singleItem.permissionArray = permissions.permissionArray;
                  singleItem.rolePermissions = permissions.rolePermissions;
                }
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={async () => {
                const singleItem = data[dataIndex];
                if (singleItem && singleItem.id) {
                  const permissions = await getPermissionsByRoleName(singleItem.name);
                  singleItem.permissionArray = permissions.permissionArray;
                  singleItem.rolePermissions = permissions.rolePermissions;
                }
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const getPermissionsByRoleName = async (roleName) => {
    dispatch(isLoading());
    const url = `${baseUrl}user/Role/GetAllPermissions/${roleName}`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      const permissions = response.data.permissions; //[{},{}] All permissions attached to a role
      const rolePermissions = permissions.map((item) => item.id); //[1,2,3] Role permission ids
      const tempLists = [...correctWayOfAddingList]; //[{},{} all kinds of permissions in the app
      for (let i = 0; i < tempLists.length; i++) {
        if (rolePermissions.includes(tempLists[i].id)) {
          tempLists[i]["isSelected"] = true;
          tempLists[i]["isInDatabase"] = true;
        }
      }
      setCorrectWayOfAddingList(tempLists);
      return { permissionArray: permissions, rolePermissions: rolePermissions };
    }
  };

  const fetchAllRoles = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}user/Role/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  },[dispatch,token])

  useEffect(() => {
    fetchAllRoles();
  }, [fetchAllRoles,updateTable]);

  useEffect(() => {
    let initialPermission = JSON.parse(localStorage.getItem("permission"));
    if (initialPermission === null) {
      let array = [];
      let userPermits = JSON.stringify(array);
      localStorage.setItem("permission", userPermits);
      let initialPermit = JSON.parse(localStorage.getItem("permission"));
      setPermissionArray(initialPermit);
    } else {
      setPermissionArray(initialPermission);
    }
  }, []);

  const openEditPermissionModal = () => {
    const permissions = [...details.permissionArray];
    const rolePermissions = permissions.map((item) => item.id); //[1,2,3] Role permission ids
    const tempLists = [...correctWayOfAddingList]; //[{},{} all kinds of permissions in the app
    for (let i = 0; i < tempLists.length; i++) {
      if (rolePermissions.includes(tempLists[i].id)) {
        tempLists[i]["isSelected"] = true;
      }
    }
    setCorrectWayOfAddingList(tempLists);
    setShowEditPermissionModal(true);
  };
  const addPermissionsToRole = (id) => {
    const tempLists = [...correctWayOfAddingList];
    const tempPermissionArray = [...details.permissionArray];
    const currentItem = tempLists.find((item) => item.id === id);
    const index = tempLists.indexOf(currentItem);
    const changeSelectedStatus = tempLists[index];
    changeSelectedStatus.isSelected = !changeSelectedStatus.isSelected;
    if (changeSelectedStatus.isSelected) {
      tempPermissionArray.push(changeSelectedStatus);
    } else {
      const currentTemPermissionArray = tempPermissionArray.find(
        (item) => item.id === id
      );
      const currentTemPermissionArrayIndex = tempPermissionArray.indexOf(
        currentTemPermissionArray
      );
      tempPermissionArray.splice(currentTemPermissionArrayIndex, 1);
    }
    setCorrectWayOfAddingList(() => tempLists);
    setDetails({
      ...details,
      permissionArray: tempPermissionArray,
    });
  };

  const handleEditChange = (name) => (e) => {
    const { value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleEditSubmit = () => {
    const permissionArray = [...details.permissionArray];
    const rolePermissions = permissionArray.map((item) => item.id);
    setDetails((prev) => ({
      ...prev,
      rolePermissions,
    }));
    dispatch(openResquestDecisionModal());
  };


  useEffect(() => {
    let initialPermission = JSON.parse(localStorage.getItem("permission"));
    if (initialPermission === null) {
      let array = [];
      let userPermits = JSON.stringify(array);
      localStorage.setItem("permission", userPermits);
      let initialPermit = JSON.parse(localStorage.getItem("permission"));
      setPermissionArray(initialPermit);
    } else {
      setPermissionArray(initialPermission);
    }
  }, []);


  const handlePermitGetAll = () => {
    setShowPermissionModal(true);
    getAllPermissions();
  };

  useEffect(() => {
    getAllPermissions();
  }, []);
  const add = permission;
  useEffect(() => {}, [add, permissionArray, selectedRole]);

  useEffect(() => {
    setRoleValues((prev) => ({ ...prev, rolePermissions: permissionArray }));
    let array = [];
    for (let i = 0; i < permissionArray.length; i++) {
      let perm = permission.filter((items) => items.id === permissionArray[i]);
      array.push(...perm);
    }
    setPermissionArrayUX(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionArray]);

  const selectPermission = (e) => {
    let selected = permissionArray;
    selected.push(e);
    setPermissionArray(selected);
    let letGetPermission = JSON.parse(localStorage.getItem("permission"));
    if (letGetPermission) {
      let array = letGetPermission;
      let permits = array;
      if (array.includes(e) === false) {
        permits.push(e);
        setPermissionArray(permits);
        let userPermission = JSON.stringify(permits);
        localStorage.setItem("permission", userPermission);
      } else {
        let arr = permits;
        arr = arr.filter((exist) => exist !== e);
        setPermissionArray(arr);
        let userPermission = JSON.stringify(arr);
        localStorage.setItem("permission", userPermission);
      }
    } else {
      let permissionArray = [];
      permissionArray.push(e);
      let userPermits = JSON.stringify(permissionArray);
      localStorage.setItem("permission", userPermits);
    }
  };

  // eslint-disable-next-line
  const selectRole = (i) => {
    let singleROle = allRoles.filter((roleSingle) => roleSingle.id === i);
    setSelectedRole(singleROle);
    setOpenModal(true);
    getAllRolesById(i);
  };

  const handleConfirm = () => {
    setErrors(validateManageRoles(roleValues, permissionArrayUX));
    if (Object.keys(validateManageRoles(roleValues)).length > 0) return;
    setOpenBox(true);
  };

  //eslint-disable-next-line

  const headCells2 = [
    { id: "id", label: "S/N" },
    { id: "parameter", label: "Permissions" },
    { id: "description", label: "Description" },
    { id: "selector", label: "Selector" },
  ];

  const [filterFn2, setFilterFn2] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleSearch2 = (e) => {
    let target = e.target;
    setPage2(0);
    setFilterFn2({
      fn: (items) => {
        return items.filter((x) =>
          x.parameter.toLowerCase().includes(target.value.toLowerCase())
        );
      },
    });
  };

  const {
    TblContainer: TblContainer2,
    TblHead: TblHead2,
    TblPagination: TblPagination2,
    recordsAfterPagingAndSorting: recordsAfterPagingAndSorting2,
    setPage: setPage2,
  } = manageRolesTable(add, headCells2, filterFn2);

  return (
    <div className="manage--roles">
      <Spinner loading={loading} />
      <Confirm
        ApiCall={(e) => handleCreateRoleWithPermission(e, setPermissionArray, fetchAllRoles)}
        openBox={openBox}
        setOpenBoxState={setOpenBox}
        errorMessage={errorMsg}
      />
      <div>
        <div className="headerdiv">
          <p>Manage Roles</p>
        </div>

        <Paper style={{ padding: 25, marginBottom: 30 }}>
          {roleExist && (
            <div className="success-container">
              <span className="success-msg error-msg--role">
                Role name already exists !
              </span>
            </div>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <label>Role Name</label>
              <input
                type="text"
                name="name"
                onChange={handleChange}
                value={roleValues.name}
                className={`${
                  Object.keys(errors).includes("name") && "error-input-z"
                }`}
              />
              {errors.name && <ErrorMessage message={errors.name} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label>Role Description</label>
              <input
                type="text"
                name="description"
                onChange={handleChange}
                value={roleValues.description}
                className={`${
                  Object.keys(errors).includes("description") && "error-input-z"
                }`}
              />
              {errors.description && (
                <ErrorMessage message={errors.description} />
              )}
            </Grid>
          </Grid>
          <section>
            <label>Role Permissions</label>
            <div className="flex--4">
              {permissionArrayUX.length === 0 && (
                <p
                  className={`role--permission ${
                    Object.keys(errors).includes("description") &&
                    "error-input-z"
                  }`}
                >
                  No Permission Selected
                </p>
              )}
              {permissionArrayUX.map((permission, index) => (
                <p key={index} className="role--permission">
                  {permission.parameter}
                </p>
              ))}
            </div>
          </section>
          <div className="select--permission">
            <button onClick={() => handlePermitGetAll()}>
              Select Permission
            </button>
          </div>

          {showPermissionModal && (
            <div className="show-permission--modal flex--2">
              <div className="permission--container ">
                <div className="input--list flex--2">
                  <div className="heading-description flex--2">
                    <p
                      className="close--permission"
                      onClick={() => setShowPermissionModal(false)}
                    >
                      &times;
                    </p>
                    <p>
                      List of Permissions - - Kindly select permissions for the
                      role{" "}
                    </p>
                  </div>
                  <div className="main-filter flex--2">
                    <input
                      className="filter"
                      placeholder="Filter by Permissions"
                      onChange={handleSearch2}
                    />
                  </div>
                </div>
                <div className="table-responsive table--1">
                  <TblContainer2>
                    <TblHead2 />
                    <tbody>
                      {recordsAfterPagingAndSorting2().map(
                        (permission, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{permission.parameter}</td>
                            <td>{permission.description}</td>
                            {!permissionArray.includes(permission.id) && (
                              <td
                                onClick={() =>
                                  selectPermission(
                                    permission.id,
                                    permission.parameter
                                  )
                                }
                              >
                                <button className="button">Select</button>
                              </td>
                            )}
                            {permissionArray.includes(permission.id) && (
                              <td
                                onClick={() => selectPermission(permission.id)}
                              >
                                <button className="button button--2">
                                  Selected
                                </button>
                              </td>
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </TblContainer2>
                  <TblPagination2 />
                </div>
              </div>
            </div>
          )}
          <button className="save" onClick={handleConfirm}>
            Save
          </button>
        </Paper>
      </div>

      {!loading && (
        <Table
          title="Manage Roles"
          subtitle=""
          name="List of Roles"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      {details && details.id && <Details details={details} />}
      {details && details.id && (
        <EditForm
          details={details}
          errorEdit={errorEdit}
          handleEditSubmit={handleEditSubmit}
          handleEditChange={handleEditChange}
          errors={errors}
           onClick={openEditPermissionModal}
           correctWayOfAddingList={correctWayOfAddingList}
           showEditPermissionModal={showEditPermissionModal}
           setShowEditPermissionModal={setShowEditPermissionModal}
           addPermissionsToRole={addPermissionsToRole}
        />
      )}
    </div>
  );
};
export default ManageRoles;

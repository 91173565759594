import { Grid } from "@material-ui/core";
import Moment from 'react-moment';
import { numberFormatter } from "../../../formatter";
import Item from "../../DatagridTable/item";

const InvestmentBooking = ({details}) => {
    return (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Investment Account Number" name={details.investmentAccountNumber} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Investment Amount" name={numberFormatter(details.amount)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Expected Interest Amount" name={details.expectedInterestAmount} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
         <Item label="Book Date" name={details.bookDate} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Maturity Date" name={details.maturityDate} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Credit Interest To" name={details.creditInterestTo} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Customer Account No" name={details.customerAccountNumber} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Account Name" name={details.customerAccountName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Tenor (In Days)" name={details.tenor} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Investment Maturity Date" name={<Moment format="D MMM YYYY">{details.maturityDate}</Moment>} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Rate Chart Code" name={details.rateChatCode} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="System Rate" name={details.systemRate} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Variance" name={details.variance} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Final Rate" name={details.finalRate} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Rate Basis" name={details.rateBasis} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Maturity Amount" name={numberFormatter(details.maturityAmount)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Create Date Time" name={details.createdDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Updated Date Time" name={details.updatedDateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Date Authorized" name={details.dateAuthorized}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item label="Status" name={details.authorizationStatus === 'PendingEditedAuthorization' ? 'Pending Edited Authorization' : details.authorizationStatus} />
        </Grid>
      </Grid>

    )
}

export default InvestmentBooking

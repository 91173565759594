import { useState, useEffect, useCallback } from "react";
import {
  getCustomerByAccountNumber,
  getGLAccount,
  debit,
} from "../../../../Api/Hooks-manager/Transactions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllBranches } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import {getAUserTillDetails} from "../../../../Api/Hooks-manager/securitymanagement/tellerTills";
import  {handleAutoDebitForBulk} from "../../../../Api/Hooks-manager/Transactions"

import { DateConverter } from "../../../ConvertDate";
import { TransactionType, ModuleType } from "../../../../Helper/enum";
import {
  getCountries,
  getAllStatesByCountryCode,
} from "../../../../Api/places/getPlaces";
import useTable from "../../../Tables/useTable";

export const BussinessLogic = () => {
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const userId = useSelector((state) => state.common.userDetails.userName);
  const [loading, setLoading] = useState(false);
  const [screenChange, setScreenChange] = useState(false);
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorSummary, setErrorSummary] = useState(null);

  const [message, setMessage] = useState("");
  const [allBranches, setAllBranches] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
//   console.log(user, "here are the user details")
  const [debitAccDetails, setDebitAccDetails] = useState({
    accountName: "",
    entryBranch: "",
    currencyCode: "",
    branchName: "",
    availableBalance: null,
    isValidAccNum: false,
    userCrCashLimit:""

  });
  const [creditAccDetails, setCreditAccDetails] = useState({
    accountName: "",
    accountBranch: "",
    currencyCode: "",
    isValidAccNum: false,
  });


  const [errors, setErrors] = useState({});

  const [glTransaction, setGlTransaction] = useState({
    debitAccount: "", // account you are reoving money from
    creditAccount: "", // account you are sending money to
    postAmount: 0, //amount you want to transact
    transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
    transactionType: TransactionType.MTTR, //enum
    entryBranch: user.operatingBranch, //get it from the user object operatingBranch
    debitAccountBranch: "", // the account in the debitor's acccount number
    creditAccountBranch: "", // the account in the creditor's acccount number
    tillId: "", // the teller ID
    narration: "", //auto generate not bmore than 50 character
    currencyCode: "NGN", //from the fectched account
    debitModule: ModuleType.TellerTill, //enum from moduleType
    creditModule: ModuleType.GlAccount, //enum from moduleType
    inputterIpAddress: "", //empty
    bankId: user.institution, //get it from the user object  institution code : operatingBranch
    chequeNumber: "", //empty
    controlNumber: "", //empty for now
    clearingType: "", //empty for now
    issueDate: "", //datetime
    bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
    routingNumber: "", //empty for now
    terminalId: "", //empty for now
    msgType: "", //empty for now
    msgStan: "", //empty for now
    depositorName: "", //for non cash deposit it should be empty
    depositorMobile: "", //for non cash deposit it should be empty
    depositorAddress: "", //for non cash deposit it should be empty
    denominations: [1000, 500, 200,100, 50, 20, 10, 5, 2, 1].map((item) => {
      return {
        denomNo: item,
        denomCount: 0,
        denomTotal: 0,
      }
    }), //send empty array for non cash transaction
    amlDataStatus: true,
    amlAccountNumber: "", // here down empty string
    amlCustomerNumber: "",
    amlIdNumber: "",
    amlBvnNumber: "",
    amlCustomerType: "",
    amlCustomer: "",
    amlWaiveId: true,
    amlTitle: "",
    amlFirstName: "",
    amlMiddleName: "",
    amlLastName: "",
    amlPhoneNumber: "",
    amlDob: "",
    amlPob: "",
    amlNationality: "",
    amlAddress: "",
    amlFundSource: "",
    amlOccupation: "",
    amlNumber: "",
    amlType: "",
    amlIssuer: "",
    amlCountry: "",
    amlState: "",
    amlExpiration: "",
    amlIssueDate: "",
  });

  const [denomTotalValue, setDenomTotalValue] = useState(0);

  const fetchAllBranches = async () => {
    const response = await getAllBranches(user.jwtToken);
    if (response && response.data) {
      setAllBranches(
        response.data.filter(
          (item) => item.authorizationStatus === "Authorized"
        )
      );
    }
  };

  const { debitAccount, creditAccount } = glTransaction;

  // const [denominationValue, setDenominationValue] = useState([glTransaction.denominations]);

// const calulateDenomination = () => {
//   const denomList = [...glTransaction.denominations];
//   for (let item of denomList){
//     item.denomTotal = item.denomTotal > 0 ? item.denomTotal : item.denomNo * item.denomCount
//   }
//   console.log(denomList, "here is the maths")
//   setGlTransaction(prev => ({
//     ...prev,
//     denominations: denomList
//   }))
// }

// useEffect(() => {
//   calulateDenomination();
// },[])

  // useEffect(() => {
  //   const denomList = [...glTransaction.denominations];
  //   const newTotal = (denomList.denomNo) * (denomList.denomCount);
  //   setGlTransaction((glTransaction) => ({
  //     ...glTransaction,
  //     denomTotal: newTotal,
  //   }));
  //   console.log(newTotal, "adding amount")
  // }, [glTransaction.denominations])


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "debitAccount" && value.length > 9) ||
      (name === "creditAccount" && value.length > 9)
    )
      return;
    const inputedVal =
      value === "" ? "" : parseFloat(value) < 0 ? 0 : parseFloat(value);
    setGlTransaction({
      ...glTransaction,
      [name]: name === "postAmount" ? inputedVal : value,
    });

    

  };
  console.log("fu", glTransaction);
  
  const handleDenominationChange = (e, index) => {
    const {name, value} = e.target;
    const denomList = [...glTransaction.denominations];
    if(name === "denomValue") return;
    const denomObject = denomList[index]
    denomObject["denomCount"] = value;
    denomObject["denomTotal"] = (denomObject['denomNo']) * (denomObject['denomCount']) 
    denomList[index] = denomObject
    const totalValue = denomList.reduce((prev, current) => {
      return prev + current.denomTotal
    },0)
    setDenomTotalValue(totalValue);
    
    console.log(totalValue)

    // setGlTransaction({
    //   ...glTransaction,
    //   denominations : denomList
    // });
    // console.log(glTransaction, "gogogo")
  }

  const fetchCreditAccountDetails = useCallback(async (accNum) => {
    //gl account details
    setLoading(true);
    const response = await getGLAccount(user.jwtToken, accNum);
    console.log("-----credit accounts details---", response);
    if (response && response.data) {
      setLoading(false);
      if (response.status) {
        if (response.data.authorizationStatus === 'Authorized' && response.data.glHierarchy === 'Child'){
        setGlTransaction({...glTransaction, creditAccount: response.data.accountNumber, currencyCode: response.data.currency, creditAccountBranch: response.data.branchCode});
        setCreditAccDetails({
          ...creditAccDetails,
          accountName: response.data.accountName,
          availableBalance: response.data.balance,
          accountBranch: response.data.branchCode,
          currencyCode: response.data.currency,
          isValidAccNum: true,
        });
        setErrors(e => ({ ...e, creditAccount: "" }));
      }else{
        setErrors({ ...errors, creditAccount: 'Unauthorized Account Number'  });
      }
      } else {
        clearCreditAccountDetails();
        setErrors(e => ({ ...errors, creditAccount: response.message }));
      }
    }
    // eslint-disable-next-line
});

  const fetchDebitAccountDetails = async () => {
    //gl account details
    setLoading(true);
    const allResponse = await getAUserTillDetails(token, userId);
    console.log("-----debit accounts details---", allResponse);
    if(allResponse && allResponse.data) {
      setLoading(false);
    }
    const response = allResponse.data.find((item => item.accountGL));
    const response2 = allResponse.data.find((item) => item.tellerTillLinkage);

    if(response){
      setGlTransaction({
        ...glTransaction,
        debitAccount: response.accountGL.accountNumber, 
        entryBranch:response.accountGL.branchCode, 
        currencyCode: response.accountGL.currency,
        debitAccountBranch: response.accountGL.branchCode,
        tillId: response.tillId,
      });
      setDebitAccDetails({
        ...debitAccDetails,
        accountName: response.accountGL.accountName,
        branchName: response.accountGL.branchName,
        entryBranch: response.accountGL.branchCode,
        currencyCode: response.accountGL.currency,
        isValidAccNum: true,
        userCrCashLimit:response2.tellerTillLinkage.userCrCashLimit

      });

      setErrors({ ...errors, debitAccount: "" });
    } else {
      clearDebitAccountDetails();

    setErrors({ ...errors, debitAccount: "" });
    }
    // if (response && response.data) {
    //   setLoading(false);
    //   if (response.status) {
    //     if (
    //       response.data.authorizationStatus === "Authorized"
    //     ) {
    //       setGlTransaction({...glTransaction,entryBranch:response.data.branchCode, currencyCode: response.data.currency});
    //       setDebitAccDetails({
    //         ...debitAccDetails,
    //         accountName: response.data.accountName,
    //         //   entryBranch: response.data.branchCode,
    //         //   currencyCode: response.data.currency,
    //         isValidAccNum: true,
    //       });
    //       setCreditAccDetails([])
    //       setErrors({ ...errors, creditAccount: "" });
    //     } else {
    //       setErrors({
    //         ...errors,
    //         creditAccount: "Unauthorized Account Number",
    //       });
    //     }
    //   } else {
    //     clearCreditAccountDetails();
    //     setErrors({ ...errors, creditAccount: response.message });
    //   }
    // }
  };

  const EmptyFieldMakeTransfer = () => {
    setGlTransaction({
      debitAccount: "", // account you are reoving money from
      creditAccount: "", // account you are sending money to
      postAmount: 0, //amount you want to transact
      transactionDate: DateConverter(new Date()), //date of transaction that can be backdated or forwrded
      transactionType: TransactionType.MTTR, //enum
      entryBranch: user.operatingBranch, //get it from the user object operatingBranch
      debitAccountBranch: "", // the account in the debitor's acccount number
      creditAccountBranch: "", // the account in the creditor's acccount number
      tillId: "", // the teller ID
      narration: "GL to GL transfer", //auto generate not bmore than 50 character
      currencyCode: "NGN", //from the fectched account
      debitModule: ModuleType.TellerTill, //enum from moduleType
      creditModule: ModuleType.GlAccount, //enum from moduleType
      inputterIpAddress: "", //empty
      bankId: user.institution, //get it from the user object  institution code : operatingBranch
      chequeNumber: "", //empty
      controlNumber: "", //empty for now
      clearingType: "", //empty for now
      issueDate: "", //datetime
      bulkType: "", //empty for now except for single debit multiple credit (if !empty the enums are: )
      routingNumber: "", //empty for now
      terminalId: "", //empty for now
      msgType: "", //empty for now
      msgStan: "", //empty for now
      depositorName: "", //for non cash deposit it should be empty
      depositorMobile: "", //for non cash deposit it should be empty
      depositorAddress: "", //for non cash deposit it should be empty
      denominations: [1000, 500, 200, 100, 50, 20, 10, 5, 2, 1].map((item) => {
        return {
          denomNo: item,
          denomCount: 0,
          denomTotal: 0,
        };
      }), //send empty array for non cash transaction
      amlDataStatus: true,
      amlAccountNumber: "", // here down empty string
      amlCustomerNumber: "",
      amlIdNumber: "",
      amlBvnNumber: "",
      amlCustomerType: "",
      amlCustomer: "",
      amlWaiveId: true,
      amlTitle: "",
      amlFirstName: "",
      amlMiddleName: "",
      amlLastName: "",
      amlPhoneNumber: "",
      amlDob: "",
      amlPob: "",
      amlNationality: "",
      amlAddress: "",
      amlFundSource: "",
      amlOccupation: "",
      amlNumber: "",
      amlType: "",
      amlIssuer: "",
      amlCountry: "",
      amlState: "",
      amlExpiration: "",
      amlIssueDate: "",
    });
  };

  const makeAutoTransfer = async () => {
    setLoading(true);
    const response = await handleAutoDebitForBulk(user.jwtToken, glTransaction);
    console.log("debited....", response);
    if (response && response.data) {
      setLoading(false);
      setMessage(response.message);
      if (response.status) {
        clearDebitAccountDetails();
        clearCreditAccountDetails();
        EmptyFieldMakeTransfer();
        setScreenChange(false);
        return true;
      } else {
        return false;
      }
    }
  };
 
  const makeTransfer = async () => {
    setLoading(true);
    const response = await debit(user.jwtToken, glTransaction);
    console.log("debited....", response);
    if (response && response.data) {
      setLoading(false);
      setMessage(response.message);
      if (response.status) {
        clearDebitAccountDetails();
        clearCreditAccountDetails();
        EmptyFieldMakeTransfer();
        setScreenChange(false);
        return true;
      } else {
        return false;
      }
    }
  };

  const clearCreditAccountDetails = () => {
    setCreditAccDetails({
      accountName: "",
      entryBranch: "",
      currencyCode: "",
      accountCurrency: "",
      bvn: "",
      availableBalance: null,
      isValidAccNum: false,
    });
    setGlTransaction({ ...glTransaction, currencyCode: "", debitAccountBranch:"" });
  };

  const clearDebitAccountDetails = () => {
    setDebitAccDetails({
      accountName: "",
      accountBranch: "",
      currencyCode: "",
      isValidAccNum: false,
    });
  };



  useEffect(() => {
    if (creditAccount && creditAccount.length < 9) {
      setErrors({ ...errors, creditAccount: "" });
      clearCreditAccountDetails();
    }
  }, [creditAccount]);

  // useEffect(() => {
  //   if (debitAccount && debitAccount.length === 9) {
  //     fetchCustomerDetails(debitAccount);
  //   }
  // }, [debitAccount]);

  useEffect(() => {
    if (debitAccount && debitAccount.length < 9) {
      setErrors({ ...errors, debitAccount: "" });
      clearDebitAccountDetails();
    }
  }, [debitAccount]);


  useEffect(() => {
    if(creditAccount && creditAccount.length === 9){

      fetchCreditAccountDetails(creditAccount);
    }
  },[creditAccount])

  useEffect(() => {
      fetchDebitAccountDetails();
    }, []);



  useEffect(() => {
    fetchAllBranches();
  }, []);

  const validateInput = () => {
    const { debitAccount, creditAccount, postAmount, narration } = glTransaction;
    const errors = {};

    if (debitAccount === "") {
      errors.debitAccount = "Account No. is required";
    }
    if (narration === "") {
        errors.narration = "Field is required";
      }

    if (debitAccount && debitAccount.length < 9) {
      errors.debitAccount = "9 characters are required";
    }
    if (
      debitAccount &&
      debitAccount.length !== 9 &&
      !debitAccount.isValidAccNum
    ) {
      errors.debitAccount = "Invalid Account No.";
    }

    if (postAmount === "") {
      errors.postAmount = "Field is required.";
    }
    // if (
    //   postAmount &&
    //   debitAccDetails.isValidAccNum &&
    //   postAmount > debitAccDetails.availableBalance
    // ) {
    //   errors.postAmount = "Insufficent funds";
    // }

    // if (debitAccDetails.availableBalance != undefined && postAmount > debitAccDetails.availableBalance){
    //   errors.postAmount = "Insufficient balance in debit account";
    // }

    return errors;
  };


  const handleScreenChange = (i) => {
    let checkErrors = validateInput();
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length > 0) {
      return;
    }
    setErrors({});
    setScreenChange(i);
    console.log("qwswqeq", i);
  };

  const handleSubmit = () => {
    const {postAmount, denominations} = glTransaction;
    const checkErrors = validateInput();
    setErrors(checkErrors);
    if( denominations && denomTotalValue !== postAmount){
      setErrorSummary({
        notBalance: `Sum of denominations: ${denomTotalValue} must be equal to post amount: ${postAmount}`
      })
      setOpen(false);
      setOpenErrorModal(true);
    } else {
      if (Object.keys(checkErrors).length > 0) return;
      setOpen(true);
      setOpenErrorModal(false)
    }
    };

  return {
    glTransaction,
    loading,
    debitAccDetails,
    creditAccDetails,
    denomTotalValue,
    screenChange,
    errorSummary,
    handleScreenChange,
    // denominationValue,
    // setDenominationValue,
    handleDenominationChange,
    errors,
    setErrors,
    validateInput,
    handleChange,
    makeTransfer,
    makeAutoTransfer,
    open,
    user,
    setOpen,
    openErrorModal,
    setOpenErrorModal,
    message,
    allBranches,
    handleSubmit,
    setGlTransaction,
  };
};

export default BussinessLogic;
import { useState, useEffect, useCallback } from "react";
import "./BankCalendar.scss";
import MenuItem from "@material-ui/core/MenuItem";
import useCustom from "../../../../Api/Hooks-manager/coreModules/useCustom";
import Confirm from "../../../../Confirmbox/Confirm";
import Spinner from "../../../Backdrop/index";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DayPicker, { DateUtils } from "react-day-picker";
import ErrorMessage from "../../../ErrorMessage";
import "react-day-picker/lib/style.css";
import { format } from "date-fns";
import DotMenu from "../../../../commons/dotMenu/dotMenu";

import { baseUrl } from "../../../../Api/configUrl";
import { formattedDate } from "../../../../formatter/date";
import { getHandler } from "../../../../Api/Hooks-manager/coreModules/coreModules";

import Table from "../../../DatagridTable/table";
import {
  openUpdateFormModal,
  openResquestDecisionModal,
  openViewModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";
import EditForm from "./edit";
import Details from "./view";

const BankCalendar = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const loading = useSelector(state => state.req.loading);
  const updateTable = useSelector(state => state.req.updateTable);
  const token = useSelector(state => state.common.userDetails.jwtToken);
  const workDate = useSelector((state) => state.common.branch.workdate);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branchCode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
      },
    },

    
    {
      name: "month",
      label: "Month",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "holiday",
      label: "Holiday",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startTime",
      label: "Start Time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "closeTime",
      label: "Close Time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "authorizationStatus",
      label: "Status",
      options: {
        customBodyRender: status => (
          <p
            style={{
              color:
                status === "Authorized"
                  ? "green"
                  : status === "Rejected"
                  ? "red"
                  : "rgb(224, 148, 33)",
            }}
          >
            {status}{" "}
          </p>
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <DotMenu>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openUpdateFormModal());
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                const singleItem = data[dataIndex];
                setDetails(singleItem);
                dispatch(openViewModal());
              }}
            >
              View
            </MenuItem>
          </DotMenu>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const getMonth = i => {
    const index = parseInt(i) - 1;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[index];
  };

  const fetchAllBankCalendar = useCallback(async () => {
    dispatch(isLoading());
    const url = `${baseUrl}organization/BankHoliday/GetAll`;
    const response = await getHandler(token, url);
    if (response && response.data) {
      dispatch(isLoading());
      if (response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].sn = i + 1;
          response.data[i].month = getMonth(response.data[i].month);
          response.data[i].createdDateTime = formattedDate(
            response.data[i].createdDateTime
          );
          response.data[i].dateAuthorized = formattedDate(
            response.data[i].dateAuthorized
          );
          response.data[i].updatedDateTime = formattedDate(
            response.data[i].updatedDateTime
          );
        }
      }
      setData(response.data);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchAllBankCalendar();
  }, [fetchAllBankCalendar, updateTable]);

  const {
    months,
    handleBankCalendarCreate,
    valuesBankHolidays,
    setValuesBankHolidays,
    handleChange,
    getAllBankCalendar,
    slicedmontstate,
    setSlicedmontstate,
  } = useCustom();

  // const [value, onChange] = useState(new Date());
  const [openBox, setOpenBox] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [error, setError] = useState({});
  const [monthUx, setMonthUx] = useState("");

  useEffect(() => {
    getAllBankCalendar();
  }, [getAllBankCalendar]);

  const handleConfirmUpdate = () => {
    dispatch(openResquestDecisionModal());
  };

  const handleMonthUx = e => {
    setMonthUx(e.target.value);
    let getIndex = months.indexOf(e.target.value);
    setDetails(prev => ({
      ...prev,
      month: getIndex,
    }));
  };
  const StartYear = new Date(workDate).getFullYear();
  const StartMonth = new Date(workDate).getMonth();
  const StartDAy = new Date(workDate).getDate();

  const handleDayClick = (day, { selected }) => {
    const selectedDay = selectedDays.concat();
    if (selected) {
      const selectedIndex = selectedDay.findIndex(selected =>
        DateUtils.isSameDay(selected, day)
      );
      selectedDay.splice(selectedIndex, 1);
    } else {
      selectedDay.push(day);
    }
    setSelectedDays(selectedDay);
  };

  useEffect(() => {
    if (selectedDays) {
      let array = [];
      for (let i = 0; i < selectedDays.length; i++) {
        console.log("got here");
        let formated = selectedDays[i].toISOString();
        let single = format(new Date(formated), "dd/MM/yyyy");
        array.push(single);
      }
      let bbb = [];
      for (let i = 0; i < array.length; i++) {
        const monthFormated = array[i].slice(3, 5);
        const dayFormated = array[i].slice(0, 2);
        let a = bbb.find(items => items.month === monthFormated);
        if (a) {
          let index = bbb.indexOf(a);
          bbb[index]["days"] = `${bbb[index]["days"]},${dayFormated}`;
        } else {
          bbb.push({ month: monthFormated, days: dayFormated });
        }
      }
      setValuesBankHolidays(prev => ({
        ...prev,
        holidays: bbb,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  const handleConfirm = () => {
    let checkError = isValidation(valuesBankHolidays);
    console.log("checking erros", checkError);
    setError(checkError);
    if (Object.keys(checkError).length > 0) return;

    setError({});
    setOpenBox(true);
  };

  const isValidation = values => {
    let error = {};

    if (!values.startTime.trim()) {
      error.startTime = "Field is required";
    }
    if (!values.closeTime.trim()) {
      error.closeTime = "Field is required";
    }
    // if (!values.bankId.trim()) {
    //   error.bankId = "Field is required";
    // }
    if (!values.exemptFlg.trim()) {
      error.exemptFlg = "Field is required";
    }

    if (values.holidays.length === 0) {
      error.holidays = "Select Holidays";
    }
    return error;
  };

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const now = new Date(workDate);
    const month = now.getMonth();
    const slicedMonth = months.slice(month);
    console.log(slicedMonth, "slicedMonthslicedMonth");
    setSlicedmontstate(slicedMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="bank--calendar border--main">
        <div className="heading">
          <p>Bank Calendar</p>
        </div>
        <div style={{ position: "absolute" }}>
          <Spinner loading={loading} />
        </div>
        <Confirm
          ApiCall={handleBankCalendarCreate}
          openBox={openBox}
          setOpenBoxState={setOpenBox}
        />
        <div className="calendar flex--1">
          <DayPicker
            height="40000"
            selectedDays={selectedDays}
            onDayClick={handleDayClick}
            month={new Date(StartYear, StartMonth)}
            fromMonth={new Date(StartYear, StartMonth)}
            disabledDays={[
              {
                before: new Date(StartYear, StartMonth, StartDAy),
              },
            ]}
            modifiers={{today: new Date()}}
          />
        </div>
        <div className="flex--4 inputs-container">
          {/* <div className="flex--3">
            <label>Bank ID</label>
            <input
              type="text"
              onChange={e => handleChange(e, "bankCalendar")}
              name="bankId"
              value={valuesBankHolidays.bankId}
              className={`${error.bankId && "error-input-z"}`}
            />{" "}
            {error.bankId && <ErrorMessage message={error.bankId} />}{" "}
          </div> */}
          <div className="flex--3">
            <label>Year</label>
            <input
              type="text"
              onChange={e => handleChange(e, "bankCalendar")}
              name="year"
              // readOnly
              disabled
              value={valuesBankHolidays.year}
            />
          </div>
          <div className="flex--3">
            <label>Start Time</label>
            <input
              type="time"
              onChange={e => handleChange(e, "bankCalendar")}
              name="startTime"
              value={valuesBankHolidays.startTime}
              className={`${error.startTime && "error-input-z"}`}
            />{" "}
            {error.startTime && <ErrorMessage message={error.startTime} />}{" "}
          </div>
          <div className="flex--3">
            <label>Close Time</label>
            <input
              type="time"
              onChange={e => handleChange(e, "bankCalendar")}
              name="closeTime"
              value={valuesBankHolidays.closeTime}
              className={`${error.closeTime && "error-input-z"}`}
            />{" "}
            {error.closeTime && <ErrorMessage message={error.closeTime} />}{" "}
          </div>
          <div className="flex--3">
            <label>Exemption Flag</label>
            <input
              type="text"
              onChange={e => handleChange(e, "bankCalendar")}
              name="exemptFlg"
              disabled
              value={valuesBankHolidays.exemptFlg}
              className={`${error.exemptFlg && "error-input-z"}`}
            />{" "}
            {error.exemptFlg && <ErrorMessage message={error.exemptFlg} />}{" "}
          </div>
        </div>
        <section className="section--holiday">
          <h4>Holidays</h4>
          <div className="flex--4">
            {valuesBankHolidays.holidays.length === 0 && (
              <div>
                <div className={`${error.exemptFlg && "error-input-z"}`}>
                  <p>No Holiday selected</p>
                </div>
                {error.holidays && <ErrorMessage message={error.holidays} />}{" "}
              </div>
            )}
            {valuesBankHolidays.holidays?.map((items, i) => (
              <div
                className={`flex--4 ${
                  i + 1 !== valuesBankHolidays.holidays.length && "holidays"
                }`}
                key={i}
              >
                <div className="flex--3">
                  <label>Month</label>
                  <p>{months[parseInt(items.month) - 1]}</p>
                </div>
                <div className="flex--3">
                  <label>Days</label>
                  <p>{items.days}</p>
                </div>
              </div>
            ))}{" "}
          </div>
        </section>
        <div className="button">
          <button onClick={handleConfirm}>Save</button>
        </div>
      </div>
      {!loading && (
        <Table
          title="Bank holidays"
          subtitle=""
          name="List of holidays"
          records={data}
          options={options}
          columns={columns}
        />
      )}
      <Details details={details} />{" "}
      {details && details.id && (
        <EditForm
          handleEditSubmit={handleConfirmUpdate}
          details={details}
          handleMonthUx={handleMonthUx}
          monthUx={monthUx}
          slicedmontstate={slicedmontstate}
          handleChange={handleChange}
        />
      )}{" "}
    </div>
  );
};

export default BankCalendar;

import * as actionTypes from "../constants";
import { updatedObject } from "../utilty";

const initialState = {
  permissionsError: null,
  branchError: null,
  countriesError: null,
  allBranchesError: null,
  permissions: [],
  userDetails: {},
  branch: {},
  countries: [],
  allBranches: [],
  allTellerTills: [],
};

const getPermissionsStart = (state, action) => {
  return updatedObject(state, {
    permissionsError: null,
  });
};

const getPermissionsSuccess = (state, action) => {
  return updatedObject(state, {
    permissions: action.payload,
  });
};

const getPermissionsFromUserObj = (state, action) => {
  return updatedObject(state, {
    permissions: action.payload.map((item) => item.parameter),
  });
};

const getUserDetails = (state, action) => {
  return updatedObject(state, {
    userDetails: action.payload,
  });
};

const getPermissionsFail = (state, action) => {
  return updatedObject(state, {
    permissionsError: action.error,
  });
};

const getBranchStart = (state, action) => {
  return updatedObject(state, {
    branchError: null,
  });
};

const getBranchSuccess = (state, action) => {
  return updatedObject(state, {
    branch: action.payload,
  });
};

const getBranchFail = (state, action) => {
  return updatedObject(state, {
    branchError: action.error,
  });
};

const getCountriesStart = (state, action) => {
  return updatedObject(state, {
    countriesError: null,
  });
};

const getCountriesSuccess = (state, action) => {
  return updatedObject(state, {
    countries: action.payload,
  });
};

const getCountriesFail = (state, action) => {
  return updatedObject(state, {
    countriesError: action.error,
  });
};

const getAllBranchesStart = (state, action) => {
  return updatedObject(state, {
    allBranchesError: null,
  });
};

const getAllBranchesSuccess = (state, action) => {
  return updatedObject(state, {
    allBranches: action.payload,
  });
};

const getAllBranchesFail = (state, action) => {
  return updatedObject(state, {
    allBranchesError: action.error,
  });
};

const getAllTellerTillStart = (state, action) => {
  return updatedObject(state, {
    allTellerTillsError : null,
  });
};

const getAllTellerTillSuccess = (state, action) => {
  return updatedObject(state, {
    allTellerTills : action.payload,
  });
};

const getAllTellerTillFail = (state, action) => {
  return updatedObject(state, {
    allTellerTillsError: action.error,
  });
};

const clearCommon = (state, action) => {
  return updatedObject(state, {
    permissionsError: null,
    branchError: null,
    countriesError: null,
    permissions: [],
    userDetails: {},
    branch: {},
    countries: [],
  });
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PERMISSIONS_START:
      return getPermissionsStart(state, action);
    case actionTypes.GET_ALL_PERMISSIONS_SUCCESS:
      return getPermissionsSuccess(state, action);
    case actionTypes.GET_ALL_PERMISSIONS_FAIL:
      return getPermissionsFail(state, action);
    case actionTypes.GET_ALL_PERMISSIONS_FROM_OBJ:
      return getPermissionsFromUserObj(state, action);
    case actionTypes.GET_USER_DETAILS:
      return getUserDetails(state, action);
    case actionTypes.GET_BRANCH_START:
      return getBranchStart(state, action);
    case actionTypes.GET_BRANCH_SUCCESS:
      return getBranchSuccess(state, action);
    case actionTypes.GET_BRANCH_FAILED:
      return getBranchFail(state, action);
    case actionTypes.GET_ALL_COUNTRIES_START:
      return getCountriesStart(state, action);
    case actionTypes.GET_ALL_COUNTRIES_SUCCESS:
      return getCountriesSuccess(state, action);
    case actionTypes.GET_ALL_COUNTRIES_FAIL:
      return getCountriesFail(state, action);
    case actionTypes.GET_ALL_BRANCHES_START:
      return getAllBranchesStart(state, action);
    case actionTypes.GET_ALL_BRANCHES_SUCCESS:
      return getAllBranchesSuccess(state, action);
    case actionTypes.GET_ALL_BRANCHES_FAIL:
      return getAllBranchesFail(state, action);
    case actionTypes.GET_ALL_TELLER_TILLS_START:
      return getAllTellerTillStart(state, action);
    case actionTypes.GET_ALL_TELLER_TILLS_SUCCESS:
      return getAllTellerTillSuccess(state, action);
    case actionTypes.GET_ALL_TELLER_TILLS_FAIL:
      return getAllTellerTillFail(state, action);
    case actionTypes.CLEAR_COMMON:
      return clearCommon(state, action);
    default:
      return state;
  }
};

export default common;

import { Grid } from "@material-ui/core";

import EditModal from "../../../DatagridTable/edit";
import ErrorMessage from "../../../ErrorMessage";
import { Routes } from "../../../../Api/configUrl";

const EditData = ({
  handleEditSubmit,
  handleEditChange,
  details,
  errorEdit,
}) => {
  return (
    <EditModal
      title="Update User Teller Tills"
      onClick={handleEditSubmit}
      question="Are you sure you want to update this User Teller Till record?"
      data={details}
      url={`${Routes.updateUserTellerTill}${details.id}`}
    >
      <Grid container spacing={3} className="userteller--tills">
        <Grid item xs={12} sm={6} md={6} className="label--input flex--3">
          <label>Till Id</label>
          <input
            type="text"
            value={details.tillId}
            onChange={handleEditChange("tillId")}
          />

          {errorEdit.tillId && <ErrorMessage message={errorEdit.tillId} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6} className="label--input flex--3">
          <label>Branch Id</label>
          <input
            type="text"
            value={details.branchId}
            onChange={handleEditChange("branchId")}
          />
          {errorEdit.branchId && <ErrorMessage message={errorEdit.branchId} />}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>User Id</label>
          <input
            type="text"
            value={details.userId}
            onChange={handleEditChange("userId")}
          />
          {errorEdit.userId && <ErrorMessage message={errorEdit.userId} />}
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <label>user Till Limit</label>
          <input
            type="text"
            value={details.userTillLimit}
            onChange={handleEditChange("userTillLimit")}
          />
          {errorEdit.userTillLimit && (
            <ErrorMessage message={errorEdit.userTillLimit} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>User Cr Cash Limit</label>
          <input
            type="text"
            value={details.userCrCashLimit}
            onChange={handleEditChange("userCrCashLimit")}
          />
          {errorEdit.userCrCashLimit && (
            <ErrorMessage message={errorEdit.userCrCashLimit} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <label>User Dr Cash Limit</label>
          <input
            type="text"
            value={details.userDrCashLimit}
            onChange={handleEditChange("userDrCashLimit")}
          />
          {errorEdit.userDrCashLimit && (
            <ErrorMessage message={errorEdit.userDrCashLimit} />
          )}
        </Grid>
      </Grid>
    </EditModal>
  );
};

export default EditData;

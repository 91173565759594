import axios from 'axios';
import { Routes } from '../../configUrl';

const changeAllPassword = async (token, user) => {
    return await axios({
        method: "post",
        url: Routes.resetPassword,
        headers: {
          "Content-Type":  "application/json",
          'Authorization': `Bearer: ${token}`,
        },
        data: user
      })
      .then((res) => { // {data:data,status:true,message:'success'}
        return {
          data: res.data.data,
          status: res.data.status,
          message: res.data.responseMessage
        };
      })
      .catch(err => { // {data:[],status:false,message:'what went wrong'}
        return {
          data: [],
            status: err.response.data.status,
            message: err.response.data.responseMessage
        }
      })
      
  };
  

export {changeAllPassword}
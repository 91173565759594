import React, { useState } from "react";
import "./BulkJournalPosting.scss";
import Paper from "@material-ui/core/Paper";
import useTable from "../../../Tables/useTable";
import { AiTwotoneDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";

const BulkJournalPosting = () => {
  const [allTellerTills, setAllTellerTills] = useState([]);
  const headCells = [
    { id: "id", label: "S/N" },
    { id: "debitaccount", label: "Debit Account" },
    { id: "creditaccount", label: "Credit Account" },
    { id: "postcurrency", label: "Post Currency" },
    { id: "accountnumber", label: "Account Number" },
    { id: "accountname", label: "Account Name" },
    { id: "amount", label: "Amount" },
    { id: "delete", label: "Delete", disableSorting: true },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "view", label: "View", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage
  } = useTable(allTellerTills, headCells, filterFn);

  return (
    <div className="bulk--posting">
      <div className="headerdiv">
        <p>Bulk Journal Posting</p>
      </div>
      <Paper className="paper">
        <div className="addbtn--container">
          <div className="flex--input1 flex--3">
            <label>Debit Amount</label>
            <input />
          </div>
          <div className="flex--3 flex--input1">
            <label>Credit Amount</label>
            <input />
          </div>
          <div className="flex--3 flex--input1">
            <label>Post Currency</label>
            <input />
          </div>
          <div className="flex--3 flex--input1">
            <label>No. of Entries</label>
            <input />
          </div>
          <div className="flex--btn">
            <label>Add</label>
            <button className="addbtn">+</button>
          </div>
        </div>
        {/* <section className="section1 flex--1">
          <main className="flex--4">
            <div className="flex--3 flex--ind">
              <label>S/No</label>
              <input type="text" value="1" />
            </div>
            <div className="flex--3 select--input">
              <label>Ac_Type</label>
              <select>
                <option value="">Customer</option>
                <option value="">Internal </option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <label>Account No</label>
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <label>Account Name</label>
              <input />
            </div>
            <div className="flex--3 " >
              <label>A/c currency</label>
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <label>Debit/Credit</label>
              <select>
                <option>Debit</option>
                <option>Credit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <label>Amount</label>
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <label>Narration</label>
              <input />
            </div>
          </main>
          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="2"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option value="">Internal </option>
                <option value="">Customer</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option>Credit</option>
                <option>Debit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>
          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="3"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option value="">Customer </option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
              <option>Debit</option>
                <option>Credit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>

          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="4"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option value="">Internal </option>
              <option value="">Customer</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option>Credit</option>
                <option>Debit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>

          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="5"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                 <option value="">Customer</option>
                <option value="">Internal </option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
              <option>Debit</option>
                <option>Credit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>
          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="6"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option value="">Internal </option>
                <option value="">Customer</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option>Credit</option>
                <option>Debit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>

          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="7"/>
            </div>
            <div className="flex--3 select--input">
              <select>
              <option value="">Customer</option>
                <option value="">Internal </option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
              <option>Debit</option>
                <option>Credit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>

          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="8"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option value="">Internal </option>
                <option value="">Customer</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option>Credit</option>
                <option>Debit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>

          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="9"/>
            </div>
            <div className="flex--3 select--input">
              <select>
              <option value="">Customer</option>
                <option value="">Internal </option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
              <option>Debit</option>
                <option>Credit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>

          <div className="flex--4">
            <div className="flex--3 flex--ind">
              <input type="text" value="10"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option value="">Internal </option>
                <option value="">Customer</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
            <div className="flex--3">
              <input type="text"/>
            </div>
            <div className="flex--3 select--input">
              <select>
                <option>Credit</option>
                <option>Debit</option>
              </select>
            </div>
            <div className="flex--3 select--input">
              <input className="ind" />
            </div>
            <div className="flex--3 flex--ind2">
              <input />
            </div>
          </div>
        <div className="down-div-flex" >
          <div className="flex--input1 flex--3">
            <label> Sum of Debit</label>
            <input />
          </div>
          <div className="flex--3 flex--input1 down-div-flex-1">
            <label>Sum of Credit</label>
            <input />
          </div>
          </div>
        </section> */}
        <button className="btn"> Save</button>
        <hr />
        <h4>Journal Postings</h4>
        <div className="sub--section flex--2">
          <div className="main-filter flex--2">
            <input className="filter" placeholder="Filter" />
            
            <button className="button--second">Remove Filter</button>
          </div>
        </div>
        {/* Table comes in here */}
        <TblContainer>
          <TblHead />
          <tbody>
            {recordsAfterPagingAndSorting().map((investment, i) => (
              <tr key={investment.id}>
                {console.log(investment, "investmentinvestment")}
                <th scope="row">{i + 1}</th>
                <td>{investment.debitaccount}</td>
                <td>{investment.creditaccount}</td>
                <td>{investment.postcurrency}</td>
                <td>{investment.accountnumber}</td>
                <td>{investment.accountname}</td>
                <td>{investment.amount}</td>
                <td>
                  <div className={`table--icon ${(investment.authorizationStatus === ('Authorized') || investment.authorizationStatus === ('Rejected')) && 'disabled-delete'}`}>
                    <AiTwotoneDelete />
                  </div>
                </td>
                <td>
                  <div className="table--icon--2">
                    <FiEdit />
                  </div>
                </td>
                <td>
                  <div className="adj--icon cursor--pointer">
                    <BsEyeFill />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
};

export default BulkJournalPosting;

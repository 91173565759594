import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "../../../DatagridTable/table";

const CountDefinition = () => {
  const countries = useSelector(state => state.common.countries);

  const [nations, setNations] = useState([]);

  useEffect(() => {
    if (countries) {
      const tem = [...countries];
      tem.forEach((item, index) => {
        item.sn = index + 1;
      });
      setNations(tem);
    }
  }, [countries]);

  const columns = [
    {
      name: "sn",
      label: "S/N",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "countryCode2",
      label: "Alpha-2 code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "countryCode3",
      label: "Alpha-3 code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "numberCode",
      label: "Numeric",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <Table
      title="Countries"
      subtitle=""
      name="List of countries"
      records={nations}
      options={options}
      columns={columns}
    />
  );
};

export default CountDefinition;

import { React, useState } from "react";
import "./debitcredittellertills.scss";
// import Denomination from "./denomination"
// import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import PayCash from "./payCash";
import ReceiveCash from "./receiveCash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// const PayCash = ({setFormPage}) => (
//   <>
//     <div style={{ paddingTop: 6, padding: 25 }}>
//       <div className="teller--def flex--1">
//         <div className="flex--3 input--3">
//           <label> GL Account Number</label>
//           <input type="text" />
//         </div>

//         <div className="flex--3  input--select">
//           <label>GL Account Branch</label>
//           <select>
//             <option>Select An Option</option>
//           </select>{" "}
//         </div>
//         <div className="flex--3  input--select">
//           <label>GL Account Currency</label>

//           <select>
//             <option>Select An Option</option>
//           </select>
//         </div>
//       </div>
//       <div className="teller--def flex--1">
//         <div className="flex--3  input--select">
//           <label>Till Currency</label>
//           <select>
//             <option>Select An Option</option>
//           </select>{" "}
//         </div>
//         <div className="flex--3 input--3">
//           <label>Till Account Number</label>
//           <input type="text" />
//         </div>
//         <div className="flex--3 input--3">
//           <label>Teller Name</label>
//           <input type="text" />
//         </div>
//       </div>

//       <div className="teller--def flex--1">
//         <div className="flex--3  input--select">
//           <label>Branch</label>
//           <select>
//             <option>Select An Option</option>
//           </select>
//         </div>
//         <div className="flex--3 input--3">
//           <label>Amount</label>
//           <input type="number" />
//         </div>
//         <div className="flex--3 input--">
//           <label>Narration</label>
//           <input type="text" />
//         </div>
//       </div>

//       <div className="flex--5">
//         <button onClick={() => setFormPage(2)}>Next</button>
//       </div>
//     </div>
//   </>
// )

// const ReceiveCash = ({setFormPage}) =>(
//   <>
//     <div style={{ paddingTop: 6, padding: 25 }}>
//       <div className="teller--def flex--1">
//         <div className="flex--3 input--3">
//           <label>Till Account Number</label>
//           <input type="text" />
//         </div>
//         <div className="flex--3 input--3">
//           <label>Teller Name</label>
//           <input type="text" />
//         </div>

//         <div className="flex--3  input--select">
//           <label>Branch</label>
//           <select>
//             <option>Select An Option</option>
//           </select>
//         </div>
//       </div>
//       <div className="teller--def flex--1">
//         <div className="flex--3  input--select">
//           <label>Till Currency</label>
//           <select>
//             <option>Select An Option</option>
//           </select>{" "}
//         </div>
//         <div className="flex--3 input--3">
//           <label> GL Account Number</label>
//           <input type="text" />
//         </div>

//         <div className="flex--3  input--select">
//           <label>GL Account Branch</label>
//           <select>
//             <option>Select An Option</option>
//           </select>{" "}
//         </div>
//       </div>
//       <div className="teller--def flex--1">
//         <div className="flex--3  input--select">
//           <label>GL Account Currency</label>

//           <select>
//             <option>Select An Option</option>
//           </select>
//         </div>
//         <div className="flex--3 input--3">
//           <label>Amount</label>
//           <input type="number" />
//         </div>
//         <div className="flex--3 input--3">
//           <label>Narration</label>
//           <input type="text" />
//         </div>
//       </div>

//       <div className="flex--5">

//         <button onClick={() => setFormPage(2)}>Next</button>
//       </div>
//     </div>
//   </>
// )

const DebitCreditTellerTills = () => {
  const [screenChange, setScreenChange] = useState("");
  //   const [formPage, setFormPage] =useState(1);

  //   const payCashPages = {
  //     1: <PayCash setFormPage={setFormPage}/>,
  //     2: <Denomination setFormPage={setFormPage} onSubmit={(amount) => console.log('Paycash', amount)}/>,
  //   }

  //   const receiveCashPages = {
  //     1: <ReceiveCash setFormPage={setFormPage}/>,
  //     2: <Denomination setFormPage={setFormPage} onSubmit={(amount) => console.log('receiveCash', amount)}/>,
  //   }

  const handleScreenChange = (i) => {
    console.log("qwswqeq", i);
    setScreenChange(i);
    // setFormPage(1)
  };
  return (
    <main className="debit-teller">
      <section className="section--1">
        <div className="headerdiv">
          <p>Miscellaneous Teller Transaction</p>
        </div>

        <div className="flex--3">
          {screenChange === "" && (
            <div className="button--double flex--2">
              <Card className="cards">
                {" "}
                <div className="flex--3">
                  <button
                    className={`${!screenChange ? "active-1" : ""}`}
                    onClick={() => handleScreenChange("payCash")}
                  >
                    1
                  </button>
                  <p>Pay Cash out</p>
                </div>
              </Card>
              <Card className="cards">
                <div className="flex--3">
                  <button
                    className={`${screenChange ? "active-1" : ""}`}
                    onClick={() => handleScreenChange("receiveCash")}
                  >
                    2
                  </button>
                  <p>Receive cash</p>
                </div>
              </Card>
            </div>
          )}
          {screenChange !== "" && (
            <button className="backButton" onClick={() => setScreenChange("")}>
              <ArrowBackIcon />
              back
            </button>
          )}

          {screenChange === "payCash" && <PayCash />}
          {screenChange === "receiveCash" && <ReceiveCash />}
        </div>
      </section>
    </main>
  );
};

export default DebitCreditTellerTills;
